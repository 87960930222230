import * as React from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import {
    Card, CardActions, CardContent, CardHeader, CircularProgress, Grid,
    IconButton, Modal, Typography,
} from '@material-ui/core';
import { Close, Save } from '@material-ui/icons';
import { BForm, BSubmit } from 'mui-bueno';
import { Formik } from 'formik';

import { Directory, Document, SelectOption } from '../../../../../@types';
import { handleErrorResponse } from '../../../../../service/utils';
import { updateDocument } from '../../../../../service/Study/documents';
import { showSuccessSnackbar } from '../../../../../modules/messageSnackbarReducer';
import CheckedAutocomplete from '../../../../../common/CheckedAutocomplete/CheckedAutocomplete';
import { getDirectoriesByStudy, updateDirectory } from '../../../../../service/Study/directories';

interface Props {
    open: any;
    setOpen: any;
    setUpdated: any;
    studyId: number;
    selectedDocument?: Document | null;
    setSelectedDocument?: any;
    selectedDirectory?: Directory | null;
    setSelectedDirectory?: any;
}

const MoveModal: React.FC<Props> = props => {
    const {
        open, setOpen, setUpdated, studyId, selectedDocument,
        setSelectedDocument, selectedDirectory, setSelectedDirectory
    } = props;

    const [directories, setDirectories] = React.useState<Directory[]>([]);
    const [directoryOptions, setDirectoryOptions] = React.useState<SelectOption<number>[]>([]);
    const [directoryMap, setDirectoryMap] = React.useState<Map<number | null, Directory>>(new Map());
    const [initialDirectory, setInitialDirectory] = React.useState<SelectOption<number>>();
    const [chosenDirectory, setChosenDirectory] = React.useState<SelectOption<number> | null>(null);
    const [saving, setSaving] = React.useState<boolean>(false);
    const [inputErrors, setInputErrors] = React.useState<string>('');

    const dispatcher = useDispatch();

    React.useEffect(() => {
        getDirectoriesByStudy(studyId).then(res => {
            setDirectories(res.data);

            // Create a lookup table for directories by ID
            const tempMap = new Map<number | null, Directory>();
            res.data.forEach((directory: Directory) => {
                if (directory.id !== null) {
                    tempMap.set(directory.id, directory)
                }
            });
            setDirectoryMap(tempMap);

            const noParent: SelectOption<number> = {
                value: -1,
                label: 'No Parent Directory'
            }
            const tempDirectoryOptions: SelectOption<number>[] = [];
            if (!selectedDocument) {
                // add no parent only for moving directory
                tempDirectoryOptions.push(noParent);
            }
            res.data.forEach((directory: Directory) => {
                // if move directory, not allow to move to its own directory
                if (selectedDocument || (selectedDirectory && selectedDirectory.id !== directory.id && !isChildOfSelected(selectedDirectory, directory))) {
                    tempDirectoryOptions.push({
                        value: directory.id!,
                        label: directory.name
                    })
                }
            })
            // Used to prefill the autocomplete with the directory the document is already in
            if (selectedDocument) {
                setInitialDirectory({
                    value: selectedDocument.directoryId,
                    label: _.find(res.data, ['id', selectedDocument.directoryId])!.name
                });
                setChosenDirectory({
                    value: selectedDocument.directoryId,
                    label: _.find(res.data, ['id', selectedDocument.directoryId])!.name
                });
            }

            setDirectoryOptions(tempDirectoryOptions);
        });

        setInputErrors('');
        setSaving(false);
    }, [open]);


    React.useEffect(() => {
        if (selectedDocument && directories.length > 0) {
            //When a new document is selected, change the initial directory to the selected document's directory
            setInitialDirectory({
                value: selectedDocument.directoryId,
                label: _.find(directories, ['id', selectedDocument.directoryId])!.name
            });
            setChosenDirectory({
                value: selectedDocument.directoryId,
                label: _.find(directories, ['id', selectedDocument.directoryId])!.name
            });
        }
        if (selectedDirectory) {
            //When a new directory is selected, change the initial directory to the selected directory's parent directory
            if (selectedDirectory.parentDirectoryId && directories.length > 0) {
                setInitialDirectory({
                    value: Number(selectedDirectory.parentDirectoryId),
                    label: _.find(directories, ['id', selectedDirectory.parentDirectoryId])!.name
                });
                setChosenDirectory({
                    value: Number(selectedDirectory.parentDirectoryId),
                    label: _.find(directories, ['id', selectedDirectory.parentDirectoryId])!.name
                });
            } else {
                setInitialDirectory({
                    value: -1,
                    label: 'No Parent Directory'
                });
                setChosenDirectory({
                    value: -1,
                    label: 'No Parent Directory'
                });
            }
        }
        setInputErrors('');
    }, [directories]);

    const validate = (): boolean => {
        if (!chosenDirectory || chosenDirectory.value === 0) {
            setInputErrors('Directory is required.');
            return false;
        }
        return true;
    };

    const isChildOfSelected = (currentSelected: Directory, parentOption: Directory | undefined): boolean => {
        // Will return true if the parentOption is an immediate or distant subdirectory of currentSelected
        if (parentOption === undefined || parentOption.parentDirectoryId === null) {
            return false;
        }
        if (parentOption.parentDirectoryId === currentSelected.id) {
            return true;
        }
        const parentOfParentOption = directoryMap.get(parentOption.parentDirectoryId)
        return isChildOfSelected(currentSelected, parentOfParentOption);
    }

    const handleSubmit = () => {
        if (!validate()) return;

        if (!saving && selectedDocument) {
            setSaving(true);
            updateDocument({
                id: selectedDocument.id,
                studyId: studyId,
                directoryId: chosenDirectory!.value,
                fileName: selectedDocument.fileName,
                filePath: selectedDocument.filePath,
                deleted: selectedDocument.deleted,
                createdAt: selectedDocument.createdAt
            }).then(res => {
                dispatcher(showSuccessSnackbar('Document moved.'));
                setChosenDirectory(null);
                setSelectedDocument(res.data);
                handleModalClose();
                setUpdated(true);
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    prefix: 'Could not move document: '
                })
                setSaving(false);
            });
        }

        if (!saving && selectedDirectory) {
            setSaving(true);
            const chosenParentId = chosenDirectory!.value === -1 ? null : chosenDirectory!.value;
            updateDirectory({
                id: selectedDirectory.id!,
                name: selectedDirectory.name,
                studyId: selectedDirectory.studyId,
                parentDirectoryId: chosenParentId,
                allowedFileDeleted: selectedDirectory.allowedFileDeleted
            }).then(res => {
                dispatcher(showSuccessSnackbar('Directory moved.'));
                setChosenDirectory(null);
                setSelectedDirectory(res.data);
                handleModalClose();
                setUpdated(true);
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    prefix: 'Could not move directory: '
                })
                setSaving(false);
            });
        }
    }

    const handleModalClose = () => {
        setOpen(false);
        setChosenDirectory(null);
    }

    return (
        <Modal
            open={open}
            onClose={handleModalClose}
        >
            <div className="modal-form">
                <Formik
                    initialValues={selectedDocument ? { ...selectedDocument } : { ...selectedDirectory }}
                    onSubmit={handleSubmit}
                    enableReinitialize
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    <BForm>
                        <Card className="detail-form sm">
                            <CardHeader
                                title={selectedDocument ? 'Move Document' : 'Move Directory'}
                                action={
                                    <IconButton color="primary" aria-label="Close" onClick={handleModalClose}>
                                        <Close />
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                <Grid container spacing={2} justifyContent='center'>
                                    <Grid item xs={12}>
                                        <CheckedAutocomplete
                                            idText={selectedDocument ? 'directoryId' : 'parentDirectoryId'}
                                            multiple={false}
                                            options={directoryOptions}
                                            labelText={'Directory'}
                                            closeOnSelect={true}
                                            defaultValue={initialDirectory}
                                            onChange={setChosenDirectory}
                                            autoFocus
                                        />
                                        <Typography color='error'>{inputErrors}</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions className="flex-end">
                                <BSubmit
                                    startIcon={saving ? null : <Save />}
                                    variant="contained"
                                    color="primary"
                                    disabled={saving}
                                >
                                    {saving && <CircularProgress size={24} className='button-progress' />}
                                    Save
                                </BSubmit>
                            </CardActions>
                        </Card>
                    </BForm>
                </Formik>
            </div>
        </Modal>
    )
};

export default MoveModal;
import * as React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';


const currentYear: number = new Date().getFullYear();

interface Props {
    year: number;
    setYear: (arg0: number) => void;
    minimum?: number;
    allowFuture?: boolean;
    className?: string;
}

const YearSelector: React.FC<Props> = (props) => {
    const {
        year,
        setYear,
        minimum,
        allowFuture,
        className,
    } = props;

    return (
        <div className={"right row " + className ?? ''}>
            <IconButton
                onClick={() => setYear(year - 1)}
                disabled={minimum ? (year <= minimum) : false}
                color="inherit"
            >
                <ChevronLeft />
            </IconButton>
            <Typography>
                {year}
            </Typography>
            <IconButton
                onClick={() => setYear(year + 1)}
                disabled={allowFuture ? false : (year >= currentYear)}
                color="inherit"
            >
                <ChevronRight />
            </IconButton>
        </div>
    );
}

export default YearSelector;
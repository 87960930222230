import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { Button, Card, Tooltip, Typography } from '@material-ui/core';
import { CallMade, ChevronRight, Create, Delete, GetApp, History, Publish } from '@material-ui/icons';

import { Directory, Document, DocumentVersion } from '../../../../@types';
import { deleteDocument, downloadLatestDocument, getDocumentHistories } from '../../../../service/Study/documents';
import { showSuccessSnackbar } from '../../../../modules/messageSnackbarReducer';
import SimpleConfirmDelete from '../../../../common/ConfirmDelete/SimpleConfirmDelete';

import UploadModal from './Modal/UploadModal';
import HistoryModal from './Modal/HistoryModal';
import { handleErrorResponse } from '../../../../service/utils';
import useLoggedInUserPermissions from '../../../../common/hooks/useLoggedInUserPermissions';
import { displayDateWithTime } from '../../../../common/Utils/utils';
import RenameModal from './Modal/RenameModal';
import MoveModal from './Modal/MoveModal';
import { getDirectoryHierarchy } from '../../../../service/Study/directories';
import { handleDownloadDocument } from './Documents';

interface Props {
    studyId: number;
    divisionId: number;
    selectedDocument: Document | null;
    setSelectedDocument: any;
    studyStarted: boolean;
    studyCompleted: boolean;
    notStartedUploadText: string;
    completedUploadText: string;
    updated: any;
    parentDirectories: Directory[];
    setParentDirectories: any;
}

const DocumentPreview: React.FC<Props> = (props) => {
    const {
        studyId, divisionId, selectedDocument, setSelectedDocument, studyStarted,
        studyCompleted, notStartedUploadText, completedUploadText, updated,
        parentDirectories, setParentDirectories
    } = props;

    const dispatcher = useDispatch();
    const history = useHistory();

    const [filePreview, setFilePreview] = React.useState<File>();
    const [filePreviewType, setFilePreviewType] = React.useState<string>('');
    const [uploadOpen, setUploadOpen] = React.useState<boolean>(false);
    const [historyOpen, setHistoryOpen] = React.useState<boolean>(false);
    const [renameOpen, setRenameOpen] = React.useState<boolean>(false);
    const [moveOpen, setMoveOpen] = React.useState<boolean>(false);
    const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);
    const [versions, setVersions] = React.useState<DocumentVersion[]>([]);
    const [deletable, setDeleteable] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (selectedDocument) {
            getDocumentHistories(selectedDocument.id!).then(res => {
                setVersions(res.data);
            });
            // Whether the file can be deleted depends on its parent directory's deletion setting
            getDirectoryHierarchy(selectedDocument.directoryId).then(dir => {
                setDeleteable(dir.data.directory.allowedFileDeleted);
                setParentDirectories(dir.data.parentDirectories.concat(dir.data.directory));
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    prefix: 'Could not retrieve directory hierarcy: '
                });
            });
            handlePreview(selectedDocument);
        }
    }, [selectedDocument]);


    // Text for tooltips
    const notStartedRename = 'Cannot rename a document before the study has been started';
    const completedRename = 'Cannot rename a document after the study has been completed';
    const notStartedMove = 'Cannot move a document before the study has been started';
    const completedMove = 'Cannot move a document after the study has been completed';
    const notStartedDelete = 'Cannot delete a document before the study has been started';
    const completedDelete = 'Cannot delete a document after the study has been completed';

    const handleDelete = (docId: number) => {
        deleteDocument(docId).then(() => {
            updated(true);
            setDeleteOpen(false);
            setSelectedDocument(null);
            dispatcher(showSuccessSnackbar('Document deleted'));
            history.push(`/study/${Number(studyId)}/documents`);
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                prefix: 'Could not delete document: '
            });
        });
    };

    // State management to determine whether to display buttons, display documents, and allow upload and delete capability based on user's permissions and role restriction
    const canUploadExisting = useLoggedInUserPermissions('API_DOCUMENT_UPLOAD_EXISTING_DOCUMENT', divisionId, studyId);
    const canUpdate = useLoggedInUserPermissions('API_DOCUMENT_UPDATE', divisionId, studyId);
    const canUpdateDocName = useLoggedInUserPermissions('API_DOCUMENT_UPDATE_DOCUMENT_NAME', divisionId, studyId);
    const canViewHistory = useLoggedInUserPermissions('API_DOCUMENT_GET_DOCUMENT_HISTORIES', divisionId, studyId);
    const canDownload = useLoggedInUserPermissions('API_DOCUMENT_DOWNLOAD_DOCUMENT', divisionId, studyId);
    const canDelete = useLoggedInUserPermissions('API_DOCUMENT_DELETE_BY_ID', divisionId, studyId);

    // Used to preview the file when it is selected
    const handlePreview = (selectedDoc: Document) => {
        downloadLatestDocument(selectedDoc.id!)
            .then(res => {
                // Replace commas and periods (not the one used with the file extension) with underscores to combat Chrome
                const fileName = selectedDoc.fileName.replace(',', '_');
                const file = new File([res.data], fileName);
                setFilePreview(file);
                determinePreviewType(selectedDoc.fileName);
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    prefix: 'Could not retrieve Document: '
                })
            });
    };

    const determinePreviewType = (fileName: string) => {
        const lastPeriod = fileName.lastIndexOf('.');
        setFilePreviewType(fileName.substring(lastPeriod + 1));
    }

    // Different file types require different ways to render them
    const handlePreviewRender = (fileToPreview: File) => {
        if (filePreviewType === 'png' || filePreviewType === 'jpg' || filePreviewType === 'jpeg') {
            return (
                <img
                    src={URL.createObjectURL(fileToPreview)}
                    alt={`Preview of Document: ${selectedDocument?.fileName}`}
                    width='100%'
                    height='100%'
                />
            )
        } else {
            return (
                <div className='unavailable-message'>
                    Preview not available.
                </div>
            )
        }
    }

    if (selectedDocument == null || selectedDocument.deleted) {
        return (
            <div className='plain-message'>
                <Typography variant='h6'>
                    Document does not exist
                </Typography>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => history.push(`/study/${Number(studyId)}/documents`)}
                    style={{ margin: 10 }}
                >
                    Back to All Directories
                </Button>

            </div>
        )
    }
    return (
        <>
            <Card className="split-cards details documents-preview">
                <div className='card-header'>
                    <p
                        className={'cursor-pointer ' + (!selectedDocument && 'active-path')}
                        onClick={() => history.push(`/study/${Number(studyId)}/documents`)}
                    >
                        All
                    </p>
                    {selectedDocument && <ChevronRight />}
                    {parentDirectories.map((dir: Directory, idx: number) => {
                        return (
                            <React.Fragment key={'dir-' + idx}>
                                <p className='cursor-pointer' onClick={() => history.push(`/study/${Number(studyId)}/documents/directory/${dir.id}`)}>
                                    {dir.name}
                                </p>
                                <ChevronRight />
                            </React.Fragment>
                        )
                    })}
                    {selectedDocument && <p className='active-path'>{selectedDocument.fileName}</p>}
                </div>
                <div className='file-actions'>
                    {canUploadExisting &&
                        <Tooltip arrow title={!studyStarted ? notStartedUploadText : studyCompleted ? completedUploadText : ''}>
                            <div>
                                <Button
                                    variant='outlined'
                                    onClick={() => setUploadOpen(true)}
                                    disabled={!studyStarted || studyCompleted}
                                    className={(!studyStarted || studyCompleted) ? 'small-grey-button-disabled' : 'small-grey-button'}
                                >
                                    <Publish />
                                    Upload New Version
                                </Button>
                            </div>
                        </Tooltip>
                    }
                    {canDownload &&
                        <Button
                            onClick={(event) => handleDownloadDocument(selectedDocument.id!, null, dispatcher, selectedDocument.fileName, event)}
                            variant='outlined'
                            disabled={false}
                            className='small-grey-button'
                        >
                            <GetApp />
                            Download
                        </Button>
                    }
                    {canViewHistory &&
                        <Button
                            onClick={() => setHistoryOpen(true)}
                            variant='outlined'
                            disabled={false}
                            className='small-grey-button'
                        >
                            <History />
                            View All Versions
                        </Button>
                    }
                    {canUpdateDocName &&
                        <Tooltip arrow title={!studyStarted ? notStartedRename : studyCompleted ? completedRename : ''}>
                            <div>
                                <Button
                                    onClick={() => setRenameOpen(true)}
                                    variant='outlined'
                                    disabled={!studyStarted || studyCompleted}
                                    className={(!studyStarted || studyCompleted) ? 'small-grey-button-disabled' : 'small-grey-button'}
                                >
                                    <Create />
                                    Rename Document
                                </Button>
                            </div>
                        </Tooltip>
                    }
                    {canUpdate &&
                        <Tooltip arrow title={!studyStarted ? notStartedMove : studyCompleted ? completedMove : ''}>
                            <div>
                                <Button
                                    onClick={() => setMoveOpen(true)}
                                    variant='outlined'
                                    disabled={!studyStarted || studyCompleted}
                                    className={(!studyStarted || studyCompleted) ? 'small-grey-button-disabled' : 'small-grey-button'}
                                >
                                    <CallMade />
                                    Move Document
                                </Button>
                            </div>
                        </Tooltip>
                    }
                    {(canDelete && deletable) &&
                        <Tooltip arrow title={!studyStarted ? notStartedDelete : studyCompleted ? completedDelete : ''}>
                            <div>
                                <Button
                                    startIcon={<Delete />}
                                    className={(!studyStarted || studyCompleted) ? 'small-grey-button-disabled' : 'small-grey-button'}
                                    onClick={() => setDeleteOpen(true)}
                                    disabled={!studyStarted || studyCompleted}
                                >
                                    Delete Document
                                </Button>
                            </div>
                        </Tooltip>
                    }
                </div>
                <div className='card-content  documents-height document-preview'>
                    {filePreview &&
                        handlePreviewRender(filePreview)
                    }
                </div>
                {versions.length > 0 &&
                    <div>
                        Uploaded: {displayDateWithTime(versions[0]!.created)}
                    </div>
                }
            </Card>
            <UploadModal
                open={uploadOpen}
                setOpen={setUploadOpen}
                setUpdated={updated}
                studyId={studyId}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
            />
            <HistoryModal
                open={historyOpen}
                setOpen={setHistoryOpen}
                studyId={studyId}
                divisionId={divisionId}
                selectedDocument={selectedDocument}
            />
            <RenameModal
                open={renameOpen}
                setOpen={setRenameOpen}
                setUpdated={updated}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
            />
            <MoveModal
                open={moveOpen}
                setOpen={setMoveOpen}
                setUpdated={updated}
                studyId={studyId}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
            />
            <SimpleConfirmDelete
                open={deleteOpen}
                setOpen={setDeleteOpen}
                type='Document'
                objectName={selectedDocument.fileName}
                handleDelete={() => handleDelete(selectedDocument.id!)}
            />
        </>
    )
}

export default DocumentPreview;
import { AxiosError } from 'axios';

import axiosInstance from './axiosInstance';
import store from '../../store';
import { clearStoreLogout } from '../../modules/loginReducer';
import { showErrorSnackbar } from '../../modules/messageSnackbarReducer';

const setupAxiosInterceptors = () => {

    axiosInstance.interceptors.response.use(
        res => {
            return res;
        },
        (error: AxiosError) => {
            const origReq = error.config;

            // Error received from service
            if (error.response) {
                if (error.response.status === 401) {
                    // Refresh token expired
                    if (origReq.url!.includes('/api/auth/refresh')) {
                        // Logout
                        axiosInstance.defaults.headers.common.Authorization = '';
                        localStorage.removeItem('accessToken');
                        store.dispatch(clearStoreLogout());
                    } else {
                        store.dispatch(showErrorSnackbar('Unauthorized Access'));
                    }
                }
            }
            // No error response from service
            else {
                store.dispatch(showErrorSnackbar('Network connection error, please try again later.'));
            }

            return Promise.reject(error);
        }
    );
};

export default setupAxiosInterceptors;
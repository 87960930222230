import { useSelector } from 'react-redux';

import { PermissionConst, User } from '../../@types';
import { Store } from '../../modules/rootReducer';

const useLoggedInUserAccess = (permission: PermissionConst, user: User, noSelf: boolean = false): boolean => {
    const userRolePerms = useSelector<Store, any>(store =>
        store.loginReducer.rolePermissions
    );
    
    const selectedPermission = userRolePerms[permission];
    if (!selectedPermission) {
        return false; //user has no permission
    }
    const isNoRestriction: boolean =
        (selectedPermission.restrictedDivisions.length === 0 &&
            selectedPermission.restrictedStudies.length === 0);
    
    const currentUser = useSelector<Store, User>(store =>
        store.loginReducer.user
    );
    const selfUser: boolean = !noSelf && (currentUser.id === user.id);

    let hasDivisionAccess: boolean = false;
    if (user.divisionIds) {
        user.divisionIds.forEach(id => {
            if (id > 0 && selectedPermission.restrictedDivisions.includes(id))
                hasDivisionAccess = true;
        });
    }

    return isNoRestriction || selfUser || hasDivisionAccess;
};

export default useLoggedInUserAccess;
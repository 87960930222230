import axiosInstance from '../Access/axiosInstance';

import {
    Invoice, InvoiceDetailWithChecks, InvoiceGeneration, InvoicePayment, InvoiceStatusSummary,
    InvoiceSummary, LineItemDetailWithInvoice, StudyInvoices, StudyInvoicingResult
} from '../../@types';
import { handleError } from '../utils';

/**
 * Gets an Invoice Status Summary by Division Id
 */
export const getStatusSummaryByDivision = (divisionId?: number) =>
    axiosInstance.get<InvoiceStatusSummary>(
        divisionId ?
            `api/invoices/statusSummary/division?divisionId=${divisionId}`
            : `api/invoices/statusSummary/division`
    ).catch(handleError);

/**
 * Gets an Invoice Status Summary by Account Id
 */
export const getStatusSummaryByAccount = (accountId: number) =>
    axiosInstance.get<InvoiceStatusSummary>(
        `/api/invoices/statusSummary/account/${accountId}`
    ).catch(handleError);

/**
 * Gets an Invoice Status Summary by Study Id
 */
export const getStatusSummaryByStudy = (studyId: number) =>
    axiosInstance.get<InvoiceStatusSummary>(
        `/api/invoices/statusSummary/study/${studyId}`
    ).catch(handleError);

export const getDivisionInvoices = (type: 'payable' | 'receivable', ageLimit: number, divisionId?: number) =>
    axiosInstance.get<InvoiceDetailWithChecks[]>(
        divisionId ?
            `/api/invoices/division/${type}?ageLimit=${ageLimit}&divisionId=${divisionId}`
            : `/api/invoices/division/${type}?ageLimit=${ageLimit}`
    )

/**
 * Get list of unpaid invoices by paying account, studyId, and receivingAccount
 * 
 */
export const getUnpaidInvoices = (payingAccountId: number, studyId: number, receivingAccountId: number, holdback?: boolean) =>
    axiosInstance.get<Invoice[]>(
        `/api/invoices/unpaid/paying/${payingAccountId}/study/${studyId}/receiving/${receivingAccountId}?holdback=${holdback}`
    ).catch(handleError)
/**
 * Gets an InvoiceSummary by Invoice Id
 */
export const getInvoiceSummary = (invoiceId: number) =>
    axiosInstance.get<InvoiceSummary>(
        `/api/invoices/summary/${invoiceId}`
    ).catch(handleError);

/**
 * Gets a list of LineItemDetailWithInvoice by Invoice Id
 */
export const getInvoiceLineItemDetails = (invoiceId: number) =>
    axiosInstance.get<LineItemDetailWithInvoice[]>(
        `/api/invoices/summary/lineItems/${invoiceId}`
    ).catch(handleError);

/**
 * Gets the stored file for an Invoice by Invoice Id
 */
export const downloadInvoice = (invoiceId: number) =>
    axiosInstance.get(
        `/api/invoices/download/${invoiceId}`,
        { responseType: 'blob' }
    ).catch(handleError);

/**
 * Gets all Receivable Invoices by Study Id
 */
export const getStudyReceivableInvoices = (studyId: number) =>
    axiosInstance.get<StudyInvoices>(
        `/api/invoices/study/receivables/${studyId}`
    ).catch(handleError);

/**
 * Gets all Payable Invoices by Study Id
 */
export const getStudyPayableInvoices = (studyId: number) =>
    axiosInstance.get<StudyInvoices>(
        `/api/invoices/study/payables/${studyId}`
    ).catch(handleError);

/**
 * Updates an invoice description
 */
export const updateInvoiceDescription = (id: number, description: string) =>
    axiosInstance.put<Invoice>(
        `/api/invoices/updateDescription/${id}`, description, { headers: { 'Content-Type': 'application/json' } }
    ).catch(handleError)

/**
 * Marks an invoice as sent
 */
export const markInvoiceAsSent = (invoiceId: number, date: string) =>
    axiosInstance.put<Invoice>(
        `/api/invoices/markAsSent/${invoiceId}`,
        date,
        { headers: { 'Content-Type': 'application/json' } }
    ).catch(handleError);

/**
 * Gets a list of payments for an invoice
 */
export const getInvoicePayments = (invoiceId: number) =>
    axiosInstance.get<InvoicePayment[]>(
        `/api/invoices/payments/${invoiceId}`
    ).catch(handleError)

/** 
 * Gets an invoice by id
 */
export const getInvoice = (id: number) =>
    axiosInstance.get<Invoice>(
        `/api/invoices/${id}`
    ).catch(handleError)

/** 
 * Generate invoice by study id
 */
export const generateInvoiceByStudy = (studyId: number) =>
    axiosInstance.post(
        `/api/invoices/study/${studyId}`
    ).catch(handleError)

/** 
 * Get last invoice's generation by study
 */
export const getLastInvoiceGeneration = (studyId: number) =>
    axiosInstance.get<InvoiceGeneration>(
        `/api/invoices/lastGeneration/study/${studyId}`
    ).catch(handleError)

/**
 * Get the amount not invoiced on each study
 */
export const getAmountNotInvoicedOnStudies = (divisionId?: number) =>
    axiosInstance.get<StudyInvoicingResult>(
        divisionId ?
            `/api/invoices/studyInvoicing/notInvoiced?divisionId=${divisionId}`
            : '/api/invoices/studyInvoicing/notInvoiced'
    ).catch(handleError)

/**
 * Get the amount not invoiced on each restricted study
 */
export const getAmountNotInvoicedOnRestrictedStudies = (divisionId?: number) =>
    axiosInstance.get<StudyInvoicingResult>(
        divisionId ?
            `/api/invoices/studyInvoicing/notInvoicedRestricted?divisionId=${divisionId}`
            : '/api/invoices/studyInvoicing/notInvoicedRestricted'
    ).catch(handleError)

/**
 * Get the amount not paid on each study
 */
export const getAmountNotPaidOnStudies = (divisionId?: number) =>
    axiosInstance.get<StudyInvoicingResult>(
        divisionId ?
            `/api/invoices/studyInvoicing/notPaid?divisionId=${divisionId}`
            : '/api/invoices/studyInvoicing/notPaid'
    ).catch(handleError)

/**
 * Get the amount not paid on each restricted study
 */
export const getAmountNotPaidOnRestrictedStudies = (divisionId?: number) =>
    axiosInstance.get<StudyInvoicingResult>(
        divisionId ?
            `/api/invoices/studyInvoicing/notPaidRestricted?divisionId=${divisionId}`
            : '/api/invoices/studyInvoicing/notPaidRestricted'
    ).catch(handleError)
import * as React from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';

import { AppBar, Drawer, Hidden, IconButton, List, Toolbar } from '@material-ui/core';
import { MenuRounded } from '@material-ui/icons';

import EnvHeader, { envMessage } from '../EnvHeader/EnvHeader';
import useLoggedInUser from '../hooks/useLoggedInUser';

import LoggedOut from './LoggedOut';
import LoggedIn from './LoggedIn';
import SimulatorHeader from './SimulatorHeader';

import logo from '../../media/logo/logo-180.png';

export interface Feature {
    key: string;
    name: string;
    route: string;
}

export const AdapterLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
    (props, ref) => <Link innerRef={ref as any} {...props} />
);

const Header: React.FC = () => {
    const { pathname } = useLocation();
    const isSimulator = pathname.includes('simulator');

    const [style, setStyle] = React.useState<string>('');

    React.useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 10)
                setStyle('simulator')
            else
                setStyle('')
        }
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const [menuOpen, setMenuOpen] = React.useState(false);
    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen)
    };

    const displayHeader = () => {
        if (useLoggedInUser().id) {
            if (isSimulator) {
                return <SimulatorHeader handleDrawerToggle={handleMenuToggle} />
            }
            return <LoggedIn handleDrawerToggle={handleMenuToggle} />;
        }
        return <LoggedOut handleDrawerToggle={handleMenuToggle} />
    }

    return (
        <div className={'header ' + style}>
            <AppBar position="sticky" elevation={1} color='secondary'>
                {envMessage && <EnvHeader />}
                <Toolbar>
                    <Link to='/' className='logo'>
                        <img src={logo} alt="CRAFT Logo Image" />
                        CRAFT
                    </Link>
                    <Hidden smDown>
                        {displayHeader()}
                    </Hidden>
                    <Hidden mdUp>
                        <div className="top-right">
                            <IconButton onClick={handleMenuToggle} color='inherit'>
                                <MenuRounded />
                            </IconButton>
                        </div>
                    </Hidden>
                </Toolbar>
            </AppBar>
            <Hidden mdUp>
                <Drawer anchor="top" open={menuOpen} onClose={handleMenuToggle} className={envMessage ? 'env header-drawer ' : 'header-drawer'}>
                    <List disablePadding className="header-menu">
                        {displayHeader()}
                    </List>
                </Drawer>
            </Hidden>
        </div>
    )
};

export default Header;
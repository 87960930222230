import * as React from 'react';
import * as yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { Card, CardActions, CardContent, CardHeader, Grid, IconButton, InputAdornment, Modal } from '@material-ui/core';
import { Close, Delete, Save } from '@material-ui/icons';
import { BButton, BForm, BOption, BSelect, BSubmit, BTextField } from 'mui-bueno';

import { PlannedCost, SelectOption } from '../../../../@types';
import { NumberFormatter } from '../../../../common/Utils/NumberFormatter';

import CheckedAutocomplete from '../../../../common/CheckedAutocomplete/CheckedAutocomplete';


interface Props {
    plannedCost: PlannedCost;
    open: any;
    setOpen: any;
    setDelete: any;
    permissionDelete: any;
    handleUpdate: (arg0: PlannedCost) => void;
    invoiceTypes: BOption<string>[];
    serviceProviderOptions: SelectOption<number>[];
}

const schema = yup.object<PlannedCost>().shape({
    name: yup.string().required('Name is required'),
    actualCost: yup.number().required('Actual cost is required').min(0, 'Actual cost cannot be negative'),
    sponsorProposal: yup.number().required('Sponsor proposal is required').min(0, 'Sponsor proposal cannot be negative'),
    maxOccurrences: yup.number().integer().positive().min(1, 'Max occurrences cannot be less than 1'),
    invoiceType: yup.string().required('Invoice type is required'),
    // serviceProvider: yup.number(),
});

const EditPlannedCost: React.FC<Props> = props => {
    const {
        plannedCost,
        open,
        setOpen,
        setDelete,
        permissionDelete,
        handleUpdate,
        invoiceTypes,
        serviceProviderOptions
    } = props;

    // service provider state management
    const [selectedServiceProvider, setSelectedServiceProvider] = React.useState<SelectOption<number> | null>(null);
    const [disableServiceProvider, setDisableServiceProvider] = React.useState<boolean>(plannedCost.invoiceType != 'PASS_THROUGH');
    const [serviceProviderError, setServiceProviderError] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (plannedCost.serviceProviderId) {
            setSelectedServiceProvider(serviceProviderOptions.find(o => o.value == plannedCost.serviceProviderId)!)
        }
    }, [setOpen]);

    const handleInvoiceTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const invoiceType = invoiceTypes[event.target.value as number].value;
        if (invoiceType != 'PASS_THROUGH')
            setSelectedServiceProvider(null);
        setDisableServiceProvider(invoiceType != 'PASS_THROUGH');
    }

    const handleSubmit = async (data: PlannedCost, { setErrors }: FormikHelpers<PlannedCost>) => {

        if (!disableServiceProvider && !selectedServiceProvider) {
            setServiceProviderError(true);
            return;
        }

        const serviceProviderId = disableServiceProvider ? undefined : selectedServiceProvider?.value
        handleUpdate({
            ...data,
            serviceProviderId: serviceProviderId
        });
    }

    const handleModalClose = (): void => setOpen(false);

    return (
        <Modal
            open={open}
            onClose={handleModalClose}
        >
            <div className="modal-form">
                <Formik
                    initialValues={{ ...plannedCost }}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={schema}
                >
                    <BForm>
                        <Card className="detail-form sm">
                            <CardHeader
                                title="Edit Planned Cost"
                                action={
                                    <IconButton color="primary" aria-label="Close" onClick={handleModalClose}>
                                        <Close />
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                <Grid container spacing={2} justifyContent='center'>
                                    <BTextField
                                        name='name'
                                        placeholder='Name...'
                                        required autoFocus
                                        xs={12} noMP
                                    />
                                    <BTextField
                                        name='sponsorProposal'
                                        placeholder='0.00'
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            inputComponent: NumberFormatter as any
                                        }}
                                        xs={4} noMP
                                    />
                                    <BTextField
                                        name='actualCost'
                                        placeholder='0.00'
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            inputComponent: NumberFormatter as any
                                        }}
                                        xs={4} noMP
                                    />
                                    <BTextField
                                        name='maxOccurrences'
                                        placeholder='1'
                                        xs={4} noMP
                                        InputProps={{
                                            inputComponent: NumberFormatter as any,
                                            inputProps: { integer: true }
                                        }}
                                    />
                                    <BSelect
                                        name='invoiceType'
                                        label='Invoice Type'
                                        placeholder='Invoice Type'
                                        variant="outlined"
                                        options={invoiceTypes}
                                        xs={disableServiceProvider ? 12 : 6} noMP
                                        onChange={handleInvoiceTypeChange}
                                    />
                                    {!disableServiceProvider &&
                                        <Grid item xs={6}>
                                            <div className='service-provider-input'>
                                                <CheckedAutocomplete
                                                    idText='serviceProvider'
                                                    placeholder='Service Provider'
                                                    multiple={false}
                                                    noCheckmark
                                                    closeOnSelect
                                                    acValue={selectedServiceProvider}
                                                    options={serviceProviderOptions}
                                                    onChange={setSelectedServiceProvider}
                                                    variant='outlined'
                                                    size='small'
                                                    labelText='Service Provider'
                                                    disabled={disableServiceProvider}
                                                    error={serviceProviderError}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </div>
                                        </Grid>
                                    }
                                    <BTextField
                                        name='autoPayTrigger'
                                        label='Autopay Trigger'
                                        placeholder='Autopay Trigger...'
                                        xs={12} noMP
                                    />
                                    <BTextField
                                        name='comment'
                                        placeholder='Comments...'
                                        xs={12} noMP
                                    />
                                </Grid>
                            </CardContent>
                            <CardActions className="space-between">
                                {permissionDelete ?
                                    <BButton
                                        startIcon={<Delete />}
                                        variant="contained"
                                        className="delete-color"
                                        onClick={() => {
                                            handleModalClose();
                                            setDelete(true);
                                        }}
                                    >
                                        Delete
                                    </BButton> : <div />}
                                <BSubmit
                                    startIcon={<Save />}
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </BSubmit>
                            </CardActions>
                        </Card>
                    </BForm>
                </Formik>
            </div>
        </Modal>
    )
};

export default EditPlannedCost;
import { ForceResetPasswordRequest, ForceResetPasswordResponse, LoginRequest, LoginResponse, MfaTokenRequest, RequestTokenResponse } from '../../@types';
import { loginJwt } from '../../modules/loginJwtReducer';
import store from '../../store';
import axiosInstance from './axiosInstance';

import { handleError } from '../utils';

/**
 * Login
 */
export const login = (loginRequest: LoginRequest) =>
    axiosInstance
        .post<LoginResponse & ForceResetPasswordResponse & MfaTokenRequest>(
            '/api/auth/login', loginRequest
        ).catch(handleError);

/**
 * Force reset password
 */
export const forceResetPassword = (forceResetPasswordRequest: ForceResetPasswordRequest) =>
    axiosInstance
        .post(
            '/api/auth/reset', forceResetPasswordRequest
        ).catch(handleError);

/**
 * Verify Totp signup
 */
export const verifySetupToken = (mfaTokenRequest: MfaTokenRequest) =>
    axiosInstance
        .post('/api/auth/verify', mfaTokenRequest).catch(handleError);

/**
 * Token request
 */
export const requestToken = (accessToken: string | null) =>
    axiosInstance
        .put<RequestTokenResponse>('/api/auth/refresh', {
            accessToken
        }, {
            headers: {
                Authorization: ''
            }
        }).then(async res => {
            const { dispatch } = store
            dispatch(loginJwt({ accessToken: res.data.accessToken }));
            return res;
        });

/** 
 * Logout
 */
export const logout = (token: string | null) =>
    axiosInstance
        .put('/api/auth/logout', {
            token
        }, {
            headers: {
                Authorization: ''
            }
        });
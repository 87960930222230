import axiosInstance from '../Access/axiosInstance';
import { AccountPaymentDetail, AccountPayment } from '../../@types';
import { handleError } from '../utils';

export const logPayment = (payingAccountId: number, studyId: number, receivingAccountId: number, formData: FormData) => 
    axiosInstance.post<AccountPayment>(
        `/api/accountPayments/paying/${payingAccountId}/study/${studyId}/receiving/${receivingAccountId}`, formData
    ).catch(handleError);

export const logHoldbackPayment = (payingAccountId: number, studyId: number, formData: FormData) => 
    axiosInstance.post<AccountPayment>(
        `/api/accountPayments/holdback/paying/${payingAccountId}/study/${studyId}`, formData
    ).catch(handleError)

/**
 * Gets account payment detail
 */
export const getPaymentDetail = (id: number) => 
    axiosInstance.get<AccountPaymentDetail>(
        `/api/accountPayments/paymentDetail/${id}`
    ).catch(handleError);

/**
* Gets the remittance as a pdf
*/
export const downloadRemittance = (id: number) =>
    axiosInstance.get(
        `/api/accountPayments/download/${id}`,
        { responseType: 'blob' }
    ).catch(handleError);
import * as React from 'react';
import { useDispatch } from 'react-redux';

import {
    Card, CardContent, CardHeader, IconButton,
    Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { BDatePicker, BForm, BSubmit } from 'mui-bueno';
import { Formik, FormikHelpers } from 'formik';

import { IncurredCost, SelectOption, TableHeader } from '../../../../../@types';
import { showSuccessSnackbar } from '../../../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../../../service/utils';
import { createIncurredFromPlannedCost, updateIncurredCost } from '../../../../../service/IncurredCost/incurredCost';
import CheckedAutocomplete from '../../../../../common/CheckedAutocomplete/CheckedAutocomplete';

const headers: TableHeader[] = [{
    type: 'dateOfOccurrence',
    displayValue: 'Date of Occurrence',
    align: 'left',
}, {
    type: 'userIds',
    displayValue: 'Employees',
    align: 'left',
}];

interface Props {
    open: any;
    handleChooseEmployeeModalClose: () => void;
    plannedId: any;
    employees: SelectOption<number>[];
    setUpdatePage: any;
}

const ChooseEmployee: React.FC<Props> = props => {
    const { open, handleChooseEmployeeModalClose, plannedId, employees, setUpdatePage } = props;

    const dispatcher = useDispatch();

    const incurredCost: IncurredCost = {
        id: 0,
        cost: 0,
        dateOfOccurrence: new Date(),
        description: '',
        invoiceType: '',
        serviceProviderId: 0,
        studyId: 0,
        userIds: []
    };

    // State management to convert from Employee SelectOption to corresponding objects' ID
    const [selectedUserIds, setSelectedUserIds] = React.useState<number[]>([]);

    const handleSetSelectedEmployees = (employeesSelected: any) => {
        const temp: number[] = [];
        employeesSelected.forEach((employee: { value: number; }) => {
            temp.push(employee.value);
        })
        setSelectedUserIds(temp);
    };

    const handleSubmit = async (data: IncurredCost, { setErrors }: FormikHelpers<IncurredCost>) => {
        let description: string = '';
        createIncurredFromPlannedCost(plannedId).then(res => {
            description = res.data.description;
            updateIncurredCost({
                id: res.data.id,
                cost: res.data.cost,
                dateOfOccurrence: data.dateOfOccurrence,
                description: res.data.description,
                invoiceType: res.data.invoiceType,
                serviceProviderId: res.data.serviceProviderId,
                studyId: res.data.studyId,
                userIds: selectedUserIds
            }).then(() => {
                setUpdatePage(true);
            });
        }).then(() => {
            dispatcher(showSuccessSnackbar(`Created incurred cost "${description}"`));
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                setStatus: setErrors,
                prefix: 'Could not create Incurred Cost: '
            });
        });

        handleChooseEmployeeModalClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleChooseEmployeeModalClose}
        >
            <div className="modal-form">
                <Formik
                    initialValues={{ ...incurredCost }}
                    onSubmit={handleSubmit}
                >
                    <BForm>
                        <Card className="detail-form lg">
                            <CardHeader
                                title="Select a Date and an Employee"
                                action={
                                    <IconButton color="primary" aria-label="Close" onClick={handleChooseEmployeeModalClose}>
                                        <Close />
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                <Typography style={{ paddingBottom: 10 }}>
                                    Select a date of occurrence and an employee, and then click create
                                </Typography>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead className="table-header">
                                            <TableRow>
                                                {headers.map(header => {
                                                    return (
                                                        <TableCell
                                                            key={'header-' + header.type}
                                                            className={'cursor-pointer primary-cell '}
                                                        >
                                                            <div className={`header-cell-label ${header.align}`}>
                                                                {header.displayValue}
                                                            </div>
                                                        </TableCell>
                                                    )
                                                })}
                                                <TableCell key='header-actions' className='primary-cell'>
                                                    <div className="header-cell-label center">
                                                        Actions
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={'add-from-planned-cost'}>
                                                <TableCell
                                                    key={'add-dateOfOccurrence'}
                                                    className={'body-cell left'}
                                                    style={{ width: '25%' }}
                                                >
                                                    <BDatePicker
                                                        name="dateOfOccurrence"
                                                        placeholder="MM/DD/YYYY"
                                                        inputVariant="standard"
                                                        required autoFocus
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    key={'add-employee-name'}
                                                    className={'body-cell left'}
                                                >
                                                    <CheckedAutocomplete
                                                        idText={'userIds'}
                                                        multiple={true}
                                                        options={employees}
                                                        labelText={'Employees'}
                                                        onChange={handleSetSelectedEmployees}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    key={'add-incurred-cost-done'}
                                                    className={'body-cell center'}
                                                    style={{ width: '5%' }}
                                                >
                                                    <BSubmit
                                                        variant="text"
                                                        color="primary"
                                                        className="flex-end"
                                                    >
                                                        Create
                                                    </BSubmit>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </BForm>
                </Formik>
            </div>
        </Modal>
    )
};

export default ChooseEmployee;
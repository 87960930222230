import axiosInstance from '../Access/axiosInstance';
import { IncurredCost, IncurredCostPage, IncurredCostPageRequest, IncurredCostWithUsers } from '../../@types';
import { handleError } from '../utils';

/**
 * Gets a page of incurred costs
 */
export const getIncurredCostPage = (pageReq: IncurredCostPageRequest) =>
    axiosInstance.get<IncurredCostPage>(
        '/api/incurredCosts', {
            params: pageReq
        }
    ).catch(handleError);

/**
 * Gets a list of incurred costs from the study
 */
export const getIncurredCostsFromStudy = (studyId: number) =>
    axiosInstance.get<IncurredCostWithUsers[]>(
        `/api/incurredCosts/study/${studyId}`
    ).catch(handleError);

/**
 * Gets an incurred cost by id
 */
export const getIncurredCost = (id: number) =>
    axiosInstance.get<IncurredCost>(
        `/api/incurredCosts/${id}`
    ).catch(handleError);

/**
 * Gets an IncurredCostWithUsers by IncurredCost id
 */
export const getUserIncurredCost = (id: number) =>
    axiosInstance.get<IncurredCostWithUsers>(
        `/api/incurredCosts/user/${id}`
    ).catch(handleError);

/**
 * Creates an incurred cost
 */
export const createIncurredCost = (incurredCost: IncurredCost) =>
    axiosInstance.post<IncurredCost>(
        '/api/incurredCosts', incurredCost
    ).catch(handleError);

/**
 * Creates an incurred cost from a planned cost
 */
export const createIncurredFromPlannedCost = (plannedCostId: number) =>
    axiosInstance.post<IncurredCost>(
        `/api/incurredCosts/${plannedCostId}`
    ).catch(handleError);

/**
 * Updates an incurred cost
 */
export const updateIncurredCost = (incurredCost: IncurredCost) =>
    axiosInstance.put<IncurredCost>(
        '/api/incurredCosts', incurredCost
    ).catch(handleError);

/**
 * Deletes an incurred cost
 */
export const deleteIncurredCost = (id: number) => 
    axiosInstance.delete(
        `/api/incurredCosts/${id}`
    ).catch(handleError);
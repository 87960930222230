interface State {
    objects: any[];
    rowsPerPage: number;
    requestedPage: number;
    orderBy: string;
    totalElements: number;
    page: number;
}

export const initialStateOrderByDescription: State = {
    objects: [],
    rowsPerPage: 10,
    requestedPage: 0,
    orderBy: 'description',
    totalElements: 0,
    page: 0
};

export const initialStateOrderByName: State = {
    objects: [],
    rowsPerPage: 10,
    requestedPage: 0,
    orderBy: 'name',
    totalElements: 0,
    page: 0
};

interface Action {
    type: 'objectPage' | 'requestedPage' | 'rowsPerPage' | 'orderBy';
    payload?: any;
}

export const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'objectPage':
            return {
                ...state,
                objects: action.payload.list,
                totalElements: action.payload.total,
                page: action.payload.page
            }
        case 'requestedPage':
            return {
                ...state,
                requestedPage: action.payload
            }
        case 'rowsPerPage':
            return {
                ...state,
                rowsPerPage: action.payload,
                page: 0,
                requestedPage: 0
            }
        case 'orderBy':
            return {
                ...state,
                orderBy: action.payload
            }
        default:
            return state;
    }
};
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Checkbox, FormControl, FormControlLabel, FormGroup, Tooltip } from '@material-ui/core';

import { DivisionTree, StudyListDetail } from '../../../@types';
import { getDivisionTreeIds } from '../../../common/Utils/utils';
import { DisplayTextFormat } from '../../../common/Utils/DisplayTextFormat';
import { studyStatusAndStyle } from '../Details/Overview/StudyOverviewDetails';
import { handleErrorResponse } from '../../../service/utils';
import { getRestrictedStudies } from '../../../service/Study/study';

import SearchBar from '../../../common/SearchBar/SearchBar';
import EditablePagingTable, { TableColumn } from '../../../common/DataTable/EditablePagingTable';
import FilterSortButton from '../../../common/FilterSortButton/FilterSortButton';
import FilterByDivision from '../../Invoicing/Invoice/FilterByDivision';
import Loading from '../../../common/Routes/Loading';


interface TableData {
    id: number | null;
    study: JSX.Element;
    status: JSX.Element;
    started: Date | undefined;
    lastPatientVisitDate: Date | undefined;
    totalPatients: number;
    totalVisits: number;
    totalRevenue: number;
    totalCost: number;
    totalProductivity: number;
}

const columns: TableColumn[] = [{
    type: 'study',
    displayValue: 'Study',
    align: 'left',
    format: 'jsx-element',
    style: { minWidth: 250 },
}, {
    type: 'status',
    displayValue: 'Status',
    align: 'center',
    format: 'jsx-element',
}, {
    type: 'started',
    displayValue: 'Started',
    align: 'center',
    format: 'date',
}, {
    type: 'lastPatientVisitDate',
    displayValue: 'Last Visit Date',
    align: 'center',
    format: 'date',
}, {
    type: 'totalPatients',
    displayValue: 'Total Patients',
    align: 'right',
    format: 'integer',
}, {
    type: 'totalVisits',
    displayValue: 'Total Visits',
    align: 'right',
    format: 'integer',
}, {
    type: 'totalCost',
    displayValue: 'Total Expense',
    align: 'right',
    format: 'currency-US',
}, {
    type: 'totalRevenue',
    displayValue: 'Total Revenue',
    align: 'right',
    format: 'currency-US',
}, {
    type: 'totalProductivity',
    displayValue: 'Total Productivity',
    align: 'right',
    format: 'currency-US',
}];

interface State {
    objects: StudyListDetail[];
    filters: {
        notStarted: boolean;
        started: boolean;
        completed: boolean;
    }
    division?: number[];
}

const initialState: State = {
    objects: [],
    filters: {
        notStarted: true,
        started: true,
        completed: true
    },
}

interface Action {
    type: 'objectPage' | 'filters' | 'division';
    payload?: any;
}

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'objectPage':
            return {
                ...state,
                objects: action.payload.list,
                // totalElements: action.payload.total,
            } as State;
        case 'filters':
            return {
                ...state,
                filters: action.payload,
            } as State;
        case 'division':
            return {
                ...state,
                division: action.payload,
            } as State;
        default:
            return state;
    }
}

const StudyList: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [studyList, setStudyList] = React.useState<StudyListDetail[]>([]);
    const [query, setQuery] = React.useState<string>('');
    const [state, dispatchPage] = React.useReducer(reducer, initialState);
    const { objects, filters, division } = state;
    const [doneLoading, setDoneLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        getRestrictedStudies()
            .then(res => {
                setStudyList(res.data);
                dispatchPage({ type: 'objectPage', payload: { list: res.data, page: 0, total: res.data.length } });
                setDoneLoading(true);
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve list of Studies: '
                });
            });
    }, []);

    // Effect hook for sort, filter, and search
    React.useEffect(() => {
        if (studyList.length > 0) {
            let filteredList: StudyListDetail[] = studyList;
            if (division && division.length != 0) {
                filteredList = filteredList.filter(study =>  {
                    return division.includes(study.divisionId);
                });
            }
            filteredList = filteredList.filter(study =>
                (filters.notStarted && !study.started) ||
                (filters.started && !!study.started && !study.completed) ||
                (filters.completed && !!study.completed)
            );
            if (Number(query) !== 0) {
                filteredList = filteredList.filter(study =>
                    study.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                    study.identifier.toLowerCase().indexOf(query.toLowerCase()) !== -1
                );
            }
            dispatchPage({
                type: 'objectPage',
                payload: { list: filteredList }
            });
        }
    }, [studyList, filters, query, division]);

    const handleChangeFilters = (notStarted: boolean, started: boolean, completed: boolean) => {
        dispatchPage({ type: 'filters', payload: { notStarted: notStarted, started: started, completed: completed } });
    };

    const handleChangeDivision = (div: DivisionTree): void => {
        dispatchPage({ type: 'division', payload: getDivisionTreeIds(div) });
    };

    const moreInfoHover = (obj: StudyListDetail) => {
        return (
            <div className="column tip-padding">
                <span> Identifier: {DisplayTextFormat(obj.identifier, 12, false)} </span>
                <span> IRB #: {obj.irbNo} </span>
                <span> Study #: {obj.studyNo} </span>
                <span> Division: {obj.divisionName} </span>
                <span> Status: {studyStatusAndStyle(obj)[0]} </span>
            </div>
        )
    }

    const convertTableData = (data: StudyListDetail[]) => {
        const newData: TableData[] = [];
        data.forEach(obj => {
            const identifiers = (
                <Tooltip arrow title={moreInfoHover(obj)}>
                    <div className='double-line-text'>
                        <div className='title sort-field'>
                            {obj.name} ({obj.identifier})
                        </div>
                        <div className='subtitle'>
                            {obj.divisionName}
                        </div>
                    </div>
                </Tooltip>
            )
            const statusChip = (
                <div className={studyStatusAndStyle(obj)[1] + ' sort-field'}>
                    {studyStatusAndStyle(obj)[0]}
                </div>
            )
            newData.push({
                id: obj.id,
                study: identifiers,
                status: statusChip,
                started: obj.started,
                lastPatientVisitDate: obj.lastPatientVisitDate,
                totalPatients: obj.totalPatients,
                totalVisits: obj.totalVisits,
                totalRevenue: obj.totalRevenue,
                totalCost: obj.totalCost,
                totalProductivity: obj.totalProductivity,
            })
        })
        return newData;
    }

    const handleRowClick = (id: number) => {
        history.push(`/study/${id}/overview`)
    }

    if (doneLoading) {
        return (
            <div className="page">
                <div className="page-content">
                    <div className="page-heading aligned-row">
                        <div className="left">
                            <SearchBar query={query} setQuery={setQuery} />
                            <FilterSortButton
                                sections={[{
                                    title: 'Filter By Status',
                                    tooltip: 'Filter by studies that have not been started, been started and completed.',
                                    body: (
                                        <FormControl>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<Checkbox checked={filters.notStarted} onChange={() => handleChangeFilters(!filters.notStarted, filters.started, filters.completed)} name="notStarted" />}
                                                    label="Not Started"
                                                />
                                                <FormControlLabel
                                                    control={<Checkbox checked={filters.started} onChange={() => handleChangeFilters(filters.notStarted, !filters.started, filters.completed)} name="started" />}
                                                    label="Started"
                                                />
                                                <FormControlLabel
                                                    control={<Checkbox checked={filters.completed} onChange={() => handleChangeFilters(filters.notStarted, filters.started, !filters.completed)} name="completed" />}
                                                    label="Completed"
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    )
                                }, {
                                    title: 'Filter By Division',
                                    tooltip: 'Filter studies by division or sub-division. Select upper "All Divisions" option to show all studies without division filter.',
                                    body: (
                                        <FilterByDivision
                                            setChosenDivision={handleChangeDivision}
                                            withinCollapsiblePanel={false}
                                            restricted={true}
                                            withinMenu
                                        />
                                    )
                                }]}
                            />
                        </div>
                    </div>
                    <div className="page-body max-xl no-ma">
                        <EditablePagingTable
                            dataList={convertTableData(objects)}
                            tableInfoColumns={columns}
                            initialSortType='study'
                            readonly={true}
                            canCreate={false}
                            mode='full'
                            rowClick={handleRowClick}
                            height='76vh'
                            borderedCells={false}
                        />
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <Loading />
        )
    }
}

export default StudyList;
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WbCloudy, WbSunny } from '@material-ui/icons'; 

import { handleErrorResponse } from '../../../../../service/utils';
import { getProductivityBySimulator } from '../../../../../service/Simulator/simulator';
import { DEFAULT_SUMMARY_DETAILS, handleSummaryDetailString } from '../../../Utils';
import { Store } from '../../../../../modules/rootReducer';
import { SimulatorState } from '../../../../../modules/simulatorReducer';

import SummaryCard from './SummaryCard';


interface Props {
    simulatorId: number;
    year: number;
}
const YearlyProductivityCard: React.FC<Props> = props => {
    const { simulatorId, year } = props;
    
    const dispatch = useDispatch();

    const { updated } = useSelector<Store, SimulatorState>(
        store => store.simulatorReducer
    );

    const [value, setValue] = React.useState<number>(0);
    const [details, setDetails] = React.useState<string>(DEFAULT_SUMMARY_DETAILS);

    React.useEffect(() => {
        getProductivityBySimulator(simulatorId, year)
            .then(res => {
                setValue(res.data.currencyValue);
                setDetails(handleSummaryDetailString(res.data));
            })
            .catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve yearly Productivity sum: '
                })
            });
    }, [simulatorId, year, updated]);

    return (
        <SummaryCard
            title="Productivity (Fiscal Year)"
            value={value}
            currency
            details={details}
            icon={(value < 0)
                ? <WbCloudy />
                : <WbSunny />}
        />
    );
}

export default YearlyProductivityCard;
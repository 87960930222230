import * as React from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import {
    Button, Card, CardContent, CardHeader, FormControl, FormControlLabel,
    IconButton, Grid, Radio, RadioGroup, Typography,
} from '@material-ui/core';
import { UnfoldLess, UnfoldMore, } from '@material-ui/icons';

import { Simulator, SimulatorResource, SimulatorUserWithSalaryLogs } from '../../../../@types';
import { showSuccessSnackbar } from '../../../../modules/messageSnackbarReducer';
import { simulatorUserWithSalaryLogsToLocalDate } from '../../../../common/Utils/utils';
import { handleErrorResponse } from '../../../../service/utils';
import {
    getDetailedEmployees, getSimulatorResources, setEmployeeGeneric,
    updateSimulatorResource, updateSimulator, getAverageFringe, getAverageSalary
} from '../../../../service/Simulator/simulator';
import { updateSimulatorCharts } from '../../../../modules/simulatorReducer';

import EmployeeDetail from './Employee/EmployeeDetail';
import ExistingPatientDetail from './Patient/ExistingPatientDetail';
import IntricateSlider from '../../../../common/Slider/IntricateSlider';
import usePermissions from '../../../../common/hooks/usePermissions';
import { NumberFormatter } from '../../../../common/Utils/NumberFormatter';
import Loading from '../../../../common/Routes/Loading';

interface Props {
    simulator: Simulator;
    expanded: boolean;
    setExpanded: any;
    updateParent: () => void;
}
const Variables: React.FC<Props> = props => {
    const { simulator, expanded, setExpanded, updateParent } = props;

    const dispatch = useDispatch();

    const canViewDetailed = usePermissions('API_SIMULATOR_GET_SIMULATOR_USERS_WITH_SALARY_LOGS');

    const [updateRequired, setUpdateRequired] = React.useState<boolean>(false);
    const [generic, setGeneric] = React.useState<boolean>(simulator.employeeGeneric);
    const [updated, setUpdated] = React.useState<boolean>(false);
    const [doneLoading, setDoneLoading] = React.useState<boolean>(false);
    const [updating, setUpdating] = React.useState<boolean>(false);

    const [genericCoordinatorsInView, setGenericCoordinatorsInView] = React.useState<boolean>(true);
    const [variableType, setVariableType] = React.useState<'employees' | 'patients'>('employees');
    const [detailedEmployees, setDetailedEmployees] = React.useState<SimulatorUserWithSalaryLogs[]>([]);
    const [averageCoordinatorSalary, setAverageCoordinatorSalary] = React.useState<number>(0);
    const [averageCoordinatorFringe, setAverageCoordinatorFringe] = React.useState<number>(0);
    const [averageProviderSalary, setAverageProviderSalary] = React.useState<number>(0);
    const [averageProviderFringe, setAverageProviderFringe] = React.useState<number>(0);

    const [initSliderVals, setInitSliderVals] = React.useState<{ coordinator: SimulatorResource, provider: SimulatorResource, patient: any }>({
        coordinator: {
            id: 0,
            simulatorId: Number(simulator.id),
            resourceType: 'Coordinator',
            count: 5,
            salary: 50000,
            fringe: 10,
            percentageEffort: 50,
        },
        provider: {
            id: 0,
            simulatorId: Number(simulator.id),
            resourceType: 'Provider',
            count: 5,
            salary: 50000,
            fringe: 10,
            percentageEffort: 50,
        },
        patient: {
            patientCount: simulator.patientCount ?? 0,
            enrollmentDuration: simulator.enrollmentDuration ?? 1,
            visitCoverage: simulator.visitCoverage ?? 0,
        }
    });
    const [genericCoordinatorValues, setGenericCoordinatorValues] = React.useState<SimulatorResource>({
        id: 0,
        simulatorId: Number(simulator.id),
        resourceType: 'C',
        count: 0,
        salary: 0,
        fringe: 0,
        percentageEffort: 0,
    });
    const [genericProviderValues, setGenericProviderValues] = React.useState<SimulatorResource>({
        id: 0,
        simulatorId: Number(simulator.id),
        resourceType: 'P',
        count: 0,
        salary: 0,
        fringe: 0,
        percentageEffort: 0,
    });
    const [genericPatientValues, setGenericPatientValues] = React.useState({
        patientCount: simulator.patientCount ?? 0,
        enrollmentDuration: simulator.enrollmentDuration ?? 1,
        visitCoverage: simulator.visitCoverage ?? 0,
    });

    const initiallyLoaded = React.useRef(false);
    React.useEffect(() => {
        setEmployeeGeneric(Number(simulator.id), generic)
            .then(() => {
                dispatch(updateSimulatorCharts())
                getSimulatorResources(Number(simulator.id))
                    .then(res => {
                        if (res.data.length > 1) {
                            const coordinatorVals = res.data.filter(o => o.resourceType === 'Coordinator')[0];
                            const providerVals = res.data.filter(o => o.resourceType === 'Provider')[0];
                            setGenericCoordinatorValues(coordinatorVals);
                            setGenericProviderValues(providerVals);
                            setInitSliderVals({
                                ...initSliderVals,
                                coordinator: coordinatorVals,
                                provider: providerVals
                            })
                            initiallyLoaded.current = true;
                        }
                        if (generic) {
                            setDoneLoading(true);
                        }
                    }).catch(err => {
                        handleErrorResponse(err, dispatch, {
                            prefix: 'Could not get generic simulator resource values: '
                        })
                    });
                getDetailedEmployees(Number(simulator.id))
                    .then(res => {
                        setDetailedEmployees(res.data.map(employee => simulatorUserWithSalaryLogsToLocalDate(employee)));
                        if (!generic) {
                            setDoneLoading(true);
                        }
                    }).catch(err => {
                        handleErrorResponse(err, dispatch, {
                            prefix: 'Could not get detailed simulator employees: '
                        })
                    });
            });

        getAverageSalary(Number(simulator.id), 'Coordinator').then(res => {
            setAverageCoordinatorSalary(res.data);
        }).catch(err => {
            handleErrorResponse(err, dispatch, {
                prefix: 'Could not get average coordinator salary: '
            })
        });

        getAverageSalary(Number(simulator.id), 'Provider').then(res => {
            setAverageProviderSalary(res.data);
        }).catch(err => {
            handleErrorResponse(err, dispatch, {
                prefix: 'Could not get average provider salary: '
            })
        });

        getAverageFringe(Number(simulator.id), 'Coordinator').then(res => {
            setAverageCoordinatorFringe(res.data);
        }).catch(err => {
            handleErrorResponse(err, dispatch, {
                prefix: 'Could not get average coordinator fringe: '
            })
        });

        getAverageFringe(Number(simulator.id), 'Provider').then(res => {
            setAverageProviderFringe(res.data);
        }).catch(err => {
            handleErrorResponse(err, dispatch, {
                prefix: 'Could not get average provider fringe: '
            })
        });
    }, [updated]);

    React.useEffect(() => {
        setEmployeeGeneric(Number(simulator.id), generic)
            .then(() => dispatch(updateSimulatorCharts()));
    }, [generic]);

    React.useLayoutEffect(() => {
        if (!_.isEqual(initSliderVals.patient, genericPatientValues)) {
            setUpdateRequired(true);
            return;
        }
        if (generic) {
            if (initSliderVals.coordinator.id && !_.isEqual(initSliderVals.coordinator, genericCoordinatorValues)) {
                setUpdateRequired(true);
                return;
            }
            if (initSliderVals.provider.id && !_.isEqual(initSliderVals.provider, genericProviderValues)) {
                setUpdateRequired(true);
                return;
            }
        }
        setUpdateRequired(false);
    }, [initiallyLoaded, genericCoordinatorValues, genericProviderValues, genericPatientValues]);

    const updateSimulatorVariables = () => {
        if (updating) return;

        setUpdating(true);
        simulator.patientCount = genericPatientValues.patientCount;
        simulator.enrollmentDuration = genericPatientValues.enrollmentDuration;
        simulator.visitCoverage = genericPatientValues.visitCoverage;

        updateSimulator(simulator, true)
            .then(() => {
                if (generic) {
                    updateSimulatorResource(genericProviderValues, false)
                        .then(providerResponse => {
                            updateSimulatorResource(genericCoordinatorValues, true)
                                .then(coordinatorResponse => {
                                    setUpdating(false);
                                    setUpdateRequired(false);
                                    dispatch(updateSimulatorCharts());
                                    dispatch(showSuccessSnackbar('Simulation Updated'));
                                    updateParent();
                                    setInitSliderVals({
                                        ...initSliderVals,
                                        coordinator: coordinatorResponse.data,
                                        provider: providerResponse.data
                                    })
                                })
                                .catch(err => {
                                    setUpdating(false);
                                    handleErrorResponse(err, dispatch, {
                                        prefix: 'Could not update simulator with coordinator slider values: '
                                    })
                                });
                        })
                        .catch(err => {
                            setUpdating(false);
                            handleErrorResponse(err, dispatch, {
                                prefix: 'Could not update simulator with provider slider values: '
                            })
                        });
                } else {
                    setUpdating(false);
                    setUpdateRequired(false);
                    dispatch(updateSimulatorCharts());
                    dispatch(showSuccessSnackbar('Simulation Updated'));
                    updateParent();
                }
            })
            .catch(err => {
                setUpdating(false);
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not update simulator with patient slider values: '
                })
            });
    }

    const employeeSliders = (isCoordinator: boolean, values: SimulatorResource, setValues: any) => {
        return (<>
            <IntricateSlider
                value={values.count}
                setValue={(value: number) => setValues({ ...values, count: value })}
                title={isCoordinator ? 'Totaly Coordinators' : 'Total Providers'}
                defaultValue={5} min={0} max={10} integer
            />
            <IntricateSlider
                value={values.salary}
                setValue={(value: number) => setValues({ ...values, salary: value })}
                title='Salary'
                defaultValue={100000} min={0} max={200000}
                start='$' width={65} integer maxLength={9}
                subtext={<>
                    <div className='subtext'>
                        <Typography>{isCoordinator ? 'Average Coordinator Salary:' : 'Average Provider Salary:'}</Typography>
                        <Typography>
                            <NumberFormatter value={isCoordinator ? averageCoordinatorSalary : averageProviderSalary} currency />
                        </Typography>
                    </div>
                </>}
            />
            <IntricateSlider
                value={values.fringe}
                setValue={(value: number) => setValues({ ...values, fringe: value })}
                title='Fringe Benefits'
                defaultValue={10} min={0} max={100}
                end='%' width={50} maxLength={6}
                subtext={<>
                    <div className='subtext'>
                        <Typography>{isCoordinator ? 'Average Coordinator Fringe:' : 'Average Provider Fringe:'}</Typography>
                        <Typography>
                            <NumberFormatter value={isCoordinator ? averageCoordinatorFringe : averageProviderFringe} suffix='%' />
                        </Typography>
                    </div>
                </>}
            />
            <IntricateSlider
                value={values.percentageEffort}
                setValue={(value: number) => setValues({ ...values, percentageEffort: value })}
                title='Study Effort Reporting'
                defaultValue={50} min={0} max={100}
                end='%' integer
                className='no-border'
            />
        </>)
    }

    const patientSliders = (values: any, setValues: any) => {
        return (<>
            <IntricateSlider
                value={values.patientCount}
                setValue={(value: number) => setValues({ ...values, patientCount: value })}
                title='Patients (Per Year)'
                titleTooltip='How many patients will be recruited over a 12 month period?'
                defaultValue={50} min={0} max={200}
                width={50} maxLength={6} integer
                subtext={<>
                    <div className='subtext'>
                        <Typography>Minimum Value:</Typography>
                        <Typography>{simulator.optimalPatientCount ? simulator.optimalPatientCount + ' Patients' : 'Unable To Retrieve'}</Typography>
                    </div>
                    <div className='subtext'>
                        <Typography>Total Patients:</Typography>
                        <Typography>{Math.ceil(values.patientCount * values.enrollmentDuration / 12)} Patients</Typography>
                    </div>
                </>}
            />
            <IntricateSlider
                value={values.enrollmentDuration}
                setValue={(value: number) => setValues({ ...values, enrollmentDuration: value })}
                title='Time Period of Enrollment'
                titleTooltip='How many months before the study is capped and no more patients can be recruited?'
                defaultValue={12} min={0} max={120}
                end='Months' width={40} maxLength={5} integer
                subtext={<>
                    <div className='subtext'>
                        <Typography>Total Years:</Typography>
                        <Typography>{Math.round(values.enrollmentDuration / 12 * 100) / 100}</Typography>
                    </div>
                </>}
            />
            <IntricateSlider
                value={values.visitCoverage}
                setValue={(value: number) => setValues({ ...values, visitCoverage: value })}
                title='Visit Coverage'
                defaultValue={90} min={0} max={100}
                end='%' width={50} maxLength={6}
                className='no-border'
            />
        </>)
    }

    const collapsedEmployeeVariables = () => {
        if (generic) {
            return (<>
                <div className='multi-header'>
                    <div
                        onClick={() => setGenericCoordinatorsInView(true)}
                        className={genericCoordinatorsInView ? 'section-header' : 'section-header inactive'}
                    >
                        Coordinators
                    </div>
                    <div
                        onClick={() => setGenericCoordinatorsInView(false)}
                        className={genericCoordinatorsInView ? 'section-header inactive' : 'section-header'}
                    >
                        Providers
                    </div>
                </div>
                {genericCoordinatorsInView ?
                    employeeSliders(true, genericCoordinatorValues, setGenericCoordinatorValues) :
                    employeeSliders(false, genericProviderValues, setGenericProviderValues)
                }
            </>)
        } else {
            const coordinators = detailedEmployees.filter(e => e.simulatorUser.resourceType == 'Coordinator');
            const providers = detailedEmployees.filter(e => e.simulatorUser.resourceType == 'Provider');
            return (<>
                <div className='section-header'>Employees</div>
                <div className="detailed-employees-collapsed">
                    <span>Coordinators</span>
                    {coordinators.length > 0 ?
                        coordinators.map((obj, index) =>
                            <li key={'detailed-coordinator-' + index}>{obj.simulatorUser.userName}</li>
                        ) : <li>No Coordinators</li>
                    }
                </div>
                <div className="detailed-employees-collapsed" style={{ paddingBottom: 10 }}>
                    <span>Providers</span>
                    {providers.length > 0 ?
                        providers.map((obj, index) =>
                            <li key={'detailed-provider-' + index}>{obj.simulatorUser.userName}</li>
                        ) : <li>No Providers</li>
                    }
                </div>
            </>)
        }
    }

    const expandedVariables = () => {
        if (variableType === 'employees') {
            return (<>
                <div className='flex-center'>
                    <Card className='employee-simulation-mode full-width'>
                        <CardHeader title='Employee Simulation Mode' />
                        <div className='content flex-center'>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    name="empSim"
                                    value={generic ? 'generic' : 'detailed'}
                                    onChange={((_event: any, value: any) => setGeneric(value == 'generic' ? true : false))}
                                >
                                    <FormControlLabel value='generic' control={<Radio />}
                                        label="Generic (Individual Employees Not Specified)"
                                    />
                                    <FormControlLabel value='detailed' control={<Radio />}
                                        label="Detailed (Specify Employees and Details)"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </Card>
                </div>
                {generic ?
                    <Card style={{ margin: 10 }}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={6} md={12} lg={6}>
                                <div className='section-header standalone'>
                                    Coordinators
                                </div>
                                {employeeSliders(true, genericCoordinatorValues, setGenericCoordinatorValues)}
                            </Grid>
                            <Grid item xs={12} sm={6} md={12} lg={6}>
                                <div className='section-header standalone'>
                                    Providers
                                </div>
                                {employeeSliders(false, genericProviderValues, setGenericProviderValues)}
                            </Grid>
                        </Grid>
                    </Card> :
                    <EmployeeDetail
                        simulator={simulator}
                        employees={detailedEmployees}
                        canView={canViewDetailed}
                        setUpdated={() => setUpdated(!updated)}
                    />
                }
            </>)
        }
        if (variableType === 'patients') {
            return (<>
                <div style={{ margin: 10 }}>
                    <Typography variant='subtitle1'>
                        Generic Simulation Variables
                    </Typography>
                    <Card>
                        {patientSliders(genericPatientValues, setGenericPatientValues)}
                    </Card>
                </div>
                <div style={{ margin: 10 }}>
                    <Typography variant='subtitle1'>
                        Existing Patients
                    </Typography>
                    <ExistingPatientDetail simulatorId={Number(simulator.id)} />
                </div>
            </>)
        }
        return <div />;
    }

    if (expanded) {
        return (
            <div style={{ position: 'relative', minWidth: '100%' }} className='transition'>
                <Card className='variables expanded'>
                    <IconButton size='small' onClick={() => setExpanded(false)} className='card-corner-button'>
                        <UnfoldLess style={{ transform: 'rotate(45deg)' }} />
                    </IconButton>
                    <CardHeader title='Variables' />
                    <div className='space-between features' style={{ zIndex: 1 }}>
                        <div className='tabs'>
                            <Button onClick={() => setVariableType('employees')} disabled={variableType === 'employees'}>
                                Employees
                            </Button>
                            <Button onClick={() => setVariableType('patients')} disabled={variableType === 'patients'}>
                                Patients
                            </Button>
                        </div>
                        <Button
                            variant='contained'
                            color='primary'
                            disabled={!updateRequired || updating}
                            className={'update-simulator ' + (updateRequired && !updating ? 'ready' : '')}
                            onClick={() => { if (updateRequired) updateSimulatorVariables() }}
                        >
                            {updating ? 'Updating' : 'Update'}
                        </Button>
                    </div>
                    <CardContent>
                        {expandedVariables()}
                    </CardContent>
                </Card>
            </div >
        )
    } else {
        return (
            <div style={{ position: 'relative', minWidth: '100%' }} className='transition'>
                <Card className='variables'>
                    {doneLoading ?
                        <>
                            <IconButton size='small' onClick={() => setExpanded(true)} className='card-corner-button'>
                                <UnfoldMore style={{ transform: 'rotate(45deg)' }} />
                            </IconButton>
                            <CardHeader title='Variables' />
                            <div className='button-container'>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    disabled={!updateRequired || updating}
                                    className={'update-simulator ' + (updateRequired && !updating ? 'ready' : '')}
                                    onClick={() => { if (updateRequired) updateSimulatorVariables() }}
                                >
                                    {updating ? 'Updating' : 'Update'}
                                </Button>
                            </div>
                            <CardContent>
                                {collapsedEmployeeVariables()}
                                <div className='section-header'>Patients</div>
                                {patientSliders(genericPatientValues, setGenericPatientValues)}
                            </CardContent>
                        </> :
                        <Loading />
                    }
                </Card>
            </div>
        )
    }
}

export default Variables;
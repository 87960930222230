import * as React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import * as yup from 'yup';
import {
    Typography
} from '@material-ui/core';

import { requestPasswordReset } from '../../../service/Management/users';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../modules/messageSnackbarReducer';
import GridWrapper from '../../../common/GridWrapper/GridWrapper';
import { AxiosError } from 'axios';
import { Formik, FormikHelpers } from 'formik';
import { BForm, BPassword, BSubmit, BTextField } from 'mui-bueno';
import { PasswordResetRequest } from '../../../@types';
import { handleEditErrors } from '../../../service/utils';

interface ForgotPasswordFormFields extends PasswordResetRequest {
    confirmNewPassword: string;
}

const schema = yup.object<ForgotPasswordFormFields>().shape({
    email: yup.string().email('Must be in email format').required('Email is required'),
    resetVerificationCode: yup.string(),
    newPassword: yup.string(),
    confirmNewPassword: yup.string().oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

const ForgotPassword: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [showCodeAndPaswordFields, setShowCodeAndPasswordFields] = React.useState<boolean>(false);

    const initialValues: ForgotPasswordFormFields = {
        email: '',
        resetVerificationCode: '',
        newPassword: '',
        confirmNewPassword: '',
    }

    const handleSubmit = async (data: ForgotPasswordFormFields, { setErrors }: FormikHelpers<ForgotPasswordFormFields>) => {
        setErrors({});
        schema.validate(data, { abortEarly: false })
            .then(() => {
                requestPasswordReset({
                    email: data.email,
                    resetVerificationCode: data.resetVerificationCode,
                    newPassword: data.newPassword,
                } as PasswordResetRequest).then(res => {
                    if (res.status === 202) {
                        setShowCodeAndPasswordFields(true);
                        return;
                    }
                    history.push('/login');
                    dispatch(showSuccessSnackbar('Password has been reset successfully.'));
                })
                    .catch((err: AxiosError) => {
                        if (err.response) {
                            if (err.response.status === 404) {
                                setErrors({email: 'Email not found'});
                            } else if (err.response.status === 422) {
                                handleEditErrors(err, setErrors);
                            } else if (err.response.status === 400) {
                                const index = err.response.data.message.indexOf('(');
                                const message = err.response.data.message.substring(0, index-1);
                                if (err.response.data.message === 'Too many requests. Try again later.') {
                                    dispatch(showErrorSnackbar('Too many requests. Try again later.'));
                                } else if (err.response.data.message === 'The code you have entered has expired.') {
                                    setErrors({resetVerificationCode: ''});
                                    dispatch(showErrorSnackbar('The code you have entered has expired. Please request a new code by clicking the login button followed by the forgot password button.'))
                                } else if (message === 'User does not exist.') {
                                    // Indicative that the service and cognito are no longer in sync
                                    // Indicates that our service has the user but Cognito doesn't
                                    dispatch(showErrorSnackbar(message));
                                } else if (message === 'User password cannot be reset in the current state.') {
                                    // Case where you create a user and then click forgot password for that user without resetting password
                                    setErrors({email: 'The password for this email has not yet been reset. Check the email for a registration email or contact an administrator to resend it.'})
                                } else if (message === 'User is disabled.') {
                                    setErrors({email: 'The user associated with this email has been deactivated. Please contact your system/institution administrator for more information.'});
                                } else if (message === 'Invalid verification code provided, please try again.') {
                                    setErrors({resetVerificationCode: message});
                                } else {
                                    dispatch(showErrorSnackbar('Unexpected error. Try again later.'));
                                }
                            }
                        }
                    })
            }).catch((err: yup.ValidationError) => {
                const errors: { [key: string]: string } = {};
                for (const e of err.inner) {
                    errors[e.path!] = e.message
                }
                setErrors(errors)
            });
    };
    
    return (
        <GridWrapper>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
            >
                <BForm>
                    <Typography className="card-heading">Forgot Password</Typography>
                    <BTextField
                        name="email"
                        label="Email"
                        noMP
                        required
                    />
                    { showCodeAndPaswordFields && 
                        <>
                            <Typography variant={'body2'}>Enter the verification code sent to the email above along with the desired new password.</Typography>
                            <BPassword
                                name="newPassword"
                                label="New Password"
                                noMP
                                required
                            />
                            <BPassword
                                name="confirmNewPassword"
                                label="Confirm New Password"
                                noMP
                                required
                            />
                            <BTextField
                                name="resetVerificationCode"
                                label="Verification Code"
                                noMP
                                required
                            />
                        </>
                    }
                    <BSubmit
                        name="login-submit" 
                        xs={12} 
                        noMP 
                        gridStyle={{marginTop: '12px', marginBottom: '0px'}}
                    >
                        Reset Password
                    </BSubmit>
                </BForm>
            </Formik>
        </GridWrapper>
    );
};

export default ForgotPassword;

import * as React from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import {
    Card, CardContent, CardHeader, Chip, FormControl, ListItemIcon,
    MenuItem, OutlinedInput, Select, Tooltip, Typography
} from '@material-ui/core';
import { Check, Delete, HelpOutline, NavigateNext, Save } from '@material-ui/icons';
import { BButton, BError, BForm, BGrid, BSubmit, BTextField } from 'mui-bueno';

import { Permission, PermissionList, Role } from '../../../@types';
import { createRole, deleteRole, getRole, updateRole } from '../../../service/Management/roles';
import { getPermissionList } from '../../../service/Management/permissions';
import { showSuccessSnackbar } from '../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../service/utils';
import { DisplayTextFormat } from '../../../common/Utils/DisplayTextFormat';
import usePermissions from '../../../common/hooks/usePermissions';


const roleSchema = yup.object<Role>().shape({
    name: yup.string().required('Name is required'),
    description: yup.string().required('Description is required')
});

interface RouteParams {
    id: string; // Either the id or 'new'
}

const RoleDetail: React.FC = () => {
    const { id } = useParams<RouteParams>();

    const history = useHistory();
    const dispatch = useDispatch();

    const permissionGetRole: boolean = usePermissions('API_ROLE_GET_BY_ID');
    const permissionCreateRole: boolean = usePermissions('API_ROLE_UPDATE');
    const permissionUpdateRole: boolean = usePermissions('API_ROLE_CREATE');
    const permissionDeleteRole: boolean = usePermissions('API_ROLE_DELETE_BY_ID');
    const permissionGetPermissionList: boolean = usePermissions('API_PERMISSION_GET_PERMISSION_LIST');

    const [role, setRole] = React.useState<Role>({
        id: undefined,
        name: '',
        description: '',
        permissionIds: [],
        userIds: []
    });
    const [permissionListItems, setPermissionListItems] = React.useState<PermissionList[]>([]);
    const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false);
    const [formChanged, setformChanged] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (id && id != 'new') {
            if (permissionGetRole)
                getRole(+id)
                    .then(res => {
                        setRole(res.data);
                    }).catch(err => {
                        handleErrorResponse(err, dispatch, {
                            prefix: 'Could not retrieve Role: '
                        });
                    });
        }

        if (permissionGetPermissionList)
            getPermissionList()
                .then(res => {
                    const tempPermissions: PermissionList[] = [];
                    res.data.forEach(permission => {
                        tempPermissions.push({
                            name: permission.name,
                            permissions: permission.permissions
                        });
                    });
                    setPermissionListItems(tempPermissions);
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not retrieve list of Permissions: '
                    });
                });
    }, [id]);

    const handleSubmitRole = async (data: Role, { setErrors }: FormikHelpers<Role>) => {
        if (role.id) {
            if (permissionUpdateRole)
                updateRole(data)
                    .then(res => {
                        setformChanged(false);
                        setRole(res.data);
                        dispatch(showSuccessSnackbar(`${res.data.name} updated`));
                    }).catch(err => {
                        handleErrorResponse(err, dispatch, {
                            setStatus: setErrors,
                            prefix: 'Could not update Role: '
                        });
                    });
        } else {
            if (permissionCreateRole)
                createRole(data).then(res => {
                    setformChanged(false);
                    history.push(`/manage/role/${res.data.id}`);
                    dispatch(showSuccessSnackbar(`${res.data.name} created`));
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        setStatus: setErrors,
                        prefix: 'Could not create Role: '
                    });
                });
        }
    };

    const handleValidateRole = (data: Role) => {
        setRole(data);
        const errorList: { [k: string]: string } = {};
        roleSchema.validate(data, { abortEarly: false })
            .catch((err: yup.ValidationError) => {
                for (const e of err.inner) {
                    if (e.path) errorList[e.path] = e.message;
                }
            });
        setformChanged(true);
        return errorList;
    };

    const handleChangePermissionIds = (event: React.ChangeEvent<{ value: unknown }>) => {
        setRole({ ...role, permissionIds: (event.target.value as string[]).sort((a, b) => a.localeCompare(b)) });
        setformChanged(true);
    }

    const isDefaultRole: boolean = (role.name === 'System Administrator'
        || role.name === 'Division Administrator'
        || role.name === 'Coordinator'
        || role.name === 'Provider'
        || role.name === 'Contract Manager'
        || role.name === 'Accountant'
        || role.name === 'Division Member')

    const handleDelete = () => {
        setConfirmDelete(true);
    };

    const handleConfirmDelete = () => {
        deleteRole(role.id!)
            .then(() => {
                dispatch(showSuccessSnackbar(`${role.name} deleted`));
                history.push('/manage/role');
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not delete Role: '
                });
            });
    };

    const displayDelete = confirmDelete ?
        <BButton
            startIcon={<HelpOutline />}
            className={!permissionDeleteRole ? 'disabled' : 'delete-color'}
            onClick={handleConfirmDelete}
            disabled={!permissionDeleteRole}
        >
            Confirm Delete
        </BButton> :
        <BButton
            startIcon={<Delete />}
            className={!permissionDeleteRole ? 'disabled' : 'delete-color'}
            onClick={handleDelete}
            disabled={!permissionDeleteRole}
        >
            Delete
        </BButton>;

    const permissionSelect = (label: string, optionsList: Permission[]) => {
        return (
            <BGrid container key={`permission-dropdown-${label}`}>
                <BGrid xs={3} gridClassName="permission-field label">
                    {label}
                </BGrid>
                <BGrid xs={9} gridClassName="permission-field">
                    <FormControl margin="dense" variant="outlined" fullWidth>
                        <Select
                            multiple
                            name="permissionIds"
                            key={`permissionIds-${label}`}
                            value={role.permissionIds}
                            onChange={handleChangePermissionIds}
                            disabled={(id && !permissionUpdateRole) || (!!id && !permissionCreateRole)}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left'
                                },
                                getContentAnchorEl: null,
                                style: {
                                    maxHeight: '20em'
                                }
                            }}
                            input={<OutlinedInput name="permissionIds" id={`permission-select-${label}`} />}
                            renderValue={selected => (
                                <div className="chips">
                                    {(selected as string[]).map(value => {
                                        const option = optionsList.find(op => op.id === value);
                                        return (
                                            option &&
                                            <Tooltip
                                                key={`permission-chip-tooltip-${option.id}`}
                                                title={option.description}
                                                enterDelay={500}
                                                arrow
                                            >
                                                <Chip
                                                    label={option.id}
                                                    className="chip"
                                                />
                                            </Tooltip>
                                        );
                                    })}
                                </div>
                            )}
                        >
                            {optionsList.map(permission => (
                                <MenuItem
                                    key={`permission-chip-menuitem-${permission.id}`}
                                    value={permission.id}
                                >
                                    {role.permissionIds.find(permissionId => permissionId === permission.id) &&
                                        <ListItemIcon>
                                            <Check />
                                        </ListItemIcon>
                                    }
                                    {permission.id}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </BGrid>
            </BGrid>
        );
    };

    return (
        <>
            <Prompt
                when={formChanged}
                message='This page contains unsaved changes. Are you sure you wish to leave?'
            />
            <div className="page-heading">
                <Typography
                    className="title inactive cursor-pointer"
                    onClick={() => history.push('/manage/role')}
                >
                    Role Management
                </Typography>
                <NavigateNext className="arrow" />
                <Typography className="title">{DisplayTextFormat(role.name, 64)}</Typography>
            </div>
            <div className="page-body">
                <Formik
                    initialValues={role}
                    values={role}
                    onSubmit={handleSubmitRole}
                    validate={handleValidateRole}
                    enableReinitialize
                >
                    <BForm>
                        <BGrid container>
                            <BGrid xs={12} md={4}>
                                <Card className="detail-form short">
                                    <CardHeader title={role.id ? 'Update Role' : 'Add Role'} />
                                    <CardContent>
                                        <BError id="general" name="general" />
                                        <BTextField
                                            name="name"
                                            label="Name"
                                            placeholder="Name"
                                            disabled={(id && isDefaultRole) || (id && !permissionUpdateRole) || false}
                                            required
                                        />
                                        <BTextField
                                            name="description"
                                            label="Description"
                                            placeholder="Description"
                                            multiline rows={5}
                                            disabled={(id && !permissionUpdateRole) || false}
                                            required
                                        />
                                    </CardContent>
                                </Card>
                            </BGrid>
                            <BGrid xs={12} md={8}>
                                <Card className="detail-form short">
                                    <CardHeader title="Access Controls" />
                                    <CardContent>
                                        <BError id="permissionIds" name="permissionIds" />
                                        {permissionListItems && permissionListItems.map(item => (
                                            permissionSelect(item.name, item.permissions)
                                        ))}
                                    </CardContent>
                                </Card>
                            </BGrid>
                            <BGrid xs={12} container gridClassName="space-between">
                                {!!id && displayDelete}
                                <BSubmit
                                    startIcon={<Save />}
                                    disabled={(id && !permissionUpdateRole) || (!!id && !permissionCreateRole)}
                                >
                                    Save
                                </BSubmit>
                            </BGrid>
                        </BGrid>
                    </BForm>
                </Formik>
            </div>
        </>
    );
}

export default RoleDetail;

import * as React from 'react';
import { useDispatch } from 'react-redux';

import { Grid, Typography } from '@material-ui/core';

import { StudyOverviewData } from '../../../../@types';
import Loading from '../../../../common/Routes/Loading';
import { DisplayTextFormat } from '../../../../common/Utils/DisplayTextFormat';
import { handleErrorResponse } from '../../../../service/utils';
import { getStudyOverview } from '../../../../service/Study/studyOverview';

import StudyOverviewVisits from './StudyOverviewVisits';
import StudyOverviewDetails from './StudyOverviewDetails';
import StudyOverviewDateModal from './StudyOverviewDateModal';
import StudyOverviewStatistics from './StudyOverviewStatistics';
import StudyOverviewInformation from './StudyOverviewInformation';

const StudyOverview: React.FC<{ studyId: number }> = props => {
    const dispatch = useDispatch();

    const [studyData, setStudyData] = React.useState<StudyOverviewData | undefined>(undefined);

    // field to edit with date modal
    const [editField, setEditField] = React.useState<null | 'started' | 'completed'>(null);

    React.useEffect(() => {
        getStudyOverview(props.studyId).then(res => {
            setStudyData(res.data);
        }).catch((err) => {
            handleErrorResponse(err, dispatch, {
                prefix: 'Could not load overview information'
            });
        });
    }, [props.studyId]);

    if (studyData) {
        return (
            <>
                <div className="page-heading">
                    <Typography className="title">{DisplayTextFormat(studyData.studyName, 64)}</Typography>
                </div>
                <div className="page-body max-xl study-overview">
                    <Grid container spacing={3} alignItems="flex-start">
                        <StudyOverviewInformation studyData={studyData} />
                        <StudyOverviewDetails studyData={studyData} setEditField={setEditField} />
                        <StudyOverviewStatistics studyData={studyData} />
                        <StudyOverviewVisits studyData={studyData} />
                    </Grid>
                </div>
                <StudyOverviewDateModal
                    field={editField}
                    setField={setEditField}
                    studyData={studyData}
                    setStudyData={setStudyData}
                    studyId={props.studyId}
                />
            </>
        );
    }
    return (<Loading />);
}

export default StudyOverview;
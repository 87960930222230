import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { BButton } from 'mui-bueno';

import { DisplayTextFormat } from '../../../../common/Utils/DisplayTextFormat';

import Visits from './Visits';
import Procedures from './Procedures';
import ScheduleOfEvents from './ScheduleOfEvents';


interface RouteParams {
    id: string; // Contract ID being accessed
    subSection: 'events' | 'visits' | 'procedures';
}

interface Props {
    studyName: string;
    studyId: number;
    divisionId: number;
    signed: boolean;
}

const ContractSchedules: React.FC<Props> = props => {
    const { id, subSection = 'events' } = useParams<RouteParams>();
    const { studyName, studyId, divisionId, signed } = props;
    const history = useHistory();

    return (
        <>
            <div className="page-heading">
                <Typography className="title">{DisplayTextFormat(studyName, 64)}</Typography>
            </div>
            <div className="page-body">
                <div className="tab-header feature-tabs">
                    <BButton
                        className={subSection === 'events' ? 'selected-tab-item' : 'not-selected-tab-item'}
                        onClick={() => history.push(`/contract/${id}/schedule/events`)}
                    >
                        Schedule of Events
                    </BButton>
                    <BButton
                        className={subSection === 'visits' ? 'selected-tab-item' : 'not-selected-tab-item'}
                        onClick={() => history.push(`/contract/${id}/schedule/visits`)}
                    >
                        Visits
                    </BButton>
                    <BButton
                        className={subSection === 'procedures' ? 'selected-tab-item' : 'not-selected-tab-item'}
                        onClick={() => history.push(`/contract/${id}/schedule/procedures`)}
                    >
                        Procedures
                    </BButton>
                </div>
                {id && subSection === 'events' &&
                    <ScheduleOfEvents contractId={Number(id)} studyId={studyId} divisionId={divisionId} readonly={signed} />
                }
                {id && subSection === 'visits' &&
                    <Visits contractId={Number(id)} studyId={studyId} divisionId={divisionId} readonly={signed} />
                }
                {id && subSection === 'procedures' &&
                    <Procedures contractId={Number(id)} studyId={studyId} divisionId={divisionId} readonly={signed} />
                }
            </div>
        </>
    )
}

export default ContractSchedules;
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';

import useLoggedInUser from '../hooks/useLoggedInUser';
import axiosInstance from '../../service/Access/axiosInstance';
import { logout } from '../../service/Access/authentication';
import { clearStoreLogout } from '../../modules/loginReducer';
import { showGeneralSnackbar } from '../../modules/messageSnackbarReducer';
import { clearJWT } from '../../modules/loginJwtReducer';

const IdleTimeout: React.FC = () => {
    const timeout = 1000 * 60 * 30; // 30 mins
    const [isIdle, setIsIdle] = React.useState(false);
    const [TMinus1, setTMinus1] = React.useState(false);
    const [TMinus2, setTMinus2] = React.useState(false);
    const [TMinus3, setTMinus3] = React.useState(false);
    const [TMinus4, setTMinus4] = React.useState(false);
    const [TMinus5, setTMinus5] = React.useState(false);

    const handleOnActive = () => setIsIdle(false);
    const handleOnActiveTM1 = () => setTMinus1(false);
    const handleOnActiveTM2 = () => setTMinus2(false);
    const handleOnActiveTM3 = () => setTMinus3(false);
    const handleOnActiveTM4 = () => setTMinus4(false);
    const handleOnActiveTM5 = () => setTMinus5(false);

    const handleOnIdle = () => setIsIdle(true);
    const handleOnIdleTM1 = () => setTMinus1(true);
    const handleOnIdleTM2 = () => setTMinus2(true);
    const handleOnIdleTM3 = () => setTMinus3(true);
    const handleOnIdleTM4 = () => setTMinus4(true);
    const handleOnIdleTM5 = () => setTMinus5(true);

    useIdleTimer({
        timeout: timeout,
        onActive: handleOnActive,
        onIdle: handleOnIdle
    });
    useIdleTimer({
        timeout: timeout - 60000,
        onActive: handleOnActiveTM1,
        onIdle: handleOnIdleTM1
    });
    useIdleTimer({
        timeout: timeout - (2 * 60000),
        onActive: handleOnActiveTM2,
        onIdle: handleOnIdleTM2
    });
    useIdleTimer({
        timeout: timeout - (3 * 60000),
        onActive: handleOnActiveTM3,
        onIdle: handleOnIdleTM3
    });
    useIdleTimer({
        timeout: timeout - (4 * 60000),
        onActive: handleOnActiveTM4,
        onIdle: handleOnIdleTM4
    });
    useIdleTimer({
        timeout: timeout - (5 * 60000),
        onActive: handleOnActiveTM5,
        onIdle: handleOnIdleTM5
    });

    const dispatch = useDispatch();
    const user = useLoggedInUser();

    React.useEffect(() => {
        const logoutBehavior = () => {
            axiosInstance.defaults.headers.common.Authorization = '';
            localStorage.removeItem('accessToken');
            dispatch(clearStoreLogout());
            dispatch(clearJWT());
            dispatch(showGeneralSnackbar('Your session has timed out due to inactivity. Please login again.'));
        }
        if (user.id) {
            if (isIdle) {
                const accessToken = localStorage.getItem('accessToken');
                logout(accessToken)
                    .then(logoutBehavior)
                    .catch(logoutBehavior)
            } else if (TMinus1) {
                dispatch(showGeneralSnackbar('You will be logged out in 1 minute due to inactivity. Perform any action to extend your session.'));
                setTMinus1(false);
            } else if (TMinus2) {
                dispatch(showGeneralSnackbar('You will be logged out in 2 minutes due to inactivity. Perform any action to extend your session.'));
                setTMinus2(false);
            } else if (TMinus3) {
                dispatch(showGeneralSnackbar('You will be logged out in 3 minutes due to inactivity. Perform any action to extend your session.'));
                setTMinus3(false);
            } else if (TMinus4) {
                dispatch(showGeneralSnackbar('You will be logged out in 4 minutes due to inactivity. Perform any action to extend your session.'));
                setTMinus4(false);
            } else if (TMinus5) {
                dispatch(showGeneralSnackbar('You will be logged out in 5 minutes due to inactivity. Perform any action to extend your session.'));
                setTMinus5(false); //set to false so that the message won't re-display when others become false.
            } else {
                // Do nothing, but sonar requires this format
            }
        }
    }, [isIdle, TMinus1, TMinus2, TMinus3, TMinus4, TMinus5, dispatch, user.id]);

    // Intentionally has null return because we don't need to render any TSX
    return null;
}

export default IdleTimeout;
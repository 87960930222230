import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Button, Card, Grid, Tab, Tabs, Tooltip, Typography } from '@material-ui/core';
import { Add, Comment, Email, Info, NavigateNext, Phone } from '@material-ui/icons';

import { AccountContact, AccountWithCredit, InvoiceWithStudyInfo } from '../../../@types';
import { paymentTypeLabel } from '../../../common/Invoicing/Utils';
import InvoicesSummaryCard from '../../../common/Invoicing/InvoicesSummaryCard';
import AccountingModal from '../../../common/Invoicing/AccountingModal';
import { handleErrorResponse } from '../../../service/utils';
import { getAccountWithTotalCredit, getPayableInvoicesList, getReceivableInvoicesList } from '../../../service/Management/accounts';

import PaymentsTable from './PaymentsTable';
import LogPayment from '../../Invoicing/Payment/LogPayment';
import InvoiceablesTable from '../../../common/Invoicing/InvoiceablesTable';
import { renderAccountCell, renderInvoiceCell, renderStudyCell } from '../../../common/DataTable/Utils';
import usePermissions from '../../../common/hooks/usePermissions';

interface RouteParams {
    id: string;
    option?: string; // 'receivable', 'payable', or 'payments'
}

const AccountDetail: React.FC = props => {
    const { id, option } = useParams<RouteParams>();

    const history = useHistory();
    const dispatcher = useDispatch();

    const [account, setAccount] = React.useState<AccountWithCredit>({
        accountWithContacts: {
            account: {
                id: 0,
                name: '',
                enteredName: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zipCode: '',
                preferredPayType: '',
                achAccountingNo: '',
                internal: false,
            },
            contacts: [],
        },
        totalCredit: 0,
    } as AccountWithCredit)
    const [primaryContact, setPrimaryContact] = React.useState<AccountContact | null>(null);
    const [otherContacts, setOtherContacts] = React.useState<AccountContact[]>([]);

    const [tableData, setTableData] = React.useState<InvoiceWithStudyInfo[]>([]);

    const [editModalOpen, setEditModalOpen] = React.useState(false);
    const [payOpen, setPayOpen] = React.useState(false);

    const [updated, setUpdated] = React.useState(false);

    const canUpdateAccounts = usePermissions('API_ACCOUNT_UPDATE') && usePermissions('API_ACCOUNT_CONTACT_SET_CONTACTS_ON_ACCOUNT');
    const canViewAccountDetails = usePermissions('API_ACCOUNT_GET_BY_ID');
    const canLogPayment = usePermissions('API_ACCOUNT_PAYMENT_LOG_PAYMENT');
    const canViewInvoiceDetails = usePermissions('API_INVOICE_GET_BY_ID');

    const convertData = (data: any[]) => {
        // Consider restricting type based on value of key "type" for line item columns
        // and invoice columns from InvoiceablesTable
        const tempData: any[] = [];
        data.forEach((obj: InvoiceWithStudyInfo) => {
            tempData.push({
                invoiceId: obj.invoiceDto.id,
                status: obj.invoiceDto.invoiceStatus,
                invoice: renderInvoiceCell(obj.invoiceDto.invoiceNo, obj.invoiceDto.id!, history, canViewInvoiceDetails),
                amount: obj.invoiceDto.amount,
                dateCreated: obj.invoiceDto.createdDate,
                dateSent: obj.invoiceDto.sentDate,
                datePaid: obj.invoiceDto.paidDate,
                datePaidMinusHoldback: obj.invoiceDto.paidDateMinusHoldback,
                study: renderStudyCell(obj.studyName, obj.studyId, history),
                payee: renderAccountCell(obj.receivingAccountName, obj.invoiceDto.receivingAccountId, history, canViewAccountDetails),
                payor: renderAccountCell(obj.payingAccountName, obj.invoiceDto.payingAccountId, history, canViewAccountDetails),
                checkNumbers: obj.checkNumbers,
                payingAccountId: obj.invoiceDto.payingAccountId,
                receivingAccountId: obj.invoiceDto.receivingAccountId,
                studyId: obj.invoiceDto.studyId,
                invoiceNo: obj.invoiceDto.invoiceNo
            })
        })
        return tempData;
    }

    React.useEffect(() => {
        if (!option) {
            history.replace(`/manage/account/${id}/receivable`);
        }
        if (option == 'receivable') {
            getReceivableInvoicesList(Number(id)).then(res => {
                setTableData(convertData(res.data));
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    prefix: 'Could not retrieve Account\'s Receivable Invoices: '
                });
            });
        }
        if (option == 'payable') {
            getPayableInvoicesList(Number(id)).then(res => {
                setTableData(convertData(res.data));
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    prefix: 'Could not retrieve Account\'s Payable Invoices: '
                });
            });
        }
    }, [id, option, updated]);

    React.useEffect(() => {
        getAccountWithTotalCredit(Number(id)).then(res => {
            setAccount(res.data);
            const nonPrimaryContacts: AccountContact[] = [];
            res.data.accountWithContacts.contacts.forEach((contact: AccountContact) => {
                if (!contact.primaryContact) {
                    nonPrimaryContacts.push(contact);
                } else {
                    setPrimaryContact(contact);
                }
            })
            setOtherContacts(nonPrimaryContacts);
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                prefix: 'Could not retrieve Account: '
            });
        });
        setUpdated(false);
    }, [id, option, updated]);

    const TabPanel = (value: any) => {
        if (value === `/manage/account/${id}/receivable` || value === `/manage/account/${id}/payable`) {
            return (
                <div role='tabpanel' id='simple-tabpanel-scheduled' aria-labelledby='simple-tab-scheduled'>
                    <InvoiceablesTable
                        type='invoices' data={tableData}
                        showColumns={option == 'receivable' ?
                            ['status', 'invoice', 'amount', 'dateCreated', 'dateSent', 'datePaidMinusHoldback', 'datePaid', 'study', 'payor'] :
                            ['status', 'invoice', 'amount', 'dateCreated', 'dateSent', 'datePaidMinusHoldback', 'datePaid', 'study', 'payee']}
                        updateOnChange={updated}
                        setUpdateOnChange={setUpdated}
                        viewMode={option}
                    />
                </div>
            )
        }
        if (value === `/manage/account/${id}/payments`) {
            return (
                <div role='tabpanel' id='simple-tabpanel-payments' aria-labelledby='simple-tab-payments'>
                    <PaymentsTable accountId={Number(account.accountWithContacts.account.id)} />
                </div>
            )
        }
    }

    const handleCallToRouter = (event: React.ChangeEvent<{}>, value: any) => {
        history.push(value);
    };

    return (
        <>
            <div className="page-heading">
                <Typography
                    className="title inactive cursor-pointer"
                    onClick={() => history.push('/manage/account')}
                >
                    Account Management
                </Typography>
                <NavigateNext className="arrow" />
                <Typography className="title">{account.accountWithContacts.account.name}</Typography>
            </div>
            <div className="page-body margin-auto reduce-top-sm">
                <Grid container direction='row' spacing={3} alignItems="stretch" className='highlight-cards'>
                    <Grid item xs={12} lg={6}>
                        <div className="section-heading">
                            <div className='text'>Account Information</div>
                            <Tooltip arrow title='General invoicing related details for this account.'><Info className='info' /></Tooltip>
                            {canUpdateAccounts && 
                                <Button id="edit-info" onClick={() => setEditModalOpen(true)}>
                                    Edit
                                </Button>
                            }
                        </div>
                        <Card className='account-summary'>
                            <Grid container direction='row' spacing={3} alignItems="stretch" className='highlight-cards'>
                                <Grid item xs={12} sm={6}>
                                    <div className='info-line'>
                                        <Typography variant='body1'>Name:</Typography>
                                        <Typography variant='body2'>{account.accountWithContacts.account.enteredName}</Typography>
                                    </div>
                                    <div className='info-line padding'>
                                        <Typography variant='body1'>Address:</Typography>
                                        {(account.accountWithContacts.account.addressLine1
                                            && account.accountWithContacts.account.city
                                            && account.accountWithContacts.account.state
                                            && account.accountWithContacts.account.zipCode) ?
                                            <Typography variant='body2'>
                                                {account.accountWithContacts.account.addressLine1} <br />
                                                {account.accountWithContacts.account.addressLine2}
                                                {account.accountWithContacts.account.addressLine2 && <br />}
                                                {account.accountWithContacts.account.city}, {account.accountWithContacts.account.state} {account.accountWithContacts.account.zipCode}
                                            </Typography> :
                                            <Typography variant='body2'>
                                                None
                                            </Typography>
                                        }
                                    </div>
                                    <div className='info-line padding'>
                                        <Typography variant='body1'>Payment Preference:</Typography>
                                        {account.accountWithContacts.account.preferredPayType ?
                                            <Typography variant='body2'>
                                                {paymentTypeLabel(account.accountWithContacts.account.preferredPayType)}
                                            </Typography> :
                                            <Typography variant='body2'>
                                                None
                                            </Typography>
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className='info-line'>
                                        <Typography variant='body1'>Primary Contact:</Typography>
                                        {primaryContact ? <>
                                            <div className='contact-attribute'>
                                                <div className='spacer' />
                                                <Typography variant='body2'>{primaryContact.name}</Typography>
                                            </div>
                                            <div className='contact-attribute email'>
                                                <Email />
                                                <Typography variant='body2'>{primaryContact.email}</Typography>
                                            </div>
                                            {primaryContact.phoneNo &&
                                                <div className='contact-attribute'>
                                                    <Phone />
                                                    <Typography variant='body2'>{primaryContact.phoneNo}</Typography>
                                                </div>
                                            }
                                            {primaryContact.comment &&
                                                <div className='contact-attribute'>
                                                    <Comment />
                                                    <Typography variant='body2'>{primaryContact.comment}</Typography>
                                                </div>
                                            }
                                        </> :
                                            <Typography variant='body2'>
                                                None
                                            </Typography>
                                        }
                                    </div>
                                    <div className='info-line padding other'>
                                        <Typography variant='body1'>Other Contact(s):</Typography>
                                        {otherContacts.length == 0 &&
                                            <Typography variant='body2'>
                                                None
                                            </Typography>
                                        }
                                        {otherContacts.map((obj: AccountContact, idx: number) => {
                                            let paddingTop = '';
                                            if (idx !== 0) { paddingTop = ' padding-top'; }
                                            return (<div key={`other-account-${idx}`}>
                                                <div className={'contact-attribute' + paddingTop}>
                                                    <Typography variant='body2'>{obj.name}</Typography>
                                                </div>
                                                {obj.email &&
                                                    <div className='contact-attribute'>
                                                        <Email />
                                                        <Typography variant='body2'>{obj.email}</Typography>
                                                    </div>
                                                }
                                                {obj.phoneNo &&
                                                    <div className='contact-attribute'>
                                                        <Phone />
                                                        <Typography variant='body2'>{obj.phoneNo}</Typography>
                                                    </div>
                                                }
                                                {obj.comment &&
                                                    <div className='contact-attribute'>
                                                        <Comment />
                                                        <Typography variant='body2'>{obj.comment}</Typography>
                                                    </div>
                                                }
                                            </div>)
                                        })}
                                    </div>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <div className="section-heading">
                            <div className='text'>Invoicing Summary</div>
                            <Tooltip arrow title="A overview highlighting the statuses of this account's invoices at a broad level."><Info className='info' /></Tooltip>
                        </div>
                        <InvoicesSummaryCard accountId={Number(id)} updateOnChange={updated} />
                    </Grid>
                </Grid>
                <div className='page-tabs'>
                    {option == 'receivable' && canLogPayment &&
                        <div className='log-payment'>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Add />}
                                onClick={() => setPayOpen(true)}
                            >
                                Log Payment
                            </Button>
                        </div>
                    }
                    <Tabs
                        value={`/manage/account/${id}/${option}`}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleCallToRouter}
                        className='sv-tab spacing'
                    >
                        <Tab label="Receivable Invoices" value={`/manage/account/${id}/receivable`} />
                        <Tab label="Payable Invoices" value={`/manage/account/${id}/payable`} />
                        <Tab label="Payments" value={`/manage/account/${id}/payments`} />
                    </Tabs>
                </div>
                {TabPanel(history.location.pathname)}
            </div>
            <LogPayment
                open={payOpen}
                setOpen={setPayOpen}
                updateOnChange={updated}
                setUpdateOnChange={setUpdated}
            />
            <AccountingModal
                id={id}
                open={editModalOpen}
                setOpen={setEditModalOpen}
                setUpdated={setUpdated}
                management
            />
        </>
    );
}

export default AccountDetail;
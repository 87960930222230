import React from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { Chip, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Check, Delete, Edit } from '@material-ui/icons';
import { BForm, BSelect, BSubmit } from 'mui-bueno';

import { DivisionUser, SelectOption } from '../../../@types';
import { showSuccessSnackbar } from '../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../service/utils';
import { assignUserToDivision, removeUserFromDivision } from '../../../service/Management/divisions';
import useLoggedInUserPermissions from '../../../common/hooks/useLoggedInUserPermissions';

import DivisionUserEdit from './DivisionUserEdit';
import SimpleConfirmDelete from '../../../common/ConfirmDelete/SimpleConfirmDelete';


const emptyDivisionUser: DivisionUser = {
    userId: -1,
    userName: '',
    roleIds: []
}

interface State {
    userList: DivisionUser[];
}

const initialState: State = {
    userList: [],
}

interface Action {
    type: 'load' | 'added' | 'edited' | 'deleted';
    payload?: any;
}

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'load':
            return {
                ...state,
                userList: action.payload,
            }
        case 'added':
            return {
                ...state,
                userList: [...state.userList, action.payload]
            }
        case 'edited':
            return {
                ...state,
                userList: state.userList.map(el => el.userId === action.payload.userId ? action.payload : el)
            }
        case 'deleted':
            return {
                ...state,
                userList: state.userList.filter(item => item.userId !== action.payload)
            }
        default:
            return state;
    }
}

interface Props {
    divisionId: number;
    userList: DivisionUser[];
    allReferenceUsers: SelectOption<number>[];
    allReferenceRoles: SelectOption<number>[];
    newlyAddedIds: number[];
}

const DivisionUsers: React.FC<Props> = props => {
    const { divisionId, allReferenceUsers, allReferenceRoles, newlyAddedIds } = props;
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { userList } = state;
    const dispatcher = useDispatch();

    const [divisionUser, setDivisionUser] = React.useState<DivisionUser>(emptyDivisionUser);
    const canEdit = useLoggedInUserPermissions('API_DIVISION_ASSIGN_USER_TO_DIVISION', divisionId, 0) ||
        newlyAddedIds.includes(divisionId);
    const canDelete = useLoggedInUserPermissions('API_DIVISION_REMOVE_USER_FROM_DIVISION', divisionId, 0) ||
        newlyAddedIds.includes(divisionId);

    // Data is in the array of role Ids, but need to lookup the role name and display with comma separated
    const getDisplayRoles = (roleIds: number[]) => {
        const filtered = _.filter(allReferenceRoles, function (o) { return roleIds.includes(o.value); });
        return _.join(_.map(filtered, 'label'), ', ');
    }

    // State management for the table actions
    const [clickedObj, setClickedObj] = React.useState<null | DivisionUser>(null);
    // State management for Edit action
    const [openEdit, setOpenEdit] = React.useState<boolean>(false);
    // State management for Delete action
    const [openDelete, setOpenDelete] = React.useState<boolean>(false);

    const handleDelete = (user: null | DivisionUser) => {
        if (user) {
            setOpenDelete(false);
            removeUserFromDivision(divisionId, user.userId)
                .then(res => {
                    dispatch({ type: 'deleted', payload: user.userId });
                    dispatcher(showSuccessSnackbar(`User ${user.userName} removed`));
                }).catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        prefix: 'Could not remove User from Division: '
                    });
                });
        }
    };

    const handleAfterEdited = (editedUser: DivisionUser) => {
        dispatch({ type: 'edited', payload: editedUser });
    }

    // State management for autocomplete options
    const [userOptions, setUserOptions] = React.useState<SelectOption<number>[]>(allReferenceUsers);
    const [selectedUser, setSelectedUser] = React.useState<null | SelectOption<number>>(null);

    const createNewObject = (data: DivisionUser) => {
        return {
            userId: data.userId,
            userName: data.userName,
            roleIds: data.roleIds
        }
    }
    // Form management
    const [requiredUser, setRequiredUser] = React.useState<boolean>(false);
    const handleSubmitUser = async (data: DivisionUser, { setErrors, resetForm }: FormikHelpers<DivisionUser>) => {
        if (selectedUser) {
            data.userId = selectedUser.value;
            data.userName = selectedUser.label;
            setRequiredUser(false);
        } else {
            setRequiredUser(true);
            return;
        }

        assignUserToDivision(divisionId, data)
            .then(res => {
                dispatch({ type: 'added', payload: createNewObject(data) });
                setSelectedUser(null);
                setDivisionUser(emptyDivisionUser);
                resetForm();
                dispatcher(showSuccessSnackbar(`User ${data.userName} added`));
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    setStatus: setErrors,
                    prefix: 'Could not assign User to Division: '
                })
            });
    };

    // State Management for table pagination
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //Initial Load data when the props userList is updated
    React.useEffect(() => {
        dispatch({ type: 'load', payload: props.userList });
        setRequiredUser(false);
    }, [props.userList]);

    //When the state userList is changed,
    // the user selections needs to be updated to make sure it not include already assigned users
    React.useEffect(() => {
        const alreadyAssignedUserIds = _.map(userList, 'userId');
        const userSelections = _.filter(allReferenceUsers, function (o) { return !alreadyAssignedUserIds.includes(o.value); });
        setUserOptions(userSelections);
    }, [userList]);

    return (
        <>
            <TablePagination
                component="div"
                count={userList.length}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Items Per Page:"
            />
            <Formik
                initialValues={divisionUser}
                onSubmit={handleSubmitUser}
                validateOnChange={false}
                validateOnBlur={false}
            >
                <BForm>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead className="table-header">
                                <TableRow>
                                    <TableCell className='primary-cell' >
                                        <div className='header-cell-label center'>User</div>
                                    </TableCell>
                                    <TableCell className='primary-cell' >
                                        <div className='header-cell-label center'>Division Role</div>
                                    </TableCell>
                                    {(canEdit || canDelete) &&
                                        <TableCell className='primary-cell'>
                                            <div className="header-cell-label center">
                                                Actions
                                            </div>
                                        </TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? userList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : userList
                                ).map((obj: any, idx: number) => {
                                    return (
                                        <TableRow key={'row-' + idx}>
                                            <TableCell className='body-cell left'>
                                                {obj['userName']}
                                            </TableCell>
                                            <TableCell className='body-cell left'>
                                                {getDisplayRoles(obj['roleIds'])}
                                            </TableCell>
                                            {(canEdit || canDelete) &&
                                                <TableCell className="body-cell center">
                                                    <div className="aligned-row" style={{ justifyContent: 'center' }}>
                                                        {canEdit &&
                                                            <Tooltip arrow title="Edit User Roles">
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setClickedObj(obj);
                                                                        setOpenEdit(true);
                                                                    }}
                                                                    style={{ padding: '6px' }}
                                                                >
                                                                    <Edit />
                                                                </IconButton>
                                                            </Tooltip>}
                                                        {canDelete &&
                                                            <Tooltip arrow title="Remove User from Division">
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setClickedObj(obj);
                                                                        setOpenDelete(true);
                                                                    }}
                                                                    style={{ padding: '6px' }}
                                                                >
                                                                    <Delete />
                                                                </IconButton>
                                                            </Tooltip>}
                                                    </div>
                                                </TableCell>
                                            }
                                        </TableRow>
                                    )
                                })}
                                {userList.length === 0 &&
                                    <TableRow>
                                        <TableCell colSpan={3} className='body-cell center' style={{ height: 50, fontSize: 16 }}>
                                            No data to display.
                                        </TableCell>
                                    </TableRow>
                                }
                                {canEdit &&
                                    <TableRow>
                                        <TableCell className='body-cell left' style={{ width: '50%' }}>
                                            <Autocomplete
                                                id="user"
                                                value={selectedUser}
                                                autoSelect={true}
                                                options={userOptions}
                                                getOptionLabel={(option) => option.label}
                                                renderInput={(params) =>
                                                    <TextField {...params} name="divisionUser" label="User" variant="standard"
                                                        error={requiredUser} helperText={requiredUser ? 'Required' : null}
                                                    />
                                                }
                                                getOptionSelected={(option, value) => option.label === value.label}
                                                onChange={(_event, newValue) => setSelectedUser(newValue)}
                                            />
                                        </TableCell>
                                        <TableCell className='body-cell left' style={{ width: '50%' }}>
                                            <BSelect
                                                name="roleIds"
                                                label="Roles"
                                                options={allReferenceRoles}
                                                multiple
                                                selectedOptionIcon={<Check />}
                                                renderValue={(selected: unknown) => {
                                                    return (selected as number[]).map(value => {
                                                        const option = allReferenceRoles[value];
                                                        return (
                                                            option && <Chip key={`role-chip-${option.value}`} label={option.label} className="chip" size={'small'} />
                                                        );
                                                    });
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className='body-cell center' style={{ width: 80 }}>
                                            <BSubmit id='add-division-user-submit' variant='text'>
                                                Add
                                            </BSubmit>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </BForm>
            </Formik>
            {clickedObj &&
                <>
                    <DivisionUserEdit
                        open={openEdit}
                        setOpen={setOpenEdit}
                        divisionId={divisionId}
                        allReferenceRoles={allReferenceRoles}
                        editDivisionUser={clickedObj}
                        saveEditedDivisionUser={handleAfterEdited}
                    />
                    <SimpleConfirmDelete
                        open={openDelete}
                        setOpen={setOpenDelete}
                        type='User From Division'
                        objectName={clickedObj.userName!}
                        handleDelete={() => handleDelete(clickedObj)}
                        message={'Do you want to remove ' + clickedObj.userName! + ' from the division?'}
                        title={'Remove User From Division?'}
                        buttonProps={{children: 'Remove'}}
                    />
                </>
            }

        </>
    )
}
export default DivisionUsers;

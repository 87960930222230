import { useSelector } from 'react-redux';

import { PermissionConst } from '../../@types';
import { Store } from '../../modules/rootReducer';

const useLoggedInDivisionPermissions = (permission: PermissionConst) => {
    const userRolePerms = useSelector<Store, any>(store =>
        store.loginReducer.rolePermissions
    );
    const selectedPermission = userRolePerms[permission];
    if (!selectedPermission) {
        return false; //user has no permission
    }

    const isNoRestriction: boolean =
        (selectedPermission.restrictedDivisions.length === 0 &&
            selectedPermission.restrictedStudies.length === 0);

    const isDivisionLevel: boolean = selectedPermission.restrictedDivisions.length > 0;

    return isNoRestriction || isDivisionLevel;
};

export default useLoggedInDivisionPermissions;
import * as React from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';

import {
    Card, CardContent, CardHeader, IconButton, InputAdornment,
    Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography
} from '@material-ui/core';
import { Close, NavigateNext } from '@material-ui/icons';
import { BDatePicker, BError, BForm, BOption, BSelect, BSubmit, BTextField } from 'mui-bueno';
import { Formik, FormikHelpers } from 'formik';

import { IncurredCost, SelectOption, StudyUser, TableHeader } from '../../../../../@types';
import { showSuccessSnackbar } from '../../../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../../../service/utils';
import { updateIncurredCost } from '../../../../../service/IncurredCost/incurredCost';
import { BIG_DECIMAL_MAX, forceDateToUtc } from '../../../../../common/Utils/utils';
import { NumberFormatter } from '../../../../../common/Utils/NumberFormatter';
import CheckedAutocomplete from '../../../../../common/CheckedAutocomplete/CheckedAutocomplete';


const headers: TableHeader[] = [{
    type: 'description',
    displayValue: 'Description',
    align: 'left',
}, {
    type: 'dateOfOccurrence',
    displayValue: 'Date of Occurrence',
    align: 'left',
}, {
    type: 'cost',
    displayValue: 'Cost',
    align: 'right',
}, {
    type: 'userIds',
    displayValue: 'Employees',
    align: 'left',
}, {
    type: 'invoiceType',
    displayValue: 'Invoice Type',
    align: 'left'
}, {
    type: 'serviceProvider',
    displayValue: 'Service Provider',
    align: 'left'
}];

interface Props {
    handleEditCostModalClose: () => void;
    incurredCostToEdit: any;
    users: StudyUser[];
    employees: SelectOption<number>[];
    invoiceTypes: BOption<string>[];
    serviceProviders: SelectOption<number>[];
    setUpdatePage: any;
    open: any;
    handleEdit?: any;
    readOnly?: boolean;
    lineItemDisplay?: boolean;
}

interface FormValues {
    id: number;
    description: string;
    dateOfOccurrence: Date;
    cost: number;
    userIds: number[];
    invoiceType: string;
    serviceProviderId: number;
    studyId: number;
}


const incurredCostSchema = yup.object<IncurredCost>().shape({
    description: yup.string().required('Description is required'),
    dateOfOccurrence: yup.date().required('Date of occurrence is required'),
    cost: yup.number().required('Cost is required').min(0, 'Min value: 0.00').max(BIG_DECIMAL_MAX, 'Max value: ' + BIG_DECIMAL_MAX)
});

const EditIncurredCost: React.FC<Props> = props => {
    const {
        handleEditCostModalClose, incurredCostToEdit, users, employees, invoiceTypes,
        serviceProviders, setUpdatePage, open, handleEdit, readOnly, lineItemDisplay
    } = props;

    const dispatcher = useDispatch();

    const initialUserIds: BOption<number>[] = [];
    const initialUserIdNumbers: number[] = [];
    if (users) {
        users.forEach(user => {
            initialUserIds.push({
                value: user.userId,
                label: user.userName!
            })

            initialUserIdNumbers.push(user.userId);
        });
    }

    // Initial values for formik
    const initialValues: FormValues = {
        id: incurredCostToEdit.incurredCostId!,
        description: incurredCostToEdit.description,
        dateOfOccurrence: forceDateToUtc(new Date(incurredCostToEdit.dateOfOccurrence!)),
        cost: incurredCostToEdit.cost,
        userIds: incurredCostToEdit.userIds,
        invoiceType: incurredCostToEdit.invoiceType,
        serviceProviderId: incurredCostToEdit.serviceProviderId,
        studyId: incurredCostToEdit.studyId
    };

    // State management to convert from SelectOption to corresponding object's ID
    const [selectedUserIds, setSelectedUserIds] = React.useState<number[]>(incurredCostToEdit.userIds);

    // State management to keep track if the user is changing the users on the incurred cost
    const [usersChanged, setUsersChanged] = React.useState<boolean>(false);

    const [selectedServiceProvider, setSelectedServiceProvider] = React.useState<SelectOption<number> | null>(null)

    const [inputErrors, setInputErrors] = React.useState<{ [k: string]: string }>({});

    React.useEffect(() => {
        if (open && incurredCostToEdit.serviceProviderId) {
            const selectedOption = serviceProviders.find(option => option.value == incurredCostToEdit.serviceProviderId)
            if (selectedOption)
                setSelectedServiceProvider(selectedOption)
        }
    }, [open, serviceProviders]);

    const handleSetSelectedEmployees = (employeesSelected: any) => {
        setUsersChanged(true);
        const temp: number[] = [];
        employeesSelected.forEach((employee: { value: number; }) => {
            temp.push(employee.value);
        })
        setSelectedUserIds(temp);
    };

    const handleUpdateIncurredCost = async (data: IncurredCost, { setErrors }: FormikHelpers<IncurredCost>) => {
        let serviceProviderId = undefined;
        if (data.invoiceType == 'PASS_THROUGH') {
            if (selectedServiceProvider?.value)
                serviceProviderId = selectedServiceProvider.value;
            else {
                const errors: { [k: string]: string } = {};
                errors.serviceProvider = 'Service Provider Required'
                setInputErrors(errors);
                setErrors(errors);
                return;
            }
        }

        if (usersChanged) {
            updateIncurredCost({
                id: data.id,
                description: data.description,
                dateOfOccurrence: data.dateOfOccurrence,
                cost: data.cost,
                userIds: selectedUserIds,
                invoiceType: data.invoiceType,
                serviceProviderId: serviceProviderId,
                studyId: incurredCostToEdit.studyId
            }).then(res => {
                setUpdatePage(true);
                setUsersChanged(false);
                setInputErrors({})
                handleEditCostModalClose();
                if (handleEdit) handleEdit(res.data);
                dispatcher(showSuccessSnackbar(`Updated incurred cost "${data.description}"`));
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    prefix: 'Could not update Incurred Cost: '
                });
            });
        } else {
            updateIncurredCost({
                id: data.id,
                description: data.description,
                dateOfOccurrence: data.dateOfOccurrence,
                cost: data.cost,
                userIds: initialUserIdNumbers,
                invoiceType: data.invoiceType,
                serviceProviderId: serviceProviderId,
                studyId: incurredCostToEdit.studyId
            }).then(res => {
                setUpdatePage(true);
                setInputErrors({})
                handleEditCostModalClose();
                if (handleEdit) handleEdit(res.data);
                dispatcher(showSuccessSnackbar(`Updated incurred cost "${data.description}"`));
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    prefix: 'Could not update Incurred Cost: '
                });
            });
        }
    };

    const incurredCostCardHeader = () => {
        return (
            <div className="aligned-row">
                <span>Line Item Info</span>
                <NavigateNext className="arrow" />
                <span>{incurredCostToEdit.description ?? ''}</span>
            </div>
        );
    }

    const handleInvoiceTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const invoiceType = invoiceTypes[event.target.value as number].value;
        if (invoiceType != 'PASS_THROUGH')
            setSelectedServiceProvider(null);
    }

    const getHeaderCell = (data: IncurredCost, header: TableHeader) => {
        if (header.type != 'serviceProvider' || (header.type == 'serviceProvider' && data.invoiceType == 'PASS_THROUGH')) {
            return (
                <TableCell
                    key={'header-' + header.type}
                    className={'primary-cell '}
                >
                    <div className={`header-cell-label ${header.align}`}>
                        {header.displayValue}
                    </div>
                </TableCell>
            )
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleEditCostModalClose}
        >
            <div className="modal-form">
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleUpdateIncurredCost}
                    validationSchema={incurredCostSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize
                >
                    {userFormikProps => (
                        <BForm>
                            <Card className="detail-form lg">
                                <CardHeader
                                    title={lineItemDisplay ? incurredCostCardHeader() : 'Update Incurred Cost'}
                                    action={
                                        <IconButton color="primary" aria-label="Close" onClick={handleEditCostModalClose}>
                                            <Close />
                                        </IconButton>
                                    }
                                />
                                <CardContent>
                                    {!lineItemDisplay &&
                                        <Typography style={{ paddingBottom: 10 }}>
                                            Update the incurred cost and then click the Update button to finish.
                                        </Typography>
                                    }
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead className="table-header">
                                                <TableRow>
                                                    {headers.map(header => {
                                                        return (
                                                            getHeaderCell(userFormikProps.values, header)
                                                        )
                                                    })}
                                                    {!readOnly && <TableCell key='header-actions' className='primary-cell'>
                                                        <div className="header-cell-label center">
                                                            Actions
                                                        </div>
                                                    </TableCell>}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow key={'edit-incurred-cost'}>
                                                    <TableCell
                                                        key={'edit-description'}
                                                        className={'body-cell left'}
                                                    >
                                                        <BTextField
                                                            name="description"
                                                            label=''
                                                            placeholder="Description"
                                                            variant="standard"
                                                            required autoFocus
                                                            disabled={readOnly}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        key={'edit-dateOfOccurrence'}
                                                        className={'body-cell left'}
                                                        style={{ width: '20%' }}
                                                    >
                                                        <BDatePicker
                                                            name="dateOfOccurrence"
                                                            label=''
                                                            placeholder="MM/DD/YYYY"
                                                            inputVariant="standard"
                                                            required
                                                            disabled={readOnly}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        key={'edit-cost'}
                                                        className={'body-cell right'}
                                                        style={{ width: '12%' }}
                                                    >
                                                        <BTextField
                                                            name="cost"
                                                            label=''
                                                            placeholder="0.00"
                                                            variant="standard"
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                                inputComponent: NumberFormatter as any
                                                            }}
                                                            required
                                                            disabled={readOnly}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        key={'edit-employee-name'}
                                                        className={'body-cell left'}
                                                        style={{ width: '25%' }}
                                                    >
                                                        <CheckedAutocomplete
                                                            idText={'userIds'}
                                                            multiple={true}
                                                            defaultValue={initialUserIds}
                                                            options={employees}
                                                            onChange={handleSetSelectedEmployees}
                                                            disabled={readOnly}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        key={'edit-invoice-type'}
                                                        className={'body-cell left'}
                                                        style={{ minWidth: '160px' }}
                                                    >
                                                        <BSelect
                                                            name='invoiceType'
                                                            label=''
                                                            placeholder='Invoice Type'
                                                            variant='standard'
                                                            options={invoiceTypes}
                                                            onChange={handleInvoiceTypeChange}
                                                            disabled={readOnly}
                                                            required
                                                            className='bselect-no-margin'
                                                        />
                                                    </TableCell>
                                                    {userFormikProps.values.invoiceType == 'PASS_THROUGH' &&
                                                        <Tooltip title={userFormikProps.values.invoiceType != 'PASS_THROUGH' ? 'Service provider is only needed if the invoice type is Pass Through' : ''}>
                                                            <TableCell key={'edit-service-provider'} className={'body-cell left'} style={{ minWidth: '160px' }}>
                                                                <CheckedAutocomplete
                                                                    idText='serviceProvider'
                                                                    placeholder='Service Provider'
                                                                    acValue={selectedServiceProvider}
                                                                    closeOnSelect
                                                                    noCheckmark
                                                                    size='small'
                                                                    disabled={userFormikProps.values.invoiceType != 'PASS_THROUGH' || readOnly}
                                                                    error={!!inputErrors['serviceProvider']}
                                                                    options={serviceProviders}
                                                                    onChange={setSelectedServiceProvider}
                                                                />
                                                                <BError name={'serviceProviderId'} id="serviceProviderId-error" />
                                                            </TableCell>
                                                        </Tooltip>
                                                    }
                                                    {!readOnly && <TableCell
                                                        key={'edit-incurred-cost-done'}
                                                        className={'body-cell center'}
                                                        style={{ width: '5%' }}
                                                    >
                                                        <BSubmit
                                                            variant="text"
                                                            color="primary"
                                                            className="flex-end"
                                                        >
                                                            Update
                                                        </BSubmit>
                                                    </TableCell>}
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        </BForm>
                    )}
                </Formik>
            </div>
        </Modal>
    )
};

export default EditIncurredCost;
import * as React from 'react';

import { CardHeader, Grid, IconButton, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';


interface Props {
    handleClose: () => void;
    title: any;
    toolTip?: string;
}

const CardTitle: React.FC<Props> = props => {
    const { handleClose, title, toolTip } = props;

    return (
        <CardHeader
            title={
                <Grid item className="word-wrap" xs={10}>
                    {title}
                    {toolTip &&
                        <Tooltip arrow title={<div style={{ whiteSpace: 'pre-line' }}>{toolTip}</div>}>
                            <InfoIcon className='info' />
                        </Tooltip>
                    }
                </Grid>
            }
            action={
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            }
        />
    );
};

export default CardTitle;

import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Card, Typography } from '@material-ui/core';

import { AccountWithCredit } from '../../../../@types';
import { getAccountWithTotalCredit } from '../../../../service/Management/accounts';
import { handleErrorResponse } from '../../../../service/utils';
import { paymentTypeLabel } from '../../../../common/Invoicing/Utils';
import usePermissions from '../../../../common/hooks/usePermissions';

interface Props {
    id: string;
}

const AccountSummary: React.FC<Props> = props => {
    const { id } = props;

    const history = useHistory();
    const dispatcher = useDispatch();
    const allowLinkToAccountDetail = usePermissions('API_ACCOUNT_GET_RECEIVABLE_INVOICES_LIST') ||
        usePermissions('API_ACCOUNT_GET_PAYABLE_INVOICES_LIST');

    const [account, setAccount] = React.useState<AccountWithCredit>({
        accountWithContacts: {
            account: {
                id: 0,
                name: '',
                enteredName: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zipCode: '',
                preferredPayType: '',
                achAccountingNo: '',
                internal: false,
            },
            contacts: [],
        },
        totalCredit: 0,
    } as AccountWithCredit)

    React.useEffect(() => {
        getAccountWithTotalCredit(Number(id)).then(res => {
            setAccount(res.data)
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                prefix: 'Could not retrieve Account: '
            });
        });
    }, [id]);

    return (
        <Card className='account-summary'>
            <Typography className={`heading ${allowLinkToAccountDetail ? 'cursor-pointer' : ''}`}
                onClick={() => allowLinkToAccountDetail ? history.push(`/manage/account/${id}`) : undefined}>
                {account.accountWithContacts.account.enteredName}
            </Typography>
            <div className='info-line padding'>
                <Typography variant='body2'>Address:</Typography>
                {(account.accountWithContacts.account.addressLine1
                    && account.accountWithContacts.account.city
                    && account.accountWithContacts.account.state
                    && account.accountWithContacts.account.zipCode) ?
                    <Typography variant='body2'>
                        {account.accountWithContacts.account.addressLine1} <br />
                        {account.accountWithContacts.account.addressLine2}
                        {account.accountWithContacts.account.addressLine2 && <br />}
                        {account.accountWithContacts.account.city}, {account.accountWithContacts.account.state} {account.accountWithContacts.account.zipCode}
                    </Typography> :
                    <Typography variant='body2'>
                        None
                    </Typography>
                }
            </div>
            <div className='info-line padding'>
                <Typography variant='body2'>Payment Preference:</Typography>
                {account.accountWithContacts.account.preferredPayType ?
                    <Typography variant='body2'>
                        {paymentTypeLabel(account.accountWithContacts.account.preferredPayType)}
                    </Typography> :
                    <Typography variant='body2'>
                        None
                    </Typography>
                }
            </div>
        </Card>
    );
}

export default AccountSummary;
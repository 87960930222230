import * as React from 'react';
import { TableCell, TableRow } from '@material-ui/core';

import { Calculation, CalculationType } from '../../../../@types';
import { displayStatisticsValue } from '../../../../common/Utils/NumberFormatter';

import MonthlyCalculationTableCell from './MonthlyCalculationTableCell';


interface Props {
    calculationTotals: Calculation[];
    monthlyCalculations: Calculation[];
    editYear: number;
    currentYear: number;
    currentMonth: number;
    titlePadding: boolean;
    onlyShowActivity?: boolean;
}

interface calculationDisplay {
    type: CalculationType;
    label: string;
}

const ActivityCostProductivityRows: React.FC<Props> = props => {
    const { calculationTotals, monthlyCalculations, editYear, currentYear, currentMonth, titlePadding, onlyShowActivity } = props;
    const paddingClass = titlePadding ? 'indent' : '';

    const displayCalculationTypes: calculationDisplay[] = onlyShowActivity 
        ? [
            { type: 'MONTHLY_ACTIVITY', label: 'Activity' }
        ] 
        : [
            { type: 'MONTHLY_ACTIVITY', label: 'Activity' },
            { type: 'MONTHLY_COST', label: 'Cost' },
            { type: 'PRODUCTIVITY', label: 'Productivity' }
        ];

    return (
        <>
            {displayCalculationTypes.map(display => {
                return (
                    <TableRow key={display.type}>
                        <TableCell className={`header-cell ${paddingClass} ${display.label.toLowerCase()} sticky`}>{display.label}</TableCell>
                        <TableCell className="text-align-right data-cell">
                            {displayStatisticsValue(calculationTotals.filter(total => (total.calculationType === display.type))[0]?.value)}
                        </TableCell>
                        <MonthlyCalculationTableCell
                            monthlyCalculations={monthlyCalculations}
                            calculationType={display.type}
                            editYear={editYear}
                            currentYear={currentYear}
                            currentMonth={currentMonth}
                        />
                    </TableRow>
                )
            })}
        </>
    )
}

export default ActivityCostProductivityRows;
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';
import { Formik, FormikHelpers } from 'formik';

import {
    Card, CardActions, CardContent, CardHeader,
    IconButton, Modal, Paper, Table,
    TableBody, TableCell, TableContainer, TableFooter,
    TableHead, TableRow, Tooltip
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, Cancel, Close, Delete, PowerSettingsNew, Save } from '@material-ui/icons';

import { BButton, BForm, BSubmit, BTextField } from 'mui-bueno';

import { Patient, PatientNote, PatientStatus } from '../../../../../@types';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../../../modules/messageSnackbarReducer';
import { displayDate } from '../../../../../common/Utils/utils';
import { handleErrorResponse } from '../../../../../service/utils';

import { createPatient, deletePatient, setPatientStatus, updatePatient } from '../../../../../service/Study/patient';
import {
    createPatientNote, deletePatientNote,
    getAllByPatientId, updatePatientNote
} from '../../../../../service/Study/patientNote';

import SimpleConfirmDelete from '../../../../../common/ConfirmDelete/SimpleConfirmDelete';
import usePermissions from '../../../../../common/hooks/usePermissions';

interface PatientNoteForm extends PatientNote {
    update?: boolean;
}

interface Props {
    open: boolean;
    setOpen: any;
    patient: Patient;
    setPatient: any;
}

const PatientModal: React.FC<Props> = props => {
    const { open, setOpen, patient, setPatient } = props;

    const dispatcher = useDispatch();

    const emptyPatientNote: PatientNoteForm = { id: null, comment: '', patientId: patient.id ?? -1 };

    const formRef = React.useRef<any>(null);

    const [updateIdentifierOpen, setUpdateIdentifierOpen] = React.useState<boolean>(false);
    const [deletePatientOpen, setDeletePatientOpen] = React.useState<boolean>(false);
    const [deletePatientNoteOpen, setDeletePatientNoteOpen] = React.useState<boolean>(false);
    const [patientNotes, setPatientNotes] = React.useState<PatientNote[]>([]);
    const [sortOrder, setSortOrder] = React.useState<boolean>(false);
    const [patientNote, setPatientNote] = React.useState<PatientNoteForm>(emptyPatientNote);
    const [patientActive, setPatientActive] = React.useState<boolean>(patient.status === 'ACTIVE');
    const [update, setUpdate] = React.useState<boolean>(false);
    const [reloadPage, setReloadPage] = React.useState<boolean>(false);

    const resetPatient = (): void => setPatient({ id: null, studyId: patient.studyId, identifier: '' });
    const resetPatientNote = (): void => setPatientNote({ ...emptyPatientNote, update: !patientNote.update });
    const patientNoteIds: number[] = patientNotes.map(note => note.id!);

    const sortedList: PatientNote[] = [...patientNotes].sort((a: PatientNote, b: PatientNote) =>
        (!sortOrder ? -1 : 1) * (new Date(a.created!).getTime() - new Date(b.created!).getTime())
    );

    // not check restricted division and study since no division information 
    // and user cannot access this modal if no access to the study
    const canCreatePatient = usePermissions('API_PATIENT_CREATE');
    const canUpdatePatient = usePermissions('API_PATIENT_UPDATE');
    const canSetPatientStatus = usePermissions('API_PATIENT_SET_PATIENT_STATUS');
    const canDeletePatient = usePermissions('API_PATIENT_DELETE_BY_ID');
    const canCreatePatientNote = usePermissions('API_PATIENT_NOTE_CREATE');
    const canDeletePatientNote = usePermissions('API_PATIENT_NOTE_DELETE_BY_ID');
    const canUpdatePatientNote = usePermissions('API_PATIENT_NOTE_UPDATE');
    const canGetPatientNotes = usePermissions('API_PATIENT_NOTE_GET_ALL_BY_PATIENT_ID');
    const canSavePatientIdentifier = (patient.id && canUpdatePatient) || (!patient.id && canCreatePatient);

    React.useEffect(() => {
        if (!patient.id || !canGetPatientNotes) return;
        getAllByPatientId(patient.id)
            .then(res => setPatientNotes(res.data))
            .catch(err => handleErrorResponse(err, dispatcher, {
                prefix: 'Could not retrieve list of PatientNotes: '
            }));
    }, [patient, update]);

    const handleClose = (): void => {
        setOpen(false);
        resetPatient();
        setPatientNote(emptyPatientNote);

        if (reloadPage) history.go(0);
    };

    const handleIdentifierSubmit = async (data: Patient, { setErrors }: FormikHelpers<Patient>) => {
        if (patient.id) {
            updatePatient({ ...data, patientNoteIds: patientNoteIds }).then(res => {
                dispatcher(showSuccessSnackbar('Patient updated'));
                setUpdateIdentifierOpen(false);
                setReloadPage(true);
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    setStatus: setErrors,
                    prefix: 'Could not update Patient: ',
                });
            });
        } else {
            createPatient({
                ...data,
                patientNoteIds: [],
                patientVisitIds: [],
            }).then(res => {
                dispatcher(showSuccessSnackbar(`Patient with identifier ${res.data.identifier} added`));
                history.go(0);
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    setStatus: setErrors,
                    prefix: 'Could not add Patient: ',
                });
            });
        }
    }

    const handleNoteSubmit = async (data: PatientNote, { setErrors }: FormikHelpers<PatientNote>) => {
        if (!patient.id) return;
        const { updated, ...submitData } = data; // Remove updated field
        if (data.id) {
            updatePatientNote(submitData)
                .then(res => {
                    dispatcher(showSuccessSnackbar('Patient Note updated'));
                    setUpdate(!update);
                    resetPatientNote();
                    setReloadPage(true);
                }).catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        setStatus: setErrors,
                        prefix: 'Could not update Patient Note: '
                    });
                });
        } else {
            createPatientNote(submitData)
                .then(res => {
                    dispatcher(showSuccessSnackbar('Patient Note created'));
                    setUpdate(!update);
                    resetPatientNote();
                    setReloadPage(true);
                })
                .catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        setStatus: setErrors,
                        prefix: 'Could not create Patient Note: '
                    });
                });
        }
    }

    const handlePatientDeletion = async () => {
        deletePatient(patient.id!).then(res => {
            setDeletePatientOpen(false);
            setOpen(false);
            history.go(0);
        }).catch((err: AxiosError) => {
            setDeletePatientOpen(false);
            dispatcher(showErrorSnackbar(err.response?.data.message));
        });
    };

    const handleMarkStatus = async (newStatus: PatientStatus) => {
        setPatientStatus(patient.id!, newStatus === 'ACTIVE').then(res => {
            dispatcher(showSuccessSnackbar(`Patient with identifier ${res.data.identifier} has been marked ${newStatus}`))
            setPatientActive(newStatus === 'ACTIVE');
            patient.status = newStatus;
        }).catch((err: AxiosError) => {
            dispatcher(showErrorSnackbar(err.response?.data.message));
        })
    }

    const closePatientNoteDelete = (value: boolean): void => {
        setDeletePatientNoteOpen(value);
        setPatientNote(emptyPatientNote);
    };

    const handlePatientNoteDeletion = async () => {
        if (!patientNote.id) return;
        deletePatientNote(patientNote.id)
            .then(res => {
                dispatcher(showSuccessSnackbar('Patient Note deleted'));
                setDeletePatientNoteOpen(false);
                resetPatientNote();
                setUpdate(!update);
                setReloadPage(true);
            })
            .catch(err => {
                handleErrorResponse(err, dispatcher, {
                    prefix: 'Could not delete Patient Note: '
                });
            });
    };

    const displayDeletePatientNote = (note: PatientNote) => {
        if (!canDeletePatientNote) return <></>;
        return (<Tooltip arrow title="Delete Note">
            <IconButton
                onClick={() => {
                    setPatientNote(note);
                    setDeletePatientNoteOpen(true);
                }}
            >
                <Delete />
            </IconButton>
        </Tooltip>);
    };

    return (
        <>
            <Modal
                open={open}
                onClose={() => handleClose()}
            >
                <div className="modal-form">
                    <Card className="detail-form sm">
                        <CardHeader
                            title={(patient.id ? 'Edit Patient' : 'Add New Patient') + (patientActive ? '' : ' (Inactive)')}
                            action={
                                <IconButton color="primary" aria-label="Close" onClick={() => handleClose()}>
                                    <Close />
                                </IconButton>
                            }
                        />
                        <CardContent style={{ padding: 0 }}>
                            <Formik
                                innerRef={formRef}
                                initialValues={patient}
                                onSubmit={handleIdentifierSubmit}
                                validateOnChange={false}
                                validateOnBlur={false}
                                enableReinitialize
                            >
                                <BForm>
                                    <div className="aligned-row flex-center margin-1">
                                        <BTextField
                                            name='identifier'
                                            label='Patient Identifier'
                                            disabled={!canSavePatientIdentifier || !patientActive}
                                            required autoFocus
                                            noMP
                                        />
                                        {canSavePatientIdentifier &&
                                            <BButton
                                                onClick={() => {
                                                    if (!patient.id)
                                                        formRef.current.handleSubmit()
                                                    else
                                                        setUpdateIdentifierOpen(true);
                                                }}
                                                startIcon={<Save />}
                                                variant="contained"
                                                color="primary"
                                                style={{ width: 100, marginLeft: 10 }}
                                                disabled={!patientActive}
                                            >
                                                Save
                                            </BButton>
                                        }
                                    </div>
                                </BForm>
                            </Formik>

                            {canGetPatientNotes && patient.id &&
                                <>
                                    <div className="patient-modal-separator" />
                                    <div className="flex-center margin-1">
                                        <Formik
                                            initialValues={patientNote}
                                            onSubmit={handleNoteSubmit}
                                            validateOnChange={false}
                                            validateOnBlur={false}
                                            enableReinitialize
                                        >
                                            <BForm>
                                                <TableContainer component={Paper} className="patient-note-table">
                                                    <Table stickyHeader>
                                                        <colgroup>
                                                            <col style={{ width: '15%' }} />
                                                            <col style={{ width: '70%' }} />
                                                            <col style={{ width: '15%' }} />
                                                        </colgroup>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell onClick={() => setSortOrder(!sortOrder)}>
                                                                    <div>
                                                                        Created
                                                                        {sortOrder ? <ArrowDropUp /> : <ArrowDropDown />}
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell>Note</TableCell>
                                                                <TableCell align="center">Actions</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {sortedList.map((note, index) => {
                                                                return (
                                                                    <TableRow key={`patient-note-${index}`} data-selected={patientNote.id === note.id}>
                                                                        <TableCell>{displayDate(note.created ?? null)}</TableCell>
                                                                        <TableCell onClick={() => { canUpdatePatientNote && setPatientNote(note) }}>
                                                                            {patientNote.id === note.id
                                                                                ? <BTextField
                                                                                    name="comment"
                                                                                    placeholder="Note"
                                                                                    label=""
                                                                                    variant="standard"
                                                                                    margin="none"
                                                                                    noMP
                                                                                    required={true}
                                                                                    disabled={false}
                                                                                    autoFocus
                                                                                    multiline
                                                                                />
                                                                                : note.comment
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {((patientNote.id === note.id) && !deletePatientNoteOpen)
                                                                                ? <>
                                                                                    <Tooltip arrow title="Save Changes">
                                                                                        <IconButton type="submit">
                                                                                            <Save />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                    <Tooltip arrow title="Discard Changes">
                                                                                        <IconButton type="button" onClick={() => resetPatientNote()}>
                                                                                            <Cancel />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </>
                                                                                : displayDeletePatientNote(note)
                                                                            }
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        </TableBody>
                                                        {canCreatePatientNote &&
                                                            <TableFooter>
                                                                <TableRow>
                                                                    <TableCell />
                                                                    <TableCell>
                                                                        <BTextField
                                                                            name="comment"
                                                                            placeholder="Note"
                                                                            label=""
                                                                            variant="standard"
                                                                            margin="none"
                                                                            noMP
                                                                            required={true}
                                                                            multiline
                                                                            disabled={!!patientNote.id}
                                                                            InputProps={{
                                                                                style: patientNote.id ? { display: 'none' } : {}
                                                                            }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <BSubmit
                                                                            startIcon={<Save />}
                                                                            variant="contained"
                                                                            color="primary"
                                                                            disabled={!!patientNote.id}
                                                                        >
                                                                            Add
                                                                        </BSubmit>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableFooter>
                                                        }
                                                    </Table>
                                                </TableContainer>
                                            </BForm>
                                        </Formik>
                                    </div>
                                </>}
                        </CardContent>
                        <CardActions className='space-between'>
                            {patient.id && canDeletePatient &&
                                <CardActions className="space-between">
                                    <BButton
                                        startIcon={<Delete />}
                                        variant="contained"
                                        className='delete-color'
                                        onClick={() => setDeletePatientOpen(true)}
                                    >
                                        Delete
                                    </BButton>
                                </CardActions>
                            }
                            {patient.id && patientActive && canSetPatientStatus &&
                                <CardActions className="space-between">
                                    <BButton
                                        startIcon={<PowerSettingsNew />}
                                        variant="contained"
                                        className='transparent-button'
                                        onClick={() => handleMarkStatus('INACTIVE')}
                                    >
                                        Deactivate
                                    </BButton>
                                </CardActions>
                            }
                            {patient.id && !patientActive && canSetPatientStatus &&
                                <CardActions className="space-between">
                                    <BButton
                                        startIcon={<PowerSettingsNew />}
                                        variant="contained"
                                        className='transparent-button'
                                        onClick={() => handleMarkStatus('ACTIVE')}
                                    >
                                        Activate
                                    </BButton>
                                </CardActions>
                            }
                        </CardActions>
                    </Card>
                </div>
            </Modal>
            <SimpleConfirmDelete
                type="Patient Identifier"
                open={updateIdentifierOpen}
                setOpen={setUpdateIdentifierOpen}
                objectName={patient.identifier}
                handleDelete={() => { formRef.current.handleSubmit() }}
                message={`Do you want to change the Patient Identifer for Patient "${patient.identifier}"?\nLine Items previously generated for this Patient will still reference the old identifier.`}
                title="Update Patient Identifier"
                buttonProps={{
                    children: 'Save',
                    className: 'primary-color',
                    startIcon: <Save />
                }}
            />
            <SimpleConfirmDelete
                type="Patient"
                open={deletePatientOpen}
                setOpen={setDeletePatientOpen}
                objectName={patient.identifier}
                handleDelete={handlePatientDeletion}
            />
            <SimpleConfirmDelete
                type="Patient Note"
                open={deletePatientNoteOpen}
                setOpen={closePatientNoteDelete}
                objectName="Patient Note"
                message={`Do you want to permanently delete the Patient Note from ${displayDate(patientNote.created ?? null)}?\nThis action cannot be undone.`}
                handleDelete={handlePatientNoteDeletion}
            />
        </>
    );
}

export default PatientModal;

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';

import { Box, Button, Tooltip, Typography } from '@material-ui/core';
import { Add, Delete, Edit, Info } from '@material-ui/icons';

import { Contract, Patient, PatientPageRequest, Procedure, SelectOption, Study, StudyUser, UnscheduledPatientVisitsTab, User } from '../../../../../@types';
import useLoggedInUserPermissions from '../../../../../common/hooks/useLoggedInUserPermissions';
import { convertReferenceDataToSelectionOptions } from '../../../../../common/Utils/utils';
import EditablePagingTable, { ActionOption, TableColumn } from '../../../../../common/DataTable/EditablePagingTable';

import { getPatientPage } from '../../../../../service/Study/patient';
import { getReferenceStudyRoles } from '../../../../../service/Reference/reference';
import { getProceduresFromContractWithTotalCost } from '../../../../../service/Contract/contracts';
import { getStudyCoordinators, getStudyProviders, getStudyUsers, getUnscheduledPatientVisitsTab } from '../../../../../service/Study/study';

import { Store } from '../../../../../modules/rootReducer';
import { showErrorSnackbar } from '../../../../../modules/messageSnackbarReducer';
import { CollapsiblePanelState } from '../../../../../modules/collapsiblePanelReducer';

import UnscheduledVisitModal from '../Modals/UnscheduledVisitModal';
import UnscheduledVisitDeleteModal from '../Modals/UnscheduledVisitDeleteModal';
import usePermissions from '../../../../../common/hooks/usePermissions';
import Loading from '../../../../../common/Routes/Loading';

interface State {
    unscheduledPatientVisitsList: any[];
}

const initialState: State = {
    unscheduledPatientVisitsList: [],
}

interface Action {
    type: 'load' | 'added' | 'edited' | 'deleted';
    payload?: any;
}

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'load':
            return {
                ...state,
                unscheduledPatientVisitsList: action.payload,
            }
        case 'added':
            return {
                ...state,
                unscheduledPatientVisitsList: [...state.unscheduledPatientVisitsList, action.payload]
            }
        case 'edited':
            return {
                ...state,
                unscheduledPatientVisitsList: state.unscheduledPatientVisitsList.map(el => el.id === action.payload.id ? action.payload : el)
            }
        case 'deleted':
            return {
                ...state,
                unscheduledPatientVisitsList: state.unscheduledPatientVisitsList.filter(item => item.id !== action.payload)
            }
        default:
            return state;
    }
}

interface UnscheduledVisitsTableProps {
    study: Study;
    currentContract: Contract;
}

const UnscheduledVisitsTable: React.FC<UnscheduledVisitsTableProps> = props => {
    const { study, currentContract } = props;
    const history = useHistory();

    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { unscheduledPatientVisitsList } = state;
    const [doneLoading, setDoneLoading] = React.useState<boolean>(false);

    // permissions
    const canViewUnscheduledPatientVisitsPage = useLoggedInUserPermissions('API_STUDY_GET_UNSCHEDULED_PATIENT_VISITS_PAGE', study.divisionId, study.id!);
    const canAddUnscheduledPatientVisits = useLoggedInUserPermissions('API_PATIENT_VISIT_CREATE', study.divisionId, study.id!);
    const canUpdateUnscheduledPatientVisits = useLoggedInUserPermissions('API_PATIENT_VISIT_UPDATE', study.divisionId, study.id!);
    const canDeleteUnscheduledPatientVisits = useLoggedInUserPermissions('API_PATIENT_VISIT_DELETE_BY_ID', study.divisionId, study.id!);

    /*
        Permission that will be used to determine if the logged in user can click on the employee chip
        and go to that user's statistics page. usePermissions('API_USER_CREATE') was used for now because it is a 
        permission that only division admins and system admins have, so this is a way to 'check' if
        the logged-in user is one of those roles.
    */
    const canGoToUserStats = usePermissions('API_USER_CREATE');

    const dispatcher = useDispatch();

    const handleAxiosError = (err: AxiosError) => {
        if (err.response?.status !== 403) {
            dispatcher(showErrorSnackbar(err.response?.data.message));
        }
    };

    const [patients, setPatients] = React.useState<Patient[]>([]);
    const [allProceduresFromContract, setAllProceduresFromContract] = React.useState<Procedure[]>([]);
    const [allCoordinatorsOnStudy, setAllCoordinatorsOnStudy] = React.useState<User[]>([]);
    const [allProvidersOnStudy, setAllProvidersOnStudy] = React.useState<User[]>([]);

    // State management for study roles
    const [allReferenceRoles, setAllReferenceRoles] = React.useState<SelectOption<number>[]>([]);

    // State management for all the users in the study
    const [studyUsers, setStudyUsers] = React.useState<StudyUser[]>([]);

    const { expanded } = useSelector<Store, CollapsiblePanelState>(
        store => store.collapsiblePanelReducer
    );

    const convertData = (data: any[]) => {
        const tempData: any[] = [];
        data.forEach((obj: UnscheduledPatientVisitsTab) => {
            tempData.push({
                patientVisit: obj.patientVisit,
                patient: obj.patient,
                fromPreviousContract: obj.fromPreviousContract,
                patientIdentifier: obj.patient.identifier,
                dateOfService: obj.patientVisit.dateOfService!,
                pvDescription: obj.patientVisit.visitName,
                users: obj.coordinators.concat(obj.providers),
                coordinators: obj.coordinators,
                providers: obj.providers,
                payment: obj.payment,
                holdback: obj.holdback,
                total: obj.total
            })
        })
        return tempData;
    };

    React.useEffect(() => {
        getStudyUsers(Number(study.id)).then(res => {
            setStudyUsers(res.data.studyUsers);
        }).catch(handleAxiosError);
        getStudyCoordinators(Number(study.id)).then(res => {
            setAllCoordinatorsOnStudy(res.data);
        }).catch(handleAxiosError);
        getStudyProviders(Number(study.id)).then(res => {
            setAllProvidersOnStudy(res.data);
        }).catch(handleAxiosError);
        getUnscheduledPatientVisitsTab(Number(study.id)).then(res => {
            const temp = res.data.map(obj => {
                return {
                    patient: obj.patient,
                    patientVisit: obj.patientVisit,
                    coordinators: Array.from(obj.coordinators),
                    providers: Array.from(obj.providers),
                    payment: obj.payment,
                    holdback: obj.holdback,
                    total: obj.total,
                    fromPreviousContract: obj.fromPreviousContract
                } as UnscheduledPatientVisitsTab
            })
            dispatch({ type: 'load', payload: convertData(temp) });
            setDoneLoading(true);
        }).catch(handleAxiosError);
        getReferenceStudyRoles().then(rolesRes => {
            const referenceRoles = convertReferenceDataToSelectionOptions(rolesRes.data)
            setAllReferenceRoles(referenceRoles);
        });
    }, []);

    React.useEffect(() => {
        const pageReq: PatientPageRequest = { studyId: Number(study.id) };
        getPatientPage(pageReq).then(res => {
            setPatients(res.data.list);
        }).catch(handleAxiosError);
    }, [study]);

    React.useEffect(() => {
        if (currentContract.id) {
            getProceduresFromContractWithTotalCost(currentContract.id).then(res => {
                setAllProceduresFromContract(res.data);
            }).catch(handleAxiosError);
        }
    }, [currentContract]);

    // Can a new unscheduled patient visit be added
    const canAddNew = !!study.started && !study.completed;

    const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [clickedObj, setClickedObj] = React.useState<null | any>(null);
    const [editClicked, setEditClicked] = React.useState<boolean>(false);
    const [modalMode, setModalMode] = React.useState<'VIEW' | 'ADD' | 'EDIT'>('ADD');

    // Effect hook to handle an issue where the clickedObj was returning null when trying to determine which mode to set
    React.useEffect(() => {
        if (clickedObj && editClicked) {
            if (clickedObj.fromPreviousContract || study.completed) {
                setModalMode('VIEW');
            } else {
                setModalMode('EDIT');
            }
            setModalOpen(true);
        }
        setEditClicked(false);
    }, [editClicked]);

    const handleAdd = () => {
        setModalMode('ADD');
        setModalOpen(true);
    }
    const handleEdit = () => {
        setEditClicked(true);
    };
    const handleDelete = () => {
        setDeleteModalOpen(true);
    }

    const actions: ActionOption[] = study.completed ? [
        { label: 'View', icon: <Info />, handler: handleEdit, permission: canUpdateUnscheduledPatientVisits, disabled: false }
    ] : [
        { label: 'Edit', icon: <Edit />, handler: handleEdit, permission: canUpdateUnscheduledPatientVisits, disabled: !canAddNew },
        { label: 'Delete', icon: <Delete />, handler: handleDelete, permission: canDeleteUnscheduledPatientVisits, disabled: !canAddNew }
    ];

    const columns: TableColumn[] = [{
        type: 'patientIdentifier',
        displayValue: 'Patient',
        align: 'left',
        placeholder: 'Patient',
        required: true,
    }, {
        type: 'dateOfService',
        displayValue: 'Date of Service',
        align: 'left',
        placeholder: 'Date of Service',
        required: true,
        style: { width: 170 },
        format: 'date'
    }, {
        type: 'pvDescription',
        displayValue: 'Description',
        align: 'left',
        placeholder: 'Description...',
        required: true,
    }, {
        type: 'patientVisitUsers',
        format: 'employees',
        displayValue: 'Employees',
        align: 'left',
        placeholder: '',
        required: false,
        style: { width: 200 },
        referenceRoles: allReferenceRoles,
        studyUsers: studyUsers,
        canGoToUserStats: canGoToUserStats
    }, {
        type: 'payment',
        displayValue: 'Amount Minus Holdback',
        align: 'right',
        required: false,
        style: { width: 170 },
        format: 'currency-US'
    }, {
        type: 'holdback',
        displayValue: 'Holdback',
        align: 'right',
        required: false,
        style: { width: 100 },
        format: 'currency-US',
        disabled: true,
        tooltipTitle: 'Holdback amount will be calculated once the incurred cost is added'
    }, {
        type: 'total',
        displayValue: 'Total',
        align: 'right',
        required: false,
        style: { width: 170 },
        format: 'currency-US'
    }];

    if (!canViewUnscheduledPatientVisitsPage) {
        return (
            <div className="page">
                <div className="page-content">
                    <div className="inner">
                        <div className="full-screen centered">
                            <Box textAlign="center" className="page-body">
                                <Typography gutterBottom variant="h1">
                                    Unscheduled Visits Table Unavailable
                                </Typography>
                                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                                    You do not have the proper permissions to access the unscheduled visits table.
                                    Please contact your division or institution administrator for more information.
                                </Typography>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => history.push('/study')}
                                    style={{ margin: 40 }}
                                >
                                    Go Back to Your Studies
                                </Button>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const tooltipText = () => {
        if (!canAddUnscheduledPatientVisits) {
            return 'You do not have permissions to add unscheduled patient visits.';
        }
        if (!study.started) {
            return 'Cannot perform this action before the study has been started';
        }
        if (study.completed) {
            return 'Cannot perform this action after the study has been completed';
        }
        return '';
    }

    if (doneLoading) {
        return (
            <>
                <div className={'constrainable' + (expanded ? ' expanded' : 'collapsed')} style={{ padding: '15px 0px 25px' }}>
                    <Tooltip title={tooltipText()}>
                        <Button
                            id="open-create-from-planned"
                            variant="contained"
                            color="primary"
                            startIcon={<Add />}
                            onClick={handleAdd}
                            disabled={!canAddUnscheduledPatientVisits || !study.started || !!study.completed}
                            style={{ pointerEvents: 'auto' }}
                        >
                            Add Unscheduled Visit
                        </Button>
                    </Tooltip>
                    <EditablePagingTable
                        dataList={unscheduledPatientVisitsList}
                        tableInfoColumns={columns}
                        editingDataObject={null}
                        handleSubmit={null}
                        validationSchema={null}
                        readonly={!canAddUnscheduledPatientVisits}
                        canCreate={false}
                        clickedObj={clickedObj}
                        setClickedObj={setClickedObj}
                        actionOptions={actions}
                    />
                </div>
                {modalOpen &&
                    <UnscheduledVisitModal
                        open={modalOpen}
                        setOpen={setModalOpen}
                        allProceduresFromContract={allProceduresFromContract}
                        allCoordinatorsOnStudy={allCoordinatorsOnStudy}
                        allProvidersOnStudy={allProvidersOnStudy}
                        patientVisit={clickedObj}
                        patients={patients}
                        contract={currentContract}
                        study={study}
                        mode={modalMode}
                    />
                }
                {clickedObj && deleteModalOpen &&
                    <UnscheduledVisitDeleteModal
                        open={deleteModalOpen}
                        setOpen={setDeleteModalOpen}
                        patientVisitData={clickedObj}
                    />
                }
            </>
        )
    } else {
        return (
            <Loading />
        )
    }
}
export default UnscheduledVisitsTable;
import * as React from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import {
    Card, CardActions, CardContent, CardHeader, Checkbox, FormControl, FormControlLabel, Grid,
    IconButton, InputAdornment, Modal, Switch, Table, TableBody, TableCell, TableContainer, TableRow, Typography
} from '@material-ui/core';
import { Close, NavigateNext } from '@material-ui/icons';
import { BButton, BDatePicker, BForm, BGrid, BOption, BSelect, BSubmit, BTextField } from 'mui-bueno';
import { Formik, FormikHelpers } from 'formik';

import { Contract, Patient, PatientVisitUser, Procedure, SelectOption, Study, User, UserSummary } from '../../../../../@types';
import { showWarningSnackbar } from '../../../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../../../service/utils';
import { createPatientVisit, getUnscheduledPatientVisitProcedures, updatePatientVisit } from '../../../../../service/Study/patientVisit';

import useLoggedInUser from '../../../../../common/hooks/useLoggedInUser';
import { getLocalDate } from '../../../../../common/Utils/utils';
import { NumberFormatter } from '../../../../../common/Utils/NumberFormatter';
import CheckedAutocomplete from '../../../../../common/CheckedAutocomplete/CheckedAutocomplete';
import { DisplayFullName, DisplayTextFormat } from '../../../../../common/Utils/DisplayTextFormat';

import { TableHeader } from './ScheduledVisitModal';
import VisitModalWarning from './VisitModalWarning';
import {
    convertToUserSummaries,
    createPerformedProcedures, getProcedureCosts, handleValidateUser,
    toSelectOptionsPatientVisitSingleUser, toSelectOptionsPatientVisitUser, validateVisitDateOfService
} from './utils';

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    allProceduresFromContract?: Procedure[];
    allCoordinatorsOnStudy?: User[];
    allProvidersOnStudy?: User[];
    patients?: Patient[];
    patientVisit?: any;
    contract?: Contract;
    study?: Study;
    mode: 'VIEW' | 'ADD' | 'EDIT';
    lineItem?: boolean;
}

interface FormValues {
    patientId: number;
    patientIdentifier?: string;
    dateOfService: Date;
    description: string;
    note: string;
    coordinators: number[];
    providers: number[];
    procedures: number[];
    payment: string;
    holdback: string;
    total: string;
}

const UnscheduledVisitModal: React.FC<Props> = props => { //NOSONAR
    const {
        open, setOpen, patients, study, patientVisit, mode, lineItem,
        allProceduresFromContract, allCoordinatorsOnStudy, allProvidersOnStudy
    } = props;

    const dispatch = useDispatch();

    const loggedInUser = useLoggedInUser();

    const [procedureIdsFromPerformedProcedure, setProcedureIdsFromPerformedProcedure] = React.useState<number[]>([]);
    const [allCurrentProceduresFromContract, setAllCurrentProceduresFromContract] = React.useState<Procedure[]>([]);
    const [fromPreviousContract, setFromPreviousContract] = React.useState<boolean>(false);

    const [patientVisitCoordinators, setPatientVisitCoordinators] = React.useState<PatientVisitUser[]>([]);
    const [patientVisitProviders, setPatientVisitProviders] = React.useState<PatientVisitUser[]>([]);
    const [patientVisitCoordinatorOptions, setPatientVisitCoordinatorOptions] = React.useState<SelectOption<number>[]>([]);
    const [patientVisitProviderOptions, setPatientVisitProviderOptions] = React.useState<SelectOption<number>[]>([]);
    const [patientVisitEmployeeOptions, setPatientVisitEmployeeOptions] = React.useState<SelectOption<number>[]>([]);
    const [remainingCoordinatorEffort, setRemainingCoordinatorEffort] = React.useState<number>(100);
    const [remainingProviderEffort, setRemainingProviderEffort] = React.useState<number>(100);

    const [allCoordinatorUserSummaries, setAllCoordinatorUserSummaries] = React.useState<UserSummary[]>([]);
    const [allProviderUserSummaries, setAllProviderUserSummaries] = React.useState<UserSummary[]>([]);

    const [multipleEmployees, setMultipleEmployees] = React.useState<boolean>(false);

    // State management for autocomplete options
    const [selectedCoordinator, setSelectedCoordinator] = React.useState<null | SelectOption<number>>(null);
    const [selectedProvider, setSelectedProvider] = React.useState<null | SelectOption<number>>(null);
    const [selectedSingleEmployee, setSelectedSingleEmployee] = React.useState<null | SelectOption<number>>(null);

    // unsaved changes states
    const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
    const [unsavedChanges, setUnsavedChanges] = React.useState<boolean>(false);

    const currentDate: Date = new Date();
    const initialValues: FormValues = (mode == 'ADD' ? {
        patientId: 0,
        dateOfService: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0),
        description: '',
        note: '',
        coordinators: [],
        providers: [],
        procedures: [],
        payment: '0',
        holdback: '0',
        total: '0',
    } : {
        patientId: patientVisit.patient.id!,
        patientIdentifier: patientVisit.patient.identifier,
        dateOfService: getLocalDate(patientVisit.patientVisit.dateOfService!),
        description: patientVisit.patientVisit.visitName,
        note: patientVisit.patientVisit.note,
        coordinators: Array.from(patientVisit.coordinators),
        providers: Array.from(patientVisit.providers),
        procedures: procedureIdsFromPerformedProcedure,
        payment: patientVisit.payment.toString(),
        holdback: patientVisit.holdback.toString(),
        total: patientVisit.total.toString(),
    });

    React.useEffect(() => {
        if (open) {
            // Set the coordinator and provider user summaries
            setAllCoordinatorUserSummaries(convertToUserSummaries(allCoordinatorsOnStudy!));

            setAllProviderUserSummaries(convertToUserSummaries(allProvidersOnStudy!));

            if (mode == 'ADD') {
                setMultipleEmployees(false);
                // When creating a new patient visit, if the logged in user is a coordinator on the study, then automatically add them to the patient visit
                if (_.find(allCoordinatorsOnStudy, ['id', loggedInUser.id]) != undefined) {
                    setPatientVisitProviders([]);
                    setPatientVisitCoordinators([{
                        userId: loggedInUser.id!,
                        userName: DisplayFullName(loggedInUser.firstName, null, loggedInUser.lastName),
                        resourceType: 'C',
                        effort: 100
                    }]);
                    setRemainingCoordinatorEffort(0);
                } else if (_.find(allProvidersOnStudy, ['id', loggedInUser.id]) != undefined) {
                    setPatientVisitCoordinators([]);
                    setPatientVisitProviders([{
                        userId: loggedInUser.id!,
                        userName: DisplayFullName(loggedInUser.firstName, null, loggedInUser.lastName),
                        resourceType: 'P',
                        effort: 100
                    }]);
                    setRemainingProviderEffort(0);
                }
            } else {
                getUnscheduledPatientVisitProcedures(patientVisit.patientVisit.id!).then(res => {
                    setProcedureIdsFromPerformedProcedure(res.data.procedureIdsFromPerformedProcedure);
                    setAllCurrentProceduresFromContract(res.data.allProceduresFromContract);
                    setFromPreviousContract(res.data.fromPreviousContract);
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not get procedures: '
                    });
                });

                const multipleCoordinators = patientVisit.coordinators.length > 1;
                const multipleProviders = patientVisit.providers.length > 1;
                const hasBoth = patientVisit.coordinators.length >= 1 && patientVisit.providers.length >= 1;
                if (multipleCoordinators || multipleProviders || hasBoth) {
                    setMultipleEmployees(true);
                }

                setPatientVisitCoordinators(Array.from(patientVisit.coordinators));
                setPatientVisitProviders(Array.from(patientVisit.providers));

                if (mode == 'EDIT') {
                    // Set initial remaining effort amounts for coordinators and providers (should be either 0 or 100)
                    let initialRemainingCoordinatorEffort: number = 100;
                    let initialRemainingProviderEffort: number = 100;
                    patientVisit.coordinators.forEach((coordinator: PatientVisitUser) => {
                        initialRemainingCoordinatorEffort = initialRemainingCoordinatorEffort - coordinator.effort;
                    });
                    setRemainingCoordinatorEffort(initialRemainingCoordinatorEffort);

                    patientVisit.providers.forEach((provider: PatientVisitUser) => {
                        initialRemainingProviderEffort = initialRemainingProviderEffort - provider.effort;
                    });
                    setRemainingProviderEffort(initialRemainingProviderEffort);
                }
            }
        }
    }, [open]);

    // Refresh the user options when PatientVisitCoordinators or PatientVisitProviders got changed and modal is open
    React.useEffect(() => {
        setPatientVisitCoordinatorOptions(toSelectOptionsPatientVisitUser(allCoordinatorUserSummaries, 'C', patientVisitCoordinators));
        setPatientVisitProviderOptions(toSelectOptionsPatientVisitUser(allProviderUserSummaries, 'P', patientVisitProviders));
        setPatientVisitEmployeeOptions(toSelectOptionsPatientVisitSingleUser(allCoordinatorUserSummaries, allProviderUserSummaries, selectedSingleEmployee?.value));
        if (patientVisitCoordinators.length == 1 && patientVisitProviders.length == 0) {
            const employee = patientVisitCoordinators[0];
            setSelectedSingleEmployee({
                value: Number(employee.userId),
                label: employee.userName + ' (Coordinator)'
            });
        } else if (patientVisitCoordinators.length == 0 && patientVisitProviders.length == 1) {
            const employee = patientVisitProviders[0];
            setSelectedSingleEmployee({
                value: Number(employee.userId),
                label: employee.userName + ' (Provider)'
            });
        }
    }, [allCoordinatorUserSummaries, allProviderUserSummaries, patientVisitCoordinators, patientVisitProviders, open]);

    const handleModalClose = () => {
        if (unsavedChanges && mode != 'VIEW') {
            setDialogOpen(true);
        } else {
            setOpen(false);
        }
    }

    const handleDialogClose = (accept: boolean) => {
        setDialogOpen(false);
        if (accept) {
            setUnsavedChanges(false);
            setOpen(false);
        }
    }

    // Handlers for PatientVisit form
    const validate = (values: FormValues) => {
        const errors: { [key: string]: string } = {};
        if (study) validateVisitDateOfService(values.dateOfService, study, errors);
        return errors;
    };
    const handleSubmit = async (data: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
        if (selectedCoordinator || selectedProvider) {
            dispatch(showWarningSnackbar('Coordinators/Providers list has unsaved changes'))
            return;
        }
        // validate at least one procedure has been added to the unscheduled visit
        const removed = procedureIdsFromPerformedProcedure.filter(x => !data.procedures.includes(x));
        const added = data.procedures.filter(x => !procedureIdsFromPerformedProcedure.includes(x));
        if (mode == 'EDIT') {
            if (added.length == 0 && procedureIdsFromPerformedProcedure.length == 0 ||
                removed.length == procedureIdsFromPerformedProcedure.length && added.length == 0) {
                dispatch(showWarningSnackbar('Unscheduled patient visit must have at least one procedure.'))
                return;
            }
        } else {
            if (data.procedures.length == 0) {
                dispatch(showWarningSnackbar('Unscheduled patient visit must have at least one procedure.'))
                return;
            }
        }

        // if there are no coordinators selected, display warning
        if ((multipleEmployees && patientVisitCoordinators.length == 0)
            || (!multipleEmployees && !selectedSingleEmployee?.label.endsWith('(Coordinator)'))) {
            dispatch(showWarningSnackbar('Patient visit must have at least one coordinator.'))
            return;
        }
        const costs = allProceduresFromContract ? getProcedureCosts(allProceduresFromContract, data.procedures) : [];
        const employees = () => {
            if (multipleEmployees) {
                return patientVisitCoordinators.concat(patientVisitProviders);
            } else {
                if (selectedSingleEmployee?.label.endsWith('(Coordinator)')) {
                    return [{
                        userId: selectedSingleEmployee.value,
                        userName: selectedSingleEmployee.label,
                        resourceType: 'C',
                        effort: 100
                    }];
                }
                if (selectedSingleEmployee?.label.endsWith('(Provider)')) {
                    return [{
                        userId: selectedSingleEmployee.value,
                        userName: selectedSingleEmployee.label,
                        resourceType: 'P',
                        effort: 100
                    }];
                }
            }
            return [];
        }
        if (mode == 'EDIT') {
            await updatePatientVisit({
                patientVisit: {
                    id: patientVisit.patientVisit.id!,
                    scheduledVisitId: null,
                    patientId: data.patientId,
                    dateOfService: data.dateOfService,
                    visitName: data.description,
                    note: data.note,
                },
                patientVisitUserList: employees(),
                addedPerformedProcedures: createPerformedProcedures(added, costs, patientVisit.patientVisit.id),
                deletedProcedureIds: removed
            }).then(() => {
                setUnsavedChanges(false);
                setOpen(false);
                history.go(0);
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not update Patient Visit: '
                });
            });
        } else {
            await createPatientVisit({
                patientVisit: {
                    id: null,
                    scheduledVisitId: null,
                    patientId: data.patientId,
                    dateOfService: data.dateOfService,
                    visitName: data.description,
                    note: data.note,
                },
                patientVisitUserList: employees(),
                addedPerformedProcedures: createPerformedProcedures(data.procedures, costs, null),
                deletedProcedureIds: []
            }).then(() => {
                setUnsavedChanges(false);
                setOpen(false);
                history.go(0);
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not create Patient Visit: '
                });
            });
        }
    };

    const patientsMapped = () => {
        if (patients && patients.length > 0) {
            if (mode === 'ADD') {
                const activePatients = patients.filter(pat => pat.status === 'ACTIVE');
                if (activePatients.length > 0) return activePatients.map(pat => ({ value: pat.id, label: pat.identifier } as BOption<number>));
            } else {
                return patients.map(pat => ({ value: pat.id, label: pat.identifier } as BOption<number>));
            }
        }
        return [];
    }

    const handleCheckboxChange = (procedure: Procedure, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void, values: FormValues) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const cost = procedure.totalCost ?? 0;
        const selectedProcedureHoldback = procedure.holdback ?? 0;
        let newTotal = 0;
        let newHoldback = 0;
        if (event.target.checked) {
            setFieldValue('procedures', [...values.procedures, procedure.id!], false);
            newTotal = Number(values.total) + cost;
            newHoldback = Number(values.holdback) + selectedProcedureHoldback;
        } else {
            setFieldValue('procedures', values.procedures.filter(index => index !== procedure.id!), false);
            newTotal = Number(values.total) - cost;
            newHoldback = Number(values.holdback) - selectedProcedureHoldback;
        }
        const newPayment = newTotal - newHoldback;
        setFieldValue('payment', newPayment, false);
        setFieldValue('holdback', newHoldback, false);
        setFieldValue('total', newTotal, false);
        setUnsavedChanges(true);
    };

    // FUNCTIONS/CONSTANTS FOR PATIENT VISIT USERS
    const handleAddCoordinator = (data: any, { resetForm, setErrors }: FormikHelpers<any>) => {
        if (selectedCoordinator != null) {
            const errors = handleValidateUser(data);
            if (Object.keys(errors).length > 0) {
                setErrors(errors);
                return;
            }
            setPatientVisitCoordinators([...patientVisitCoordinators, {
                userId: selectedCoordinator.value,
                userName: selectedCoordinator.label,
                resourceType: 'C',
                effort: data.effort
            }]);
            setRemainingCoordinatorEffort(remainingCoordinatorEffort - Number(data.effort));
            setSelectedCoordinator(null);
            setUnsavedChanges(true);
            resetForm();
        }
    };
    const handleAddProvider = (data: any, { resetForm, setErrors }: FormikHelpers<any>) => {
        if (selectedProvider != null) {
            const errors = handleValidateUser(data);
            if (Object.keys(errors).length > 0) {
                setErrors(errors);
                return;
            }
            setPatientVisitProviders([...patientVisitProviders, {
                userId: selectedProvider.value,
                userName: selectedProvider.label,
                resourceType: 'P',
                effort: data.effort
            }]);
            setRemainingProviderEffort(remainingProviderEffort - Number(data.effort));
            setSelectedProvider(null);
            setUnsavedChanges(true);
            resetForm();
        }
    };
    const handleChangeEmployee = (value: any, type: 'C' | 'P' | 'S') => {
        setUnsavedChanges(true);
        if (type == 'C') {
            return setSelectedCoordinator(value);
        } else if (type == 'P') {
            return setSelectedProvider(value);
        } else {
            return setSelectedSingleEmployee(value);
        }
    };
    const handleDeleteEmployee = (_event: React.MouseEvent<HTMLElement>, obj: PatientVisitUser, coordinator: boolean) => {
        if (coordinator) {
            setRemainingCoordinatorEffort(remainingCoordinatorEffort + Number(obj.effort));
            setPatientVisitCoordinators(patientVisitCoordinators.filter(usr => usr.userId !== obj.userId));
        } else {
            setRemainingProviderEffort(remainingProviderEffort + Number(obj.effort));
            setPatientVisitProviders(patientVisitProviders.filter(usr => usr.userId !== obj.userId));
        }
        setUnsavedChanges(true);
    };

    const employeeTableForm = (coordinator: boolean) => {
        const noCoordinators = patientVisitCoordinators.length < 1;
        const noProviders = patientVisitProviders.length < 1
        if (mode == 'VIEW' && ((coordinator && noCoordinators) || (!coordinator && noProviders))) {
            return;
        }
        return (
            <Grid
                item xs={12} className='table-accordion'
                md={mode == 'VIEW' && ((!coordinator && noCoordinators) || (coordinator && noProviders)) ? 12 : 6}
            >
                <Card>
                    <div className='card-header'>{coordinator ? 'Coordinator' : 'Provider'}</div>
                    <Formik
                        initialValues={coordinator ? {
                            coordinator: null,
                            effort: remainingCoordinatorEffort < 0 ? 0 : remainingCoordinatorEffort
                        } : {
                            provider: null,
                            effort: remainingProviderEffort < 0 ? 0 : remainingProviderEffort
                        }}
                        onSubmit={coordinator ? handleAddCoordinator : handleAddProvider}
                        validate={handleValidateUser}
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize
                    >
                        {userFormikProps => (
                            <TableContainer className='input-table'>
                                <Table>
                                    {TableHeader(mode == 'VIEW' ? true : false)}
                                    <TableBody>
                                        {(coordinator ? patientVisitCoordinators : patientVisitProviders).map((obj: PatientVisitUser, idx: number) => {
                                            return (
                                                <TableRow key={(coordinator ? 'coordinator-row-' : 'provider-row-') + idx}>
                                                    <TableCell key={'name-' + idx} className="body-cell" style={{ width: '60%' }}>{DisplayTextFormat(obj.userName)}</TableCell>
                                                    <TableCell key={'effort-' + idx} className="body-cell">{obj.effort} %</TableCell>
                                                    {mode != 'VIEW' &&
                                                        <TableCell key='header-actions' className="body-cell center" style={{ width: '10%' }}>
                                                            <IconButton
                                                                aria-controls="more-actions-menu"
                                                                aria-haspopup="true"
                                                                onClick={(event) => handleDeleteEmployee(event, obj, coordinator)}
                                                            >
                                                                <Close />
                                                            </IconButton>
                                                        </TableCell>
                                                    }
                                                </TableRow>
                                            )
                                        })}
                                        {mode != 'VIEW' && (coordinator ? patientVisitCoordinatorOptions : patientVisitProviderOptions).length > 0 &&
                                            <TableRow>
                                                <TableCell className='body-cell left' style={{ width: '60%' }}>
                                                    <CheckedAutocomplete
                                                        idText={coordinator ? 'coordinator' : 'provider'}
                                                        multiple={false}
                                                        acValue={coordinator ? selectedCoordinator : selectedProvider}
                                                        options={coordinator ? patientVisitCoordinatorOptions : patientVisitProviderOptions}
                                                        onChange={(value: any) => handleChangeEmployee(value, (coordinator ? 'C' : 'P'))}
                                                    />
                                                </TableCell>
                                                <TableCell className='body-cell left'>
                                                    <BTextField
                                                        name='effort'
                                                        label=''
                                                        placeholder='0'
                                                        variant='standard'
                                                        margin='none'
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                            inputComponent: NumberFormatter as any,
                                                            inputProps: { integer: true }
                                                        }}
                                                        required
                                                    />
                                                </TableCell>
                                                <TableCell className='body-cell center' style={{ width: '10%' }}>
                                                    <BButton
                                                        variant='text'
                                                        className={(coordinator ? selectedCoordinator : selectedProvider) != null && userFormikProps.values.effort ? 'pulse-btn' : 'disabled-btn'}
                                                        onClick={() => {
                                                            if (coordinator) { handleAddCoordinator(userFormikProps.values, userFormikProps) }
                                                            if (!coordinator) { handleAddProvider(userFormikProps.values, userFormikProps) }
                                                        }}
                                                    >
                                                        Add
                                                    </BButton>
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Formik>
                </Card>
            </Grid>
        )
    }

    const cardTitle = () => {
        if (mode == 'VIEW') return ('Unscheduled Patient Visit: ' + patientVisit.patientVisit.visitName);
        if (mode == 'ADD') return ('Add Unscheduled Patient Visit');
        if (mode == 'EDIT') return ('Edit Unscheduled Patient Visit: ' + patientVisit.patientVisit.visitName);
        if (lineItem) {
            return (
                <div className="aligned-row">
                    <span>Line Item Info</span>
                    <NavigateNext className="arrow" />
                    <span>Unscheduled Patient Visit</span>
                </div>
            )
        }
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleModalClose}
            >
                <div className='modal-form'>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validate={validate}
                        validateOnChange={false}
                        validateOnBlur={true}
                        enableReinitialize
                    >
                        {formikProps => (
                            <BForm>
                                <Card className='detail-form lg'>
                                    <CardHeader
                                        title={cardTitle()}
                                        action={
                                            <IconButton color="primary" aria-label="Close" onClick={handleModalClose}>
                                                <Close />
                                            </IconButton>
                                        }
                                    />
                                    <CardContent>
                                        <div className='form-section'>
                                            <Typography className="title">Basic Information</Typography>
                                            <BGrid container gridClassName='body' spacing={2}>
                                                {mode == 'VIEW' ?
                                                    <BTextField
                                                        name="patientIdentifier"
                                                        label="Patient Identifier"
                                                        disabled
                                                        xs={12} sm={6} noMP
                                                    /> :
                                                    <BSelect
                                                        name="patientId"
                                                        label="Patient Identifier"
                                                        options={patientsMapped()}
                                                        variant="outlined"
                                                        required autoFocus
                                                        xs={12} sm={6} noMP
                                                        onChange={() => setUnsavedChanges(true)}
                                                    />
                                                }
                                                <BDatePicker
                                                    name="dateOfService"
                                                    label="Date of Service"
                                                    inputVariant="outlined"
                                                    disabled={mode == 'VIEW'}
                                                    xs={12} sm={6} noMP
                                                    onAccept={() => setUnsavedChanges(true)}
                                                    onBlur={() => setUnsavedChanges(true)}
                                                />
                                                <BTextField
                                                    name="description"
                                                    label="Description"
                                                    disabled={mode == 'VIEW'}
                                                    multiline
                                                    xs={12} noMP
                                                    onChange={() => setUnsavedChanges(true)}
                                                />
                                                <BTextField
                                                    name="note"
                                                    label="Notes"
                                                    disabled={mode == 'VIEW'}
                                                    multiline rows={2}
                                                    xs={12} noMP
                                                    onChange={() => setUnsavedChanges(true)}
                                                />
                                            </BGrid>
                                        </div>
                                        <div className='form-section not-first'>
                                            <Grid container justifyContent='space-between' alignItems='center'>
                                                <Typography className="title">Employees</Typography>
                                                {mode != 'VIEW' &&
                                                    <div className='row multiple-switch'>
                                                        <Switch checked={multipleEmployees} onClick={() => setMultipleEmployees(!multipleEmployees)} />
                                                        <Typography>Multiple Employees</Typography>
                                                    </div>
                                                }
                                            </Grid>
                                            <BGrid container gridClassName='body'>
                                                {multipleEmployees ? <>
                                                    {employeeTableForm(true)}
                                                    {employeeTableForm(false)}
                                                </> :
                                                    <Grid item xs={12} className='single-employee-input'>
                                                        <CheckedAutocomplete
                                                            idText='employee'
                                                            labelText='Employee'
                                                            multiple={false}
                                                            acValue={selectedSingleEmployee}
                                                            options={patientVisitEmployeeOptions}
                                                            onChange={(value: any) => handleChangeEmployee(value, 'S')}
                                                            variant='outlined'
                                                            disabled={mode == 'VIEW'}
                                                        />
                                                    </Grid>
                                                }
                                            </BGrid>
                                        </div>
                                        <div className='form-section not-first'>
                                            <Typography className="title">Procedures Completed</Typography>
                                            <BGrid container gridClassName='body procedures'>
                                                <FormControl component="fieldset">
                                                    <Grid container>
                                                        {(allProceduresFromContract ? allProceduresFromContract : allCurrentProceduresFromContract).map((procedure: Procedure, idx: number) => {
                                                            return (
                                                                <Grid item xs={12} sm={6} lg={4} key={'procedure-' + procedure.id + 'idx-' + idx}>
                                                                    <FormControlLabel
                                                                        value={procedure.name}
                                                                        control={
                                                                            <Checkbox
                                                                                color="primary"
                                                                                checked={formikProps.values.procedures.includes(procedure.id!)}
                                                                                onChange={handleCheckboxChange(procedure, formikProps.setFieldValue, formikProps.values)}
                                                                                disabled={mode == 'VIEW'}
                                                                            />
                                                                        }
                                                                        label={procedure.name + ' (' + procedure.resourceType + ')'}
                                                                        labelPlacement="end"
                                                                    />
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid>
                                                </FormControl>
                                            </BGrid>
                                        </div>
                                        <div className='form-section not-first'>
                                            <Typography className="title">Costs</Typography>
                                            <BGrid container gridClassName='body procedures' spacing={2}>
                                                <BTextField
                                                    name="payment"
                                                    label="Amount minus Holdback"
                                                    disabled
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        inputComponent: NumberFormatter as any
                                                    }}
                                                    inputProps={{ className: 'right-input-text' }}
                                                    xs={4} noMP
                                                />
                                                <BTextField
                                                    name="holdback"
                                                    label="Holdback"
                                                    disabled
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        inputComponent: NumberFormatter as any
                                                    }}
                                                    inputProps={{ className: 'right-input-text' }}
                                                    xs={4} noMP
                                                />
                                                <BTextField
                                                    name="total"
                                                    label="Total"
                                                    disabled
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        inputComponent: NumberFormatter as any
                                                    }}
                                                    inputProps={{ className: 'right-input-text' }}
                                                    xs={4} noMP
                                                />
                                            </BGrid>
                                        </div>
                                    </CardContent>
                                    {mode != 'VIEW' &&
                                        <CardActions className='flex-end'>
                                            <BSubmit
                                                variant="contained"
                                                color="primary"
                                                disabled={mode == 'EDIT' && fromPreviousContract}
                                            >
                                                {mode == 'EDIT' ? 'Submit' : 'Save'}
                                            </BSubmit>
                                        </CardActions>
                                    }
                                </Card>
                            </BForm>
                        )}
                    </Formik>
                </div>
            </Modal>
            {dialogOpen &&
                <VisitModalWarning
                    unsavedChanges={unsavedChanges}
                    dialogOpen={dialogOpen}
                    handleDialogClose={handleDialogClose}
                />
            }
        </>
    )
}
export default UnscheduledVisitModal;
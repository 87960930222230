import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../modules/rootReducer';
import { CollapsiblePanelState, toggleCollapsiblePanel } from '../../modules/collapsiblePanelReducer';

import CollapsibleFilterPanel from '../../common/CollapsibleFilterPanel/CollapsibleFilterPanel';
import usePermissions from '../../common/hooks/usePermissions';
import FilterByDivision from '../Invoicing/Invoice/FilterByDivision';


interface Props {
    handleChangeSelectedDivision: any;
}

const DivisionListBrowsingPanel: React.FC<Props> = props => {
    const {
        handleChangeSelectedDivision
    } = props;

    const dispatch = useDispatch();

    const { expanded } = useSelector<Store, CollapsiblePanelState>( store => store.collapsiblePanelReducer );
    React.useEffect(() => {
        if (!expanded) dispatch(toggleCollapsiblePanel());
    }, []);
    const canSelectAllDivisions = usePermissions('API_INVOICE_GET_AMOUNT_NOT_INVOICED_ON_STUDIES');

    const browseOptions = (
        <div className="browsing-options scrollable-sidebar">
            <FilterByDivision
                setChosenDivision={handleChangeSelectedDivision}
                withinCollapsiblePanel
                restricted={!canSelectAllDivisions}
                divisionStatisticsPage
                headerText="Filter By Division"
                tooltipText="Study and User Statistics will be displayed for the selected Division and its sub-Divisions"
            />
        </div>
    );

    return (
        <CollapsibleFilterPanel contents={browseOptions} />
    );
}

export default DivisionListBrowsingPanel;
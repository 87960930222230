import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, Button, Paper, Table, TableBody, TableContainer, Typography } from '@material-ui/core';

import { Patient, PatientVisit, ScheduledPatientVisitsTab, Study } from '../../../../../@types';
import { getScheduledPatientVisitsTab } from '../../../../../service/Study/study';
import { Store } from '../../../../../modules/rootReducer';
import { showErrorSnackbar } from '../../../../../modules/messageSnackbarReducer';
import { CollapsiblePanelState } from '../../../../../modules/collapsiblePanelReducer';
import useLoggedInUserPermissions from '../../../../../common/hooks/useLoggedInUserPermissions';

import PatientModal from '../Modals/PatientModal';
import ScheduledVisitsModal from '../Modals/ScheduledVisitModal';
import ScheduledVisitCostSection from './ScheduledVisitsTableCost';
import ScheduledVisitsTableHeader from './ScheduledVisitsTableHeader';
import ScheduledVisitTotalSection from './ScheduledVisitsTableTotal';
import ScheduledVisitPatientSection from './ScheduledVisitsTablePatients';
import Loading from '../../../../../common/Routes/Loading';

interface VisitsTableProps {
    study: Study;
}

const ScheduledVisitsTable: React.FC<VisitsTableProps> = props => {
    const { study } = props;

    const history = useHistory();
    const dispatch = useDispatch();

    const canViewScheduledPatientVisitsPage = useLoggedInUserPermissions('API_STUDY_GET_SCHEDULED_PATIENT_VISITS_PAGE', study.divisionId, study.id!);
    const canCreateAddendum = useLoggedInUserPermissions('API_CONTRACT_CREATE_ADDENDUM', study.divisionId, study.id!);

    const { expanded } = useSelector<Store, CollapsiblePanelState>(
        store => store.collapsiblePanelReducer
    );

    const [patientModalOpen, setPatientModalOpen] = React.useState(false);
    const [chosenPatient, setChosenPatient] = React.useState<Patient>({
        id: null,
        studyId: Number(study.id),
        status: 'ACTIVE',
        identifier: '',
    });

    const [scheduledPatientVisitsData, setScheduledPatientVisitsData] = React.useState<ScheduledPatientVisitsTab | null>({} as ScheduledPatientVisitsTab);
    const [patientVisitOpen, setPatientVisitOpen] = React.useState(false);
    const [newPatientVisit, setNewPatientVisit] = React.useState<PatientVisit>({
        id: null,
        patientId: 0,
    });
    const [readonlyPatientVist, setReadonlyPatientVisit] = React.useState(false);

    React.useEffect(() => {
        getScheduledPatientVisitsTab(Number(study.id)).then(res => {
            setScheduledPatientVisitsData(res.data);
        }).catch(err => {
            if (err.response?.status === 404) {
                setScheduledPatientVisitsData(null);
            } else if (err.response?.status !== 403) {
                dispatch(showErrorSnackbar(err.response?.data.message));
            }
        });
    }, [study]);

    const handlePatientVisit = (patId: number, svId: number, completelyNew: boolean, readonly: boolean) => () => {
        if (!completelyNew && scheduledPatientVisitsData) {
            const newPV: PatientVisit = scheduledPatientVisitsData.scheduledPatientVisits[patId][svId];
            setNewPatientVisit(newPV);
            setReadonlyPatientVisit(readonly);
        } else {
            setNewPatientVisit({ id: null, patientId: patId, scheduledVisitId: svId });
            setReadonlyPatientVisit(false);
        }
        setPatientVisitOpen(true);
    }

    const handleEditPatient = (patient: Patient) => {
        setChosenPatient(patient);
        setPatientModalOpen(true);
    }

    const errorPageContent = (message: string, path: string, buttonMessage: string) => {
        return (
            <div className="page">
                <div className="page-content">
                    <div className="inner">
                        <div className="page-heading" />
                        <div className="full-screen centered">
                            <Box textAlign="center" className="page-body">
                                <Typography gutterBottom variant="h1">
                                    Scheduled Visits Table Unavailable
                                </Typography>
                                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                                    {message}
                                </Typography>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => history.push(path)}
                                    style={{ margin: 40 }}
                                >
                                    {buttonMessage}
                                </Button>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (!canViewScheduledPatientVisitsPage) {
        return errorPageContent(
            'You do not have the proper permissions to access the scheduled visits table. Please contact your division or institution administrator for more information.',
            '/study',
            'Go Back to Your Studies'
        );
    } else if (scheduledPatientVisitsData === null) {
        return errorPageContent(
            'There are no scheduled visits associated with the contract for this study.',
            canCreateAddendum ? `/study/${study.id!}/addendums` : '/study',
            canCreateAddendum ? 'Create an Addendum' : 'Go Back to Your Studies'
        );
    } else if (!scheduledPatientVisitsData.scheduledVisits) {
        return (
            <Loading />
        );
    }

    return (
        <>
            <Paper className={expanded ? 'constrain-expanded' : ''} style={{ padding: 10, marginTop: 10 }}>
                <TableContainer className='constrain-table' id='sv-matrix'>
                    <Table className='border-separate'>
                        <ScheduledVisitsTableHeader
                            scheduledVisits={scheduledPatientVisitsData.scheduledVisits}
                            procedures={scheduledPatientVisitsData.proceduresAssociatedWithScheduledVisits}
                        />
                        <TableBody>
                            <ScheduledVisitPatientSection
                                scheduledVisits={scheduledPatientVisitsData.scheduledVisits}
                                patients={scheduledPatientVisitsData.patients}
                                patientNotes={scheduledPatientVisitsData.patientNotes}
                                setAddPatientOpen={() => setPatientModalOpen(true)}
                                patientVisits={scheduledPatientVisitsData.scheduledPatientVisits}
                                handleEditPatient={handleEditPatient}
                                handlePatientVisit={handlePatientVisit}
                                study={study}
                            />
                            <ScheduledVisitCostSection
                                scheduledVisits={scheduledPatientVisitsData.scheduledVisits}
                                payments={scheduledPatientVisitsData.payments}
                                holdbacks={scheduledPatientVisitsData.holdbacks}
                                totalPerVisits={scheduledPatientVisitsData.totalPerVisits}
                            />
                            <ScheduledVisitTotalSection
                                totalVisits={scheduledPatientVisitsData.totalVisits}
                                expectedPaid={scheduledPatientVisitsData.expectedPaid}
                                expectedHold={scheduledPatientVisitsData.expectedHold}
                                totalPaid={scheduledPatientVisitsData.totalPaid}
                                count={scheduledPatientVisitsData.scheduledVisits.length}
                            />
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            {patientModalOpen &&
                <PatientModal
                    open={patientModalOpen}
                    setOpen={setPatientModalOpen}
                    patient={chosenPatient}
                    setPatient={setChosenPatient}
                />
            }
            {patientVisitOpen &&
                <ScheduledVisitsModal
                    open={patientVisitOpen}
                    setOpen={setPatientVisitOpen}
                    patientVisit={newPatientVisit}
                    study={study}
                    scheduledPatientVisitsData={scheduledPatientVisitsData}
                    readOnly={readonlyPatientVist}
                />
            }
        </>
    );
}
export default ScheduledVisitsTable;

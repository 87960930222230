import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { Bar, BarChart, Cell, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { amountFormatter, halfStudyNameFormatter, handleStudyNameTooltip } from '../Utils';

interface Props {
    graphData: any[];
    barColor?: string;
    displayColumn: 'study' | 'date' | 'amount';
    notInvoiced: boolean;
}

const InvoiceChart: React.FC<Props> = (props) => {
    const { graphData, barColor, displayColumn, notInvoiced } = props;

    const history = useHistory();

    const [graphHeight, setGraphHeight] = React.useState<number>(0);

    React.useEffect(() => {
        setGraphHeight(graphData.length * 40);
    }, [graphData]);

    const chartMargin = () => {
        if (displayColumn == 'study') {
            return { top: -20, right: 0, bottom: -10, left: -5 };
        }
        if (displayColumn == 'date') {
            return { top: -20, right: 0, bottom: -10, left: 0 };
        }
        if (displayColumn == 'amount') {
            return { top: -30, right: 0, bottom: 0, left: 100 };
        }
    };

    return (
        <ResponsiveContainer width='99%' height={graphHeight}>
            <BarChart
                layout='vertical'
                data={graphData}
                barSize={20}
                margin={chartMargin()}
            >
                <XAxis
                    dataKey={notInvoiced ? 'amountNotInvoiced' : 'amountNotPaid'}
                    type="number"
                    orientation="top"
                    axisLine={false}
                    tick={false}
                    padding={displayColumn == 'amount' ? { right: 10 } : { right: 0 }}
                />
                <YAxis
                    dataKey="studyName"
                    type="category"
                    axisLine={false}
                    interval={0}
                    hide={true}
                />
                {displayColumn == 'study' && <>
                    <Tooltip
                        isAnimationActive={false}
                        content={(studyNameTooltipProp) => (
                            handleStudyNameTooltip(studyNameTooltipProp)
                        )}
                    />
                    <Bar
                        dataKey="studyName"
                        isAnimationActive={false}
                        label={{ position: 'right', formatter: halfStudyNameFormatter, fill: '#383838' }}
                    >
                        {graphData.map((entry, index) => (
                            <Cell cursor="pointer" key={`cell-${index}`}
                                onClick={() => {
                                    notInvoiced ?
                                        history.push(`/study/${entry.studyId}/invoicing/receivable/line-items`) :
                                        history.push(`/study/${entry.studyId}/invoicing/receivable/invoices`)
                                }
                                }
                            />
                        ))}
                    </Bar>
                </>}
                {displayColumn == 'date' &&
                    <Bar
                        dataKey={notInvoiced ? 'lastInvoiceDate' : 'lastPaymentDate'}
                        isAnimationActive={false}
                        label={{ position: 'right', fill: '#383838' }}
                    >
                        {graphData.map((entry, index) => (
                            <Cell cursor="pointer" key={`cell-${index}`}
                                onClick={() => {
                                    notInvoiced ?
                                        entry.invoiceId && history.push(`/invoicing/${entry.invoiceId}`) :
                                        entry.paymentId && history.push(`/manage/account/${entry.accountId}/payments/${entry.paymentId}`)
                                }
                                }
                            />
                        ))}
                    </Bar>
                }
                {displayColumn == 'amount' && <>
                    <ReferenceLine x={0} stroke="#DCDCDC" />
                    <Bar
                        dataKey={notInvoiced ? 'amountNotInvoiced' : 'amountNotPaid'}
                        isAnimationActive={false}
                        label={{ position: 'left', formatter: amountFormatter, fill: '#383838' }}
                        fill={barColor ? barColor : '#000'}
                    >
                        {graphData.map((entry, index) => (
                            <Cell cursor="pointer" key={`cell-${index}`}
                                onClick={() => {
                                    notInvoiced ?
                                        history.push(`/study/${entry.studyId}/invoicing/receivable/line-items`) :
                                        history.push(`/study/${entry.studyId}/invoicing/receivable/invoices`)
                                }
                                }
                            />
                        ))}
                    </Bar>
                </>}
            </BarChart>
        </ResponsiveContainer>
    )
}
export default InvoiceChart;
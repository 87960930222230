/*** CONSTANTS ***/
export const FORCE_RESET = 'authenticationReducer/FORCE_RESET';
export type FORCE_RESET = typeof FORCE_RESET;

export const CLEAR_FORCE_RESET = 'authenticationReducer/CLEAR_FORCE_RESET';
export type CLEAR_FORCE_RESET = typeof CLEAR_FORCE_RESET;

export const SETUP_MFA = 'authenticationReducer/SETUP_MFA';
export type SETUP_MFA = typeof SETUP_MFA;

/*** ACTIONS ***/

export interface ForceReset {
    type: FORCE_RESET;
    payload: ForceResetPasswordState;
}

export interface ClearForceReset {
    type: CLEAR_FORCE_RESET;
}

export interface SetupMfa {
    type: SETUP_MFA;
    payload: ForceResetPasswordState;
}

export type AuthenticationAction = ForceReset | ClearForceReset | SetupMfa;

/*** ACTION CREATORS ***/
export const forcePasswordReset = (resetState: ForceResetPasswordState): ForceReset => {
    return {
        type: FORCE_RESET,
        payload: resetState

    };
}

export const clearStoreForceReset = (): ClearForceReset => {
    return {
        type: CLEAR_FORCE_RESET
    }
}

export const setupMfa = (mfaState: ForceResetPasswordState): SetupMfa => {
    return {
        type: SETUP_MFA,
        payload: mfaState
    }
}

/*** REDUCER ***/

export interface ForceResetPasswordState {
    userEmail: string;
    message: string;
    session: string;
    secretCode?: string;
    accessToken?: string;
}

export const initialState: ForceResetPasswordState = {
    userEmail: '',
    message: '',
    session: '',
    secretCode: '',
};

export const reducer = (
    state: ForceResetPasswordState = initialState,
    action: AuthenticationAction
): ForceResetPasswordState => {
    if (action.type === FORCE_RESET) {
        return {
            ...state,
            userEmail: action.payload.userEmail,
            message: action.payload.message,
            session: action.payload.session,
        };
    } else if (action.type === CLEAR_FORCE_RESET) {
        return initialState;
    } else if (action.type === SETUP_MFA) {
        return {
            ...state,
            userEmail: action.payload.userEmail,
            message: action.payload.message,
            session: action.payload.session,
            secretCode: action.payload.secretCode,
            accessToken: action.payload.accessToken,
        }
    }
    /* istanbul ignore next */
    else {
        return state;
    }
};

import axiosInstance from '../Access/axiosInstance';
import { Contract, ContractListDetail, Study, ContractPage, ContractPageRequest, Procedure, ContractEstimatedCosts, ContractScheduleOfEvents, SignContractWarning } from '../../@types';
import { handleError } from '../utils';

/**
 * Gets a page of contracts
 */
export const getContractsPage = (pageReq: ContractPageRequest) =>
    axiosInstance.get<ContractPage>(
        '/api/contracts', { params: pageReq }
    ).catch(handleError);

/**
 * 
 * @returns 
 */
export const getRestrictedContracts = () =>
    axiosInstance.get<ContractListDetail[]>(
        '/api/contracts/restricted/'
    ).catch(handleError);

/**
 * Gets a contract by id
 */
export const getContract = (id: number) =>
    axiosInstance.get<Contract>(
        `/api/contracts/${id}`
    ).catch(handleError);

/**
* Gets a contract's estimated costs per patient by id
*/
export const getContractEstimatedCosts = (id: number) =>
    axiosInstance.get<ContractEstimatedCosts>(
        `/api/contracts/estimatedCosts/${id}`
    ).catch(handleError);

/**
 * Updates a contract
 */
export const updateContract = (contract: Contract) =>
    axiosInstance.put<Contract>(
        '/api/contracts', contract
    ).catch(handleError);

/**
 * Creates a contract
 */
export const createContract = (contract: Study) =>
    axiosInstance.post<Contract>(
        '/api/contracts', contract
    ).catch(handleError);

/**
 * Signs a contract
 */
export const signContract = (contractId: number, date: string) =>
    axiosInstance.put(
        `/api/contracts/sign/${contractId}?date=${date}`
    ).catch(handleError);

/**
 * Creates an addendum for the study
 */
export const createAddendum = (studyId: number) =>
    axiosInstance.post<Contract>(
        `/api/contracts/addendum/${studyId}`
    ).catch(handleError);

/**
 * Deletes a contract
 */
export const deleteContract = (id: number) =>
    axiosInstance.delete(
        `/api/contracts/${id}`
    ).catch(handleError);

/**
 * Gets associated procedures from contract
 */
export const getProceduresFromContract = (id: number) =>
    axiosInstance.get<Procedure[]>(
        `/api/contracts/procedures/${id}`
    ).catch(handleError);


export const getProceduresFromContractWithTotalCost = (id: number) =>
    axiosInstance.get<Procedure[]>(
        `/api/contracts/procedures/${id}?includeTotalCost=true`
    ).catch(handleError);

/**
 * Gets contract schedule of events
 */
export const getScheduleOfEvents = (id: number) =>
    axiosInstance.get<ContractScheduleOfEvents>(
        `api/contracts/scheduleOfEvents/${id}`
    ).catch(handleError);

/**
 * Gets sign contract warnings
 */
export const getSignContractWarnings = (id: number) =>
    axiosInstance.get<SignContractWarning>(
        `/api/contracts/warnings/sign/${id}`
    ).catch(handleError);
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Card, Grid, Table, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import { Contract, ContractEstimatedCosts, Study } from '../../../../@types';
import { DisplayTextFormat } from '../../../../common/Utils/DisplayTextFormat';
import { NumberFormatter } from '../../../../common/Utils/NumberFormatter';
import { displayDate } from '../../../../common/Utils/utils';
import { showSuccessSnackbar } from '../../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../../service/utils';
import { getStudy } from '../../../../service/Study/study';
import { getDivision } from '../../../../service/Management/divisions';
import { deleteContract, getContract, getContractEstimatedCosts } from '../../../../service/Contract/contracts';

import TypeConfirmDelete from '../../../../common/ConfirmDelete/TypeConfirmDelete';
import useLoggedInUserPermissions from '../../../../common/hooks/useLoggedInUserPermissions';
import ContractEditInfo from './ContractEditInfo';
import ContractEditDetails from './ContractEditDetails';
import ContractSignModal from './ContractSignModal';

interface Props {
    contractId: number;
    studyId: number;
    divisionId: number;
    setUpdatedSigned: any;
    setUpdatedInfo: any;
}

const ContractOverview: React.FC<Props> = props => {
    const { contractId, studyId, divisionId, setUpdatedSigned, setUpdatedInfo } = props;
    const history = useHistory();
    const dispatch = useDispatch();

    // Checks for the user/role/permissions
    const permissionStudyUpdate: boolean = useLoggedInUserPermissions('API_STUDY_UPDATE', divisionId, studyId);
    const permissionContractUpdate: boolean = useLoggedInUserPermissions('API_CONTRACT_UPDATE', divisionId, studyId);
    const permissionContractSign: boolean = useLoggedInUserPermissions('API_CONTRACT_SIGN', divisionId, studyId);
    const permissionDelete: boolean = useLoggedInUserPermissions('API_CONTRACT_DELETE_BY_ID', divisionId, studyId);
    
    const [contract, setContract] = React.useState<Contract>({
        id: contractId,
        studyId: 0,
        sponsorProposal: 0,
        negotiatingCost: 0,
        actualCost: 0,
        finalCost: 0,
        overhead: 0,
        holdback: 0,
        coordinatorCost: 0,
        providerCost: 0,
        projectedSubjects: 0,
        perSubjectDuration: 0,
        coordinatorExtraTime: 0,
        providerExtraTime: 0,
        coordinatorAdminTime: 0,
        providerAdminTime: 0,
        signed: null
    });

    const [contractEstimatedCosts, setContractEstimatedCosts] = React.useState<ContractEstimatedCosts>({
        providerActualCostPerPatient: 0,
        providerProposedCostPerPatient: 0,
        providerPercentEffortPerPatient: 0,
        coordinatorActualCostPerPatient: 0,
        coordinatorProposedCostPerPatient: 0,
        coordinatorPercentEffortPerPatient: 0,
        otherActualCostPerPatient: 0,
        otherProposedCostPerPatient: 0,
    });

    const [study, setStudy] = React.useState<Study>({
        id: null,
        name: '',
        identifier: '',
        irbNo: '',
        studyId: '',
        divisionId: 0,
        started: new Date(),
        completed: new Date(),
        deleted: false,
        users: [],
        accountId: 0,
        sponsorId: 0,
        sponsorIdentifier: '',
    });

    const [division, setDivision] = React.useState<string>('');

    // Manage the simple React state for opening/closing the edit modals (information and details)
    const [editInfoOpen, setEditInfoOpen] = React.useState(false);
    const [editDetailsOpen, setEditDetailsOpen] = React.useState(false);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [signContractOpen, setSignContractOpen] = React.useState(false);
    
    React.useEffect(() => {
        getContract(Number(contractId))
            .then(contractRes => {
                setContract(contractRes.data);
                getContractEstimatedCosts(Number(contractId))
                    .then(costsRes => {
                        setContractEstimatedCosts(costsRes.data);
                    }).catch(err => {
                        handleErrorResponse(err, dispatch, {
                            prefix: 'Could not retrieve estimated costs per Patient: '
                        });
                    });
                getStudy(contractRes.data.studyId)
                    .then(studyRes => {
                        setStudy(studyRes.data);
                        getDivision(studyRes.data.divisionId)
                            .then(divisionRes => {
                                setDivision(divisionRes.data.name);
                            }).catch(err => {
                                handleErrorResponse(err, dispatch, {
                                    prefix: 'Could not retrieve Division: '
                                });
                            });
                    }).catch(err => {
                        handleErrorResponse(err, dispatch, {
                            prefix: 'Could not retrieve Study: '
                        });
                    });
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve Contract: '
                })
                history.push('/contract');
            });
    }, [contractId, editInfoOpen, editDetailsOpen]);

    // Delete handlers
    const handleDelete = () => {
        deleteContract(Number(contract.id))
            .then(() => {
                setDeleteOpen(false);
                dispatch(showSuccessSnackbar(`Deleted contract "${study.name}"`));
                history.push('/contract');
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not delete Contract: '
                });
            });
    }

    const estimatedActualTotalPerPatient =
        (contractEstimatedCosts.coordinatorActualCostPerPatient +
            contractEstimatedCosts.providerActualCostPerPatient +
            contractEstimatedCosts.otherActualCostPerPatient)
    const estimatedProposedTotalPerPatient =
        (contractEstimatedCosts.coordinatorProposedCostPerPatient +
            contractEstimatedCosts.providerProposedCostPerPatient +
            contractEstimatedCosts.otherProposedCostPerPatient)


    const signedField = (signed: any) => {
        if (signed) { 
            return displayDate(signed) 
        } else if (!permissionContractSign) {
            return 'Not Signed'
        } else {
            return (
                <Button
                    id="edit-info"
                    onClick={() => setSignContractOpen(true)}
                    variant="contained"
                    color="primary"
                    style={{ width: 200 }}
                >
                    Sign Contract
                </Button>
            )
        }
    }

    const informationTip = 'The following lists general information about the contract including all identification related attributes, attributes related to its status, and important costs to review.';
    const detailsTip = 'The following are values critical to the existence of a contract. They are used in calculations to help estimate various costs associated with the potential study.';
    const estimatedCostsTip = 'The following are the total estimated costs for each patient. Since each resource requires different costs, the estimations vary across the resource types.';

    return (
        <>
            <div className="page-heading">
                <Typography className="title">{DisplayTextFormat(study.name, 64)}</Typography>
            </div>
            <div className="page-body max-xl contract-overview">
                <Grid container spacing={3} alignItems="flex-start">
                    <Grid container item xs={12} md={6} lg={4}>
                        <div className="section-heading">
                            <div className='text'>Information</div>
                            <Tooltip arrow title={informationTip}><InfoIcon className='info' /></Tooltip>
                            {permissionStudyUpdate &&
                                <Button id="edit-info" onClick={() => setEditInfoOpen(true)}>
                                    Edit
                                </Button>
                            }
                        </div>
                        <Card className='info'>
                            <Typography>{DisplayTextFormat(study.name)}</Typography>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Identifier:</TableCell>
                                            <TableCell>{DisplayTextFormat(study.identifier)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>IRB #:</TableCell>
                                            <TableCell>{study.irbNo}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Study #:</TableCell>
                                            <TableCell>{study.studyId}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Sponsor&apos;s Study ID:</TableCell>
                                            <TableCell>{study.sponsorIdentifier}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Division:</TableCell>
                                            <TableCell>{division}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Created:</TableCell>
                                            <TableCell>{contract.created ? displayDate(contract.created) : ''}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Last Update:</TableCell>
                                            <TableCell>{contract.updated ? displayDate(contract.updated) : ''}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Signed:</TableCell>
                                            <TableCell>{signedField(contract.signed)}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                            <TableContainer className='costs-table'>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align='right'>Actual Cost</TableCell>
                                            <TableCell align='right'><NumberFormatter currency value={contract.actualCost} /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='right'>Proposed Cost</TableCell>
                                            <TableCell align='right'><NumberFormatter currency value={contract.sponsorProposal} /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='right'>Negotiating Cost</TableCell>
                                            <TableCell align='right'><NumberFormatter currency value={contract.negotiatingCost} /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='right'>Final Cost</TableCell>
                                            <TableCell align='right'><NumberFormatter currency value={contract.finalCost} /></TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </Card>
                    </Grid>
                    <Grid container item xs={12} md={6} lg={4}>
                        <div className="section-heading">
                            <div className='text'>Details</div>
                            <Tooltip arrow title={detailsTip}><InfoIcon className='info' /></Tooltip>
                            {(permissionContractUpdate && !contract.signed) &&
                                <Button id="edit-details" onClick={() => setEditDetailsOpen(true)}>
                                    Edit
                                </Button>
                            }
                        </div>
                        <Card className='details'>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align='right'>Overhead</TableCell>
                                            <TableCell align='right'>{contract.overhead}</TableCell>
                                            <TableCell>%</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='right'>Holdback</TableCell>
                                            <TableCell align='right'>{contract.holdback}</TableCell>
                                            <TableCell>%</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='right'>Coordinator Cost</TableCell>
                                            <TableCell align='right'>{contract.coordinatorCost}</TableCell>
                                            <TableCell>per hour</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='right'>Provider Cost</TableCell>
                                            <TableCell align='right'>{contract.providerCost}</TableCell>
                                            <TableCell>per hour</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='right'>Projected Subjects</TableCell>
                                            <TableCell align='right'>{contract.projectedSubjects}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='right'>Per Subject Duration</TableCell>
                                            <TableCell align='right'>{contract.perSubjectDuration}</TableCell>
                                            <TableCell>months</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='right'>Coordinator Extra Time</TableCell>
                                            <TableCell align='right'>{contract.coordinatorExtraTime}</TableCell>
                                            <TableCell>mins/assessment</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='right'>Provider Extra Time</TableCell>
                                            <TableCell align='right'>{contract.providerExtraTime}</TableCell>
                                            <TableCell>mins/assessment</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='right'>Coordinator Admin Time</TableCell>
                                            <TableCell align='right'>{contract.coordinatorAdminTime}</TableCell>
                                            <TableCell>hrs/patient</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='right'>Provider Admin Time</TableCell>
                                            <TableCell align='right'>{contract.providerAdminTime}</TableCell>
                                            <TableCell>hrs/patient</TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </Card>
                    </Grid>
                    <Grid container item xs={12} md={6} lg={4}>
                        <div className="section-heading">
                            <div className='text'>Estimated Costs Per Patient</div>
                            <Tooltip arrow title={estimatedCostsTip}><InfoIcon className='info' /></Tooltip>
                        </div>
                        <Card className='patient'>
                            <TableContainer className='costs-table'>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className='heading' colSpan={2}>Provider</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='right'>Actual Cost</TableCell>
                                            <TableCell align='right'><NumberFormatter currency value={contractEstimatedCosts.providerActualCostPerPatient} /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='right'>Proposed Cost</TableCell>
                                            <TableCell align='right'><NumberFormatter currency value={contractEstimatedCosts.providerProposedCostPerPatient} /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='right'>Percent Effort</TableCell>
                                            <TableCell align='right'><NumberFormatter percent value={contractEstimatedCosts.providerPercentEffortPerPatient} /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className='heading' colSpan={2}>Coordinator</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='right'>Actual Cost</TableCell>
                                            <TableCell align='right'><NumberFormatter currency value={contractEstimatedCosts.coordinatorActualCostPerPatient} /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='right'>Proposed Cost</TableCell>
                                            <TableCell align='right'><NumberFormatter currency value={contractEstimatedCosts.coordinatorProposedCostPerPatient} /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='right'>Percent Effort</TableCell>
                                            <TableCell align='right'><NumberFormatter percent value={contractEstimatedCosts.coordinatorPercentEffortPerPatient} /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className='heading' colSpan={2}>Other</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='right'>Actual Cost</TableCell>
                                            <TableCell align='right'><NumberFormatter currency value={contractEstimatedCosts.otherActualCostPerPatient} /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='right'>Proposed Cost</TableCell>
                                            <TableCell align='right'><NumberFormatter currency value={contractEstimatedCosts.otherProposedCostPerPatient} /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className='heading' colSpan={2}>Total</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='right'>Actual Cost</TableCell>
                                            <TableCell align='right'><NumberFormatter currency value={estimatedActualTotalPerPatient} /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='right'>Proposed Cost</TableCell>
                                            <TableCell align='right'><NumberFormatter currency value={estimatedProposedTotalPerPatient} /></TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </Card>
                    </Grid>
                </Grid>
                <ContractEditInfo open={editInfoOpen} setOpen={setEditInfoOpen} study={study} setStudy={setStudy} setDelete={setDeleteOpen} permissionDelete={permissionDelete} signed={!!contract.signed}
                    setUpdatedInfo={setUpdatedInfo} />
                <ContractEditDetails open={editDetailsOpen} setOpen={setEditDetailsOpen} contract={contract} setContract={setContract} />
                <ContractSignModal open={signContractOpen} setOpen={setSignContractOpen} contract={{ ...contract, signed: new Date() }} setContract={setContract} setUpdatedSigned={setUpdatedSigned} />
                <TypeConfirmDelete open={deleteOpen} setOpen={setDeleteOpen} type='Contract' objectName={study.name} handleDelete={handleDelete} />
            </div>
        </>
    )
}

export default ContractOverview;
import * as React from 'react';

import { TableCell, TableHead, TableRow, Typography } from '@material-ui/core';

import { ShortScheduledVisit } from '../../../../@types';
import { DisplayTextFormat } from '../../../../common/Utils/DisplayTextFormat';


interface SOEHeaderProps {
    scheduledVisits: ShortScheduledVisit[];
}

const ScheduleOfEventsHeader: React.FC<SOEHeaderProps> = props => {
    const { scheduledVisits } = props;
    return (
        <TableHead className="soe-table-header">
            <TableRow>
                <TableCell
                    key={'header-procedure'}
                    className={'soe-header-procedure'}
                    colSpan={2}
                >
                    <Typography variant="body2">Procedure</Typography>
                </TableCell>
                <TableCell
                    key={'header-resource'}
                    className={'soe-header-resource'}
                >
                    <Typography variant="body2">Resource</Typography>
                </TableCell>
                <TableCell
                    key={'header-time-required'}
                    className={'soe-header-time-required'}
                    align="right"
                >
                    <Typography variant="body2">Time (mins)</Typography>
                </TableCell>
                <TableCell
                    key={'header-actual-cost'}
                    className={'soe-header-actual-cost'}
                    align="right"
                >
                    <Typography variant="body2">Actual Cost</Typography>
                </TableCell>
                {scheduledVisits.map((sv, idx) => {
                    return (
                        <TableCell
                            key={'header-' + idx + '-sv-' + sv.id}
                            className={'soe-primary-cell'}
                        >
                            <div className={'soe-header-cell-label center'}>
                                <Typography variant="body2">{DisplayTextFormat(sv.visitName)}</Typography>
                            </div>
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}
export default ScheduleOfEventsHeader;
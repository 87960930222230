import * as React from 'react';
import { FormikProps } from 'formik';
import { TableCell, TableRow, Tooltip } from '@material-ui/core';

import { NumberFormatter } from '../../../../common/Utils/NumberFormatter';

import { SOEForm } from './ScheduleOfEvents';
import { ArrowDownward } from '@material-ui/icons';


interface TotalsTableProps {
    formikProps: FormikProps<SOEForm>;
    proposedTotalCosts: number[];
    overrideCosts: number[];
}

const TotalsTable: React.FC<TotalsTableProps> = props => {
    const { formikProps, proposedTotalCosts, overrideCosts } = props;

    const totalActual = formikProps.values.actualCosts.length > 0 ? formikProps.values.actualCosts.reduce((accumulator, currentValue) => accumulator + currentValue) : 0;
    const totalProposed = formikProps.values.proposedCosts.length > 0 ? formikProps.values.proposedCosts.reduce((accumulator, currentValue) => accumulator + currentValue) : 0;
    const totalFinal = formikProps.values.finalCosts.length > 0 ? formikProps.values.finalCosts.reduce((accumulator, currentValue, index) => accumulator + currentValue) : 0;

    const costStyle = (idx: number, currType: string) => {
        const actual = formikProps.values.actualCosts[idx];
        const proposed = formikProps.values.proposedCosts[idx];
        const override = overrideCosts[idx];

        const overrideCostExists = currType == 'final' && override;
        const actualCostIsFinal = currType == 'actual' && actual > proposed && !override;
        const proposedCostIsFinal = currType == 'proposed' && actual <= proposed && !override;
        if (overrideCostExists || actualCostIsFinal || proposedCostIsFinal) {
            return 'current-final-cost';
        }
        return '';
    }

    return (
        <>
            <TableRow key='totals-grid-actual-total-cost'>
                <TableCell key='totals-grid-actual-total-cost-cell' className='totals-grid-section-starter left' colSpan={3}>
                    Total Actual Cost
                </TableCell>
                <TableCell key='totals-grid-actual-total' className='soe-sum-totals right' colSpan={2}>
                    <NumberFormatter currency value={totalActual} />
                </TableCell>
                {formikProps.values.actualCosts.map((cost: number, idx: number) => {
                    return (
                        <TableCell key={'totals-grid-visit-actual-total' + idx} className={'soe-grid-readonly-cell right ' + costStyle(idx, 'actual')}>
                            <NumberFormatter currency value={cost} />
                        </TableCell>
                    );
                })}
            </TableRow>
            <TableRow key='totals-grid-proposed-total-cost'>
                <TableCell key='totals-grid-proposed-total-cost-cell' className={'totals-grid-section-starter left'} colSpan={3}>
                    Total Proposed Cost
                </TableCell>
                <TableCell key={'totals-grid-proposed-total'} className={'soe-sum-totals right'} colSpan={2}>
                    <NumberFormatter currency value={totalProposed} />
                </TableCell>
                {formikProps.values.proposedCosts.map((cost: number, idx: number) => {
                    return (
                        <TableCell key={'totals-grid-visit-proposed-total' + idx} className={'soe-grid-readonly-cell right ' + costStyle(idx, 'proposed')}>
                            <NumberFormatter currency value={cost} />
                        </TableCell>
                    );
                })}
            </TableRow>
            <TableRow key='totals-grid-final-total-cost'>
                <TableCell key='totals-grid-final-total-cost-cell' className='totals-grid-section-starter soe-ptc-border left' colSpan={3}>
                    Total Final Cost
                </TableCell>
                <TableCell key='totals-grid-final-total' className='soe-sum-totals right' colSpan={2}>
                    <NumberFormatter currency value={totalFinal} />
                </TableCell>
                {formikProps.values.proposedCosts.map((ftc: number, idx: number) => {
                    if (overrideCosts[idx] && overrideCosts[idx] < formikProps.values.actualCosts[idx] && overrideCosts[idx] < proposedTotalCosts[idx]) {
                        return (
                            <TableCell key={'totals-grid-visit-final-total' + idx} className={'soe-grid-readonly-cell right ' + costStyle(idx, 'final')}>
                                <div className='row right'>
                                    <Tooltip arrow title="The manually entered final cost value is lower than the visit's actual and proposed cost. 
                                        If this was a mistake, the overrided cost can be re-entered or removed by editing a visit in the Visits tab."
                                    >
                                        <ArrowDownward className='low-value-alert' />
                                    </Tooltip>
                                    <NumberFormatter currency value={overrideCosts[idx]} />
                                </div>
                            </TableCell>
                        );
                    } else {
                        return (
                            <TableCell key={'totals-grid-visit-final-total' + idx} className={'soe-grid-readonly-cell right ' + costStyle(idx, 'final')}>
                                <NumberFormatter currency value={overrideCosts[idx] ?? ftc} />
                            </TableCell>
                        );
                    }
                })}
            </TableRow>
        </>
    )
}
export default TotalsTable;
import * as React from 'react';
import { Card, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from '@material-ui/core';
import { Info, UnfoldLess, UnfoldMore } from '@material-ui/icons';

import { Study, UserSummary } from '../../../../@types';
import { DisplayTextFormat } from '../../../../common/Utils/DisplayTextFormat';
import { getStudyStatisticsUsers } from '../../../../service/Study/calculations';
import useLoggedInUserPermissions from '../../../../common/hooks/useLoggedInUserPermissions';

import MonthlyTableHeader from './MonthlyTableHeader';
import StudyStatisticsTableRow from './StudyStatisticsTableRow';
import UserStatisticsTableRow from './UserStatisticsTableRow';
import YearSelector from '../../../../common/DataTable/YearSelector';


const currentYear: number = new Date().getFullYear();
const currentMonth: number = new Date().getMonth();

const infoToolip: string = 'If any effort values are inaccurate, please contact the organization’s administrator or division manager to correct them.';

interface UserStatisticsDisplay {
    data: UserSummary[];
    label: string;
    display: boolean;
}

interface Props {
    study: Study;
    year?: number;
}

const StudyStatistics: React.FC<Props> = props => {
    const { study, year } = props;

    const permissionGetStudyStatisticsUsers: boolean =
        useLoggedInUserPermissions('API_CALCULATION_GET_STUDY_STATISTICS_USERS', study.divisionId, study.id!);
    const permissionDisplayCalculationTotalsByStudyId: boolean =
        useLoggedInUserPermissions('API_CALCULATION_GET_CALCULATION_TOTALS_BY_STUDY_ID', study.divisionId, -1);
    const permissionDisplayMonthlyCalculationTotalsByStudyIdAndYear: boolean =
        useLoggedInUserPermissions('API_CALCULATION_GET_MONTHLY_CALCULATION_TOTALS_BY_STUDY_ID_AND_YEAR', study.divisionId, -1);

    const [editYear, setEditYear] = React.useState<number>(year ?? currentYear);
    const [displayUsers, setDisplayUsers] = React.useState<UserStatisticsDisplay[]>([]);
    const [expandAllCoordinators, setExpandAllCoordinators] = React.useState<boolean>(false);
    const [expandAllProviders, setExpandAllProviders] = React.useState<boolean>(false);
    const [expandAllOthers, setExpandAllOthers] = React.useState<boolean>(false);
    const startYear: number = study.started ? new Date(study.started.toString()).getUTCFullYear() : new Date().getUTCFullYear();

    React.useEffect(() => {
        if (permissionGetStudyStatisticsUsers) {
            getStudyStatisticsUsers(Number(study.id)).then(res => {
                const temp: UserStatisticsDisplay[] = [];
                temp.push({ data: res.data.coordinators, label: 'Coordinators', display: (permissionDisplayCalculationTotalsByStudyId && permissionDisplayMonthlyCalculationTotalsByStudyIdAndYear) });
                temp.push({ data: res.data.providers, label: 'Providers', display: (permissionDisplayCalculationTotalsByStudyId && permissionDisplayMonthlyCalculationTotalsByStudyIdAndYear) });
                temp.push({ data: res.data.others, label: 'Others', display: (permissionDisplayCalculationTotalsByStudyId && permissionDisplayMonthlyCalculationTotalsByStudyIdAndYear && (res.data.others.length > 0)) });
                setDisplayUsers(temp);
            });
        }
    }, [study]);

    const handleExpandAllCoordinators = () => {
        setExpandAllCoordinators(!expandAllCoordinators);
    }

    const handleExpandAllProviders = () => {
        setExpandAllProviders(!expandAllProviders);
    }

    const handleExpandAllOthers = () => {
        setExpandAllOthers(!expandAllOthers);
    }

    const displayIcon = (label: string) => {
        if (label === 'Coordinators') {
            return !expandAllCoordinators ? <UnfoldMore /> : <UnfoldLess />;
        } else if (label === 'Providers') {
            return !expandAllProviders ? <UnfoldMore /> : <UnfoldLess />;
        } else if (label === 'Others') {
            return !expandAllOthers ? <UnfoldMore /> : <UnfoldLess />;
        }
    }

    const passExpandedProp = (label: string) => {
        if (label === 'Coordinators') {
            return expandAllCoordinators;
        } else if (label === 'Providers') {
            return expandAllProviders;
        } else {
            return expandAllOthers;
        }
    }

    return (
        <>
            <div className="page-heading">
                <Typography className="title">{DisplayTextFormat(study.name, 64)}</Typography>
            </div>
            <div className="page-body">
                <div className="aligned-row space-between">
                    <div className="center row page-title">
                        Statistics
                        <Tooltip arrow title={infoToolip}><Info className="info-icon" /></Tooltip>
                    </div>
                    <YearSelector year={editYear} setYear={setEditYear} minimum={startYear} />
                </div>
                <Card style={{ width: '100%', padding: 10 }}>
                    <TableContainer component={Paper} style={{ maxHeight: '65vh' }}>
                        <Table stickyHeader className="study-statistics-table">
                            <MonthlyTableHeader editYear={editYear} currentYear={currentYear} currentMonth={currentMonth} />
                            <TableBody>
                                <StudyStatisticsTableRow
                                    study={study} editYear={editYear} currentYear={currentYear} currentMonth={currentMonth}
                                />
                                {displayUsers.map(usersStatDisp => {
                                    return (
                                        usersStatDisp.display &&
                                        <React.Fragment key={usersStatDisp.label}>
                                            <TableRow 
                                                className='resource-row-hover cursor-pointer'
                                                onClick={() => {
                                                    if (usersStatDisp.label === 'Coordinators') {
                                                        handleExpandAllCoordinators();
                                                    } else if (usersStatDisp.label === 'Providers') {
                                                        handleExpandAllProviders();
                                                    } else if (usersStatDisp.label === 'Others') {
                                                        handleExpandAllOthers();
                                                    }
                                                }}
                                            >
                                                <TableCell colSpan={1} className="primary-color sticky">
                                                    {usersStatDisp.label}
                                                    <IconButton 
                                                        className={'white-icon'}
                                                        disabled={!permissionGetStudyStatisticsUsers}
                                                    >
                                                        {displayIcon(usersStatDisp.label)}
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell colSpan={13} className="primary-color" />
                                            </TableRow>
                                            {usersStatDisp.data.map(user => {
                                                return (
                                                    <UserStatisticsTableRow user={user} key={`${usersStatDisp.label}-row-user-${user.id}`}
                                                        study={study} editYear={editYear}
                                                        currentMonth={currentMonth} currentYear={currentYear}
                                                        expanded={passExpandedProp(usersStatDisp.label)}
                                                    />)
                                            })}
                                        </React.Fragment>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </div>
        </>
    );
}

export default StudyStatistics;
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Card, CardHeader, Typography } from '@material-ui/core';

interface Props {
    userId: number,
    missingLogs: number,
}

const GoToEffortLogs: React.FC<Props> = props => {
    const { userId, missingLogs } = props;

    const history = useHistory();

    return (
        <Card className='quick-access-widget effort-link'>
            <CardHeader
                title={<div className="header">Effort Log</div>}
                className="card-header"
            />
            <div className="flex-center column" style={{ height: 105 }}>
                <Typography className={'effort-status ' + (missingLogs > 0 ? 'warning' : '')}>
                    {missingLogs > 0 ?
                        `You have ${missingLogs} unentered effort log${missingLogs == 1 ? '' : 's'} from the last 3 months.` :
                        'You have entered all of your effort for the last 3 months.'
                    }
                </Typography>
                <Button
                    variant="outlined"
                    color="primary"
                    className='outlined-button grey'
                    onClick={() => history.push(`/manage/user/${userId}/statistics`)}
                >
                    Go To Effort Logs
                </Button>
            </div>
        </Card>
    )
}

export default GoToEffortLogs;
import * as React from 'react';

import { User } from '../../../../@types';
import EffortLogTable from './EffortLogTable';

const UserStatistics: React.FC<{ user: User, year?: number }> = props => {
    const { user, year } = props;

    return (
        <div className="main">
            <EffortLogTable user={user} year={year} />
        </div>
    );
}

export default UserStatistics;
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, Card, CardActions, CardContent, Grid, Modal, Typography } from '@material-ui/core';
import { BForm, BGrid, BSubmit, BTextField } from 'mui-bueno';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';

import { SelectOption, Study} from '../../../@types';
import { handleErrorResponse } from '../../../service/utils';
import CardTitle from '../../../common/Card/CardTitle';
import CheckedAutocomplete from '../../../common/CheckedAutocomplete/CheckedAutocomplete';
import { createSimulator, getRestrictedSimulatorStudies } from '../../../service/Simulator/simulator';
import { showSuccessSnackbar } from '../../../modules/messageSnackbarReducer';

interface Props {
    open: any;
    setOpen: any;
}

const schema = yup.object<Study>().shape({
    subtitle: yup.string().required('Subtitle is required'),
    description: yup.string(),
});

export interface CreateSimulator {
    studyId: number;
    subtitle: string;
    description: string;
}

const AddSimulatorModal: React.FC<Props> = props => {
    const { open, setOpen } = props;

    const history = useHistory();
    const dispatch = useDispatch();

    const [studies, setStudies] = React.useState<SelectOption<number>[]>([]);
    const [selectedStudy, setSelectedStudy] = React.useState<null | SelectOption<number>>(null);

    const initial: CreateSimulator = {
        studyId: 0,
        subtitle: '',
        description: '',
    }

    React.useEffect(() => {
        setSelectedStudy(null);
        getRestrictedSimulatorStudies()
            .then((res) => {
                const temp: SelectOption<number>[] = [];
                res.data.forEach((study: Study) => {
                    if (study.id) {
                        temp.push({
                            value: study.id,
                            label: study.name
                        })
                    }
                });
                setStudies(temp);
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve list of Studies: '
                });
            });
    }, [open]);


    // CHANGE TO CREATE SIMULATOR WHEN SERVICE IS UPDATED
    const handleSubmit = async (data: CreateSimulator, { setErrors }: FormikHelpers<CreateSimulator>) => {
        if (selectedStudy) {
            const studyName = (studies.filter(s => s.value == selectedStudy.value))[0].label;
            createSimulator({ ...data, studyId: selectedStudy.value }).then(res => {
                dispatch(showSuccessSnackbar(`Simulator '${studyName}' created`));
                history.push(`/simulator/${res.data.id}`);
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    setStatus: setErrors,
                    prefix: 'Could not create Simulator: '
                });
            });
        }
    }


    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
        >
            <div className='modal-form'>
                <Formik
                    initialValues={initial}
                    onSubmit={handleSubmit}
                    enableReinitialize
                    validationSchema={schema}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    <BForm>
                        <Card className='detail-form sm'>
                            <CardTitle title='Generate Simulator' handleClose={() => setOpen(false)} />
                            {studies.length > 0 ?
                                <>
                                    <CardContent>
                                        <Grid container spacing={1} className='body' justifyContent='center'>
                                            <BGrid xs={12} sm={10} noMP>
                                                <CheckedAutocomplete
                                                    idText='studyId'
                                                    labelText='Study'
                                                    placeholder='Select Study...'
                                                    multiple={false}
                                                    options={studies}
                                                    acValue={selectedStudy}
                                                    onChange={setSelectedStudy}
                                                    closeOnSelect
                                                    variant='outlined'
                                                    noCheckmark autoFocus
                                                    size='small'
                                                    required
                                                />
                                            </BGrid>
                                            <BTextField
                                                name='subtitle'
                                                label='Subtitle'
                                                placeholder='Subtitle of Simulator'
                                                required
                                                xs={12} sm={10} noMP
                                            />
                                            <BTextField
                                                name='description'
                                                label='Description'
                                                placeholder='Enter description...'
                                                multiline={true}
                                                minRows={3}
                                                xs={12} sm={10} noMP
                                            />
                                        </Grid>
                                    </CardContent>
                                    <CardActions className='flex-end'>
                                        <BSubmit
                                            variant='contained'
                                            color='primary'
                                        >
                                            Generate
                                        </BSubmit>
                                    </CardActions>
                                </> :
                                <CardContent className='flex-center column'>
                                    <Typography variant='body1' align='center' style={{ paddingBottom: 20 }}>
                                        Institution has no contracts/studies yet.<br />These are required to create simulators.
                                    </Typography>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={() => history.push(`/`)}
                                    >
                                        Return to Main Application
                                    </Button>
                                </CardContent>
                            }
                        </Card>
                    </BForm>
                </Formik>
            </div>
        </Modal>
    )
};

export default AddSimulatorModal;

import * as React from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import {
    Card, CardActions,
    CardContent, CardHeader,
    Grid, IconButton,
    Modal,
    Typography
} from '@material-ui/core';
import { Close, Save } from '@material-ui/icons';
import {
    BDatePicker, BForm,
    BSubmit
} from 'mui-bueno';

import { Invoice } from '../../../@types';
import { showSuccessSnackbar } from '../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../service/utils';
import { markInvoiceAsSent } from '../../../service/Invoice/invoice';


interface Props {
    open: boolean;
    setOpen: (arg0: boolean) => void;
    invoice: Invoice;
    setInvoice: (arg0: Invoice) => void;
}

const schema = yup.object<Invoice>().shape({
    sentDate: yup.string().required('Send Date is required'),
});

const MarkAsSentModal: React.FC<Props> = props => {
    const { open, setOpen, invoice, setInvoice } = props;

    const dispatcher = useDispatch();

    const handleSubmit = async (data: Invoice, { setErrors }: FormikHelpers<Invoice>) => {
        const localDateSplit = data.sentDate!.toLocaleDateString().split('/');
        const month = Number(localDateSplit[0]) < 10 ? '0' + localDateSplit[0] : localDateSplit[0];
        const day = Number(localDateSplit[1]) < 10 ? '0' + localDateSplit[1] : localDateSplit[1];
        const localDateString = localDateSplit[2] + '-' + month + '-' + day;
        markInvoiceAsSent(invoice.id!, localDateString)
            .then(res => {
                dispatcher(showSuccessSnackbar('Invoice successfully marked as sent.'));
                setInvoice(res.data);
                handleModalClose();
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    setStatus: setErrors,
                    prefix: 'Could not mark invoice as sent: '
                })
            });
    }

    const handleModalClose = () => {
        setOpen(false);
    }

    return (
        <Modal
            open={open}
            onClose={handleModalClose}
        >
            <div className="modal-form">
                <Formik
                    initialValues={{ ...invoice, sentDate: new Date() } as Invoice}
                    onSubmit={handleSubmit}
                    validationSchema={schema}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    <BForm>
                        <Card className="detail-form sm">
                            <CardHeader
                                title="Mark Invoice as Sent"
                                action={
                                    <IconButton color="primary" aria-label="Close" onClick={handleModalClose}>
                                        <Close />
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                <Typography>
                                    This date is meant to record when the invoice was sent. CRAFT does not send invoices.
                                </Typography>
                                <Grid container spacing={0} justifyContent='center'>
                                    <Grid item xs={10}>
                                        <BDatePicker
                                            name="sentDate"
                                            label="Sent Date"
                                            placeholder="Date"
                                            inputVariant='outlined'
                                            required autoFocus
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions className="flex-end">
                                <BSubmit
                                    startIcon={<Save />}
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </BSubmit>
                            </CardActions>
                        </Card>
                    </BForm>
                </Formik>
            </div>
        </Modal>
    )
}

export default MarkAsSentModal;

import axiosInstance from '../Access/axiosInstance';
import { EffortLog, EffortLogStatus } from '../../@types';
import { handleError } from '../utils';

/**
 * Gets a List of EffortLogs by userId and year
 */
export const getEffortLogsByYear = (id: number, year: number, month?: number) =>
    axiosInstance.get<EffortLog[]>(
        `/api/effortLogs/user/${id}/${year}${month ? `?month=${month}` : ''}`
    ).catch(handleError);

/**
 * Gets a List of EffortLogs by userId, studyId and year
 */
export const getEffortLogsByStudyAndYear = (userId: number, studyId: number, year: number) =>
    axiosInstance.get<EffortLog[]>(
        `/api/effortLogs/user/${userId}/study/${studyId}/${year}`
    ).catch(handleError);

/**
 * Processes multiple EffortLogs
 * * EffortLogs with a null id will be created
 * * EffortLogs with a valid id will be updated
 * * EffortLogs with a valid id and a percentageEffort of 0 will be deleted
 */
export const processMultipleEffortLogs = (effortLogs: EffortLog[]) =>
    axiosInstance.put(
        `/api/effortLogs/multiple`, effortLogs
    ).catch(handleError);

/**
 * Returns whether user is on any active studies 
 * and how many effort log inputs from the last 3 months are missing
 */
export const getCurrentUserEffortLogStatus = () =>
    axiosInstance.get<EffortLogStatus>(
        `/api/effortLogs/status`
    ).catch(handleError);
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Chip, CircularProgress, Tooltip, Typography } from '@material-ui/core';
import { PersonPinCircle, Room } from '@material-ui/icons';
import {
    CartesianGrid,
    Label,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    XAxis,
    YAxis
} from 'recharts';

import { SimulatorEvent, SimulatorHealth } from '../../../../../@types';
import { displayDate, getLocalDate } from '../../../../../common/Utils/utils';
import { NumberFormatter } from '../../../../../common/Utils/NumberFormatter';
import { handleErrorResponse } from '../../../../../service/utils';
import { getMonthlyStudyHealthByYear, getSimulatorEvents } from '../../../../../service/Simulator/simulator';
import { Store } from '../../../../../modules/rootReducer';
import { SimulatorState } from '../../../../../modules/simulatorReducer';

import ChartYearControls from '../ChartYearControls';


interface DataPoint {
    dayOfYear: number;
    productivity: number;
    activity: number;
    cost: number;
}

function dateFormatter(value: any): string {
    const futureDate = new Date(2021, 0, 1);
    futureDate.setDate(futureDate.getDate() + value);
    return futureDate.toLocaleString('default', { month: 'short' });
}

function moneyFormatter(value: any): string {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
    }).format(value);
}

export function dateToDayOfYear(date: Date): number {
    return Math.floor((date.getTime() - new Date(date.getFullYear(), 0).getTime()) / (1000 * 60 * 60 * 24));
}

interface Props {
    id: number;
    editYear: number;
    setEditYear: (arg0: number) => void;
}
const ProductivityChart: React.FC<Props> = props => {
    const { id, editYear, setEditYear } = props;
    const currentYear: number = new Date().getFullYear();
    const currentMonth: number = new Date().getMonth();

    const dispatch = useDispatch();

    const { updated } = useSelector<Store, SimulatorState>(
        store => store.simulatorReducer
    );
    
    const [data, setData] = React.useState<DataPoint[]>([]);
    const [events, setEvents] = React.useState<SimulatorEvent[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        setLoading(true);
        getSimulatorEvents(id, editYear)
            .then(res => {
                setEvents(res.data.map(event => {
                    return({
                        ...event,
                        dateOfOccurrence: getLocalDate(event.dateOfOccurrence)
                    });
                }));
            })
            .catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve Simulator Events: '
                });
            })
            .finally(() => setLoading(false));

        setLoading(true);
        getMonthlyStudyHealthByYear(id, editYear)
            .then(res => {
                const dataPoints: DataPoint[] = [];
                Array.from(Array(12).keys()).forEach(index => {
                    const actual: SimulatorHealth | null = res.data.actual[index];
                    const simulated: SimulatorHealth | null = res.data.simulated[index];

                    if (actual && (actual.activity | actual.cost | actual.productivity) != 0) {
                        dataPoints.push({
                            dayOfYear: dateToDayOfYear(getLocalDate(actual.associatedDate)),
                            productivity: actual.productivity,
                            activity: actual.activity,
                            cost: actual.cost
                        });
                    } else if (simulated && (simulated.activity | simulated.cost | simulated.productivity) != 0) {
                        dataPoints.push({
                            dayOfYear: dateToDayOfYear(getLocalDate(simulated.associatedDate)),
                            productivity: simulated.productivity,
                            activity: simulated.activity,
                            cost: simulated.cost
                        });
                    } else {
                        dataPoints.push({
                            dayOfYear: dateToDayOfYear(new Date(editYear, index)),
                            productivity: 0,
                            activity: 0,
                            cost: 0
                        });
                    }
                });
                
                setData(dataPoints);
            })
            .catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve simulated Study health data: '
                });
            })
            .finally(() => setLoading(false));
    },[id, editYear, updated]);

    const yearPercent = (): number => {
        if (editYear < currentYear) {
            return 1;
        } else if (editYear > currentYear) {
            return 0;
        }else {
            return currentMonth / 11;
        }
    };

    const positiveRangePercent = (): number => {
        const maxProductivity = Math.max(...data.map(month => month.productivity ?? 0));
        const minProductivity = Math.min(...data.map(month => month.productivity ?? 0));
        
        if (maxProductivity <= 0) {
            return 0;
        } else if (minProductivity >= 0) {
            return 1;
        } else {
            return maxProductivity / (maxProductivity - minProductivity);
        }
    };

    const CustomXAxisTick = (axisProps: any) => {
        const {x, y, payload} = axisProps;
		
        return (
            <g transform={`translate(${x}, ${y})`}>
                <text x={0} y={0} dy={16} fill="#666">
                    <tspan textAnchor="middle" x={0}>{dateFormatter(payload.value)}</tspan>
                    <tspan textAnchor="middle" x={0} dy={20}>{editYear}</tspan>
                </text>
            </g>
        );
    };
    
    const CustomReferenceLineLabel = (lineLabelProps: any) => {
        const { value, offset, viewBox } = lineLabelProps;
        const width: number = 20;
        const event: SimulatorEvent = JSON.parse(value);

        const tooltip = <div className="productivity-reference-line-tooltip">
            <strong>{displayDate(event.dateOfOccurrence)}</strong>
            <span>{event.event}</span>
        </div>;

        return (
            <Tooltip arrow placement="top" title={tooltip}>
                <g>
                    <foreignObject
                        x={viewBox.x - (width / 2)}
                        y={viewBox.y - width + offset}
                        width={width}
                        height={width}
                    >
                        {event.type === 'EMPLOYEE'
                            ? <PersonPinCircle fontSize="small" className="employee-icon" />
                            : <Room fontSize="small" className="patient-icon" />}
                    </foreignObject>
                    <line
                        x={viewBox.x - (width / 2)}
                        strokeWidth={2}
                        paintOrder={1}
                        x1={viewBox.x}
                        y1={250}
                        x2={viewBox.x}
                        y2={20}
                    />
                </g>
            </Tooltip>
        );
    };

    const TooltipDot: React.FC = (tooltipProps: any) => {
        const { cx, cy, index, payload } = tooltipProps;
        const pointData = data[index];
        
        const [open, setOpen] = React.useState<boolean>(false);

        const tooltip = <div className="productivity-point-tooltip">
            <strong>{dateFormatter(+payload.dayOfYear)}&nbsp;{editYear}</strong>
            <div>
                <span>Productivity:</span>
                <NumberFormatter currency value={payload.productivity} />
            </div>
            <div>
                <span>Activity:</span>
                <NumberFormatter currency value={pointData.activity} />
            </div>
            <div>
                <span>Cost:</span>
                <NumberFormatter currency value={pointData.cost} />
            </div>
        </div>;

        const width: number = 8;
        const offset: number = width / 2;
        const radius: number = 3;
        const mult: number = 10;

        return (
            <>
                <svg
                    x={cx - offset}
                    y={cy - offset}
                    width={width}
                    height={width}
                >
                    <g transform={`translate(${offset} ${offset})`}>
                        <Tooltip
                            arrow
                            placement="top"
                            title={tooltip}
                            open={open}
                        >
                            <circle r={radius} opacity={0} />
                        </Tooltip>
                    </g>
                </svg>
                <svg
                    x={cx - (offset * mult)}
                    y={cy - (offset * mult)}
                    width={width * mult}
                    height={width * mult}
                >
                    <g transform={`translate(${offset * mult} ${offset * mult})`}>
                        <circle r={radius * mult} opacity={0}
                            onMouseEnter={() => setOpen(true)}
                            onMouseLeave={() => setOpen(false)}
                        />
                    </g>
                </svg>
            </>
        );
    };
    const CustomDot = (dotProps: any) => {
        return (
            <TooltipDot {...dotProps} />
        );
    };

    if (loading) return(
        <Box display="flex" justifyContent="center" alignItems="center" textAlign="center" height={354}>
            <div>
                <CircularProgress />
                <Typography gutterBottom variant="h1">
                    Loading...
                </Typography>
            </div>
        </Box>
    );

    return (
        <div className="simulator-productivity-chart">
            <div className="legend">
                <Chip
                    avatar={
                        <svg>
                            <defs>
                                <linearGradient id="productivity-icon-color" x1={0} y1={0} x2={0} y2={1}>
                                    <stop offset={0.5} className="line-positive" />
                                    <stop offset={0.5} className="line-negative" />
                                </linearGradient>
                            </defs>
                            <g transform="translate(9 9)">
                                <circle r={6} fill="url(#productivity-icon-color)" />
                            </g>
                        </svg>
                    }
                    label="Productivity"
                    variant="outlined"
                    size="small"
                />
                <Chip
                    avatar={<PersonPinCircle fontSize="small" className="employee-icon" />}
                    label="Employee Joined/Left"
                    variant="outlined"
                    size="small"
                />
                <Chip
                    avatar={<Room fontSize="small" className="patient-icon" />}
                    label="Patient Added/Dropped"
                    variant="outlined"
                    size="small"
                />
            </div>
            <ResponsiveContainer width="100%" height={300}>
                <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 20,
                    }}
                >
                    <defs>
                        <linearGradient id="future-opacity" x1={0} y1={0} x2={1} y2={0}>
                            <stop offset={0} stopOpacity={0} className="line-background" />
                            <stop offset={yearPercent()} stopOpacity={0} className="line-background" />
                            <stop offset={yearPercent()} className="line-background" />
                            <stop offset={yearPercent()} className="line-background" />
                        </linearGradient>
                        <linearGradient id="negative-color" x1={0} y1={0} x2={0} y2={1}>
                            {positiveRangePercent() !== 0 && <stop offset={positiveRangePercent()} className="line-positive" />}
                            {positiveRangePercent() !== 1 && <stop offset={positiveRangePercent()} className="line-negative" />}
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis
                        dataKey="dayOfYear"
                        scale="time"
                        type="number"
                        tick={CustomXAxisTick}
                        padding={{ left: 60, right: 60 }}
                    />
                    <YAxis tickFormatter={moneyFormatter} />
                    {events.map((event, index) => {
                        return (
                            <ReferenceLine
                                key={`event-line-${index}`}
                                x={dateToDayOfYear(event.dateOfOccurrence)}
                                className={`${event.type.toLocaleLowerCase()}-reference-line`}
                                strokeWidth={2}
                                opacity={0}
                                transform={`translate(${event.type === 'EMPLOYEE' ? 0.35 : -0.35} 0)`}
                                paintOrder={1}
                            >
                                <Label
                                    position="top"
                                    value={JSON.stringify(event)}
                                    content={CustomReferenceLineLabel}
                                />
                            </ReferenceLine>
                        );
                    })}
                    <Line
                        type="linear"
                        dataKey="productivity"
                        name="Productivity"
                        stroke="url(#negative-color)"
                        strokeWidth={3}
                        dot={false}
                        paintOrder={2}
                        connectNulls
                    />
                    <Line
                        type="linear"
                        dataKey="productivity"
                        name="Productivity"
                        stroke="url(#future-opacity)"
                        strokeWidth={3.1}
                        strokeDasharray="5 5"
                        dot={CustomDot}
                        paintOrder={3}
                        connectNulls
                    />
                </LineChart>
            </ResponsiveContainer>
            <ChartYearControls editYear={editYear} setEditYear={setEditYear} />
        </div>
    );
}

export default ProductivityChart;
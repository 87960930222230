import * as React from 'react';
import { useDispatch } from 'react-redux';
import {
    Card,
    CardContent,
    CardHeader,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import {
    Contacts,
    HelpOutline
} from '@material-ui/icons';
import { BButton } from 'mui-bueno';

import {
    User,
    UserRole
} from '../../../../@types';
import {
    assignUserAdminRole,
    getUserRoles,
    removeUserAdminRole
} from '../../../../service/Management/users';
import { showSuccessSnackbar } from '../../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../../service/utils';
import { DisplayTextFormat } from '../../../../common/Utils/DisplayTextFormat';
import useLoggedInUserAccess from '../../../../common/hooks/useLoggedInUserAccess';
import useLoggedInUserPermissions from '../../../../common/hooks/useLoggedInUserPermissions';


const UserRoleInformation: React.FC<{ user: User }> = props => {
    const { user } = props;

    const dispatch = useDispatch();

    const permissionGetUserRoles: boolean = useLoggedInUserAccess('API_USER_GET_USER_ROLES', user);
    const permissionAssignAdminRole: boolean = useLoggedInUserPermissions('API_USER_ASSIGN_ADMIN_ROLE', -1, -1);
    const permissionRemoveAdminRole: boolean = useLoggedInUserPermissions('API_USER_REMOVE_ADMIN_ROLE', -1, -1);

    const [userRoles, setUserRoles] = React.useState<UserRole[]>([]);
    const [update, setUpdate] = React.useState<boolean>(false);
    const [hasAdminRole, setHasAdminRole] = React.useState<boolean>(false);
    const [confirmAssignAdminRole, setConfirmAssignAdminRole] = React.useState<boolean>(false);
    const [confirmRemoveAdminRole, setConfirmRemoveAdminRole] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (user.id && permissionGetUserRoles) {
            getUserRoles(user.id)
                .then(res => {
                    setUserRoles(res.data);

                    setHasAdminRole(false);
                    res.data.forEach(userRole => {
                        if (userRole.roleId === 1)
                            setHasAdminRole(true);
                    });
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not retrieve list of User Roles: '
                    });
                });
        }
    }, [user, update]);

    // Assign Admin Role Handlers
    const handleAssignAdminRole = () => {
        setConfirmAssignAdminRole(true);
    }
    const handleConfirmAssignAdminRole = () => {
        if (user.id) {
            assignUserAdminRole(user.id)
                .then(res => {
                    dispatch(showSuccessSnackbar(`Admin Role assigned to ${user.firstName} ${user.lastName}`));
                    setConfirmAssignAdminRole(false);
                    setUpdate(!update);
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not assign Role to User: '
                    });
                    setConfirmAssignAdminRole(false);
                });
        }
    }

    // Remove Admin Role Handlers
    const handleRemoveAdminRole = () => {
        setConfirmRemoveAdminRole(true);
    }
    const handleConfirmRemoveAdminRole = () => {
        if (user.id) {
            removeUserAdminRole(user.id)
                .then(res => {
                    dispatch(showSuccessSnackbar(`Admin Role removed from ${user.firstName} ${user.lastName}`));
                    setConfirmRemoveAdminRole(false);
                    setUpdate(!update);
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not remove Role from User: '
                    });
                    setConfirmRemoveAdminRole(false);
                });
        }
    }

    const confirmationButton = (variant: string) => {
        let icon;
        let handlerFunction;
        let buttonText: string = '';

        if (variant == 'assignAdminRole') {
            icon = confirmAssignAdminRole ? <HelpOutline /> : <Contacts />;
            handlerFunction = confirmAssignAdminRole ? handleConfirmAssignAdminRole : handleAssignAdminRole;
            buttonText = confirmAssignAdminRole ? 'Confirm Assign Role' : 'Assign System Administrator Role';
        } else if (variant == 'removeAdminRole') {
            icon = confirmRemoveAdminRole ? <HelpOutline /> : <Contacts />;
            handlerFunction = confirmRemoveAdminRole ? handleConfirmRemoveAdminRole : handleRemoveAdminRole;
            buttonText = confirmRemoveAdminRole ? 'Confirm Remove Role' : 'Remove System Administrator Role';
        }

        return (
            <BButton
                variant="contained"
                startIcon={icon}
                className={`full-width ${!user.active ? 'disabled' : 'secondary-button'}`}
                onClick={handlerFunction}
                disabled={!user.active}
            >
                {buttonText}
            </BButton>
        );
    };

    return (
        <>
            {permissionGetUserRoles &&
                <Card className='detail-form'>
                    <CardHeader
                        title="User Role Information"
                    />
                    <CardContent>
                        <TableContainer className='user-role-table' component={Paper}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="primary-color">Role Name</TableCell>
                                        <TableCell className="primary-color">Restriction</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userRoles.map((userRole, index) => {
                                        let label: string = '';
                                        let disabled: boolean = false;

                                        if (userRole.divisionId)
                                            label = `(Division) ${userRole.divisionName}`;
                                        else if (userRole.studyId)
                                            label = `(Study) ${userRole.studyName}`;
                                        else if (!userRole.divisionId && !userRole.studyId)
                                            disabled = true;

                                        return (
                                            <TableRow key={`userRole-${index}`}>
                                                <TableCell>{DisplayTextFormat(userRole.roleName)}</TableCell>
                                                <TableCell className={disabled ? 'background-color' : ''}>{DisplayTextFormat(label)}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            {userRoles.length === 0 && <Typography variant="body1" className="flex-center margin-2">No data to display.</Typography>}
                        </TableContainer>
                        <br />
                        {(permissionAssignAdminRole || permissionRemoveAdminRole) &&
                            confirmationButton(hasAdminRole ? 'removeAdminRole' : 'assignAdminRole')
                        }
                    </CardContent>
                </Card>
            }
        </>
    );
}

export default UserRoleInformation;

import axios, { AxiosInstance } from 'axios';

const institutionName: string = window.location.hostname.split('.')[0];

/* eslint-disable-next-line no-undef*/
const serverIpAddress: string = process.env.REACT_APP_SERVICE_URL
    ? institutionName === 'craft2'
        ? `https://${process.env.REACT_APP_SERVICE_URL}`
        : `https://${institutionName}${process.env.REACT_APP_SERVICE_URL}`
    : 'http://localhost:8080';

const axiosInstance: AxiosInstance = axios.create({
    baseURL: serverIpAddress
});

export default axiosInstance;

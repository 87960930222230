import * as React from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormikHelpers } from 'formik';
import { BOption } from 'mui-bueno';
import { Delete, Edit } from '@material-ui/icons';

import { AccountPageRequest, Procedure, SelectOption } from '../../../../@types';

import { showSuccessSnackbar } from '../../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../../service/utils';
import { getAccountPage } from '../../../../service/Account/account';
import { getResourceTypes } from '../../../../service/Reference/reference';
import { getInvoiceTypes } from '../../../../service/InvoiceType/invoiceType';
import { getProceduresFromContract } from '../../../../service/Contract/contracts';
import { createProcedure, deleteProcedure, reorderProcedures } from '../../../../service/Contract/procedures';

import SimpleConfirmDelete from '../../../../common/ConfirmDelete/SimpleConfirmDelete';
import useLoggedInUserPermissions from '../../../../common/hooks/useLoggedInUserPermissions';
import { ActionOption, TableColumn } from '../../../../common/DataTable/EditablePagingTable';
import { BIG_DECIMAL_MAX, convertAccountDataToSelectOptions, convertReferenceDataToBOptions, INT_MAX } from '../../../../common/Utils/utils';

import ProcedureEdit from './ProcedureEdit';
import DragAndDropTable from '../../../../common/DataTable/DragAndDropTable';
import Loading from '../../../../common/Routes/Loading';

interface State {
    procedureList: any[];
}

const initialState: State = {
    procedureList: [],
}

interface Action {
    type: 'load' | 'added' | 'edited' | 'deleted';
    payload?: any;
}

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'load':
            return {
                ...state,
                procedureList: action.payload,
            }
        case 'added':
            return {
                ...state,
                procedureList: [...state.procedureList, action.payload]
            }
        case 'edited':
            return {
                ...state,
                procedureList: state.procedureList.map(el => el.id === action.payload.id ? action.payload : el)
            }
        case 'deleted':
            return {
                ...state,
                procedureList: state.procedureList.filter(item => item.id !== action.payload)
            }
        default:
            return state;
    }
}

const schema = yup.object<Procedure>().shape({
    name: yup.string().required('Required'),
    sponsorProposal: yup.number().min(0, 'Min value: 0').max(BIG_DECIMAL_MAX, 'Max value: ' + BIG_DECIMAL_MAX),
    actualCost: yup.number().min(0, 'Min value: 0').max(BIG_DECIMAL_MAX, 'Max value: ' + BIG_DECIMAL_MAX),
    timeRequired: yup.number().integer('Need an integer').min(0, 'Min value: 0').max(INT_MAX, 'Max value: ' + INT_MAX),
    resourceType: yup.string().required('Required'),
});

interface Props {
    contractId: number;
    studyId: number;
    divisionId: number;
    readonly: boolean;
}

const Procedures: React.FC<Props> = (props) => {

    const { contractId, divisionId, studyId, readonly } = props;

    // permissions
    const canCreate = useLoggedInUserPermissions('API_PROCEDURE_CREATE', divisionId, studyId);
    const canDelete = useLoggedInUserPermissions('API_PROCEDURE_DELETE_BY_ID', divisionId, studyId);
    const canEdit = useLoggedInUserPermissions('API_PROCEDURE_UPDATE', divisionId, studyId);
    const canReorder = useLoggedInUserPermissions('API_PROCEDURE_REORDER', divisionId, studyId);

    const [state, dispatch] = React.useReducer(reducer, initialState);

    const { procedureList } = state;
    const dispatcher = useDispatch();

    const emptyProcedure: Procedure = {
        id: null,
        contractId: 0,
        name: '',
        sponsorProposal: 0,
        actualCost: 0,
        finalContractCost: null,
        resourceType: '',
        timeRequired: 0,
        invoiceType: 'INVOICE',
        serviceProviderId: undefined,
    }

    const [procedure, setProcedure] = React.useState<Procedure>(emptyProcedure);
    const [doneLoading, setDoneLoading] = React.useState<boolean>(false);

    // State Management for Form
    const [invoiceTypes, setInvoiceTypes] = React.useState<BOption<string>[]>([]);
    const [resourceTypes, setResourceTypes] = React.useState<BOption<string>[]>([]);
    const [serviceProviderOptions, setServiceProviderOptions] = React.useState<SelectOption<number>[]>([]);
    const [selectedServiceProvider, setSelectedServiceProvider] = React.useState<SelectOption<number> | null>(null);
    const [updatableActualCost, setUpdatableActualCost] = React.useState<boolean>(false);
    const [disableServiceProvider, setDisableServiceProvider] = React.useState<boolean>(true);
    const [serviceProviderError, setServiceProviderError] = React.useState<boolean>(false);
    const [disableFinalContractCost, setDisableFinalContractCost] = React.useState<boolean>(false);

    // State management for Edit action
    const [openEdit, setOpenEdit] = React.useState<boolean>(false);
    const [openDelete, setOpenDelete] = React.useState<boolean>(false);
    const [clickedObj, setClickedObj] = React.useState<null | Procedure>(null);

    // State management to rerender when the list has been reordered
    const [reorderedList, setReorderedList] = React.useState<any[]>([]);

    // Initial load data
    React.useEffect(() => {
        getInvoiceTypes().then(res => {
            setInvoiceTypes(convertReferenceDataToBOptions(res.data));
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                prefix: 'Could not get InvoiceTypes: '
            })
        });

        getResourceTypes().then(res => {
            setResourceTypes(convertReferenceDataToBOptions(res.data));
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                prefix: 'Could not get Resource Types: '
            })
        });

        const pageReq: AccountPageRequest = {
            page: 0,
            sort: 'name'
        };
        getAccountPage(pageReq).then(res => {
            setServiceProviderOptions(convertAccountDataToSelectOptions(res.data.list))
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                prefix: 'Could not retrieve list of Service Providers: '
            });
        });

        getProceduresFromContract(contractId).then(res => {
            dispatch({ type: 'load', payload: res.data });
            setDoneLoading(true);
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                prefix: 'Could not find Procedures for the contract: '
            })
        });

        if (procedure.serviceProviderId) {
            setSelectedServiceProvider(serviceProviderOptions.find(o => o.value == procedure.serviceProviderId)!)
        }
    }, []);

    // Rerender the list when it has been reordered
    React.useEffect(() => {
        dispatch({ type: 'load', payload: reorderedList });
    }, [reorderedList]);

    const handleResourceTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const resourceType = resourceTypes[event.target.value as number].value;
        setUpdatableActualCost(resourceType === 'O');
    }

    const handleInvoiceTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const invoiceType = invoiceTypes[event.target.value as number].value;
        setDisableServiceProvider(invoiceType != 'PASS_THROUGH');
        setDisableFinalContractCost(invoiceType != 'INVOICE' && invoiceType != 'PASS_THROUGH');
    }

    const handleClickDelete = () => {
        setOpenDelete(true);
    };

    const handleDelete = (selectedObj: null | Procedure) => {
        if (selectedObj) {
            deleteProcedure(selectedObj.id!)
                .then(res => {
                    dispatch({ type: 'deleted', payload: selectedObj.id });
                    dispatcher(showSuccessSnackbar(`Procedure ${selectedObj.name} deleted`));
                }).catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        prefix: 'Could not delete Procedure: '
                    });
                });
        }
        setOpenDelete(false);
    }

    const handleClickEdit = () => {
        setOpenEdit(true);
    }

    const handleEdit = (editedProcedure: Procedure) => {
        dispatch({ type: 'edited', payload: editedProcedure });
    }

    // Form management
    const handleSubmit = async (data: Procedure, { setErrors, resetForm }: FormikHelpers<Procedure>) => {

        if (!disableServiceProvider && (!selectedServiceProvider || selectedServiceProvider.value == 0)) {
            setServiceProviderError(true);
            return;
        }

        const serviceProviderId = disableServiceProvider ? undefined : selectedServiceProvider?.value
        const newProcedure = {
            ...data,
            contractId: Number(contractId),
            serviceProviderId: serviceProviderId
        }
        createProcedure(newProcedure).then(res => {
            setProcedure(emptyProcedure);
            resetForm();
            setDisableServiceProvider(true);
            setServiceProviderError(false);
            setSelectedServiceProvider(null);
            setDisableFinalContractCost(false);
            dispatch({ type: 'added', payload: res.data });
            dispatcher(showSuccessSnackbar('Procedure added'));
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                setStatus: setErrors,
                prefix: 'Could not add Procedure: '
            });
        });
    };

    const handleReorderProcedures = (procedureContractId: number, idsList: number[]) => {
        reorderProcedures(procedureContractId, idsList).catch(err => {
            handleErrorResponse(err, dispatcher, {
                prefix: 'Could not reorder list of Procedures: '
            })
        });
    };

    const actions: ActionOption[] = [
        { label: 'Edit', icon: <Edit />, handler: handleClickEdit, permission: canEdit },
        { label: 'Delete', icon: <Delete />, handler: handleClickDelete, permission: canDelete }
    ];

    const columns: TableColumn[] = [{
        type: 'name',
        displayValue: 'Name',
        align: 'left',
        placeholder: 'Name...',
        required: true,
        noSort: true,
        style: { minWidth: 250 },
    }, {
        type: 'resourceType',
        displayValue: 'Resource Type',
        align: 'center',
        placeholder: 'Resource Type',
        required: true,
        style: { minWidth: 60 },
        bOptions: resourceTypes,
        onChange: handleResourceTypeChange,
        noSort: true
    }, {
        type: 'sponsorProposal',
        displayValue: 'Sponsor Proposal',
        align: 'right',
        required: false,
        style: { minWidth: 100 },
        format: 'currency-US',
        noSort: true
    }, {
        type: 'actualCost',
        displayValue: 'Actual Cost',
        align: 'right',
        required: false,
        style: { minWidth: 100 },
        format: 'currency-US',
        disabled: !updatableActualCost,
        tooltipTitle: !updatableActualCost ?
            'Actual cost will be calculated based on the cost of the resource type and the time required once the procedure is added'
            : undefined,
        noSort: true
    }, {
        type: 'timeRequired',
        displayValue: 'Time Req (min)',
        align: 'center',
        placeholder: '0',
        required: false,
        style: { minWidth: 60 },
        format: 'integer',
        noSort: true
    }, {
        type: 'invoiceType',
        displayValue: 'Invoice Type',
        align: 'left',
        placeholder: 'Invoice Type',
        required: false,
        style: { width: 150 },
        bOptions: invoiceTypes,
        onChange: handleInvoiceTypeChange,
        format: 'enum',
        noSort: true
    }, {
        type: 'serviceProvider',
        displayValue: 'Service Provider',
        align: 'left',
        placeholder: '',
        required: false,
        style: { minWidth: 200 },
        onChange: setSelectedServiceProvider,
        multiple: false,
        closeOnSelect: true,
        noCheckmark: true,
        selectOptions: serviceProviderOptions,
        disabled: disableServiceProvider,
        acValue: selectedServiceProvider,
        error: serviceProviderError,
        tooltipTitle: disableServiceProvider ?
            'Service provider is only needed if the invoice type is Pass Through'
            : undefined,
        noSort: true
    }, {
        type: 'finalContractCost',
        displayValue: 'Final Cost',
        align: 'right',
        required: false,
        style: { minWidth: 100 },
        format: 'currency-US',
        disabled: disableFinalContractCost,
        tooltipTitle: disableFinalContractCost ?
            'Final cost is only needed if the invoice type is Invoice or Pass Through'
            : undefined,
        noSort: true
    }, {
        type: 'numInsideSequence',
        displayValue: 'Sequence',
        align: 'left',
        derivedField: true,
        hidden: true
    },];

    if (doneLoading) {
        return (
            <>
                <DragAndDropTable
                    dataList={procedureList}
                    setReorderedList={setReorderedList}
                    reorderListHandler={handleReorderProcedures}
                    tableInfoColumns={columns}
                    editingDataObject={procedure}
                    handleSubmit={handleSubmit}
                    validationSchema={schema}
                    readonly={readonly}
                    canCreate={canCreate}
                    clickedObj={clickedObj}
                    setClickedObj={setClickedObj}
                    actionOptions={actions}
                    initialSortType='numInsideSequence'
                    canReorder={canReorder}
                />
                {clickedObj && openEdit &&
                    <ProcedureEdit
                        open={openEdit}
                        setOpen={setOpenEdit}
                        procedure={clickedObj ?? emptyProcedure}
                        handleEdit={handleEdit}
                        resourceTypes={resourceTypes}
                        invoiceTypes={invoiceTypes}
                        serviceProviderOptions={serviceProviderOptions}
                    />
                }
                {clickedObj && openDelete &&
                    <SimpleConfirmDelete
                        open={openDelete}
                        setOpen={setOpenDelete}
                        type='Procedure'
                        objectName={clickedObj?.name ? clickedObj.name : ''}
                        handleDelete={() => handleDelete(clickedObj)}
                    />
                }
            </>
        )
    } else {
        return (
            <Loading />
        )
    }
}

export default Procedures;
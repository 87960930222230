import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { Button, Card, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import { CallMade, ChevronRight, Create, CreateNewFolder, Delete, DescriptionOutlined, Folder, GetApp, NoteAdd, Settings } from '@material-ui/icons';

import { Directory, DirectoryDetail, DocumentTree } from '../../../../@types';
import { handleErrorResponse } from '../../../../service/utils';
import { deleteDirectory } from '../../../../service/Study/directories';
import { getDocumentsByDirectory } from '../../../../service/Study/documents';

import { displayDateWithTime } from '../../../../common/Utils/utils';
import { DisplayTextFormat } from '../../../../common/Utils/DisplayTextFormat';
import { showSuccessSnackbar } from '../../../../modules/messageSnackbarReducer';
import SimpleConfirmDelete from '../../../../common/ConfirmDelete/SimpleConfirmDelete';
import useLoggedInUserPermissions from '../../../../common/hooks/useLoggedInUserPermissions';
import { handleDownloadDocument } from './Documents';

import MoveModal from './Modal/MoveModal';
import UploadModal from './Modal/UploadModal';
import RenameModal from './Modal/RenameModal';
import AddDirectoryModal from './Modal/AddDirectoryModal';
import DeletionSettingModal from './Modal/DeletionSettingModal';

interface Props {
    studyId: number;
    divisionId: number;
    selectedDirectory: any;
    setSelectedDirectory: any;
    studyStarted: boolean;
    studyCompleted: boolean;
    notStartedUploadText: string;
    completedUploadText: string;
    setUpdated: any;
    updated: boolean;
    canUploadNewDoc: boolean;
    canCreateNewDir: boolean;
    directories: DocumentTree[];
    parentDirectories: Directory[];
    setParentDirectories: any;

}

const DirectoryPreview: React.FC<Props> = (props) => {
    const {
        studyId, divisionId, selectedDirectory, setSelectedDirectory, studyStarted,
        studyCompleted, notStartedUploadText, completedUploadText, setUpdated, updated, canUploadNewDoc,
        canCreateNewDir, directories, parentDirectories, setParentDirectories
    } = props;

    const history = useHistory();
    const dispatcher = useDispatch();

    const [files, setFiles] = React.useState<DirectoryDetail[]>([]);
    const [uploadOpen, setUploadOpen] = React.useState<boolean>(false);
    const [addDirectoryOpen, setAddDirectoryOpen] = React.useState<boolean>(false);
    const [renameOpen, setRenameOpen] = React.useState<boolean>(false);
    const [moveOpen, setMoveOpen] = React.useState<boolean>(false);
    const [deletionSettingOpen, setDeletionSettingOpen] = React.useState<boolean>(false);
    const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);
    const [deletable, setDeletable] = React.useState<boolean>(false);
    const [hasDocuments, setHasDocuments] = React.useState<boolean>(false);

    // Text for tooltips
    const notStartedRename = 'Cannot rename a directory before the study has been started';
    const completedRename = 'Cannot rename a directory after the study has been completed';
    const notStartedMove = 'Cannot move a directory before the study has been started';
    const completedMove = 'Cannot move a directory after the study has been completed';
    const notStartedDelete = 'Cannot delete a directory before the study has been started';
    const completedDelete = 'Cannot delete a directory after the study has been completed';
    const hasDocumentsDelete = 'Directories containing documents or directories cannot be deleted';
    const notStartedDeletionSetting = 'Cannot change the deletion settings of a directory before the study has been started';
    const completedDeletionSetting = 'Cannot change the deletion settings of a directory after the study has been completed';

    // State management to determine whether to display buttons, display documents, and allow upload and delete capability based on user's permissions and role restriction
    const canUpdate = useLoggedInUserPermissions('API_DIRECTORY_UPDATE', divisionId, studyId);
    const canDelete = useLoggedInUserPermissions('API_DIRECTORY_DELETE_BY_ID', divisionId, studyId);
    const canDownload = useLoggedInUserPermissions('API_DOCUMENT_DOWNLOAD_DOCUMENT', divisionId, studyId);
    const canUpdateDeletionSetting = useLoggedInUserPermissions('API_DIRECTORY_UPDATE_DELETION_SETTING', divisionId, studyId);
    
    const isNonRootDirectory = (dir: any) => {
        if (dir && dir.id > 0) return true;
        return false;
    }

    const isRoot = (dir: any) => {
        if (dir && dir.id == 0) return true;
        return false;
    }
    
    const handleDelete = (dirId: number) => {
        deleteDirectory(dirId).then(() => {
            history.push(`/study/${Number(studyId)}/documents`);
            setUpdated(true);
            setDeleteOpen(false);
            setSelectedDirectory(null);
            dispatcher(showSuccessSnackbar('Directory deleted'));
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                prefix: 'Could not delete directory: '
            });
        });
    };

    React.useEffect(() => {
        setFiles([]);
        setParentDirectories([]);
        if (selectedDirectory && selectedDirectory.id != 0) {
            getDocumentsByDirectory(selectedDirectory.id)
                .then(res => {
                    setFiles(res.data.directoryDetailList);
                    setParentDirectories(res.data.directoryList);
                    res.data.directoryDetailList.length > 0 ? setHasDocuments(true) : setHasDocuments(false);
                })
                .catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        prefix: 'Could not retrieve files from directory: '
                    });
                });
            setDeletable(selectedDirectory.allowedFileDeleted);
        }
    }, [selectedDirectory, updated]);

    if (selectedDirectory === undefined) {
        return (
            <div className='plain-message'>
                <Typography variant='h6'>
                    Directory does not exist
                </Typography>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => history.push(`/study/${Number(studyId)}/documents/directory`)}
                    style={{ margin: 10 }}
                >
                    Back to All Directories
                </Button>
            </div>
        )
    }

    const renderList = () => {
        if (isRoot(selectedDirectory)) {
            return (
                directories.map((obj: DocumentTree, idx: number) => {
                    return (
                        <TableRow
                            key={'row-' + idx}
                            className='clickable-table-row'
                            onClick={() => history.push(`/study/${Number(studyId)}/documents/directory/${obj.id}`)}
                        >
                            <TableCell className='icon-cell'>
                                <div className='file-name'>
                                    <Folder />
                                    <div className='body-cell-label'>
                                        {obj.name}
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell /><TableCell /><TableCell />
                        </TableRow>
                    )
                })
            )
        }
        if (isNonRootDirectory(selectedDirectory) && files.length > 0) {
            return (
                files.map((obj: DirectoryDetail, idx: number) => {
                    if (obj.document) {
                        return (
                            <TableRow
                                key={'row-' + idx}
                                className='clickable-table-row'
                                onClick={() => history.push(`/study/${Number(studyId)}/documents/document/${obj.id}`)}
                            >
                                <TableCell className='icon-cell'>
                                    <div className='file-name'>
                                        <DescriptionOutlined />
                                        <div className='body-cell-label'>
                                            <div>{DisplayTextFormat(obj.name, 60)}</div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div className='body-cell-label'>
                                        {displayDateWithTime(obj.uploaded)}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div className='body-cell-label'>
                                        {obj.documentSize} {obj.documentSizeUnit}
                                    </div>
                                </TableCell>
                                <TableCell className='center'>
                                    {canDownload &&
                                        <IconButton style={{ padding: 5 }}
                                            onClick={(event) => handleDownloadDocument(Number(obj.id), null, dispatcher, obj.name, event)}
                                        >
                                            <GetApp />
                                        </IconButton>
                                    }
                                </TableCell>
                            </TableRow>
                        )
                    } else {
                        return (
                            <TableRow
                                key={'row-' + idx}
                                className='clickable-table-row'
                                onClick={() => history.push(`/study/${Number(studyId)}/documents/directory/${obj.id}`)}
                            >
                                <TableCell className='icon-cell'>
                                    <div className='file-name'>
                                        <Folder />
                                        <div className='body-cell-label'>
                                            {obj.name}
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell /><TableCell /><TableCell />
                            </TableRow>
                        )
                    }
                })
            )
        }
        if (isNonRootDirectory(selectedDirectory) && files.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={9} className='body-cell center' style={{ height: 80, fontSize: 16 }}>
                        No data to display.
                    </TableCell>
                </TableRow>
            )
        }
        return <TableRow />;
    }

    return (
        <>
            <Card className="split-cards details documents-preview">
                <div className='card-header'>
                    <p
                        className={'cursor-pointer ' + (isRoot(selectedDirectory) && 'active-path')}
                        onClick={() => history.push(`/study/${Number(studyId)}/documents`)}
                    >
                        All
                    </p>
                    {isNonRootDirectory(selectedDirectory) && <ChevronRight />}
                    {parentDirectories.map((dir: Directory, idx: number) => {
                        return (
                            <React.Fragment key={'dir-' + idx}>
                                <p className='cursor-pointer' onClick={() => history.push(`/study/${Number(studyId)}/documents/directory/${dir.id}`)}>
                                    {dir.name}
                                </p>
                                <ChevronRight />
                            </React.Fragment>
                        )
                    })}
                    {isNonRootDirectory(selectedDirectory) && <p className='active-path'>{selectedDirectory.name}</p>}
                </div>
                <div className='file-actions'>
                    {(isNonRootDirectory(selectedDirectory) && canUploadNewDoc) &&
                        <Tooltip arrow title={!studyStarted ? notStartedUploadText : studyCompleted ? completedUploadText : ''}>
                            <div>
                                <Button
                                    variant='outlined'
                                    onClick={() => setUploadOpen(true)}
                                    disabled={!studyStarted || studyCompleted}
                                    className={(!studyStarted || studyCompleted) ? 'small-grey-button-disabled' : 'small-grey-button'}
                                >
                                    <NoteAdd />
                                    Upload New Document
                                </Button>
                            </div>
                        </Tooltip>
                    }
                    {canCreateNewDir &&
                        <Tooltip arrow title={!studyStarted ? notStartedUploadText : studyCompleted ? completedUploadText : ''}>
                            <div>
                                <Button
                                    variant='outlined'
                                    onClick={() => setAddDirectoryOpen(true)}
                                    disabled={!studyStarted || studyCompleted}
                                    className={(!studyStarted || studyCompleted) ? 'small-grey-button-disabled' : 'small-grey-button'}
                                >
                                    <CreateNewFolder />
                                    New Directory
                                </Button>
                            </div>
                        </Tooltip>
                    }
                    {(isNonRootDirectory(selectedDirectory) && canUpdate) && <>
                        <Tooltip arrow title={!studyStarted ? notStartedRename : studyCompleted ? completedRename : ''}>
                            <div>
                                <Button
                                    onClick={() => setRenameOpen(true)}
                                    variant='outlined'
                                    disabled={!studyStarted || studyCompleted}
                                    className={(!studyStarted || studyCompleted) ? 'small-grey-button-disabled' : 'small-grey-button'}
                                >
                                    <Create />
                                    Rename Directory
                                </Button>
                            </div>
                        </Tooltip>
                        <Tooltip arrow title={!studyStarted ? notStartedMove : studyCompleted ? completedMove : ''}>
                            <div>
                                <Button
                                    onClick={() => setMoveOpen(true)}
                                    variant='outlined'
                                    disabled={!studyStarted || studyCompleted}
                                    className={(!studyStarted || studyCompleted) ? 'small-grey-button-disabled' : 'small-grey-button'}
                                >
                                    <CallMade />
                                    Move Directory
                                </Button>
                            </div>
                        </Tooltip>
                    </>}
                    {(isNonRootDirectory(selectedDirectory) && canUpdateDeletionSetting) &&
                        <Tooltip arrow title={!studyStarted ? notStartedDeletionSetting : studyCompleted ? completedDeletionSetting : ''}>
                            <div>
                                <Button
                                    onClick={() => setDeletionSettingOpen(true)}
                                    variant='outlined'
                                    disabled={!studyStarted || studyCompleted}
                                    className={(!studyStarted || studyCompleted) ? 'small-grey-button-disabled' : 'small-grey-button'}
                                >
                                    <Settings />
                                    Deletion Setting
                                </Button>
                            </div>
                        </Tooltip>
                    }
                    {(isNonRootDirectory(selectedDirectory) && canDelete && deletable) &&
                        <Tooltip arrow title={!studyStarted ? notStartedDelete : studyCompleted ?
                            completedDelete : hasDocuments ? hasDocumentsDelete : ''}
                        >
                            <div>
                                <Button
                                    startIcon={<Delete />}
                                    className={(!studyStarted || studyCompleted || hasDocuments) ? 'small-grey-button-disabled' : 'small-grey-button'}
                                    onClick={() => setDeleteOpen(true)}
                                    disabled={!studyStarted || studyCompleted || hasDocuments}
                                >
                                    Delete Directory
                                </Button>
                            </div>
                        </Tooltip>
                    }
                </div>
                <TableContainer className='folder-contents-table  documents-height document-preview'>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className='primary-cell icon-cell-header'>
                                    <div className='header-cell-label'>
                                        Name
                                    </div>
                                </TableCell>
                                <TableCell className='primary-cell'>
                                    <div className='header-cell-label'>
                                        Upload Date
                                    </div>
                                </TableCell>
                                <TableCell className='primary-cell'>
                                    <div className='header-cell-label'>
                                        Size
                                    </div>
                                </TableCell>
                                <TableCell className='primary-cell'>
                                    <div className="header-cell-label center">
                                        Download
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderList()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
            <AddDirectoryModal
                open={addDirectoryOpen}
                setOpen={setAddDirectoryOpen}
                setUpdated={setUpdated}
                studyId={studyId}
                parentDirectorySet={isNonRootDirectory(selectedDirectory) ? selectedDirectory : null}
            />
            {isNonRootDirectory(selectedDirectory) &&
                <>
                    <UploadModal
                        open={uploadOpen}
                        setOpen={setUploadOpen}
                        setUpdated={setUpdated}
                        studyId={studyId}
                        selectedDocument={null}
                        setSelectedDocument={null}
                        currentDirectory={selectedDirectory}
                    />
                    <RenameModal
                        open={renameOpen}
                        setOpen={setRenameOpen}
                        setUpdated={setUpdated}
                        selectedDirectory={selectedDirectory}
                        setSelectedDirectory={setSelectedDirectory}
                    />
                    <MoveModal
                        open={moveOpen}
                        setOpen={setMoveOpen}
                        setUpdated={setUpdated}
                        studyId={studyId}
                        selectedDirectory={selectedDirectory}
                        setSelectedDirectory={setSelectedDirectory}
                    />
                    <DeletionSettingModal
                        open={deletionSettingOpen}
                        setOpen={setDeletionSettingOpen}
                        setUpdated={setUpdated}
                        selectedDirectory={selectedDirectory}
                        setSelectedDirectory={setSelectedDirectory}
                    />
                    <SimpleConfirmDelete
                        open={deleteOpen}
                        setOpen={setDeleteOpen}
                        type='Directory'
                        objectName={selectedDirectory.name}
                        handleDelete={() => handleDelete(selectedDirectory.id!)}
                    />
                </>
            }
        </>
    )
}

export default DirectoryPreview;

import * as React from 'react';
import { useDispatch } from 'react-redux';

import {
    Card, CardActions, CardContent, CardHeader,
    Grid,
    IconButton, InputAdornment, Modal
} from '@material-ui/core';
import { Close, Save } from '@material-ui/icons';
import { BForm, BGrid, BOption, BSelect, BSubmit, BTextField } from 'mui-bueno';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';

import { Procedure, SelectOption } from '../../../../@types';
import { showSuccessSnackbar } from '../../../../modules/messageSnackbarReducer';
import { BIG_DECIMAL_MAX, INT_MAX } from '../../../../common/Utils/utils';
import { NumberFormatter } from '../../../../common/Utils/NumberFormatter';
import CheckedAutocomplete from '../../../../common/CheckedAutocomplete/CheckedAutocomplete';
import { handleErrorResponse } from '../../../../service/utils';
import { updateProcedure } from '../../../../service/Contract/procedures';


interface Props {
    open: any;
    setOpen: any;
    procedure: Procedure;
    handleEdit?: any;
    setReload?: any;
    resourceTypes: BOption<string>[];
    invoiceTypes: BOption<string>[];
    serviceProviderOptions: SelectOption<number>[];
    readOnly?: boolean;
}

const schema = yup.object<Procedure>().shape({
    name: yup.string().required('Required'),
    sponsorProposal: yup.number().min(0, 'Min value: 0').max(BIG_DECIMAL_MAX, 'Max value: ' + BIG_DECIMAL_MAX),
    actualCost: yup.number().when('resourceType', {
        is: 'O',
        then: yup.number().typeError('A number is required').required('Required').min(0, 'Min value: 0').max(BIG_DECIMAL_MAX, 'Max value: ' + BIG_DECIMAL_MAX),
        otherwise: yup.number().nullable(true)
    }),
    timeRequired: yup.number().integer('Need an integer').min(0, 'Min value: 0').max(INT_MAX, 'Max value: ' + INT_MAX),
    resourceType: yup.string().required('Required'),
});

const ProcedureEdit: React.FC<Props> = props => {
    const { open, setOpen, procedure, handleEdit, setReload, resourceTypes, invoiceTypes, serviceProviderOptions, readOnly } = props;
    const dispatcher = useDispatch();

    const [disableActualCost, setDisableActualCost] = React.useState<boolean>(procedure.resourceType != 'O');
    const [disableServiceProvider, setDisableServiceProvider] = React.useState<boolean>(procedure.invoiceType != 'PASS_THROUGH');
    const [disableFinalContractCost, setDisableFinalContractCost] = React.useState<boolean>(procedure.invoiceType != 'INVOICE' && procedure.invoiceType != 'PASS_THROUGH');
    const [selectedServiceProvider, setSelectedServiceProvider] = React.useState<SelectOption<number> | null>();
    const [serviceProviderError, setServiceProviderError] = React.useState<boolean>(false);

    const handleSubmit = async (data: Procedure, { setErrors }: FormikHelpers<Procedure>) => {

        if (!disableServiceProvider && !selectedServiceProvider) {
            setServiceProviderError(true);
            return;
        }

        updateProcedure({
            ...data,
            serviceProviderId: disableServiceProvider ? undefined : selectedServiceProvider?.value
        }).then(res => {
            dispatcher(showSuccessSnackbar(`Procedure ${data.name} updated`));
            handleModalClose();
            if (setReload) setReload(true);
            if (handleEdit) handleEdit(res.data);
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                setStatus: setErrors,
                prefix: 'Could not update Procedure: '
            });
        });
    }

    React.useEffect(() => {
        if (procedure.serviceProviderId) {
            setSelectedServiceProvider(serviceProviderOptions.find(o => o.value == procedure.serviceProviderId))
        }
    }, [setOpen])

    const handleModalClose = () => {
        setOpen(false);
        setSelectedServiceProvider(null);
    }

    const handleResourceTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const resourceType = resourceTypes[event.target.value as number].value;
        setDisableActualCost(resourceType != 'O');
    }

    const handleInvoiceTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const invoiceType = invoiceTypes[event.target.value as number].value;
        setDisableServiceProvider(invoiceType != 'PASS_THROUGH');
        setDisableFinalContractCost(invoiceType != 'INVOICE' && invoiceType != 'PASS_THROUGH');
    }

    return (
        <Modal
            open={open}
            onClose={handleModalClose}
        >
            <div className="modal-form">
                <Formik
                    initialValues={procedure}
                    onSubmit={handleSubmit}
                    validationSchema={schema}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    <BForm>
                        <Card className="detail-form sm">
                            <CardHeader
                                title={`${readOnly ? 'View' : 'Edit'} Procedure`}
                                action={
                                    <IconButton color="primary" aria-label="Close" onClick={handleModalClose}>
                                        <Close />
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                <BGrid container spacing={2} alignment='center'>
                                    <BTextField
                                        name='name'
                                        label='Name'
                                        placeholder='Name...'
                                        disabled={readOnly}
                                        required autoFocus
                                        xs={12}
                                        noMP
                                    />
                                    <BSelect
                                        name='resourceType'
                                        label='Resource Type'
                                        placeholder='Resource Type...'
                                        options={resourceTypes}
                                        onChange={handleResourceTypeChange}
                                        disabled={readOnly}
                                        required
                                        xs={6}
                                        noMP
                                    />
                                    <BTextField
                                        name='timeRequired'
                                        label='Time Required (mins)'
                                        placeholder='0'
                                        InputProps={{
                                            inputComponent: NumberFormatter as any,
                                            inputProps: { integer: true }
                                        }}
                                        disabled={readOnly}
                                        xs={6}
                                        noMP
                                    />
                                    <BSelect
                                        name='invoiceType'
                                        label='Invoice Type'
                                        placeholder='Invoice Type'
                                        options={invoiceTypes}
                                        onChange={handleInvoiceTypeChange}
                                        disabled={readOnly}
                                        xs={!disableServiceProvider ? 6 : 12}
                                        noMP
                                    />
                                    {!disableServiceProvider &&
                                        <Grid item xs={6}>
                                            <div className='service-provider-input'>
                                                <CheckedAutocomplete
                                                    idText='serviceProviderId'
                                                    placeholder='Service Provider'
                                                    multiple={false}
                                                    noCheckmark
                                                    closeOnSelect
                                                    acValue={selectedServiceProvider}
                                                    options={serviceProviderOptions}
                                                    onChange={setSelectedServiceProvider}
                                                    variant='outlined'
                                                    size='small'
                                                    labelText='Service Provider'
                                                    disabled={disableServiceProvider || readOnly}
                                                    error={serviceProviderError}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </div>
                                        </Grid>
                                    }
                                    <BTextField
                                        name='sponsorProposal'
                                        label='Sponsor Proposal'
                                        placeholder='0.00'
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            inputComponent: NumberFormatter as any
                                        }}
                                        disabled={readOnly}
                                        xs={12} sm={!disableFinalContractCost ? 4 : 6}
                                        noMP
                                    />
                                    <BTextField
                                        name='actualCost'
                                        label='Actual Cost'
                                        placeholder='0.00'
                                        disabled={disableActualCost || readOnly}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            inputComponent: NumberFormatter as any
                                        }}
                                        xs={12} sm={!disableFinalContractCost ? 4 : 6}
                                        noMP
                                    />
                                    {!disableFinalContractCost &&
                                        <BTextField
                                            name='finalContractCost'
                                            label='Final Cost'
                                            placeholder='0.00'
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                inputComponent: NumberFormatter as any
                                            }}
                                            disabled={disableFinalContractCost || readOnly}
                                            xs={12} sm={4} noMP
                                        />
                                    }
                                </BGrid>
                            </CardContent>
                            {!readOnly &&
                                <CardActions className="flex-end">
                                    <BSubmit
                                        startIcon={<Save />}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Save
                                    </BSubmit>
                                </CardActions>
                            }
                        </Card>
                    </BForm>
                </Formik>
            </div>
        </Modal>
    )
};

export default ProcedureEdit;

import axiosInstance from '../Access/axiosInstance';
import { Directory, DirectoryHierarchy, DirectoryPage, DirectoryPageRequest } from '../../@types';
import { handleError } from '../utils';

/**
* Gets a page of directories
*/
export const getDirectoryPage = (pageReq: DirectoryPageRequest) =>
    axiosInstance.get<DirectoryPage>(
        '/api/directories', { params: pageReq }
    ).catch(handleError);

/**
 * Gets a directory by id
 */
export const getDirectory = (id: number) =>
    axiosInstance.get<Directory>(
        `/api/directories/${id}`
    ).catch(handleError);

/**
 * Gets directories by study id
 */
export const getDirectoriesByStudy = (studyId: number) =>
    axiosInstance.get<Directory[]>(
        `/api/directories/study/${studyId}`
    ).catch(handleError);

/**
 * Updates a directory
 */
export const updateDirectory = (directory: Directory) =>
    axiosInstance.put<Directory>(
        '/api/directories', directory
    ).catch(handleError);

/**
 * Creates a directory
 */
export const createDirectory = (directory: Directory) =>
    axiosInstance.post<Directory>(
        '/api/directories', directory
    ).catch(handleError);

/**
 * Deletes a directory
 */
export const deleteDirectory = (id: number) =>
    axiosInstance.delete(
        `/api/directories/${id}`
    ).catch(handleError);

/**
 * Gets a directory hierarchy
 */
export const getDirectoryHierarchy = (id: number) =>
    axiosInstance.get<DirectoryHierarchy>(
        `/api/directories/hierarchy/${id}`
    ).catch(handleError);

/**
 * Updates the deletion setting for a directory
 */
export const updateDirectoryDeletionSetting = (id: number, allowedDeleted: boolean) =>
    axiosInstance.put(
        `/api/directories/deletionSetting/${id}?allowedFileDeleted=${allowedDeleted}`
    ).catch(handleError);
import axiosInstance from '../Access/axiosInstance';
import { PlannedCost, PlannedCostPage, PlannedCostPageRequest } from '../../@types';
import { handleError } from '../utils';

/**
 * Gets a page of planned costs
 */
export const getPlannedCostPage = (pageReq: PlannedCostPageRequest) =>
    axiosInstance.get<PlannedCostPage>(
        '/api/plannedCosts', {
            params: pageReq
        }
    ).catch(handleError);

/**
 * Gets a planned cost by id
 */
export const getPlannedCost = (id: number) =>
    axiosInstance.get<PlannedCost>(
        `/api/plannedCosts/${id}`
    ).catch(handleError);

/**
 * Creates a planned cost
 */
export const createPlannedCost = (plannedCost: PlannedCost) =>
    axiosInstance.post<PlannedCost>(
        '/api/plannedCosts', plannedCost
    ).catch(handleError);

/**
 * Updates a planned cost
 */
export const updatePlannedCost = (plannedCost: PlannedCost) =>
    axiosInstance.put<PlannedCost>(
        '/api/plannedCosts', plannedCost
    ).catch(handleError);

/**
 * Deletes a planned cost
 */
export const deletePlannedCost = (id: number) => 
    axiosInstance.delete(
        `/api/plannedCosts/${id}`
    ).catch(handleError);
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PersonPinCircle } from '@material-ui/icons'; 

import { handleErrorResponse } from '../../../../../service/utils';
import { getSummaryEmployeeBySimulator } from '../../../../../service/Simulator/simulator';
import { DEFAULT_SUMMARY_DETAILS, handleSummaryDetailString } from '../../../Utils';
import { Store } from '../../../../../modules/rootReducer';
import { SimulatorState } from '../../../../../modules/simulatorReducer';

import SummaryCard from './SummaryCard';


interface Props {
    simulatorId: number;
}
const TotalEmployeesCard: React.FC<Props> = props => {
    const { simulatorId } = props;

    const dispatch = useDispatch();

    const { updated } = useSelector<Store, SimulatorState>(
        store => store.simulatorReducer
    );

    const [value, setValue] = React.useState<number>(0);
    const [details, setDetails] = React.useState<string>(DEFAULT_SUMMARY_DETAILS);

    React.useEffect(() => {
        getSummaryEmployeeBySimulator(simulatorId)
            .then(res => {
                setValue(res.data.countValue);
                setDetails(handleSummaryDetailString(res.data));
            })
            .catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve Employee count: '
                })
            });
    }, [simulatorId, updated]);

    return (
        <SummaryCard
            title="Total Employees"
            value={value}
            details={details}
            icon={<PersonPinCircle />}
        />
    );
}

export default TotalEmployeesCard;
import * as React from 'react';
import { Card, Grid } from '@material-ui/core';

import { NumberFormatter } from '../../../../../common/Utils/NumberFormatter';


interface Props {
    title?: string;
    value?: number | string;
    currency?: boolean;
    details?: string;
    icon?: React.ReactNode;
}
const SummaryCard: React.FC<Props> = props => {
    const { title, value, currency, details, icon } = props;

    return (
        <Card
            className="simulator-summary-card"
            data-currency={currency}
            data-negative={!!value && (value < 0)}
        >
            <Grid container>
                <Grid item xs={9}>
                    <span>{title}</span><br />
                    {currency 
                        ? <NumberFormatter currency value={value} />
                        : <span>{value}</span>
                    }<br />
                    <span>{details}</span>
                </Grid>
                <Grid item xs={3}>
                    {icon}
                </Grid>
            </Grid>
        </Card>
    );
}

export default SummaryCard;
import axiosInstance from '../Access/axiosInstance';
import { handleError } from '../utils';

/**
 * Upload BulkEntry Schedule of Events
 */
export const uploadSchedule = (contractId: number, formData: FormData, append: boolean) =>
    axiosInstance.post(
        `/api/scheduledVisits/upload/${contractId}?append=${append}`,
        formData
    ).catch(handleError);

/**
 * Download Entry Template
 */
export const downloadTemplate = () =>
    axiosInstance.get(
        '/api/scheduledVisits/downloadTemplate',
        { responseType: 'blob' }
    ).catch(handleError);
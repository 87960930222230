import * as React from 'react';

import { Button, Card, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import { StudyOverviewData } from '../../../../@types';
import { NumberFormatter } from '../../../../common/Utils/NumberFormatter';
import { displayDate } from '../../../../common/Utils/utils';

import useLoggedInUserPermissions from '../../../../common/hooks/useLoggedInUserPermissions';

export const studyStatusAndStyle = (study: any) => {
    if (!study.started)
        return ['Not Started', 'red-chip'];
    else if (study.started && !study.completed)
        return ['Started', 'green-chip'];
    else
        return ['Completed', 'blue-chip'];
}

export const contractStatusAndStyle = (contract: any) => {
    if (contract.finalized || (contract.created && contract.signed))
        return ['Finalized', 'green-chip'];
    else
        return ['Pending', 'grey-chip'];
}

interface Props {
    studyData: StudyOverviewData,
    setEditField: any,
}

const StudyOverviewDetails: React.FC<Props> = props => {
    const { studyData, setEditField } = props;

    const tooltipText = 'The following displays several important dates & financial values for the study.';
    const unrealizedIncomeTooltip = 'Total amount that the study is expected to receive from the remaining follow-up scheduled visits.';

    const permissionStudyUpdate: boolean = !!(studyData.division.id && useLoggedInUserPermissions('API_STUDY_OVERVIEW_UPDATE_STARTED_AND_COMPLETED', studyData.division.id, studyData.id));

    const renderDateField = (date: Date | null, isStarted: boolean, disabled: boolean) => {
        const fieldName = isStarted ? 'started' : 'completed';
        if (date === null) {
            if (permissionStudyUpdate) {
                return (
                    <Button
                        id="edit-info"
                        onClick={() => setEditField(fieldName)}
                        variant="contained"
                        color="primary"
                        style={{ width: 130, fontSize: 12, padding: 1 }}
                        disabled={disabled}
                    >
                        {isStarted ? 'Start' : 'Mark as Complete'}
                    </Button>
                );
            }
            else
                return (isStarted ? 'Not Started' : 'Not Completed');
        }
        else {
            if (permissionStudyUpdate) {
                return (
                    <a id='clickable-text' className="clickable-text" onClick={() => setEditField(fieldName)}>
                        {displayDate(date)}
                    </a>
                );
            }
            else
                return (<>{displayDate(date)}</>);
        }
    }

    return (
        <Grid item xs={12} md={8} container>
            <div className='section-heading'>
                <div className='text'>Details</div>
                <Tooltip arrow title={tooltipText}><InfoIcon className='info' /></Tooltip>
            </div>
            <div className='overview-card-div'>
                <Card className='study-details detail-row-text-details'>
                    {studyData &&
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Started:</TableCell>
                                                <TableCell>{renderDateField(studyData.started, true, false)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Completed:</TableCell>
                                                <TableCell>{renderDateField(studyData.completed, false, studyData.started === null)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Last Update:</TableCell>
                                                <TableCell>{displayDate(studyData.lastUpdated)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell># of Patients:</TableCell>
                                                <TableCell>{studyData.totalPatients}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Expected Value:</TableCell>
                                                <TableCell><NumberFormatter currency value={studyData.expectedValuePerPatient} /> / patient</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Last Visit Date:</TableCell>
                                                <TableCell>{displayDate(studyData.lastPatientVisitDate) || 'No Visits'}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Overhead:</TableCell>
                                                <TableCell>{studyData.contract.overhead}%</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Total Overhead:</TableCell>
                                                <TableCell><NumberFormatter currency value={studyData.overheadTotal} /></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Holdback:</TableCell>
                                                <TableCell>{studyData.contract.holdback}%</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Total Holdback:</TableCell>
                                                <TableCell><NumberFormatter currency value={studyData.holdbackTotal} /></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Total Revenue:</TableCell>
                                                <TableCell><NumberFormatter currency value={studyData.totalRevenue} /></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Total Expense:</TableCell>
                                                <TableCell><NumberFormatter currency value={studyData.totalCost} /></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Tooltip arrow title={unrealizedIncomeTooltip}>
                                                        <div className='cursor-pointer'>Estimated Unrealized Income:</div>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell><NumberFormatter currency value={studyData.unrealizedIncome} /></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    }
                    <div className={`study-status ${studyStatusAndStyle(studyData)[1]}`}>
                        {studyStatusAndStyle(studyData)[0]}
                    </div>
                </Card>
            </div>
        </Grid>
    );
}

export default StudyOverviewDetails;

import * as React from 'react';

import { Box, Card, Grid, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { Simulator, SimulatorUserWithSalaryLogs } from '../../../../../@types';
import AddEmployeeDetailedModal from './AddEmployeeDetailedModal';
import EmployeeDetailedCard from './EmployeeDetailedCard';
import EmployeeEffort from './EmployeeEffort';

interface Props {
    simulator: Simulator;
    employees: SimulatorUserWithSalaryLogs[];
    canView: boolean;
    setUpdated: () => void;
}

const EmployeeDetail: React.FC<Props> = (props) => {
    const { simulator, employees, canView, setUpdated } = props;

    const [addEmployeeOpen, setAddEmployeeOpen] = React.useState<boolean>(false);

    return (<>
        {canView ?
            <Grid container spacing={3} className="detailed-employees-expanded">
                {employees.map((simUser: SimulatorUserWithSalaryLogs, idx: number) =>
                    <Grid item xs={6} md={12} lg={6} xl={4} key={'detailed-employee-' + idx}>
                        <EmployeeDetailedCard simUser={simUser} setUpdated={setUpdated} />
                    </Grid>
                )}
                <Grid item xs={6} md={12} lg={6} xl={4} className='flex-center'>
                    <Card className="add-employee" onClick={() => setAddEmployeeOpen(true)}>
                        <Add />
                        <span>Add Employee</span>
                    </Card>
                </Grid>
                <Grid item xs={12} className='flex-center'>
                    <EmployeeEffort simulator={simulator} employees={employees} />
                </Grid>
            </Grid> :
            <Box textAlign="center" className="centered page-body">
                <Typography gutterBottom variant="h3">
                    Restricted
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                    You do not have permission to access this section.
                </Typography>
            </Box>
        }
        <AddEmployeeDetailedModal
            open={addEmployeeOpen}
            setOpen={setAddEmployeeOpen}
            simulatorId={Number(simulator.id)}
            setUpdated={setUpdated}
        />
    </>)
}
export default EmployeeDetail;
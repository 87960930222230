import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, Tooltip } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { ContractListDetail, DivisionTree } from '../../../@types';
import { getDivisionTreeIds } from '../../../common/Utils/utils';
import { DisplayTextFormat } from '../../../common/Utils/DisplayTextFormat';
import { handleErrorResponse } from '../../../service/utils';
import { getRestrictedContracts } from '../../../service/Contract/contracts';
import { contractStatusAndStyle } from '../../Study/Details/Overview/StudyOverviewDetails';
import usePermissions from '../../../common/hooks/usePermissions';

import SearchBar from '../../../common/SearchBar/SearchBar';
import FilterSortButton from '../../../common/FilterSortButton/FilterSortButton';
import EditablePagingTable, { TableColumn } from '../../../common/DataTable/EditablePagingTable';
import ContractAdd from './ContractAdd';
import FilterByDivision from '../../Invoicing/Invoice/FilterByDivision';
import Loading from '../../../common/Routes/Loading';


interface TableData {
    id: number | null;
    contract: JSX.Element;
    status: JSX.Element;
    created: Date | undefined;
    signed: Date | undefined;
    finalContractCost: number;
    actualCost: number;
    sponsorProposal: number;
    negotiatingCost: number;
}

const columns: TableColumn[] = [{
    type: 'contract',
    displayValue: 'Contract',
    align: 'left',
    format: 'jsx-element',
    style: { minWidth: 250 },
}, {
    type: 'status',
    displayValue: 'Status',
    align: 'center',
    format: 'jsx-element',
}, {
    type: 'created',
    displayValue: 'Created',
    align: 'center',
    format: 'date',
}, {
    type: 'signed',
    displayValue: 'Signed',
    align: 'center',
    format: 'date',
}, {
    type: 'finalContractCost',
    displayValue: 'Final Cost',
    align: 'right',
    format: 'currency-US',
}, {
    type: 'actualCost',
    displayValue: 'Actual Cost',
    align: 'right',
    format: 'currency-US',
}, {
    type: 'sponsorProposal',
    displayValue: 'Sponsor Proposal',
    align: 'right',
    format: 'currency-US',
}, {
    type: 'negotiatingCost',
    displayValue: 'Negotiating Cost',
    align: 'right',
    format: 'currency-US',
}];

interface State {
    objects: any[];
    filters: {
        pending: boolean;
        finalized: boolean;
    }
    division?: number[];
}

const initialState: State = {
    objects: [],
    filters: {
        pending: true,
        finalized: true,
    },
}

interface Action {
    type: 'objectPage' | 'filters' | 'division';
    payload?: any;
}

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'objectPage':
            return {
                ...state,
                objects: action.payload.list,
            } as State;
        case 'filters':
            return {
                ...state,
                filters: action.payload,
            } as State;
        case 'division':
            return {
                ...state,
                division: action.payload,
            } as State;
        default:
            return state;
    }
}

const ContractList: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [contractList, setContractList] = React.useState<ContractListDetail[]>([]);
    const [query, setQuery] = React.useState<string>('');
    const [state, dispatchPage] = React.useReducer(reducer, initialState);
    const { objects, filters, division } = state;
    const [doneLoading, setDoneLoading] = React.useState<boolean>(false);

    const permissionContractCreate: boolean = usePermissions('API_CONTRACT_CREATE');

    React.useEffect(() => {
        getRestrictedContracts()
            .then(res => {
                setContractList(res.data);
                dispatchPage({ type: 'objectPage', payload: { list: res.data } });
                setDoneLoading(true);
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve list of Contracts: '
                });
            });
    }, []);

    React.useEffect(() => {
        if (contractList.length > 0) {
            let filteredList: ContractListDetail[] = contractList;
            if (division && division.length != 0) {
                filteredList = filteredList.filter(contract =>  {
                    return division.includes(contract.divisionId);
                });
            }
            filteredList = filteredList.filter(contract =>
                (filters.pending && !contract.signed) ||
                (filters.finalized && contract.signed)
            );
            if (Number(query) !== 0) {
                filteredList = filteredList.filter(contract =>
                    contract.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                    contract.identifier.toLowerCase().indexOf(query.toLowerCase()) !== -1
                );
            }
            dispatchPage({
                type: 'objectPage',
                payload: { list: filteredList }
            });
        }
    }, [contractList, filters, query, division]);

    const handleChangeFilters = (pending: boolean, finalized: boolean) => {
        dispatchPage({ type: 'filters', payload: { pending: pending, finalized: finalized } });
    };
    const handleChangeDivision = (div: DivisionTree): void => {
        dispatchPage({ type: 'division', payload: getDivisionTreeIds(div) });
    };

    // Manage the simple React state for opening/closing the add contract modal
    const [addContractOpen, setAddContractOpen] = React.useState(false);
    const handleAddContractOpen = () => {
        setAddContractOpen(true);
    };

    const moreInfoHover = (obj: ContractListDetail) => {
        return (
            <div className="column tip-padding">
                <span> Identifier: {DisplayTextFormat(obj.identifier, 12, false)} </span>
                <span> IRB #: {obj.irbNo} </span>
                <span> Study #: {obj.studyNo} </span>
                <span> Division: {obj.divisionName} </span>
                <span> Status: {contractStatusAndStyle(obj)[0]} </span>
            </div>
        )
    }

    const convertTableData = (data: ContractListDetail[]) => {
        const newData: TableData[] = [];
        data.forEach(obj => {
            const identifiers = (
                <Tooltip arrow title={moreInfoHover(obj)}>
                    <div className='double-line-text'>
                        <div className='title sort-field'>
                            {obj.name} ({obj.identifier})
                        </div>
                        <div className='subtitle'>
                            {obj.divisionName}
                        </div>
                    </div>
                </Tooltip>
            )
            const statusChip = (
                <div className={contractStatusAndStyle(obj)[1] + ' sort-field'}>
                    {contractStatusAndStyle(obj)[0]}
                </div>
            )
            newData.push({
                id: obj.id,
                contract: identifiers,
                status: statusChip,
                created: obj.created,
                signed: obj.signed,
                finalContractCost: obj.finalContractCost,
                actualCost: obj.actualCost,
                sponsorProposal: obj.sponsorProposal,
                negotiatingCost: obj.negotiatingCost,
            })
        })
        return newData;
    }

    const handleRowClick = (id: number) => {
        history.push(`/contract/${id}/overview`)
    }

    if (doneLoading) {
        return (
            <div className="page">
                <div className="page-content">
                    <div className="page-heading aligned-row">
                        <div className="left">
                            <SearchBar query={query} setQuery={setQuery} />
                            <FilterSortButton
                                sections={[{
                                    title: 'Filter By Status',
                                    tooltip: 'Filter contract list by status. Contracts with a pending status have not been signed.',
                                    body: (
                                        <FormControl>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<Checkbox checked={filters.pending} onChange={() => handleChangeFilters(!filters.pending, filters.finalized)} name="pending" />}
                                                    label="Pending"
                                                />
                                                <FormControlLabel
                                                    control={<Checkbox checked={filters.finalized} onChange={() => handleChangeFilters(filters.pending, !filters.finalized)} name="finalized" />}
                                                    label="Finalized"
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    )
                                }, {
                                    title: 'Filter By Division',
                                    tooltip: 'Filter contracts by division or sub-division. Select upper "All Divisions" option to show all contracts without division filter.',
                                    body: (
                                        <FilterByDivision
                                            setChosenDivision={handleChangeDivision}
                                            withinCollapsiblePanel={false}
                                            restricted={true}
                                            withinMenu
                                        />
                                    )
                                }]}
                            />
                        </div>
                        <div className="right">
                            {permissionContractCreate && <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Add />}
                                onClick={() => handleAddContractOpen()}
                            >
                                New Contract
                            </Button>}
                        </div>
                    </div>
                    <div className="page-body max-xl no-ma">
                        <EditablePagingTable
                            dataList={convertTableData(objects)}
                            tableInfoColumns={columns}
                            initialSortType='contract'
                            readonly={true}
                            canCreate={false}
                            mode='full'
                            rowClick={handleRowClick}
                            height='76vh'
                            borderedCells={false}
                        />
                    </div>
                    <ContractAdd open={addContractOpen} setOpen={setAddContractOpen} />
                </div>
            </div>
        )
    } else {
        return (
            <Loading />
        )
    }
}

export default ContractList;
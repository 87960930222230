import axiosInstance from '../Access/axiosInstance';
import { Account, AccountAndContacts, AccountPage, AccountPageRequest, AccountPaymentWithInfo, AccountSummary } from '../../@types';
import { handleError } from '../utils';

/**
 * Gets a page of accounts
 */
export const getAccountPage = (pageReq: AccountPageRequest) =>
    axiosInstance.get<AccountPage>(
        '/api/accounts', {
            params: pageReq
        }
    ).catch(handleError);

/**
 * Gets an account by id
 */
export const getAccount = (id: number) =>
    axiosInstance.get<Account>(
        `/api/accounts/${id}`
    ).catch(handleError);

/**
 * Creates an account and associated contacts.
 * Associates the account to a study if applicable
 */
export const createAccountAndAssociateContacts = (accountAndContacts: AccountAndContacts, studyId?: number) =>
    axiosInstance.post<AccountAndContacts>(
        studyId ? `/api/accounts/?studyId=${studyId}` : `/api/accounts`, accountAndContacts
    ).catch(handleError);

/**
 * Updates an account
 */
export const updateAccount = (account: Account) =>
    axiosInstance.put<Account>(
        '/api/accounts', account
    ).catch(handleError);

/**
 * Deletes an account
 */
export const deleteAccount = (id: number) => 
    axiosInstance.delete(
        `/api/accounts/${id}`
    ).catch(handleError);

/**
 * Gets the account summaries
 */
export const getAccountSummaries = () =>
    axiosInstance.get<AccountSummary[]>(
        '/api/accounts/summaries'
    ).catch(handleError);

/**
 * Gets a list of AccountPaymentWithInfo assigned accountId Account
 */
export const getAccountPaymentsWithInfo = (accountId: number) =>
    axiosInstance.get<AccountPaymentWithInfo[]>(
        `/api/accounts/payments/${accountId}`
    ).catch(handleError);
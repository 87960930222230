import * as React from 'react';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { Card, CardActions, CardContent, CardHeader, Chip, IconButton, InputAdornment, Modal } from '@material-ui/core';
import { Check, Close, Save } from '@material-ui/icons';
import { BDatePicker, BEmail, BForm, BGrid, BSelect, BSubmit, BTextField } from 'mui-bueno';

import { SelectOption, User } from '../../../@types';
import { createUser } from '../../../service/Management/users';
import { handleEditErrors, handleErrorResponse } from '../../../service/utils';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../modules/messageSnackbarReducer';
import { AxiosError } from 'axios';
import { getRestrictedDivisions } from '../../../service/Management/divisions';
import { NumberFormatter } from '../../../common/Utils/NumberFormatter';
import useLoggedInUserPermissions from '../../../common/hooks/useLoggedInUserPermissions';

interface CreateUserForm extends Record<string, any> {
    firstName: string;
    middleName?: string;
    lastName: string;
    email: string;
    divisionIds?: number[];
    salary: number;
    fringe: number;
    hourlyRate: number;
    effectiveDate: Date;
}

const UserAdd: React.FC<{ open: any, setOpen: any }> = props => {
    const { open, setOpen } = props;

    const history = useHistory();
    const dispatch = useDispatch();

    const permissionCreateUserAdmin = useLoggedInUserPermissions('API_USER_CREATE', -1, -1);

    const createUserSchema = yup.object<CreateUserForm>().shape({
        firstName: yup.string().required('First Name is required'),
        middleName: yup.string(),
        lastName: yup.string().required('Last Name is required'),
        email: yup.string().email('Must be in email format').required('Email is required'),
        divisionIds: permissionCreateUserAdmin ? yup.array() : yup.array().min(1, 'At least one Division must be selected'),
        effectiveDate: yup.date().required('Effective Date is required'),
        salary: yup.number().required('Salary is required'),
        fringe: yup.number().required('Fringe Benefits is required')
    });

    const [user, setUser] = React.useState<User>({
        id: undefined,
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        divisionIds: [],
        active: true
    });
    const [salaryLog] = React.useState<any>({
        id: undefined,
        salary: '0',
        fringe: '0',
        hourlyRate: undefined,
        effectiveDate: null,
        userId: undefined
    });
    const [divisionOptions, setDivisionOptions] = React.useState<SelectOption<number>[]>([]);

    React.useEffect(() => {
        if (open)
            getRestrictedDivisions()
                .then(res => {
                    const temp: SelectOption<number>[] = [];
                    res.data.forEach(division => {
                        temp.push({
                            value: +division.id!,
                            label: division.name
                        })
                    });
                    setDivisionOptions(temp);
                })
                .catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not retrieve list of Divisions: '
                    });
                });
    }, [open]);

    const handleSubmitUser = async (data: any, { setErrors }: FormikHelpers<User>) => {
        setErrors({});
        createUser({
            user: {
                id: undefined,
                firstName: data.firstName,
                middleName: data.middleName,
                lastName: data.lastName,
                email: data.email,
                divisionIds: data.divisionIds,
                active: true
            },
            salaryLog: {
                id: undefined,
                salary: data.salary,
                fringe: data.fringe,
                hourlyRate: +(data.salary! / 2080).toFixed(4),
                effectiveDate: data.effectiveDate,
                userId: undefined
            }
        }).then(res => {
            dispatch(showSuccessSnackbar(`${res.data.firstName} ${res.data.lastName} created`));
            history.push(`/manage/user/${res.data.id}/details`);
        }).catch((err: AxiosError) => {
            if (err.response) {
                if (err.response.status === 401) {
                    dispatch(showErrorSnackbar('Invalid JWT Token'));
                } else if (err.response.status === 422) {
                    handleEditErrors(err, setErrors);
                } else if (err.response.status === 400) {
                    const index = err.response.data.message.indexOf('(');
                    const message = err.response.data.message.substring(0, index - 1);
                    if (err.response.data.message === 'Too many requests. Try again later.') {
                        dispatch(showErrorSnackbar(err.response.data.message));
                    } else if (message === 'An account with the given email already exists.') {
                        // Indicates cognito and our service are out of sync
                        // Indicates cognito has the email but our service doesn't
                        dispatch(showErrorSnackbar(message));
                    } else {
                        dispatch(showErrorSnackbar('Unexpected error. Try again later.'));
                    }
                }
            }
        });
    };

    const handleNewUserModalClose = () => {
        setOpen(false);
        setUser({
            id: undefined,
            firstName: '',
            middleName: '',
            lastName: '',
            email: '',
            divisionIds: [],
            active: true
        });
    };

    return (
        <Modal
            open={open}
            onClose={handleNewUserModalClose}
        >
            <div className="modal-form">
                <Formik
                    initialValues={{ ...user, ...salaryLog }}
                    onSubmit={handleSubmitUser}
                    validationSchema={createUserSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    <BForm>
                        <Card className="detail-form sm">
                            <CardHeader
                                title="Add User"
                                action={
                                    <IconButton color="primary" aria-label="Close" onClick={handleNewUserModalClose}>
                                        <Close />
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                <BGrid container>
                                    <BGrid xs={12} sm={4}>
                                        <BTextField
                                            name="firstName"
                                            label="First Name"
                                            placeholder="First Name"
                                            noGrid
                                            required autoFocus
                                        />
                                    </BGrid>
                                    <BGrid xs={12} sm={4}>
                                        <BTextField
                                            name="middleName"
                                            label="Middle Name"
                                            placeholder="Middle Name"
                                            noGrid
                                        />
                                    </BGrid>
                                    <BGrid xs={12} sm={4}>
                                        <BTextField
                                            name="lastName"
                                            label="Last Name"
                                            placeholder="Last Name"
                                            noGrid
                                            required
                                        />
                                    </BGrid>
                                </BGrid>
                                <BEmail
                                    name="email"
                                    label="Email"
                                    placeholder="Email"
                                    gridClassName="user-add-input-grid"
                                    required
                                />
                                <BSelect
                                    multiple
                                    name="divisionIds"
                                    label="Divisions"
                                    options={divisionOptions}
                                    selectedOptionIcon={<Check />}
                                    renderValue={(selected: unknown) => {
                                        return (selected as number[]).map(value => {
                                            const option = divisionOptions[value];
                                            return (
                                                option && <Chip key={`division-chip-${option.value}`} label={option.label} className="chip" size={'small'} />
                                            );
                                        });
                                    }}
                                    required={!permissionCreateUserAdmin}
                                />
                                <BGrid container>
                                    <BGrid xs={12} sm={4}>
                                        <BTextField
                                            name="salary"
                                            label="Salary"
                                            placeholder="e.g. 55,000.00"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                inputComponent: NumberFormatter as any
                                            }}
                                            noGrid
                                            required
                                        />
                                    </BGrid>
                                    <BGrid xs={12} sm={4}>
                                        <BTextField
                                            name="fringe"
                                            label="Fringe Benefits"
                                            placeholder="e.g. 5.00"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                inputComponent: NumberFormatter as any
                                            }}
                                            noGrid
                                            required
                                        />
                                    </BGrid>
                                    <BGrid xs={12} sm={4}>
                                        <BDatePicker
                                            name="effectiveDate"
                                            label="Effective Date"
                                            placeholder="mm/dd/yyyy"
                                            inputVariant="outlined"
                                            noGrid
                                            required
                                        />
                                    </BGrid>
                                </BGrid>
                            </CardContent>
                            <CardActions className="flex-end">
                                <BSubmit
                                    startIcon={<Save />}
                                    variant="contained"
                                    color="primary"
                                >
                                    Create
                                </BSubmit>
                            </CardActions>
                        </Card>
                    </BForm>
                </Formik>
            </div>
        </Modal>
    );
}

export default UserAdd;
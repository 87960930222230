import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import { Button, Card, CardContent, Grid, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { Add, CreateNewFolder, Info, NoteAdd } from '@material-ui/icons';

import { Directory, Document, DocumentTree } from '../../../../@types';
import { showErrorSnackbar } from '../../../../modules/messageSnackbarReducer';
import useLoggedInUserPermissions from '../../../../common/hooks/useLoggedInUserPermissions';
import { getStudy } from '../../../../service/Study/study';
import { handleErrorResponse } from '../../../../service/utils';
import { getDirectory } from '../../../../service/Study/directories';
import { downloadDocument, downloadLatestDocument, getDocument, getDocumentTreeByStudy } from '../../../../service/Study/documents';

import DirectoryList from './DirectoryList';
import UploadModal from './Modal/UploadModal';
import AddDirectoryModal from './Modal/AddDirectoryModal';
import DocumentPreview from './DocumentPreview';
import DirectoryPreview from './DirectoryPreview';

interface Props {
    id: string;
}

interface RouteParams {
    subSection: string; // Either 'directory' or 'document'
    subId?: string; // Either the id of the directory (null | string) or document
}

const Documents: React.FC<Props> = (props) => {
    const { id } = props;
    const { subSection: type, subId: fileId } = useParams<RouteParams>();

    const history = useHistory();
    const dispatcher = useDispatch();

    const [studyName, setStudyName] = React.useState<string>('');
    const [divisionId, setDivisionId] = React.useState<number>(0);
    const [started, setStarted] = React.useState<boolean>(false);
    const [completed, setCompleted] = React.useState<boolean>(false);

    const [selectedDocument, setSelectedDocument] = React.useState<Document | null>(null);
    const [parentDirectories, setParentDirectories] = React.useState<Directory[]>([]);
    const [selectedDirectory, setSelectedDirectory] = React.useState<Directory | null | undefined>(null);
    // Selected Directory can be a directory, root directory (id: 0), invalid directory (undefined/not found), or no rendered yet (null)

    const [uploadOpen, setUploadOpen] = React.useState<boolean>(false);
    const [addDirectoryOpen, setAddDirectoryOpen] = React.useState<boolean>(false);

    const [updated, setUpdated] = React.useState<boolean>(false);

    // State management to determine whether to display buttons, display documents, and allow upload and delete capability based on user's permissions and role restriction
    const canUploadNewDocument = useLoggedInUserPermissions('API_DOCUMENT_UPLOAD_NEW_DOCUMENT', divisionId, Number(id));
    const canCreateNewDir = useLoggedInUserPermissions('API_DIRECTORY_CREATE', divisionId, Number(id));
    const canGetDocuments = useLoggedInUserPermissions('API_DOCUMENT_GET_DOCUMENTS_BY_STUDY', divisionId, Number(id));

    // Text for tooltips
    const notStartedUpload = 'Cannot upload a document or directory before the study has been started';
    const completedUpload = 'Cannot upload a document or directory after the study has been completed';

    const [addMenuAnchor, setAddMenuAnchor] = React.useState<null | HTMLElement>(null);

    const [fileStructure, setFileStructure] = React.useState<DocumentTree[]>([]);
    const loadFileStructure = () => {
        getDocumentTreeByStudy(Number(id))
            .then(res => {
                setFileStructure(_.orderBy(res.data, ['name']));
            })
            .catch(err => {
                handleErrorResponse(err, dispatcher, {
                    prefix: 'Could not retrieve directories and documents: '
                });
            });
    }

    React.useEffect(() => {
        loadFileStructure();
        getStudy(Number(id)).then(res => {
            setStudyName(res.data.name);
            setDivisionId(res.data.divisionId);
            res.data.started ? setStarted(true) : setStarted(false);
            res.data.completed ? setCompleted(true) : setCompleted(false);
        }).catch(err => {
            dispatcher(showErrorSnackbar('Could not find Study'));
            history.push('/study');
        });
    }, []);

    React.useEffect(() => {
        if (updated) {
            loadFileStructure();
        }
        setUpdated(false);
    }, [updated]);

    React.useEffect(() => {
        if (!type) {
            history.push(`/study/${id}/documents/directory`)
        }
        setParentDirectories([]);
        setSelectedDocument(null);
        setSelectedDirectory(null);
        if (type === 'directory' && !fileId) {
            // This state is used for the all directories view
            setSelectedDirectory({
                id: 0,
                parentDirectoryId: 0,
                name: '',
                studyId: 0,
                allowedFileDeleted: true,
            });
        }
        if (type === 'directory' && fileId) {
            getDirectory(Number(fileId)).then(res => {
                setSelectedDirectory(res.data);
            }).catch(err => {
                setSelectedDirectory(undefined);
            })
        }
        if (type === 'document' && fileId) {
            getDocument(Number(fileId)).then(res => {
                setSelectedDocument(res.data);
            })
        }
    }, [type, fileId]);


    return (
        <>
            <div className="page-heading">
                <Typography className="title">{studyName}</Typography>
            </div>
            <div className="page-body max-xl">
                <div className='row'>
                    <Typography className="page-title">Documents</Typography>
                    <Tooltip
                        arrow
                        placement='right-start'
                        title={
                            <div style={{ whiteSpace: 'pre-line' }}>
                                Please use <b>Upload New Version</b> to update an Informed Consent Form
                            </div>
                        }
                    >
                        <Info className='info' />
                    </Tooltip>
                </div>
                <Grid container>
                    <Grid item sm={12} md={5} lg={3}>
                        <Card className="split-cards directory">
                            <div className='card-header'>
                                <Typography
                                    className='cursor-pointer'
                                    onClick={() => history.push(`/study/${id}/documents/directory`)}
                                >
                                    All Directories
                                </Typography>
                                {(canUploadNewDocument && canCreateNewDir) &&
                                    <Tooltip title={!started ? notStartedUpload : completed ? completedUpload : ''}>
                                        <div>
                                            <Button
                                                color='inherit'
                                                variant='outlined'
                                                className={(!started || completed) ? 'small-grey-button-disabled' : 'small-grey-button'}
                                                onClick={event => setAddMenuAnchor(event.currentTarget)}
                                                disabled={!started || completed}
                                            >
                                                <Add className="icon small" />
                                                Add
                                            </Button>
                                        </div>
                                    </Tooltip>
                                }
                            </div>
                            <CardContent className='documents-height list'>
                                {canGetDocuments &&
                                    <DirectoryList
                                        id={id}
                                        type={type}
                                        fileId={fileId}
                                        documentTree={fileStructure}
                                    />
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item sm={12} md={7} lg={9}>
                        {(type === 'directory' || !type) &&
                            <DirectoryPreview
                                studyId={Number(id)}
                                divisionId={divisionId}
                                selectedDirectory={selectedDirectory}
                                setSelectedDirectory={setSelectedDirectory}
                                studyStarted={started}
                                studyCompleted={completed}
                                notStartedUploadText={notStartedUpload}
                                completedUploadText={completedUpload}
                                setUpdated={setUpdated}
                                updated={updated}
                                canUploadNewDoc={canUploadNewDocument}
                                canCreateNewDir={canCreateNewDir}
                                directories={fileStructure}
                                parentDirectories={parentDirectories}
                                setParentDirectories={setParentDirectories}
                            />
                        }
                        {(type === 'document') &&
                            <DocumentPreview
                                studyId={Number(id)}
                                divisionId={divisionId}
                                selectedDocument={selectedDocument}
                                setSelectedDocument={setSelectedDocument}
                                studyStarted={started}
                                studyCompleted={completed}
                                notStartedUploadText={notStartedUpload}
                                completedUploadText={completedUpload}
                                updated={setUpdated}
                                parentDirectories={parentDirectories}
                                setParentDirectories={setParentDirectories}
                            />
                        }

                    </Grid>
                </Grid>
            </div>
            <Menu
                keepMounted
                getContentAnchorEl={null}
                anchorEl={addMenuAnchor}
                open={Boolean(addMenuAnchor)}
                onClose={() => setAddMenuAnchor(null)}
                className='new-directory-item'
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                {canUploadNewDocument &&
                    <MenuItem onClick={() => { setUploadOpen(true); setAddMenuAnchor(null); }}>
                        <NoteAdd />
                        New Document
                    </MenuItem>
                }
                {canCreateNewDir &&
                    <MenuItem onClick={() => { setAddDirectoryOpen(true); setAddMenuAnchor(null); }}>
                        <CreateNewFolder />
                        New Directory
                    </MenuItem>
                }
            </Menu>
            <UploadModal
                open={uploadOpen}
                setOpen={setUploadOpen}
                setUpdated={setUpdated}
                studyId={Number(id)}
                selectedDocument={null}
                setSelectedDocument={setSelectedDocument}
            />
            <AddDirectoryModal
                open={addDirectoryOpen}
                setOpen={setAddDirectoryOpen}
                setUpdated={setUpdated}
                studyId={Number(id)}
                parentDirectorySet={null}
            />
        </>
    )
}

export const handleDownloadDocument = (docId: number, versionId: string | null, dispatcher: any, filename: string, event: any) => {
    if (versionId) {
        downloadDocument(docId, versionId)
            .then(res => {
                const blob = new Blob([res.data]);
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.setAttribute('download', filename);
                link.click();
                URL.revokeObjectURL(link.href);
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    prefix: 'Could not retrieve Document: '
                })
            });
    } else {
        downloadLatestDocument(docId)
            .then(res => {
                const blob = new Blob([res.data]);
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.setAttribute('download', filename);
                link.click();
                URL.revokeObjectURL(link.href);
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    prefix: 'Could not retrieve Document: '
                })
            });
    }
    event.stopPropagation();
}

export default Documents;

import * as React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, ListItem, TextField, Tooltip } from '@material-ui/core';
import { InfoRounded } from '@material-ui/icons';

import { NumberFormatter } from '../../../common/Utils/NumberFormatter';

import CollapsibleFilterPanel from '../../../common/CollapsibleFilterPanel/CollapsibleFilterPanel';
import FilterByDivision from './FilterByDivision';
import usePermissions from '../../../common/hooks/usePermissions';


interface Props {
    statusFilters: any;
    handleChangeStatusFilters: any;
    columnFilters: any;
    handleChangeColumnFilters: any;
    dateRange: any;
    handleChangeDateRange: any;
    handleChangeSelectedDivision: any;
}

const InvoiceListBrowsingPanel: React.FC<Props> = props => {
    const {
        statusFilters,
        handleChangeStatusFilters,
        columnFilters,
        handleChangeColumnFilters,
        dateRange,
        handleChangeDateRange,
        handleChangeSelectedDivision,
    } = props;

    const canSelectAllDivisions = usePermissions('API_INVOICE_GET_AMOUNT_NOT_INVOICED_ON_STUDIES');

    const browseOptions = (
        <div className="browsing-options scrollable-sidebar">
            <FilterByDivision
                setChosenDivision={handleChangeSelectedDivision}
                withinCollapsiblePanel
                restricted={!canSelectAllDivisions}
            />
            <ListItem className="section-title">
                Table Filter Options
            </ListItem>
            <div className="spacer" />
            <ListItem>
                <FormControl style={{ width: '100%' }}>
                    <FormLabel className="sub-section-title">
                        Time Frame
                        <Tooltip title="Select Invoices by Created Date within a specified date range">
                            <InfoRounded />
                        </Tooltip>
                    </FormLabel>
                    <div className="aligned-row">
                        Past&nbsp;
                        <TextField
                            variant="outlined"
                            value={dateRange}
                            onBlur={handleChangeDateRange}
                            onKeyPress= {event => {
                                if (event.key === 'Enter') handleChangeDateRange(event);
                            }}
                            InputProps={{
                                inputComponent: NumberFormatter as any,
                                inputProps: { integer: true }
                            }}
                            style={{ padding: '0px 7px' }}
                        />
                        &nbsp;{`Month${dateRange > 1 ? 's' : ''}`}
                    </div>
                </FormControl>
            </ListItem>
            <ListItem>
                <FormControl>
                    <FormLabel className="sub-section-title">
                        Status
                        <Tooltip title="Filter Invoices by status">
                            <InfoRounded />
                        </Tooltip>
                    </FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={statusFilters.NOT_SENT} onChange={() => handleChangeStatusFilters(!statusFilters.NOT_SENT, statusFilters.NOT_PAID, statusFilters.PARTIAL, statusFilters.CURRENT, statusFilters.PAID)} name="statusFilter1" />}
                            label="Not Sent"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={statusFilters.NOT_PAID} onChange={() => handleChangeStatusFilters(statusFilters.NOT_SENT, !statusFilters.NOT_PAID, statusFilters.PARTIAL, statusFilters.CURRENT, statusFilters.PAID)} name="statusFilter2" />}
                            label="Not Paid"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={statusFilters.PARTIAL} onChange={() => handleChangeStatusFilters(statusFilters.NOT_SENT, statusFilters.NOT_PAID, !statusFilters.PARTIAL, statusFilters.CURRENT, statusFilters.PAID)} name="statusFilter3" />}
                            label="Partial"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={statusFilters.CURRENT} onChange={() => handleChangeStatusFilters(statusFilters.NOT_SENT, statusFilters.NOT_PAID, statusFilters.PARTIAL, !statusFilters.CURRENT, statusFilters.PAID)} name="statusFilter4" />}
                            label="Current"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={statusFilters.PAID} onChange={() => handleChangeStatusFilters(statusFilters.NOT_SENT, statusFilters.NOT_PAID, statusFilters.PARTIAL, statusFilters.CURRENT, !statusFilters.PAID)} name="statusFilter5" />}
                            label="Paid"
                        />
                    </FormGroup>
                </FormControl>
            </ListItem>
            <ListItem>
                <FormControl>
                    <FormLabel className="sub-section-title">
                        Show Columns
                        <Tooltip title="Unselected columns will not be displayed in the Invoice table">
                            <InfoRounded />
                        </Tooltip>
                    </FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={columnFilters.dateCreated} onChange={() => handleChangeColumnFilters(!columnFilters.dateCreated, columnFilters.dateSent, columnFilters.datePaidMinusHoldback, columnFilters.datePaid, columnFilters.checkNumbers)} name="columnFilter1" />}
                            label="Date Created"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={columnFilters.dateSent} onChange={() => handleChangeColumnFilters(columnFilters.dateCreated, !columnFilters.dateSent, columnFilters.datePaidMinusHoldback, columnFilters.datePaid, columnFilters.checkNumbers)} name="columnFilter2" />}
                            label="Date Sent"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={columnFilters.datePaidMinusHoldback} onChange={() => handleChangeColumnFilters(columnFilters.dateCreated, columnFilters.dateSent, !columnFilters.datePaidMinusHoldback, columnFilters.datePaid, columnFilters.checkNumbers)} name="columnFilter3" />}
                            label= {<Tooltip arrow title='Date paid minus holdback'><div>Date Paid to CURRENT</div></Tooltip>}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={columnFilters.datePaid} onChange={() => handleChangeColumnFilters(columnFilters.dateCreated, columnFilters.dateSent, columnFilters.datePaidMinusHoldback, !columnFilters.datePaid, columnFilters.checkNumbers)} name="columnFilter4" />}
                            label={<Tooltip arrow title='Date fully paid'><div>Date Paid</div></Tooltip>}
                        />
                    </FormGroup>
                </FormControl>
            </ListItem>
        </div>
    )

    return (
        <CollapsibleFilterPanel contents={browseOptions} />
    )
}

export default InvoiceListBrowsingPanel;
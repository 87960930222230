import * as React from 'react';
import * as yup from 'yup';
import { AxiosError } from 'axios';
import { Prompt } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Card, CardActions, CardContent, CardHeader, Grid } from '@material-ui/core';
import { HelpOutline, PersonOutline, Save, VpnKey } from '@material-ui/icons';
import { BButton, BEmail, BForm, BSubmit, BTextField, } from 'mui-bueno';
import { Formik, FormikHelpers } from 'formik';

import { User } from '../../../../@types';
import {
    activateUser, deactivateUser, hasUserResetTemporaryPassword,
    resendUserInvite, updateUser
} from '../../../../service/Management/users';
import { handleEditErrors, handleErrorResponse } from '../../../../service/utils';
import { showSuccessSnackbar } from '../../../../modules/messageSnackbarReducer';
import useLoggedInUserAccess from '../../../../common/hooks/useLoggedInUserAccess';
import useLoggedInUser from '../../../../common/hooks/useLoggedInUser';
import usePermissions from '../../../../common/hooks/usePermissions';


const userSchema = yup.object<User>().shape({
    firstName: yup.string().required('First name is required'),
    middleName: yup.string(),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email('Must be in email format').required('Email is required')
});

const GeneralInformation: React.FC<{ user: User, setUser: any }> = props => {
    const { user, setUser } = props;

    const dispatch = useDispatch();

    const [confirmDeactivateUser, setConfirmDeactivateUser] = React.useState<boolean>(false);
    const [confirmActivateUser, setConfirmActivateUser] = React.useState<boolean>(false);
    const [formChanged, setFormChanged] = React.useState<boolean>(false);
    const [userResetTemporaryPassword, setUserResetTemporaryPassword] = React.useState<boolean>(false);
    const [emailResent, setEmailResent] = React.useState<boolean>(false);
    
    const permissionActivateUser: boolean = useLoggedInUserAccess('API_USER_ACTIVATE', user, true);
    const permissionDeactivateUser: boolean = useLoggedInUserAccess('API_USER_DEACTIVATE', user, true);
    const permissionGetUserResetTemporaryPassword: boolean = usePermissions('API_USER_GET_USER_RESET_TEMPORARY_PASSWORD');
    const permissionResendUserInvite: boolean = usePermissions('API_USER_RESEND_USER_INVITE');
    
    const activateButtonType = user.active ? 'deactivateUser' : 'activateUser';
    const loggedInUser: User = useLoggedInUser();
    // can update only if updating one self or have CREATE permission to update others.
    // this is to prevent other division-based roles than div admin to update other users.
    const permissionCreateUser: boolean = useLoggedInUserAccess('API_USER_CREATE', user);
    const permissionUpdateOthers: boolean = loggedInUser.id === user.id || permissionCreateUser;
    const permissionUpdateUser: boolean = useLoggedInUserAccess('API_USER_UPDATE', user) && permissionUpdateOthers;

    React.useEffect(() => {
        if (permissionGetUserResetTemporaryPassword && user.id) {
            hasUserResetTemporaryPassword(user.id)
                .then(res => {
                    setUserResetTemporaryPassword(res.data);
                }).catch(err => {
                    handleErrorResponse(err, dispatch);
                })
        }
    }, [permissionGetUserResetTemporaryPassword, user]);

    const handleValidateUser = (data: User) => {
        const errorList: { [k: string]: string } = {};
        userSchema.validate(data, { abortEarly: false })
            .catch((err: yup.ValidationError) => {
                for (const e of err.inner) {
                    if (e.path) errorList[e.path] = e.message;
                }
            });
        setFormChanged(true);
        return errorList;
    }

    const handleSubmitUser = async (data: User, { setErrors }: FormikHelpers<User>) => {
        let prefix = 'Could not update User: ';
        if (permissionUpdateUser) {
            updateUser({
                ...data,
                id: user.id
            }).then(res => {
                dispatch(showSuccessSnackbar(`${res.data.firstName} ${res.data.lastName} updated`));
                setUser(res.data);
                setFormChanged(false);
            }).catch((err: AxiosError) => {
                if (err.response) {
                    if (err.response.status === 422) {
                        handleEditErrors(err, setErrors);
                        err.response.data.message = '';
                        prefix = 'Could not update User'
                    }
                    if (err.response.status === 400) {
                        const index = err.response.data.message.indexOf('(');
                        const message = err.response.data.message.substring(0, index - 1);
                        if (message === 'An account with the given email already exists.') {
                            setErrors({ email: 'An account with the given email already exists.' })
                            err.response.data.message = message;
                        } else if (err.response.data.message === 'This user has been disabled. All changes have not been applied.') {
                            // User is on profile page right when he is deactivated
                            // If user goes to the profile page after he is deactivated or refreshes all the fields will be disabled
                            setErrors({ email: err.response.data.message });
                            err.response.data.message = 'Please contact your system/institution administrator for more information.';
                        }
                    }
                    handleErrorResponse(err, dispatch, {
                        prefix: prefix
                    });
                }
            });
        }
    }

    // Deactivate User Handlers
    const handleDeactivateUser = () => {
        setConfirmDeactivateUser(true);
    }
    const handleConfirmDeactivateUser = () => {
        if (permissionDeactivateUser) {
            deactivateUser(user.id!)
                .then(res => {
                    dispatch(showSuccessSnackbar(`${res.data.firstName} ${res.data.lastName} deactivated`));
                    setUser(res.data);
                    setConfirmDeactivateUser(false);
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not deactivate User: '
                    });
                });
        }
    }

    // Activate User Handlers
    const handleActivateUser = () => {
        setConfirmActivateUser(true);
    }
    const handleConfirmActivateUser = () => {
        if (permissionActivateUser) {
            activateUser(user.id!)
                .then(res => {
                    dispatch(showSuccessSnackbar(`${res.data.firstName} ${res.data.lastName} activated`));
                    setUser(res.data);
                    setConfirmActivateUser(false);
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not activate User: '
                    });
                });
        }
    }

    const confirmationButton = (variant: string) => {
        let icon;
        let className: string = '';
        let handlerFunction;
        let buttonText: string = '';
        let disabled: boolean = false;
        const isSelf: boolean = user.id === loggedInUser.id;

        if (variant == 'deactivateUser') {
            icon = confirmDeactivateUser ? <HelpOutline /> : <PersonOutline />;
            className = (!permissionDeactivateUser || isSelf) ? 'disabled' : 'secondary-button';
            handlerFunction = confirmDeactivateUser ? handleConfirmDeactivateUser : handleDeactivateUser;
            buttonText = confirmDeactivateUser ? 'Confirm Deactivate' : 'Deactivate';
            disabled = !permissionDeactivateUser || isSelf;
        } else if (variant == 'activateUser') {
            icon = confirmActivateUser ? <HelpOutline /> : <PersonOutline />;
            className = (!permissionActivateUser || isSelf) ? 'disabled' : 'secondary-button';
            handlerFunction = confirmActivateUser ? handleConfirmActivateUser : handleActivateUser;
            buttonText = confirmActivateUser ? 'Confirm Activate' : 'Activate';
            disabled = !permissionActivateUser || isSelf;
        }

        return (
            <BButton
                variant="contained"
                startIcon={icon}
                className={className}
                onClick={handlerFunction}
                disabled={disabled}
                style={{ marginRight: 5 }}
            >
                {buttonText}
            </BButton>
        );
    }

    const handleResendUserInvite = () => {
        if (user.id) {
            resendUserInvite(user.id)
                .then(res => {
                    setEmailResent(true);
                    dispatch(showSuccessSnackbar('Temporary password has been resent.'));
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not resend temporary password: '
                    });
                });
        }
    }

    return (
        <>
            <Prompt
                when={formChanged}
                message={'This page contains unsaved changes. Are you sure you wish to leave?'}
            />
            <Formik
                initialValues={user}
                values={user}
                onSubmit={handleSubmitUser}
                validate={handleValidateUser}
                enableReinitialize
            >
                <BForm>
                    <Card className="detail-form">
                        <CardHeader title="General Information" />
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12} sm={4}>
                                    <BTextField
                                        name="firstName"
                                        label="First Name"
                                        placeholder="First Name"
                                        disabled={!user.active || !permissionUpdateUser}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <BTextField
                                        name="middleName"
                                        label="Middle Name"
                                        placeholder="Middle Name"
                                        disabled={!user.active || !permissionUpdateUser}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <BTextField
                                        name="lastName"
                                        label="Last Name"
                                        placeholder="Last Name"
                                        disabled={!user.active || !permissionUpdateUser}
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <BEmail
                                name="email"
                                label="Email"
                                placeholder="Email"
                                disabled={!user.active || !permissionUpdateUser}
                                required
                            />
                        </CardContent>
                        <CardActions className="space-between">
                            <div>
                                {(permissionActivateUser || permissionDeactivateUser) ? confirmationButton(activateButtonType) : null}
                                {(user.id !== loggedInUser.id && permissionResendUserInvite) &&
                                    <BButton
                                        variant="contained"
                                        startIcon={<VpnKey />}
                                        onClick={handleResendUserInvite}
                                        disabled={emailResent || !userResetTemporaryPassword}
                                    >
                                        Resend Temporary Password
                                    </BButton>
                                }
                            </div>
                            <BSubmit
                                startIcon={<Save />}
                                variant="contained"
                                color="primary"
                                disabled={!user.active || !permissionUpdateUser}
                            >
                                Save
                            </BSubmit>
                        </CardActions>
                    </Card>
                </BForm>
            </Formik>
        </>
    );
}

export default GeneralInformation;

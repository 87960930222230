import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AxiosError } from 'axios';

import { Box, Button, Card, Grid, Paper, Table, TableBody, TableContainer, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import { ScheduledPatientVisitsTab, StudyOverviewData } from '../../../../@types';
import useLoggedInUserPermissions from '../../../../common/hooks/useLoggedInUserPermissions';
import { getScheduledPatientVisitsTab } from '../../../../service/Study/study';
import ScheduledVisitsTableHeader from '../Activity/Scheduled/ScheduledVisitsTableHeader';
import ScheduledVisitTotalSection from '../Activity/Scheduled/ScheduledVisitsTableTotal';
import { handleErrorResponse } from '../../../../service/utils';

import { Store } from '../../../../modules/rootReducer';
import { CollapsiblePanelState } from '../../../../modules/collapsiblePanelReducer';

interface Props {
    studyData: StudyOverviewData,
}

const StudyOverviewVisits: React.FC<Props> = props => {
    const { studyData } = props;

    const dispatch = useDispatch();
    const history = useHistory();

    const { expanded } = useSelector<Store, CollapsiblePanelState>(
        store => store.collapsiblePanelReducer
    );

    // hooks must be called, so use 0 for ids if the study hasn't loaded yet.
    const canViewScheduledPatientVisitsTable = useLoggedInUserPermissions('API_STUDY_GET_SCHEDULED_PATIENT_VISITS_PAGE', studyData.division.id!, studyData.id);

    const [scheduledPatientVisitsData, setScheduledPatientVisitsData] = React.useState<ScheduledPatientVisitsTab | null>({} as ScheduledPatientVisitsTab);

    React.useEffect(() => {
        getScheduledPatientVisitsTab(studyData.id).then(res => {
            setScheduledPatientVisitsData(res.data);
        }).catch((err: AxiosError) => {
            if (err.response?.status === 404)
                setScheduledPatientVisitsData(null);
            else
                handleErrorResponse(err, dispatch, {
                    prefix: err.response?.data.message
                });
        });
    }, [studyData]);

    const errorMessageContent = (message: string) => {
        return (
            <Box textAlign="center" className="page-body">
                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                    {message}
                </Typography>
            </Box>
        );
    }

    const renderBody = () => {
        if (!canViewScheduledPatientVisitsTable)
            return errorMessageContent('You do not have the proper permissions to access the scheduled visits table. Please contact your division or institution administrator for more information.');
        else if (scheduledPatientVisitsData === null)
            return errorMessageContent('There are no scheduled visits associated with the contract for this study.');

        // Basically the backend will never return an empty scheduledVisits - it would throw a 404 error instead
        // As a result, the case where scheduledVisits is [] in tests doesn't need to be tested
        else if (!scheduledPatientVisitsData.scheduledPatientVisits)
            return errorMessageContent('Loading... Thank you for your patience.');
        else
            return (
                <TableContainer component={Paper} className='constrain-totals'>
                    <Table className='border-separate'>
                        <ScheduledVisitsTableHeader
                            scheduledVisits={scheduledPatientVisitsData.scheduledVisits}
                            procedures={scheduledPatientVisitsData.proceduresAssociatedWithScheduledVisits}
                        />
                        <TableBody>
                            <ScheduledVisitTotalSection
                                totalVisits={scheduledPatientVisitsData.totalVisits}
                                expectedPaid={scheduledPatientVisitsData.expectedPaid}
                                expectedHold={scheduledPatientVisitsData.expectedHold}
                                totalPaid={scheduledPatientVisitsData.totalPaid}
                                count={scheduledPatientVisitsData.scheduledVisits.length}
                            />
                        </TableBody>
                    </Table>
                </TableContainer>
            );
    }

    return (
        <Grid container item>
            <div className="section-heading">
                <div className='text'>Visits Summary</div>
                <Tooltip arrow title={'A summary of the visit totals for the study'}>
                    <InfoIcon className='info' />
                </Tooltip>
                <Button id="view-scheduled-activity" onClick={() => history.push(`/study/${studyData.id}/activity/scheduled`)}>
                    View Full Details
                </Button>
            </div>
            <Card className={'info ' + (expanded && 'constrain-expanded')}>
                {renderBody()}
            </Card>
        </Grid>
    );
}

export default StudyOverviewVisits;

import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import {
    IconButton, Link, Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Tooltip
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { BForm, BSubmit } from 'mui-bueno';

import { SelectOption, StudyUser } from '../../../../@types';
import { showSuccessSnackbar } from '../../../../modules/messageSnackbarReducer';
import { DisplayTextFormat } from '../../../../common/Utils/DisplayTextFormat';
import { assignUserToStudy, removeUserFromStudy } from '../../../../service/Study/study';
import { handleErrorResponse } from '../../../../service/utils';
import useLoggedInUserPermissions from '../../../../common/hooks/useLoggedInUserPermissions';
import SimpleConfirmDelete from '../../../../common/ConfirmDelete/SimpleConfirmDelete';
import CheckedAutocomplete from '../../../../common/CheckedAutocomplete/CheckedAutocomplete';
import usePermissions from '../../../../common/hooks/usePermissions';
import useLoggedInUser from '../../../../common/hooks/useLoggedInUser';


const emptyStudyUser: StudyUser = {
    userId: -1,
    userName: '',
    roleIds: []
}

interface State {
    objects: StudyUser[];
}

const initialState: State = {
    objects: []
}

interface Action {
    type: 'objectPage';
    payload?: any;
}

const reducer = (state: State, action: Action) => {
    if (action.type == 'objectPage') {
        return {
            ...state,
            objects: action.payload
        }
    } else {
        return state;
    }
}

interface Props {
    studyId: number;
    divisionId: number;
    divisionUsers: SelectOption<number>[];
    studyRole: SelectOption<number>;
    allStudyUsers: StudyUser[];
    update: boolean;
    setUpdate: any;
    started: boolean;
    completed: boolean;
}

const StudyRoleTable: React.FC<Props> = props => {

    const { studyId, divisionId, divisionUsers, studyRole, allStudyUsers, update, setUpdate, started, completed } = props;
    const history = useHistory();
    const dispatcher = useDispatch();

    const loggedInUser = useLoggedInUser();

    const [state, dispatchPage] = React.useReducer(reducer, initialState);
    const {
        objects
    } = state;

    // Permissions to determine if certain elements should be displayed
    const canAssign = useLoggedInUserPermissions('API_STUDY_ASSIGN_USER_TO_STUDY', divisionId, studyId);
    const canRemove = useLoggedInUserPermissions('API_STUDY_REMOVE_USER_FROM_STUDY', divisionId, studyId);

    /*
        Permission that will be used to determine if the logged in user can click on the employee's name
        and go to that user's statistics page. usePermissions('API_USER_CREATE') was used for now because it is a 
        permission that only division admins and system admins have, so this is a way to 'check' if
        the logged-in user is one of those roles.
    */
    const canGoToUserStats = usePermissions('API_USER_CREATE');

    const [studyUser, setStudyUser] = React.useState<StudyUser>(emptyStudyUser);

    // State management for autocomplete options
    const [selectedUser, setSelectedUser] = React.useState<null | SelectOption<number>>(null);

    // State management for the selected object to perform actions on
    const [clickedObj, setClickedObj] = React.useState<null | StudyUser>(null);

    const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);

    const handleClose = () => {
        setClickedObj(null);
    };

    // Text for tooltips
    const notStartedRemove = 'Cannot remove an employee before the study has been started';
    const completedRemove = 'Cannot remove an employee after the study has been completed';
    const notStartedAdd = 'Cannot assign an employee before the study has been started';
    const completedAdd = 'Cannot assign an employee after the study has been completed';

    // Get current user, all users, and all roles on initial load
    React.useEffect(() => {
        if (studyId) {
            const studyUsersWithRole: StudyUser[] = [];
            allStudyUsers.forEach((user: StudyUser) => {
                if (user.roleIds) {
                    if (user.roleIds.includes(studyRole.value)) {
                        studyUsersWithRole.push(user);
                    }
                }
            })
            dispatchPage({ type: 'objectPage', payload: studyUsersWithRole });

            setRequiredUser(false);
        }
    }, [allStudyUsers, studyId, update, deleteOpen]);

    // Form management
    const [requiredUser, setRequiredUser] = React.useState<boolean>(false);

    const handleGoToUserStatistics = (userId: number) => {
        history.push(`/manage/user/${userId}/statistics`);
    };

    const handleSubmitUser = async (data: StudyUser, { setErrors, resetForm }: FormikHelpers<StudyUser>) => {
        if (selectedUser) {
            data.userId = selectedUser.value;
            data.userName = selectedUser.label;
            data.roleIds = [studyRole.value];
            setRequiredUser(false);
        } else {
            setRequiredUser(true);
            return;
        }
        assignUserToStudy(studyId, data).then(res => {
            resetForm();
            setSelectedUser(null);
            setStudyUser(emptyStudyUser);
            dispatcher(showSuccessSnackbar(`${data.userName!} added`));
            setUpdate(!update);
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                setStatus: setErrors,
                prefix: 'Could not add User: '
            });
        });
    };

    const handleRemoveUser = (userId: null | number) => {
        if (userId) {
            removeUserFromStudy(studyId, userId)
                .then(res => {
                    setDeleteOpen(false);
                    setClickedObj(null);
                    dispatcher(showSuccessSnackbar('User removed'));
                    setUpdate(!update);
                }).catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        prefix: 'Could not remove User: '
                    });
                });
        }
        handleClose();
    };

    return (
        <>
            <Formik
                initialValues={studyUser}
                onSubmit={handleSubmitUser}
                validateOnChange={false}
                validateOnBlur={false}
            >
                <BForm>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead className="table-header">
                                <TableRow>
                                    {(studyRole) &&
                                        <TableCell className='primary-cell' >
                                            <div className='header-cell-label center'>{studyRole.label + 's'}</div>
                                        </TableCell>
                                    }
                                    {canRemove &&
                                        <TableCell className='primary-cell'>
                                            <div className="header-cell-label center">
                                                Actions
                                            </div>
                                        </TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {objects.map((obj: any, idx: number) => {
                                    return (
                                        <TableRow key={'row-' + idx}>
                                            {(canGoToUserStats || loggedInUser.id === obj.userId) ?
                                                <TableCell className='body-cell left'>
                                                    <Tooltip title='View employee&apos;s statistics' arrow>
                                                        <Link className='cursor-pointer' onClick={() => handleGoToUserStatistics(obj.userId)}>
                                                            {DisplayTextFormat(obj['userName'])}
                                                        </Link>
                                                    </Tooltip>
                                                </TableCell> :
                                                <TableCell className='body-cell left'>

                                                    {DisplayTextFormat(obj['userName'])}

                                                </TableCell>
                                            }
                                            {canRemove &&
                                                <TableCell key='header-actions' className="body-cell center">
                                                    <div className="aligned-row" style={{ justifyContent: 'center' }}>
                                                        <Tooltip
                                                            title={!started ? notStartedRemove : completed ? completedRemove : 'Remove Employee'}
                                                            arrow
                                                        >
                                                            <div>
                                                                <IconButton
                                                                    onClick={() => { setDeleteOpen(true); setClickedObj(obj); }}
                                                                    style={{ padding: '6px' }}
                                                                    disabled={!started || completed}
                                                                >
                                                                    {<Delete />}
                                                                </IconButton>
                                                            </div>
                                                        </Tooltip>
                                                    </div>
                                                </TableCell>
                                            }
                                        </TableRow>
                                    )
                                })}
                                {objects.length === 0 &&
                                    <TableRow>
                                        <TableCell colSpan={3} className='body-cell center' style={{ height: 50, fontSize: 16 }}>
                                            No data to display.
                                        </TableCell>
                                    </TableRow>
                                }
                                {canAssign &&
                                    <TableRow>
                                        <TableCell className='body-cell left'>
                                            <Tooltip title={!started ? notStartedAdd : completed ? completedAdd : ''}>
                                                <div>
                                                    <CheckedAutocomplete
                                                        idText={'user'}
                                                        multiple={false}
                                                        disabled={!started || completed}
                                                        acValue={selectedUser}
                                                        options={divisionUsers}
                                                        labelText={'User'}
                                                        error={requiredUser}
                                                        onChange={setSelectedUser}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell className='body-cell center' style={{ width: '10%' }}>
                                            <BSubmit
                                                id='add-study-user-submit'
                                                variant='text'
                                                disabled={!started || completed}
                                            >
                                                Add
                                            </BSubmit>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </BForm>
            </Formik>
            {clickedObj &&
                <>
                    <SimpleConfirmDelete
                        open={deleteOpen}
                        setOpen={setDeleteOpen}
                        type='User From Study'
                        objectName={clickedObj.userName!}
                        handleDelete={() => handleRemoveUser(clickedObj.userId)}
                        message={'Do you want to remove ' + clickedObj.userName! + ' from the study?'}
                        title={'Remove User From Study?'}
                        buttonProps={{ children: 'Remove' }}
                    />
                </>
            }
        </>
    )
}

export default StudyRoleTable;

import * as React from 'react';
import { IconButton, InputAdornment, Slider, TextField, Typography, Tooltip } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { NumberFormatter } from '../Utils/NumberFormatter';
import { commaSeparated } from '../Utils/utils';

interface Props {
    value: any;
    setValue: any;

    title: string;
    titleTooltip?: string;

    defaultValue: number;
    min: number;
    max: number;
    marks?: boolean;

    start?: any;
    end?: any;
    textAlign?: string;
    width?: number;
    integer?: boolean;
    maxLength?: number;

    className?: string;
    subtext?: JSX.Element;
}

const IntricateSlider: React.FC<Props> = props => {
    const {
        value, setValue, title, titleTooltip,
        defaultValue, min, max,
        start, end, width, integer, maxLength,
        className, subtext,
    } = props;

    const [currMax, setCurrMax] = React.useState(max);

    React.useEffect(() => {
        if (value < min) {
            setValue(min);
        }
        // Using a variable to mediate the currMax state in order to prevent
        // the currMax and value to be out of sync
        let newMax = max;
        if (value > max) { newMax = value }
        setCurrMax(newMax);
    }, [value]);

    return (
        <div className={'slider-item ' + className}>
            <div className='row space-between full-width'>
                {titleTooltip ?
                    <Tooltip title={titleTooltip}>
                        <Typography>{title}</Typography>
                    </Tooltip> :
                    <Typography>{title}</Typography>
                }
                <TextField
                    variant='filled'
                    className='slider-input'
                    value={Number(value)}
                    placeholder='0'
                    onChange={(event) => setValue(Number(event.target.value))}
                    InputProps={{
                        startAdornment: start ? <InputAdornment position="end">{start}</InputAdornment> : '',
                        endAdornment: end ? <InputAdornment position="end">{end}</InputAdornment> : '',
                        inputComponent: NumberFormatter as any,
                        inputProps: {
                            integer: integer,
                            min: min,
                            maxLength: maxLength ? maxLength : 3,
                            style: {
                                width: width ? width : 30,
                            },
                        },
                    }}
                />
            </div>
            <div className='row flex-center full-width' style={{ padding: '8px 0px 6px' }}>
                <IconButton
                    className='stepper-button'
                    onClick={() => {
                        if (value > min) { setValue(Number(value) - 1) }
                    }}
                >
                    <Remove />
                </IconButton>
                <Slider
                    value={Number(value)}
                    onChange={(_e: React.ChangeEvent<{}>, v: number | number[]) => setValue(v)}
                    defaultValue={defaultValue}
                    step={1}
                    marks={[{
                        value: min,
                        label: commaSeparated(min),
                    }, {
                        value: value,
                        label: commaSeparated(value == null ? 0 : value),
                    }, {
                        value: currMax,
                        label: commaSeparated(currMax),
                    }]}
                    min={min} max={currMax}
                />
                <IconButton
                    className='stepper-button'
                    onClick={() => setValue(Number(value) + 1)}
                >
                    <Add />
                </IconButton>
            </div>
            {subtext}
        </div>
    )
}

export default IntricateSlider;
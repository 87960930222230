import * as React from 'react';
import { useHistory } from 'react-router';
import { Button, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

import { Calculation, EffortLog, Study, UserSummary } from '../../../../@types';
import { getEffortLogsByStudyAndYear } from '../../../../service/Management/effort-logs';
import { getCalculationTotalsByUserAndStudyId, getUserCalculationsByStudyAndYear } from '../../../../service/Study/calculations';
import { shortUserString } from '../../../../common/Utils/utils';
import { DisplayTextFormat } from '../../../../common/Utils/DisplayTextFormat';
import { displayStatisticsPercent } from '../../../../common/Utils/NumberFormatter';
import useLoggedInUserPermissions from '../../../../common/hooks/useLoggedInUserPermissions';

import ActivityCostProductivityRows from './ActivityCostProductivityRows';


interface Props {
    user: UserSummary;
    study: Study;
    editYear: number;
    currentYear: number;
    currentMonth: number;
    expanded: boolean;
}

const UserStatisticsTableRow: React.FC<Props> = props => {
    const { editYear, user, study, currentYear, currentMonth } = props;

    const history = useHistory();

    const permissionGetEffortLogsByStudyAndYear: boolean =
        useLoggedInUserPermissions('API_EFFORT_LOG_GET_USER_EFFORT_LOGS_BY_STUDY_AND_YEAR', study.divisionId, -1);
    const permissionGetUserCalculationsByStudyAndYear: boolean =
        useLoggedInUserPermissions('API_CALCULATION_GET_USER_CALCULATIONS_BY_STUDY_AND_YEAR', study.divisionId, -1);
    const permissionGetCalculationTotalsByUserAndStudyId: boolean =
        useLoggedInUserPermissions('API_CALCULATION_GET_CALCULATION_TOTALS_BY_USER_AND_STUDYID', study.divisionId, -1);

    const [open, setOpen] = React.useState<boolean>(props.expanded);
    const [effortLogs, setEffortLogs] = React.useState<EffortLog[]>([]);
    const [monthlyCalculations, setMonthlyCalculations] = React.useState<Calculation[]>([]);
    const [calculationTotals, setCalculationTotals] = React.useState<Calculation[]>([]);

    React.useEffect(() => {
        if (open) {
            if (permissionGetEffortLogsByStudyAndYear)
                getEffortLogsByStudyAndYear(Number(user.id), Number(study.id), editYear).then(res => {
                    res.data.forEach(effortLog => effortLog.month = new Date(effortLog.month));
                    setEffortLogs(res.data);
                });

            if (permissionGetUserCalculationsByStudyAndYear)
                getUserCalculationsByStudyAndYear(Number(user.id), Number(study.id), editYear).then(res => {
                    res.data.forEach(calculation => calculation.associatedDate = new Date(calculation.associatedDate));
                    setMonthlyCalculations(res.data);
                });

            if (permissionGetCalculationTotalsByUserAndStudyId)
                getCalculationTotalsByUserAndStudyId(Number(user.id), Number(study.id)).then(res => {
                    setCalculationTotals(res.data);
                });
        }
    }, [open, editYear]);

    React.useEffect(() => {
        setOpen(props.expanded);
    }, [props.expanded])

    return (permissionGetEffortLogsByStudyAndYear && permissionGetUserCalculationsByStudyAndYear && permissionGetCalculationTotalsByUserAndStudyId) ? (
        <>
            <TableRow>
                <TableCell colSpan={14} className="user-header-cell" data-open={open}>
                    <Button onClick={() => setOpen(!open)}>
                        <span className="sticky" style={{ left: '8px' }}>{DisplayTextFormat(shortUserString(user), 64)}</span>
                        <span>{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</span>
                    </Button>
                </TableCell>
            </TableRow>
            {open &&
                <>
                    <TableRow>
                        <Tooltip arrow title="View User Statistics and Effort Logging page">
                            <TableCell className="header-cell indent effort sticky" style={{padding: 0}}>
                                <Button onClick={() => history.push(`/manage/user/${user.id}/statistics/${editYear}`)}>
                                    Effort
                                </Button>
                            </TableCell>
                        </Tooltip>
                        <TableCell className="data-cell unused" />
                        {Array.from(Array(12).keys()).map(month => {
                            const filtered: EffortLog[] = effortLogs.filter(effortLog =>
                                effortLog.month.getUTCMonth() === month &&
                                effortLog.month.getUTCFullYear() === editYear);
                            const value: number = filtered[0]?.percentageEffort ?? 0;
                            return (
                                <TableCell
                                    key={`user-${user.id}-month-${month}-effort`}
                                    className="text-align-right data-cell"
                                    data-disabled={editYear === currentYear && month > currentMonth}
                                >
                                    {displayStatisticsPercent(value/100)}
                                </TableCell>
                            )
                        })}
                    </TableRow>
                    <ActivityCostProductivityRows
                        calculationTotals={calculationTotals}
                        monthlyCalculations={monthlyCalculations}
                        editYear={editYear}
                        currentYear={currentYear}
                        currentMonth={currentMonth}
                        titlePadding={true}
                    />
                </>}
        </>
    ) :
        (<React.Fragment />);
}

export default UserStatisticsTableRow;
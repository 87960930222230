
import * as React from 'react'
import { useDispatch } from 'react-redux';
import { Card, CardContent, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';

import { InvoicePayment } from '../../../@types';
import { TableColumn } from '../../../common/DataTable/EditablePagingTable';
import { displayDate } from '../../../common/Utils/utils';
import { NumberFormatter } from '../../../common/Utils/NumberFormatter';
import { handleErrorResponse } from '../../../service/utils';
import { getInvoicePayments } from '../../../service/Invoice/invoice';

import CardTitle from '../../../common/Card/CardTitle'


interface Props {
    open: boolean,
    setOpen: (state: boolean) => void
    invoiceId: number
    invoiceNo: string
}


const PaymentsForInvoice: React.FC<Props> = props => {

    const { open, setOpen, invoiceId, invoiceNo } = props;

    const [payments, setPayments] = React.useState<InvoicePayment[]>([])

    const dispatcher = useDispatch();

    React.useEffect(() => {
        if (invoiceId && open) {
            //get account Payment List by invoice id
            getInvoicePayments(invoiceId)
                .then(res => {
                    const list = res.data;
                    list.sort((a, b) => {
                        return new Date(b.appliedDate).getTime() - new Date(a.appliedDate).getTime()
                    })
                    setPayments(list)
                }).catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        prefix: 'Payments for invoice could not be retrieved: '
                    })
                })
        }
    }, [open])

    const handleCloseModal = () => {
        setOpen(false);
    }

    const tblHeaders: TableColumn[] = [
        { type: 'dateApplied', displayValue: 'Date Applied', align: 'left', format: 'date' },
        { type: 'checkNo', displayValue: 'Check Number', align: 'left' },
        { type: 'usedAmount', displayValue: 'Amount Applied', align: 'right', format: 'currency-US' }

    ]

    const getCellContents = (type: string, obj: InvoicePayment) => {
        switch (type) {
            case 'dateApplied': {
                return displayDate(obj.appliedDate)
            }
            case 'usedAmount': {
                return (
                    <NumberFormatter currency value={+obj.usedAmount} />
                )
            }
            case 'checkNo': {
                return obj.checkNo;
            }
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            className='payments-for-invoice'
        >
            <Card className='detail-form md'>
                <CardTitle title={`Payments for Invoice ${invoiceNo}`} handleClose={handleCloseModal} />
                <CardContent>
                    <TableContainer component={Paper} className='payment-table-container'>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {tblHeaders.map((header) => (
                                        <TableCell
                                            key={`header-${header.type}`}
                                            className='payment-table-header-cell'
                                        >
                                            <Typography className={`${header.align}`}>
                                                {header.displayValue}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {payments.map((paymentObj, idx) => (
                                    <TableRow key={`row-${idx}`}>
                                        {tblHeaders.map((header) => (
                                            <TableCell
                                                key={`${header.type}-${idx}`}
                                                className={`payment-table-body-cell ${header.align}`}
                                            >
                                                {getCellContents(header.type, paymentObj)}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                                {payments.length === 0 &&
                                    <TableRow>
                                        <TableCell colSpan={tblHeaders.length + 1} className='payment-table-body-cell center' style={{ height: 80, fontSize: 16 }}>
                                            No data to display.
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Modal>
    )
}

export default PaymentsForInvoice;
import * as React from 'react';
import {
    Button, Card, CardActions, CardContent,
    CardHeader, IconButton, Modal, Typography
} from '@material-ui/core';
import { Close, Delete } from '@material-ui/icons';


interface Props {
    open: boolean;
    setOpen: (arg0: boolean) => void;
    type: string;
    objectName: string;
    handleDelete: () => {} | void;
    message?: string;
    title?: string;
    buttonProps?: {
        children?: React.ReactNode;
        className?: string;
        startIcon?: React.ReactNode;
    };
}

const SimpleConfirmDelete: React.FC<Props> = props => {
    const { open, setOpen, type, objectName, handleDelete, message, title, buttonProps } = props;

    const handleModalClose = (): void => setOpen(false);

    return (
        <Modal
            open={open}
            onClose={handleModalClose}
        >
            <div className="modal-form">
                <Card className="detail-form sm">
                    <CardHeader
                        title={title ?? `Delete ${type}?`}
                        action={
                            <IconButton color="primary" aria-label="Close" onClick={handleModalClose}>
                                <Close />
                            </IconButton>
                        }
                    />
                    <CardContent>
                        <Typography align="center" style={{ whiteSpace: 'pre-line' }}>
                            {message ?? `Do you want to permanently delete "${objectName}"?\nThis action cannot be undone.`}
                        </Typography>
                    </CardContent>
                    <CardActions className="flex-end">
                        <Button
                            onClick={handleDelete}
                            startIcon={buttonProps?.startIcon ?? <Delete />}
                            className={buttonProps?.className ?? 'delete-color'}
                            variant="contained"
                        >
                            {buttonProps?.children ?? 'Delete'}
                        </Button>
                    </CardActions>
                </Card>
            </div>
        </Modal>
    )
};

export default SimpleConfirmDelete;

import axiosInstance from '../Access/axiosInstance';
import { Division, DivisionTree, DivisionUser, DivisionWithUsers, StudyProductivityResult } from '../../@types';
import { handleError } from '../utils';

/**
 * Gets a page of divisions
 */
export const getRestrictedDivisions = () =>
    axiosInstance.get<Division[]>(
        '/api/divisions'
    ).catch(handleError);

/**
 * Gets a division by id
 */
export const getDivision = (id: number) =>
    axiosInstance.get<Division>(
        `/api/divisions/${id}`
    ).catch(handleError);

/**
 * Updates a division
 */
export const updateDivision = (division: Division) =>
    axiosInstance.put<Division>(
        '/api/divisions', division
    ).catch(handleError);

/**
 * Creates a division
 */
export const createDivision = (division: Division) =>
    axiosInstance.post<Division>(
        '/api/divisions', division
    ).catch(handleError);

/**
 * Deletes a division
 */
export const deleteDivision = (id: number) =>
    axiosInstance.delete(
        `/api/divisions/${id}`
    ).catch(handleError);

/**
 * Gets a division by id with all associated users
 */
export const getDivisionUsers = (id: number) =>
    axiosInstance.get<DivisionWithUsers>(
        `/api/divisions/divisionUsers/${id}`
    ).catch(handleError);

/**
 * Assign user with roles to a division
 */
export const assignUserToDivision = (divisionId: number, user: DivisionUser) =>
    axiosInstance.post(
        `/api/divisions/${divisionId}`, user
    ).catch(handleError);

/**
 * Remove user from division
 */
export const removeUserFromDivision = (divisionId: number, userId: number) =>
    axiosInstance.post(
        `/api/divisions/removeUser/${divisionId}?userId=${userId}`
    ).catch(handleError);

/**
 * Gets all divisions in Tree format
 */
export const getDivisionTree = () =>
    axiosInstance.get<DivisionTree[]>(
        '/api/divisions/tree'
    ).catch(handleError);

/**
 * Gets lifetime productivity for all studies in the selected division
 */
export const getLifetimeDivisionHealth = (divisionId?: number) =>
    axiosInstance.get<StudyProductivityResult>(
        divisionId && divisionId > 0 ?
            `/api/divisions/divisionHealth/?divisionId=${divisionId}`
            : `/api/divisions/divisionHealth/`
    ).catch(handleError);

/**
 * Gets a fiscal year's productivity for all studies in the selected division
 */
export const getYearlyDivisionHealth = (divisionId: number, year: number) =>
    axiosInstance.get<StudyProductivityResult>(
        `/api/divisions/divisionHealth/?divisionId=${divisionId}&${year}`
    ).catch(handleError);

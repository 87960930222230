import * as React from 'react';
import { useDispatch } from 'react-redux';

import { Card, CardActions, CardContent, CardHeader, Grid, IconButton, InputAdornment, Modal } from '@material-ui/core';
import { Close, Save } from '@material-ui/icons';
import { BForm, BSubmit, BTextField } from 'mui-bueno';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';

import { ScheduledVisit } from '../../../../@types';
import { showSuccessSnackbar } from '../../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../../service/utils';
import { updateScheduledVisit } from '../../../../service/Contract/scheduledVisits';
import { BIG_DECIMAL_MAX, INT_MAX } from '../../../../common/Utils/utils';
import { NullableNumberFormatter, NumberFormatter } from '../../../../common/Utils/NumberFormatter';

interface Props {
    open: any;
    setOpen: any;
    editVisit: any;
    saveEditedVisit?: any;
}

const schema = yup.object<ScheduledVisit>().shape({
    visitName: yup.string().required('Required'),
    scheduleWindow: yup.number().integer('Need an integer').min(0, 'Min value: 0').max(INT_MAX, 'Max value: ' + INT_MAX).nullable(true),
    timeFromBaseline: yup.number().required('Required').integer('Need an integer').min(-INT_MAX, 'Min value: -' + INT_MAX).max(INT_MAX, 'Max value: ' + INT_MAX).nullable(true),
    overrideCost: yup.number().min(0, 'Min value: 0').max(BIG_DECIMAL_MAX, 'Max value: ' + BIG_DECIMAL_MAX).nullable(true),
});

const VisitEdit: React.FC<Props> = props => {
    const { open, setOpen, editVisit, saveEditedVisit } = props;
    const dispatcher = useDispatch();

    const [visit, setVisit] = React.useState<ScheduledVisit>({
        id: 0,
        visitName: '',
        numInsideSequence: 0,
        timeFromBaseline: 0,
        actualCost: 0,
        sponsorProposal: 0,
        finalContractCost: 0,
        overrideCost: null,
        scheduleWindow: 0,
        contractId: 0,
        procedureIds: [],
    });

    React.useEffect(() => {
        setVisit(editVisit)
    }, [editVisit]);

    const handleSubmit = async (data: ScheduledVisit, { setErrors }: FormikHelpers<ScheduledVisit>) => {
        updateScheduledVisit(data)
            .then(res => {
                dispatcher(showSuccessSnackbar(`Scheduled Visit ${data.visitName} updated`));
                handleModalClose();
                if (saveEditedVisit) saveEditedVisit(res.data);
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    setStatus: setErrors,
                    prefix: 'Could not update Scheduled Visit: '
                });
            });
    };

    const handleModalClose = () => {
        setOpen(false);
    };

    return (
        <Modal
            open={open}
            onClose={handleModalClose}
        >
            <div className="modal-form">
                <Formik
                    initialValues={visit}
                    onSubmit={handleSubmit}
                    validationSchema={schema}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {formikValues =>
                        <BForm>
                            <Card className="detail-form sm">
                                <CardHeader
                                    title="Edit Scheduled Visit"
                                    action={
                                        <IconButton color="primary" aria-label="Close" onClick={handleModalClose}>
                                            <Close />
                                        </IconButton>
                                    }
                                />
                                <CardContent>
                                    <Grid container justifyContent='flex-start' className='contract-visit-modal'>
                                        <Grid item xs={12}>
                                            <BTextField
                                                name='visitName'
                                                label='Visit Name'
                                                placeholder='Visit Name...'
                                                required autoFocus
                                            />
                                        </Grid>
                                        <Grid item xs={12} className='subtitle'>
                                            Visit Index in Sequence: {formikValues.values.numInsideSequence}
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            <BTextField
                                                name='timeFromBaseline'
                                                label='Time From Baseline (days)'
                                                placeholder='Time From Baseline...'
                                                InputProps={{
                                                    inputComponent: NumberFormatter as any,
                                                    inputProps: { integer: true }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            <BTextField
                                                name='scheduleWindow'
                                                label='Schedule Window (days)'
                                                placeholder='Schedule Window...'
                                                InputProps={{
                                                    inputComponent: NumberFormatter as any,
                                                    inputProps: { integer: true }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className='label'>
                                            Actual Cost:
                                        </Grid>
                                        <Grid item xs={3} className='cost-value'>
                                            <NumberFormatter currency value={formikValues.values.actualCost} />
                                        </Grid>
                                        <Grid item xs={6} className='label'>
                                            Sponsor Proposal:
                                        </Grid>
                                        <Grid item xs={3} className='cost-value'>
                                            <NumberFormatter currency value={formikValues.values.sponsorProposal} />
                                        </Grid>
                                        <Grid item xs={6} className='label'>
                                            Final Cost:
                                        </Grid>
                                        <Grid item xs={3} className='cost-value'>
                                            <NumberFormatter currency value={formikValues.values.overrideCost ? formikValues.values.overrideCost : formikValues.values.finalContractCost} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <BTextField
                                                name='overrideCost'
                                                label="Override Visit's Final Cost"
                                                placeholder='No Value'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    inputComponent: NullableNumberFormatter as any
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions className="flex-end">
                                    <BSubmit
                                        startIcon={<Save />}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Save
                                    </BSubmit>
                                </CardActions>
                            </Card>
                        </BForm>
                    }
                </Formik>
            </div>
        </Modal>
    )
};

export default VisitEdit;

export type paymentTypes = 'ACH' | 'CHECK' | 'INTRA_TRANSFER';

export const paymentTypeMap = {
    'ACH': 'Automated Clearing House (ACH)',
    'CHECK': 'Check',
    'INTRA_TRANSFER': 'Intracompany Transfer',
}

export const paymentTypeLabel = (type: any) => {
    if (type in paymentTypeMap) {
        return paymentTypeMap[type as paymentTypes];
    }
    return type;
}
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useHistory } from 'react-router-dom';
import {
    Button, Collapse, Hidden, List, ListItem, ListItemText, Menu, MenuItem
} from '@material-ui/core';
import {
    AccountCircle, ExpandLess, ExpandMore
} from '@material-ui/icons';

import { User } from '../../@types';
import { Store } from '../../modules/rootReducer';
import { clearJWT } from '../../modules/loginJwtReducer';
import { clearStoreLogout } from '../../modules/loginReducer';
import { logout } from '../../service/Access/authentication';
import { AdapterLink, Feature } from './Header';
import { DisplayTextFormat } from '../../common/Utils/DisplayTextFormat';
import axiosInstance from '../../service/Access/axiosInstance';
import usePermissions from '../hooks/usePermissions';
import useLoggedInUser from '../hooks/useLoggedInUser';
import useLoggedInDivisionPermissions from '../hooks/useLoggedInDivisionPermissions';


interface Props {
    handleDrawerToggle: () => void;
}

const LoggedIn: React.FC<Props> = props => {
    const dispatch = useDispatch();
    const history = useHistory();

    const protectedFeatures: Feature[] = [{
        key: 'dashboard',
        name: 'Dashboard',
        route: '/dashboard'
    }];

    if (usePermissions('API_CONTRACT_GET_CONTRACTS')) {
        protectedFeatures.push({
            key: 'contracts',
            name: 'Contracts',
            route: '/contract'
        })
    }
    if (usePermissions('API_STUDY_GET_USER_STUDIES')) {
        protectedFeatures.push({
            key: 'studies',
            name: 'Studies',
            route: '/study'
        })
    }

    const invoicingFeatures: Feature[] = [{
        key: 'invoicing',
        name: 'Invoicing',
        route: '/invoicing'
    }];

    const divisionFeatures: Feature[] = [{
        key: 'divisions',
        name: 'Divisions',
        route: '/divisions'
    }];

    const adminFeatures: Feature[] = [{
        key: 'management',
        name: 'Management',
        route: '/manage'
    }];

    const accountFeatures: Feature[] = [{
        key: 'change-password',
        name: 'Change Password',
        route: '/user/password/change'
    }]

    const simulatorFeature: Feature = {
        key: 'simulator',
        name: 'Simulator',
        route: '/simulator'
    }

    const permissionGetUserSummaries: boolean = usePermissions('API_USER_GET_USER_SUMMARIES');
    const permissionGetRoleSummaries: boolean = usePermissions('API_ROLE_GET_ROLE_SUMMARIES');
    const permissionGetDivisionTree: boolean = usePermissions('API_DIVISION_GET_DIVISION_TREE');
    const permissionGetUserById: boolean = usePermissions('API_USER_GET_BY_ID');

    const showAdmin: boolean = permissionGetUserSummaries
        || permissionGetRoleSummaries
        || permissionGetDivisionTree;


    const permissionGetDivisionUsers: boolean = useLoggedInDivisionPermissions('API_USER_GET_DIVISION_USERS');
    const permissionGetDivisionStudies: boolean = useLoggedInDivisionPermissions('API_STUDY_GET_DIVISION_STUDIES');
    const permissionGetSalaryLogsByDivision: boolean = useLoggedInDivisionPermissions('API_SALARY_LOG_GET_BY_DIVISION');
    const permissionGetDivisionCalculations: boolean = useLoggedInDivisionPermissions('API_CALCULATION_GET_MONTHLY_DIVISION_CALCULATIONS');
    const permissionGetDivisionYearlySums: boolean = useLoggedInDivisionPermissions('API_CALCULATION_GET_YEARLY_SUMS_BY_DIVISION');
    const permissionGetDivisionLifetimeSums: boolean = useLoggedInDivisionPermissions('API_CALCULATION_GET_LIFETIME_SUMS_BY_DIVISION');

    const showDivision: boolean = permissionGetDivisionUsers
        && permissionGetDivisionStudies
        && permissionGetSalaryLogsByDivision
        && permissionGetDivisionCalculations
        && permissionGetDivisionYearlySums
        && permissionGetDivisionLifetimeSums;

    const showInvoicing = usePermissions('API_INVOICE_GET_DIVISION_PAYABLE_INVOICES')
        && usePermissions('API_INVOICE_GET_DIVISION_RECEIVABLE_INVOICES')
        && usePermissions('API_INVOICE_GET_STATUS_SUMMARY_BY_DIVISION')

    const showSimulator = usePermissions('API_SIMULATOR_GET_ALL_SIMULATIONS_BY_DIVISION');

    const { handleDrawerToggle } = props;

    const { firstName, lastName } = useSelector<Store, User>(
        store => store.loginReducer.user
    );

    const [open, setOpen] = React.useState(false);
    const handleAccountDropdown = () => {
        setOpen(!open);
    };

    const [logoutAnchor, setLogoutAnchor] = React.useState<null | HTMLElement>(null);
    const handleCloseMenu = () => {
        setLogoutAnchor(null);
    }

    const handleLogout = () => {
        logoutAnchor && handleCloseMenu(); // In case the menu is open

        const token = localStorage.getItem('accessToken');

        const logoutBehavior = () => {
            axiosInstance.defaults.headers.common.Authorization = '';
            localStorage.removeItem('accessToken');
            dispatch(clearStoreLogout());
            dispatch(clearJWT());
            history.push('/');
        }

        logout(token).then(logoutBehavior).catch(logoutBehavior);
    };

    const user = useLoggedInUser();
    const handleProfile = () => {
        logoutAnchor && handleCloseMenu(); // In case the menu is open
        history.push(`/manage/user/${user.id}/details`);
    }

    const featureButton = (feature: Feature) => {
        return (
            <Button
                key={`feature-${feature.name}`}
                component={NavLink}
                to={feature.route}
                disableRipple
                className="tab"
            >
                {feature.name}
            </Button>
        )
    }

    const featureListItem = (feature: Feature) => {
        return (
            <ListItem
                key={`feature-${feature.name}`}
                button
                component={AdapterLink}
                to={feature.route}
                onClick={handleDrawerToggle}
            >
                <ListItemText primary={feature.name} className="item" />
            </ListItem>
        )
    }

    return (
        <>
            <Hidden smDown>
                {protectedFeatures.map(feature => (
                    featureButton(feature)
                ))}
                {showDivision && divisionFeatures.map(feature => (
                    featureButton(feature)
                ))}
                {showInvoicing && invoicingFeatures.map(feature => (
                    featureButton(feature)
                ))}
                {showAdmin && adminFeatures.map(feature => (
                    featureButton(feature)
                ))}
                <div className='top-right'>
                    {showSimulator &&
                        <Button id="simulator-button" onClick={() => history.push(`/simulator`)}>
                            Simulator
                        </Button>
                    }
                    <Button id="account-button" onClick={event => setLogoutAnchor(event.currentTarget)}>
                        <div className="row">
                            <AccountCircle />
                            <p className="user-name">{DisplayTextFormat(`${firstName} ${lastName}`)}</p>
                        </div>
                    </Button>
                </div>
                <Menu
                    anchorEl={logoutAnchor}
                    keepMounted
                    open={Boolean(logoutAnchor)}
                    onClose={handleCloseMenu}
                    style={{ zIndex: 1301, marginTop: 5 }}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    {accountFeatures.map((feature) =>
                        <MenuItem
                            key={`feature-${feature.name}`}
                            onClick={handleCloseMenu}
                            component={Link}
                            to={feature.route}
                        >
                            {feature.name}
                        </MenuItem>
                    )}
                    {permissionGetUserById && <MenuItem onClick={handleProfile}>Profile</MenuItem>}
                    <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
                </Menu>
            </Hidden>
            <Hidden mdUp>
                {protectedFeatures.map(feature => (
                    featureListItem(feature)
                ))}
                {showDivision && divisionFeatures.map(feature => (
                    featureListItem(feature)
                ))}
                {showInvoicing && invoicingFeatures.map(feature => (
                    featureListItem(feature)
                ))}
                {showAdmin && adminFeatures.map(feature => (
                    featureListItem(feature)
                ))}
                {showSimulator &&
                    featureListItem(simulatorFeature)
                }
                <ListItem button onClick={handleAccountDropdown}>
                    <ListItemText primary={firstName + ' ' + lastName} className="item" />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List disablePadding>
                        {accountFeatures.map((feature) =>
                            <ListItem
                                key={`feature-${feature.name}`}
                                button
                                component={AdapterLink}
                                to={feature.route}
                                onClick={handleDrawerToggle}
                            >
                                <ListItemText primary={feature.name} className="item sub" />
                            </ListItem>
                        )}
                        <ListItem button onClick={() => { handleLogout(); handleDrawerToggle() }}>
                            <ListItemText primary="Logout" onClick={handleLogout} className="item sub" />
                        </ListItem>
                    </List>
                </Collapse>
            </Hidden>
        </>
    )
};

export default LoggedIn;
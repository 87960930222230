import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

import { StudyWithVisit } from '../../../../../@types';
import { displayDate } from '../../../../../common/Utils/utils';
import { handleErrorResponse } from '../../../../../service/utils';
import { getNoRecentVisitStudies, getRecentVisitStudies } from '../../../../../service/Study/study';

import QuickAccess from '../QuickAccess';


const options = [
    {label: 'Recent Activity', value: '1'},
    {label: 'No Recent Activity', value: '2'}
];

interface Props {
    className?: string;
    style?: React.CSSProperties;
}

const StudyQuickAccess: React.FC<Props> = props => {
    const { className, style } = props;

    const dispatch = useDispatch();
    const history = useHistory();

    const [studyList, setStudyList] = React.useState<any[]>([]);
    const [selectedOption, setSelectedOption] = React.useState<string>(options[0].value);
    
    const toStudyDisplay = (data: StudyWithVisit[]) => {
        return data.map((study, index) => {
            return (
                <div key={`study-element-${index}`} className="study-element">
                    <Button onClick={() => history.push(`/study/${study.studyId}/overview`)}>
                        <div>
                            <span>{study.studyName}</span>
                            <li>Last Visit: {study.lastVisitDate ? displayDate(study.lastVisitDate) : 'N/A'}</li>
                        </div>
                    </Button>
                </div>
            );
        });
    };

    const handleStudyError = (err: any): void =>
        handleErrorResponse(err, dispatch, {
            prefix: 'Could not retrieve list of Studies: '
        });

    React.useEffect(() => {
        if (options[0].value === selectedOption) {
            getRecentVisitStudies(20)
                .then(res => setStudyList(toStudyDisplay(res.data)))
                .catch(err => handleStudyError(err));
        } else {
            getNoRecentVisitStudies(3, 20)
                .then(res => setStudyList(toStudyDisplay(res.data)))
                .catch(err => handleStudyError(err));
        }
    }, [selectedOption]);

    return (
        <QuickAccess
            title="Studies"
            list={studyList}
            options={options}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            className={className}
            style={style}
        />
    );
}

export default StudyQuickAccess;
import axiosInstance from '../Access/axiosInstance';
import { StudyLineItems, LineItem } from '../../@types';
import { handleError } from '../utils';

/**
 * Gets an Receivable Line Items by Study Id
 */
export const getStudyReceivableLineItems = (studyId: number) =>
    axiosInstance.get<StudyLineItems>(
        `/api/lineItems/study/receivables/${studyId}`
    ).catch(handleError);

/**
 * Gets an Payable Line Items by Study Id
 */
export const getStudyPayableLineItems = (studyId: number) =>
    axiosInstance.get<StudyLineItems>(
        `/api/lineItems/study/payables/${studyId}`
    ).catch(handleError);


/**
 * Creates a line item
 * @param lineItem 
 */
export const createLineItem = (lineItem : LineItem) => 
    axiosInstance.post<LineItem>(
        '/api/lineItems', lineItem
    ).catch(handleError);


/**
 * Gets a line item by id
 * @param id 
 * @returns Line Item
 */

export const getLineItem = (id: number) => 
    axiosInstance.get<LineItem>(
        `/api/lineItems/${id}`
    ).catch(handleError);

/** 
 * Updates line item cost
 * @param id line item id
 * @param formData formData object containing a cost variable
 */

export const updateLineItemCost = (id: number, formData: FormData) =>
    axiosInstance.put<LineItem>(
        `/api/lineItems/${id}`, formData
    ).catch(handleError);


import axiosInstance from '../Access/axiosInstance';
import { StudyOverviewData } from '../../@types';
import { handleError } from '../utils';

/**
 * Gets the study overview information for the study with the given id.
 */
export const getStudyOverview = (id: number) =>
    axiosInstance.get<StudyOverviewData>(
        `/api/studyOverview/${id}`
    ).catch(handleError);

/**
 * Updates the started and completed fields for the study with the included id.
 */
export const updateStartedAndCompleted = (so: StudyOverviewData) =>
    axiosInstance.put<StudyOverviewData>(
        '/api/studyOverview', so
    ).catch(handleError);
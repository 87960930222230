import * as React from 'react';

import { Button, Hidden } from '@material-ui/core';

import { AdapterLink } from './Header';

interface Props {
    handleDrawerToggle: () => void;
}

const LoggedOut: React.FC<Props> = props => {
    const { handleDrawerToggle } = props;
    return (
        <>
            <Hidden smDown>
                <div className='top-right'>
                    <Button
                        component={AdapterLink}
                        to="/login"
                        disableRipple
                        className="animated-button outline"
                    >
                        Login
                    </Button>
                </div>
            </Hidden>
            <Hidden mdUp>
                <div className='app-access'>
                    <Button
                        component={AdapterLink}
                        to="/login"
                        disableRipple
                        className="animated-button"
                        onClick={handleDrawerToggle}
                    >
                        Login
                    </Button>
                </div>
            </Hidden>
        </>
    )
}

export default LoggedOut;
import * as React from 'react';
import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { GetApp } from '@material-ui/icons';
import { BButton } from 'mui-bueno';

import { handleErrorResponse } from '../../../service/utils';
import { downloadInvoice } from '../../../service/Invoice/invoice';
import { downloadRemittance } from '../../../service/AccountPayment/accountPayment';


interface Props {
    type: 'INVOICE' | 'REMITTANCE',
    id: number | undefined,
    fileName: string,
}

const DownloadPdf: React.FC<Props> = props => {
    const {type, id, fileName} = props;
    const dispatcher = useDispatch();

    const buttonText = type === 'INVOICE' ? 'Download Invoice' : 'Download Remittance';

    const downloadFile = (res: AxiosResponse<any>) => {
        const blob = new Blob([res.data]);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', fileName);
        link.click();
        URL.revokeObjectURL(link.href);
    }

    const handlerFunction = () => {
        if (type === 'INVOICE') {
            id && downloadInvoice(id)
                .then(res => {
                    downloadFile(res);
                }).catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        prefix: 'Could not retrieve invoice: '
                    })
                });
        } else {
            id && downloadRemittance(id)
                .then(res => {
                    downloadFile(res);
                }).catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        prefix: 'Could not retrieve remittance: '
                    })
                });
        }
    }

    return (
        <BButton
            variant="outlined"
            startIcon={<GetApp />}
            onClick={handlerFunction}
        >
            {buttonText}
        </BButton>
    );
}

export default DownloadPdf;

import * as React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router';

import { DivisionTree } from '../../@types';

import DivisionStatistics from './DivisionStatistics';
import DivisionListBrowsingpanel from './DivisionListBrowsingpanel';


interface RouteParams {
    id?: string;
}

const Divisions: React.FC = () => {
    const { id } = useParams<RouteParams>();

    const history = useHistory();

    const handleChangeSelectedDivision = (division: DivisionTree): void => {
        history.push(`/divisions/${division.id}`);
    };
    
    return (
        <div className="page">
            <DivisionListBrowsingpanel handleChangeSelectedDivision={handleChangeSelectedDivision} />
            <div className="page-content">
                <div className="inner">
                    <DivisionStatistics divisionId={id ? +id : null} />
                </div>
            </div>
        </div>
    );
}

export default Divisions;
import * as React from 'react';
import { useDispatch } from 'react-redux';

import {
    Button, ClickAwayListener, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@material-ui/core';
import { BTextField } from 'mui-bueno';
import { Formik } from 'formik';

import { Simulator, SimulatorDefaultEffort, SimulatorUserWithSalaryLogs } from '../../../../../@types';
import { DisplayTextFormat } from '../../../../../common/Utils/DisplayTextFormat';
import { NumberFormatter } from '../../../../../common/Utils/NumberFormatter';
import { handleErrorResponse } from '../../../../../service/utils';
import { createDefaultEffort, getDefaultEfforts, recalculateCost, updateDefaultEffort } from '../../../../../service/Simulator/simulator';
import { updateSimulatorCharts } from '../../../../../modules/simulatorReducer';

interface Props {
    simulator: Simulator;
    employees: SimulatorUserWithSalaryLogs[];
}

const EmployeeEffort: React.FC<Props> = props => {
    const { simulator, employees } = props;

    const dispatch = useDispatch();

    const [effort, setEffort] = React.useState<number>(0);
    const [editDefaultEffort, setEditDefaultEffort] = React.useState<SimulatorDefaultEffort | null>(null);
    const [defaultEfforts, setDefaultEfforts] = React.useState<SimulatorDefaultEffort[]>([]);
    const [hoveredCell, setHoveredCell] = React.useState<[number, number]>([-1, -1]);

    const [updateRequired, setUpdateRequired] = React.useState<boolean>(false);
    const [updating, setUpdating] = React.useState<boolean>(false);
    const [updated, setUpdated] = React.useState<'default' | 'monthly' | ''>('');

    const retrieveDefaultEffort = () => {
        getDefaultEfforts(Number(simulator.id))
            .then(res => {
                setDefaultEfforts(res.data);
                setUpdated('');
            })
            .catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: "Could not retrieve simulator employees' default effort values: "
                });
            });
    }

    React.useEffect(() => {
        retrieveDefaultEffort();
    }, [simulator, employees]);

    React.useEffect(() => {
        if (updated == 'default') {
            retrieveDefaultEffort();
            setUpdateRequired(true);
        }
        if (updated == 'monthly') {
            setUpdateRequired(true);
        }
    }, [updated]);

    const handleDefaultEffortSubmit = async (data: SimulatorDefaultEffort) => {
        if (data.id) {
            updateDefaultEffort(data)
                .then(() => {
                    setEditDefaultEffort(null);
                    setUpdated('default');
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: "Could not update simulator employee's monthly default effort: "
                    });
                });
        } else {
            createDefaultEffort(data)
                .then(() => {
                    setEditDefaultEffort(null);
                    setUpdated('default');
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: "Could not create simulator employee's monthly default effort: "
                    });
                });
        }
    }

    React.useEffect(() => {
        if (editDefaultEffort) {
            const upHandler = ({ keyCode }: { keyCode: number }) => {
                if (keyCode === 13)
                    handleDefaultEffortSubmit({ ...editDefaultEffort, percentageEffort: effort })
            };
            window.addEventListener("keyup", upHandler);
            return () => {
                window.removeEventListener("keyup", upHandler);
            };
        }
    });

    const updateCalculations = () => {
        if (updating) return;
        setUpdating(true);
        setUpdateRequired(false);
        recalculateCost(Number(simulator.id))
            .then(() => {
                setUpdating(false);
                dispatch(updateSimulatorCharts());
            })
            .catch(err => {
                setUpdating(false);
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not update simulator calculations: '
                })
            });
    }

    const defaultCell = (obj: SimulatorUserWithSalaryLogs, objIndex: number) => {
        const currDefaultEffort = defaultEfforts.find(e => e.simulatorUserId == obj.simulatorUser.id);
        const newDefaultEffort = {
            id: null,
            simulatorId: Number(simulator.id),
            simulatorUserId: obj.simulatorUser.id,
            simulatorUserName: obj.simulatorUser.userName,
            studyId: simulator.studyId,
            studyName: simulator.studyName,
            percentageEffort: 0,
        }
        if (editDefaultEffort?.simulatorUserId == obj.simulatorUser.id) {
            return (
                <TableCell
                    key={`study-${objIndex}`}
                    data-column="1"
                    className={`data-cell input-cell sticky`}
                    onMouseOver={() => setHoveredCell([-2, obj.simulatorUser.id])}
                    onMouseLeave={() => setHoveredCell([-1, -1])}
                >
                    <Formik initialValues={editDefaultEffort} onSubmit={() => handleDefaultEffortSubmit({ ...editDefaultEffort, percentageEffort: effort })}>
                        <ClickAwayListener onClickAway={() => handleDefaultEffortSubmit({ ...editDefaultEffort, percentageEffort: effort })}>
                            <div>
                                <BTextField
                                    name="percentageEffort"
                                    label=''
                                    onChange={(event) => setEffort(Number(event.target.value))}
                                    variant="filled"
                                    autoFocus noMP
                                    className='cell-input'
                                    InputProps={{
                                        endAdornment: '%',
                                        inputComponent: NumberFormatter as any,
                                        inputProps: { integer: true, maxLength: 3, min: 0, max: 100 },
                                    }}
                                />
                            </div>
                        </ClickAwayListener>
                    </Formik>
                </TableCell>
            )
        }
        return (
            <TableCell
                key={`study-${objIndex}`}
                data-column="1"
                className={`data-cell input-cell button sticky`}
                onMouseOver={() => setHoveredCell([-2, obj.simulatorUser.id])}
                onMouseLeave={() => setHoveredCell([-1, -1])}
            >
                <Button onClick={() => setEditDefaultEffort(currDefaultEffort ?? newDefaultEffort)}>
                    {currDefaultEffort ? currDefaultEffort.percentageEffort + '%' : '--'}
                </Button>
            </TableCell>
        )
    }

    return (
        <div className={`simulator-effort-table constrainable`}>
            <div className='space-between effort-header'>
                <span>Employee Effort Logs</span>
                <Button
                    variant='contained'
                    color='primary'
                    disabled={!updateRequired || updating}
                    className={'update-simulator ' + (updateRequired && !updating ? 'ready' : '')}
                    onClick={() => { if (updateRequired) updateCalculations() }}
                >
                    {updating ? 'Updating' : 'Update'}
                </Button>
            </div>
            <div className='effort-body'>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell className="background" style={{ zIndex: 4, backgroundColor: 'white' }} />
                                <TableCell
                                    className="header-cell sticky center" data-column="1"
                                    data-highlight={hoveredCell[0] === -2}
                                    style={{ borderRadius: '3px 0 0 0' }}
                                >
                                    Default Monthly Effort
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {employees.map((obj, objIndex) => {
                                return (
                                    <TableRow key={`study-row-${obj.simulatorUser.id}`}>
                                        <TableCell
                                            align="left"
                                            className="header-cell sticky button"
                                            data-column="0"
                                            data-highlight={hoveredCell[1] === obj.simulatorUser.id}
                                        >
                                            {DisplayTextFormat(obj.simulatorUser.userName, 20, false)}
                                        </TableCell>
                                        {defaultCell(obj, objIndex)}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default EmployeeEffort;
import { StudyListDetail } from './../../@types/index.d';
import axiosInstance from '../Access/axiosInstance';
import {
    Contract, Study, StudyPage, StudyPageRequest, User, ScheduledVisit,
    PatientVisitsList, UnscheduledPatientVisitsTab, StudyWithUsers, StudyWithVisit, StudyUser, ScheduledPatientVisitsTab
} from '../../@types';
import { handleError } from '../utils';

/**
 * Gets a page of studies
 */
export const getStudyPage = (pageReq: StudyPageRequest) =>
    axiosInstance.get<StudyPage>(
        '/api/studies',
        {
            params: pageReq
        }
    ).catch(handleError);

/**
 * Gets a list of restricted studies
 */
export const getRestrictedStudies = () =>
    axiosInstance.get<StudyListDetail[]>(
        '/api/studies/restricted'
    ).catch(handleError);

/**
 * Gets a study by id
 */
export const getStudy = (id: number) =>
    axiosInstance.get<Study>(
        `/api/studies/${id}`
    ).catch(handleError);

/**
 * Gets a list of scheduled visits based on the most recent signed contract
 */
export const getScheduledVisitsFromStudy = (id: number) =>
    axiosInstance.get<ScheduledVisit[]>(
        `/api/studies/scheduledVisits/${id}`
    ).catch(handleError);

/**
* Gets a list of scheduled patient visits
*/
export const getScheduledPatientVisitsFromStudy = (id: number) =>
    axiosInstance.get<PatientVisitsList[]>(
        `/api/studies/scheduledPatientVisits/${id}`
    ).catch(handleError);

/**
* Gets a list of unscheduled patient visits
*/
export const getUnscheduledPatientVisitsFromStudy = (id: number) =>
    axiosInstance.get<PatientVisitsList[]>(
        `/api/studies/unscheduledPatientVisits/${id}`
    ).catch(handleError);

/**
* Gets a study's current contract by id
*/
export const getStudyCurrentContract = (id: number) =>
    axiosInstance.get<Contract>(
        `/api/studies/contract-current/${id}`
    ).catch(handleError);

/**
 * Gets a List of Study's given User Id is assigned to
 */
export const getUserStudies = (userId: number | undefined) =>
    axiosInstance.get<Study[]>(
        `/api/studies/user/${userId}`
    ).catch(handleError);

export const getDivisionStudies = (divisionId: number) =>
    axiosInstance.get<Study[]>(
        `api/studies/division/${divisionId}`
    ).catch(handleError);

/**
 * Gets a List Users assigned to the Study as a Coordinator
 * @param id the Study id 
 * @returns a List of Users
 */
export const getStudyCoordinators = (id: number) =>
    axiosInstance.get<User[]>(
        `/api/studies/${id}/coordinators`
    ).catch(handleError);

/**
 * Gets a List Users assigned to the Study as a Provider
 * @param id the Study id 
 * @returns a List of Users
 */
export const getStudyProviders = (id: number) =>
    axiosInstance.get<User[]>(
        `/api/studies/${id}/providers`
    ).catch(handleError);

/**
 * Updates a study
 */
export const updateStudy = (study: Study) =>
    axiosInstance.put<Study>(
        '/api/studies', study
    ).catch(handleError);

/**
 * Deletes a study
 */
export const deleteStudy = (id: number) =>
    axiosInstance.delete(
        `/api/studies/${id}`
    ).catch(handleError);

/**
 * Gets a study by contract id
 */
export const getStudyByContract = (contractId: number) =>
    axiosInstance.get<Study>(
        `/api/studies/studyByContract/${contractId}`
    ).catch(handleError);

/**
 * Gets the information for the unscheduled visits tab of the study visits page for a study
 */
export const getScheduledPatientVisitsTab = (id: number) =>
    axiosInstance.get<ScheduledPatientVisitsTab>(
        `/api/studies/scheduledPatientVisitsPage/${id}`
    ).catch(handleError);

/**
 * Gets the information for the unscheduled visits tab of the study visits page for a study
 */
export const getUnscheduledPatientVisitsTab = (id: number) =>
    axiosInstance.get<UnscheduledPatientVisitsTab[]>(
        `/api/studies/unscheduledPatientVisitsPage/${id}`
    ).catch(handleError);

/**
 * Gets the information for a single Unscheduled Visit
 */
export const getUnscheduledPatientVisitsTabByPatientVisitId = (id: number) =>
    axiosInstance.get<UnscheduledPatientVisitsTab>(
        `/api/studies/unscheduledPatientVisit/${id}`
    ).catch(handleError);

/**
* Gets a study by id with all associated users
*/
export const getStudyUsers = (id: number) =>
    axiosInstance.get<StudyWithUsers>(
        `/api/studies/studyUsers/${id}`
    ).catch(handleError);

/**
* Assign user with roles to a study
*/
export const assignUserToStudy = (studyId: number, user: StudyUser) =>
    axiosInstance.post(
        `/api/studies/${studyId}`, user
    ).catch(handleError);

/**
 * Remove user from study
 */
export const removeUserFromStudy = (studyId: number, userId: number) =>
    axiosInstance.post(
        `/api/studies/removeUser/${studyId}?userId=${userId}`
    ).catch(handleError);

//add tests
/**
 * Get list of studies by account id
 */
export const getStudiesByAccount = (accountId: number) =>
    axiosInstance.get<Study[]>(
        `/api/studies/account/${accountId}`
    ).catch(handleError);

/**
 * Get list of errors to determine whether study can be started
 * If list non-empty study cannot be started
 */
export const canBeStarted = (studyId: number) =>
    axiosInstance.get<boolean>(
        `/api/studies/startable/${studyId}`
    ).catch(handleError);

/**
 * Get list of Studies with most recent logged PatientVisit
 * @param limit the maximum number of Studies that can be returned
 */
export const getRecentVisitStudies = (limit?: number) =>
    axiosInstance.get<StudyWithVisit[]>(
        '/api/studies/mostRecentVisit',
        { params: {
            limit: limit
        }}
    ).catch(handleError);

/**
 * Get list of Studies with no recently logged PatientVisit
 * @param inactivityMonthCount the number of months in the past to consider. Studies with PatientVisits logged after this time will be excluded
 * @param limit the maximum number of Studies that can be returned
 */
export const getNoRecentVisitStudies = (inactivityMonthCount: number, limit?: number) =>
    axiosInstance.get<StudyWithVisit[]>(
        '/api/studies/noRecentVisit',
        { params: {
            inactivityMonthCount: inactivityMonthCount,
            limit: limit
        }}
    ).catch(handleError);
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { FormikProps } from 'formik';
import { IconButton, TableCell, TableRow } from '@material-ui/core';
import { EditTwoTone, InfoTwoTone } from '@material-ui/icons';
import { BOption } from 'mui-bueno';

import { AccountPageRequest, ContractScheduleOfEvents, Procedure, SelectOption } from '../../../../@types';
import { convertAccountDataToSelectOptions, convertReferenceDataToBOptions } from '../../../../common/Utils/utils';
import { DisplayTextFormat } from '../../../../common/Utils/DisplayTextFormat';
import { NumberFormatter } from '../../../../common/Utils/NumberFormatter';
import { handleErrorResponse } from '../../../../service/utils';
import { getInvoiceTypes } from '../../../../service/InvoiceType/invoiceType';
import { getResourceTypes } from '../../../../service/Reference/reference';

import { SOEForm } from './ScheduleOfEvents';
import ProcedureEdit from './ProcedureEdit';
import { getAccountPage } from '../../../../service/Account/account';


interface SOETableProps {
    scheduleOfEvents: ContractScheduleOfEvents;
    unlockCells: boolean;
    formikProps: FormikProps<SOEForm>;
    readonly: boolean;
    setReload: any;
    canEditProcedure: boolean;
}

const ScheduleOfEventsTable: React.FC<SOETableProps> = props => { //NOSONAR
    const { scheduleOfEvents, unlockCells, formikProps, readonly, setReload, canEditProcedure } = props;

    const dispatch = useDispatch();

    const [clickedProcedure, setClickedProcedure] = React.useState<(Procedure | null)>(null);
    const [openEditProcedure, setOpenEditProcedure] = React.useState<boolean>(false);
    const [invoiceTypes, setInvoiceTypes] = React.useState<BOption<string>[]>([]);
    const [resourceTypes, setResourceTypes] = React.useState<BOption<string>[]>([]);
    const [serviceProviderOptions, setServiceProviderOptions] = React.useState<SelectOption<number>[]>([]);

    React.useEffect(() => {
        getInvoiceTypes()
            .then(res => {
                setInvoiceTypes(convertReferenceDataToBOptions(res.data));
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve list of Invoice Types: '
                });
            });
        getResourceTypes()
            .then(res => {
                setResourceTypes(convertReferenceDataToBOptions(res.data));
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve list of Resource Types: '
                })
            });
            
        const pageReq: AccountPageRequest = {
            page: 0,
            sort: 'name'
        };
        getAccountPage(pageReq).then(res => {
            setServiceProviderOptions(convertAccountDataToSelectOptions(res.data.list))
        }).catch(err => {
            handleErrorResponse(err, dispatch, {
                prefix: 'Could not retrieve list of Accounts: '
            });
        });
    }, [])

    const modifiable = !readonly && unlockCells ? 'soe-grid-cell' : '';
    const handleCellClick = (procedureIdx: number, scheduledVisitIdx: number) => (event: React.MouseEvent<HTMLTableHeaderCellElement>) => {
        if (unlockCells) {
            const importantValue = scheduleOfEvents.procedures[procedureIdx].id + ',' + scheduleOfEvents.scheduledVisits[scheduledVisitIdx].id!;
            
            const procedureActualCost = scheduleOfEvents.procedures[procedureIdx].actualCost ?? 0;
            const procedureProposedCost = scheduleOfEvents.procedures[procedureIdx].sponsorProposal ?? 0;

            let tempValue: number = 0;
            let tempList: number[] = [];

            if (formikProps.values.hiddenAssociations.includes(importantValue)) {
                tempValue = formikProps.values.actualCosts[scheduledVisitIdx];
                tempList = [...formikProps.values.actualCosts];
                tempList.splice(scheduledVisitIdx, 1, tempValue - procedureActualCost);
                formikProps.setFieldValue('actualCosts', tempList, false);

                tempValue = formikProps.values.proposedCosts[scheduledVisitIdx];
                tempList = [...formikProps.values.proposedCosts];
                tempList.splice(scheduledVisitIdx, 1, tempValue - procedureProposedCost);
                formikProps.setFieldValue('proposedCosts', tempList, false);

                tempValue = formikProps.values.finalCosts[scheduledVisitIdx];
                tempList = [...formikProps.values.finalCosts];
                tempList.splice(scheduledVisitIdx, 1, scheduleOfEvents.overrideCosts[scheduledVisitIdx] ?? (tempValue - procedureProposedCost));
                formikProps.setFieldValue('finalCosts', tempList, false);

                formikProps.setFieldValue('hiddenAssociations', formikProps.values.hiddenAssociations.filter(str => str !== scheduleOfEvents.procedures[procedureIdx].id + ',' + scheduleOfEvents.scheduledVisits[scheduledVisitIdx].id!), false);
                if (formikProps.values.added.includes(importantValue)) {
                    formikProps.setFieldValue('added', formikProps.values.added.filter(str => str !== importantValue), false);
                } else {
                    formikProps.setFieldValue('removed', formikProps.values.removed.concat([importantValue]), false);
                }
            } else {
                tempValue = formikProps.values.actualCosts[scheduledVisitIdx];
                tempList = [...formikProps.values.actualCosts];
                tempList.splice(scheduledVisitIdx, 1, tempValue + procedureActualCost);
                formikProps.setFieldValue('actualCosts', tempList, false);

                tempValue = formikProps.values.proposedCosts[scheduledVisitIdx];
                tempList = [...formikProps.values.proposedCosts];
                tempList.splice(scheduledVisitIdx, 1, tempValue + procedureProposedCost);
                formikProps.setFieldValue('proposedCosts', tempList, false);

                tempValue = formikProps.values.finalCosts[scheduledVisitIdx];
                tempList = [...formikProps.values.finalCosts];
                tempList.splice(scheduledVisitIdx, 1, scheduleOfEvents.overrideCosts[scheduledVisitIdx] ?? (tempValue + procedureProposedCost));
                formikProps.setFieldValue('finalCosts', tempList, false);

                formikProps.setFieldValue('hiddenAssociations', formikProps.values.hiddenAssociations.concat([importantValue]), false);
                if (formikProps.values.removed.includes(importantValue)) {
                    formikProps.setFieldValue('removed', formikProps.values.removed.filter(str => str !== importantValue), false);
                } else {
                    formikProps.setFieldValue('added', formikProps.values.added.concat([importantValue]), false);
                }
            }
        }
    }
    const handleEditProcedure = (procedure: Procedure) => {
        setClickedProcedure(procedure);
        setOpenEditProcedure(true);
    }

    return (
        <>
            {scheduleOfEvents.procedures.map((element: Procedure, idx: number) => {
                return (
                    <TableRow key={'soe-grid-procedure-' + idx}>
                        <TableCell key={'soe-grid-procedure-name-' + idx} className={'soe-procedure-label-cell left'}>
                            {DisplayTextFormat(element.name, 35)}
                        </TableCell>
                        <TableCell key={'soe-grid-edit-' + idx} className={'soe-procedure-edit-cell left'}>
                            {(!readonly || canEditProcedure) ?
                                <IconButton onClick={() => handleEditProcedure(element)} style={{ color: '#d5dbf0' }}>
                                    {readonly ? <InfoTwoTone /> : <EditTwoTone />}
                                </IconButton> :
                                <IconButton disabled>
                                    <div style={{ width: 20.8 }} />
                                </IconButton>
                            }
                        </TableCell>
                        <TableCell key={'soe-grid-resource-' + idx} className={'soe-grid-readonly-cell center soe-resource-cell'}>
                            {element.resourceType}
                        </TableCell>
                        <TableCell key={'soe-grid-time-' + idx} className={'soe-grid-readonly-cell right soe-time-required-cell'}>
                            {element.timeRequired}
                        </TableCell>
                        <TableCell key={'soe-grid-actual-cost-' + idx} className={'soe-grid-readonly-cell right soe-actual-cost-cell'}>
                            <NumberFormatter currency value={element.actualCost} />
                        </TableCell>
                        {new Array(scheduleOfEvents.scheduledVisits.length).fill(element.actualCost).map((value: number, idx2: number) => {
                            if (element.scheduledVisitIds!.includes(scheduleOfEvents.scheduledVisits[idx2].id!)) {
                                return (
                                    <TableCell
                                        key={'soe-grid-link-' + idx + '-' + idx2}
                                        className={
                                            formikProps.values.removed.includes(scheduleOfEvents.procedures[idx].id + ',' + scheduleOfEvents.scheduledVisits[idx2].id!) ?
                                                'soe-body-cell soe-grid-readonly right soe-grid-selected soe-grid-removed ' + modifiable
                                                :
                                                'soe-body-cell soe-grid-readonly right soe-grid-selected ' + modifiable
                                        }
                                        onClick={handleCellClick(idx, idx2)}
                                    >
                                        {(!unlockCells || !formikProps.values.removed.includes(scheduleOfEvents.procedures[idx].id + ',' + scheduleOfEvents.scheduledVisits[idx2].id!)) &&
                                            <NumberFormatter currency value={value} />
                                        }
                                    </TableCell>
                                );
                            }
                            const editing = readonly ? 'soe-body-cell right soe-grid-unselected' : 'soe-body-cell right soe-grid-unselected-editing';
                            return (
                                <TableCell
                                    key={'soe-grid-link-' + idx + '-' + idx2}
                                    className={
                                        formikProps.values.added.includes(scheduleOfEvents.procedures[idx].id + ',' + scheduleOfEvents.scheduledVisits[idx2].id!) ?
                                            'soe-body-cell soe-grid-readonly right soe-grid-unselected soe-grid-added ' + modifiable
                                            : editing
                                    }
                                    onClick={handleCellClick(idx, idx2)}
                                >
                                    {unlockCells && formikProps.values.added.includes(scheduleOfEvents.procedures[idx].id + ',' + scheduleOfEvents.scheduledVisits[idx2].id!) &&
                                        <NumberFormatter currency value={value} />
                                    }
                                </TableCell>
                            );
                        })}
                    </TableRow>
                )
            })}
            {clickedProcedure && openEditProcedure &&
                <ProcedureEdit
                    open={openEditProcedure}
                    setOpen={setOpenEditProcedure}
                    procedure={clickedProcedure}
                    resourceTypes={resourceTypes}
                    invoiceTypes={invoiceTypes}
                    setReload={setReload}
                    serviceProviderOptions={serviceProviderOptions}
                    readOnly={readonly}
                />
            }
        </>
    )
}
export default ScheduleOfEventsTable;
import axiosInstance from '../Access/axiosInstance';

import { SalaryLog, SalaryLogPage, SalaryLogPageRequest } from '../../@types';
import { handleError } from '../utils';


/**
 * Gets a page of salary logs
 */
export const getSalaryLogPage = (pageReq: SalaryLogPageRequest) =>
    axiosInstance.get<SalaryLogPage>(
        `/api/salaryLogs`, {
            params: pageReq
        }
    ).catch(handleError);

export const getSalaryLogsByDivision = (id: number) =>
    axiosInstance.get<SalaryLog[]>(
        `/api/salaryLogs/division/${id}`
    ).catch(handleError);

/**
 * Updates a salary log
 */
export const updateSalaryLog = (salaryLog: SalaryLog) =>
    axiosInstance.put<SalaryLog>(
        '/api/salaryLogs', salaryLog
    ).catch(handleError);

/**
 * Creates a salary log
 */
export const createSalaryLog = (salaryLog: SalaryLog) =>
    axiosInstance.post<SalaryLog>(
        '/api/salaryLogs', salaryLog
    ).catch(handleError);

/**
 * Deletes a salary log
 */
export const deleteSalaryLog = (id: number) =>
    axiosInstance.delete(
        `/api/salaryLogs/${id}`
    ).catch(handleError);

/**
* Gets earliest effective date by user id
*/
export const getEarliestEffectiveDateByUser = (userId: number) =>
    axiosInstance.get<string>(
        `/api/salaryLogs/earliestDate/${userId}`
    ).catch(handleError);
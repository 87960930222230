import * as React from 'react';
import { useHistory, useParams } from 'react-router';

import { Tab, Tabs, Typography } from '@material-ui/core';

import { Contract, Study } from '../../../../@types';
import { DisplayTextFormat } from '../../../../common/Utils/DisplayTextFormat';
import useLoggedInUserPermissions from '../../../../common/hooks/useLoggedInUserPermissions';

import VisitsTable from './Scheduled/ScheduledVisitsTable';
import IncurredCosts from './IncurredCosts/IncurredCosts';
import UnscheduledVisitsTable from './Unscheduled/UnscheduledVisitsTable';


interface RouteParams {
    id: string;
    subSection: string; // 'scheduled', 'unscheduled', or 'other'
}

interface VisitsProps {
    study: Study;
    currentContract: Contract;
}

const Activity: React.FC<VisitsProps> = props => {
    const { study, currentContract } = props;

    const { subSection = 'scheduled' } = useParams<RouteParams>();

    const id = study.id!;

    const history = useHistory();

    const handleCallToRouter = (event: React.ChangeEvent<{}>, value: any) => {
        history.push(value);
    };

    const canRetrieveIncurredCosts = useLoggedInUserPermissions('API_INCURRED_COST_GET_INCURRED_COSTS_BY_STUDY', study.divisionId, study.id!);

    const TabPanel = (value: any) => {
        if (value === `/study/${id}/activity/scheduled`) {
            return (
                <div role='tabpanel' id='simple-tabpanel-scheduled' aria-labelledby='simple-tab-scheduled'>
                    <VisitsTable study={study} />
                </div>
            )
        }
        if (value === `/study/${id}/activity/unscheduled`) {
            return (
                <div role='tabpanel' id='simple-tabpanel-unscheduled' aria-labelledby='simple-tab-unscheduled'>
                    <UnscheduledVisitsTable study={study} currentContract={currentContract} />
                </div>
            )
        }
        return (
            <div role='tabpanel' id='simple-tabpanel-other' aria-labelledby='simple-tab-other'>
                {canRetrieveIncurredCosts ? //NOSONAR
                    <IncurredCosts id={id.toString()} divisionId={study.divisionId} currentContractId={currentContract.id!} /> :
                    <Typography className='instruction-padding'>
                        You do not have permission to view the incurred costs.
                    </Typography>
                }
            </div>
        );
    }

    return (
        <>
            <div className="page-heading">
                <Typography className="title">{DisplayTextFormat(study.name, 64)}</Typography>
            </div>
            <div className="page-body" style={{ marginTop: '-15px', paddingBottom: '5px' }}>
                <Tabs
                    value={`/study/${id}/activity/${subSection}`}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleCallToRouter}
                    className={'sv-tab'}
                >
                    <Tab label="Scheduled Visits" value={`/study/${id}/activity/scheduled`} />
                    <Tab label="Unscheduled Visits" value={`/study/${id}/activity/unscheduled`} />
                    <Tab label="Other Incurred Costs" value={`/study/${id}/activity/other`} />
                </Tabs>
                {TabPanel(history.location.pathname)}
            </div>
        </>
    )
}
export default Activity;
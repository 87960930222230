import { PatientVisitWithUsers, UnscheduledPatientVisitProcedure } from './../../@types/index.d';
import axiosInstance from '../Access/axiosInstance';
import { PatientVisit, PatientVisitPage, PatientVisitPageRequest, PerformedProcedure, ScheduledPatientVisitDetail, ScheduledVisit } from '../../@types';
import { handleError } from '../utils';

/**
 * Gets a page of patient visits
 */
export const getPatientVisitsPage = (pageReq: PatientVisitPageRequest) =>
    axiosInstance.get<PatientVisitPage>(
        '/api/patientVisits',
        {
            params: pageReq
        }
    ).catch(handleError);

/**
 * Gets a patient visit by id
 */
export const getPatientVisit = (id: number) =>
    axiosInstance.get<PatientVisit>(
        `/api/patientVisits/${id}`
    ).catch(handleError);

/**
 * Updates a patient visit
 */
export const updatePatientVisit = (patientVisit: PatientVisitWithUsers) =>
    axiosInstance.put<PatientVisit>(
        '/api/patientVisits', patientVisit
    ).catch(handleError);

/**
 * Creates a patient visit
 */
export const createPatientVisit = (patientVisit: PatientVisitWithUsers) =>
    axiosInstance.post<PatientVisit>(
        '/api/patientVisits', patientVisit
    ).catch(handleError);

/**
 * Deletes a patient visit
 */
export const deletePatientVisit = (id: number) =>
    axiosInstance.delete(
        `/api/patientVisits/${id}`
    ).catch(handleError);

/**
 * Gets associated performed procedures from patient visit
 */
export const getPerformedProcedures = (id: number) =>
    axiosInstance.get<PerformedProcedure[]>(
        `/api/patientVisits/performedProcedures/${id}`
    ).catch(handleError);

/**
 * Gets associated scheduled visit from patient visit
 */
export const getScheduledVisit = (id: number) =>
    axiosInstance.get<ScheduledVisit>(
        `/api/patientVisits/scheduledVisit/${id}`
    ).catch(handleError);

/**
 * Gets patient visit detail
 */
export const getScheduledPatientVisitDetail = (id: number) =>
    axiosInstance.get<ScheduledPatientVisitDetail>(
        `/api/patientVisits/scheduledPatientVisit/${id}`
    ).catch(handleError);

/**
* Gets unscheduled patient visit's procedures
*/
export const getUnscheduledPatientVisitProcedures = (id: number) =>
    axiosInstance.get<UnscheduledPatientVisitProcedure>(
        `/api/patientVisits/unscheduled/procedures/${id}`
    ).catch(handleError);
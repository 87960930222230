import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Card, Grid, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Add from '@material-ui/icons/Add';

import { Contract, ContractPageRequest } from '../../../../@types';
import useLoggedInUserPermissions from '../../../../common/hooks/useLoggedInUserPermissions';
import { displayDate } from '../../../../common/Utils/utils';
import { NumberFormatter } from '../../../../common/Utils/NumberFormatter';
import { DisplayTextFormat } from '../../../../common/Utils/DisplayTextFormat';
import { getStudy, getStudyCurrentContract } from '../../../../service/Study/study';
import { createAddendum, getContractsPage } from '../../../../service/Contract/contracts';
import { handleErrorResponse } from '../../../../service/utils';


interface State {
    objects: any[];
    rowsPerPage: number;
    requestedPage: number;
    orderBy: string;
    totalElements: number;
    page: number;
}

const initialState: State = {
    objects: [],
    rowsPerPage: 10,
    requestedPage: 0,
    orderBy: 'id',
    totalElements: 0,
    page: 0,
}

interface Action {
    type: 'objectPage';
    payload?: any;
}

const reducer = (state: State, action: Action) => {
    if (action.type == 'objectPage') {
        return {
            ...state,
            objects: action.payload.list,
            totalElements: action.payload.total,
            page: action.payload.page
        }
    }
    return state;
}

const Addendums: React.FC<{ id: string }> = props => {
    const { id } = props;
    const history = useHistory();
    const dispatcher = useDispatch();

    const [state, dispatchPage] = React.useReducer(reducer, initialState);
    const {
        objects,
        rowsPerPage,
        requestedPage,
        orderBy,
    } = state;

    const [studyName, setStudyName] = React.useState<string>('');
    const [divisionId, setDivisionId] = React.useState<number>(0);
    const [currentContractId, setCurrentContractId] = React.useState<number>(0);

    React.useEffect(() => {
        getStudy(Number(id)).then(res => {
            setStudyName(res.data.name);
            setDivisionId(res.data.divisionId);
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                prefix: 'Could not find Study: '
            });
            history.push('/study');
        });

        getStudyCurrentContract(Number(id)).then(res => {
            setCurrentContractId(res.data.id!);
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                prefix: 'Could not find Study: '
            });
            history.push('/study');
        });

        const pageReq: ContractPageRequest = {
            page: requestedPage,
            size: rowsPerPage,
            sort: orderBy,
            descending: true,
            studyId: Number(id)
        }
        getContractsPage(pageReq).then(res => {
            dispatchPage({ type: 'objectPage', payload: res.data });
        })

    }, [id]);

    // Permissions to determine if create addendum button should be displayed
    const canCreate = useLoggedInUserPermissions('API_CONTRACT_CREATE_ADDENDUM', divisionId, Number(id));

    const handleCreateAddendum = () => {
        createAddendum(Number(id)).then(res => {
            history.push(`/contract/${res.data.id}/overview`);
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                prefix: 'Could not find Study: '
            });
        })
    }

    return (
        <>
            <div className="page-heading">
                <Typography className="title">{DisplayTextFormat(studyName, 64)}</Typography>
            </div>
            <div className="page-body max-lgxl addendum-history">
                <div className="aligned-row">
                    <div className="left">
                        <div className="page-title">Addendum History</div>
                        <Tooltip
                            arrow
                            title='The most recent addendums will appear first, 
                            with the currently signed contract being denoted with the &quot;Current&quot; tag on the top right of the card. 
                            When creating an addendum, make sure all the information and details are filled out before signing.'
                        >
                            <InfoIcon />
                        </Tooltip>
                    </div>
                    {canCreate &&
                        <div className="right create-addendum">
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Add />}
                                onClick={() => handleCreateAddendum()}
                            >
                                Create Addendum
                            </Button>
                        </div>
                    }
                </div>
                <Grid container spacing={4} justifyContent='flex-start' alignItems='flex-start'>
                    {objects.map((obj: Contract, idx: number) => {
                        return (
                            <Grid item xs={12} sm={6} md={4} key={'row-' + idx}>
                                <Card
                                    onClick={() => { history.push(`/contract/${obj.id}/overview`) }}
                                    className='addendum-card column'
                                >
                                    <div className='large-content'>
                                        {obj.created == null ? <div /> :
                                            <div> Created: {displayDate(obj.created)} </div>
                                        }
                                        {obj.signed == null ? <div>Not Signed</div> :
                                            <div> Signed: {displayDate(obj.signed)} </div>
                                        }
                                    </div>
                                    <div className='divider' />
                                    <div className='small-content'>

                                        <div> Sponsor Proposal: <NumberFormatter currency value={obj.sponsorProposal} /> </div>
                                        <div> Negotiating Cost: <NumberFormatter currency value={obj.negotiatingCost} /> </div>
                                        <div> Actual Cost: <NumberFormatter currency value={obj.actualCost} /> </div>
                                        <div> Final Cost: <NumberFormatter currency value={obj.finalCost} /> </div>
                                    </div>
                                    {obj.id == currentContractId && <div className='signed-tag'>Current</div>}
                                </Card>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        </>
    )

}
export default Addendums;
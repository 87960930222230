import { BOption } from 'mui-bueno';
import _ from 'lodash';
import { PatientVisitUser, PerformedProcedure, SelectOption, ShortProcedure, Study, User, UserSummary } from '../../../../../@types';
import { getLocalDate } from '../../../../../common/Utils/utils';
import { DisplayFullName } from '../../../../../common/Utils/DisplayTextFormat';


export const getProcedureCosts = (allProceduresFromContract: ShortProcedure[], procedureIds: number[]) => {
    const mappedProcedures = allProceduresFromContract.map(proc => proc.id!);
    const costs: number[] = [];
    for (const procid of procedureIds) {
        const index = mappedProcedures.indexOf(procid);
        const cost = allProceduresFromContract[index].sponsorProposal ? allProceduresFromContract[index].sponsorProposal! : 0;
        costs.push(cost);
    }
    return costs;
};

export const createPerformedProcedures = (procedureIds: number[], costs: number[], patientVisitId: null | number) => {
    const addingProcedures = [];
    for (let i = 0; i < procedureIds.length; i++) {
        addingProcedures.push({
            id: null,
            patientVisitId: patientVisitId,
            procedureId: procedureIds[i],
            lineItemId: null,
            cost: costs[i]
        } as PerformedProcedure);
    }
    return addingProcedures;
};

const createBOptionPatientVisitUser = (userId: number, firstName: string, lastName: string, resourceType: string) => {
    return {
        value: {
            userId: userId,
            userName: firstName + ' ' + lastName,
            resourceType: resourceType,
            effort: 0
        },
        label: firstName + ' ' + lastName
    }
}
export const toBOptionsPatientVisitUser = (users: UserSummary[], resourceType: string, excludedUsers: PatientVisitUser[]) => {
    const temp: BOption<PatientVisitUser>[] = [];
    const excludedUserIds = _.map(excludedUsers, 'userId');
    users.forEach((aUser: UserSummary) => {
        if (!excludedUserIds.includes(aUser.id)) {
            temp.push(createBOptionPatientVisitUser(
                aUser.id,
                aUser.firstName,
                aUser.lastName,
                resourceType
            ));
        }
    });
    return temp;
};

const createSelectOptionPatientVisitUser = (userId: number, firstName: string, middleName: string | undefined, lastName: string, resourceType: string) => {
    if (middleName) {
        return {
            value: userId,
            label: firstName + ' ' + middleName + ' ' + lastName
        }
    } else {
        return {
            value: userId,
            label: firstName + ' ' + lastName
        }
    }
}

export const toSelectOptionsPatientVisitUser = (users: UserSummary[], resourceType: string, excludedUsers: PatientVisitUser[]) => {
    const temp: SelectOption<number>[] = [];
    const excludedUserIds = _.map(excludedUsers, 'userId');
    users.forEach((aUser: UserSummary) => {
        if (!excludedUserIds.includes(aUser.id)) {
            temp.push(createSelectOptionPatientVisitUser(
                aUser.id,
                aUser.firstName,
                aUser.middleName,
                aUser.lastName,
                resourceType
            ));
        }
    });
    return temp;
};

export const toSelectOptionsPatientVisitSingleUser = (coordinators: UserSummary[], providers: UserSummary[], excludedUser: number | undefined) => {
    const temp: SelectOption<number>[] = [];
    coordinators.forEach((u: UserSummary) => {
        // Commented out because of bugs
        // - when removing a user
        // - console warnings because current option won't have a value in this array

        // if (excludedUser != u.id) {
        temp.push({
            value: u.id,
            label: DisplayFullName(u.firstName, u.middleName, u.lastName) + ' (Coordinator)'
        });
        // }
    });
    providers.forEach((u: UserSummary) => {
        // if (excludedUser != u.id) {
        temp.push({
            value: u.id,
            label: DisplayFullName(u.firstName, u.middleName, u.lastName) + ' (Provider)'
        });
        // }
    });
    return temp;
};


export const toBOptionsPatientVisitUserFromUsers = (users: User[], resourceType: string, excludedUsers: PatientVisitUser[]) => {
    const temp: BOption<PatientVisitUser>[] = [];
    const excludedUserIds = _.map(excludedUsers, 'userId');
    users.forEach((aUser: User) => {
        if (!excludedUserIds.includes(aUser.id!)) {
            temp.push(createBOptionPatientVisitUser(
                aUser.id!,
                aUser.firstName,
                aUser.lastName,
                resourceType
            ));
        }
    });
    return temp;
};

export const addBOptionsPatientVisitUser = (existing: BOption<PatientVisitUser>[], patientVisitUser: PatientVisitUser, resourceType: string) => {
    return existing.concat([{
        value: {
            userId: patientVisitUser.userId,
            userName: patientVisitUser.userName,
            resourceType: resourceType,
            effort: 0
        },
        label: patientVisitUser.userName
    }])
};

export const createEmptyUnscheduledPatientVisitsTab = () => {
    return {
        patient: { id: 0, studyId: 0, identifier: '' },
        patientVisit: { id: 0, patientId: 0, dateOfService: new Date() },
        coordinators: [],
        providers: [],
        payment: 0,
        holdback: 0,
        total: 0,
        fromPreviousContract: false
    }
}

export const validateVisitDateOfService = (enteredDate: Date, study: Study, errors: { [key: string]: string }) => {
    const studyStartedDate = getLocalDate(study.started!);

    if (getLocalDate(enteredDate) < studyStartedDate) {
        errors.dateOfService = 'Date of Service cannot occur before the study\'s started date';
    }

    if (getLocalDate(enteredDate) > new Date()) {
        errors.dateOfService = 'Date of Service cannot occur after the current date';
    }
}

export const getDefaultDateOfService = (visitDateOfService: Date | undefined) => {
    return visitDateOfService ? getLocalDate(visitDateOfService) : new Date();
}



export const handleValidateUser = (value: any) => {
    const errors: { [key: string]: string } = {};
    if (value.effort <= 0) { errors.effort = 'Proportion must be greater than 0%' }
    if (value.effort > 100) { errors.effort = 'Proportion cannot be greater than 100%' }
    return errors;
};


export const convertToUserSummaries = (users: User[]) => {
    if (!users) return [];
    const userSummaries: UserSummary[] = [];
    users.forEach((aUser: User) => {
        userSummaries.push({
            id: aUser.id!,
            firstName: aUser.firstName,
            middlename: aUser.middleName!,
            lastName: aUser.lastName,
            active: aUser.active!
        })
    });

    return userSummaries;
};


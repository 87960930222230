import * as React from 'react';
import { useDispatch } from 'react-redux';

import { Card, CardActions, CardContent, CardHeader, Grid, IconButton, Link, Modal, Typography } from '@material-ui/core';
import { Close, Delete, Save } from '@material-ui/icons';
import { Formik, FormikHelpers } from 'formik';
import { BButton, BDatePicker, BForm, BSubmit } from 'mui-bueno';

import { StudyOverviewData } from '../../../../@types';
import { handleErrorResponse } from '../../../../service/utils';
import { updateStartedAndCompleted } from '../../../../service/Study/studyOverview';
import { getLocalDate } from '../../../../common/Utils/utils';
import { showSuccessSnackbar } from '../../../../modules/messageSnackbarReducer';
import { canBeStarted } from '../../../../service/Study/study';
import { useHistory } from 'react-router-dom';

interface Props {
    field: null | 'started' | 'completed',
    setField: any,
    studyData: StudyOverviewData,
    setStudyData: any
    studyId?: number;
}

const StudyOverviewDateModal: React.FC<Props> = props => {
    const { field, setField, studyData, setStudyData, studyId } = props;

    const dispatch = useDispatch();
    const history = useHistory();

    const isStarted: boolean = field === 'started';
    const removeable: boolean = isStarted ? studyData.started !== null && studyData.completed === null : studyData.completed !== null;
    const [startable, setStartable] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (field === 'started' && studyId) {
            canBeStarted(studyId).then(res => {
                setStartable(res.data);
            }).catch(err => {
                handleErrorResponse(err, dispatch);
            })
        }
    }, [field, studyId])

    const dispatcher = useDispatch();

    const handleModalClose = () => {
        setField(null);
    }

    const handleRemove = ({ setErrors }: FormikHelpers<StudyOverviewData>) => {
        const editedData = { ...studyData };
        if (isStarted) {
            editedData.started = null;
        }
        editedData.completed = null;
        handleSubmit(editedData, setErrors);
    }

    const handleFormikSubmit = async (data: StudyOverviewData, { setErrors }: FormikHelpers<StudyOverviewData>) => {
        handleSubmit(data, setErrors);
    }

    const handleSubmit = async (data: StudyOverviewData, setErrors: any) => {
        updateStartedAndCompleted({ ...data, started: data.started, completed: data.completed }).then(res => {
            dispatcher(showSuccessSnackbar('Study details updated'));
            setStudyData(res.data);
            handleModalClose();
            history.go(0);
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                setStatus: setErrors,
                prefix: 'Could not update Study: ',
            });

        });
    }

    const getInitial = () => {
        if (isStarted) {
            if (studyData.started) {
                return { ...studyData, started: getLocalDate(studyData.started) };
            }
            return { ...studyData, started: new Date() };
        }
        if (studyData.completed) {
            studyData.completed = getLocalDate(studyData.completed);
            return { ...studyData };
        }
        return { ...studyData, completed: new Date() };
    }

    return (
        <Modal
            open={!!(field && studyData)}
            onClose={handleModalClose}
        >
            <div className="modal-form">
                <Formik
                    initialValues={getInitial()}
                    onSubmit={handleFormikSubmit}
                >
                    {formikHelpers =>
                        <BForm>
                            <Card className="detail-form sm">
                                <CardHeader
                                    title={isStarted ? 'Set Started Date' : 'Set Completed Date'}
                                    action={
                                        <IconButton color="primary" aria-label="Close" onClick={handleModalClose}>
                                            <Close />
                                        </IconButton>
                                    }
                                />
                                <CardContent>
                                    <Grid container spacing={0} justifyContent='center'>
                                        {!isStarted && !studyData.completed &&
                                            <Grid item xs={10}>
                                                <Typography className='warning-text title'>
                                                    WARNING:
                                                </Typography>
                                                <Typography className='warning-text middle'>
                                                    Once completed, efforts can no longer be added to this study.
                                                </Typography>
                                                <Typography className='warning-text middle'>
                                                    Any further changes must be made by clicking the underlined date next to &quot;Completed&quot;
                                                    in the information panel and clicking the &quot;Remove Completed&quot; button.
                                                </Typography>
                                            </Grid>
                                        }
                                        {isStarted && !startable &&
                                            <>
                                                <Typography className='start-study-warning middle' key={'startable-error'}>
                                                    Some data in this study&apos;s Invoicing tab is incomplete.
                                                </Typography>
                                                <Typography className='warning-text middle' key={'startable-error-suggestion'}>
                                                    Click <Link onClick={() => history.push(`/study/${studyId}/invoicing/receivable/invoices`)} className={'cursor-pointer'}>here</Link> to resolve these issues.
                                                </Typography>
                                            </>
                                        }
                                        <BDatePicker
                                            name={field !== null ? field : ''}
                                            label={isStarted ? 'Started Date' : 'Completed Date'}
                                            placeholder="Date"
                                            required autoFocus
                                            inputVariant='outlined'
                                            disabled={isStarted && !startable}
                                            xs={10}
                                        />
                                    </Grid>
                                </CardContent>
                                <CardActions className="space-between">
                                    {removeable ?
                                        <BButton
                                            startIcon={<Delete />}
                                            variant="contained"
                                            className='delete-color'
                                            onClick={() => handleRemove(formikHelpers)}
                                        >
                                            Remove {isStarted ? 'Start Date' : 'Completed Date'}
                                        </BButton> : <div />
                                    }
                                    <BSubmit
                                        startIcon={<Save />}
                                        variant="contained"
                                        color="primary"
                                        disabled={isStarted && !startable}
                                    >
                                        Save
                                    </BSubmit>
                                </CardActions>
                            </Card>
                        </BForm>
                    }
                </Formik>
            </div>
        </Modal>
    );
}

export default StudyOverviewDateModal;
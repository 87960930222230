import * as React from 'react';

import { Typography } from '@material-ui/core';

import { DisplayTextFormat } from '../../../../common/Utils/DisplayTextFormat';
import StudyAccountSettings from './StudyAccountSettings';

interface Props {
    studyName: string;
    accountId: number | null;
    sponsorId: number | null;
    studyId: number | null;
    signed: boolean;
}

const Accounts: React.FC<Props> = props => { //NOSONAR
    const { studyName, accountId, sponsorId, studyId, signed } = props;

    return (
        <>
            <div className="page-heading">
                <Typography className="title">{DisplayTextFormat(studyName, 64)}</Typography>
            </div>
            <div className="page-body">
                <StudyAccountSettings
                    studyName={studyName}
                    accountId={accountId}
                    sponsorId={sponsorId}
                    studyId={studyId}
                    contractSigned={signed}
                />
            </div>
        </>
    );
}

export default Accounts;
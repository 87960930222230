import * as React from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormikHelpers } from 'formik';
import { Delete, Edit } from '@material-ui/icons';

import { Procedure, ScheduledVisit } from '../../../../@types';
import { INT_MAX } from '../../../../common/Utils/utils';
import { showSuccessSnackbar } from '../../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../../service/utils';
import { createScheduledVisit, deleteScheduledVisit, getScheduledVisitsByContract, reorderScheduledVisits } from '../../../../service/Contract/scheduledVisits';
import SimpleConfirmDelete from '../../../../common/ConfirmDelete/SimpleConfirmDelete';
import useLoggedInUserPermissions from '../../../../common/hooks/useLoggedInUserPermissions';

import DragAndDropTable, { ActionOption, TableColumn } from '../../../../common/DataTable/DragAndDropTable';
import VisitEdit from './VisitEdit';
import { renderVisitCost } from '../../../../common/DataTable/Utils';
import Loading from '../../../../common/Routes/Loading';

interface TableData {
    id: number | null;
    visitName: string | undefined;
    numInsideSequence: number;
    timeFromBaseline: number | undefined;
    scheduleWindow: number | undefined;
    actualCost: JSX.Element;
    sponsorProposal: JSX.Element;
    finalContractCost: JSX.Element;
    overrideCost: number | null | undefined;
    contractId: number;
    procedureIds: number[];
}

const columns: TableColumn[] = [{
    type: 'visitName',
    displayValue: 'Visit Name',
    align: 'left',
    placeholder: 'Name...',
    required: true,
    noSort: true,
    style: { minWidth: 250 },
}, {
    type: 'timeFromBaseline',
    displayValue: 'Time From Baseline (days)',
    align: 'left',
    placeholder: 'Time From Baseline...',
    required: false,
    style: { width: 120 },
    format: 'integer',
    noSort: true
}, {
    type: 'scheduleWindow',
    displayValue: 'Schedule Window (days)',
    align: 'left',
    placeholder: 'Schedule Window...',
    required: false,
    style: { width: 120 },
    format: 'integer',
    noSort: true
}, {
    type: 'actualCost',
    displayValue: 'Actual Cost',
    align: 'right',
    derivedField: true,
    style: { width: 170 },
    format: 'jsx-element',
    noSort: true
}, {
    type: 'sponsorProposal',
    displayValue: 'Sponsor Proposal',
    align: 'right',
    derivedField: true,
    style: { width: 170 },
    format: 'jsx-element',
    noSort: true
}, {
    type: 'finalContractCost',
    displayValue: 'Final Cost',
    align: 'right',
    derivedField: true,
    style: { width: 170 },
    format: 'jsx-element',
    noSort: true
}, {
    type: 'numInsideSequence',
    displayValue: 'Sequence',
    align: 'left',
    derivedField: true,
    hidden: true
}];

const schema = yup.object<Procedure>().shape({
    visitName: yup.string().required('Required'),
    scheduleWindow: yup.number().integer('Need an integer').min(0, 'Min value: 0').max(INT_MAX, 'Max value: ' + INT_MAX),
    timeFromBaseline: yup.number().integer('Need an integer').min(-INT_MAX, 'Min value: -' + INT_MAX).max(INT_MAX, 'Max value: ' + INT_MAX),
});

const emptyScheduledVisit: ScheduledVisit = {
    id: null,
    contractId: 0,
    visitName: '',
    sponsorProposal: 0,
    finalContractCost: 0,
    overrideCost: null,
    actualCost: 0,
    numInsideSequence: 0,
    timeFromBaseline: 0,
    scheduleWindow: 0,
    procedureIds: [],
}

interface State {
    visitList: any[];
}

const initialState: State = {
    visitList: [],
}

interface Action {
    type: 'load' | 'added' | 'edited' | 'deleted';
    payload?: any;
}

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'load':
            return {
                ...state,
                visitList: action.payload,
            }
        case 'added':
            return {
                ...state,
                visitList: [...state.visitList, action.payload]
            }
        case 'edited':
            return {
                ...state,
                visitList: state.visitList.map(el => el.id === action.payload.id ? action.payload : el)
            }
        case 'deleted':
            return {
                ...state,
                visitList: state.visitList.filter(item => item.id !== action.payload)
            }
        default:
            return state;
    }
}

interface Props {
    contractId: number;
    studyId: number;
    divisionId: number;
    readonly: boolean;
}

const Visits: React.FC<Props> = (props) => {
    const { contractId, divisionId, studyId, readonly } = props;
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { visitList } = state;
    const dispatcher = useDispatch();

    const [visit, setVisit] = React.useState<ScheduledVisit>(emptyScheduledVisit);
    const [doneLoading, setDoneLoading] = React.useState<boolean>(false);

    const canCreate = useLoggedInUserPermissions('API_SCHEDULED_VISIT_CREATE', divisionId, studyId);
    const canDelete = useLoggedInUserPermissions('API_SCHEDULED_VISIT_DELETE_BY_ID', divisionId, studyId);
    const canEdit = useLoggedInUserPermissions('API_SCHEDULED_VISIT_UPDATE', divisionId, studyId);
    const canReorder = useLoggedInUserPermissions('API_SCHEDULED_VISIT_REORDER', divisionId, studyId);

    // State management for Edit action
    const [openEdit, setOpenEdit] = React.useState<boolean>(false);
    const [openDelete, setOpenDelete] = React.useState<boolean>(false);

    // State management for the more actions menu
    const [clickedObj, setClickedObj] = React.useState<null | ScheduledVisit>(null);
    // Convert table data back to original object type for forms to accept
    const setClickedObjConversion = (obj: any) => {
        return (
            setClickedObj({
                id: obj.id!,
                contractId: obj.contractId,
                visitName: obj.visitName!,
                sponsorProposal: obj.sponsorProposal.props.value,
                finalContractCost: obj.finalContractCost.props.value,
                overrideCost: obj.overrideCost,
                actualCost: obj.actualCost.props.value,
                numInsideSequence: obj.numInsideSequence,
                timeFromBaseline: obj.timeFromBaseline,
                scheduleWindow: obj.scheduleWindow,
                procedureIds: obj.procedureIds
            })
        )
    }

    // State management to rerender when the list has been reordered
    const [reorderedList, setReorderedList] = React.useState<any[]>([]);

    const handleClickDelete = () => {
        setOpenDelete(true);
    }
    const handleDelete = (selectedObj: null | ScheduledVisit) => {
        if (selectedObj) {
            deleteScheduledVisit(selectedObj.id!)
                .then(res => {
                    dispatch({ type: 'deleted', payload: selectedObj.id });
                    dispatcher(showSuccessSnackbar(`Scheduled Visit ${selectedObj.visitName} deleted`));
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not delete Scheduled Visit: '
                    })
                });
        }
        setOpenDelete(false);
    }

    const handleClickEdit = (): void => {
        setOpenEdit(true);
    }

    const handleEdit = (editedVisit: ScheduledVisit): void => {
        dispatch({ type: 'edited', payload: editedVisit });
    }

    const actions: ActionOption[] = [
        { label: 'Edit', icon: <Edit />, handler: handleClickEdit, permission: canEdit },
        { label: 'Delete', icon: <Delete />, handler: handleClickDelete, permission: canDelete }
    ];

    // Initial load data
    React.useEffect(() => {
        getScheduledVisitsByContract(contractId)
            .then(res => {
                dispatch({ type: 'load', payload: res.data });
                setDoneLoading(true);
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve list of Scheduled Visits: '
                })
            });
    }, []);

    // Rerender the list when it has been reordered
    React.useEffect(() => {
        dispatch({ type: 'load', payload: convertToScheduledVisits(reorderedList) });
    }, [reorderedList]);

    // Form management
    const handleSubmit = async (data: ScheduledVisit, { setErrors, resetForm }: FormikHelpers<ScheduledVisit>) => {
        createScheduledVisit({
            ...data,
            contractId: Number(contractId)
        }).then(res => {
            setVisit(emptyScheduledVisit);
            resetForm();
            dispatch({ type: 'added', payload: res.data });
            dispatcher(showSuccessSnackbar('Scheduled Visit added'));
        }).catch(err => {
            handleErrorResponse(err, dispatch, {
                setStatus: setErrors,
                prefix: 'Could not create Scheduled Visit: '
            });
        });
    };

    const handleReorderVisits = (visitContractId: number, idsList: number[]) => {
        reorderScheduledVisits(visitContractId, idsList).catch(err => {
            handleErrorResponse(err, dispatch, {
                prefix: 'Could not reorder list of Scheduled Visits: '
            })
        });
    };

    const convertTableData = (data: ScheduledVisit[]) => {
        const newData: TableData[] = [];
        data.forEach(obj => {
            const actual = Number(obj.actualCost);
            const proposed = Number(obj.sponsorProposal);
            const override = Number(obj.overrideCost);
            const final = Number(obj.finalContractCost);
            newData.push({
                id: obj.id,
                visitName: obj.visitName,
                numInsideSequence: obj.numInsideSequence,
                timeFromBaseline: obj.timeFromBaseline,
                scheduleWindow: obj.scheduleWindow,
                actualCost: renderVisitCost('actual', actual, proposed, override, final),
                sponsorProposal: renderVisitCost('proposed', actual, proposed, override, final),
                finalContractCost: renderVisitCost('final', actual, proposed, override, final),
                overrideCost: obj.overrideCost,
                contractId: obj.contractId,
                procedureIds: obj.procedureIds ? obj.procedureIds : [],
            })
        })
        return newData;
    };

    const convertToScheduledVisits = (data: TableData[]) => {
        const newList: ScheduledVisit[] = [];
        data.forEach(obj => {
            newList.push({
                id: obj.id!,
                contractId: obj.contractId,
                visitName: obj.visitName!,
                sponsorProposal: obj.sponsorProposal.props.value,
                finalContractCost: obj.finalContractCost.props.value,
                overrideCost: obj.overrideCost,
                actualCost: obj.actualCost.props.value,
                numInsideSequence: obj.numInsideSequence,
                timeFromBaseline: obj.timeFromBaseline,
                scheduleWindow: obj.scheduleWindow,
                procedureIds: obj.procedureIds
            })
        })
        return newList;
    }

    if (doneLoading) {
        return (
            <>
                <DragAndDropTable
                    dataList={convertTableData(visitList)}
                    setReorderedList={setReorderedList}
                    reorderListHandler={handleReorderVisits}
                    tableInfoColumns={columns}
                    editingDataObject={visit}
                    handleSubmit={handleSubmit}
                    validationSchema={schema}
                    readonly={readonly}
                    canCreate={canCreate}
                    clickedObj={clickedObj}
                    setClickedObj={setClickedObjConversion}
                    actionOptions={actions}
                    initialSortType='numInsideSequence'
                    canReorder={canReorder}
                />
                <VisitEdit
                    open={openEdit}
                    setOpen={setOpenEdit}
                    editVisit={clickedObj ?? emptyScheduledVisit}
                    saveEditedVisit={handleEdit}
                />
                <SimpleConfirmDelete
                    open={openDelete}
                    setOpen={setOpenDelete}
                    type='Scheduled Visit'
                    objectName={clickedObj?.visitName ? clickedObj.visitName : ''}
                    handleDelete={() => handleDelete(clickedObj)}
                />
            </>
        )
    } else {
        return (
            <Loading />
        )
    }
}

export default Visits;
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Room } from '@material-ui/icons';

import { handleErrorResponse } from '../../../../../service/utils';
import { getSummaryPatientBySimulator } from '../../../../../service/Simulator/simulator';
import { DEFAULT_SUMMARY_DETAILS, handleSummaryDetailString } from '../../../Utils';
import { Store } from '../../../../../modules/rootReducer';
import { SimulatorState } from '../../../../../modules/simulatorReducer';

import SummaryCard from './SummaryCard';


interface Props {
    simulatorId: number;
}
const TotalPatientsCard: React.FC<Props> = props => {
    const { simulatorId } = props;

    const dispatch = useDispatch();

    const { updated } = useSelector<Store, SimulatorState>(
        store => store.simulatorReducer
    );

    const [value, setValue] = React.useState<number>(0);
    const [details, setDetails] = React.useState<string>(DEFAULT_SUMMARY_DETAILS);

    React.useEffect(() => {
        getSummaryPatientBySimulator(simulatorId)
            .then(res => {
                setValue(res.data.countValue);
                setDetails(handleSummaryDetailString(res.data));
            })
            .catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve Patient count: '
                })
            });
    }, [simulatorId, updated]);

    return (
        <SummaryCard
            title="Total Patients"
            value={value}
            details={details}
            icon={<Room />}
        />
    );
}

export default TotalPatientsCard;
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button, Card, CardActions, CardContent,
    CardHeader, FormControlLabel, FormGroup, Grid,
    IconButton, InputAdornment, Modal, Paper,
    Switch, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Tooltip,
    Typography
} from '@material-ui/core';
import { Beenhere, Close, Input, Save, SystemUpdateAlt } from '@material-ui/icons';
import { BError, BForm, BSubmit, BTextField } from 'mui-bueno';
import { Formik } from 'formik';

import { Calculation, EffortLog, Study, User } from '../../../../@types';
import { DataGrid, DataRow } from '../../../../common/DataTable/Utils';
import {
    calculationToLocalDate, displayDate, effortLogToLocalDate,
    forceDateToUtc, shortMonthString, studyToLocalDate
} from '../../../../common/Utils/utils';
import { DisplayTextFormat } from '../../../../common/Utils/DisplayTextFormat';
import {
    displayStatisticsPercent, displayStatisticsValue,
    NumberFormatter
} from '../../../../common/Utils/NumberFormatter';
import { Store } from '../../../../modules/rootReducer';
import { showSuccessSnackbar } from '../../../../modules/messageSnackbarReducer';
import { CollapsiblePanelState } from '../../../../modules/collapsiblePanelReducer';
import { handleErrorResponse } from '../../../../service/utils';
import { getUserStudies } from '../../../../service/Study/study';
import { getEarliestEffectiveDateByUser } from '../../../../service/Management/salary-logs';
import { getEffortLogsByYear, processMultipleEffortLogs } from '../../../../service/Management/effort-logs';
import { getStudyCalculationTotalByUser, getUserProductivityCalculationsByYear } from '../../../../service/Study/calculations';
import useLoggedInUser from '../../../../common/hooks/useLoggedInUser';
import useLoggedInUserAccess from '../../../../common/hooks/useLoggedInUserAccess';

import YearSelector from '../../../../common/DataTable/YearSelector';


const currentYear: number = new Date().getFullYear();
const currentMonth: number = new Date().getMonth();
const UPDATE: string = 'UPDATE';

function inputNameString(studyId: number, effortLogId: number): string {
    return `percentageEffort-${studyId}-${!effortLogId ? 'new' : effortLogId}`;
}

const EffortLogTable: React.FC<{ user: User, year?: number }> = props => {
    const { user, year } = props;
    const { expanded } = useSelector<Store, CollapsiblePanelState>(
        store => store.collapsiblePanelReducer
    );
    const loggedInUser = useLoggedInUser();
    const history = useHistory();
    const dispatch = useDispatch();

    const permissionGetUserStudies: boolean = useLoggedInUserAccess('API_STUDY_GET_USER_STUDIES', user);
    const permissionGetStudyCalculationTotalByUser: boolean = useLoggedInUserAccess('API_CALCULATION_GET_STUDY_CALCULATION_TOTAL_BY_USER', user);
    const permissionGetEffortLogsByYear: boolean = useLoggedInUserAccess('API_EFFORT_LOG_GET_USER_EFFORT_LOGS_BY_YEAR', user);
    const permissionGetCalculationsByYear: boolean = useLoggedInUserAccess('API_CALCULATION_GET_USER_CALCULATIONS_BY_YEAR', user);
    const permissionGetEarliestEffectiveSalary: boolean = useLoggedInUserAccess('API_SALARY_LOG_GET_EARLIEST_EFFECTIVE_DATE_BY_USER', user);

    const showProductivity: boolean =
        permissionGetStudyCalculationTotalByUser
        && permissionGetCalculationsByYear
        && (user.id !== loggedInUser.id);

    const [studies, setStudies] = React.useState<Study[]>([]);
    const [editYear, setEditYear] = React.useState<number>(year ?? currentYear);
    const [editMonth, setEditMonth] = React.useState<number>(-1);
    const [showCompleted, setShowCompleted] = React.useState<boolean>(false);
    
    const activeStudiesByYear: Study[] = studies.filter(study => 
        study.started! <= new Date(editYear, 11, 31)
        && (!showCompleted ? !study.completed : true)
        && (study.completed == null || study.completed >= new Date(editYear, 0, 1)));
    const activeStudiesByEditMonth: Study[] = studies.filter(study =>
        study.started! < new Date(editYear, editMonth + 1, 1)
        && (!showCompleted ? !study.completed : true)
        && (study.completed == null || study.completed >= new Date(editYear, editMonth, 1)));
    const activeStudiesByMonth = (month: number): Study[] => studies.filter(study =>
        study.started! < new Date(editYear, month + 1, 1)
        && (!showCompleted ? !study.completed : true)
        && (study.completed == null || study.completed >= new Date(editYear, month, 1)));
    
    const [hasPreviousMonthData, setHasPreviousMonthData] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (editMonth === -1) return; // skip check when closing modal
        setHasPreviousMonthData(false);
        for (let i: number = 0; i < activeStudiesByEditMonth.length; i++) {
            const previousEffortLog: EffortLog | undefined = effortLogGrid.getByIndex(activeStudiesByEditMonth[i].id!, editMonth - 1);
            
            if (previousEffortLog && previousEffortLog.percentageEffort > 0)
                setHasPreviousMonthData(true);
        }
    }, [studies, editMonth]);

    const formRef = React.useRef<any>(null);
    const [formValues, setFormValues] = React.useState<{ [key: string]: string }>({});
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [refreshTable, setRefreshTable] = React.useState<boolean>(false);
    const [earliestSalaryDate, setEarliestSalaryDate] = React.useState<Date>(new Date());
    const [hoveredCell, setHoveredCell] = React.useState<[number, number]>([-1, -1]);

    const [effortLogGrid, setEffortLogGrid] = React.useState<DataGrid<EffortLog>>(new DataGrid<EffortLog>());
    const [effortLogTotals, setEffortLogTotals] = React.useState<DataRow<number>>({});
    const [calculationGrid, setCalculationGrid] = React.useState<DataGrid<Calculation>>(new DataGrid<Calculation>());
    const [studyLifetimeTotals, setStudyLifetimeTotals] = React.useState<DataRow<number>>({});

    React.useEffect(() => {
        if (!user.id) return;
        if (permissionGetUserStudies) {
            getUserStudies(user.id)
                .then(res => {
                    setStudies(res.data.map(study => studyToLocalDate(study)));
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not retrieve list of Studies: '
                    });
                })
        }
        if (permissionGetEarliestEffectiveSalary) {
            getEarliestEffectiveDateByUser(user.id)
                .then(res => {
                    setEarliestSalaryDate(forceDateToUtc(new Date(res.data)))
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not retrieve earliest effective salary of user: '
                    });
                })
        }
    }, [user]);

    React.useEffect(() => {
        if (!user.id) return;
        if (showProductivity)
            getStudyCalculationTotalByUser(user.id)
                .then(res => {
                    const tempRow: DataRow<number> = {};
                    res.data.forEach(calculation => {
                        tempRow[calculation.studyId ?? -1] = calculation.value
                    });
                    setStudyLifetimeTotals(tempRow);
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not retrieve Study Calculation data: '
                    });
                });
    }, [user, refreshTable]);

    React.useEffect(() => {
        if (!user.id) return;

        getEffortLogsByYear(user.id, editYear)
            .then(res => {
                const convertedEffortLogs: EffortLog[] = res.data.map(effortLog => effortLogToLocalDate(effortLog));

                const tempGrid: DataGrid<EffortLog> = new DataGrid<EffortLog>();
                const tempSums: DataRow<number> = {};
                convertedEffortLogs.forEach(effortLog => {
                    const index: number = effortLog.studyId ?? -1;
                    const month: number = effortLog.month.getUTCMonth();

                    tempGrid.setByIndex(index, month, effortLog);
                    tempSums[month] = (tempSums[month] ?? 0) + effortLog.percentageEffort;
                });

                getEffortLogsByYear(user.id!, editYear - 1, 11)
                    .then(prevYearRes => {
                        const convertedEffortLogs: EffortLog[] = prevYearRes.data.map(effortLog => effortLogToLocalDate(effortLog));

                        convertedEffortLogs.forEach(effortLog => {
                            const index: number = effortLog.studyId ?? -1;
                            const month: number = -1;

                            tempGrid.setByIndex(index, month, effortLog);
                        });
                    }).catch(err => {
                        handleErrorResponse(err, dispatch, {
                            prefix: 'Could not retrieve list of Effort Logs: '
                        });
                    });

                setEffortLogGrid(tempGrid);
                setEffortLogTotals(tempSums);
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve list of Effort Logs: '
                });
            });

        if (showProductivity)
            getUserProductivityCalculationsByYear(user.id, editYear)
                .then(res => {
                    const convertedCalculations: Calculation[] = res.data.map(calculation => calculationToLocalDate(calculation));

                    const tempGrid: DataGrid<Calculation> = new DataGrid<Calculation>();
                    convertedCalculations.forEach(calculation => {
                        const index: number = calculation.studyId ?? -1;
                        const month: number = calculation.associatedDate.getUTCMonth();

                        tempGrid.setByIndex(index, month, calculation);
                    });
                    setCalculationGrid(tempGrid);
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not retrieve list of Calculations: '
                    });
                });
    }, [user, editYear, refreshTable]);

    const toggleFormUpdate = (): string => (!formValues[UPDATE] || formValues[UPDATE] === '0') ? '1' : '0';

    React.useEffect(() => {
        const temp: { [key: string]: string } = {};
        activeStudiesByEditMonth.map(study => {
            const effortLog: EffortLog | undefined = effortLogGrid.getByIndex(study.id ?? -1, editMonth);
            const effortLogId: number = effortLog?.id ?? 0;
            const percentageEffort: number = effortLog?.percentageEffort ?? 0;

            const nameString: string = inputNameString(study.id ?? -1, effortLogId);

            temp[nameString] = (!effortLogId || !percentageEffort) ? '' : percentageEffort.toString();
        });
        setFormValues({
            ...temp,
            [UPDATE]: toggleFormUpdate()
        });
    }, [editMonth]);

    const applyCarryOver = (): void => {
        const temp: { [key: string]: string } = {};

        for (const key in formRef.current.values) {
            const studyId: number = +key.split('-')[1];
            const previousEffortLog: EffortLog | undefined = effortLogGrid.getByIndex(studyId, editMonth - 1);
            const percentageEffort: number = previousEffortLog?.percentageEffort ?? 0;

            temp[key] = !percentageEffort ? '' : percentageEffort.toString();
        }

        setFormValues({
            ...temp,
            [UPDATE]: toggleFormUpdate()
        });
    };

    const applySuggestedValue = (): void => {
        const temp: { [key: string]: string } = {};

        for (const key in formRef.current.values) {
            const studyId: number = +key.split('-')[1];
            const effortLog: EffortLog | undefined = effortLogGrid.getByIndex(studyId, editMonth);
            const percentageEffort: number = effortLog?.effortSuggestion ?? 0;

            temp[key] = !percentageEffort ? '' : percentageEffort.toString();
        }

        setFormValues({
            ...temp,
            [UPDATE]: toggleFormUpdate()
        });
    };

    const handleModalOpen = (month: number) => (): void => {
        setEditMonth(month);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setEditMonth(-1);
        setFormValues({});
    };

    const handleValidate = async (data: { [key: string]: string }) => {
        const errorList: { [k: string]: string } = {};
        let updatedEffortLog: boolean = false;
        let effortSum: number = 0;
        for (const key in data) {
            if (key === UPDATE) continue;

            if (+data[key] > 0 || key.split('-')[2] != 'new')
                updatedEffortLog = true;

            if (isNaN(+data[key]))
                errorList[key] = 'Logged Percent Effort must be a numeric value';
            if (+data[key] < 0)
                errorList[key] = 'Logged Percent Effort cannot be negative';
            if (data[key] && parseInt(data[key]) !== +data[key])
                errorList[key] = 'Logged Percent Effort must be a whole number';

            if (effortSum <= 100) {
                effortSum += +data[key];

                if (effortSum > 100)
                    errorList['general'] = 'Total logged Percent Effort cannot exceed 100% for a single month';
            }
        }

        if (!updatedEffortLog)
            errorList['general'] = 'No entered value';

        return errorList;
    };

    const handleSubmit = async (data: { [key: string]: string }) => {
        const requestBody: EffortLog[] = [];

        for (const key in data) {
            if (key === UPDATE) continue;

            const value: number = +data[key];
            const studyId: number = +key.split('-')[1];
            const effortLog: EffortLog | undefined = effortLogGrid.getByIndex(studyId, editMonth);

            if (!effortLog && value > 0) {
                requestBody.push({
                    id: null,
                    userId: user.id ?? null,
                    studyId: studyId,
                    month: new Date(editYear, editMonth),
                    percentageEffort: value,
                    effortSuggestion: 0
                });
            } else if (effortLog) {
                requestBody.push({
                    ...effortLog,
                    percentageEffort: value
                });
            }
        }

        if (requestBody.length === 0) return; // No EffortLogs to process

        processMultipleEffortLogs(requestBody)
            .then(res => {
                dispatch(showSuccessSnackbar(`Effort for ${shortMonthString(editMonth, editYear)} ${editYear} updated`));
                handleModalClose();
                setRefreshTable(!refreshTable);
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not process Effort Log(s): '
                });
            });
    };

    // Viewing productivity is reserved for division admins, who should be allowed to edit effort without the 3 month restriction
    const handleDisableMonth = (canViewProductivity: boolean, checkYear: number, checkMonth: number): boolean => {
        // First check if there is an effective salary log for the current month
        // If there is no salary information for that month, disable the input
        const noEffectiveSalary: boolean = ((new Date(checkYear, checkMonth + 1, 1)).getTime() < earliestSalaryDate.getTime());
        if (noEffectiveSalary) return true;
        
        const isFutureMonth: boolean = (checkYear === currentYear && checkMonth > currentMonth);
        if (canViewProductivity) {
            // Disabled months should be all future months
            return isFutureMonth;
        } else {
            // Disabled months should be any that are not the current month and the 2 preceding months
            // If the current month is March (2) or later, don't worry about going to the previous year
            if (currentMonth >= 2) {
                return (isFutureMonth || checkYear !== currentYear || checkMonth < (currentMonth - 2));
            } else {
                // Determine how many months from the previous year to allow
                // i.e. if current month is January (0), we need November and December = 2 months
                const prevYearMonths: number = 2 - currentMonth;
                return (isFutureMonth || checkYear < (currentYear - 1) || (checkYear === (currentYear - 1) && checkMonth <= (11 - prevYearMonths)) || checkMonth < (currentMonth - prevYearMonths));
            }
        }
    }

    return (
        <>
            {permissionGetUserStudies && permissionGetEffortLogsByYear &&
                <>
                    <div className="effort-log-table-header">
                        <div className="row left">
                            <FormGroup style={{ paddingLeft: '2vw' }}>
                                <FormControlLabel
                                    control={<Switch checked={showCompleted} onChange={((event: any, value: boolean) => setShowCompleted(value))} />}
                                    label="Show Completed Studies"
                                />
                            </FormGroup>
                        </div>
                        <YearSelector year={editYear} setYear={setEditYear} />
                    </div>
                    <Card className={'page-width ' + (expanded && 'constrain-expanded')}>
                        <CardContent>
                            <TableContainer component={Paper}>
                                <Table stickyHeader className="effort-log-table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="header-cell background sticky" />
                                            {showProductivity &&
                                                <TableCell className="header-cell productivity" data-left>
                                                    Total<br />Productivity
                                                </TableCell>
                                            }
                                            {Array.from(Array(12).keys()).map(month => {
                                                const disabled: boolean = handleDisableMonth(showProductivity, editYear, month);
                                                return (
                                                    <Tooltip
                                                        key={month + '-header'}
                                                        title={!disabled ? `Log Effort for ${shortMonthString(month, editYear)} ${editYear}` : ''}
                                                        placement="top"
                                                        arrow
                                                    >
                                                        <TableCell
                                                            className="header-cell"
                                                            data-highlight={hoveredCell[0] === month}
                                                        >
                                                            <Button
                                                                onClick={handleModalOpen(month)}
                                                                disabled={disabled}
                                                                className="header-button"
                                                            >
                                                                {shortMonthString(month, editYear)}<br />{editYear}
                                                            </Button>
                                                        </TableCell>
                                                    </Tooltip>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="header-cell effort sticky" data-left>
                                                Total Logged Effort
                                            </TableCell>
                                            {showProductivity && <TableCell className="data-cell unused" />}
                                            {Array.from(Array(12).keys()).map(month => {
                                                const value: number = effortLogTotals[month] ?? 0;
                                                const disabled: boolean = handleDisableMonth(showProductivity, editYear, month);
                                                return (
                                                    <Tooltip arrow key={`header-${month}`} title={disabled ? '' : `Total Logged Effort for ${shortMonthString(month, editYear)} ${editYear}`}>
                                                        <TableCell
                                                            className="data-cell"
                                                            data-disabled={disabled}
                                                        >
                                                            {displayStatisticsPercent(value / 100)}
                                                        </TableCell>
                                                    </Tooltip>
                                                )
                                            })}
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={showProductivity ? 14 : 13} className="row-separator" />
                                        </TableRow>
                                        {activeStudiesByYear.map((study, index) => {
                                            const tooltip = <div className="column tip-padding">
                                                <span> {study.name} </span>
                                                {study.started && <span> Started: {displayDate(study.started)} </span>}
                                                {study.completed && <span> Completed: {displayDate(study.completed)} </span>}
                                            </div>;
                                            return (
                                                <React.Fragment key={'effort-log-study-' + study.id}>
                                                    <TableRow>
                                                        <Tooltip title={tooltip}>
                                                            <TableCell
                                                                className="header-cell sticky"
                                                                rowSpan={showProductivity ? 2 : 1}
                                                                data-index={index}
                                                                data-highlight={hoveredCell[1] === study.id}
                                                            >
                                                                <Button
                                                                    onClick={() => history.push(`/study/${study.id}/statistics/${editYear}`)}
                                                                    className="header-button"
                                                                    endIcon={study.completed && <Beenhere />}
                                                                >
                                                                    {DisplayTextFormat(study.name, 24, false)}
                                                                </Button>
                                                            </TableCell>
                                                        </Tooltip>
                                                        {showProductivity && <TableCell className="data-cell unused" />}
                                                        {Array.from(Array(12).keys()).map(month => {
                                                            const effortLog: EffortLog | undefined = effortLogGrid.getByIndex(study.id ?? -1, month);
                                                            const percentageEffort: number = effortLog?.percentageEffort ?? 0;
                                                            const effortSuggestion: number = effortLog?.effortSuggestion ?? 0;

                                                            const valueHigher: boolean = !!effortSuggestion && (percentageEffort > effortSuggestion);
                                                            const valueLower: boolean = !!effortSuggestion && (percentageEffort < effortSuggestion);
                                                            const showWarning: boolean = (valueHigher || valueLower);
                                                            const tooltipBox = !!effortSuggestion &&
                                                                <div className="column tip-padding">
                                                                    <span>Suggested Effort: {displayStatisticsPercent(effortSuggestion / 100)}</span>
                                                                    {(valueHigher || valueLower) &&
                                                                        <span>{`Logged Effort value is ${valueHigher ? 'higher' : 'lower'} than the suggested value`}</span>}
                                                                </div>;

                                                            const disabled: boolean = handleDisableMonth(showProductivity, editYear, month)
                                                                || (study.completed && (new Date(editYear, month, 1) >= study.completed))
                                                                || !study.started
                                                                || (study.started && study.started >= new Date(editYear, month+1, 1));
                                                            const studyActive: boolean = activeStudiesByMonth(month).includes(study);

                                                            return (
                                                                <Tooltip arrow key={`data-cell-${month}-${study.id}`} title={(effortSuggestion) ? tooltipBox : ''} enterDelay={250} >
                                                                    <TableCell
                                                                        className="data-cell button"
                                                                        data-disabled={disabled}
                                                                        onMouseEnter={() => !disabled && setHoveredCell([month, study.id ?? -1])}
                                                                        onMouseLeave={() => setHoveredCell([-1, -1])}
                                                                    >
                                                                        {(disabled || !studyActive)
                                                                            ? <span style={{ paddingRight: '5px' }}>
                                                                                {showWarning && <span style={{ paddingRight: '0.25rem' }}>*</span>}
                                                                                {displayStatisticsPercent(percentageEffort / 100)}
                                                                            </span>
                                                                            : <Button onClick={handleModalOpen(month)}>
                                                                                {showWarning && <span style={{ paddingRight: '0.25rem' }}>*</span>}
                                                                                {displayStatisticsPercent(percentageEffort / 100)}
                                                                            </Button>
                                                                        }
                                                                    </TableCell>
                                                                </Tooltip>
                                                            )
                                                        })}
                                                    </TableRow>
                                                    {showProductivity &&
                                                        <TableRow>
                                                            <Tooltip arrow title={`${study.name}: Total Lifetime Productivity`}>
                                                                <TableCell className="data-cell">
                                                                    {displayStatisticsValue(studyLifetimeTotals[study.id ?? -1])}
                                                                </TableCell>
                                                            </Tooltip>
                                                            {Array.from(Array(12).keys()).map(month => {
                                                                const calculation: Calculation | undefined = calculationGrid.getByIndex(study.id ?? -1, month);
                                                                const value: number = calculation?.value ?? 0;
                                                                const disabled: boolean = handleDisableMonth(showProductivity, editYear, month)
                                                                    || (study.completed && (new Date(editYear, month, 1) >= study.completed))
                                                                    || !study.started
                                                                    || (study.started && study.started >= new Date(editYear, month+1, 1));
                                                                return (
                                                                    <Tooltip
                                                                        key={`productivity-cell-${month}`}
                                                                        title={disabled ? '' : `Productivity for ${shortMonthString(month, editYear)} ${editYear}`}
                                                                    >
                                                                        <TableCell
                                                                            className="data-cell button"
                                                                            data-disabled={disabled}
                                                                            onMouseEnter={() => !disabled && setHoveredCell([month, study.id ?? -1])}
                                                                            onMouseLeave={() => setHoveredCell([-1, -1])}
                                                                        >
                                                                            <span style={{ paddingRight: '5px' }}>{displayStatisticsValue(value)}</span>
                                                                        </TableCell>
                                                                    </Tooltip>
                                                                )
                                                            })}
                                                        </TableRow>
                                                    }
                                                </React.Fragment>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                                {(activeStudiesByYear.length === 0) && <Typography variant="body1" className="flex-center margin-2">No data to display. User is not assigned to any active Studies in {editYear.toString()}.</Typography>}
                            </TableContainer>
                        </CardContent>
                    </Card>
                    <Modal
                        open={modalOpen && (editMonth >= 0) && formValues != {}}
                        onClose={handleModalClose}
                    >
                        <div className="modal-form">
                            <Formik
                                innerRef={formRef}
                                onSubmit={handleSubmit}
                                validate={handleValidate}
                                validateOnChange={false}
                                initialValues={formValues}
                                enableReinitialize
                            >
                                <BForm>
                                    <Card className="effort-log-form sm">
                                        <CardHeader
                                            title={<>
                                                Log Effort for {shortMonthString(editMonth, editYear)} {editYear}
                                                <BError id="general" name="general" />
                                            </>}
                                            action={
                                                <IconButton
                                                    color="primary"
                                                    aria-label="Close"
                                                    onClick={handleModalClose}
                                                >
                                                    <Close />
                                                </IconButton>
                                            }
                                        />
                                        <CardContent>
                                            <>{hasPreviousMonthData && 'hasPreviousMonthData'}</>
                                            <Grid container className="aligned-row">
                                                {hasPreviousMonthData && <Grid item xs={3}>
                                                    <Tooltip
                                                        title={`Carry over ${shortMonthString(editMonth - 1, editYear)} ${((editMonth - 1) < 0) ? editYear - 1 : editYear} Logged Effort`}
                                                        placement="top"
                                                        arrow
                                                    >
                                                        <Button
                                                            endIcon={<Input />}
                                                            onClick={() => applyCarryOver()}
                                                        >
                                                            {shortMonthString(editMonth - 1, editYear)}&nbsp;{((editMonth - 1) < 0) ? editYear - 1 : editYear}
                                                        </Button>
                                                    </Tooltip>
                                                </Grid>}
                                                <Grid item xs={hasPreviousMonthData ? 9 : 12} style={{ paddingLeft: '10px' }} className="aligned-row">
                                                    <Tooltip
                                                        title={'Apply all suggested Effort values'}
                                                        placement="top"
                                                        arrow
                                                    >
                                                        <Button
                                                            endIcon={<SystemUpdateAlt />}
                                                            onClick={() => applySuggestedValue()}
                                                        >
                                                            {shortMonthString(editMonth, editYear)}&nbsp;{editYear}
                                                        </Button>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                            <Grid container className="form-body">
                                                {activeStudiesByEditMonth.map(study => {
                                                    const effortLog: EffortLog | undefined = effortLogGrid.getByIndex(study.id ?? -1, editMonth);
                                                    const previousMonthEffortLog: EffortLog | undefined = effortLogGrid.getByIndex(study.id ?? -1, editMonth - 1);
                                                    const effortLogId: number = effortLog?.id ?? 0;
                                                    const suggestedValue: number = effortLog?.effortSuggestion ?? 0;

                                                    const nameString: string = inputNameString(study.id ?? -1, effortLogId);

                                                    return (
                                                        <div
                                                            key={`edit-percentageEffort-${shortMonthString(editMonth, editYear)}-${study.id}-${effortLogId}`}
                                                            className="aligned-row"
                                                        >
                                                            {hasPreviousMonthData && <Grid item xs={3}>
                                                                <div style={{ height: '100%', padding: '10px' }}>
                                                                    {displayStatisticsPercent((previousMonthEffortLog?.percentageEffort ?? 0) / 100)}
                                                                </div>
                                                            </Grid>}
                                                            <Grid item xs={hasPreviousMonthData ? 9 : 12}>
                                                                <BTextField
                                                                    id={nameString}
                                                                    name={nameString}
                                                                    label={`Percent Effort for ${study.name}`}
                                                                    placeholder={suggestedValue ? `Suggested Value: ${suggestedValue}` : 'No Suggested Value'}
                                                                    InputLabelProps={{ shrink: true }}
                                                                    InputProps={{
                                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                                        inputComponent: NumberFormatter as any,
                                                                        inputProps: { integer: true }
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </div>
                                                    );
                                                })}
                                            </Grid>
                                        </CardContent>
                                        <CardActions className="flex-end">
                                            <BSubmit
                                                startIcon={<Save />}
                                                variant="contained"
                                                color="primary"
                                            >
                                                Save
                                            </BSubmit>
                                        </CardActions>
                                    </Card>
                                </BForm>
                            </Formik>
                        </div>
                    </Modal>
                </>
            }
        </>
    );
}

export default EffortLogTable;

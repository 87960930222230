import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Grid } from '@material-ui/core';
import { AssignmentReturned, CloudDownload, LockOpen, LockOutlined } from '@material-ui/icons';
import { BSubmit } from 'mui-bueno';

import useLoggedInUserPermissions from '../../../../common/hooks/useLoggedInUserPermissions';
import { handleErrorResponse } from '../../../../service/utils';
import { downloadTemplate } from '../../../../service/Contract/schedule';

import BulkEntryModal from './BulkEntryModal';


interface Props {
    contractId: number;
    studyId: number;
    divisionId: number;
    readonly: boolean;
    unlockCells?: boolean;
    handleUnlockCell?: () => void;
    setUploaded: any;
}

const ScheduleOfEventsActions: React.FC<Props> = props => {
    const { contractId, studyId, divisionId, readonly, unlockCells, handleUnlockCell, setUploaded } = props;

    const dispatch = useDispatch();

    const [bulkEntryOpen, setBulkEntryOpen] = React.useState<boolean>(false);

    const isAllowedBulkEntry = useLoggedInUserPermissions('API_SCHEDULED_VISIT_CREATE_BULK_ENTRY', divisionId, studyId);

    const handleDownload = () => {
        downloadTemplate()
            .then(res => {
                const blob = new Blob([res.data]);
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.setAttribute('download', 'entry-template.csv');
                link.click();
                URL.revokeObjectURL(link.href);
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve Template: '
                })
            });
    };

    return (
        <>
            {!readonly && isAllowedBulkEntry &&
                <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                        {unlockCells ? 
                            <BSubmit
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<LockOutlined />}
                            >
                                Lock cells
                            </BSubmit> :
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                startIcon={<LockOpen />}
                                onClick={handleUnlockCell}
                            >
                                Unlock cells
                            </Button>
                        }
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={<CloudDownload />}
                            onClick={handleDownload}
                        >
                            Entry Template
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={<AssignmentReturned />}
                            onClick={() => setBulkEntryOpen(true)}
                        >
                            Bulk Entry
                        </Button>
                    </Grid>
                </Grid>
            }

            <BulkEntryModal open={bulkEntryOpen} setOpen={setBulkEntryOpen} contractId={contractId} setUploaded={setUploaded}/>

        </>
    )
}

export default ScheduleOfEventsActions;
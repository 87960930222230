import * as React from 'react';
import { useDispatch } from 'react-redux';
import {
    useHistory,
    useParams
} from 'react-router-dom';
import {
    Grid,
    Typography
} from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import { BButton } from 'mui-bueno';

import { User } from '../../../@types';
import { getUser } from '../../../service/Management/users';
import { handleErrorResponse } from '../../../service/utils';
import usePermissions from '../../../common/hooks/usePermissions';
import GeneralInformation from './GeneralInformation/GeneralInformation';
import SalaryInformation from './SalaryInformation/SalaryInformation';
import UserRoleInformation from './UserRoleInformation/UserRoleInformation';
import UserStatistics from './Statistics/UserStatistics';


interface RouteParams {
    id: string; // User ID being accessed
    option: string; // Either 'details' or 'statistics'
    optionId?: string; // the year to be displayed on the UserStatistics page
}

const UserDetail: React.FC = () => { const { id, option = 'details', optionId } = useParams<RouteParams>();
    const history = useHistory();
    const dispatch = useDispatch();

    const permissionGetUserStudies: boolean = usePermissions('API_STUDY_GET_USER_STUDIES');
    const permissionGetEffortLogsByYear: boolean = usePermissions('API_EFFORT_LOG_GET_USER_EFFORT_LOGS_BY_YEAR');

    const [user, setUser] = React.useState<User>({
        id: undefined,
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        password: '',
        roleIds: [],
        divisionIds: [],
        active: true
    });

    React.useEffect(() => {
        getUser(Number(id)).then(res => {
            setUser(res.data);
        }).catch(err => {
            handleErrorResponse(err, dispatch, {
                prefix: 'Could not retrieve User: '
            });
            history.push('/manage/user');
        });
    }, [id]);

    return (
        <>
            <div className="page-heading">
                <Typography
                    className="title inactive cursor-pointer"
                    onClick={() => history.push('/manage/user')}
                >
                    User Management
                </Typography>
                <NavigateNext className="arrow" />
                <Typography className="title">{user.firstName + ' ' + user.lastName}</Typography>
            </div>
            <div className="page-body max-xl">
                <div className="user-detail-option-header">
                    <BButton
                        className={option === 'details' ? 'selected-user-detail-option-tab' : 'not-selected-user-detail-option-tab'}
                        onClick={() => history.push(`/manage/user/${id}/details`)}
                    >
                        Details
                    </BButton>
                    {permissionGetUserStudies && permissionGetEffortLogsByYear &&
                        <BButton
                            className={option === 'statistics' ? 'selected-user-detail-option-tab' : 'not-selected-user-detail-option-tab'}
                            onClick={() => history.push(`/manage/user/${id}/statistics`)}
                        >
                            Statistics
                        </BButton>
                    }
                </div>
                <div className="user-detail-main">
                    {id && option === 'details' &&
                        <Grid container spacing={2}>
                            <Grid item lg={7} md={7} sm={12} xs={12}>
                                <GeneralInformation user={user} setUser={setUser} />
                            </Grid>
                            <Grid item lg={5} md={5} sm={12} xs={12}>
                                <SalaryInformation user={user} />
                                <br />
                                <UserRoleInformation user={user} />
                            </Grid>
                        </Grid>
                    }
                    {id && option === 'statistics' &&
                        <UserStatistics user={user} year={optionId ? +optionId : undefined} />
                    }
                </div>
            </div>
        </>
    )
}

export default UserDetail;

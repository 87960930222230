import axiosInstance from '../Access/axiosInstance';
import { Procedure } from '../../@types';
import { handleError } from '../utils';

/**
 * Updates a procedure
 */
export const updateProcedure = (procedure: Procedure) =>
    axiosInstance.put<Procedure>(
        '/api/procedures', procedure
    ).catch(handleError);

/**
 * Creates a procedure
 */
export const createProcedure = (procedure: Procedure) =>
    axiosInstance.post<Procedure>(
        '/api/procedures', procedure
    ).catch(handleError);

/**
 * Deletes a procedure
 */
export const deleteProcedure = (id: number) =>
    axiosInstance.delete(
        `/api/procedures/${id}`
    ).catch(handleError);

/**
 * Retrieves a procedure by id
 */
export const getProcedure = (id: number) =>
    axiosInstance.get<Procedure>(
        `/api/procedures/${id}`
    ).catch(handleError);

/**
 * Reorder the list of procedures
 */
export const reorderProcedures = (contractId: number, procedureIds: number[]) =>
    axiosInstance.put(
        `/api/procedures/reorder/contract/${contractId}`, procedureIds
    ).catch(handleError);
import {
    CreateSimulatorEmployee, Simulator, SimulatorEmployee, SimulatorEvent, SimulatorHealthCombined,
    SimulatorPatient, SimulatorResource, SimulatorResourceResult, SimulatorSalaryLog,
    SimulatorSummaryResult, SimulatorUser, SimulatorUserWithSalaryLogs, Study,
    SimulatorDefaultEffort, SimulatorEffortLog, SimulatorEffortLogResult
} from '../../@types';
import axiosInstance from '../Access/axiosInstance';
import { handleError } from '../utils';
import { CreateSimulator } from '../../features/Simulator/List/AddSimulatorModal';

/**
 * Gets a simulator by ID
 */
export const getSimulator = (simulatorId: number) =>
    axiosInstance.get<Simulator>(
        `/api/simulator/${simulatorId}`
    ).catch(handleError);

/**
 * Gets all simulators in division provided, gets all simulators for all divisions if passed null
 */
export const getAllByDivision = (divisionId: number | null) =>
    axiosInstance.get<Simulator[]>(
        `/api/simulator/division${divisionId ? `?divisionId=${divisionId}` : ''}`
    ).catch(handleError);

/**
 * Get simulator's patients by simulator ID
 */
export const getSimulatorPatients = (simulatorId: number) =>
    axiosInstance.get<SimulatorPatient[]>(
        `/api/simulator/simulatorPatients/${simulatorId}`
    ).catch(handleError);

/**
 * Updates a simulator patient's first visit date
 */
export const updatePatientFirstVisit = (simulatorPatientId: number, newDate: Date) =>
    axiosInstance.put<Simulator>(
        `/api/simulator/firstVisit/${simulatorPatientId}`, newDate
    ).catch(handleError);


/**
 * Creates a simulator
 */
export const createSimulator = (simulator: CreateSimulator) =>
    axiosInstance.post<Simulator>(
        '/api/simulator', simulator
    ).catch(handleError);

/**
 * Updates a simulator
 */
export const updateSimulator = (simulator: Simulator, recalculate: boolean | null) =>
    axiosInstance.put<Simulator>(
        `/api/simulator${recalculate ? `?recalculate=${recalculate}` : ''}`, simulator
    ).catch(handleError);

/**
 * Creates both a SimulatorEmployee and a SimulatorSalaryLog for the new Employee
 */
export const createDetailedEmployee = (createSimulatorEmployee: CreateSimulatorEmployee) => 
    axiosInstance.post<CreateSimulatorEmployee>(
        '/api/simulator/detailedEmployee', createSimulatorEmployee
    ).catch(handleError);

/**
 * Copies User data for an existing Employee into the specififed Simulator
 */
export const importExistingUser = (simulatorId: number, userId: number) =>
    axiosInstance.post<SimulatorEmployee>(
        `/api/simulator/importEmployee/${simulatorId}/${userId}`
    ).catch(handleError);

/**
 * Gets total Productivity for the lifetime of a Simulator or a specified fiscal year
 * @param simulatorId Identifies the Simulator to retrieve data for
 * @param fiscalYear If set, only the Productivity sum for the specified year will be returned
 */
export const getProductivityBySimulator = (simulatorId: number, fiscalYear?: number) =>
    axiosInstance.get<SimulatorSummaryResult>(
        `/api/simulator/summary/productivity/${simulatorId}`,
        {
            params: {
                fiscalYear: fiscalYear
            }
        }
    ).catch(handleError);

/**
 * Gets total Patient count for the lifetime of a Simulator
 * @param simulatorId Identifies the Simulator to retrieve data for
 */
export const getSummaryPatientBySimulator = (simulatorId: number) =>
    axiosInstance.get<SimulatorSummaryResult>(
        `/api/simulator/summary/patient/${simulatorId}`
    ).catch(handleError);

/**
 * Gets total Employee count for the lifetime of a Simulator
 * @param simulatorId Identifies the Simulator to retrieve data for
 */
export const getSummaryEmployeeBySimulator = (simulatorId: number) =>
    axiosInstance.get<SimulatorSummaryResult>(
        `/api/simulator/summary/employee/${simulatorId}`
    ).catch(handleError);

/**
 * Gets monthly resource requirements by year
 * Create a simulator resource
 */
export const createSimulatorResource = (resource: SimulatorResource) =>
    axiosInstance.post<SimulatorResource>(
        '/api/simulator/simulatorResources', resource
    ).catch(handleError);

/**
 * Updates a simulator resource
 */
export const updateSimulatorResource = (resource: SimulatorResource, recalculate: boolean | null) =>
    axiosInstance.put<SimulatorResource>(
        `/api/simulator/simulatorResources${recalculate ? `?recalculate=${recalculate}` : ''}`, resource
    ).catch(handleError);

/**
 * Gets all the resources from a simulator
 */
export const getSimulatorResources = (simId: number) =>
    axiosInstance.get<SimulatorResource[]>(
        `/api/simulator/simulatorResources/${simId}`
    ).catch(handleError);

/* 
 * Gets montly resource requirements by year
 */
export const getMonthlyResourceResultByYear = (simId: number, year: number) =>
    axiosInstance.get<SimulatorResourceResult>(
        `/api/simulator/resource/monthly/${simId}`,
        {
            params: {
                year: year
            }
        }
    ).catch(handleError);

/*
 * Retrieves actual and simulated Calculation data for the Simulator StudyHealth chart
 * @param simulatorId id of the requested Simulator
 * @param year year to filter Calculation data by
 */
export const getMonthlyStudyHealthByYear = (simulatorId: number, year: number) =>
    axiosInstance.get<SimulatorHealthCombined>(
        `/api/simulator/studyHealth/monthly/${simulatorId}`,
        {
            params: {
                year: year
            }
        }
    ).catch(handleError);

/**
 * Creates a new SimulatorSalaryLog
 */
export const createSimulatorSalaryLog = (salaryLog: SimulatorSalaryLog) =>
    axiosInstance.post<SimulatorSalaryLog>(
        '/api/simulator/salaryLog', salaryLog
    ).catch(handleError);

/**
 * Updates an existing SimulatorSalaryLog
 */
export const updateSimulatorSalaryLog = (salaryLog: SimulatorSalaryLog) =>
    axiosInstance.put<SimulatorSalaryLog>(
        '/api/simulator/salaryLog', salaryLog
    ).catch(handleError);

/**
 * Deletes an existing SimulatorSalaryLog
 */
export const deleteSimulatorSalaryLog = (salaryLogId: number) =>
    axiosInstance.delete(
        `/api/simulator/salaryLog/${salaryLogId}`
    ).catch(handleError);

/**
 * Gets detailed employees on a simulation
 */
export const getDetailedEmployees = (simulatorId: number) =>
    axiosInstance.get<SimulatorUserWithSalaryLogs[]>(
        `/api/simulator/detailedEmployees/${simulatorId}`
    ).catch(handleError);

/**
 * Updates a detailed employee on a simulation
 */
export const updateDetailedEmployee = (simulatorUser: SimulatorUser) =>
    axiosInstance.put<SimulatorUser>(
        '/api/simulator/detailedEmployee', simulatorUser
    ).catch(handleError);

/**
 * Removes a detailed employee from a simulation
 */
export const removeDetailedEmployee = (simulatorUserId: number) =>
    axiosInstance.delete(
        `/api/simulator/detailedEmployee/${simulatorUserId}`
    ).catch(handleError);

/**
 * Sets simulator employee variables mode to "Generic"
 */
export const setEmployeeGeneric = (simulatorId: number, generic: boolean) =>
    axiosInstance.put<Simulator>(
        `/api/simulator/employeeGeneric/${simulatorId}?employeeGeneric=${generic}`
    ).catch(handleError);

/**
 * Returns a list of Employee and Patient SimulatorEvents for a selected Simulator and year
 */
export const getSimulatorEvents = (simulatorId: number, year: number) =>
    axiosInstance.get<SimulatorEvent[]>(
        `/api/simulator/events/${simulatorId}/${year}`
    ).catch(handleError);

/**
 * Retrieves the average salary for the designated resource type
 */
export const getAverageSalary = (simulatorId: number, resourceType: string) =>
    axiosInstance.get<number>(
        `/api/simulator/averageSalary/${simulatorId}?resourceType=${resourceType}`
    ).catch(handleError);

/**
 * Retrieves the average fringe for the designated resource type
 */
export const getAverageFringe = (simulatorId: number, resourceType: string) =>
    axiosInstance.get<number>(
        `/api/simulator/averageFringe/${simulatorId}?resourceType=${resourceType}`
    ).catch(handleError);

/**
 * Retrieves the restricted studies for creating simulator
 */
export const getRestrictedSimulatorStudies = () =>
    axiosInstance.get<Study[]>(
        '/api/simulator/studies'
    ).catch(handleError);

/**
 * Retrieves the list of default effort values on a simulator
 */
export const getDefaultEfforts = (simulatorId: number) =>
    axiosInstance.get<SimulatorDefaultEffort[]>(
        `/api/simulator/defaultEffort/${simulatorId}`
    ).catch(handleError);

/**
 * Creates a default effort log for an employee on a simulator
 */
export const createDefaultEffort = (effort: SimulatorDefaultEffort) =>
    axiosInstance.post<SimulatorDefaultEffort>(
        `/api/simulator/defaultEffort`, effort
    ).catch(handleError);

/**
 * Updates a default effort log for an employee on a simulator
 */
export const updateDefaultEffort = (effort: SimulatorDefaultEffort) =>
    axiosInstance.put<SimulatorDefaultEffort>(
        `/api/simulator/defaultEffort`, effort
    ).catch(handleError);

/**
 * Retrieves the list of effort log values on a simulator
 */
export const getEffortLogs = (simulatorId: number, year: number) =>
    axiosInstance.get<SimulatorEffortLogResult>(
        `/api/simulator/effortLog/${simulatorId}?year=2022`
    ).catch(handleError);

/**
 * Creates an effort log for an employee on a simulator
 */
export const createEffortLog = (effort: SimulatorEffortLog) =>
    axiosInstance.post<SimulatorDefaultEffort>(
        `/api/simulator/effortLog`, effort
    ).catch(handleError);

/**
 * Updates an effort log for an employee on a simulator
 */
export const updateEffortLog = (effort: SimulatorEffortLog) =>
    axiosInstance.put<SimulatorDefaultEffort>(
        `/api/simulator/effortLog`, effort
    ).catch(handleError);


/**
 * Updates calculations in simulator
 */
export const recalculateCost = (simulatorId: number) =>
    axiosInstance.put<SimulatorDefaultEffort>(
        `/api/simulator/recalculateCost/${simulatorId}`
    ).catch(handleError);
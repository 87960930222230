
import * as React from 'react'
import { Button, IconButton, TextField, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { BGrid } from 'mui-bueno';
import { DisplayTextFormat } from '../Utils/DisplayTextFormat';
import { BGridProps } from 'mui-bueno/lib/components/BGrid';


interface Props{
    name: string;
    label: string;
    onFileSelect: (file: File | undefined) => void;
    fileTypes?: string | string []
    displayFileSelected?: boolean
}

const OutlinedFileInput:React.FC<Props> = props => {
    const {
        name,
        label,
        onFileSelect,
        fileTypes,
        displayFileSelected = true,
    } = props

    const [selectedFile, setSelectedFile] = React.useState<File>();
    const [inputErrors, setInputErrors] = React.useState<string>();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files;
        if (!fileList) return;
        const selected = fileList[0];
        if (fileTypes){
            const selectedFileType = selected.name.split('.').reverse()[0]
            if(!fileTypes.includes(selectedFileType)){
                const fileTypeString = fileTypes.toString().replace(/,/g, ', ');
                setInputErrors(`Invalid File Type: '.${selectedFileType}'. File must be one of the following types: ${fileTypeString}` )
                setSelectedFile(undefined);
                onFileSelect(undefined);
                return;
            }else{
                setInputErrors(undefined);
            }
        }
        setSelectedFile(selected);
        onFileSelect(selected);
    };

    const handleRemoveFile = (event: React.ChangeEvent<any>) => {
        event.stopPropagation();
        setSelectedFile(undefined);
        onFileSelect(undefined);
    }

    const getClassName = () => {
        if(inputErrors){
            return 'outlined-file-input error'
        }else{
            return 'outline-file-input '
        }
    }

    return(
        <BGrid {...props as BGridProps}>
            <TextField
                key={`outlined-file-input-${name}`}
                label={label}
                variant='outlined'
                placeholder=''
                multiline
                fullWidth
                className={getClassName()}
                helperText={inputErrors? inputErrors : null}
                InputProps={{
                    disabled: true,
                    error: !!inputErrors,
                    startAdornment: 
                        <div className='file-upload-contents'>
                            <label htmlFor='upload-file' >
                                <input
                                    style={{display: 'none'}}
                                    id='upload-file'
                                    name='upload-file'
                                    type='file'
                                    onChange={handleFileChange}
                                />
                                <Button variant='outlined' component='span' >
                                    <Typography variant='button'>UPLOAD</Typography>
                                </Button>
                            </label>
                            {displayFileSelected && selectedFile &&
                                <div className='selected-file-text' >
                                    <Typography>{DisplayTextFormat(selectedFile?.name, 40)}</Typography>
                                    <IconButton  size='small' onClick={handleRemoveFile} >
                                        <CloseIcon className='remove-file' fontSize='small' />
                                    </IconButton>
                                </div>
                            }
                        </div>
                }}
            />
        </BGrid>
    )
}

export default OutlinedFileInput;
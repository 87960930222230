import axiosInstance from '../Access/axiosInstance';
import { Patient, PatientPage, PatientPageRequest } from '../../@types';
import { handleError } from '../utils';

/**
 * Gets a page of patients
 */
export const getPatientPage = (pageReq: PatientPageRequest) =>
    axiosInstance.get<PatientPage>(
        '/api/patients', {
            params: pageReq
        }
    ).catch(handleError);

/**
 * Gets a patient by id
 */
export const getPatient = (id: number) =>
    axiosInstance.get<Patient>(
        `/api/patients/${id}`
    ).catch(handleError);

/**
 * Updates a patient status to active/inactive by patient id
 */
export const setPatientStatus = (id: number, active: boolean) =>
    axiosInstance.put<Patient>(
        `/api/patients/status/${id}?active=${active}`
    ).catch(handleError);

/**
 * Updates a patient
 */
export const updatePatient = (patient: Patient) =>
    axiosInstance.put<Patient>(
        '/api/patients', patient
    ).catch(handleError);

/**
 * Creates a patient
 */
export const createPatient = (patient: Patient) =>
    axiosInstance.post<Patient>(
        '/api/patients', patient
    ).catch(handleError);

/**
 * Deletes a patient
 */
export const deletePatient = (id: number) => 
    axiosInstance.delete(
        `/api/patients/${id}`
    ).catch(handleError);
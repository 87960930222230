import * as React from 'react';
import { useDispatch } from 'react-redux';

import {
    Card, Table, TableBody, TableCell, TableContainer,
    TableFooter,
    TableHead, TableRow, Typography
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

import { DivisionTree, StudyProductivityData, StudyProductivityResult } from '../../../../@types';
import Loading from '../../../../common/Routes/Loading';
import { displayDate } from '../../../../common/Utils/utils';
import { handleErrorResponse } from '../../../../service/utils';
import { getLifetimeDivisionHealth } from '../../../../service/Management/divisions';
import HealthChart from './HealthChart';
import { amountFormatter } from '../Utils';

interface Props {
    division: DivisionTree;
}

interface State {
    sortedList: StudyProductivityData[];
    sortColumn: string;
    sortOrder: boolean;
}

interface Action {
    type: 'list' | 'column' | 'order';
    payload?: any;
}

interface GraphData {
    studyId: number;
    studyName: string;
    divisionName: string;
    productivity: number;
    lastVisitDate: string | null;
}

const HealthWidget: React.FC<Props> = (props) => {
    const { division } = props;
    const dispatcher = useDispatch();

    const [data, setData] = React.useState<StudyProductivityResult>({ list: [], totalAmount: 0 });
    const [doneLoading, setDoneLoading] = React.useState<boolean>(false);

    // State management for sorting
    const initialState: State = {
        sortedList: data.list,
        sortColumn: 'productivity',
        sortOrder: false,
    };

    const reducer = (invoiceWidgetState: State, action: Action) => {
        switch (action.type) {
            case 'list':
                return { ...invoiceWidgetState, sortedList: action.payload };
            case 'column':
                return { ...invoiceWidgetState, sortColumn: action.payload, sortOrder: true }
            case 'order':
                return { ...invoiceWidgetState, sortOrder: action.payload }
            default:
                return invoiceWidgetState;
        }
    };

    const [state, dispatchPage] = React.useReducer(reducer, initialState);
    const { sortedList, sortColumn, sortOrder } = state;

    const getData = (divisionId?: number) => {
        getLifetimeDivisionHealth(divisionId).then(res => {
            setData(res.data);
            setDoneLoading(true);
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                prefix: 'Could not get division\'s study health data: '
            })
        });
    }

    // Retrieve the updated data for the specified division or when a payment is made
    React.useEffect(() => {
        if (division) {
            getData(division.id);
        } else {
            getData();
        }
    }, [division]);

    // Sorting
    const handleSortClick = (columnType: string): void => {
        if (columnType === sortColumn) {
            dispatchPage({ type: 'order', payload: !sortOrder });
        } else {
            dispatchPage({ type: 'column', payload: columnType });
        }
    };

    const headerSortIcon = (sortType: string) => {
        if (sortColumn === sortType) return sortOrder ? <ArrowDropUp /> : <ArrowDropDown />;
    };

    React.useEffect(() => {
        const sorted: any[] = [...data.list].sort((aObj: any, bObj: any) => {
            const order: number = !sortOrder ? -1 : 1;
            const a = aObj[sortColumn];
            const b = bObj[sortColumn];

            if (a instanceof Date || b instanceof Date) {
                return order * ((a?.getTime() ?? new Date(0).getTime()) - (b?.getTime() ?? new Date(0).getTime()));
            } else if (typeof a === 'number' || typeof b === 'number') {
                return order * ((a ?? 0) - (b ?? 0));
            } else {
                return order * (a ?? '').localeCompare(b ?? '');
            }
        });
        dispatchPage({ type: 'list', payload: sorted });
    }, [data, sortColumn, sortOrder]);

    // Need to convert the date to a string before sending the data to the bar graph
    const convertData = (dataToConvert: StudyProductivityData[]) => {
        const tempData: GraphData[] = [];
        dataToConvert.forEach((obj: StudyProductivityData) => {
            tempData.push({
                studyId: obj.studyId,
                studyName: obj.studyName,
                divisionName: obj.divisionName,
                productivity: obj.productivity ?? null,
                lastVisitDate: obj.lastVisitDate ? displayDate(obj.lastVisitDate) : 'No Visits',
            })
        })
        return tempData;
    };

    if (doneLoading) {
        return (
            <Card className='bar-chart-widget card'>
                <div className='widget-header'>
                    <Typography className='header-text'>Study Health</Typography>
                    {/* <ToggleButtonGroup
                        value={dataMode}
                        onChange={((event: any, value: any) => setDataMode(value))}
                        className="mode-toggle-group"
                        style={{ marginLeft: '0.5vw', height: 32 }}
                        exclusive
                    >
                        <ToggleButton value="employee" data-mode={dataMode}>
                            <Tooltip arrow title={`Analyze productivity across employees in the selected division`}>
                                <Typography>Employee</Typography>
                            </Tooltip>
                        </ToggleButton>
                        <ToggleButton value="study" data-mode={dataMode}>
                            <Tooltip arrow title={`Analyze productivity across studies in the selected division`}>
                                <Typography>Study</Typography>
                            </Tooltip>
                        </ToggleButton>
                        <ToggleButton value="division" data-mode={dataMode}>
                            <Tooltip arrow title={`Analyze productivity across divisions and sub-divisions in the selected parent division`}>
                                <Typography>Division</Typography>
                            </Tooltip>
                        </ToggleButton>
                    </ToggleButtonGroup> */}
                </div>
                <TableContainer className='table-container'>
                    <Table stickyHeader>
                        <TableHead className="table-header">
                            <TableRow>
                                <TableCell className='cursor-pointer'
                                    style={{ width: '25%', paddingLeft: 10 }}
                                    onClick={() => handleSortClick('studyName')}
                                >
                                    <div className='header-cell-label left text-600'>
                                        Study
                                        {headerSortIcon('studyName')}
                                    </div>
                                </TableCell>
                                <TableCell className='cursor-pointer'
                                    style={{ width: '52%' }}
                                    onClick={() => { handleSortClick('productivity') }}
                                >
                                    <div className='header-cell-label center text-600'>
                                        Productivity
                                        {headerSortIcon('productivity')}
                                    </div>
                                </TableCell>
                                <TableCell className='cursor-pointer'
                                    onClick={() => { handleSortClick('lastVisitDate') }}
                                >
                                    <div className='header-cell-label left text-600'>
                                        Last Visit
                                        {headerSortIcon('lastVisitDate')}
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {data.list.length > 0 ?
                            <>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ paddingLeft: 10 }}>
                                            <HealthChart
                                                data={convertData(sortedList)}
                                                displayColumn='studyName'
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingLeft: 0 }}>
                                            <HealthChart
                                                data={convertData(sortedList)}
                                                displayColumn='productivity'
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <HealthChart
                                                data={convertData(sortedList)}
                                                displayColumn='lastVisitDate'
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                                <TableFooter className='info-row'>
                                    <TableRow>
                                        <TableCell className='left' style={{ paddingLeft: 10 }}>
                                            Total
                                        </TableCell>
                                        <TableCell className='center'>
                                            {amountFormatter(data.totalAmount)}
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableFooter>
                            </> :
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={3} className='body-cell center' style={{ height: 80, fontSize: 16 }}>
                                        No data to display.
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
            </Card>
        )
    } else {
        return (
            <Loading />
        )
    }
}

export default HealthWidget;
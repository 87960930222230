import * as React from 'react';
import { Card, CardContent, CardHeader, Grid, Tab, Tabs } from '@material-ui/core';


interface Props {
    title: string;
    list: any[];
    options?: {value: string; label: string;}[];
    selectedOption?: string;
    setSelectedOption?: (arg0: string) => void;
    className?: string;
    style?: React.CSSProperties;
}

const QuickAccess: React.FC<Props> = props => {
    const { title, list, options, selectedOption, setSelectedOption, className, style } = props;
    const titleKey: string = title.toLowerCase().replace(' ', '-');

    const handleChange = (event: any, newValue: string) => {
        setSelectedOption && setSelectedOption(newValue);
    };

    return (
        <Card className={'quick-access-widget ' + className} style={style}>
            <CardHeader
                title={<>
                    <div className="header">{title}</div>
                    {options && <div className="sub-header">
                        <Tabs
                            value={selectedOption ?? ''}
                            onChange={handleChange}
                            indicatorColor="primary"
                        >
                            {options.map((option, index) => {
                                return (
                                    <Tab
                                        key={`${titleKey}-list-filter-${index}`}
                                        label={option.label}
                                        value={option.value}
                                    />
                                );
                            })}
                        </Tabs>
                    </div>}
                </>}
                className="card-header"
            />
            <CardContent className="card-content">
                <Grid container>
                    {list.map((item, index) => {
                        return (
                            <Grid
                                key={`${titleKey}-list-${index}`}
                                className="list-item"
                                xs={12}
                                item
                            >
                                {item}
                            </Grid>
                        );
                    })}
                </Grid>
            </CardContent>
        </Card>
    );
}

export default QuickAccess;
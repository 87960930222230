import axiosInstance from '../Access/axiosInstance';
import { DirectoryDetailInfo, Document, DocumentByDirectory, DocumentTree, DocumentVersion } from '../../@types';
import { handleError } from '../utils';

/**
 * Creates a document
 */
export const createDocument = (document: Document) =>
    axiosInstance.post<Document>(
        '/api/documents', document
    ).catch(handleError);

/**
 * Updates a document
 */
export const updateDocument = (document: Document) =>
    axiosInstance.put<Document>(
        '/api/documents', document
    ).catch(handleError);

/**
 * Updates a document's name
 */
export const updateDocumentName = (id: number, newName: string) =>
    axiosInstance.put<Document>(
        `/api/documents/${id}?newName=${newName}`
    ).catch(handleError);

/**
 * Gets a document by id
 */
export const getDocument = (id: number) =>
    axiosInstance.get<Document>(
        `/api/documents/${id}`
    ).catch(handleError);

/**
 * Deletes a document
 */
export const deleteDocument = (id: number) =>
    axiosInstance.delete(
        `/api/documents/${id}`
    ).catch(handleError);

/**
 * Gets all documents from a study
 */
export const getDocumentsByStudy = (id: number) =>
    axiosInstance.get<DocumentByDirectory[]>(
        `/api/documents/study/${id}`
    ).catch(handleError);

/**
 * Gets all documents from a study in tree format
 */
export const getDocumentTreeByStudy = (id: number) =>
    axiosInstance.get<DocumentTree[]>(
        `/api/documents/tree/study/${id}`
    ).catch(handleError);

/**
 * Gets all details from a directory in a study
 */
export const getDocumentsByDirectory = (id: number) =>
    axiosInstance.get<DirectoryDetailInfo>(
        `/api/documents/directory/${id}`
    ).catch(handleError);

/**
 * Uploads a new document to a study
 */
export const uploadNewDocument = (studyId: number, directoryId: number, formData: FormData) =>
    axiosInstance.post<Document>(
        `/api/documents/upload/study/${studyId}/directory/${directoryId}`, formData
    ).catch(handleError);

/**
 * Uploads an existing document to a study
 */
export const uploadExistingDocument = (documentId: number, directoryId: number, formData: FormData) =>
    axiosInstance.post<Document>(
        `/api/documents/upload/${documentId}/directory/${directoryId}`, formData
    ).catch(handleError);

/**
 * Gets all history from a document
 */
export const getDocumentHistories = (id: number) =>
    axiosInstance.get<DocumentVersion[]>(
        `/api/documents/histories/${id}`
    ).catch(handleError);

/**
 * Downloads the latest version of a document on a study
 */
export const downloadLatestDocument = (id: number) =>
    axiosInstance.get(
        `/api/documents/download/${id}`,
        { responseType: 'blob' }
    ).catch(handleError);

/**
 * Downloads a specified version of a document on a study
 */
export const downloadDocument = (id: number, versionId: string) =>
    axiosInstance.get(
        `/api/documents/download/${id}?versionId=${versionId}`,
        { responseType: 'blob' }
    ).catch(handleError);

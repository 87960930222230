import { Chip } from "@material-ui/core";
import React from "react";
import {
    ResponsiveContainer,
    BarChart,
    ReferenceLine,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip
} from "recharts";
import { ResourceRequiredData } from "../../../../../@types";
import { handleAllocatedReqTooltip } from "../../../Utils";
import ChartYearControls from "../ChartYearControls";

interface Props {
    data: ResourceRequiredData[];
    editYear: number;
    setEditYear: any;
    coordinatorColor: string;
    providerColor: string;
}

const AllocatedOverReqChart: React.FC<Props> = (props) => {
    const { data, editYear, setEditYear, coordinatorColor, providerColor } = props;

    const monthExtractor = (date: string) => {
        // Date is in the format YYYY-MM-DD
        const splitDate: number[] = date.toString().split('-').map(a => +a);
        const newDate = new Date(splitDate[0], (splitDate[1] - 1), splitDate[2]);
        const locale: string = 'en-US';
        return newDate.toLocaleDateString(locale, { month: 'short' });
    }
    
    const CustomXAxisTick = (axisProps: any) => {
        const { x, y, payload } = axisProps;
        return (
            <g transform={`translate(${x}, ${y})`}>
                <text x={0} y={0} dy={16} fill="#666">
                    <tspan textAnchor="middle" x="0">{monthExtractor(payload.value)}</tspan>
                    <tspan textAnchor="middle" x="0" dy="20">{editYear}</tspan>
                </text>
            </g>
        );
    };

    return (
        <>
            <div className='resource-legend'>
                <Chip
                    avatar={
                        <svg>
                            <g transform={`translate(${9} ${9})`}>
                                <circle r={6} fill={coordinatorColor} />
                            </g>
                        </svg>
                    }
                    label="Coordinator"
                    variant="outlined"
                    size="small"
                    className="legend-chip"
                />
                <Chip
                    avatar={
                        <svg>
                            <g transform={`translate(${9} ${9})`}>
                                <circle r={6} fill={providerColor} />
                            </g>
                        </svg>
                    }
                    label="Provider"
                    variant="outlined"
                    size="small"
                    className="legend-chip"
                />
            </div>
            <ResponsiveContainer width='100%' height={400} className='widget-bar-chart'>
                <BarChart
                    width={1000}
                    height={400}
                    data={data}
                    margin={{
                        top: 20,
                        right: 20,
                        left: 20,
                        bottom: 20
                    }}
                    barSize={30}
                >
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis
                        dataKey="associatedDate"
                        scale='band'
                        interval={0}
                        tick={CustomXAxisTick}
                        padding={{ left: 20, right: 20 }}
                    />
                    <YAxis label={{ value: 'Allocated - Required (FTE)', angle: -90, dx: -30, position: 'insideStart' }} />
                    <Tooltip
                        isAnimationActive={false}
                        content={(resourceReqTooltipProp) => (
                            handleAllocatedReqTooltip(resourceReqTooltipProp, editYear)
                        )}
                    />
                    <ReferenceLine y={0} stroke="#000" />
                    <Bar dataKey="coordinatorDifference" name="Coordinator" fill="#075c55" />
                    <Bar dataKey="providerDifference" name="Provider" fill="#982126" />
                </BarChart>
            </ResponsiveContainer>
            <ChartYearControls editYear={editYear} setEditYear={setEditYear} />
        </>
    )
}

export default AllocatedOverReqChart;
/*** CONSTANTS ***/
export const TOGGLE_COLLAPSIBLE_PANEL = 'collabsiblePanelReducer/TOGGLE_COLLAPSIBLE_PANEL';

/*** ACTIONS ***/
export interface ToggleCollapsiblePanel {
    type: typeof TOGGLE_COLLAPSIBLE_PANEL;
}

export type CollapsiblePanelAction = ToggleCollapsiblePanel;

/*** ACTION CREATORS ***/
export const toggleCollapsiblePanel = (): ToggleCollapsiblePanel => {
    return {
        type: TOGGLE_COLLAPSIBLE_PANEL,
    };
};

/*** REDUCER ***/
export interface CollapsiblePanelState {
    expanded: boolean;
}

export const initialState: CollapsiblePanelState = {
    expanded: false,
};

export const reducer = (
    state: CollapsiblePanelState = initialState,
    action: CollapsiblePanelAction
): CollapsiblePanelState => {
    if (action.type === TOGGLE_COLLAPSIBLE_PANEL) {
        return {
            expanded: !state.expanded,
        };
    } else {
        /* istanbul ignore next */
        return state;
    }
};

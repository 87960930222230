import axiosInstance from '../Access/axiosInstance';
import { InvoiceType } from '../../@types';
import { handleError } from '../utils';

/**
 * Gets a list of invoice types
 */
export const getInvoiceTypes = () =>
    axiosInstance.get<InvoiceType[]>(
        '/api/invoiceTypes'
    ).catch(handleError);
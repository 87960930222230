import axiosInstance from '../Access/axiosInstance';
import { PatientNote } from '../../@types';
import { handleError } from '../utils';


const API_HEADER: string = '/api/patientNotes';

/**
 * Creates a PatientNote
 */
export const createPatientNote = (patientNote: PatientNote) =>
    axiosInstance.post<PatientNote>(
        API_HEADER, patientNote
    ).catch(handleError);

/**
 * Gets a list of PatientNotes assigned to specified Patient
 */
export const getAllByPatientId = (id: number) =>
    axiosInstance.get<PatientNote[]>(
        API_HEADER + `/patient/${id}`
    ).catch(handleError);

/**
 * Updates a PatientNote
 */
export const updatePatientNote = (patientNote: PatientNote) =>
    axiosInstance.put<PatientNote>(
        API_HEADER, patientNote
    ).catch(handleError);

/**
 * Deletes a PatientNote by id
 */
export const deletePatientNote = (id: number) => 
    axiosInstance.delete(
        API_HEADER + `/${id}`
    ).catch(handleError);
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import * as yup from 'yup';
import { Button, Typography } from '@material-ui/core';

import { User } from '../../../@types';
import GridWrapper from '../../../common/GridWrapper/GridWrapper';
import OutlinedTextField from '../../../common/OutlinedTextField/OutlinedTextField';
import OutlinedPassword from '../../../common/OutlinedTextField/OutlinedPassword';
import { signUp } from '../../../service/Management/users';
import { handleUnprocessableEntity } from '../../../service/utils';
import { showSuccessSnackbar } from '../../../modules/messageSnackbarReducer';

const schema = yup.object<User>().shape({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email('Must be in email format').required('Email is required')
});

const SignUp: React.FC = () => {
    const history = useHistory();

    const dispatch = useDispatch();

    const [user, setUser] = React.useState<User>({
        id: undefined,
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        password: '',
        divisionIds: [],
        studyIds: [],
        roleIds: [],
    });

    const [errors, setErrors] = React.useState<any>({});

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault(); // prevent component from reloading
        schema.validate(user, {abortEarly: false})
            .then(() => {
                setErrors({});
                signUp(user).then(() => {
                    history.push('/login');
                    dispatch(showSuccessSnackbar('Registered successfully; please login.'));
                }).catch(err => handleUnprocessableEntity(err, setErrors));
            }).catch( (err: yup.ValidationError) => {
                const list: any = {};
                for (const e of err.inner) {
                    if (e.path) list[e.path] = e.message;
                }
                setErrors(list);
            });
    }

    const handleChange = (name: keyof User) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setUser({ ...user, [name]: event.target.value });
    }

    return (
        <GridWrapper>
            <form onSubmit={handleSubmit}>
                <Typography className="card-heading">Sign Up</Typography>
                <OutlinedTextField
                    label="First Name"
                    value={user.firstName}
                    onChange={handleChange('firstName')}
                    error={errors['firstName']}
                />
                <OutlinedTextField
                    label="Last Name"
                    value={user.lastName}
                    onChange={handleChange('lastName')}
                    error={errors['lastName']}
                />
                <OutlinedTextField
                    label="Email"
                    value={user.email}
                    onChange={handleChange('email')}
                    error={errors['email']}
                />
                <OutlinedPassword
                    label="Password"
                    value={user.password}
                    onChange={handleChange('password')}
                    error={errors['password']}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className='card-button'
                >
                    Register
                </Button>
                <div className="option-links">
                    <Typography variant="body2">
                        Already have an account?  <Link to="/login">Sign in</Link>
                    </Typography>
                </div>
            </form>
        </GridWrapper>
    )
}

export default SignUp;

import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { Bar, BarChart, Cell, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { amountFormatter, handleStudyNameTooltip, studyNameFormatter } from '../Utils';

interface Props {
    data: any[];
    displayColumn: 'studyName' | 'productivity' | 'lastVisitDate';
}

const HealthChart: React.FC<Props> = props => {
    const { data, displayColumn } = props;

    const history = useHistory();

    const [graphHeight, setGraphHeight] = React.useState<number>(0);
    React.useEffect(() => {
        setGraphHeight(data.length * 40);
    }, [data]);

    const contains: any = () => {
        let neg: boolean = false;
        let pos: boolean = false;
        let zero: boolean = false;
        if (displayColumn == 'productivity') {
            for (const d of data) {
                if (d.productivity < 0) {
                    neg = true;
                    if (pos || zero) break;
                }
                if (d.productivity > 0) {
                    pos = true;
                    if (neg || zero) break;
                }
                if (d.productivity == 0) {
                    zero = true;
                    if (neg || pos) break;
                }
            }
        }
        return { negative: neg, positive: pos };
    }

    const chartMargin = () => {
        if (displayColumn == 'studyName') {
            return { top: -20, right: 0, bottom: -10, left: -5 };
        }
        if (displayColumn == 'lastVisitDate') {
            return { top: -20, right: 0, bottom: -10, left: 0 };
        }
        if (displayColumn == 'productivity') {
            return {
                top: -30,
                right: (!(contains().positive) && contains().negative) ? 100 : 0,
                bottom: 0,
                left: (!(contains().negative) && (contains().positive || contains().zero)) ? 100 : 0
            };
        }
    };

    return (
        <ResponsiveContainer width='99%' height={graphHeight} className='widget-bar-chart'>
            <BarChart
                data={data}
                layout='vertical'
                barSize={20}
                margin={chartMargin()}
            >
                <XAxis
                    dataKey='productivity'
                    type={displayColumn == 'productivity' ? 'number' : 'category'}
                    orientation="top"
                    axisLine={false}
                    tick={false}
                />
                <YAxis
                    dataKey="studyName"
                    type='category'
                    interval={0}
                    hide
                />
                {displayColumn == 'studyName' && <>
                    <Tooltip
                        isAnimationActive={false}
                        content={(studyNameTooltipProp) => (
                            handleStudyNameTooltip(studyNameTooltipProp)
                        )}
                    />
                    <Bar
                        dataKey="studyName"
                        isAnimationActive={false}
                        label={{ position: 'right', formatter: studyNameFormatter, fill: '#383838' }}
                    >
                        {data.map((entry, index) => (
                            <Cell
                                cursor="pointer" key={`cell-${index}`}
                                onClick={() => history.push(`/study/${entry.studyId}/overview`)}
                            />
                        ))}
                    </Bar>
                </>}
                {displayColumn == 'productivity' && <>
                    <ReferenceLine x={0} stroke="#DCDCDC" />
                    <Bar
                        dataKey='productivity'
                        fill='#e04f4f'
                        isAnimationActive={false}
                        label={{ position: 'left', formatter: amountFormatter, fill: '#383838' }}
                    >
                        {data.map((entry, index) => {
                            let productivityColor = '#e04f4f';
                            if (entry.productivity > 0) {
                                productivityColor = '#72CE7C';
                            }
                            return (
                                <Cell
                                    fill={productivityColor} cursor="pointer" key={`cell-${index}`}
                                    onClick={() => history.push(`/study/${entry.studyId}/statistics`)}
                                />
                            )
                        })}
                    </Bar>
                </>}
                {displayColumn == 'lastVisitDate' &&
                    <Bar
                        dataKey='lastVisitDate'
                        isAnimationActive={false}
                        label={{ position: 'right', fill: '#383838' }}
                    >
                        {data.map((entry, index) => (
                            <Cell
                                cursor="pointer" key={`cell-${index}`}
                                onClick={() => history.push(`/study/${entry.studyId}/activity/scheduled`)}
                            />
                        ))}
                    </Bar>
                }
            </BarChart>
        </ResponsiveContainer>
    )
}

export default HealthChart;
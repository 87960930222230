import * as React from 'react';
import { TableCell, TableRow } from '@material-ui/core';

import { NumberFormatter } from '../../../../../common/Utils/NumberFormatter';

interface SVTotalSectionProps {
    totalVisits: number[];
    expectedPaid: number[];
    expectedHold: number[];
    totalPaid: number[];
    count: number;
}

const ScheduledVisitsTableTotal: React.FC<SVTotalSectionProps> = props => {
    const { totalVisits, expectedPaid, expectedHold, totalPaid, count } = props;
    return (
        <>
            <TableRow>
                <TableCell key={'visit-cost'} className={'sv-body-table-label-stick left'}>
                    Total
                </TableCell>
                <TableCell key='sv-header-actions' align='left' colSpan={count} className="sv-body-table-label" />
            </TableRow>
            <TableRow key='total-visits-row'>
                <TableCell key='total-visits-section' className='sv-table-body-section-starter right'>
                    Total Visits
                </TableCell>
                {totalVisits.map((totalVisit: number, svidx: number) => {
                    return (
                        <TableCell key={'total-patient-visits-' + (svidx + 1)} className='body-cell right'>
                            {totalVisit}
                        </TableCell>
                    )
                })}
            </TableRow>
            <TableRow key='expected-paid-row'>
                <TableCell key='expected-paid-section' className='sv-table-body-section-starter right'>
                    Expected Paid
                </TableCell>
                {expectedPaid.map((ep: number, svidx: number) => {
                    return (
                        <TableCell key={'scheduled-visit-expected-paid-' + (svidx + 1)} className={'body-cell right'}>
                            <NumberFormatter currency value={ep} />
                        </TableCell>
                    )
                })}
            </TableRow>
            <TableRow key='expected-hold-row'>
                <TableCell key='expected-hold-section' className='sv-table-body-section-starter right'>
                    Expected Hold
                </TableCell>
                {expectedHold.map((eh: number, svidx: number) => {
                    return (
                        <TableCell key={'scheduled-visit-expected-holdback-' + (svidx + 1)} className={'body-cell right'}>
                            <NumberFormatter currency value={eh} />
                        </TableCell>
                    )
                })}
            </TableRow>
            <TableRow key='total-paid-row'>
                <TableCell key='total-paid-section' className='sv-table-body-section-starter right'>
                    Total
                </TableCell>
                {totalPaid.map((tp: number, svidx: number) => {
                    return (
                        <TableCell key={'scheduled-visit-expected-holdback-' + (svidx + 1)} className={'body-cell right'}>
                            <NumberFormatter currency value={tp} />
                        </TableCell>
                    )
                })}
            </TableRow>
        </>
    )
}

export default ScheduledVisitsTableTotal;
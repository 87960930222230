import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Check, GetApp } from '@material-ui/icons';

import { handleErrorResponse } from '../../../service/utils';
import EditablePagingTable, { TableColumn } from '../../../common/DataTable/EditablePagingTable';
import { getAccountPaymentsWithInfo } from '../../../service/Account/account';
import { downloadRemittance } from '../../../service/AccountPayment/accountPayment';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { NumberFormatter } from '../../../common/Utils/NumberFormatter';
import { Store } from '../../../modules/rootReducer'
import { CollapsiblePanelState } from '../../../modules/collapsiblePanelReducer';


interface Props {
    accountId: number
}

interface TableData {
    id: number;
    totalAmount: number;
    unusedAmount: number;
    payeeAccount: string;
    checkNo?: string;
    remittanceFilePath: string | null;
    holdback?: boolean;
}

const PaymentsTable: React.FC<Props> = props => {
    const { accountId } = props;

    const { expanded } = useSelector<Store, CollapsiblePanelState>(
        store => store.collapsiblePanelReducer
    );

    const dispatch = useDispatch();
    const history = useHistory();

    const [tableData, setTableData] = React.useState<TableData[]>([]);
    const [totalUnused, setTotalUnused] = React.useState<number>(0);

    React.useEffect(() => {
        getAccountPaymentsWithInfo(accountId)
            .then(res => {
                let unused = 0;
                setTableData(res.data
                    // should always have an id for accountPayments retrieved from service
                    .filter(ap => !!ap.accountPayment.id)
                    .map(ap => {
                        unused += ap.amountUnused;
                        return {
                            id: ap.accountPayment.id!,
                            totalAmount: ap.accountPayment.amount,
                            unusedAmount: ap.amountUnused,
                            payeeAccount: ap.receivingAccountName,
                            checkNo: ap.accountPayment.checkNo,
                            remittanceFilePath: ap.accountPayment.remittanceFilePath,
                            holdback: ap.accountPayment.holdback
                        }
                    })
                );
                setTotalUnused(unused)
            })
            .catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve list of Account Payments: '
                });
            });
    }, [accountId]);

    const handleDownload = (selected: TableData): void => {
        downloadRemittance(selected.id)
            .then(res => {
                const blob = new Blob([res.data]);
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.setAttribute('download', selected.remittanceFilePath ?? '');
                link.click();
                URL.revokeObjectURL(link.href);
            }).catch(err =>
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not download remittance: '
                })
            );
    };

    const handleView = (selected: TableData): void => {
        history.push(`/manage/account/${accountId}/payments/${selected.id}`);
    };

    const columns: TableColumn[] = [
        { type: 'holdback', displayValue: 'Holdback', align: 'center', format: 'symbol', symbolProps: { icon: <Check />, label: 'Y' }, style: { width: '5%' } },
        { type: 'totalAmount', displayValue: 'Total Amount', align: 'right', format: 'currency-US', style: { width: '10%' } },
        { type: 'unusedAmount', displayValue: 'Unused Amount', align: 'right', format: 'currency-US', style: { width: '10%' } },
        { type: 'checkNo', displayValue: 'Check Number', align: 'left', greyIfEmpty: true, style: { width: '20%' } },
        { type: 'payeeAccount', displayValue: 'Payee Account', align: 'left', greyIfEmpty: true, style: { width: '50%' } },
        {
            type: 'remittanceFilePath', displayValue: 'Remittance', greyIfEmpty: true, align: 'center', format: 'button', noSort: true,
            buttonProps: { iconLabel: <GetApp />, handler: handleDownload, handlerPermission: true, disable: r => !r }
        },
        {
            type: 'details', displayValue: 'Details', greyIfEmpty: true, align: 'center', format: 'button', noSort: true,
            buttonProps: { label: 'View', handler: handleView, handlerPermission: true }
        }
    ];


    const conditionalClassName = () => {
        if (expanded) {
            return 'constrainable expanded';
        }
        if (!expanded) {
            return 'constrainable collapsed';
        }
        return 'constrainable';
    }

    return (
        <div>
            <EditablePagingTable
                dataList={tableData}
                tableInfoColumns={columns}
                editingDataObject={undefined}
                handleSubmit={undefined}
                validationSchema={undefined}
                readonly={false}
                canCreate={false}
                clickedObj={undefined}
                setClickedObj={undefined}
                initialSortType='totalAmount'
                mode='dynamic'
            />
            <br />
            <div className={`payments-footer`}>
                <TableContainer component={Paper} className={`table ${conditionalClassName()}`} >
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    key={'credit-header'}
                                    className='cell-header'
                                    colSpan={2}
                                >
                                    Total Account Credit
                                </TableCell>
                                <TableCell
                                    key={'credit-total'}
                                    className='cell-body'
                                    colSpan={1}
                                >
                                    <NumberFormatter currency value={totalUnused} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}

export default PaymentsTable;
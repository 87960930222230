import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import { Collapse, Hidden, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import CallMadeIcon from '@material-ui/icons/CallMade';

import { Store } from '../../modules/rootReducer';
import { CollapsiblePanelState, toggleCollapsiblePanel } from '../../modules/collapsiblePanelReducer';

export interface NavItem {
    title: string;
    icon: any;
    route: string;
}

interface Props {
    navOptions?: NavItem[];
}

const CollabsiblePanel: React.FC<Props> = props => {
    const { navOptions } = props;

    const location = useLocation();

    // Manage the expanded state in the Redux store
    const dispatch = useDispatch();
    const { expanded } = useSelector<Store, CollapsiblePanelState>(
        store => store.collapsiblePanelReducer
    );
    const handleToggle = () => {
        dispatch(toggleCollapsiblePanel())
    }

    // Manage the simple React state for opening/closing the nav top bar
    const [toggleOpen, setToggleOpen] = React.useState(false);
    const handleToggleMenu = () => {
        setToggleOpen(!toggleOpen);
    }

    const currOption = () => {
        let curr: any = {
            title: '',
            icon: '',
            route: '',
        };
        if (navOptions) {
            curr = navOptions.find(obj => {
                return (obj.route === location.pathname)
            });
        }
        if (curr) {
            return (
                <div className='row'>
                    <ListItemIcon>{curr.icon}</ListItemIcon>
                    <ListItemText primary={curr.title} />
                </div>
            )
        }
        return <div />
    }

    const unselectedOptions = () => {
        let options: (NavItem[] | undefined) = [];
        if (navOptions) {
            options = navOptions.filter(obj => {
                return (obj.route != location.pathname)
            });
        }
        return options;
    }

    if (navOptions) {
        return (
            <div className="collapsible-panel">
                <Hidden smDown>
                    <div className={expanded ? 'side-bar' : 'side-bar collapsed'}>
                        <List>
                            <ListItem
                                button
                                id="toggle"
                                className="toggle"
                                onClick={handleToggle}
                            >
                                <CallMadeIcon />
                                <ListItemText primary="Collapse Panel" />
                            </ListItem>
                            {navOptions.map((item, idx) => {
                                return (
                                    <NavLink
                                        key={item.title}
                                        to={item.route}
                                        className="option"
                                    >
                                        {expanded ?
                                            <ListItem button key={item.title}>
                                                <ListItemIcon>{item.icon}</ListItemIcon>
                                                <ListItemText primary={item.title} />
                                            </ListItem>
                                            :
                                            <Tooltip arrow title={item.title}>
                                                <ListItem button key={item.title}>
                                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                                    <ListItemText primary={item.title} />
                                                </ListItem>
                                            </Tooltip>
                                        }
                                    </NavLink>
                                )
                            })}
                        </List>
                        <div className={expanded ? 'filler' : 'filler collapsed'} />
                    </div>
                </Hidden>
                <Hidden mdUp>
                    <div className="top-bar">
                        <List>
                            <ListItem button onClick={handleToggleMenu} className='selected-item'>
                                <ListItemText primary={currOption()} />
                                {toggleOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={toggleOpen} timeout="auto" unmountOnExit>
                                {unselectedOptions().map((item, idx) => {
                                    return (
                                        <NavLink
                                            key={item.title}
                                            to={item.route}
                                            className="option"
                                        >
                                            <ListItem
                                                button
                                                key={item.title}
                                                onClick={() => setToggleOpen(false)}
                                            >
                                                <ListItemIcon>{item.icon}</ListItemIcon>
                                                <ListItemText primary={item.title} />
                                            </ListItem>
                                        </NavLink>
                                    )
                                })}
                            </Collapse>
                        </List>
                    </div>
                </Hidden>
            </div>
        )
    }
    return null;
};

export default CollabsiblePanel;
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';
import _ from 'lodash';

import {
    Card, CardActions, CardContent, CardHeader, Checkbox, FormControl, FormControlLabel,
    FormGroup, Grid, IconButton, InputAdornment, Modal, Switch, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography
} from '@material-ui/core';
import { Close, NavigateNext } from '@material-ui/icons';
import { BButton, BDatePicker, BError, BForm, BGrid, BSubmit, BTextField } from 'mui-bueno';
import { Formik, FormikHelpers } from 'formik';

import {
    PatientVisit, PatientVisitUser, ScheduledPatientVisitsTab, SelectOption, ShortProcedure, Study
} from '../../../../../@types';
import { getLocalDate } from '../../../../../common/Utils/utils';
import { NumberFormatter } from '../../../../../common/Utils/NumberFormatter';
import { DisplayFullName, DisplayTextFormat } from '../../../../../common/Utils/DisplayTextFormat';
import { showErrorSnackbar, showWarningSnackbar } from '../../../../../modules/messageSnackbarReducer';
import CheckedAutocomplete from '../../../../../common/CheckedAutocomplete/CheckedAutocomplete';
import SimpleConfirmDelete from '../../../../../common/ConfirmDelete/SimpleConfirmDelete';
import useLoggedInUser from '../../../../../common/hooks/useLoggedInUser';
import useLoggedInUserPermissions from '../../../../../common/hooks/useLoggedInUserPermissions';

import {
    createPatientVisit, deletePatientVisit, getScheduledPatientVisitDetail, updatePatientVisit
} from '../../../../../service/Study/patientVisit';
import { handleErrorResponse } from '../../../../../service/utils';
import {
    createPerformedProcedures, getProcedureCosts, handleValidateUser,
    toSelectOptionsPatientVisitSingleUser, toSelectOptionsPatientVisitUser, validateVisitDateOfService
} from './utils';

import VisitModalWarning from './VisitModalWarning';


interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    patientVisit: PatientVisit;
    study: Study;
    scheduledPatientVisitsData: ScheduledPatientVisitsTab;
    readOnly?: boolean;
    lineItemName?: string;
}

interface PatientVisitFormValues {
    identifier: string;
    dateOfService: Date;
    coordinators: PatientVisitUser[];
    providers: PatientVisitUser[];
    procedures: number[];
    note: string;
}

export const TableHeader = (viewOnly: boolean) => (
    <TableHead className="visit-employee-header">
        <TableRow>
            <TableCell className='primary-cell'>
                <div className='header-cell-label'>Employee</div>
            </TableCell>
            <TableCell className='primary-cell'>
                <Tooltip arrow
                    title={<>
                        Describes the percent of work out of 100% that this employee did for this visit.
                        <br /><br />
                        (Coord1) + (Coord2) + (...) = 100%
                        <br />
                        (Prov1) + (Prov2) + (...) = 100%
                        <br /><br />
                        If two coordinators did equal work, they would each enter 50%.
                    </>}
                >
                    <div className='header-cell-label'>Proportion of Visit</div>
                </Tooltip>
            </TableCell>
            {!viewOnly &&
                <TableCell className='primary-cell'>
                    <div className="header-cell-label center">Actions</div>
                </TableCell>
            }
        </TableRow>
    </TableHead>
);

const ScheduledVisitsModal: React.FC<Props> = props => { //NOSONAR

    const { open, setOpen, patientVisit, study, scheduledPatientVisitsData, readOnly, lineItemName } = props;

    const dispatch = useDispatch();

    const loggedInUser = useLoggedInUser();

    const canUpdatePatientVisit = !readOnly && useLoggedInUserPermissions('API_PATIENT_VISIT_UPDATE', study.divisionId, study.id!);
    const canDeletePatientVisit = !readOnly && useLoggedInUserPermissions('API_PATIENT_VISIT_DELETE_BY_ID', study.divisionId, study.id!);
    const isCreating = !patientVisit.id;

    const [allProceduresFromContract, setAllProceduresFromContract] = React.useState<ShortProcedure[]>([]);
    const [chosenProcedures, setChosenProcedures] = React.useState<number[]>([]);
    const [unchangedProcedures, setUnchangedProcedures] = React.useState<number[]>([]);

    const [patientVisitCoordinators, setPatientVisitCoordinators] = React.useState<PatientVisitUser[]>([]);
    const [patientVisitCoordinatorOptions, setPatientVisitCoordinatorOptions] = React.useState<SelectOption<number>[]>([]);
    const [patientVisitProviders, setPatientVisitProviders] = React.useState<PatientVisitUser[]>([]);
    const [patientVisitProviderOptions, setPatientVisitProviderOptions] = React.useState<SelectOption<number>[]>([]);
    const [patientVisitEmployeeOptions, setPatientVisitEmployeeOptions] = React.useState<SelectOption<number>[]>([]);
    const [enteredDateOfService, setEnteredDateOfService] = React.useState<Date>();
    const [remainingCoordinatorEffort, setRemainingCoordinatorEffort] = React.useState<number>(100);
    const [remainingProviderEffort, setRemainingProviderEffort] = React.useState<number>(100);
    const [visitNote, setVisitNote] = React.useState<string>(patientVisit.note ?? '');

    const [multipleEmployees, setMultipleEmployees] = React.useState<boolean>(false);

    // State management for autocomplete options
    const [selectedCoordinator, setSelectedCoordinator] = React.useState<null | SelectOption<number>>(null);
    const [selectedProvider, setSelectedProvider] = React.useState<null | SelectOption<number>>(null);
    const [selectedSingleEmployee, setSelectedSingleEmployee] = React.useState<null | SelectOption<number>>(null);

    // unsaved changes states
    const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
    const [unsavedChanges, setUnsavedChanges] = React.useState<boolean>(false);

    const [deleteOpen, setDeleteOpen] = React.useState(false);

    const currentDate: Date = new Date();
    const initialValues: PatientVisitFormValues = {
        identifier: (patientVisit.patientId ? scheduledPatientVisitsData.patients.find(pat => pat.id === patientVisit.patientId)?.identifier : '') ?? '',
        dateOfService: enteredDateOfService ?? new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0),
        coordinators: patientVisitCoordinators ?? [],
        providers: patientVisitProviders ?? [],
        procedures: chosenProcedures,
        note: visitNote,
    };

    React.useEffect(() => {
        if (patientVisit.patientId !== 0) {
            if (patientVisit.id) {
                setEnteredDateOfService(getLocalDate(patientVisit.dateOfService!));
                getScheduledPatientVisitDetail(patientVisit.id).then(res => {
                    const multipleCoordinators = res.data.visitCoordinators.length > 1;
                    const multipleProviders = res.data.visitProviders.length > 1;
                    const hasBoth = res.data.visitCoordinators.length >= 1 && res.data.visitProviders.length >= 1;
                    if (multipleCoordinators || multipleProviders || hasBoth) {
                        setMultipleEmployees(true);
                    }

                    setAllProceduresFromContract(res.data.allProceduresFromContract);
                    if (res.data.performedProcedures) {
                        const currentProcedureIds = res.data.performedProcedures.map(pp => pp.procedureId);
                        setChosenProcedures(currentProcedureIds);
                        setUnchangedProcedures(currentProcedureIds);
                    } else {
                        setChosenProcedures([]);
                        setUnchangedProcedures([]);
                    }

                    setPatientVisitCoordinators(res.data.visitCoordinators);
                    setPatientVisitProviders(res.data.visitProviders);

                    // Set initial remaining effort amounts for coordinators and providers (should be either 0 or 100) when editing
                    let initialRemainingCoordinatorEffort: number = 100;
                    let initialRemainingProviderEffort: number = 100;
                    res.data.visitCoordinators.forEach((coordinator: PatientVisitUser) => {
                        initialRemainingCoordinatorEffort = initialRemainingCoordinatorEffort - coordinator.effort;
                    });
                    setRemainingCoordinatorEffort(initialRemainingCoordinatorEffort);

                    res.data.visitProviders.forEach((provider: PatientVisitUser) => {
                        initialRemainingProviderEffort = initialRemainingProviderEffort - provider.effort;
                    });
                    setRemainingProviderEffort(initialRemainingProviderEffort);
                }).catch(err => {
                    dispatch(showErrorSnackbar('Could not get patient visit detail'));
                });
            } else {
                // When creating a new patient visit, if the logged in user is a coordinator on the study, then automatically add them to the patient visit
                if (_.find(scheduledPatientVisitsData.allCoordinatorsOnStudy, ['id', loggedInUser.id]) != undefined) {
                    setPatientVisitCoordinators([{
                        userId: loggedInUser.id!,
                        userName: DisplayFullName(loggedInUser.firstName, null, loggedInUser.lastName),
                        resourceType: 'C',
                        effort: 100
                    }]);
                    setRemainingCoordinatorEffort(0);
                    setPatientVisitProviders([]);
                } else if (_.find(scheduledPatientVisitsData.allProvidersOnStudy, ['id', loggedInUser.id]) != undefined) {
                    setPatientVisitProviders([{
                        userId: loggedInUser.id!,
                        userName: DisplayFullName(loggedInUser.firstName, null, loggedInUser.lastName),
                        resourceType: 'P',
                        effort: 100
                    }]);
                    setRemainingProviderEffort(0);
                    setPatientVisitCoordinators([]);
                } else {
                    setSelectedSingleEmployee(null);
                    setPatientVisitCoordinators([]);
                    setPatientVisitProviders([]);
                }
                const scheduledVisitIds = scheduledPatientVisitsData.scheduledVisits.map(sv => sv.id!);
                const index2 = scheduledVisitIds.indexOf(patientVisit.scheduledVisitId!);
                const proceduresOnSV = scheduledPatientVisitsData.proceduresAssociatedWithScheduledVisits[index2];
                setChosenProcedures(proceduresOnSV.map(proc => proc.id!));
                setUnchangedProcedures(proceduresOnSV.map(proc => proc.id!));
                setAllProceduresFromContract(scheduledPatientVisitsData.allProceduresFromContract);
                setMultipleEmployees(false);
                setSelectedCoordinator(null);
                setSelectedProvider(null);
            }
        }
    }, [patientVisit]);

    // Refresh the user options when PatientVisitCoordinators or PatientVisitProviders got changed
    React.useEffect(() => {
        setPatientVisitCoordinatorOptions(toSelectOptionsPatientVisitUser(scheduledPatientVisitsData.allCoordinatorsOnStudy, 'C', patientVisitCoordinators));
        setPatientVisitProviderOptions(toSelectOptionsPatientVisitUser(scheduledPatientVisitsData.allProvidersOnStudy, 'P', patientVisitProviders));
        setPatientVisitEmployeeOptions(toSelectOptionsPatientVisitSingleUser(scheduledPatientVisitsData.allCoordinatorsOnStudy, scheduledPatientVisitsData.allProvidersOnStudy, selectedSingleEmployee?.value));
        if (patientVisitCoordinators.length == 1 && patientVisitProviders.length == 0) {
            const employee = patientVisitCoordinators[0];
            setSelectedSingleEmployee({
                value: Number(employee.userId),
                label: employee.userName + ' (Coordinator)'
            });
        } else if (patientVisitCoordinators.length == 0 && patientVisitProviders.length == 1) {
            const employee = patientVisitProviders[0];
            setSelectedSingleEmployee({
                value: Number(employee.userId),
                label: employee.userName + ' (Provider)'
            });
        }
    }, [patientVisitCoordinators, patientVisitProviders]);

    const handleModalClose = () => {
        if (unsavedChanges && !readOnly) {
            setDialogOpen(true);
        } else {
            setOpen(false);
        }
    }

    const handleDialogClose = (accept: boolean) => {
        setDialogOpen(false);
        if (accept) {
            setUnsavedChanges(false);
            setOpen(false);
        }
    }

    // Handlers for PatientVisit form
    const handleValidatePatientVisit = (values: PatientVisitFormValues) => {
        const errors: { [key: string]: string } = {};
        validateVisitDateOfService(values.dateOfService, study, errors);

        if (values.procedures.length == 0) {
            errors.procedures = 'Patient visit must have at least one procedure';
        }

        if ((multipleEmployees && patientVisitCoordinators.length == 0)
            || (!multipleEmployees && !selectedSingleEmployee?.label.endsWith('(Coordinator)'))) {

            errors.employees = 'Patient visit must have at least one coordinator';
        }

        return errors;
    };

    const postSubmitActions = () => {
        setUnsavedChanges(false);
        setOpen(false);
        history.go(0);
    }

    const handleSubmit = async (data: PatientVisitFormValues, { setErrors }: FormikHelpers<PatientVisitFormValues>) => {
        if (selectedCoordinator || selectedProvider) {
            dispatch(showWarningSnackbar('Coordinators/Providers list has unsaved changes'))
            return;
        }

        let costs: number[] = [];
        const employees = () => {
            if (multipleEmployees) {
                return patientVisitCoordinators.concat(patientVisitProviders);
            } else {
                if (selectedSingleEmployee?.label.endsWith('(Coordinator)')) {
                    return [{
                        userId: selectedSingleEmployee.value,
                        userName: selectedSingleEmployee.label,
                        resourceType: 'C',
                        effort: 100
                    }];
                }
                if (selectedSingleEmployee?.label.endsWith('(Provider)')) {
                    return [{
                        userId: selectedSingleEmployee.value,
                        userName: selectedSingleEmployee.label,
                        resourceType: 'P',
                        effort: 100
                    }];
                }
            }
            return [];
        }
        if (isCreating) {
            costs = getProcedureCosts(allProceduresFromContract, data.procedures);
            createPatientVisit({
                patientVisit: {
                    id: null,
                    scheduledVisitId: patientVisit.scheduledVisitId,
                    patientId: patientVisit.patientId,
                    dateOfService: data.dateOfService,
                    note: visitNote,
                },
                patientVisitUserList: employees(),
                addedPerformedProcedures: createPerformedProcedures(data.procedures, costs, null),
                deletedProcedureIds: []
            }).then(() => {
                postSubmitActions();
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    setStatus: setErrors,
                    prefix: 'Could not create Patient Visit: '
                });
            });
        } else {
            const removed = unchangedProcedures.filter(x => !data.procedures.includes(x));
            const added = data.procedures.filter(x => !unchangedProcedures.includes(x));
            costs = getProcedureCosts(allProceduresFromContract, added);
            updatePatientVisit({
                patientVisit: {
                    id: patientVisit.id,
                    scheduledVisitId: patientVisit.scheduledVisitId,
                    patientId: patientVisit.patientId,
                    dateOfService: data.dateOfService,
                    note: visitNote,
                },
                patientVisitUserList: employees(),
                addedPerformedProcedures: createPerformedProcedures(added, costs, patientVisit.id),
                deletedProcedureIds: removed
            }).then(() => {
                postSubmitActions();
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    setStatus: setErrors,
                    prefix: 'Could not update Patient Visit: '
                });
            });
        }
    };

    const handleDelete = async () => {
        await deletePatientVisit(patientVisit.id!).then(() => {
            postSubmitActions();
        }).catch((err: AxiosError) => {
            dispatch(showErrorSnackbar(err.message));
        });
    };

    const handleCheckboxChange = (id: number, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void, values: PatientVisitFormValues) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (event.target.checked) {
            setFieldValue('procedures', [...values.procedures, id], false);
            setChosenProcedures([...values.procedures, id]);
        } else {
            setFieldValue('procedures', values.procedures.filter(index => index !== id), false);
            setChosenProcedures(values.procedures.filter(index => index !== id));
        }
        setUnsavedChanges(true);
    };

    // Handlers for PatientVisitUser array state updates
    const handleAddCoordinator = (data: any, { resetForm, setErrors }: FormikHelpers<any>) => {
        if (selectedCoordinator != null) {
            const errors = handleValidateUser(data);
            if (Object.keys(errors).length > 0) {
                setErrors(errors);
                return;
            }
            setPatientVisitCoordinators([...patientVisitCoordinators, {
                userId: selectedCoordinator.value,
                userName: selectedCoordinator.label,
                resourceType: 'C',
                effort: data.effort
            }]);
            setRemainingCoordinatorEffort(remainingCoordinatorEffort - Number(data.effort));
            setSelectedCoordinator(null);
            setUnsavedChanges(true);
            resetForm();
        }
    };
    const handleChangeEmployee = (value: any, type: 'C' | 'P' | 'S') => {
        setUnsavedChanges(true);
        if (type == 'C') {
            return setSelectedCoordinator(value);
        } else if (type == 'P') {
            return setSelectedProvider(value);
        } else {
            return setSelectedSingleEmployee(value);
        }
    };
    const handleDeleteEmployee = (_event: React.MouseEvent<HTMLElement>, obj: PatientVisitUser, coordinator: boolean) => {
        if (coordinator) {
            setRemainingCoordinatorEffort(remainingCoordinatorEffort + Number(obj.effort));
            setPatientVisitCoordinators(patientVisitCoordinators.filter(usr => usr.userId !== obj.userId));
        } else {
            setRemainingProviderEffort(remainingProviderEffort + Number(obj.effort));
            setPatientVisitProviders(patientVisitProviders.filter(usr => usr.userId !== obj.userId));
        }
        setUnsavedChanges(true);
    };
    const handleAddProvider = (data: any, { resetForm, setErrors }: FormikHelpers<any>) => {
        if (selectedProvider != null) {
            const errors = handleValidateUser(data);
            if (Object.keys(errors).length > 0) {
                setErrors(errors);
                return;
            }
            setPatientVisitProviders([...patientVisitProviders, {
                userId: selectedProvider.value,
                userName: selectedProvider.label,
                resourceType: 'P',
                effort: data.effort
            }]);
            setRemainingProviderEffort(remainingProviderEffort - Number(data.effort));
            setSelectedProvider(null);
            setUnsavedChanges(true);
            resetForm();
        }
    };

    const employeeTableForm = (coordinator: boolean) => {
        const noCoordinators = patientVisitCoordinators.length < 1;
        const noProviders = patientVisitProviders.length < 1
        if (readOnly && ((coordinator && noCoordinators) || (!coordinator && noProviders))) {
            return;
        }
        return (
            <Grid
                item xs={12} className='table-accordion'
                md={readOnly && ((!coordinator && noCoordinators) || (coordinator && noProviders)) ? 12 : 6}
            >
                <Card>
                    <div className='card-header'>{coordinator ? 'Coordinator' : 'Provider'}</div>
                    <Formik
                        initialValues={coordinator ? {
                            coordinator: null,
                            effort: remainingCoordinatorEffort < 0 ? 0 : remainingCoordinatorEffort
                        } : {
                            provider: null,
                            effort: remainingProviderEffort < 0 ? 0 : remainingProviderEffort
                        }}
                        onSubmit={coordinator ? handleAddCoordinator : handleAddProvider}
                        validate={handleValidateUser}
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize
                    >
                        {userFormikProps => (
                            <TableContainer className='input-table'>
                                <Table>
                                    {TableHeader(readOnly ? true : false)}
                                    <TableBody>
                                        {(coordinator ? patientVisitCoordinators : patientVisitProviders).map((obj: PatientVisitUser, idx: number) => {
                                            return (
                                                <TableRow key={(coordinator ? 'coordinator-row-' : 'provider-row-') + idx}>
                                                    <TableCell key={'name-' + idx} className="body-cell" style={{ width: '60%' }}>{DisplayTextFormat(obj.userName)}</TableCell>
                                                    <TableCell key={'effort-' + idx} className="body-cell">{obj.effort} %</TableCell>
                                                    {!readOnly &&
                                                        <TableCell key='header-actions' className="body-cell center" style={{ width: '10%' }}>
                                                            <IconButton
                                                                aria-controls="more-actions-menu"
                                                                aria-haspopup="true"
                                                                onClick={(event) => handleDeleteEmployee(event, obj, coordinator)}
                                                            >
                                                                <Close />
                                                            </IconButton>
                                                        </TableCell>
                                                    }
                                                </TableRow>
                                            )
                                        })}
                                        {!readOnly && (coordinator ? patientVisitCoordinatorOptions : patientVisitProviderOptions).length > 0 &&
                                            <TableRow>
                                                <TableCell className='body-cell left' style={{ width: '60%' }}>
                                                    <CheckedAutocomplete
                                                        idText={coordinator ? 'coordinator' : 'provider'}
                                                        multiple={false}
                                                        acValue={coordinator ? selectedCoordinator : selectedProvider}
                                                        options={coordinator ? patientVisitCoordinatorOptions : patientVisitProviderOptions}
                                                        onChange={(value: any) => handleChangeEmployee(value, (coordinator ? 'C' : 'P'))}
                                                    />
                                                </TableCell>
                                                <TableCell className='body-cell left'>
                                                    <BTextField
                                                        name='effort'
                                                        label=''
                                                        placeholder='0'
                                                        variant='standard'
                                                        margin='none'
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                            inputComponent: NumberFormatter as any,
                                                            inputProps: { integer: true }
                                                        }}
                                                        required
                                                    />
                                                </TableCell>
                                                <TableCell className='body-cell center' style={{ width: '10%' }}>
                                                    <BButton
                                                        variant='text'
                                                        className={(coordinator ? selectedCoordinator : selectedProvider) != null && userFormikProps.values.effort ? 'pulse-btn' : 'disabled-btn'}
                                                        onClick={() => {
                                                            if (coordinator) { handleAddCoordinator(userFormikProps.values, userFormikProps) }
                                                            if (!coordinator) { handleAddProvider(userFormikProps.values, userFormikProps) }
                                                        }}
                                                    >
                                                        Add
                                                    </BButton>
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Formik>
                </Card>
            </Grid>
        )
    }

    const getScheduledVisitName = (scheduledVisitId: number | undefined | null) => {
        if (!scheduledVisitId) return '';
        const scheduledVisit = _.find(scheduledPatientVisitsData.scheduledVisits, ['id', scheduledVisitId]);
        return scheduledVisit ? scheduledVisit.visitName : '';
    }

    const cardTitle = () => {
        if (patientVisit.id) {
            if (lineItemName) {
                return (
                    <div className="aligned-row">
                        <span>Line Item Info</span>
                        <NavigateNext className="arrow" />
                        <span>Scheduled Patient Visit</span>
                    </div>
                )
            }
            return ('Edit Patient Visit: ' + patientVisit.visitName);
        }
        return ('Log Patient Visit: ' + getScheduledVisitName(patientVisit.scheduledVisitId) );
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleModalClose}
            >
                <div className="modal-form">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validate={handleValidatePatientVisit}
                        validateOnChange={false}
                        valid
                        enableReinitialize
                    >
                        {formikProps => (
                            <BForm>
                                <Card className="detail-form lg">
                                    <CardHeader
                                        title={cardTitle()}
                                        action={
                                            <IconButton color="primary" aria-label="Close" onClick={handleModalClose}>
                                                <Close />
                                            </IconButton>
                                        }
                                    />
                                    <CardContent>
                                        <div className='form-section'>
                                            <Typography className="title">Basic Information</Typography>
                                            <BGrid container gridClassName='body' spacing={2}>
                                                <BTextField
                                                    name="identifier"
                                                    disabled
                                                    xs={12} sm={6} noMP
                                                />
                                                <BDatePicker
                                                    name="dateOfService"
                                                    label="Date of Service"
                                                    inputVariant="outlined"
                                                    autoFocus
                                                    disabled={isCreating ? false : (readOnly || !canUpdatePatientVisit)}
                                                    onAccept={(date) => {
                                                        setEnteredDateOfService(date);
                                                        setUnsavedChanges(true);
                                                    }}
                                                    onBlur={event => {
                                                        setEnteredDateOfService(new Date(event.target.value));
                                                        setUnsavedChanges(true);
                                                    }}
                                                    xs={12} sm={6} noMP
                                                />
                                                <BTextField
                                                    name="note"
                                                    label="Notes (Publicly Viewable)"
                                                    placeholder="Notes (Publicly Viewable)"
                                                    multiline rows={2}
                                                    disabled={isCreating ? false : (readOnly || !canUpdatePatientVisit)}
                                                    xs={12} noMP
                                                    onChange={(event) => { setVisitNote(event.target.value); setUnsavedChanges(true) }}
                                                />
                                            </BGrid>
                                        </div>
                                        <div className='form-section not-first'>
                                            {!readOnly && <Grid container justifyContent='space-between' alignItems='center'>
                                                <Typography className="title">Employees</Typography>
                                                {!lineItemName &&
                                                    <div className='row multiple-switch'>
                                                        <Switch checked={multipleEmployees} onClick={() => setMultipleEmployees(!multipleEmployees)} />
                                                        <Typography>Multiple Employees</Typography>
                                                    </div>
                                                }
                                            </Grid>}
                                            <BError id="employees" name="employees" />
                                            <BGrid container gridClassName='body'>
                                                {multipleEmployees ? <>
                                                    {employeeTableForm(true)}
                                                    {employeeTableForm(false)}
                                                </> :
                                                    <Grid item xs={12} className='single-employee-input'>
                                                        <CheckedAutocomplete
                                                            idText={'employee'}
                                                            labelText='Employee'
                                                            multiple={false}
                                                            disabled={isCreating ? false : !canUpdatePatientVisit}
                                                            acValue={selectedSingleEmployee}
                                                            options={patientVisitEmployeeOptions}
                                                            onChange={(value: any) => handleChangeEmployee(value, 'S')}
                                                            variant='outlined'
                                                        />
                                                    </Grid>
                                                }
                                            </BGrid>
                                        </div>
                                        <div className='form-section not-first'>
                                            <Typography className="title">Procedures Completed</Typography>
                                            <BError id="procedures" name="procedures" />
                                            <BGrid container gridClassName='body procedures'>
                                                <FormControl component="fieldset">
                                                    <FormGroup row>
                                                        <Grid container>
                                                            {chosenProcedures && allProceduresFromContract.map((procedure: ShortProcedure, idx: number) => {
                                                                return (
                                                                    <Grid item xs={12} sm={6} lg={4} key={'procedure-' + procedure.id + 'idx-' + idx}>
                                                                        <FormControlLabel
                                                                            value={procedure.name}
                                                                            control={
                                                                                <Checkbox
                                                                                    color="primary"
                                                                                    checked={formikProps.values.procedures.includes(procedure.id!)}
                                                                                    onChange={isCreating || canUpdatePatientVisit ? handleCheckboxChange(procedure.id!, formikProps.setFieldValue, formikProps.values) : undefined}
                                                                                    disabled={isCreating ? false : !canUpdatePatientVisit}
                                                                                />
                                                                            }
                                                                            label={procedure.name + ' (' + procedure.resourceType + ')'}
                                                                            labelPlacement="end"
                                                                        />
                                                                    </Grid>
                                                                );
                                                            })}
                                                        </Grid>
                                                    </FormGroup>
                                                </FormControl>
                                            </BGrid>
                                        </div>
                                    </CardContent>
                                    {!readOnly &&
                                        <CardActions className='space-between'>
                                            {patientVisit.id && canDeletePatientVisit ?
                                                <BButton
                                                    onClick={() => setDeleteOpen(true)}
                                                    className='delete-button'
                                                >
                                                    Delete
                                                </BButton> : <div />
                                            }
                                            <BSubmit
                                                variant="contained"
                                                color="primary"
                                                disabled={!canUpdatePatientVisit}
                                            >
                                                Submit
                                            </BSubmit>
                                        </CardActions>
                                    }
                                </Card>
                            </BForm>
                        )}
                    </Formik>
                </div>
            </Modal>
            {patientVisit.visitName &&
                <SimpleConfirmDelete
                    type='Scheduled Patient'
                    open={deleteOpen}
                    setOpen={setDeleteOpen}
                    objectName={patientVisit.visitName}
                    handleDelete={handleDelete}
                />
            }
            {dialogOpen &&
                <VisitModalWarning
                    unsavedChanges={unsavedChanges && !readOnly}
                    dialogOpen={dialogOpen}
                    handleDialogClose={handleDialogClose}
                />
            }
        </>
    )
}
export default ScheduledVisitsModal;
import * as React from 'react';
import { useParams } from 'react-router-dom';

import InvoiceDetail from './Invoice/InvoiceDetail';
import InvoiceList from './Invoice/InvoiceList';

interface RouteParams {
    section: string;
    id?: string;
}

const Invoicing: React.FC = props => {
    const { id } = useParams<RouteParams>();

    return (
        <div className="page">
            <div className="page-content">
                <div className="inner">
                    {id ?
                        <InvoiceDetail id={+id} /> :
                        <InvoiceList />
                    }
                </div>
            </div>
        </div>
    );
}

export default Invoicing;
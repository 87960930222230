import * as React from 'react';
import { TableCell, TableRow, Typography } from '@material-ui/core';

import { ShortScheduledVisit } from '../../../../@types';
import { DisplayTextFormat } from '../../../../common/Utils/DisplayTextFormat';


interface TotalTableHeaderProps {
    scheduledVisits: ShortScheduledVisit[];
}

const TotalsTableHeader: React.FC<TotalTableHeaderProps> = props => {
    const { scheduledVisits } = props;
    return (
        <TableRow>
            <TableCell
                key={'header-totals-empty-cell'}
                style={{ position: 'sticky', left: '0', backgroundColor: '#ffffff' }}
                colSpan={3}
            />
            <TableCell
                key={'header-resource'}
                className={'totals-grid-header-total right'}
                style={{left: '370px'}}
                colSpan={2}
            >
                <Typography variant="body2">Total</Typography>
            </TableCell>
            {scheduledVisits.map((sv, idx) => {
                return (
                    <TableCell
                        key={'header-' + idx + '-sv-' + sv.id}
                        className={'totals-grid-sv-header'}
                    >
                        <div className={'center'}>
                            <Typography variant="body2">{DisplayTextFormat(sv.visitName)}</Typography>
                        </div>
                    </TableCell>
                )
            })}
        </TableRow>
    )
}
export default TotalsTableHeader;
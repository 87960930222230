import * as React from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { Card, CardActions, CardContent, CardHeader, Grid, IconButton, Modal } from '@material-ui/core';
import { Close, Delete, Save } from '@material-ui/icons';
import { BButton, BForm, BSelect, BSubmit, BTextField } from 'mui-bueno';

import { Division, SelectOption, Study } from '../../../../@types';
import { showSuccessSnackbar } from '../../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../../service/utils';
import { updateStudy } from '../../../../service/Study/study';
import { getRestrictedDivisions } from '../../../../service/Management/divisions';


interface Props {
    open: boolean;
    setOpen: any;
    study: Study;
    setStudy: any;
    setDelete: any;
    permissionDelete: boolean;
    signed: boolean;
    setUpdatedInfo: any;
}

const schema = yup.object<Study>().shape({
    name: yup.string().required('Name is required'),
    identifier: yup.string().required('Identifier is required'),
    irbNo: yup.string().required('IRB Number is required'),
    studyId: yup.string().required('Study ID is required'),
    divisionId: yup.string().required('Division ID is required')
});

const ContractEditInfo: React.FC<Props> = props => {
    const { open, setOpen, study, setStudy, setDelete, permissionDelete, signed, setUpdatedInfo } = props;

    const dispatcher = useDispatch();

    const [divisions, setDivisions] = React.useState<SelectOption<number>[]>([]);

    React.useEffect(() => {
        getRestrictedDivisions()
            .then((res: { data: Division[] }) => {
                // For each division, store the division as a SelectOption
                const tempDivisions: SelectOption<number>[] = [];
                res.data.forEach((division: Division) => {
                    if (division.id) {
                        tempDivisions.push({
                            value: division.id,
                            label: division.name
                        })
                    }
                });
                setDivisions(tempDivisions);
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    prefix: 'Could not retrieve list of Divisions: '
                });
            });
    }, [study]);

    const handleValidate = (data: Study) => {
        const errorList: { [k: string]: string } = {};
        schema.validate(data, { abortEarly: false })
            .catch((err: yup.ValidationError) => {
                for (const e of err.inner) {
                    if (e.path) errorList[e.path] = e.message;
                }
            });
        return errorList;
    }

    const handleSubmit = async (data: Study, { setErrors }: FormikHelpers<Study>) => {
        updateStudy({ ...data, id: study.id })
            .then(res => {
                dispatcher(showSuccessSnackbar(`Contract/Study ${study.name} updated`));
                setStudy(res.data);
                handleModalClose();
                setUpdatedInfo(true);
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    setStatus: setErrors,
                    prefix: 'Could not update Contract/Study info: '
                })
            });
    }

    const handleModalClose = () => {
        setOpen(false);
    }

    return (
        <Modal
            open={open}
            onClose={handleModalClose}
        >
            <div className="modal-form">
                <Formik
                    initialValues={{ ...study }}
                    onSubmit={handleSubmit}
                    validate={handleValidate}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    <BForm>
                        <Card className="detail-form sm">
                            <CardHeader
                                title="Edit Contract Information"
                                action={
                                    <IconButton color="primary" aria-label="Close" onClick={handleModalClose}>
                                        <Close />
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                <Grid container spacing={2} justifyContent='center'>
                                    <BTextField
                                        name='name'
                                        label='Name'
                                        placeholder='Name'
                                        required autoFocus
                                        xs={12} sm={10} noMP
                                    />
                                    <BTextField
                                        name='identifier'
                                        label='Identifier'
                                        placeholder='Identifier'
                                        required
                                        xs={12} sm={10} noMP
                                    />
                                    <BTextField
                                        name='irbNo'
                                        label='IRB Number'
                                        placeholder='IRB Number'
                                        xs={12} sm={10} noMP
                                    />
                                    <BTextField
                                        name='studyId'
                                        label='Study Number'
                                        placeholder='Study Number'
                                        xs={12} sm={10} noMP
                                    />
                                    <BTextField
                                        name='sponsorIdentifier'
                                        label='Sponsor Identifier'
                                        placeholder='Sponsor Identifier'
                                        xs={12} sm={10} noMP
                                    />
                                    <BSelect
                                        name='divisionId'
                                        label='Division'
                                        placeholder='Division'
                                        options={divisions}
                                        variant='outlined'
                                        required
                                        xs={12} sm={10} noMP
                                    />
                                </Grid>
                            </CardContent>
                            <CardActions className="space-between">
                                {(permissionDelete && !signed) ?
                                    <BButton
                                        startIcon={<Delete />}
                                        variant="contained"
                                        className="delete-color"
                                        onClick={() => {
                                            handleModalClose();
                                            setDelete(true);
                                        }}
                                    >
                                        Delete
                                    </BButton> : <div />}
                                <BSubmit
                                    startIcon={<Save />}
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </BSubmit>
                            </CardActions>
                        </Card>
                    </BForm>
                </Formik>
            </div>
        </Modal>
    )
};

export default ContractEditInfo;

import * as React from 'react';
import { useDispatch } from 'react-redux';

import { Card, CardActions, Chip, Typography, IconButton, Tooltip } from '@material-ui/core';
import { Add, Close, Save } from '@material-ui/icons';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { BForm, BGrid, BSubmit, BDatePicker, BTextField } from 'mui-bueno';
import { Formik, FormikHelpers } from 'formik';

import { SimulatorSalaryLog, SimulatorUserWithSalaryLogs } from '../../../../../@types';
import { removeDetailedEmployee, updateDetailedEmployee } from '../../../../../service/Simulator/simulator';
import { handleErrorResponse } from '../../../../../service/utils';
import { showSuccessSnackbar } from '../../../../../modules/messageSnackbarReducer';
import { updateSimulatorCharts } from '../../../../../modules/simulatorReducer';
import usePermissions from '../../../../../common/hooks/usePermissions';
import { displayDate, getLocalDate } from '../../../../../common/Utils/utils';
import SimpleConfirmDelete from '../../../../../common/ConfirmDelete/SimpleConfirmDelete';
import EmployeeDetailedSalaryLogModal from './SalaryLog/EmployeeDetailedSalaryLogModal';
import { } from 'recharts';


interface Props {
    simUser: SimulatorUserWithSalaryLogs;
    setUpdated: () => void;
}

const EmployeeDetailedCard: React.FC<Props> = (props) => {
    const { simUser, setUpdated } = props;
    const dispatch = useDispatch();

    const [simResourceType, setSimResourceType] = React.useState<string>(simUser.simulatorUser.resourceType);
    const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);
    const [salaryLogOpen, setSalaryLogOpen] = React.useState<boolean>(false);
    const [selectedSalaryLog, setSelectedSalaryLog] = React.useState<SimulatorSalaryLog | null>(null);

    const canUpdateDetailed = usePermissions('API_SIMULATOR_UPDATE_DETAILED_EMPLOYEE');
    const canRemoveDetailed = usePermissions('API_SIMULATOR_DELETE_DETAILED_EMPLOYEE');

    const initialValues: SimulatorUserWithSalaryLogs = {
        simulatorUser: {
            id: simUser.simulatorUser.id,
            simulatorId: simUser.simulatorUser.simulatorId,
            userId: simUser.simulatorUser.userId,
            userName: simUser.simulatorUser.userName,
            started: getLocalDate(simUser.simulatorUser.started),
            ended: simUser.simulatorUser.ended ? getLocalDate(simUser.simulatorUser.ended) : null,
            resourceType: simUser.simulatorUser.resourceType
        },
        salaryLogs: simUser.salaryLogs
    };

    const handleRemoveUser = async (simUserId: number) => {
        removeDetailedEmployee(simUserId).then(() => {
            dispatch(showSuccessSnackbar('Detailed Employee removed'));
            setUpdated();
            dispatch(updateSimulatorCharts());
        }).catch(err => {
            handleErrorResponse(err, dispatch, {
                prefix: 'Could not remove Detailed Employee: '
            });
        });
        setDeleteOpen(false);
    };

    const handleSubmit = async (data: SimulatorUserWithSalaryLogs, { setErrors }: FormikHelpers<any>) => {
        updateDetailedEmployee({
            id: data.simulatorUser.id,
            simulatorId: data.simulatorUser.simulatorId,
            userId: data.simulatorUser.userId,
            userName: data.simulatorUser.userName,
            started: data.simulatorUser.started,
            ended: data.simulatorUser.ended,
            resourceType: simResourceType
        }).then(() => {
            dispatch(showSuccessSnackbar('Detailed Employee updated'));
            setUpdated();
            dispatch(updateSimulatorCharts());
        }).catch(err => {
            handleErrorResponse(err, dispatch, {
                setStatus: setErrors,
                prefix: 'Could not update Detailed Employee: '
            });
        });
    }
    
    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
            >
                <BForm>
                    <Card className='employee-detail'>
                        <div className='space-between employee-header'>
                            <div className='left'>
                                {canUpdateDetailed ?
                                    <BTextField
                                        name='simulatorUser.userName'
                                        placeholder='Employee Name'
                                        label=''
                                        required
                                        noGrid
                                        variant='standard'
                                        inputProps={{ style: { fontSize: '1.1em' } }}
                                        disabled={!canUpdateDetailed}
                                    /> :
                                    initialValues.simulatorUser.userName
                                }
                            </div>
                            {!initialValues.simulatorUser.userId &&
                                <div className='right'>
                                    <Chip className='simulated-chip' label='New' />
                                </div>
                            }
                            {canRemoveDetailed &&
                                <IconButton size='small' onClick={() => setDeleteOpen(true)}>
                                    <Close />
                                </IconButton>
                            }
                        </div>
                        <BGrid container spacing={1} gridClassName='employee-content' alignment='top'>
                            <BGrid xs={12} noMP gridClassName='employee-type-toggle'>
                                <Typography>
                                    Type
                                </Typography>
                                <ToggleButtonGroup
                                    value={simResourceType}
                                    onChange={((event: any, value: any) => setSimResourceType(value))}
                                    size='small'
                                    exclusive
                                >
                                    <ToggleButton value='Coordinator' disabled={simResourceType === 'Coordinator'}>
                                        <Typography>Coordinator</Typography>
                                    </ToggleButton>
                                    <ToggleButton value='Provider' disabled={simResourceType === 'Provider'}>
                                        <Typography>Provider</Typography>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </BGrid>
                            <BDatePicker
                                name='simulatorUser.started'
                                label='Started Date'
                                staticLabel
                                required
                                noMP xs={6}
                                disabled={!canUpdateDetailed}
                            />
                            <BDatePicker
                                name='simulatorUser.ended'
                                label='Ended Date'
                                staticLabel
                                noMP xs={6}
                                disabled={!canUpdateDetailed}
                            />
                            <BGrid xs={12} noMP gridClassName='employee-salary-logs'>
                                <div className='space-between'>
                                    <Typography>
                                        Salary Logs
                                    </Typography>
                                    <IconButton size='small' onClick={() => setSalaryLogOpen(true)}>
                                        <Add />
                                    </IconButton>
                                </div>
                                {(initialValues.salaryLogs && initialValues.salaryLogs.length > 0) &&
                                    [...initialValues.salaryLogs]
                                        .sort((a, b) => (new Date(a.effectiveDate)).getTime() - (new Date(b.effectiveDate)).getTime())
                                        .map((salaryLog: SimulatorSalaryLog, idx: number) => {
                                            return (
                                                <Chip
                                                    key={`user-${initialValues.simulatorUser.id}-salaryLog-${idx}`}
                                                    label={`${displayDate(salaryLog.effectiveDate, true)}; ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(salaryLog.salary)} (Salary); ${salaryLog.fringe}% (Fringe)`}
                                                    onClick={() => {
                                                        setSelectedSalaryLog(salaryLog);
                                                        setSalaryLogOpen(true);
                                                    }}
                                                />
                                            )
                                        })
                                }
                            </BGrid>
                        </BGrid>
                        {canUpdateDetailed &&
                            <Tooltip arrow title="Saving changes to simulator employees will not affect the employee's data in the CRAFT system.">
                                <CardActions className="flex-end">
                                    <BSubmit
                                        startIcon={<Save />}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Save
                                    </BSubmit>
                                </CardActions>
                            </Tooltip>
                        }
                    </Card>
                </BForm>
            </Formik>
            {initialValues.simulatorUser.id &&
                <SimpleConfirmDelete
                    open={deleteOpen}
                    setOpen={setDeleteOpen}
                    type='User From Study'
                    objectName={initialValues.simulatorUser.userName}
                    handleDelete={() => handleRemoveUser(initialValues.simulatorUser.id)}
                    message={'Do you want to remove ' + initialValues.simulatorUser.userName + ' from the simulation?'}
                    title={'Remove User From Simulation?'}
                    buttonProps={{ children: 'Remove' }}
                />
            }
            <EmployeeDetailedSalaryLogModal
                simulatorUserId={simUser.simulatorUser.id}
                open={salaryLogOpen}
                setOpen={setSalaryLogOpen}
                salaryLog={selectedSalaryLog}
                setSalaryLog={setSelectedSalaryLog}
                setUpdated={() => setUpdated()}
            />
        </>
    )
}
export default EmployeeDetailedCard;
import { useSelector } from 'react-redux';

import { PermissionConst } from '../../@types';
import { Store } from '../../modules/rootReducer';

const useLoggedInUserPermissions = (permission: PermissionConst, divisionId: number, studyId: number) => {
    const userRolePerms = useSelector<Store, any>(store =>
        store.loginReducer.rolePermissions
    );
    const selectedPermission = userRolePerms[permission];
    if (!selectedPermission) {
        return false; //user has no permission
    }

    const isNoRestriction: boolean =
        (selectedPermission.restrictedDivisions.length === 0 &&
            selectedPermission.restrictedStudies.length === 0);

    const isDivisionRestricted: boolean =
        (divisionId > 0 && selectedPermission.restrictedDivisions.includes(divisionId));

    const isStudyRestricted: boolean =
        (studyId > 0 && selectedPermission.restrictedStudies.includes(studyId));

    return isNoRestriction || isDivisionRestricted || isStudyRestricted;
};

export default useLoggedInUserPermissions;
import axiosInstance from '../Access/axiosInstance';
import { Permission, PermissionList } from '../../@types';
import { handleError } from '../utils';

/**
 * Gets a permission by id
 */
export const getPermission = (id: number) =>
    axiosInstance.get<Permission>(
        `/api/permissions/${id}`
    ).catch(handleError);

/**
 * Gets a list of permissions for use in the RoleDetail page
 */
export const getPermissionList = () =>
    axiosInstance.get<PermissionList[]>(
        `/api/permissions/list`
    ).catch(handleError);

/**
 * Updates a permission
 */
export const updatePermission = (permission: Permission) =>
    axiosInstance.put<Permission>(
        '/api/permissions', permission
    ).catch(handleError);

/**
 * Creates a permission
 */
export const createPermission = (permission: Permission) =>
    axiosInstance.post<Permission>(
        '/api/permissions', permission
    ).catch(handleError);

/**
 * Deletes a permission 
 */
export const deletePermission = (id: number) => 
    axiosInstance.delete(
        `/api/permissions/${id}`
    ).catch(handleError);

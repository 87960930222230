import * as React from 'react';

import { useParams } from 'react-router';

import { AccountBalance, Contacts, People, ViewModule } from '@material-ui/icons';

import UserList from './UserList/UserList';
import RoleList from './RoleList/RoleList';
import UserDetail from './UserDetail/UserDetail';
import RoleDetail from './RoleDetail/RoleDetail';
import DivisionList from './Division/DivisionList';
import AccountList from './Account/AccountList';
import AccountDetail from './Account/AccountDetail';
import CollapsiblePanel, { NavItem } from '../../common/CollapsiblePanel/CollapsiblePanel';

import PaymentDetail from './Account/PaymentDetail';
import usePermissions from '../../common/hooks/usePermissions';

interface RouteParams {
    type: string;
    id?: string;
    option?: string;
    optionId?: string;
}

const Management: React.FC = () => {
    const { type, id, option, optionId } = useParams<RouteParams>();

    const navManagementOptions: NavItem[] = [
        { title: 'Users', icon: <People />, route: '/manage/user' },
        { title: 'Roles', icon: <Contacts />, route: '/manage/role' },
        { title: 'Divisions', icon: <ViewModule />, route: '/manage/division' },
    ];

    if (usePermissions('API_ACCOUNT_GET_ACCOUNT_SUMMARIES'))
        navManagementOptions.push({ title: 'Accounts', icon: <AccountBalance />, route: '/manage/account' });
    
    return (
        <div className="page">
            <CollapsiblePanel navOptions={navManagementOptions}/>
            <div className="page-content">
                <div className="inner">
                    {(type === 'user' && !id) && <UserList />}
                    {(type === 'user' && !!id) && <UserDetail />}
                    {(type === 'role' && !id) && <RoleList />}
                    {(type === 'role' && !!id) && <RoleDetail />}
                    {(type === 'division') && <DivisionList />}
                    {(type === 'account' && !id) && <AccountList />}
                    {(type === 'account' && !!id && !optionId) && <AccountDetail/>}
                    {(type === 'account' && !!id && !!option && !!optionId) && <PaymentDetail/>}
                </div>
            </div>
        </div>
    )
}

export default Management;

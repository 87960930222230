import { BOption } from "mui-bueno";

import {
    Account,
    Calculation,
    DivisionTree,
    EffortLog,
    InvoiceType,
    ReferenceType,
    SelectOption,
    SimulatorSalaryLog,
    SimulatorUser,
    SimulatorUserWithSalaryLogs,
    Study,
    UserSummary
} from "../../@types";


export const displayDate = (date: Date | null, abbrevYear?: boolean): string => {
    const yearPreference = abbrevYear ? '2-digit' : 'numeric';
    // Date provided is null
    if (!date) return '';
    // Date provided does not have a timestamp
    if (date.toString().indexOf("T") === -1) {
        const splitDate: number[] = date.toString().split('-').map(a => +a);
        const newDate = new Date(splitDate[0], (splitDate[1] - 1), splitDate[2]);
        const locale: string = 'en-US';
        return newDate.toLocaleDateString(locale, { year: yearPreference, month: 'numeric', day: 'numeric' });
    } else {
        // Date provided has a timestamp (i.e. created or updated)
        const newDate = new Date(date);
        const locale: string = 'en-US';
        return newDate.toLocaleDateString(locale, { year: yearPreference, month: 'numeric', day: 'numeric' });
    }
};

export const displayDateWithTime = (date: Date, shortFormat?: boolean) => {
    const newDate = new Date(date);
    const locale: string = 'en-US';
    const dateString = newDate.toLocaleDateString(locale, { year: 'numeric', month: 'numeric', day: 'numeric' });
    const timeString = newDate.toLocaleTimeString(locale, { hour: 'numeric', minute: '2-digit' });
    if (shortFormat)
        return dateString + ' ' + timeString;
    else
        return dateString + ' at ' + timeString;
}

export const getLocalDate = (date: Date) => {
    // Date provided does not have a timestamp
    if (date.toString().indexOf("T") === -1) {
        const splitDate: number[] = date.toString().split('-').map(a => +a);
        return new Date(splitDate[0], (splitDate[1] - 1), splitDate[2]);
    } else {
        // Date provided has a timestamp (i.e. created or updated)
        return new Date(date);
    }
};
export function forceStringToUtcDate(date: string) {
    const localDate = new Date(date);
    return forceDateToUtc(localDate);
}

export function forceDateToUtc(localDate: Date) {
    return new Date(
        localDate.getTime() + Math.abs(localDate.getTimezoneOffset() * 60000)
    );
}

export function studyToLocalDate(study: Study): Study {
    return ({
        ...study,
        started: study.started ? getLocalDate(study.started) : null,
        completed: study.completed ? getLocalDate(study.completed) : null
    });
}

export function calculationToLocalDate(calculation: Calculation): Calculation {
    return ({
        ...calculation,
        associatedDate: getLocalDate(calculation.associatedDate)
    });
}

export function effortLogToLocalDate(effortLog: EffortLog): EffortLog {
    return ({
        ...effortLog,
        month: getLocalDate(effortLog.month)
    });
}

export function simulatorUserToLocalDate(simulatorUser: SimulatorUser): SimulatorUser {
    return ({
        ...simulatorUser,
        started: getLocalDate(simulatorUser.started),
        ended: simulatorUser.ended ? getLocalDate(simulatorUser.ended) : null
    });
}

export function simulatorSalaryLogToLocalDate(simulatorSalaryLog: SimulatorSalaryLog): SimulatorSalaryLog {
    return ({
        ...simulatorSalaryLog,
        effectiveDate: getLocalDate(simulatorSalaryLog.effectiveDate)
    });
}

export function simulatorUserWithSalaryLogsToLocalDate(simulatorUserWithSalaryLogs: SimulatorUserWithSalaryLogs): SimulatorUserWithSalaryLogs {
    return ({
        simulatorUser: simulatorUserToLocalDate(simulatorUserWithSalaryLogs.simulatorUser),
        salaryLogs: simulatorUserWithSalaryLogs.salaryLogs ? simulatorUserWithSalaryLogs.salaryLogs.map(simulatorSalaryLog => simulatorSalaryLogToLocalDate(simulatorSalaryLog)) : []
    });
}

export function convertAccountDataToSelectOptions(data: Account[]) {
    const temp: SelectOption<number>[] = [];
    data.forEach((account: Account) => {
        if (account.id) {
            temp.push({
                value: Number(account.id),
                label: account.name
            });
        }
    });
    return temp;
}

export function convertInvoiceTypeDataToBOptions(data: InvoiceType[]) {
    // For each invoice type, store the invoice type as a BOption
    const temp: BOption<string>[] = [];
    data.forEach((type: InvoiceType) => {
        temp.push({
            value: type.id as string,
            label: type.name
        })
    })
    return temp;
}

export function convertReferenceDataToBOptions(referenceData: ReferenceType[]) {
    const temp: BOption<string>[] = [];
    referenceData.forEach((type: ReferenceType) => {
        temp.push({
            value: type.id as string,
            label: type.name
        })
    });
    return temp;
}

export function convertReferenceDataToSelectionOptions(referenceData: ReferenceType[]) {
    const temp: SelectOption<number>[] = [];
    referenceData.forEach((type: ReferenceType) => {
        temp.push({
            value: Number(type.id),
            label: type.name
        })
    });
    return temp;
}

export const isDecimal = (value: string) => {
    const regex = /^\d*\.{0,1}\d*$/;
    return value.match(regex);
}

export const shortUserString = (user: UserSummary, surnameOrdered: boolean = false) => {
    const middleInitial: string = user.middleName ? `${user.middleName?.substring(0, 1).toLocaleUpperCase()}. ` : '';
    if (surnameOrdered) {
        return `${user.lastName}, ${user.firstName} ${middleInitial}`;
    } else {
        return `${user.firstName} ${middleInitial}${user.lastName}`;
    }
}

export const shortMonthString = (month: number, year: number) => {
    return new Date(year, month).toLocaleString('default', { month: 'short' });
}

export function capitalizeFirstLetter([first, ...rest]: string): string {
    return first.toLocaleUpperCase() + rest.join('');
}

function getDivisionIdListWithChildren(list: number[], children: DivisionTree[]): number[] {
    children.forEach(division => {
        list.push(division.id);
        getDivisionIdListWithChildren(list, division.children ?? []);
    });
    return list;
}
/**
 * Returns a list of ids for a DivisionTree and all of it's child elements
 */
export function getDivisionTreeIds(divisionTree: DivisionTree): number[] {
    return getDivisionIdListWithChildren([divisionTree.id], divisionTree.children ?? []);
}

export const commaSeparated = (value: number | string) => {
    const regex = /\B(?=(\d{3})+(?!\d))/g;
    return value.toString().replace(regex, ",");
}

export const INT_MAX = 2147483647;
export const BIG_DECIMAL_MAX = 99999999999.9999;

export const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
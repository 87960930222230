import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Card, Grid, IconButton, Link, Tooltip, Typography } from '@material-ui/core';
import { Edit, Info, NavigateNext } from '@material-ui/icons';

import { Invoice, InvoiceStatus, InvoiceSummary, LineItemDetailWithInvoice, LineItemTable } from '../../../@types';
import { renderDescriptionCell } from '../../../common/DataTable/Utils';
import { NumberFormatter } from '../../../common/Utils/NumberFormatter';
import { DisplayTextFormat } from '../../../common/Utils/DisplayTextFormat';
import { displayDate } from '../../../common/Utils/utils';
import { handleErrorResponse } from '../../../service/utils';
import { getInvoiceLineItemDetails, getInvoiceSummary } from '../../../service/Invoice/invoice';

import StatusChip from '../../../common/Invoicing/StatusChip';
import DownloadPdf from './DownloadPdf';
import MarkAsSentModal from './MarkAsSentModal';
import PaymentsForInvoice from './PaymentsForInvoice';
import EditInvoiceDesc from '../Invoice/EditInvoiceDesc';
import LogPayment from '../Payment/LogPayment';
import InvoiceablesTable from '../../../common/Invoicing/InvoiceablesTable';
import ViewSourceModal from './ViewSourceModal';
import usePermissions from '../../../common/hooks/usePermissions';

interface Props {
    id: number;
}

const shouldDisableEdit: InvoiceStatus[] = ['NOT_PAID', 'PAID', 'CURRENT', 'PARTIAL']
const shouldDisablePayment: InvoiceStatus[] = ['PAID', 'NOT_SENT']


const InvoiceDetail: React.FC<Props> = props => {
    const { id } = props;

    const dispatch = useDispatch();
    const history = useHistory();

    const initialInvoiceSummary: InvoiceSummary = {
        invoiceDetail: {
            invoice: {
                id: -1,
                invoiceNo: '',
                amount: 0,
                amountMinusHoldback: 0,
                description: '',
                autopayable: false,
                createdDate: new Date(),
                lineItemIds: [],
                payingAccountId: 0,
                receivingAccountId: 0,
                invoiceStatus: 'NOT_SENT',
                studyId: 0
            },
            studyName: '',
            payingAccountName: '',
            payingAccountInternal: true,
            receivingAccountName: '',
            receivingAccountInternal: true,
            checkNumbers: []
        },
        divisionName: '',
        amountPaid: 0
    };

    const [invoiceSummary, setInvoiceSummary] = React.useState<InvoiceSummary>({ ...initialInvoiceSummary });
    const [summaryModalOpen, setSummaryModalOpen] = React.useState<boolean>(false);
    const [markAsSentModalOpen, setMarkAsSentModalOpen] = React.useState<boolean>(false);
    const [logPaymentModalOpen, setLogPaymentModalOpen] = React.useState<boolean>(false);
    const [paymentsModalOpen, setPaymentsModalOpen] = React.useState<boolean>(false);
    const [editLineItemOpen, setEditLineItemOpen] = React.useState<boolean>(false);
    const [tableData, setTableData] = React.useState<LineItemTable[]>([]);
    const [updateOnChange, setUpdateOnChange] = React.useState<boolean>(false);
    const [viewSourceOpen, setViewSourceOpen] = React.useState<boolean>(false);
    const [clickedObj, setClickedObj] = React.useState<LineItemDetailWithInvoice | null>(null);

    const canLogPayment: boolean = usePermissions('API_ACCOUNT_PAYMENT_LOG_PAYMENT');
    const canViewAccountDetails = usePermissions('API_ACCOUNT_GET_BY_ID');
    const canMarkAsSent = usePermissions('API_INVOICE_MARK_AS_SENT');
    const canEditDescription = usePermissions('API_INVOICE_UPDATE_DESCRIPTION');

    React.useEffect(() => {
        getInvoiceSummary(id)
            .then(res => {
                setInvoiceSummary(res.data);
            })
            .catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve Invoice Summary: '
                })
            });
    }, [updateOnChange]);

    React.useEffect(() => {
        getInvoiceLineItemDetails(id)
            .then(res => {
                setTableData(toLineItemTable(res.data));
            })
            .catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve list of Line items: '
                })
            });
    }, [editLineItemOpen, invoiceSummary]);

    const setInvoice = (invoice: Invoice): void =>
        setInvoiceSummary({
            ...invoiceSummary,
            invoiceDetail: {
                ...invoiceSummary.invoiceDetail,
                invoice: invoice
            }
        });

    const toLineItemTable = (list: LineItemDetailWithInvoice[]): LineItemTable[] => [...list].map(lineItem => {
        return ({
            id: lineItem.lineItemId,
            status: lineItem.status,
            datePaid: lineItem.paidDate,
            datePaidMinusHoldback: lineItem.paidDateMhb,
            description: renderDescriptionCell(lineItem, setClickedObj, setViewSourceOpen, viewSourceOpen),
            amount: lineItem.actualUnitCost * (lineItem.unitCount ?? 1),
            payor: invoiceSummary.invoiceDetail.payingAccountName,
            payee: invoiceSummary.invoiceDetail.receivingAccountName,
            sourceType: lineItem.sourceType,
            sourceId: lineItem.sourceId
        });
    }) ?? [];

    const handleEditDescription = (): void => setSummaryModalOpen(true);
    const handleMarkAsSent = (): void => setMarkAsSentModalOpen(true);
    const handleLogAPayment = (): void => setLogPaymentModalOpen(true);
    const handleViewAllPayments = (): void => setPaymentsModalOpen(true);

    const paymentTooltip1 = `Displays all payments applied to an invoice. \n\n Payments pay off the oldest line items first. Payments that are insufficent to cover an entire line item will be logged as credits for ${invoiceSummary.invoiceDetail.payingAccountName}. \n\nClick `
    const paymentTooltip2 = ` to view all payments and credits for ${invoiceSummary.invoiceDetail.payingAccountName}.`

    const paymentButtonDisplay = () => {

        const title = invoiceSummary.invoiceDetail.invoice.invoiceStatus == 'CURRENT' ? 'Log Holdback Payment' : 'Log Received Payment'

        if (canLogPayment) {
            return (
                <Button
                    onClick={handleLogAPayment}
                    variant="contained"
                    color="primary"
                    disabled={shouldDisablePayment.includes(invoiceSummary.invoiceDetail.invoice.invoiceStatus)}
                >
                    {title}
                </Button>
            )
        } else {
            return (
                <Typography className="value"> Not Paid </Typography>
            )
        }
    }

    const markAsSentButton = () => {
        const className = invoiceSummary.invoiceDetail.invoice.invoiceStatus == 'NOT_SENT' ? 'contained-button-pulse' : '';

        return (
            canMarkAsSent?
                <Button
                    className={className}
                    onClick={handleMarkAsSent}
                    variant='contained'
                    color='primary'
                    disabled={invoiceSummary.invoiceDetail.invoice.invoiceStatus === 'PAID'}
                >
                    Mark as Sent
                </Button>
                : <Typography className='value'>Not Sent</Typography>
        )

    }

    const paymentTooltipTitle = (
        <div className='payment-tooltip'>
            {paymentTooltip1}
            <a href={`/manage/account/${invoiceSummary.invoiceDetail.invoice.payingAccountId}/payments`}>
                here
            </a>
            {paymentTooltip2}
        </div>
    )

    const PayorName = (
        <Typography className='value'>
            {DisplayTextFormat(invoiceSummary.invoiceDetail.payingAccountName ?? '')}
        </Typography>
    )

    const PayeeName = (
        <Typography className='value'>
            {DisplayTextFormat(invoiceSummary.invoiceDetail.receivingAccountName ?? '')}
        </Typography>
    )

    return (
        <>
            <div className='page-heading'>
                <Typography
                    className='title inactive cursor-pointer'
                    onClick={() => history.push('/invoicing')}
                >
                    Invoicing
                </Typography>
                <NavigateNext className='arrow' />
                <Typography className='title'>{`Invoice #${invoiceSummary.invoiceDetail.invoice.invoiceNo}`}</Typography>
            </div>
            <Grid container spacing={2} className='invoice-detail'>
                <Grid item xs={12} lg={6} className='info-card'>
                    <div className='aligned-row space-between info-header'>
                        <Typography className='title'>Invoice Details</Typography>
                        <DownloadPdf
                            type='INVOICE'
                            id={invoiceSummary.invoiceDetail.invoice.id ?? undefined}
                            fileName={`${invoiceSummary.invoiceDetail.invoice.invoiceNo}.pdf`}
                        />
                    </div>
                    <Card className='info-body'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} >
                                <Typography className='label'>Invoice Number</Typography>
                                <Typography className='value'>{DisplayTextFormat(invoiceSummary.invoiceDetail.invoice.invoiceNo)}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography className='label'>Status</Typography>
                                <div className='value'>
                                    <StatusChip status={invoiceSummary.invoiceDetail.invoice.invoiceStatus ?? 'NOT_SENT'} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography className='label'>Study</Typography>
                                <Link className='cursor-pointer' onClick={() => history.push(`/study/${invoiceSummary.invoiceDetail.invoice.studyId}/overview`)}>
                                    <Typography className='value'>{DisplayTextFormat(invoiceSummary.invoiceDetail.studyName)}</Typography>
                                </Link>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography className='label'>Created</Typography>
                                <Typography className='value'>
                                    {displayDate(invoiceSummary.invoiceDetail.invoice.createdDate)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography className='label'>Division</Typography>
                                <Typography className='value'>{DisplayTextFormat(invoiceSummary.divisionName)}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {(invoiceSummary.invoiceDetail.receivingAccountInternal
                                    || invoiceSummary.invoiceDetail.invoice.invoiceStatus != 'NOT_PAID'
                                    || !!invoiceSummary.invoiceDetail.invoice.sentDate) &&
                                    <>
                                        <div className='label-icon-div'>
                                            {invoiceSummary.invoiceDetail.invoice.sentDate
                                                ? <Typography className='label'>Marked as Sent</Typography>
                                                : <Typography className='label'>Sent</Typography>
                                            }
                                            <Tooltip arrow placement='bottom-start' title={'This date is meant to record when the invoice was sent. CRAFT does not send invoices.'} interactive>
                                                <Info className='info' />
                                            </Tooltip>
                                        </div>
                                        <Typography className='value'>
                                            {invoiceSummary.invoiceDetail.invoice.sentDate
                                                ? displayDate(invoiceSummary.invoiceDetail.invoice.sentDate)
                                                : markAsSentButton()
                                            }
                                        </Typography>
                                    </>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <div className='label-icon-div'>
                                    <Typography className='label'>Description</Typography>
                                    {canEditDescription &&
                                        <IconButton
                                            color='primary'
                                            aria-label='Edit'
                                            onClick={handleEditDescription}
                                            className='edit-desc'
                                        >
                                            <Edit fontSize='small' />
                                        </IconButton>
                                    }
                                </div>
                                {invoiceSummary.invoiceDetail.invoice.description ?
                                    <Typography className='value' style={{ whiteSpace: 'pre-line', overflowY: 'auto', maxHeight: '14vh' }}>{invoiceSummary.invoiceDetail.invoice.description}</Typography>
                                    : <Typography className='value transparent'>Click the icon above to add a description...</Typography>
                                }
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={6} className='info-card'>
                    <div className='aligned-row info-header'>
                        <Typography className='title'>Payment Details</Typography>
                    </div>
                    <Card className='info-body'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography className='label'>Payee</Typography>
                                {canViewAccountDetails ?
                                    <Link className='cursor-pointer' onClick={() => history.push(`/manage/account/${invoiceSummary.invoiceDetail.invoice.receivingAccountId}`)}>
                                        {PayeeName}
                                    </Link>
                                    : PayeeName
                                }
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography className='label'>Total Amount Minus Holdback</Typography>
                                <Typography className='value'>
                                    <NumberFormatter currency value={invoiceSummary.invoiceDetail.invoice.amountMinusHoldback} />
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography className='label'>Payor</Typography>
                                {canViewAccountDetails ?
                                    <Link className='cursor-pointer' onClick={() => history.push(`/manage/account/${invoiceSummary.invoiceDetail.invoice.payingAccountId}`)}>
                                        {PayorName}
                                    </Link>
                                    : PayorName
                                }
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography className='label'>Total Amount</Typography>
                                <Typography className='value'>
                                    <NumberFormatter currency value={invoiceSummary.invoiceDetail.invoice.amount} />
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography className='label'>Paid</Typography>
                                <Typography className="value">
                                    {invoiceSummary.invoiceDetail.invoice.paidDate
                                        ? displayDate(invoiceSummary.invoiceDetail.invoice.paidDate)
                                        : paymentButtonDisplay()
                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography className='label'>Amount Applied</Typography>
                                <Typography className='value'>
                                    <NumberFormatter currency value={invoiceSummary.amountPaid} />
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className='label-icon-div'>
                                    <Typography className='label'>Payments</Typography>
                                    <Tooltip arrow placement='bottom-start' title={paymentTooltipTitle} interactive>
                                        <Info className='info' />
                                    </Tooltip>
                                </div>
                                <Typography className='value'>
                                    <Button
                                        onClick={handleViewAllPayments}
                                        variant='contained'
                                        color='primary'
                                    >
                                        View Applied Payments
                                    </Button>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} className='info-card'>
                    <Typography className='title'>Line Items</Typography>
                    <InvoiceablesTable
                        type='line-items'
                        data={tableData}
                        showColumns={['status', 'datePaidMinusHoldback', 'datePaid' , 'description', 'amount']}
                        disableEdit={shouldDisableEdit.includes(invoiceSummary.invoiceDetail.invoice.invoiceStatus)}
                        editLineItemOpen={editLineItemOpen}
                        setEditLineItemOpen={setEditLineItemOpen}
                        updateOnChange={updateOnChange}
                        setUpdateOnChange={setUpdateOnChange}
                        mode='full'
                    />
                </Grid>
            </Grid>
            <EditInvoiceDesc
                open={summaryModalOpen}
                setOpen={setSummaryModalOpen}
                updateOnChange={updateOnChange}
                setUpdateOnChange={setUpdateOnChange}
                invoice={invoiceSummary.invoiceDetail.invoice}
            />
            <MarkAsSentModal
                open={markAsSentModalOpen}
                setOpen={setMarkAsSentModalOpen}
                invoice={invoiceSummary.invoiceDetail.invoice}
                setInvoice={setInvoice}
            />
            <LogPayment
                open={logPaymentModalOpen}
                setOpen={setLogPaymentModalOpen}
                payingAccountId={invoiceSummary.invoiceDetail.invoice.payingAccountId}
                receivingAccountId={invoiceSummary.invoiceDetail.invoice.receivingAccountId}
                studyId={invoiceSummary.invoiceDetail.invoice.studyId}
                invoiceIds={[invoiceSummary.invoiceDetail.invoice?.id!]}
                logHoldback={invoiceSummary.invoiceDetail.invoice.invoiceStatus == 'CURRENT'}
                receivedPayment
                updateOnChange={updateOnChange}
                setUpdateOnChange={setUpdateOnChange}
            />
            {clickedObj && <ViewSourceModal
                open={viewSourceOpen}
                setOpen={setViewSourceOpen}
                lineItem={clickedObj}
            />}
            <PaymentsForInvoice
                open={paymentsModalOpen}
                setOpen={setPaymentsModalOpen}
                invoiceId={invoiceSummary.invoiceDetail.invoice.id ?? -1}
                invoiceNo={invoiceSummary.invoiceDetail.invoice.invoiceNo}
            />
        </>
    );
}

export default InvoiceDetail;
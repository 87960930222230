import axiosInstance from '../Access/axiosInstance';
import { ScheduledVisit, Procedure, PatientVisit, ScheduleOfEventAssociation } from '../../@types';
import { handleError } from '../utils';

/**
 * Gets a scheduled visit by id
 */
export const getScheduledVisit = (id: number) =>
    axiosInstance.get<ScheduledVisit>(
        `/api/scheduledVisits/${id}`
    ).catch(handleError);

/**
 * Updates a scheduled visit
 */
export const updateScheduledVisit = (scheduledVisit: ScheduledVisit) =>
    axiosInstance.put<ScheduledVisit>(
        '/api/scheduledVisits', scheduledVisit
    ).catch(handleError);

/**
 * Creates a scheduled visit
 */
export const createScheduledVisit = (scheduledVisit: ScheduledVisit) =>
    axiosInstance.post<ScheduledVisit>(
        '/api/scheduledVisits', scheduledVisit
    ).catch(handleError);

/**
 * Deletes a scheduled visit
 */
export const deleteScheduledVisit = (id: number) =>
    axiosInstance.delete(
        `/api/scheduledVisits/${id}`
    ).catch(handleError);

/**
 * Gets a list of associated procedures from a scheduled visit
 */
export const getProceduresFromScheduledVisits = (id: number) =>
    axiosInstance.get<Procedure[]>(
        `/api/scheduledVisits/procedures/${id}`
    ).catch(handleError);

/**
 * Gets number of assoicated patient visits from a scheduled visit
 */
export const getPatientVisits = (id: number) =>
    axiosInstance.get<PatientVisit[]>(
        `/api/scheduledVisits/patientVisits/${id}`
    ).catch(handleError);

/**
 * Gets scheduled visits by contract id
 */
export const getScheduledVisitsByContract = (contractId: number) =>
    axiosInstance.get<ScheduledVisit[]>(
        `/api/scheduledVisits/contract/${contractId}`
    ).catch(handleError);

/**
 * Updates the schedule of event associations
 */
export const updateScheduleOfEventAssociations = (associations: ScheduleOfEventAssociation[]) =>
    axiosInstance.post(
        '/api/scheduledVisits/associations', associations
    ).catch(handleError);

/**
 * Reorder the list of scheduled visits
 */
export const reorderScheduledVisits = (contractId: number, scheduledVisitIds: number[]) =>
    axiosInstance.put(
        `/api/scheduledVisits/reorder/contract/${contractId}`, scheduledVisitIds
    ).catch(handleError);
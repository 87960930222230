import * as React from 'react';

import {
    Card, CardActions, CardContent, CardHeader, CircularProgress, Grid, IconButton, Modal, Typography
} from '@material-ui/core';
import { Close, Save } from '@material-ui/icons';

import { Formik } from 'formik';
import { BForm, BSubmit, BSwitch } from 'mui-bueno';

import { Directory } from '../../../../../@types';
import { useDispatch } from 'react-redux';
import { updateDirectoryDeletionSetting } from '../../../../../service/Study/directories';
import { showSuccessSnackbar } from '../../../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../../../service/utils';

interface Props {
    open: any;
    setOpen: any;
    setUpdated: any;
    selectedDirectory: Directory;
    setSelectedDirectory: any;
}

const DeletionSettingModal: React.FC<Props> = props => {
    const { open, setOpen, setUpdated, selectedDirectory, setSelectedDirectory } = props;

    const [saving, setSaving] = React.useState<boolean>(false);

    const dispatcher = useDispatch();

    const handleSubmit = (data: any) => {
        if (!saving && selectedDirectory) {
            setSaving(true);
            updateDirectoryDeletionSetting(selectedDirectory.id!, data.allowedFileDeleted)
                .then(() => {
                    dispatcher(showSuccessSnackbar('Directory deletion setting updated.'));
                    setSelectedDirectory({ ...selectedDirectory, allowedFileDeleted: data.allowedFileDeleted });
                    setUpdated(true);
                    setSaving(false);
                    handleModalClose();
                }).catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        prefix: 'Could not update directory deletion settings: '
                    })
                    setSaving(false);
                });
        }
    }

    const handleModalClose = () => {
        setOpen(false);
    }

    return (
        <Modal
            open={open}
            onClose={handleModalClose}
        >
            <div className="modal-form">
                <Formik
                    initialValues={{ ...selectedDirectory }}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    <BForm>
                        <Card className="detail-form sm">
                            <CardHeader
                                title='Deletion Setting'
                                action={
                                    <IconButton color="primary" aria-label="Close" onClick={handleModalClose}>
                                        <Close />
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                <Grid container spacing={0} justifyContent='center' alignItems='center'>
                                    <Grid item xs={12}>
                                        <Typography className='warning-text title'>
                                            WARNING:
                                        </Typography>
                                        <Typography className='warning-text middle'>
                                            If enabled, users managing this study will be able to
                                            delete the documents inside this directory and the directory itself.
                                        </Typography>
                                    </Grid>
                                    <BSwitch
                                        name="allowedFileDeleted"
                                        label="Enable File and Directory Deletion"
                                        labelPlacement='end'
                                        autoFocus
                                    />
                                </Grid>
                            </CardContent>
                            <CardActions className="flex-end">
                                <BSubmit
                                    startIcon={saving ? null : <Save />}
                                    variant="contained"
                                    color="primary"
                                    disabled={saving}
                                >
                                    {saving && <CircularProgress size={24} className='button-progress' />}
                                    Save
                                </BSubmit>
                            </CardActions>
                        </Card>
                    </BForm>
                </Formik>
            </div>
        </Modal>
    )
};

export default DeletionSettingModal;
import axiosInstance from '../Access/axiosInstance';
import { ReferenceType } from '../../@types';
import { handleError } from '../utils';

/**
 * Gets a list of resource types
 */
export const getResourceTypes = () =>
    axiosInstance.get<ReferenceType[]>(
        '/api/reference/resourceTypes'
    ).catch(handleError);

/**
 * Gets a list of users as reference data
 */
export const getReferenceUsers = () =>
    axiosInstance.get<ReferenceType[]>(
        '/api/users/reference'
    ).catch(handleError);

/**
 * Gets a list of division roles as reference data
 */
export const getReferenceDivisionRoles = () =>
    axiosInstance.get<ReferenceType[]>(
        '/api/roles/division'
    ).catch(handleError);

/**
 * Gets a list of division roles as reference data
 */
export const getReferenceStudyRoles = () =>
    axiosInstance.get<ReferenceType[]>(
        '/api/roles/study'
    ).catch(handleError);
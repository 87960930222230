import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
    DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot,
    Droppable, DroppableProvided, DropResult, ResponderProvided
} from 'react-beautiful-dnd';
import {
    Button, IconButton, Menu, MenuItem, Paper, Table, TableBody, TableCell,
    TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tooltip
} from '@material-ui/core';
import { MoreHoriz, Reorder } from '@material-ui/icons';
import { BForm, BOption, BSubmit } from 'mui-bueno';
import { Formik } from 'formik';

import { SelectOption, StudyUser } from '../../@types';
import { renderEmployeeChip } from './Utils';
import { displayDate } from '../Utils/utils';
import { NumberFormatter } from '../Utils/NumberFormatter';
import { DisplayTextFormat } from '../Utils/DisplayTextFormat';
import { Store } from '../../modules/rootReducer';
import { CollapsiblePanelState } from '../../modules/collapsiblePanelReducer';

import useLoggedInUser from '../hooks/useLoggedInUser';
import StatusChip from '../Invoicing/StatusChip';
import { renderFieldInTableCell } from './EditablePagingTable';



export interface TableColumn {
    type: string;
    displayValue: string;
    align: 'left' | 'center' | 'right';
    style?: any;
    placeholder?: string;
    required?: boolean;
    bOptions?: BOption<string>[];
    selectOptions?: SelectOption<number>[];
    derivedField?: boolean;
    format?: 'integer' | 'currency-US' | 'date' | 'status' | 'button' | 'enum' | 'long-desc' | 'jsx-element' | 'symbol' | 'employees';
    onChange?: any;
    disabled?: boolean;
    hidden?: boolean;
    greyIfEmpty?: boolean;
    noSort?: boolean;
    buttonProps?: {
        disable?: (object: any) => boolean;
        label?: string;
        iconLabel?: React.ReactNode;
        handler: (object: any) => void;
        handlerPermission: boolean;
    };
    symbolProps?: {
        label?: string;
        icon?: React.ReactNode;
    }
    // checkedautocomplete props
    acValue?: any;
    multiple?: boolean;
    defaultValue?: any;
    noCheckmark?: boolean;
    closeOnSelect?: boolean;
    error?: boolean;
    errorMessage?: any;
    tooltipTitle?: string;
    headerToolTip?: string;
    // Props for employee chips
    referenceRoles?: SelectOption<number>[];
    studyUsers?: StudyUser[];
    canGoToUserStats?: boolean;
}

export interface ActionOption {
    label: string;
    handler: any;
    permission: boolean;
    permissionFunction?: (arg0: any) => boolean;
    disabled?: boolean;
    tooltipTitle?: string;
    icon?: React.ReactNode;
}

interface State {
    data: any[];
    sortColumn: string;
}

interface Action {
    type: 'list' | 'drop';
    payload?: any;
}

interface Props {
    dataList: any[];
    setReorderedList: any;
    reorderListHandler: any;
    tableInfoColumns: TableColumn[];
    editingDataObject: any;
    handleSubmit: any;
    validationSchema?: any;
    handleValidate?: any;
    readonly: boolean;
    canCreate: boolean;
    clickedObj: null | any;
    setClickedObj: any;
    actionOptions?: ActionOption[];
    initialSortType?: string;
    canReorder?: boolean;
    mode?: 'full' | 'expanded' | 'dynamic';
}

const DragAndDropTable: React.FC<Props> = (props) => {
    const {
        dataList,
        setReorderedList,
        reorderListHandler,
        tableInfoColumns,
        editingDataObject,
        handleSubmit,
        validationSchema,
        handleValidate,
        readonly,
        canCreate,
        clickedObj,
        setClickedObj,
        actionOptions,
        initialSortType,
        canReorder,
        mode,
    } = props;

    const history = useHistory();
    const loggedInUser = useLoggedInUser();

    // State management for table pagination
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(-1);

    const hasMenuItems: boolean = !!actionOptions;
    const hasActionPermission: boolean = !!actionOptions?.find(action => action.permission);

    const { expanded } = useSelector<Store, CollapsiblePanelState>(
        store => store.collapsiblePanelReducer
    );
    
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const initialState: State = {
        data: dataList,
        sortColumn: initialSortType ? initialSortType : tableInfoColumns[0].type,
    };

    const reducer = (state: State, action: Action) => {
        switch (action.type) {
            case 'list':
                return { ...state, data: action.payload };
            case 'drop': {
                if (!action.payload.destination == undefined) return { ...state }
                if (action.payload.destination === action.payload.source) return { ...state }

                let sourceIndex: number = action.payload.source;
                let destinationIndex: number = action.payload.destination;

                if (rowsPerPage > 0) {
                    sourceIndex = sourceIndex + (page * rowsPerPage);
                    destinationIndex = destinationIndex + (page * rowsPerPage);
                }

                const movingUp: boolean = sourceIndex > destinationIndex;

                const reorderList = [...state.data]
                    .map((row, index) => {
                        if (index < (movingUp ? destinationIndex : sourceIndex)
                            || index > (movingUp ? sourceIndex : destinationIndex)) {
                            return (row);
                        } else if (index === sourceIndex) {
                            return ({
                                ...row,
                                numInsideSequence: destinationIndex + 1
                            });
                        } else {
                            return ({
                                ...row,
                                numInsideSequence: movingUp ? (row.numInsideSequence + 1) : index
                            });
                        }
                    })
                    .sort((a, b) => a.numInsideSequence - b.numInsideSequence);

                // Need list of ids to send to the service to reorder the visits/procedures
                const idsList: number[] = [];
                // All visits/procedures will have the same contractId
                const contractId: number = reorderList[0].contractId;
                reorderList.forEach((obj: any) => {
                    idsList.push(obj.id);
                })

                reorderListHandler(contractId, idsList);
                setReorderedList(reorderList);
                return { ...state, data: reorderList }
            }
            default:
                return state;
        }
    };
    const [listState, dispatchList] = React.useReducer(reducer, initialState);
    const { data } = listState;

    const handleDragEnd = (result: DropResult, provided?: ResponderProvided) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        dispatchList({
            type: 'drop',
            payload: {
                destination: result.destination.index,
                source: result.source.index
            }
        });
    };

    // State management for the more actions menu
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleMoreMenu = (event: React.MouseEvent<HTMLElement>, selected: any) => {
        setClickedObj(selected);
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const tableEndRef = React.useRef<HTMLInputElement>(null);
    const [updated, setUpdated] = React.useState<number>(0);
    React.useEffect(() => {
        // Prevent scrolling on initial render
        if (updated > 0) {
            setUpdated(updated + 1);
        }
        dispatchList({ type: 'list', payload: dataList });
    }, [dataList])

    React.useEffect(() => {
        if (tableEndRef.current) tableEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [updated]);

    const conditionalClassName = () => {
        if (mode == 'expanded') {
            return 'constrainable expanded';
        }
        if ((!mode || mode == 'dynamic') && expanded) {
            return 'constrainable expanded';
        }
        if ((!mode || mode == 'dynamic') && !expanded) {
            return 'constrainable collapsed';
        }
        return 'constrainable';
    }

    const renderActionIcons = (obj: any, objIndex: number): React.ReactNode => {
        if ((actionOptions?.length ?? 0) > 3) {
            return (
                <IconButton
                    aria-controls="more-actions-menu"
                    aria-haspopup="true"
                    onClick={(event) => handleMoreMenu(event, obj)}
                    style={{ padding: 0 }}
                >
                    <MoreHoriz />
                </IconButton>
            );
        } else {
            return (
                <div className="aligned-row" style={{ justifyContent: 'center' }}>
                    {actionOptions?.map((option, index) => {
                        if (!option.permission) return;
                        if (!!option.permissionFunction && !option.permissionFunction(obj)) return;
                        return (
                            <Tooltip
                                key={`row-${objIndex}-action-${index}`}
                                title={option.label}
                                arrow
                            >
                                <IconButton
                                    onClick={() => {
                                        setClickedObj(obj);
                                        option.handler(obj);
                                    }}
                                    style={{ padding: '6px' }}
                                >
                                    {option.icon ?? <span className="ept-custom-icon">&#10033;</span>}
                                </IconButton>
                            </Tooltip>
                        );
                    })}
                </div>
            );
        }
    };

    return (
        <>
            <div className={conditionalClassName()}>
                <TablePagination
                    component="div"
                    count={dataList.length}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[{ label: 'All', value: -1 }, 10, 20, 30]}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Items Per Page:"
                    className='table-pagination'
                    labelDisplayedRows={({ from, to, count }) => {
                        if (to == -1) {
                            return `${count} of ${count}`;
                        }
                        return `${from}-${to} of ${count}`;
                    }}
                />
                <Formik
                    initialValues={editingDataObject}
                    onSubmit={handleSubmit}
                    validate={handleValidate ?? undefined}
                    validationSchema={validationSchema ?? undefined}
                    enableReinitialize
                    validateOnBlur={false}
                    validateOnChange={false}
                >
                    <BForm>
                        <TableContainer component={Paper} className='input-table' style={{ maxHeight: '70vh' }}>
                            <Table stickyHeader>
                                <TableHead className="table-header">
                                    <TableRow>
                                        {(!readonly && canReorder) &&
                                            <TableCell className={'primary-cell primary-color'} />
                                        }
                                        {tableInfoColumns.map(header => {
                                            if (!header.hidden) {
                                                return (
                                                    <TableCell
                                                        key={'header-' + header.type}
                                                        className={'primary-cell primary-color'}
                                                        style={{ ...(header.style ? header.style : '') }}
                                                    >
                                                        <Tooltip arrow title={header.headerToolTip ?? ''} placement='top'>
                                                            <div className={`header-cell-label ${header.align}`}>
                                                                {header.displayValue}
                                                            </div>
                                                        </Tooltip>
                                                    </TableCell>
                                                );
                                            }
                                        })}
                                        {!readonly && hasActionPermission && hasMenuItems &&
                                            <TableCell key="header-actions" className="primary-cell primary-color">
                                                <div className="header-cell-label center">
                                                    Actions
                                                </div>
                                            </TableCell>
                                        }
                                    </TableRow>
                                </TableHead>
                                <DragDropContext onDragEnd={handleDragEnd}>
                                    <Droppable droppableId="droppable" direction="vertical">
                                        {(droppableProvided: DroppableProvided) => (
                                            <TableBody
                                                ref={droppableProvided.innerRef}
                                                {...droppableProvided.droppableProps}
                                            >
                                                {(rowsPerPage > 0
                                                    ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : data
                                                ).map((obj: any, idx: number) => (
                                                    <Draggable
                                                        key={obj.id}
                                                        draggableId={obj.id.toString()}
                                                        index={idx}
                                                        isDragDisabled={readonly || !canReorder}
                                                    >
                                                        {(
                                                            draggableProvided: DraggableProvided,
                                                            snapshot: DraggableStateSnapshot
                                                        ) => {
                                                            return (
                                                                <TableRow key={'row-' + idx}
                                                                    ref={draggableProvided.innerRef}
                                                                    {...draggableProvided.draggableProps}
                                                                    style={{ ...draggableProvided.draggableProps.style }}
                                                                    className={snapshot.isDragging ? 'ept-dragged-row' : ''}
                                                                >
                                                                    {(!readonly && canReorder) &&
                                                                        <TableCell style={{ width: 40 }} className='body-cell center'>
                                                                            <div {...draggableProvided.dragHandleProps}>
                                                                                <Reorder />
                                                                            </div>
                                                                        </TableCell>
                                                                    }
                                                                    {tableInfoColumns.map(column => {
                                                                        const backgroundColor = column.greyIfEmpty && !obj[column.type] ? ' disabled-cell ' : '';
                                                                        if (!column.hidden) {
                                                                            if (column.format === 'symbol') {
                                                                                const icon: React.ReactNode | undefined = column.symbolProps?.icon;
                                                                                const label: string | undefined = column.symbolProps?.label;
                                                                                return (
                                                                                    <TableCell key={column.type + '-' + idx} className={`body-cell ${column.align}`}>
                                                                                        {obj[column.type] ? icon ?? label : ''}
                                                                                    </TableCell>
                                                                                )
                                                                            } else if (column.format === 'currency-US' && (obj[column.type] != null)) {
                                                                                return (
                                                                                    <TableCell key={column.type + '-' + idx} className={`${backgroundColor} body-cell ${column.align}`}>
                                                                                        <NumberFormatter currency value={obj[column.type]} />
                                                                                    </TableCell>
                                                                                )
                                                                            } else if (column.format === 'date') {
                                                                                return (
                                                                                    <TableCell key={column.type + '-' + idx} className={`${backgroundColor} body-cell ${column.align}`}>
                                                                                        {displayDate(obj[column.type])}
                                                                                    </TableCell>
                                                                                )
                                                                            } else if (column.format === 'status') {
                                                                                return (
                                                                                    obj[column.type] ?
                                                                                        <TableCell key={column.type + '-' + idx} className={`body-cell ${column.align}`}>
                                                                                            <StatusChip width='small' status={obj[column.type]} />
                                                                                        </TableCell> : <TableCell key={column.type + '-' + idx} className={'body-cell'} />
                                                                                )
                                                                            } else if (column.format === 'button') {
                                                                                const icon: React.ReactNode | undefined = column.buttonProps?.iconLabel;
                                                                                const label: string | undefined = column.buttonProps?.label;
                                                                                const value = obj[column.type];
                                                                                let buttonDisabled: boolean = !column.buttonProps?.handlerPermission ?? false;
                                                                                if (!buttonDisabled && column.buttonProps?.disable)
                                                                                    buttonDisabled = column.buttonProps.disable(obj[column.type]);
                                                                                const buttonEmpty: boolean = !icon && !label && !value;
                                                                                const backgroundColorButton: string = column.greyIfEmpty && (buttonDisabled || buttonEmpty) ? ' disabled-cell ' : '';
                                                                                return (
                                                                                    <TableCell key={column.type + '-' + idx} className={`'${backgroundColorButton}' body-cell ${column.align} `}>
                                                                                        {(icon || label || value) && <Button
                                                                                            variant="text"
                                                                                            color="primary"
                                                                                            onClick={() => column.buttonProps?.handler(obj)}
                                                                                            disabled={buttonDisabled}
                                                                                        >
                                                                                            {!icon && !label ? value : <>{icon}{label}</>}
                                                                                        </Button>}
                                                                                    </TableCell>
                                                                                )
                                                                            } else if (column.format === 'enum' && column.bOptions) { // renders enum type as proper label
                                                                                return (
                                                                                    <TableCell key={column.type + '-' + idx} className={`${backgroundColor} body-cell ${column.align}`}>
                                                                                        {DisplayTextFormat(column.bOptions.find(o => o.value == obj[column.type])?.label)}
                                                                                    </TableCell>
                                                                                )
                                                                            } else if (column.format === 'employees') {
                                                                                if (obj.users) {
                                                                                    return (
                                                                                        <TableCell key={column.type + '-' + idx} className={`${backgroundColor} body-cell ${column.align}`}>
                                                                                            {obj.users.map((user: StudyUser) => {
                                                                                                return renderEmployeeChip(column.referenceRoles!, user, column.studyUsers!, history,
                                                                                                    (column.canGoToUserStats || loggedInUser.id === user.userId)
                                                                                                );
                                                                                            })}
                                                                                        </TableCell>
                                                                                    )
                                                                                }
                                                                            } else if (column.selectOptions) { // if checked autocomplete component, render label
                                                                                return (
                                                                                    <TableCell key={column.type + '-' + idx} className={`${backgroundColor} body-cell ${column.align}`}>
                                                                                        {DisplayTextFormat(column.selectOptions.find(o => o.value == obj.serviceProviderId)?.label)}
                                                                                    </TableCell>
                                                                                )
                                                                            } else if (column.format === 'long-desc') {
                                                                                return (
                                                                                    <TableCell
                                                                                        key={column.type + '-' + idx}
                                                                                        className={`body-cell ${column.align}`}
                                                                                        style={{ minWidth: '550px', width: '550px' }}
                                                                                    >
                                                                                        {DisplayTextFormat(obj[column.type], 75)}
                                                                                    </TableCell>
                                                                                )

                                                                            } else if (column.format === 'jsx-element') {
                                                                                return (
                                                                                    <TableCell key={column.type + '-' + idx} className={`${backgroundColor} body-cell ${column.align}`}>
                                                                                        {obj[column.type]}
                                                                                    </TableCell>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <TableCell key={column.type + '-' + idx} className={`${backgroundColor} body-cell ${column.align}`}>
                                                                                        {DisplayTextFormat(obj[column.type], 35)}
                                                                                    </TableCell>
                                                                                )
                                                                            }
                                                                        }
                                                                    })}
                                                                    {!readonly && hasActionPermission && hasMenuItems &&
                                                                        <TableCell key='header-actions' className="body-cell center">
                                                                            {renderActionIcons(obj, idx)}
                                                                        </TableCell>
                                                                    }
                                                                </TableRow>
                                                            );
                                                        }}
                                                    </Draggable>
                                                ))}
                                                {droppableProvided.placeholder}
                                                {dataList.length === 0 &&
                                                    <TableRow>
                                                        <TableCell colSpan={tableInfoColumns.length + 1} className='body-cell center' style={{ height: 80, fontSize: 16 }}>
                                                            No data to display.
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                {!readonly && canCreate &&
                                    <TableFooter className="background-color-paper" style={{ position: 'sticky', bottom: 0, zIndex: 1001 }}>
                                        <TableRow>
                                            <TableCell className='body-cell' />
                                            {tableInfoColumns.map((column, idx) => { return (!column.hidden && renderFieldInTableCell(column, idx)) })}
                                            <TableCell className='body-cell center' style={{ width: 80 }}>
                                                <BSubmit id='add-submit' variant='text' onClick={() => setUpdated(updated + 1)}>
                                                    Add
                                                </BSubmit>
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                }
                            </Table>
                            <div ref={tableEndRef} />
                        </TableContainer>
                    </BForm>
                </Formik>
            </div>
            {clickedObj &&
                <Menu
                    id="more-actions-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    {actionOptions?.map(option => {
                        if (option.permission) return (
                            <Tooltip key={`actions-tooltip-${option.label}`} title={option.tooltipTitle ? option.tooltipTitle : ''} arrow>
                                <div>
                                    <MenuItem
                                        key={`more-actions-${option.label}`}
                                        onClick={() => {
                                            option.handler();
                                            handleCloseMenu();
                                        }}
                                        disabled={option.disabled ? option.disabled : false}
                                    >
                                        {option.label}
                                    </MenuItem>
                                </div>
                            </Tooltip>
                        );
                    })}
                </Menu>
            }
        </>
    )
}

export default DragAndDropTable;

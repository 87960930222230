import * as React from 'react';
import * as yup from 'yup';

import { Button, Card, CardActions, CardContent, Grid, Modal, Tooltip, Typography } from '@material-ui/core';
import { HelpOutlineOutlined, Info } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import { BButton, BError, BForm, BGrid, BOption, BRadio, BRadioGroup, BSelect, BSubmit, BTextField } from 'mui-bueno';
import { Formik, FormikErrors, FormikHelpers, FormikProps, validateYupSchema } from 'formik';

import { useDispatch } from 'react-redux';
import { Account, AccountAndContacts, AccountContact } from '../../@types';
import { handleErrorResponse } from '../../service/utils';
import { AxiosError } from 'axios';
import { showSuccessSnackbar } from '../../modules/messageSnackbarReducer';
import { useHistory } from 'react-router-dom';
import usePermissions from '../hooks/usePermissions';
import { getAccountWithTotalCredit } from '../../service/Management/accounts';
import { createAccountAndAssociateContacts, deleteAccount, updateAccount } from '../../service/Account/account';
import { associateContactsToAccount } from '../../service/Account/accountContact';
import SimpleConfirmDelete from '../ConfirmDelete/SimpleConfirmDelete';
import CardTitle from '../Card/CardTitle';

interface Props {
    id?: string;
    open: boolean;
    setOpen: any;
    management: boolean;
    internal?: boolean;
    initialAccount?: (Account | null);
    initialContacts?: AccountContact[];
    studyName?: string;
    studyId?: number | null;
    setNewAccount?: any;
    setNewContacts?: any;
    setUpdated?: any;
    contractSigned?: boolean;
}

interface FormikSubmitter {
    id: number;
    handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
}

interface AccountingModalProps {
    id: number | null;
    enteredName: string;
    internal?: string;
    preferredPayType: string;
    achAccountingNo: string;
    addressLine1: string;
    addressLine2: string | null;
    city: string;
    state: string;
    zipCode: string;
    checkAddress: string;
    eftAddress: string;
}

/**
 * The Accounting Modal appears in 3 places: the Account List page, the Account Detail page, and the StudyAccountSettings
 * (appears in the Invoicing Accounts tab of the Contracts Details section and the Invoicing tab of the Study Details section).
 * These 3 places can be further generalized into either being in the Management section or not. This is reflected by the `management`
 * prop.
 * 
 * If the AccountModal is being used in the Management section (Account List and Account Details), here are the following props:
 * 
 * **Required**
 * - open
 * - setOpen
 * - management (needs to be true)
 * 
 * **Optional**
 * - id: should be set to true if editing an account
 * - setUpdated: function that notifies the parent whether the account has been updated
 * 
 * If the AccountModal is not being used in the management section, here are the following props (all required):
 * - open
 * - setOpen
 * - management (needs to be false)
 * - internal: whether the account is internal or external
 * - initialAccount: the account that is being modified (can be null if creating an account)
 * - initialContacts: the contacts for the account that is being modified ([] if creating an account)
 * - studyName: the name of the study
 * - studyId: the id of the study (is null if creating an account)
 * - setNewAccount: function that returns the result of the creation/edit to the parent
 * - setNewContacts: function that returns the result of the creation/edit (for contacts) to the parent
 * - contractSigned: indicates whether the contract has been signed. Used to denote required fields in the Invoicing tab of the Study Details
 */
const AccountingModal: React.FC<Props> = props => {
    const { id, open, setOpen, management, internal, initialAccount, initialContacts, studyName, studyId, setNewAccount, setNewContacts, setUpdated, contractSigned } = props;

    const dispatch = useDispatch();
    const history = useHistory();

    const emptyAccount: Account = {
        id: null,
        name: '',
        enteredName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
        internal: internal ?? false,
        achAccountingNo: '',
        preferredPayType: '',
        checkAddress: '',
        eftAddress: '',
    };

    const [idCount, setIdCount] = React.useState<number>(management ? 2 : 1);
    const [contactsList, setContactsList] = React.useState<AccountContact[]>(management ? [{ id: 1, accountId: null, email: '', name: '', phoneNo: '', comment: '', primaryContact: true }] : []);
    const [subformSubmitters, setSubformSubmitters] = React.useState<FormikSubmitter[]>([]);
    const [contactErrors, setContactErrors] = React.useState<boolean>(false);
    const [accountErrors, setAccountErrors] = React.useState<boolean>(false);
    const [account, setAccount] = React.useState<Account>(emptyAccount);

    const internalTooltip = (
        <div className={'info-tooltip'}>
            <div className='text'>Internal Account</div>
            <Tooltip arrow title='An account within this institution that the sponsor will pay to.'><Info className='info' /></Tooltip>
        </div>
    );

    const externalTooltip = (
        <div className={'info-tooltip'}>
            <div className='text'>External Account</div>
            <Tooltip arrow title='An account outside of this institution that is typically used as a sponsor to a study.'><Info className='info' /></Tooltip>
        </div>
    );

    const accountTypes: BRadio<string>[] = [
        { label: internalTooltip, value: 'true' },
        { label: externalTooltip, value: 'false' },
    ];

    const possiblePaymentTypes: BOption<string>[] = [
        { value: 'ACH', label: 'Automated Clearing House (ACH)' },
        { value: 'CHECK', label: 'Check' },
        { value: 'INTRA_TRANSFER', label: 'Intracompany Transfer' },
    ]

    const externalPaymentTypes = possiblePaymentTypes.slice(0, 2);

    React.useEffect(() => {
        if (initialAccount) {
            setAccount(initialAccount);
        } else {
            setAccount(emptyAccount);
        }
    }, [initialAccount, open])

    React.useEffect(() => {
        if (initialContacts && initialContacts.length > 0) {
            const list = initialContacts;
            list.sort((ac1, ac2) => (ac1.id! - ac2.id!));
            const lastId = list[list.length - 1].id!;
            setContactsList(list);
            setIdCount(lastId + 1);
        } else {
            setContactsList([]);
        }
    }, [initialContacts, open])

    const canDeleteAccount = usePermissions('API_ACCOUNT_DELETE_BY_ID');
    const [openConfirmDelete, setOpenConfirmDelete] = React.useState<boolean>(false);

    const contactSectionRef = React.useRef<HTMLInputElement>(null);
    React.useEffect(() => {
        if (contactSectionRef.current) contactSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [contactErrors]);

    const accountSectionRef = React.useRef<HTMLInputElement>(null);
    React.useEffect(() => {
        if (accountSectionRef.current) accountSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [accountErrors]);

    React.useEffect(() => {
        const accountId = Number(id);
        if (accountId) {
            getAccountWithTotalCredit(accountId)
                .then(res => {
                    const acc: Account = res.data.accountWithContacts.account;
                    setAccount({
                        id: acc.id,
                        name: acc.enteredName,
                        enteredName: acc.enteredName,
                        addressLine1: acc.addressLine1,
                        addressLine2: acc.addressLine2,
                        city: acc.city,
                        state: acc.state,
                        zipCode: acc.zipCode,
                        internal: acc.internal,
                        achAccountingNo: acc.achAccountingNo,
                        preferredPayType: acc.preferredPayType ?? '',
                        checkAddress: acc.checkAddress,
                        eftAddress: acc.eftAddress,
                    });
                    const contacts: AccountContact[] = res.data.accountWithContacts.contacts;
                    contacts.sort((ac1, ac2) => (ac1.id! - ac2.id!));
                    const lastId = contacts[contacts.length - 1].id!;
                    setContactsList(contacts);
                    setIdCount(lastId + 1);
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not retrieve account or contacts: '
                    });
                });
        }
    }, [id, open])

    const initialValues: AccountingModalProps = {
        id: account.id,
        enteredName: account.enteredName,
        internal: account.internal ? 'true' : 'false',
        preferredPayType: account.preferredPayType ?? '',
        achAccountingNo: account.achAccountingNo,
        addressLine1: account.addressLine1,
        addressLine2: account.addressLine2,
        city: account.city,
        state: account.state,
        zipCode: account.zipCode,
        checkAddress: account.checkAddress ?? '',
        eftAddress: account.eftAddress ?? '',
    }

    const handleCloseModal = () => {
        setOpen(false);
        setAccount(emptyAccount);
        setIdCount(management ? 2 : 1);
        setOpenConfirmDelete(false);
        setContactsList(management ? [{ id: 1, accountId: null, email: '', name: '', phoneNo: '', comment: '', primaryContact: true }] : []);
        setContactErrors(false);
        setAccountErrors(false);
        setSubformSubmitters([]);
    }

    const checkForEditErrors = (err: AxiosError) => {
        if (!err.response || err.response.status !== 422) return;
        else if (Object.keys(err.response.data.editErrors).includes('enteredName'))
            setAccountErrors(true);
        else if (Object.keys(err.response.data.editErrors).includes('general'))
            setContactErrors(true);
    }

    const handleSubmit = async (data: AccountingModalProps, { setErrors }: FormikHelpers<any>) => { //NOSONAR
        setContactErrors(false);
        setAccountErrors(false);
        if (!validate(setErrors, data))
            return;
        const accountName = studyName ? data.enteredName + '-' + studyName : data.enteredName;
        const achNo = data.preferredPayType === 'ACH' ? data.achAccountingNo : '';
        const payType = data.preferredPayType ? data.preferredPayType : null;
        const newAccount: Account = {
            id: data.id,
            name: accountName,
            enteredName: data.enteredName,
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2,
            city: data.city,
            state: data.state,
            zipCode: data.zipCode,
            internal: internal ?? (data.internal === 'true'),
            achAccountingNo: achNo,
            preferredPayType: payType,
            checkAddress: data.checkAddress,
            eftAddress: data.eftAddress,
        }
        if ((management && !id) || (!management && !initialAccount)) {
            const newContacts = contactsList.map(ac => { return { ...ac, id: null } });
            const newAccountAndContacts: AccountAndContacts = {
                account: newAccount,
                contacts: newContacts,
            }
            createAccountAndAssociateContacts(newAccountAndContacts, studyId!)
                .then(res => {
                    setUpdated && setUpdated(true);
                    dispatch(showSuccessSnackbar('Account and contact(s) successfully created'));
                    setNewAccount && setNewAccount(res.data.account);
                    setNewContacts && setNewContacts(res.data.contacts);
                    handleCloseModal();
                    const createdAccountId: number = res.data.account.id!;
                    management && history.push(`/manage/account/${createdAccountId}`);
                }).catch((err: AxiosError) => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not create account or contact(s): ',
                        setStatus: setErrors,
                    });
                    checkForEditErrors(err);
                });
        } else {
            updateAccount(newAccount)
                .then(res => {
                    setNewAccount && setNewAccount(res.data);
                    const updatedContacts = contactsList.map(val => {
                        if (!val.accountId) { //added
                            return { ...val, id: null }
                        }
                        return val;
                    })
                    associateContactsToAccount(updatedContacts, res.data.id!)
                        .then(contactRes => {
                            setNewContacts && setNewContacts(contactRes.data.contacts);
                            setUpdated && setUpdated(true);
                            dispatch(showSuccessSnackbar('Account and contact(s) successfully updated'));
                            handleCloseModal();
                        }).catch((err: AxiosError) => {
                            handleErrorResponse(err, dispatch, {
                                prefix: 'Account successfully updated but could not update contact(s) for account: ',
                                setStatus: setErrors,
                            });
                            checkForEditErrors(err);
                        });
                }).catch((err: AxiosError) => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not update account: ',
                        setStatus: setErrors,
                    });
                    checkForEditErrors(err);
                });
        }
    }

    const validate = (setErrors: (errors: FormikErrors<any>) => void, data: AccountingModalProps): boolean => { //NOSONAR
        if (management) {
            if (data.internal === 'false' && data.preferredPayType === 'INTRA_TRANSFER') {
                setErrors({ 'preferredPayType': 'Payment Preference is required' });
                return false;
            }
            if (contactsList.length === 0) {
                setErrors({ 'general-errors': 'Contact is required.' });
                setContactErrors(true);
                return false;
            }
        } else {
            if (contactsList.length === 0)
                return true;
        }
        const primaryCount = contactsList.map(contact => contact.primaryContact).filter(val => val).length;
        const errorName = management ? 'general-errors' : 'general'
        if (primaryCount > 1) {
            setErrors({ [errorName]: 'Only one contact can be the primary.' });
            setContactErrors(true);
            return false;
        } else if (primaryCount < 1) {
            setErrors({ [errorName]: 'One contact must be the primary.' });
            setContactErrors(true);
            return false;
        }
        for (let i = 0; i < contactsList.length; i++) {
            subformSubmitters[i].handleSubmit();
        }
        const errors: { [key: string]: string }[] = contactsList.map((contact) => validateContact(contact));
        const anyErrors = errors.some((val) => Object.keys(val).length !== 0);
        if (anyErrors) {
            setContactErrors(true);
            return false;
        }
        return true;
    };

    const validateContact = (contact: AccountContact) => {
        const errors: { [key: string]: string } = {};
        try { //NOSONAR
            validateYupSchema<AccountContact>(contact, schema, true);
        } catch (err: any) {
            const thing: yup.ValidationError = err;
            for (const e of thing.inner) {
                if (e.path === 'name') errors.name = e.message;
                else if (e.path === 'email') errors.email = e.message;
                else if (e.path === 'phoneNo') errors.phoneNo = e.message;
                else continue
            }
        }
        return errors;
    }

    const handleAddNewContact = () => {
        if (contactsList.length === 0) {
            setContactsList(oldArray => [...oldArray, { id: idCount, accountId: null, email: '', name: '', phoneNo: '', comment: '', primaryContact: true }]);
        } else {
            setContactsList(oldArray => [...oldArray, { id: idCount, accountId: null, email: '', name: '', phoneNo: '', comment: '', primaryContact: false }]);
        }
        setIdCount(idCount + 1);
    }

    const modifyField = (fieldName: ('name' | 'email' | 'phoneNo' | 'primaryContact' | 'comment'), contactId: number, value: string | boolean) => {
        const tempObj = contactsList;
        const index = contactsList.findIndex(c => c.id === contactId);
        if (index < 0)
            return;
        if (fieldName === 'primaryContact') {
            const tempList: AccountContact[] = [];
            contactsList.forEach((con: AccountContact) => {
                if (con.id === contactId)
                    tempList.push({ ...con, primaryContact: true });
                else
                    tempList.push({ ...con, primaryContact: false });
            })
            setContactsList(tempList);
            return;
        }
        let object = contactsList[index];
        object = { ...object, [fieldName]: value }
        tempObj.splice(index, 1, object);
        setContactsList(tempObj);
    }

    const setSubmitter = (contactId: number, subformHandleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void): void => {
        if (subformSubmitters.map(sfs => sfs.id).includes(contactId)) {
            const tempObj = subformSubmitters;
            const index = subformSubmitters.findIndex(sfs => sfs.id === contactId);
            let object = subformSubmitters[index];
            object = { ...object, handleSubmit: subformHandleSubmit }
            tempObj.splice(index, 1, object);
            setSubformSubmitters(tempObj);
        } else {
            setSubformSubmitters(oldArray => [...oldArray, { id: contactId, handleSubmit: subformHandleSubmit }]);
        }
    }

    const submitSubform = async (data: AccountContact, { setErrors }: FormikHelpers<AccountContact>) => {
        // Values will be submitted by the main form. Validation performed using validationSchema
    }

    const handleDelete = (contact: AccountContact) => () => {
        setContactsList(contactsList.filter((c: AccountContact) => c.id !== contact.id));
        setSubformSubmitters(subformSubmitters.filter((fs: FormikSubmitter) => fs.id !== contact.id));
        dispatch(showSuccessSnackbar('Contact marked for deletion.'));
    }

    const schema = yup.object<AccountContact>().shape({
        name: yup.string().required('Name is required'),
        email: yup.string().email('Must be in email format').required('Email is required'),
        phoneNo: yup.string().length(14, 'Incorrect format for phone number (999)-999-9999'),
        comment: yup.string(),
        primaryContact: yup.bool(),
    });

    const setSubformSubmit = (contactId: number, subformHandleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void) => {
        setSubmitter(contactId, subformHandleSubmit);
        return null;
    }

    const displayContact = (contact: AccountContact) => {
        return (
            <BGrid container gridClassName={'contact-section-border'}>
                <Formik
                    key={contact.id}
                    onSubmit={submitSubform}
                    validationSchema={schema}
                    initialValues={contact}
                    enableReinitialize
                    validateOnBlur={false}
                    validateOnChange={false}
                >
                    {formikValues =>
                        <>
                            {setSubformSubmit(contact.id!, formikValues.handleSubmit)}
                            <BTextField
                                name="name"
                                label="Name"
                                placeholder="Name"
                                onBlur={() => { modifyField('name', contact.id!, formikValues.values.name) }}
                                InputLabelProps={{ shrink: true }}
                                xs={12}
                                autoFocus
                            />
                            <BTextField
                                name="email"
                                label="Email"
                                placeholder="Email"
                                onBlur={() => { modifyField('email', contact.id!, formikValues.values.email) }}
                                InputLabelProps={{ shrink: true }}
                                sm={6}
                                xs={12}
                            />
                            <BTextField
                                name="phoneNo"
                                label="Phone"
                                placeholder="Phone"
                                onBlur={() => { modifyField('phoneNo', contact.id!, formikValues.values.phoneNo) }}
                                InputLabelProps={{ shrink: true }}
                                format={'(999)-999-9999'}
                                sm={6}
                                xs={12}
                            />
                            <BTextField
                                name="comment"
                                label="Comments"
                                placeholder="Comments"
                                onBlur={() => { modifyField('comment', contact.id!, formikValues.values.comment) }}
                                InputLabelProps={{ shrink: true }}
                                multiline
                                minRows={2}
                                xs={12}
                            />
                            <div className="aligned-row">
                                <div className='left'>
                                    <BRadioGroup
                                        name="primaryContact"
                                        label={''}
                                        xs={12}
                                        sameButtonColor='secondary'
                                        radios={[{ label: 'Primary Contact', value: true }]}
                                        onClick={() => {
                                            modifyField('primaryContact', contact.id!, true)
                                        }}
                                    />
                                </div>
                                <div className={'right'}>
                                    <Button
                                        name="Remove"
                                        variant="text"
                                        className={'contact-delete-button'}
                                        onClick={handleDelete(contact)}
                                    >
                                        Remove
                                    </Button>
                                </div>
                            </div>
                        </>
                    }
                </Formik>
            </BGrid>
        );
    }

    const typeOfAccount = internal ? 'Internal' : 'Sponsor';
    const topMessage = internal ? 'Internal accounts are necessary for the primary invoicing logistics throughout the study.' : 'Sponsor accounts are necessary to track the invoices from the study to the sponsor.';
    const handleConfirmDelete = () => {
        setOpenConfirmDelete(true);
    }

    const handleDeleteAccount = () => {
        deleteAccount(Number(id))
            .then(() => {
                dispatch(showSuccessSnackbar('Account successfully deleted'));
                history.push('/manage/account');
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not delete Account: '
                });
            });
        handleCloseModal();
    }

    const deleteButton = () => {
        const disabledClass = !canDeleteAccount ? 'disabled' : 'delete-color';
        return (
            <BButton
                startIcon={<HelpOutlineOutlined />}
                variant="contained"
                className={disabledClass}
                onClick={handleConfirmDelete}
                disabled={!canDeleteAccount}
            >
                Delete
            </BButton>
        );
    }
    const getOptions = (formikValues: FormikProps<AccountingModalProps>) => {
        if (management)
            return formikValues.values.internal === 'true' ? possiblePaymentTypes : externalPaymentTypes;
        else
            return internal ? possiblePaymentTypes : externalPaymentTypes;
    }

    const displayCardActions = () => {
        if (!management) {
            return (
                <CardActions className='flex-end'>
                    {initialAccount ?
                        <BSubmit>Save</BSubmit> :
                        <BSubmit>Create</BSubmit>
                    }
                </CardActions>
            );
        } else if (id) {
            return (
                <CardActions className="space-between">
                    {canDeleteAccount && deleteButton()}
                    <BSubmit>Save</BSubmit>
                </CardActions>
            );
        } else {
            return (
                <CardActions className="flex-end">
                    <BSubmit>Create</BSubmit>
                </CardActions>
            );
        }
    }

    const getContactClassName = () => {
        const numberOfPrimary = contactsList.map(contact => contact.primaryContact).filter(val => val).length;
        if (contactErrors || (contractSigned && (contactsList.length === 0 || numberOfPrimary !== 1)))
            return 'body error';
        else
            return 'body';
    }

    const getBasicInformationMessage = () => {
        return (
            <>
                <Typography variant='body2' align='center'>{topMessage}</Typography>
                <Typography variant='body2' align='center'>The &quot;Name&quot; field must be filled out prior to signing the contract.</Typography>
            </>
        );
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleCloseModal}
            >
                <div className="modal-form">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        enableReinitialize
                        validateOnBlur={false}
                        validateOnChange={false}
                    >
                        {formikValues => //NOSONAR
                            <BForm>
                                <Card className="detail-form md account-form">
                                    <CardTitle
                                        handleClose={handleCloseModal}
                                        title={<>
                                            {management && (id ? 'Edit Account' : 'Add Account')}
                                            {!management && (initialAccount ? 'Edit ' + typeOfAccount + ' Account for ' + studyName : 'Add ' + typeOfAccount + ' Account for ' + studyName)}
                                        </>}
                                    />
                                    <CardContent>
                                        <div className='form-section'>
                                            <Grid container>
                                                <Typography className="title">Basic Information</Typography>
                                                {!management && <Tooltip arrow title={getBasicInformationMessage()}><Info className='info' /></Tooltip>}
                                            </Grid>
                                            <div ref={accountSectionRef} />
                                            <BGrid container gridClassName='body'>
                                                <BTextField
                                                    name="enteredName"
                                                    label="Name"
                                                    placeholder="Account Name"
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{
                                                        classes: {
                                                            notchedOutline: !formikValues.values.enteredName && !!account.id ? 'incomplete-field' : undefined
                                                        }
                                                    }}
                                                    required autoFocus
                                                    xs={12}
                                                />
                                                {management &&
                                                    <BRadioGroup
                                                        name="internal"
                                                        radios={accountTypes}
                                                        label='Account Type'
                                                        className={'modal-radio-buttons'}
                                                        sameButtonColor='primary'
                                                    />
                                                }
                                                {(management || internal) &&
                                                    <>
                                                        <BSelect
                                                            name="preferredPayType"
                                                            label="Payment Preference"
                                                            options={getOptions(formikValues)}
                                                            variant='outlined'
                                                            sm={formikValues.values.preferredPayType === 'ACH' ? 6 : 12}
                                                            xs={12}
                                                        />
                                                        {formikValues.values.preferredPayType === 'ACH' &&
                                                            <BTextField
                                                                name="achAccountingNo"
                                                                label="ACH Account Number"
                                                                placeholder="Account Number"
                                                                InputLabelProps={{ shrink: true }}
                                                                sm={6}
                                                                xs={12}
                                                            />
                                                        }
                                                    </>
                                                }
                                                {!management && !internal &&
                                                    <BGrid justify={'center'} noMP>
                                                        <Typography className={'mailing-address-label'}>Invoice Mailing Address</Typography>
                                                    </BGrid>
                                                }
                                                <BTextField
                                                    name="addressLine1"
                                                    label="Address Line 1"
                                                    placeholder="Address Line 1"
                                                    InputLabelProps={{ shrink: true }}
                                                    xs={12}
                                                />
                                                <BTextField
                                                    name="addressLine2"
                                                    label="Address Line 2"
                                                    placeholder="Address Line 2"
                                                    InputLabelProps={{ shrink: true }}
                                                    xs={12}
                                                />
                                                <BTextField
                                                    name="city"
                                                    label="City"
                                                    placeholder="City"
                                                    InputLabelProps={{ shrink: true }}
                                                    xs={12} sm={5}
                                                />
                                                <BTextField
                                                    name="state"
                                                    label="State"
                                                    placeholder="State"
                                                    InputLabelProps={{ shrink: true }}
                                                    xs={12} sm={2}
                                                />
                                                <BTextField
                                                    name="zipCode"
                                                    label="Zip Code"
                                                    placeholder="Zip Code"
                                                    InputLabelProps={{ shrink: true }}
                                                    xs={12} sm={5}
                                                />
                                            </BGrid>
                                        </div>
                                        {(management || internal) &&
                                            <div className='form-section not-first'>
                                                <Grid container>
                                                    <Typography className="title">Payment Addresses</Typography>
                                                    <Tooltip arrow title={'EFT = Electronic Funds Transfer'}><Info className='info' /></Tooltip>
                                                </Grid>
                                                <BGrid container gridClassName='body'>
                                                    <BTextField
                                                        name="checkAddress"
                                                        label="Check Mailing Address"
                                                        placeholder="Check Mailing Address"
                                                        InputLabelProps={{ shrink: true }}
                                                        multiline
                                                        minRows={5}
                                                        xs={12}
                                                    />
                                                    <BTextField
                                                        name="eftAddress"
                                                        label="EFT Address"
                                                        placeholder="Electronic Funds Transfer Address"
                                                        InputLabelProps={{ shrink: true }}
                                                        multiline
                                                        minRows={5}
                                                        xs={12}
                                                    />
                                                </BGrid>
                                            </div>
                                        }
                                        <div className='form-section not-first'>
                                            <Grid container>
                                                <Typography className={contactErrors ? 'title error' : 'title'}>Contacts</Typography>
                                                {!management && <Tooltip arrow title={'Before a study begins, an account must have one primary contact in addition to any other contacts.'}><Info className='info' /></Tooltip>}
                                            </Grid>
                                            <BGrid container gridClassName={getContactClassName()}>
                                                <div ref={contactSectionRef} />
                                                {contactsList.map((obj: AccountContact, idx: number) => {
                                                    return (
                                                        <div key={'contact-' + idx}>{displayContact(obj)}</div>
                                                    )
                                                })}
                                                <Button color="secondary" name='contact-button' onClick={handleAddNewContact} className={'add-contact-button'}>
                                                    <div className={'button-border'}>
                                                        <AddIcon className={'icon'} />
                                                        <Typography variant={'body2'} className={'bold-text'}>Add Another Contact</Typography>
                                                    </div>
                                                </Button>
                                            </BGrid>
                                            <BError name={!management ? 'general' : 'general-errors'} id='general-errors' />
                                        </div>
                                    </CardContent>
                                    {displayCardActions()}
                                </Card>
                            </BForm>
                        }
                    </Formik>
                </div>
            </Modal>
            {management &&
                <SimpleConfirmDelete open={openConfirmDelete} setOpen={setOpenConfirmDelete} type={'Account'} objectName={account.name} handleDelete={handleDeleteAccount} />
            }
        </>
    );
}

export default AccountingModal;
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
    Card, CardContent, CardHeader, Grid, IconButton, Typography
} from '@material-ui/core';

import { Edit } from '@material-ui/icons';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

import { Simulator } from '../../../@types';
import { handleErrorResponse } from '../../../service/utils';
import { getSimulator } from '../../../service/Simulator/simulator';
import usePermissions from '../../../common/hooks/usePermissions';
import YearSelector from '../../../common/DataTable/YearSelector';

import ResourceCharts from './Outputs/Resource/ResourceCharts';
import TotalPatientsCard from './Outputs/Summary/TotalPatientsCard';
import TotalEmployeesCard from './Outputs/Summary/TotalEmployeesCard';
import ProductivityChart from './Outputs/Productivity/ProductivityChart';
import YearlyProductivityCard from './Outputs/Summary/YearlyProductivityCard';
import LifetimeProductivityCard from './Outputs/Summary/LifetimeProductivityCard';

import Variables from './Variables/Variables';
import SimulatorEditDetailsModal from './SimulatorEditDetailsModal';
import { statusStyle } from '../Utils';


interface RouteParams {
    id: string;
}

type OutputType = 'productivity' | 'resources';

const SimulatorDetail: React.FC = () => {
    const { id } = useParams<RouteParams>();

    const history = useHistory();
    const dispatcher = useDispatch();

    const currentYear: number = new Date().getFullYear();

    const [simulator, setSimulator] = React.useState<Simulator>({
        id: null,
        subtitle: '',
        description: '',
        created: new Date(),
        author: '',
        studyId: 0,
        studyName: '',
        studyIdentifier: '',
        divisionId: 0,
        divisionName: '',
        patientCount: 0,
        optimalPatientCount: 0,
        enrollmentDuration: 0,
        visitCoverage: 0,
        employeeGeneric: true,
        lifetimeProductivity: 0,
        sourceStatus: ''
    });
    const [editYear, setEditYear] = React.useState<number>(currentYear);
    const [outputType, setOutputType] = React.useState<OutputType>('productivity');
    const [variablesExpanded, setVariablesExpanded] = React.useState<boolean>(false);
    const [editDetailsOpen, setEditDetailsOpen] = React.useState<boolean>(false);
    const [updated, setUpdated] = React.useState<boolean>(false);

    const canViewResourceCharts = usePermissions('API_SIMULATOR_GET_MONTHLY_RESOURCE_RESULT_BY_YEAR');

    React.useEffect(() => {
        getSimulator(Number(id)).then(res => {
            setSimulator(res.data);
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                prefix: 'Could not get simulator: '
            })
        });
    }, [id, updated]);

    return (
        <div className='simulator-page'>
            <div className='page-content'>
                <div className='simulator-page-heading aligned-row'>
                    <div className='left'>
                        <Typography className='title' onClick={() => history.push(`/study/${simulator.studyId}`)}>
                            {simulator.studyName}
                        </Typography>
                        <div className={statusStyle(simulator)[1] + ' sort-field'}>
                            {statusStyle(simulator)[0]}
                        </div>
                    </div>
                    <div className='simulator-output-mode'>
                        <div className="row right">
                            <Typography>Output Mode:</Typography>
                            <ToggleButtonGroup
                                value={outputType}
                                onChange={((event: any, value: any) => setOutputType(value))}
                                className="mode-toggle-group"
                                style={{ marginLeft: '0.5vw' }}
                                size="small"
                                exclusive
                            >
                                <ToggleButton value="productivity" disabled={outputType === 'productivity'}>
                                    <Typography>Productivity</Typography>
                                </ToggleButton>
                                {canViewResourceCharts &&
                                    <ToggleButton value="resources" disabled={outputType === 'resources'}>
                                        <Typography>Resource Requirements</Typography>
                                    </ToggleButton>
                                }
                            </ToggleButtonGroup>
                        </div>
                        <YearSelector year={editYear} setYear={setEditYear} allowFuture className='simulator-year-select' />
                    </div>
                </div>
                <Grid container spacing={1} className='simulator-detail'>
                    <Grid item xs={12} sm={variablesExpanded ? 12 : 6} md={variablesExpanded ? 6 : 3} className='simulator-side-bar transition'>
                        {!variablesExpanded &&
                            <div style={{ position: 'relative', minWidth: '100%' }} className='transition' >
                                <Card className='description'>
                                    <IconButton size='small' onClick={() => setEditDetailsOpen(true)} className='card-corner-button'>
                                        <Edit />
                                    </IconButton>
                                    <Typography className='simulator-subtitle'>
                                        {simulator.subtitle}
                                    </Typography>
                                    {simulator.description &&
                                        <Typography className='simulator-description'>
                                            {simulator.description}
                                        </Typography>
                                    }
                                </Card>
                            </div>
                        }
                        {simulator.id &&
                            <Variables
                                simulator={simulator}
                                expanded={variablesExpanded}
                                setExpanded={setVariablesExpanded}
                                updateParent={() => setUpdated(!updated)}
                            />}
                    </Grid>
                    <Grid item xs={12} sm={variablesExpanded ? 12 : 6} md={variablesExpanded ? 6 : 9} className='simulator-body transition'>
                        <Grid container spacing={2}>
                            {outputType === 'productivity' ?
                                <>
                                    <Grid item xs={6} sm={variablesExpanded ? 6 : 12} md={6} lg={variablesExpanded ? 6 : 3}>
                                        <LifetimeProductivityCard simulatorId={Number(id)} />
                                    </Grid>
                                    <Grid item xs={6} sm={variablesExpanded ? 6 : 12} md={6} lg={variablesExpanded ? 6 : 3}>
                                        <YearlyProductivityCard simulatorId={Number(id)} year={editYear} />
                                    </Grid>
                                    <Grid item xs={6} sm={variablesExpanded ? 6 : 12} md={6} lg={variablesExpanded ? 6 : 3}>
                                        <TotalEmployeesCard simulatorId={Number(id)} />
                                    </Grid>
                                    <Grid item xs={6} sm={variablesExpanded ? 6 : 12} md={6} lg={variablesExpanded ? 6 : 3}>
                                        <TotalPatientsCard simulatorId={Number(id)} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Card className='description'>
                                            <CardHeader title='Study Health' />
                                            <CardContent className='card-content'>
                                                <ProductivityChart id={Number(id)} editYear={editYear} setEditYear={setEditYear} />
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </> :
                                <ResourceCharts simulatorId={Number(id)} editYear={editYear} setEditYear={setEditYear} />
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <SimulatorEditDetailsModal
                    open={editDetailsOpen}
                    setOpen={setEditDetailsOpen}
                    simulator={simulator}
                    setSimulator={setSimulator}
                />
            </div>
        </div >
    )
}
export default SimulatorDetail;
/*** CONSTANTS ***/
export const UPDATE_SIMULATOR_CHARTS = 'simulatorReducer/UPDATE_SIMULATOR_CHARTS';

/*** ACTIONS ***/
export interface UpdateSimulatorCharts {
    type: typeof UPDATE_SIMULATOR_CHARTS;
}

export type SimulatorAction = UpdateSimulatorCharts;

/*** ACTION CREATORS ***/
export const updateSimulatorCharts = (): UpdateSimulatorCharts => {
    return {
        type: UPDATE_SIMULATOR_CHARTS,
    };
};

/*** REDUCER ***/
export interface SimulatorState {
    updated: boolean;
}

export const initialState: SimulatorState = {
    updated: false,
};

export const reducer = (
    state: SimulatorState = initialState,
    action: SimulatorAction
): SimulatorState => {
    if (action.type === UPDATE_SIMULATOR_CHARTS) {
        return {
            updated: !state.updated,
        };
    } else {
        /* istanbul ignore next */
        return state;
    }
};

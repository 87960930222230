import * as React from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Modal,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import { Close, Save } from '@material-ui/icons';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import {
    BButton,
    BDatePicker,
    BForm,
    BGrid,
    BSubmit,
    BTextField
} from 'mui-bueno';

import { SelectOption } from '../../../../../@types';
import { shortUserString } from '../../../../../common/Utils/utils';
import { NumberFormatter } from '../../../../../common/Utils/NumberFormatter';
import { handleErrorResponse } from '../../../../../service/utils';
import { getActiveRestrictedUserSummaries } from '../../../../../service/Management/users';
import { createDetailedEmployee, importExistingUser } from '../../../../../service/Simulator/simulator';
import { showSuccessSnackbar } from '../../../../../modules/messageSnackbarReducer';
import { updateSimulatorCharts } from '../../../../../modules/simulatorReducer';

import CheckedAutocomplete from '../../../../../common/CheckedAutocomplete/CheckedAutocomplete';


type FormResourceType = 'Coordinator' | 'Provider';
interface SimulatorUserAddForm extends Record<string, any> {
    id: number | null;
    userId: number | null;
    userName: string;
    started: Date;
    ended: Date | null;
    resourceType: FormResourceType;
    effectiveDate: Date;
    salary: string;
    fringe: string;
}

const schema = yup.object<SimulatorUserAddForm>().shape({
    userName: yup.string().required('Name is required'),
    started: yup.date().required('Started Date is required'),
    ended: yup.date().nullable(),
    effectiveDate: yup.date().required('Salary Log Effective Date is required'),
    salary: yup.number().required('Salary is required'),
    fringe: yup.number().required('Fringe is required')
});

const initialValues: SimulatorUserAddForm = {
    id: null,
    userId: null,
    userName: '',
    started: new Date(),
    ended: null,
    resourceType: 'Coordinator',
    effectiveDate: new Date(),
    salary: '',
    fringe: '',
    importEmployee: '',
};

interface Props {
    simulatorId: number;
    open: boolean;
    setOpen: (arg0: boolean) => void;
    setUpdated: () => void;
}
const AddEmployeeDetailedModal: React.FC<Props> = (props) => {
    const { simulatorId, open, setOpen, setUpdated } = props;

    const dispatch = useDispatch();

    const [formMode, setFormMode] = React.useState<'new' | 'import'>('new');
    const importEmployeeMode: boolean = (formMode === 'import');
    const [resourceType, setResourceType] = React.useState<FormResourceType>('Coordinator');
    const [employeeOptions, setEmployeeOptions] = React.useState<SelectOption<number>[]>([]);
    const [importEmployee, setImportEmployee] = React.useState<number>(-1);
    
    React.useEffect(() => {
        if (formMode != 'import') return;
        getActiveRestrictedUserSummaries()
            .then(res => {
                setEmployeeOptions(res.data.map(user => {
                    return({
                        value: user.id,
                        label: shortUserString(user, true)
                    });
                }).sort((a, b) => a.label.localeCompare(b.label)));
            })
            .catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve list of Users: '
                });
            });
    }, [formMode]);

    const handleValidate = (data: SimulatorUserAddForm) => {
        const errorList: { [k: string]: string } = {};
        
        if (data.ended && (data.started.getTime() > data.ended.getTime())) {
            errorList['ended'] = 'Employee Date Removed cannot be before Employee Start Date';
        }
        if (data.effectiveDate.getTime() > data.started.getTime()) {
            errorList['effectiveDate'] = 'Salary Log Effective Date cannot be after Employee Start Date';
        }

        return errorList;
    }

    const handleSubmit = async (data: SimulatorUserAddForm, { setErrors }: FormikHelpers<any>) => {
        const submitData = {
            user: {
                id: null,
                simulatorId: simulatorId,
                userId: null,
                userName: data.userName,
                started: data.started,
                ended: data.ended,
                resourceType: resourceType
            },
            salaryLog: {
                id: null,
                simulatorUserId: null,
                salary: +data.salary,
                fringe: +data.fringe,
                effectiveDate: data.effectiveDate
            }
        };
        createDetailedEmployee(submitData)
            .then(res => {
                dispatch(showSuccessSnackbar(`Simulator Employee: ${res.data.user.userName} successfuly created`));
                dispatch(updateSimulatorCharts());
                setUpdated();
                setOpen(false);
            })
            .catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not create new Simulator Employee: ',
                    setStatus: setErrors
                });
            });
    };

    const handleImport = async () => {
        importExistingUser(simulatorId, importEmployee)
            .then(res => {
                dispatch(showSuccessSnackbar(`Employee: ${res.data.simulatorUser.userName} successfuly imported into Simulator`));
                dispatch(updateSimulatorCharts());
                setUpdated();
                setOpen(false);
            })
            .catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not import exiting Employee into Simulator: '
                });
            });
    };

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <div className="modal-form">
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validate={handleValidate}
                    validationSchema={schema}
                    enableReinitialize
                    validateOnBlur={false}
                    validateOnChange={false}
                >
                    <BForm>
                        <Card className="detail-form sm">
                            <CardHeader
                                title={importEmployeeMode ? 'Import Existing Employee' : 'Add Simulator Employee'}
                                action={
                                    <IconButton color="primary" aria-label="Close" onClick={() => setOpen(false)}>
                                        <Close />
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                <BGrid container spacing={1}>
                                    <BGrid noMP xs={12} gridClassName='flex-center'>
                                        <FormControl component="fieldset">
                                            <RadioGroup row aria-label="Employee Add Mode" name="employee-add-mode"
                                                value={formMode} onChange={((event: any, value: any) => setFormMode(value))}
                                            >
                                                <FormControlLabel value="new" control={<Radio />}
                                                    label="Enter Employee details"
                                                />
                                                <FormControlLabel value="import" control={<Radio />}
                                                    label="Import existing Employee"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </BGrid>
                                    {importEmployeeMode
                                        ? <BGrid noMP xs={12}>
                                            <Typography variant="body1">Employee to import</Typography>
                                            <CheckedAutocomplete
                                                idText="importEmployee"
                                                options={employeeOptions}
                                                onChange={(val: SelectOption<number>) => setImportEmployee(val?.value ?? -1)}
                                                variant="outlined"
                                                multiple={false}
                                                closeOnSelect
                                                required
                                            />
                                        </BGrid>
                                        : <>
                                            <BTextField
                                                name="userName"
                                                label="Name"
                                                staticLabel
                                                noMP xs={12}
                                            />
                                            <BGrid noMP xs={12} gridClassName='employee-type-toggle'>
                                                <Typography>
                                                    Type
                                                </Typography>
                                                <ToggleButtonGroup
                                                    value={resourceType}
                                                    onChange={((event: any, value: FormResourceType) => setResourceType(value))}
                                                    size='small'
                                                    exclusive
                                                >
                                                    <ToggleButton value="Coordinator" disabled={resourceType === 'Coordinator' || importEmployeeMode} color="primary">
                                                        <Typography>Coordinator</Typography>
                                                    </ToggleButton>
                                                    <ToggleButton value="Provider" disabled={resourceType === 'Provider' || importEmployeeMode} color="primary">
                                                        <Typography>Provider</Typography>
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </BGrid>
                                            <BDatePicker
                                                name='started'
                                                label='Date Added'
                                                staticLabel
                                                noMP xs={12} sm={6}
                                                disabled={importEmployeeMode}
                                            />
                                            <BDatePicker
                                                name='ended'
                                                label='Date Removed'
                                                staticLabel
                                                noMP xs={12} sm={6}
                                                disabled={importEmployeeMode}
                                            />
                                            <BDatePicker
                                                name='effectiveDate'
                                                label='Salary Log Effective Date'
                                                staticLabel
                                                noMP xs={12} sm={6}
                                                disabled={importEmployeeMode}
                                            />
                                            <BTextField
                                                name="salary"
                                                label="Salary"
                                                placeholder={undefined}
                                                staticLabel
                                                noMP xs={12} sm={3}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    inputComponent: NumberFormatter as any
                                                }}
                                                disabled={importEmployeeMode}
                                            />
                                            <BTextField
                                                name="fringe"
                                                label="Fringe"
                                                placeholder={undefined}
                                                staticLabel
                                                noMP xs={12} sm={3}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                    inputComponent: NumberFormatter as any
                                                }}
                                                disabled={importEmployeeMode}
                                            />
                                        </>}
                                </BGrid>
                            </CardContent>
                            <CardActions className="flex-end">
                                {importEmployeeMode
                                    ? <BButton
                                        startIcon={<Save />}
                                        variant="contained"
                                        type="button"
                                        onClick={() => handleImport()}
                                    >
                                        Import
                                    </BButton>
                                    : <BSubmit
                                        startIcon={<Save />}
                                        variant="contained"
                                    >
                                        Save
                                    </BSubmit>}
                                
                            </CardActions>
                        </Card>
                    </BForm>
                </Formik>
            </div>
        </Modal>
    );
}
export default AddEmployeeDetailedModal;
import * as React from 'react';

import {
    FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

interface Props {
    label: string;
    value: any;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    error: any;
}

const OutlinedPassword: React.FC<Props> = props => {
    const {
        label,
        value,
        onChange,
        error
    } = props;

    const [showPassword, setShowPassword] = React.useState<boolean>(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }

    return (
        <FormControl margin="dense" variant="outlined" fullWidth>
            <InputLabel htmlFor={'password-outlined-' + label}>{label}</InputLabel>
            <OutlinedInput
                id={'password-outlined-' + label}
                label="Password"
                type={showPassword ? 'text' : 'password'}
                value={value}
                onChange={onChange}
                error={!!error}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            <FormHelperText id="password-outlined-helper-text" error>{error}</FormHelperText>
        </FormControl>
    )
}

export default OutlinedPassword;
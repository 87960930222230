import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Button, Typography } from '@material-ui/core';

const NotFound: React.FC = () => {
    const history = useHistory();

    const handleHomePage = () => {
        history.push('/');
    }

    return (
        <div className="full-screen centered">
            <Box textAlign="center" className="page-body">
                <Typography gutterBottom variant="h1">
                    Page Not Found
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                    The page you are looking for does not exist.
                </Typography>
                <Button 
                    variant="outlined" 
                    color="primary" 
                    onClick={handleHomePage}
                    style={{margin: 40}}
                >
                    Back to Home
                </Button>
            </Box>
        </div>
    );
};
export default NotFound;
import { useSelector } from 'react-redux';

import { PermissionConst } from '../../@types';

import { Store } from '../../modules/rootReducer';

const usePermissions = (permission: PermissionConst) => {
    const userPerms = useSelector<Store, PermissionConst[]>(store =>
        store.loginReducer.permissions
    );

    return userPerms.includes(permission);
};

export default usePermissions;
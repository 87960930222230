import * as React from 'react';
import { Prompt } from 'react-router-dom';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

interface Props {
    unsavedChanges: boolean;
    dialogOpen: boolean;
    handleDialogClose: (accept: boolean) => void;
}

const VisitModalWarning: React.FC<Props> = props => {

    const { unsavedChanges, dialogOpen, handleDialogClose } = props;

    return (
        <>
            <Prompt
                when={unsavedChanges}
                message='This page may contain unsaved changes. Are you sure you wish to leave?'
            />
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>
                    Are you sure you wish to leave?
                </DialogTitle>
                <DialogContent>
                    This page may contain unsaved changes.
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDialogClose(false)}>Cancel</Button>
                    <Button onClick={() => handleDialogClose(true)} autoFocus>Ok</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default VisitModalWarning;
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@material-ui/core';
import { Add, ArrowDropDown, Delete, Edit, Info } from '@material-ui/icons';

import { RoleSummary, TableHeader } from '../../../@types';
import { deleteRole, getRoleSummaries } from '../../../service/Management/roles';
import { handleErrorResponse } from '../../../service/utils';
import { DisplayTextFormat } from '../../../common/Utils/DisplayTextFormat';
import { showSuccessSnackbar } from '../../../modules/messageSnackbarReducer';

import usePermissions from '../../../common/hooks/usePermissions';
import SearchBar from '../../../common/SearchBar/SearchBar';
import SimpleConfirmDelete from '../../../common/ConfirmDelete/SimpleConfirmDelete';


const headers: TableHeader[] = [{
    type: 'name',
    displayValue: 'Name',
    align: 'left',
}, {
    type: 'description',
    displayValue: 'Description',
    align: 'left',
}];

interface State {
    objects: any[];
    rowsPerPage: number;
    requestedPage: number;
    orderBy: string;
    searchText: string;
    totalElements: number;
    page: number;
}

const initialState: State = {
    objects: [],
    rowsPerPage: 10,
    requestedPage: 0,
    orderBy: 'name',
    searchText: '',
    totalElements: 0,
    page: 0
}

interface Action {
    type: 'objectPage' | 'requestedPage' | 'rowsPerPage' | 'orderBy' | 'searchText';
    payload?: any;
}

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'objectPage':
            return {
                ...state,
                objects: action.payload.list,
                totalElements: action.payload.total,
                page: action.payload.page
            }
        case 'requestedPage':
            return {
                ...state,
                requestedPage: action.payload
            }
        case 'rowsPerPage':
            return {
                ...state,
                rowsPerPage: action.payload,
                page: 0,
                requestedPage: 0
            }
        case 'orderBy':
            return {
                ...state,
                orderBy: action.payload
            }
        case 'searchText':
            return {
                ...state,
                searchText: action.payload,
                page: 0,
                requestedPage: 0
            }
        default:
            return state;
    }
}

const RoleList: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const permissionCreateRole: boolean = usePermissions('API_ROLE_CREATE');
    const permissionDeleteRole: boolean = usePermissions('API_ROLE_DELETE_BY_ID');
    const permissionEditRole: boolean = usePermissions('API_ROLE_UPDATE');

    const [roleSummaries, setRoleSummaries] = React.useState<RoleSummary[]>([]);
    const [selectedRole, setSelectedRole] = React.useState<RoleSummary | null>(null);
    const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);
    const [updated, setUpdated] = React.useState<boolean>(false);
    const [state, dispatchPage] = React.useReducer(reducer, initialState);
    const {
        objects,
        rowsPerPage,
        requestedPage,
        orderBy,
        searchText,
        totalElements,
        page
    } = state;

    React.useEffect(() => {
        getRoleSummaries()
            .then(res => {
                setRoleSummaries(res.data);
                dispatchPage({ type: 'objectPage', payload: { list: res.data, page: 0, total: res.data.length } });
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve list of Roles: '
                });
            });
    }, [updated]);

    React.useEffect(() => {
        if (roleSummaries.length > 0) {
            let filteredList: RoleSummary[] = roleSummaries;

            if (Number(searchText) !== 0) {
                filteredList = filteredList.filter(role =>
                    role.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
                    || role.description.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
                );
            }

            if (['name', 'description'].includes(orderBy)) {
                filteredList = filteredList.sort((a, b) => (a[orderBy] ?? '').localeCompare(b[orderBy] ?? ''));
            }

            let displayList: RoleSummary[] = filteredList;
            if (filteredList.length > rowsPerPage) {
                displayList = filteredList.slice((requestedPage * rowsPerPage), ((1 + requestedPage) * rowsPerPage));
            }

            dispatchPage({
                type: 'objectPage',
                payload: {
                    list: displayList,
                    page: requestedPage,
                    total: filteredList.length
                }
            });
        }
    }, [roleSummaries, requestedPage, rowsPerPage, orderBy, searchText]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        dispatchPage({ type: 'requestedPage', payload: newPage });
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        dispatchPage({ type: 'rowsPerPage', payload: parseInt(event.target.value, 10) });
    }
    const handleChangeSortBy = (header: string) => (event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => {
        dispatchPage({ type: 'orderBy', payload: header });
    }
    const handleChangeSearchText = (text: string) => {
        dispatchPage({ type: 'searchText', payload: text });
    }

    const handleDelete = (id: null | number) => {
        if (id) {
            deleteRole(id)
                .then(() => {
                    setUpdated(!updated);
                    setDeleteOpen(false);
                    dispatch(showSuccessSnackbar('Role successfully deleted'));
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not delete Role: '
                    });
                });
        }
    };

    const paginationFeatures = (full: boolean) => {
        return (
            <TablePagination
                component="div"
                count={totalElements ?? initialState.totalElements}
                rowsPerPage={rowsPerPage}
                page={page ?? initialState.page}
                rowsPerPageOptions={[5, 10, 20, 30]}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Items Per Page:"
                className={full ? '' : 'paging-arrows'}
            />
        )
    }

    return (
        <>
            <div className="page-heading">
                <Typography className="title">Role Management</Typography>
            </div>
            <div className="page-body max-lgxl">
                <div className="aligned-row">
                    <div className="left">
                        <SearchBar query={searchText} setQuery={handleChangeSearchText} />
                    </div>
                    <div className="right">
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Add />}
                            onClick={() => history.push('/manage/role/new')}
                            disabled={!permissionCreateRole}
                        >
                            Add Role
                        </Button>
                    </div>
                </div>
                {paginationFeatures(true)}
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead className="table-header">
                            <TableRow>
                                {headers.map(header => {
                                    return (
                                        <TableCell
                                            key={'header-' + header.type}
                                            onClick={handleChangeSortBy(header.type)}
                                            className={'cursor-pointer primary-cell ' +
                                                (orderBy === header.type && 'selected')
                                            }
                                        >
                                            <div className={`header-cell-label ${header.align}`}>
                                                {header.displayValue}
                                                {orderBy === header.type && <ArrowDropDown />}
                                            </div>
                                        </TableCell>
                                    )
                                })}
                                <TableCell key='header-actions' className='primary-cell'>
                                    <div className="header-cell-label center">
                                        Actions
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {objects.map((obj: any, idx: number) => {
                                return (
                                    <TableRow key={'row-' + idx}>
                                        {headers.map(header => {
                                            return (
                                                <TableCell
                                                    key={header.type + '-' + idx}
                                                    className={`body-cell ${header.align}`}
                                                >
                                                    {header.type == 'description' ? DisplayTextFormat(obj[header.type], 64) : DisplayTextFormat(obj[header.type])}
                                                </TableCell>
                                            )
                                        })}
                                        <TableCell
                                            key={`actions-${idx}`}
                                            className="body-cell center"
                                        >
                                            <Tooltip arrow title={permissionEditRole ? 'View/Edit Role Details' : 'View Role Details'}>
                                                <IconButton
                                                    onClick={() => { history.push(`/manage/role/${obj.id}`) }}
                                                    style={{ padding: '6px' }}
                                                >
                                                    {permissionEditRole ? <Edit /> : <Info />}
                                                </IconButton>
                                            </Tooltip>
                                            {permissionDeleteRole && <Tooltip arrow title="Delete Role">
                                                <IconButton
                                                    onClick={() => {
                                                        setSelectedRole(obj);
                                                        setDeleteOpen(true);
                                                    }}
                                                    style={{ padding: '6px' }}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    {objects.length === 0 && <Typography variant="body1" className="flex-center margin-2">No data to display.</Typography>}
                </TableContainer>
                {paginationFeatures(false)}
            </div>
            <SimpleConfirmDelete
                open={deleteOpen}
                setOpen={setDeleteOpen}
                type="Role"
                objectName={selectedRole?.name ?? ''}
                handleDelete={() => handleDelete(selectedRole?.id ?? null)}
            />
        </>
    )
}

export default RoleList;
import * as React from 'react';

import {
    Card, CardActions, CardContent, CardHeader, CircularProgress, Grid, IconButton, Input, Modal, Typography,
} from '@material-ui/core';
import { Close, Save } from '@material-ui/icons';
import { Formik } from 'formik';
import { BForm, BSubmit } from 'mui-bueno';

import { Directory, Document, SelectOption } from '../../../../../@types';
import { uploadExistingDocument, uploadNewDocument } from '../../../../../service/Study/documents';
import { useDispatch } from 'react-redux';
import { showSuccessSnackbar } from '../../../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../../../service/utils';
import CheckedAutocomplete from '../../../../../common/CheckedAutocomplete/CheckedAutocomplete';
import { getDirectoriesByStudy } from '../../../../../service/Study/directories';

interface Props {
    open: any;
    setOpen: any;
    setUpdated: any;
    studyId: number;
    selectedDocument: Document | null;
    setSelectedDocument: any;
    currentDirectory?: Directory;
}

const UploadModal: React.FC<Props> = props => {
    const { open, setOpen, setUpdated, studyId, selectedDocument, setSelectedDocument, currentDirectory } = props;

    const [document, setDocument] = React.useState<Document>({
        id: null,
        studyId: studyId,
        directoryId: 0,
        fileName: '',
        filePath: '',
        deleted: false,
        createdAt: new Date()
    });

    const [selectedFile, setSelectedFile] = React.useState<File>();
    const [directoryOptions, setDirectoryOptions] = React.useState<SelectOption<number>[]>([]);
    const [selectedDirectory, setSelectedDirectory] = React.useState<SelectOption<number> | null>(null);
    const [saving, setSaving] = React.useState<boolean>(false);
    const [inputErrors, setInputErrors] = React.useState<{ [k: string]: string }>({});

    const dispatcher = useDispatch();

    /*
        Determined acceptable file extensions:
        '.pdf', '.doc', '.docx',
        '.png', '.jpg', '.jpeg',
        '.csv', '.xls', '.xlsx',
        '.txt', '.ppt', '.pptx'
    */
    // Converted file extensions to their respective MIME type
    const acceptableFileTypes: string[] = [
        'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'image/png', 'image/jpg', 'image/jpeg',
        'text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/plain', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ];

    React.useEffect(() => {
        getDirectoriesByStudy(studyId).then(res => {
            const tempDirectoryOptions: SelectOption<number>[] = [];
            res.data.forEach((directory: Directory) => {
                tempDirectoryOptions.push({
                    value: directory.id!,
                    label: directory.name
                })
            })
            // This occurs when clicking the 'Upload New Document' button while inside a directory
            if (currentDirectory) {
                setSelectedDirectory({
                    value: currentDirectory.id!,
                    label: ''
                });
            }
            setDirectoryOptions(tempDirectoryOptions);
        });

        setSaving(false);
        setInputErrors({});
    }, [open]);

    React.useEffect(() => {
        if (selectedDocument) {
            setDocument(selectedDocument);
        } else {
            setDocument({
                id: null,
                studyId: studyId,
                directoryId: 0,
                fileName: '',
                filePath: '',
                deleted: false,
                createdAt: new Date()
            });
        }
        setInputErrors({});
    }, [selectedDocument]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files;
        if (!fileList) return;
        setSelectedFile(fileList[0]);
        setInputErrors({});
    };

    const validate = (): boolean => {
        const errorList: { [k: string]: string } = {};
        // Only when uploading a new document is a directory selected
        if (!selectedDocument && !currentDirectory && (!selectedDirectory || selectedDirectory.value === 0)) {
            errorList['directory'] = 'Directory is required.';
        }
        if (!selectedFile) {
            errorList['file'] = 'File is required.';
        } else if (!acceptableFileTypes.includes(selectedFile.type)) {
            errorList['file'] = 'Invalid file type.';
        }

        setInputErrors(errorList);
        return Object.keys(errorList).length === 0;
    };

    const handleSubmit = () => {
        if (!validate()) return;

        if (selectedFile && !saving) {
            if (!selectedDocument) {
                setSaving(true);
                const formData = new FormData();
                formData.append('file', selectedFile, selectedFile.name);
                uploadNewDocument(studyId, selectedDirectory!.value, formData)
                    .then(res => {
                        dispatcher(showSuccessSnackbar('New document uploaded.'));
                        handleModalClose();
                        setUpdated(true);
                    }).catch(err => {
                        handleErrorResponse(err, dispatcher, {
                            setStatus: setInputErrors,
                            prefix: 'Could not upload document: '
                        })
                        setSaving(false);
                    });
            } else {
                setSaving(true);
                const formData = new FormData();
                formData.append('file', selectedFile, selectedFile.name);
                uploadExistingDocument(selectedDocument.id!, selectedDocument.directoryId, formData)
                    .then(res => {
                        dispatcher(showSuccessSnackbar('New version uploaded.'));
                        setSelectedDocument(res.data);
                        handleModalClose();
                        setUpdated(true);
                    }).catch(err => {
                        handleErrorResponse(err, dispatcher, {
                            setStatus: setInputErrors,
                            prefix: 'Could not upload new version: '
                        })
                        setSaving(false);
                    });
            }
        }
    }

    const handleModalClose = () => {
        setSelectedFile(undefined);
        setSelectedDirectory(null);
        setOpen(false);
    }

    return (
        <Modal
            open={open}
            onClose={handleModalClose}
        >
            <div className="modal-form">
                <Formik
                    initialValues={document}
                    values={document}
                    onSubmit={handleSubmit}
                    enableReinitialize
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    <BForm>
                        <Card className="detail-form sm">
                            <CardHeader
                                title={selectedDocument ? 'Upload New Version' :
                                    currentDirectory ? 'Upload New Document to ' + currentDirectory.name :
                                        'Upload New Document'
                                }
                                action={
                                    <IconButton color="primary" aria-label="Close" onClick={handleModalClose}>
                                        <Close />
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                <Grid container spacing={4} justifyContent='center'>
                                    {(!selectedDocument && !currentDirectory) &&
                                        <Grid item xs={12} sm={10} md={12} lg={10}>
                                            <CheckedAutocomplete
                                                idText={'directoryId'}
                                                multiple={false}
                                                options={directoryOptions}
                                                labelText={'Directory'}
                                                closeOnSelect={true}
                                                onChange={setSelectedDirectory}
                                                error={!!inputErrors['directory']}
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12} sm={10} md={12} lg={10}>
                                        <Input type="file" name="fileSelect" autoFocus={true} fullWidth={true}
                                            onChange={handleFileChange}
                                            error={!!inputErrors['file']}
                                        />
                                        <Typography color='error'>{inputErrors['file']}</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions className="flex-end">
                                <BSubmit
                                    startIcon={saving ? null : <Save />}
                                    variant="contained"
                                    color="primary"
                                    disabled={saving}
                                >
                                    {saving && <CircularProgress size={24} className='button-progress' />}
                                    Upload
                                </BSubmit>
                            </CardActions>
                        </Card>
                    </BForm>
                </Formik>
            </div>
        </Modal>
    )
};

export default UploadModal;
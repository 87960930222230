import * as React from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { Card, CardActions, CardContent, CardHeader, Grid, IconButton, InputAdornment, Modal } from '@material-ui/core';
import { Close, Save } from '@material-ui/icons';
import { BForm, BSubmit, BTextField } from 'mui-bueno';

import { Contract } from '../../../../@types';
import { showSuccessSnackbar } from '../../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../../service/utils';
import { getContract, updateContract } from '../../../../service/Contract/contracts';


interface Props {
    open: boolean;
    setOpen: any;
    contractId: number;
    costs: any;
    setCosts: any;
}

const schema = yup.object<any>().shape({
    coordinatorCost: yup.number().required('Coordinator Cost is required'),
    providerCost: yup.number().required('Provider Cost is required'),
});

const EditEmployeeCosts: React.FC<Props> = props => {
    const { open, setOpen, contractId, costs, setCosts } = props;

    const dispatcher = useDispatch();

    const [contract, setContract] = React.useState<(Contract | null)>(null);

    React.useEffect(() => {
        getContract(contractId)
            .then(res => {
                setContract(res.data)
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    prefix: 'Could not retrieve Contract: '
                });
            });
    }, []);

    const handleValidate = (data: any) => {
        const errorList: { [k: string]: string } = {};
        schema.validate(data, { abortEarly: false })
            .catch((err: yup.ValidationError) => {
                for (const e of err.inner) {
                    if (e.path) errorList[e.path] = e.message;
                }
            });
        return errorList;
    }

    const handleSubmit = async (data: any, { setErrors }: FormikHelpers<any>) => {
        updateContract({ ...contract, ...data, id: contractId })
            .then(res => {
                dispatcher(showSuccessSnackbar('Employee costs updated'));
                setCosts(res.data);
                handleModalClose();
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    setStatus: setErrors,
                    prefix: 'Could not update Contract: '
                });
            });
    }

    const handleModalClose = () => {
        setOpen(false);
    }

    return (
        <Modal
            open={open}
            onClose={handleModalClose}
        >
            <div className="modal-form">
                <Formik
                    initialValues={{ ...costs }}
                    onSubmit={handleSubmit}
                    validate={handleValidate}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    <BForm>
                        <Card className="detail-form sm">
                            <CardHeader
                                title="Edit Employee Costs"
                                action={
                                    <IconButton color="primary" aria-label="Close" onClick={handleModalClose}>
                                        <Close />
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                <Grid container justifyContent='center'>
                                    <Grid item xs={7}>
                                        <BTextField
                                            name="coordinatorCost"
                                            label="Coordinator Cost"
                                            placeholder="e.g., 25"
                                            required autoFocus
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                endAdornment: <InputAdornment position="end">per hr</InputAdornment>
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={7}>
                                        <BTextField
                                            name="providerCost"
                                            label="Provider Cost"
                                            placeholder="e.g., 25"
                                            required
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                endAdornment: <InputAdornment position="end">per hr</InputAdornment>
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions className="flex-end">
                                <BSubmit
                                    startIcon={<Save />}
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </BSubmit>
                            </CardActions>
                        </Card>
                    </BForm>
                </Formik>
            </div>
        </Modal>
    )
};

export default EditEmployeeCosts;

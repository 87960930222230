import axiosInstance from '../Access/axiosInstance';
import { Account, AccountSummary, InvoiceWithStudyInfo, AccountWithCredit } from '../../@types';
import { handleError } from '../utils';

/**
 * Gets an account by id
 */
export const getAccount = (id: number) =>
    axiosInstance.get<Account>(
        `/api/accounts/${id}`
    ).catch(handleError);

/**
* Gets an account with total credit by id
*/
export const getAccountWithTotalCredit = (id: number) =>
    axiosInstance.get<AccountWithCredit>(
        `/api/accounts/withTotalCredit/${id}`
    ).catch(handleError);

/**
 * Gets the account summaries
 */
export const getAccountSummaries = () =>
    axiosInstance.get<AccountSummary[]>(
        '/api/accounts/summaries'
    ).catch(handleError);

/**
 * Gets all Receivable Invoices by Account Id
 */
export const getReceivableInvoicesList = (accountId: number) =>
    axiosInstance.get<InvoiceWithStudyInfo[]>(
        `/api/accounts/receivableInvoices/${accountId}`
    ).catch(handleError);

/**
 * Gets all Payable Invoices by Account Id
 */
export const getPayableInvoicesList = (accountId: number) =>
    axiosInstance.get<InvoiceWithStudyInfo[]>(
        `/api/accounts/payableInvoices/${accountId}`
    ).catch(handleError);
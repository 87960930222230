import * as React from 'react';
import { useDispatch } from 'react-redux';

import {
    Card, CardContent, CardHeader, IconButton, Modal,
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography
} from '@material-ui/core';
import { ArrowDropDown, Close } from '@material-ui/icons';
import { PlannedCostPageRequest, TableHeader } from '../../../../../@types';
import { showErrorSnackbar } from '../../../../../modules/messageSnackbarReducer';
import { getPlannedCostPage } from '../../../../../service/PlannedCost/plannedCost';
import ChooseEmployee from './ChooseEmployee';
import { initialStateOrderByName, reducer } from './utils';
import { NumberFormatter } from '../../../../../common/Utils/NumberFormatter';
import { DisplayTextFormat } from '../../../../../common/Utils/DisplayTextFormat';

const headers: TableHeader[] = [{
    type: 'name',
    displayValue: 'Description',
    align: 'left',
}, {
    type: 'actualCost',
    displayValue: 'Estimated Cost',
    align: 'right',
}, {
    type: 'invoiceType',
    displayValue: 'Invoice Type',
    align: 'left',
}, {
    type: 'serviceProvider',
    displayValue: 'Service Provider',
    align: 'left',
}, {
    type: 'comment',
    displayValue: 'Comment',
    align: 'left',
}];

interface Props {
    handleFromPlannedModalClose: () => void;
    contractId: any;
    employees: any;
    serviceProviders: any;
    setUpdatePage: any;
    open: any;
}

const CreateFromPlanned: React.FC<Props> = props => {
    const { handleFromPlannedModalClose, contractId, employees, serviceProviders, setUpdatePage, open } = props;

    const dispatcher = useDispatch();

    const [state, dispatchPage] = React.useReducer(reducer, initialStateOrderByName);
    const {
        objects,
        rowsPerPage,
        requestedPage,
        orderBy,
        totalElements,
        page
    } = state;
    const [chosenPlannedId, setChosenPlannedId] = React.useState<number>(0);

    // State management for page requests
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        dispatchPage({ type: 'requestedPage', payload: newPage });
    }
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        dispatchPage({ type: 'rowsPerPage', payload: parseInt(event.target.value, 10) });
    }
    const handleChangeSortBy = (header: string) => (event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => {
        dispatchPage({ type: 'orderBy', payload: header });
    }

    const paginationFeatures = (full: boolean) => {
        return (
            <TablePagination
                component="div"
                count={totalElements ?? initialStateOrderByName.totalElements}
                rowsPerPage={rowsPerPage}
                page={page ?? initialStateOrderByName.page}
                rowsPerPageOptions={[10, 20, 30]}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Items Per Page:"
                className={full ? '' : 'paging-arrows'}
            />
        )
    }

    // State management for choosing employee modal
    const [chooseEmployeeOpen, setChooseEmployeeOpen] = React.useState(false);
    const handleChooseEmployeeModalClose = () => {
        handleFromPlannedModalClose();
        setChooseEmployeeOpen(false);
    }

    React.useEffect(() => {
        if (contractId != 0) {
            const pageReq: PlannedCostPageRequest = {
                page: requestedPage,
                size: rowsPerPage,
                sort: orderBy,
                contractId: contractId
            }
            getPlannedCostPage(pageReq).then(res => {
                dispatchPage({ type: 'objectPage', payload: res.data });
            }).catch(err => {
                dispatcher(showErrorSnackbar('Could not find Planned Costs'));
            });
        }
    }, [contractId]);

    const handlePreFill = (plannedId: number) => {
        setChosenPlannedId(plannedId);
        setChooseEmployeeOpen(true);
        handleFromPlannedModalClose();
    }

    const formatInvoiceType = (invoiceType?: string) => {
        if (invoiceType) {
            const displayText = invoiceType.toLowerCase().replace('_', ' ')
            return DisplayTextFormat(displayText)
        } else {
            return ''
        }
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleFromPlannedModalClose}
            >
                <div className="modal-form">
                    <Card className="detail-form md">
                        <CardHeader
                            title="Select from Planned Costs"
                            action={
                                <IconButton color="primary" aria-label="Close" onClick={handleFromPlannedModalClose}>
                                    <Close />
                                </IconButton>
                            }
                        />
                        <CardContent>
                            {objects.length > 0 ? <>
                                <Typography>
                                    Click on a planned cost from the list below to create an incurred cost from. You will then be
                                    asked to select the date of occurrence and the employee in charge of the cost.
                                </Typography>
                                {paginationFeatures(true)}
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead className="table-header">
                                            <TableRow>
                                                {headers.map(header => {
                                                    return (
                                                        <TableCell
                                                            key={'header-' + header.type}
                                                            onClick={handleChangeSortBy(header.type)}
                                                            className={'cursor-pointer primary-cell ' +
                                                                (orderBy === header.type && 'selected')
                                                            }
                                                        >
                                                            <div className={`header-cell-label ${header.align}`}>
                                                                {header.displayValue}
                                                                {orderBy === header.type && <ArrowDropDown />}
                                                            </div>
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {objects.map((obj: any, idx: number) => {
                                                return (
                                                    <TableRow
                                                        className="cursor-pointer"
                                                        key={'row-' + idx}
                                                        hover={true}
                                                        onClick={() => handlePreFill(obj.id)}
                                                    >
                                                        <TableCell
                                                            key={'description-' + idx}
                                                            className={'body-cell left'}
                                                        >
                                                            {obj.name}
                                                        </TableCell>
                                                        <TableCell
                                                            key={'actual-cost-' + idx}
                                                            className={'body-cell right'}
                                                            style={{ width: '20%' }}
                                                        >
                                                            <NumberFormatter currency value={obj.actualCost} />
                                                        </TableCell>
                                                        <TableCell
                                                            key={'invoiceType-' + idx}
                                                            className={'body-cell left'}
                                                            style={{ textTransform: 'capitalize' }}
                                                        >
                                                            {formatInvoiceType(obj.invoiceType)}
                                                        </TableCell>
                                                        <TableCell
                                                            key={'serviceProvider-' + idx}
                                                            className={'body-cell left'}
                                                        >
                                                            {DisplayTextFormat(serviceProviders.find((o: { value: any; }) => o.value == obj.serviceProviderId)?.label)}
                                                        </TableCell>
                                                        <TableCell
                                                            key={'comment-' + idx}
                                                            className={'body-cell left'}
                                                            style={{ width: '30%' }}
                                                        >
                                                            {obj.comment}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </> :
                                <Typography>
                                    This study has no planned costs to select from.
                                </Typography>
                            }
                        </CardContent>
                    </Card>
                </div>
            </Modal>
            {chooseEmployeeOpen &&
                <ChooseEmployee
                    open={chooseEmployeeOpen}
                    handleChooseEmployeeModalClose={handleChooseEmployeeModalClose}
                    plannedId={chosenPlannedId} employees={employees}
                    setUpdatePage={setUpdatePage}
                />
            }
        </>
    )
};

export default CreateFromPlanned;
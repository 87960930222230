import * as React from 'react';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import { ArrowDropDown, Delete, Edit } from '@material-ui/icons';
import { BForm, BOption, BSelect, BSubmit, BTextField } from 'mui-bueno';

import { AccountPageRequest, PlannedCost, PlannedCostPageRequest, SelectOption, TableHeader } from '../../../../@types';
import { convertAccountDataToSelectOptions, convertInvoiceTypeDataToBOptions } from '../../../../common/Utils/utils';
import { DisplayTextFormat } from '../../../../common/Utils/DisplayTextFormat';
import { NumberFormatter } from '../../../../common/Utils/NumberFormatter';
import { Store } from '../../../../modules/rootReducer';
import { showSuccessSnackbar } from '../../../../modules/messageSnackbarReducer';
import { CollapsiblePanelState } from '../../../../modules/collapsiblePanelReducer';
import { handleErrorResponse } from '../../../../service/utils';
import { getStudy } from '../../../../service/Study/study';
import { getContract } from '../../../../service/Contract/contracts';
import { getAccountPage } from '../../../../service/Account/account';
import { getInvoiceTypes } from '../../../../service/InvoiceType/invoiceType';
import { createPlannedCost, deletePlannedCost, getPlannedCostPage, updatePlannedCost } from '../../../../service/PlannedCost/plannedCost';

import CheckedAutocomplete from '../../../../common/CheckedAutocomplete/CheckedAutocomplete';
import SimpleConfirmDelete from '../../../../common/ConfirmDelete/SimpleConfirmDelete';
import useLoggedInUserPermissions from '../../../../common/hooks/useLoggedInUserPermissions';
import EditPlannedCost from './EditPlannedCost';


const headers: TableHeader[] = [{
    type: 'name',
    displayValue: 'Name',
    align: 'left',
}, {
    type: 'sponsorProposal',
    displayValue: 'Sponsor Proposal',
    align: 'right',
}, {
    type: 'actualCost',
    displayValue: 'Actual Cost',
    align: 'right',
}, {
    type: 'maxOccurrences',
    displayValue: 'Max Occurrences',
    align: 'left',
}, {
    type: 'invoiceType',
    displayValue: 'Invoice Type',
    align: 'left',
}, {
    type: 'serviceProvider',
    displayValue: 'Service Provider',
    align: 'left',
}, {
    type: 'autoPayTrigger',
    displayValue: 'Autopay Trigger',
    align: 'left',
}, {
    type: 'comment',
    displayValue: 'Comments',
    align: 'left',
}];

interface State {
    objects: any[];
    orderBy: string;
}

const initialState: State = {
    objects: [],
    orderBy: 'id',
}

interface Action {
    type: 'objectPage' | 'orderBy';
    payload?: any;
}

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'objectPage':
            return {
                ...state,
                objects: action.payload.list,
            }
        case 'orderBy':
            return {
                ...state,
                orderBy: action.payload
            }
        default:
            return state;
    }
}

const schema = yup.object<PlannedCost>().shape({
    name: yup.string().required('Name is required'),
    actualCost: yup.number().required('Actual cost is required').min(0, 'Actual cost cannot be negative'),
    sponsorProposal: yup.number().required('Sponsor proposal is required').min(0, 'Sponsor proposal cannot be negative'),
    maxOccurrences: yup.number().integer().positive().min(1, 'Max occurrences cannot be less than 1'),
    invoiceType: yup.string().required('Invoice type is required'),
});

interface Props {
    contractId: number;
    studyId: number;
    divisionId: number;
    signed: boolean
}

const PlannedCosts: React.FC<Props> = props => {
    const { contractId, divisionId, studyId, signed } = props;
    const history = useHistory();
    const dispatcher = useDispatch();

    // Checks for the user/role/permissions
    const permissionCreate: boolean = useLoggedInUserPermissions('API_PLANNED_COST_CREATE', divisionId, studyId);
    const permissionUpdate: boolean = useLoggedInUserPermissions('API_PLANNED_COST_UPDATE', divisionId, studyId);
    const permissionDelete: boolean = useLoggedInUserPermissions('API_PLANNED_COST_DELETE_BY_ID', divisionId, studyId);

    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { objects, orderBy } = state;

    const [invoiceTypes, setInvoiceTypes] = React.useState<BOption<string>[]>([]);
    const [studyName, setStudyName] = React.useState<string>('');
    const [plannedCost] = React.useState<PlannedCost>({
        id: null,
        contractId: undefined,
        name: '',
        sponsorProposal: 0.0,
        actualCost: 0.0,
        maxOccurrences: 1,
        serviceProviderId: undefined,
        invoiceType: 'INVOICE',
        autoPayTrigger: '',
        comment: '',
    });
    const [updated, setUpdated] = React.useState<boolean>(false);

    const { expanded } = useSelector<Store, CollapsiblePanelState>(
        store => store.collapsiblePanelReducer
    );

    // service provider state management
    const [selectedServiceProvider, setSelectedServiceProvider] = React.useState<SelectOption<number> | null>(null);
    const [serviceProviderOptions, setServiceProviderOptions] = React.useState<SelectOption<number>[]>([]);
    const [disableServiceProvider, setDisableServiceProvider] = React.useState<boolean>(true);
    const [serviceProviderError, setServiceProviderError] = React.useState<boolean>(false);

    // State management for the selected object to perform actions on
    const [clickedObj, setClickedObj] = React.useState<null | PlannedCost>(null);

    // State management for the actions modals
    const [editOpen, setEditOpen] = React.useState<boolean>(false);
    const handleUpdate = (obj: PlannedCost): void => {
        updatePlannedCost(obj).then(res => {
            dispatcher(showSuccessSnackbar(`Planned Cost "${obj.name}"" updated`));
            setEditOpen(false);
            setClickedObj(null);
            setUpdated(!updated);
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                prefix: 'Could not update Planned Cost: '
            });
        });
    };
    const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);
    const handleDelete = (obj: PlannedCost): void => {
        deletePlannedCost(Number(obj.id))
            .then(() => {
                setDeleteOpen(false);
                setClickedObj(null);
                setUpdated(!updated);
                dispatcher(showSuccessSnackbar(`Deleted planned cost "${obj.name}"`));
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    prefix: 'Could not delete Planned Cost: '
                })
            });
    };

    const handleChangeSortBy = (header: string) => (_event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => {
        dispatch({ type: 'orderBy', payload: header });
    }

    const handleInvoiceTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const invoiceType = invoiceTypes[event.target.value as number].value;
        if (invoiceType != 'PASS_THROUGH')
            setSelectedServiceProvider(null);
        setDisableServiceProvider(invoiceType != 'PASS_THROUGH');
    }

    // Form management
    const handleSubmit = async (data: PlannedCost, { setErrors, resetForm }: FormikHelpers<PlannedCost>) => {
        // get the service provider id when field is enabled
        const serviceProviderId = disableServiceProvider ? undefined : selectedServiceProvider?.value

        // if the service provider id is undefined but the field is enabled, display error
        if (!serviceProviderId && !disableServiceProvider) {
            setServiceProviderError(true);
            return;
        }

        createPlannedCost({
            ...data,
            contractId: Number(contractId),
            serviceProviderId: serviceProviderId
        }).then(() => {
            resetForm();
            setSelectedServiceProvider(null);
            setUpdated(!updated);
            setDisableServiceProvider(true);
            setServiceProviderError(false);
            dispatcher(showSuccessSnackbar(`${data.name} added to planned costs`));
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                setStatus: setErrors,
                prefix: 'Unable to create Planned Cost: '
            });
        });
        setSelectedServiceProvider(null);
    };

    React.useEffect(() => {
        getContract(Number(contractId))
            .then(resContract => {
                getStudy(resContract.data.studyId)
                    .then(resStudy => {
                        setStudyName(resStudy.data.name);
                    }).catch(err => {
                        handleErrorResponse(err, dispatcher, {
                            prefix: 'Could not retrieve Study: '
                        });
                        history.push('/contract');
                    });
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    prefix: 'Could not retrieve Contract: '
                });
                history.push('/contract');
            });

        getInvoiceTypes()
            .then(res => {
                setInvoiceTypes(convertInvoiceTypeDataToBOptions(res.data));
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    prefix: 'Could not retrieve list of Invoice Types: '
                })
            });

        const pageReq: AccountPageRequest = {
            page: 0,
            sort: 'name'
        };
        getAccountPage(pageReq).then(res => {
            setServiceProviderOptions(convertAccountDataToSelectOptions(res.data.list))
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                prefix: 'Could not retrieve list of Accounts: '
            });
        });
    }, []);

    React.useEffect(() => {
        const pageReq: PlannedCostPageRequest = {
            sort: orderBy,
            contractId: Number(contractId)
        };

        getPlannedCostPage(pageReq)
            .then((res: { data: any; }) => {
                dispatch({ type: 'objectPage', payload: res.data });
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    prefix: 'Could not retrieve list of Planned Costs: '
                })
            });
    }, [orderBy, updated]);

    const TableHeaderSection = () => (
        <TableHead className="table-header">
            <TableRow>
                {headers.map(header => {
                    return (
                        <TableCell
                            key={'header-' + header.type}
                            onClick={handleChangeSortBy(header.type)}
                            className={'cursor-pointer ' +
                                (orderBy === header.type ? 'primary-cell selected' : 'primary-cell')
                            }
                        >
                            <div className={`header-cell-label ${header.align}`}>
                                {header.displayValue}
                                {orderBy === header.type && <ArrowDropDown />}
                            </div>
                        </TableCell>
                    )
                })}
                {!signed &&
                    <TableCell key='header-actions' className='primary-cell'>
                        <div className="header-cell-label center">
                            Actions
                        </div>
                    </TableCell>
                }
            </TableRow>
        </TableHead>
    )

    return (
        <>
            <div className="page-heading">
                <Typography className="title">{DisplayTextFormat(studyName, 64)}</Typography>
            </div>
            <div className="page-body">
                <div className={'constrainable' + (expanded ? ' expanded' : '')}>
                    <div className="page-title">
                        Planned Costs
                    </div>
                    <Formik
                        initialValues={plannedCost}
                        values={plannedCost}
                        onSubmit={handleSubmit}
                        validateOnBlur={false}
                        validateOnChange={false}
                        validationSchema={schema}
                        enableReinitialize
                    >
                        <BForm>
                            <TableContainer component={Paper} className='input-table'>
                                <Table>
                                    <TableHeaderSection />
                                    <TableBody>
                                        {objects.map((obj: any, idx: number) => {
                                            return (
                                                <TableRow key={'row-' + idx}>
                                                    {headers.map(header => {
                                                        if (header.type == 'sponsorProposal' || header.type == 'actualCost') {
                                                            return (
                                                                <TableCell key={header.type + '-' + idx} className={`body-cell ${header.align}`}>
                                                                    <NumberFormatter currency value={obj[header.type]} />
                                                                </TableCell >
                                                            )
                                                        } else if (header.type == 'invoiceType') {
                                                            return (
                                                                <TableCell key={header.type + '-' + idx} className={`body-cell ${header.align}`}>
                                                                    {invoiceTypes.find(type => type.value == obj[header.type])?.label}
                                                                </TableCell>
                                                            )
                                                        } else if (header.type == 'comment') {
                                                            return (
                                                                <TableCell key={header.type + '-' + idx} className={`body-cell ${header.align}`}>
                                                                    {DisplayTextFormat(obj[header.type], 48)}
                                                                </TableCell>
                                                            )
                                                        } else if (header.type == 'serviceProvider') {
                                                            return (
                                                                <TableCell key={header.type + '-' + idx} className={`body-cell ${header.align}`}>
                                                                    {DisplayTextFormat(serviceProviderOptions.find(o => o.value == obj.serviceProviderId)?.label)}
                                                                </TableCell>
                                                            )
                                                        }
                                                        return (
                                                            <TableCell key={header.type + '-' + idx} className={`body-cell ${header.align}`}>
                                                                {DisplayTextFormat(obj[header.type])}
                                                            </TableCell>
                                                        )
                                                    })}
                                                    {!signed &&
                                                        <TableCell key='header-actions' className="body-cell center">
                                                            <div className="aligned-row" style={{ justifyContent: 'center' }}>
                                                                {permissionUpdate &&
                                                                    <Tooltip
                                                                        title='Edit'
                                                                        arrow
                                                                    >
                                                                        <IconButton
                                                                            onClick={() => { setEditOpen(true); setClickedObj(obj); }}
                                                                            style={{ padding: '6px' }}
                                                                        >
                                                                            {<Edit />}
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                }
                                                                {permissionDelete &&
                                                                    <Tooltip
                                                                        title='Delete'
                                                                        arrow
                                                                    >
                                                                        <IconButton
                                                                            onClick={() => { setDeleteOpen(true); setClickedObj(obj); }}
                                                                            style={{ padding: '6px' }}
                                                                        >
                                                                            {<Delete />}
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                }
                                                            </div>
                                                        </TableCell>
                                                    }
                                                </TableRow >
                                            )
                                        })}
                                        {objects.length === 0 &&
                                            <TableRow>
                                                <TableCell colSpan={9} className='body-cell center' style={{ height: 80, fontSize: 16 }}>
                                                    No data to display.
                                                </TableCell>
                                            </TableRow>
                                        }
                                        {permissionCreate && !signed &&
                                            <TableRow>
                                                <TableCell className='body-cell left' style={{ minWidth: '150px' }}>
                                                    <BTextField
                                                        name='name'
                                                        label=''
                                                        placeholder='Name...'
                                                        variant='standard'
                                                        required autoFocus
                                                        noMP
                                                    />
                                                </TableCell>
                                                <TableCell className='body-cell left'>
                                                    <BTextField
                                                        name='sponsorProposal'
                                                        label=''
                                                        placeholder='0.00'
                                                        variant='standard'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                            inputComponent: NumberFormatter as any
                                                        }}
                                                        noMP
                                                    />
                                                </TableCell>
                                                <TableCell className='body-cell left'>
                                                    <BTextField
                                                        name='actualCost'
                                                        label=''
                                                        placeholder='0.00'
                                                        variant='standard'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                            inputComponent: NumberFormatter as any
                                                        }}
                                                        noMP
                                                    />
                                                </TableCell>
                                                <TableCell className='body-cell left'>
                                                    <BTextField
                                                        name='maxOccurrences'
                                                        label=''
                                                        placeholder='1'
                                                        variant='standard'
                                                        InputProps={{
                                                            inputComponent: NumberFormatter as any,
                                                            inputProps: { integer: true }
                                                        }}
                                                        noMP
                                                    />
                                                </TableCell>
                                                <TableCell className='body-cell left' style={{ minWidth: '130px' }}>
                                                    <BSelect
                                                        name='invoiceType'
                                                        label=''
                                                        placeholder='Invoice Type'
                                                        variant='standard'
                                                        options={invoiceTypes}
                                                        required
                                                        onChange={handleInvoiceTypeChange}
                                                        noMP
                                                        className='bselect-no-margin'
                                                    />
                                                </TableCell>
                                                <Tooltip title={disableServiceProvider ? 'Service provider is only needed if the invoice type is Pass Through' : ''} arrow>
                                                    <TableCell className='body-cell left' style={{ minWidth: '220px' }}>
                                                        <CheckedAutocomplete
                                                            idText='serviceProvider'
                                                            placeholder='Service Provider'
                                                            options={serviceProviderOptions}
                                                            acValue={selectedServiceProvider}
                                                            multiple={false}
                                                            closeOnSelect
                                                            onChange={setSelectedServiceProvider}
                                                            noCheckmark
                                                            disabled={disableServiceProvider}
                                                            error={serviceProviderError}
                                                        />
                                                    </TableCell>
                                                </Tooltip>
                                                <TableCell className='body-cell left' style={{ minWidth: '150px' }}>
                                                    <BTextField
                                                        name='autoPayTrigger'
                                                        label=''
                                                        placeholder='Autopay Trigger...'
                                                        variant='standard'
                                                        noMP
                                                    />
                                                </TableCell>
                                                <TableCell className='body-cell left' style={{ minWidth: '150px' }}>
                                                    <BTextField
                                                        name='comment'
                                                        label=''
                                                        placeholder='Comments...'
                                                        variant='standard'
                                                        noMP
                                                    />
                                                </TableCell>
                                                <TableCell className='body-cell center'>
                                                    <BSubmit id='add-employee-submit' variant='text'>
                                                        Add
                                                    </BSubmit>
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </TableBody >
                                </Table >
                            </TableContainer >
                        </BForm >
                    </Formik >
                </div>
                {clickedObj && editOpen &&
                    <EditPlannedCost
                        plannedCost={clickedObj}
                        open={editOpen}
                        setOpen={setEditOpen}
                        setDelete={setDeleteOpen}
                        permissionDelete={permissionDelete}
                        handleUpdate={handleUpdate}
                        serviceProviderOptions={serviceProviderOptions}
                        invoiceTypes={invoiceTypes}
                    />
                }
                {clickedObj && deleteOpen &&
                    <SimpleConfirmDelete
                        open={deleteOpen}
                        setOpen={setDeleteOpen}
                        type='Planned Cost'
                        objectName={clickedObj.name}
                        handleDelete={() => handleDelete(clickedObj)}
                    />
                }
            </div >
        </>
    )
}

export default PlannedCosts;
import * as React from 'react';

import { TableCell, TableHead, TableRow } from '@material-ui/core';
import { shortMonthString } from '../../../../common/Utils/utils';


interface Props {
    editYear: number;
    currentYear: number;
    currentMonth: number;
}

const MonthlyTableHeader: React.FC<Props> = props => {
    const { editYear, currentYear, currentMonth } = props;

    return (
        <TableHead>
            <TableRow>
                <TableCell className="background-color-paper sticky" style={{ zIndex: 4 }} />
                <TableCell className="text-align-right">Total<br />(Lifetime)</TableCell>
                {Array.from(Array(12).keys()).map(month => {
                    return (
                        <TableCell
                            key={month + '-header'}
                            className="text-align-right data-cell"
                            data-disabled={editYear === currentYear && month > currentMonth}
                        >
                            {shortMonthString(month, editYear)}<br />{editYear}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead >
    );
}

export default MonthlyTableHeader;

import * as React from 'react';
import { Tooltip } from '@material-ui/core';


const CHAR_LIMIT: number = 24;

/**
 * Shortens long strings and displays the full text as a Tooltip. When shortened the last three chars of the shortened string will be replaced with '...'
 * @param value the string to shorten and display
 * @param charLimit (default 24) the maximum allowed length of the string
 * @param showTooltip (default true) if false, the tooltip containing the full text of value will not be displayed
 */
export const DisplayTextFormat = (value: string | undefined | null, charLimit: number = CHAR_LIMIT, showTooltip: boolean = true) => {
    value = value ?? '';
    if (value.length > charLimit) {
        if (showTooltip) {
            return <Tooltip arrow title={value}><span>{`${value.substring(0, charLimit-3)}...`}</span></Tooltip>;
        } else {
            return `${value.substring(0, charLimit-3)}...`;
        }
    } else {
        return value;
    }
}


export const DisplayFullName = (first: string, middle: string | undefined | null, last: string) => {
    if (middle) {
        return first + ' ' + middle + ' ' + last;
    }
    return first + ' ' + last;
}
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import _ from 'lodash';

import { Button, Tooltip } from '@material-ui/core';
import { Add, Delete, Edit } from '@material-ui/icons';
import { BOption } from 'mui-bueno';
import { FormikHelpers } from 'formik';

import { AccountPageRequest, IncurredCost, IncurredCostWithUsers, SelectOption, StudyUser } from '../../../../../@types';
import { Store } from '../../../../../modules/rootReducer';
import { CollapsiblePanelState } from '../../../../../modules/collapsiblePanelReducer';
import { handleErrorResponse } from '../../../../../service/utils';
import { getStudyUsers } from '../../../../../service/Study/study';
import { getAccountPage } from '../../../../../service/Account/account';
import { getInvoiceTypes } from '../../../../../service/InvoiceType/invoiceType';
import { getReferenceStudyRoles } from '../../../../../service/Reference/reference';
import { createIncurredCost, deleteIncurredCost, getIncurredCostsFromStudy } from '../../../../../service/IncurredCost/incurredCost';
import SimpleConfirmDelete from '../../../../../common/ConfirmDelete/SimpleConfirmDelete';
import useLoggedInUserPermissions from '../../../../../common/hooks/useLoggedInUserPermissions';
import EditablePagingTable, { ActionOption, TableColumn } from '../../../../../common/DataTable/EditablePagingTable';
import { BIG_DECIMAL_MAX, convertAccountDataToSelectOptions, convertInvoiceTypeDataToBOptions, convertReferenceDataToSelectionOptions } from '../../../../../common/Utils/utils';
import { showSuccessSnackbar } from '../../../../../modules/messageSnackbarReducer';

import EditIncurredCost from './EditIncurredCost';
import CreateFromPlanned from './CreateFromPlanned';
import usePermissions from '../../../../../common/hooks/usePermissions';
import Loading from '../../../../../common/Routes/Loading';

interface State {
    incurredCostList: any[];
}

const initialState: State = {
    incurredCostList: [],
}

interface Action {
    type: 'load' | 'added' | 'edited' | 'deleted';
    payload?: any;
}

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'load':
            return {
                ...state,
                incurredCostList: action.payload,
            }
        case 'added':
            return {
                ...state,
                incurredCostList: [...state.incurredCostList, action.payload]
            }
        case 'edited':
            return {
                ...state,
                incurredCostList: state.incurredCostList.map(el => el.id === action.payload.id ? action.payload : el)
            }
        case 'deleted':
            return {
                ...state,
                incurredCostList: state.incurredCostList.filter(item => item.id !== action.payload)
            }
        default:
            return state;
    }
}

const incurredCostSchema = yup.object<IncurredCost>().shape({
    description: yup.string().required('Description is required'),
    dateOfOccurrence: yup.date().required('Date of occurrence is required'),
    cost: yup.number().required('Cost is required').min(0, 'Min value: 0.00').max(BIG_DECIMAL_MAX, 'Max value: ' + BIG_DECIMAL_MAX)
});

interface Props {
    id: string;
    divisionId: number;
    currentContractId: number;
}

const IncurredCosts: React.FC<Props> = (props) => {
    const { id, divisionId, currentContractId } = props;

    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { incurredCostList } = state;
    const dispatcher = useDispatch();

    const emptyIncurredCost: IncurredCost = {
        id: null,
        cost: 0,
        dateOfOccurrence: null,
        description: '',
        studyId: Number(id),
        userIds: [],
        invoiceType: 'INVOICE',
        serviceProviderId: undefined
    }

    const [incurredCost, setIncurredCost] = React.useState<IncurredCost>(emptyIncurredCost);
    const [doneLoading, setDoneLoading] = React.useState<boolean>(false);
    const [updated, setUpdated] = React.useState(false);

    // Keep track of if the study has been started and completed
    const [started, setStarted] = React.useState<boolean>(false);
    const [completed, setCompleted] = React.useState<boolean>(false);

    // State management for users in the study
    const [studyUsers, setStudyUsers] = React.useState<StudyUser[]>([]);
    const [studyUserOptions, setStudyUserOptions] = React.useState<SelectOption<number>[]>([]);

    // State management to capture users selected from autocomplete
    const [selectedUsers, setSelectedUsers] = React.useState<SelectOption<number>[]>([]);

    // State management for study roles
    const [allReferenceRoles, setAllReferenceRoles] = React.useState<SelectOption<number>[]>([]);

    // State management for invoice types
    const [invoiceTypes, setInvoiceTypes] = React.useState<BOption<string>[]>([]);

    // State management to capture serviceProvider selected from autocomplete
    const [selectedServiceProvider, setSelectedServiceProvider] = React.useState<SelectOption<number> | null>(null);
    const [serviceProviderOptions, setServiceProviderOptions] = React.useState<SelectOption<number>[]>([]);

    // State management to determine if service provider is needed and to display an error if none was chosen when required
    const [disableServiceProvider, setDisableServiceProvider] = React.useState<boolean>(true);
    const [serviceProviderError, setServiceProviderError] = React.useState<boolean>(false);

    // State management for the actions
    const [openEdit, setOpenEdit] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState<boolean>(false);
    const [clickedObj, setClickedObj] = React.useState<null | any>(null);

    // State management for the select from planned cost modal
    const [fromPlannedOpen, setFromPlannedOpen] = React.useState(false);
    const handleFromPlannedModalClose = () => {
        setFromPlannedOpen(false);
    };
    const { expanded } = useSelector<Store, CollapsiblePanelState>(
        store => store.collapsiblePanelReducer
    );

    // State management to determine whether to display add, edit, and delete capability based on user's permissions and role restriction
    const canRetrieve = useLoggedInUserPermissions('API_INCURRED_COST_GET_INCURRED_COSTS_BY_STUDY', divisionId, Number(id));
    const canCreate = useLoggedInUserPermissions('API_INCURRED_COST_CREATE', divisionId, Number(id));
    const canEdit = useLoggedInUserPermissions('API_INCURRED_COST_UPDATE', divisionId, Number(id));
    const canDelete = useLoggedInUserPermissions('API_INCURRED_COST_DELETE_BY_ID', divisionId, Number(id));

    /*
        Permission that will be used to determine if the logged in user can click on the employee chip
        and go to that user's statistics page. usePermissions('API_USER_CREATE') was used for now because it is a 
        permission that only division admins and system admins have, so this is a way to 'check' if
        the logged-in user is one of those roles.
    */
    const canGoToUserStats = usePermissions('API_USER_CREATE');

    const convertData = (data: any[]) => {
        const tempData: any[] = [];
        data.forEach((obj: IncurredCostWithUsers) => {
            tempData.push({
                incurredCostId: obj.incurredCost.id,
                description: obj.incurredCost.description,
                dateOfOccurrence: obj.incurredCost.dateOfOccurrence,
                cost: obj.incurredCost.cost,
                users: obj.incurredCostUsers,
                invoiceType: obj.incurredCost.invoiceType,
                serviceProviderId: obj.incurredCost.serviceProviderId,
                studyId: Number(id)
            })
        })
        return tempData;
    };

    // Initial load
    React.useEffect(() => {
        getStudyUsers(Number(id)).then(res => {
            setStudyUsers(res.data.studyUsers);

            res.data.study.started ? setStarted(true) : setStarted(false);
            res.data.study.completed ? setCompleted(true) : setCompleted(false);

            getReferenceStudyRoles().then(rolesRes => {
                const referenceRoles = convertReferenceDataToSelectionOptions(rolesRes.data)
                setAllReferenceRoles(referenceRoles);

                // Convert all users in the study into select options
                const temp: SelectOption<number>[] = [];
                res.data.studyUsers.forEach((type: StudyUser) => {
                    temp.push({
                        value: type.userId,
                        label: type.userName! + ` (${type.roleIds ? handleGetRoleNameFromList(referenceRoles, type.roleIds) : ''})`
                    })
                })
                setStudyUserOptions(temp);
            });
        })

        getInvoiceTypes().then(res => {
            setInvoiceTypes(convertInvoiceTypeDataToBOptions(res.data));
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                prefix: 'Could not get Invoice Types: '
            })
        });

        const pageReq: AccountPageRequest = {
            page: 0,
            sort: 'name'
        };

        getAccountPage(pageReq).then(res => {
            setServiceProviderOptions(convertAccountDataToSelectOptions(res.data.list))
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                prefix: 'Could not retrieve list of Service Providers: '
            })
        });

        getIncurredCostsFromStudy(Number(id)).then(res => {
            dispatch({ type: 'load', payload: convertData(res.data) });
            setDoneLoading(true);
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                prefix: 'Could not retrieve the Incurred Costs on the study: '
            })
        });

        if (incurredCost.serviceProviderId) {
            setSelectedServiceProvider(serviceProviderOptions.find(o => o.value == incurredCost.serviceProviderId)!)
        }

        setUpdated(false);
    }, [id, updated]);

    const handleInvoiceTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const invoiceType = invoiceTypes[event.target.value as number].value;
        setDisableServiceProvider(invoiceType != 'PASS_THROUGH');
    };

    // Delete incurred cost action
    const handleClickDelete = () => {
        setOpenDelete(true);
    };

    const handleDelete = (obj: any) => {
        deleteIncurredCost(Number(obj.incurredCostId)).then(res => {
            dispatch({ type: 'deleted', payload: obj.incurredCostId });
            setUpdated(true);
            dispatcher(showSuccessSnackbar(`Deleted incurred cost "${obj.description}"`));
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                prefix: 'Could not delete Incurred Cost: '
            });
        });

        setOpenDelete(false);
    };

    // Edit incurred cost action
    const handleClickEdit = () => {
        setOpenEdit(true);
    };

    const handleEdit = (editedIncurredCost: any) => {
        dispatch({ type: 'edited', payload: editedIncurredCost });
    };

    // Form management
    const handleSubmitIncurredCost = async (data: IncurredCost, { setErrors, resetForm }: FormikHelpers<IncurredCost>) => {
        const userIds: number[] = [];
        selectedUsers.forEach((selectedUser: { value: number; }) => {
            userIds.push(selectedUser.value);
        });

        if (!disableServiceProvider && (!selectedServiceProvider || selectedServiceProvider.value == 0)) {
            setServiceProviderError(true);
            return;
        }

        const serviceProviderId = disableServiceProvider ? undefined : selectedServiceProvider?.value;
        const newIncurredCost = {
            ...data,
            serviceProviderId: serviceProviderId,
            studyId: Number(id),
            userIds: userIds
        }

        createIncurredCost(newIncurredCost).then(res => {
            setIncurredCost(emptyIncurredCost);
            resetForm();
            setSelectedUsers([]);
            setSelectedServiceProvider(null);
            setServiceProviderError(false);
            setDisableServiceProvider(true);
            dispatch({ type: 'added', payload: res.data });
            setUpdated(true);
            dispatcher(showSuccessSnackbar(`Created incurred cost "${data.description}"`));
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                prefix: 'Could not create Incurred Cost: '
            });
        });
    };


    const tooltipText = (create: boolean) => {
        if (!started) {
            return 'Cannot perform this action before the study has been started.';
        }
        if (completed) {
            return 'Cannot perform this action after the study has been completed.';
        }
        if (create) {
            return 'Use this instead of the table inputs to create an incurred cost based off of a planned cost.';
        }
        return '';
    }

    const actions: ActionOption[] = completed ? [] : [
        { label: 'Edit', icon: <Edit />, handler: handleClickEdit, permission: canEdit, disabled: !started || completed, tooltipTitle: tooltipText(false) },
        { label: 'Delete', icon: <Delete />, handler: handleClickDelete, permission: canDelete, disabled: !started || completed, tooltipTitle: tooltipText(false) }
    ];

    const columns: TableColumn[] = [{
        type: 'description',
        displayValue: 'Description',
        align: 'left',
        placeholder: 'Description...',
        required: true,
    }, {
        type: 'dateOfOccurrence',
        displayValue: 'Date of Occurrence',
        align: 'left',
        placeholder: 'Date of Occurrence',
        required: true,
        style: { width: 170 },
        format: 'date'
    }, {
        type: 'employees',
        format: 'employees',
        displayValue: 'Employees',
        align: 'left',
        placeholder: '',
        required: false,
        style: { width: 200 },
        onChange: setSelectedUsers,
        multiple: true,
        closeOnSelect: false,
        noCheckmark: false,
        selectOptions: studyUserOptions,
        disabled: !started || completed,
        acValue: selectedUsers,
        referenceRoles: allReferenceRoles,
        studyUsers: studyUsers,
        canGoToUserStats: canGoToUserStats
    }, {
        type: 'cost',
        displayValue: 'Cost',
        align: 'right',
        required: false,
        style: { width: 170 },
        format: 'currency-US'
    }, {
        type: 'invoiceType',
        displayValue: 'Invoice Type',
        align: 'left',
        placeholder: 'Invoice Type',
        required: false,
        style: { width: 150 },
        bOptions: invoiceTypes,
        onChange: handleInvoiceTypeChange,
        format: 'enum'
    }, {
        type: 'serviceProvider',
        displayValue: 'Service Provider',
        align: 'left',
        placeholder: '',
        required: false,
        style: { width: 160 },
        onChange: setSelectedServiceProvider,
        multiple: false,
        closeOnSelect: true,
        noCheckmark: true,
        selectOptions: serviceProviderOptions,
        disabled: disableServiceProvider,
        acValue: selectedServiceProvider,
        error: serviceProviderError,
        tooltipTitle: disableServiceProvider ?
            'Service provider is only needed if the invoice type is Pass Through'
            : undefined
    }];

    // Get the user's study full role name
    const handleGetRoleNameFromList = (referenceRoles: SelectOption<number>[], roleIds: number[]) => {
        return _.find(referenceRoles, ['value', roleIds[0]])!.label

    };

    if (doneLoading) {
        return (
            <>
                {canRetrieve ?
                    <div className={'constrainable' + (expanded ? ' expanded' : 'collapsed')} style={{ padding: '15px 0px 25px' }}>
                        <div className="left">
                            {canCreate &&
                                <Tooltip arrow title={tooltipText(true)}>
                                    <Button
                                        id="open-create-from-planned"
                                        variant="contained"
                                        color="primary"
                                        startIcon={<Add />}
                                        onClick={() => setFromPlannedOpen(true)}
                                        disabled={!started || completed}
                                        style={{ pointerEvents: 'auto' }}
                                    >
                                        Select from Planned Costs
                                    </Button>
                                </Tooltip>
                            }
                        </div>
                        <EditablePagingTable
                            dataList={incurredCostList}
                            tableInfoColumns={columns}
                            initialSortType={'dateOfOccurrence'}
                            editingDataObject={incurredCost}
                            handleSubmit={handleSubmitIncurredCost}
                            validationSchema={incurredCostSchema}
                            readonly={!canRetrieve}
                            canCreate={canCreate && started && !completed}
                            clickedObj={clickedObj}
                            setClickedObj={setClickedObj}
                            actionOptions={actions}
                        />
                        <CreateFromPlanned
                            handleFromPlannedModalClose={handleFromPlannedModalClose}
                            contractId={currentContractId}
                            employees={studyUserOptions}
                            serviceProviders={serviceProviderOptions}
                            setUpdatePage={setUpdated}
                            open={fromPlannedOpen}
                        />
                        {clickedObj && openEdit &&
                            <EditIncurredCost
                                handleEditCostModalClose={() => setOpenEdit(false)}
                                incurredCostToEdit={clickedObj}
                                users={clickedObj.users}
                                employees={studyUserOptions}
                                invoiceTypes={invoiceTypes}
                                serviceProviders={serviceProviderOptions}
                                setUpdatePage={setUpdated}
                                open={openEdit}
                                handleEdit={handleEdit}
                            />
                        }

                        {clickedObj && openDelete &&
                            <SimpleConfirmDelete
                                open={openDelete}
                                setOpen={setOpenDelete}
                                type='Incurred Cost'
                                objectName={clickedObj.description}
                                handleDelete={() => handleDelete(clickedObj)}
                            />
                        }
                    </div>
                    :
                    <></>
                }
            </>
        )
    } else {
        return (
            <Loading />
        )
    }
}

export default IncurredCosts;
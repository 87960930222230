import { Chip } from "@material-ui/core";
import React from "react";
import {
    ResponsiveContainer,
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip
} from "recharts";
import { handleResourceReqTooltip } from "../../../Utils";
import ChartYearControls from "../ChartYearControls";

interface Props {
    data: any[];
    editYear: number;
    setEditYear: any;
    coordinatorAllocatedColor: string;
    providerAllocatedColor: string;
    coordinatorRequiredColor: string;
    providerRequiredColor: string;
}

const ResourceReqChart: React.FC<Props> = (props) => {
    const { data, editYear, setEditYear, coordinatorAllocatedColor, providerAllocatedColor, coordinatorRequiredColor, providerRequiredColor } = props;

    const monthExtractor = (date: string) => {
        // Date is in the format YYYY-MM-DD
        const splitDate: number[] = date.toString().split('-').map(a => +a);
        const newDate = new Date(splitDate[0], (splitDate[1] - 1), splitDate[2]);
        const locale: string = 'en-US';
        return newDate.toLocaleDateString(locale, { month: 'short' });
    }

    const CustomXAxisTick = (axisProps: any) => {
        const { x, y, payload } = axisProps;
        return (
            <g transform={`translate(${x}, ${y})`}>
                <text x={0} y={0} dy={16} fill="#666">
                    <tspan textAnchor="middle" x="0">{monthExtractor(payload.value)}</tspan>
                    <tspan textAnchor="middle" x="0" dy="20">{editYear}</tspan>
                </text>
            </g>
        );
    };

    return (
        <>
            <div className='resource-legend'>
                <Chip
                    avatar={
                        <svg>
                            <g transform={`translate(${9} ${9})`}>
                                <circle r={6} fill={coordinatorAllocatedColor} />
                            </g>
                        </svg>
                    }
                    label="Coordinator Allocated"
                    variant="outlined"
                    size="small"
                    className="legend-chip"
                />
                <Chip
                    avatar={
                        <svg>
                            <g transform={`translate(${9} ${9})`}>
                                <circle r={6} fill={providerAllocatedColor} />
                            </g>
                        </svg>
                    }
                    label="Provider Allocated"
                    variant="outlined"
                    size="small"
                    className="legend-chip"
                />
                <Chip
                    avatar={
                        <svg>
                            <g transform={`translate(${9} ${9})`}>
                                <circle r={6} fill={coordinatorRequiredColor} />
                            </g>
                        </svg>
                    }
                    label="Coordinator Required"
                    variant="outlined"
                    size="small"
                    className="legend-chip"
                />
                <Chip
                    avatar={
                        <svg>
                            <g transform={`translate(${9} ${9})`}>
                                <circle r={6} fill={providerRequiredColor} />
                            </g>
                        </svg>
                    }
                    label="Provider Required"
                    variant="outlined"
                    size="small"
                    className="legend-chip"
                />
            </div>
            <ResponsiveContainer width='100%' height={400} className='widget-bar-chart'>
                <ComposedChart
                    width={1000}
                    height={400}
                    data={data}
                    margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis
                        dataKey="associatedDate"
                        scale='band'
                        interval={0}
                        tick={CustomXAxisTick}
                        padding={{ left: 20, right: 20 }}
                    />
                    <YAxis label={{ value: 'FTE', angle: -90, dx: -30, position: 'inside' }} />
                    <Tooltip
                        isAnimationActive={false}
                        content={(resourceReqTooltipProp) => (
                            handleResourceReqTooltip(resourceReqTooltipProp, editYear)
                        )}
                    />
                    <Bar dataKey="coordinatorAllocated" name="Coordinator Actual" barSize={20} fill={coordinatorAllocatedColor} />
                    <Bar dataKey="providerAllocated" name="Provider Actual" barSize={20} fill={providerAllocatedColor} />
                    <Line
                        type="linear"
                        dataKey="coordinatorRequired"
                        name="Coordinator Required"
                        stroke={coordinatorRequiredColor}
                        strokeWidth={3}
                        dot={false}
                    />
                    <Line
                        type="linear"
                        dataKey="providerRequired"
                        name="Provider Required"
                        stroke={providerRequiredColor}
                        strokeWidth={3}
                        dot={false}
                    />
                </ComposedChart>
            </ResponsiveContainer>
            <ChartYearControls editYear={editYear} setEditYear={setEditYear} />
        </>
    )
}

export default ResourceReqChart;
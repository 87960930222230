import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IconButton, makeStyles, Snackbar, SnackbarContent } from '@material-ui/core';
import { green, grey, red, yellow } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';

import { Store } from '../../modules/rootReducer';
import { clearSnackbar, MessageSnackbarState } from '../../modules/messageSnackbarReducer';

const useStyles = makeStyles({
    success: {
        backgroundColor: green[600]
    },
    error: {
        backgroundColor: red[600]
    },
    warning: {
        backgroundColor: yellow[600],
        color: '#333e62'
    },
    general: {
        backgroundColor: grey[800]
    }
});

const MessageSnackbar: React.FC = () => {
    const dispatch = useDispatch();
    const classes = useStyles();


    const [open, setOpen] = React.useState(false);
    const { message, type } = useSelector<Store, MessageSnackbarState>(
        store => store.messageSnackbarReducer
    );

    const close = () => {
        setOpen(false);
        dispatch(clearSnackbar());
    };

    React.useEffect(() => {
        if (message) {
            setOpen(true);
        }
    }, [message]);

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            open={open}
            key="success-snackbar"
            autoHideDuration={10000}
            onClose={close}
        >
            <SnackbarContent
                className={classes[type]}
                message={message}
                action={
                    <IconButton onClick={close}>
                        <CloseIcon />
                    </IconButton>
                }
            />
        </Snackbar>
    );
};

export default MessageSnackbar;

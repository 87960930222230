import * as React from 'react';

import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import {
    Typography,
} from '@material-ui/core';

import { DecodedToken, LoginRequest, PermissionConst, User, UserRolePermission } from '../../../@types';
import { login } from '../../../service/Access/authentication';
import { forcePasswordReset, setupMfa } from '../../../modules/authenticationReducer';
import { loginSuccess, permissionsFetched } from '../../../modules/loginReducer';
import { loginJwt } from '../../../modules/loginJwtReducer';
import axiosInstance from '../../../service/Access/axiosInstance';
import GridWrapper from '../../../common/GridWrapper/GridWrapper';
import { showErrorSnackbar } from '../../../modules/messageSnackbarReducer';
import { handleEditErrors } from '../../../service/utils';
import { getCurrentUserPermissions } from '../../../service/Management/users';
import { AxiosError } from 'axios';

import { Formik, FormikHelpers } from 'formik';
import { BError, BForm, BPassword, BSubmit, BTextField } from 'mui-bueno';

const schema = yup.object<LoginRequest>().shape({
    email: yup.string().email('Must be in email format').required('Email is required'),
    password: yup.string().required('Password is required')
});

interface stateType {
    from: { pathname: string }
}

const Login: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { state } = useLocation<stateType>();

    const initialValues: LoginRequest = {
        email: '',
        password: '',
        mfaToken: '',
    }
    const [showToken, setShowToken] = React.useState<boolean>(false);

    const handleSubmit = async (data: LoginRequest, { setErrors }: FormikHelpers<LoginRequest>) => {
        setErrors({});
        if (showToken && data.mfaToken.length === 0) {
            setErrors({ mfaToken: 'MFA token is required.' });
            return;
        }
        schema.validate(data, { abortEarly: false })
            .then(() => {
                login(data).then(res => {
                    if (res.status === 202) {
                        setShowToken(true);
                        return;
                    }
                    const accessToken = res.data.accessToken;

                    axiosInstance.defaults.headers.common.Authorization =
                        'Bearer ' + accessToken;

                    localStorage.setItem('accessToken', accessToken);

                    const decodedToken: DecodedToken = jwt_decode(accessToken);

                    const user: User = {
                        id: decodedToken.id,
                        firstName: decodedToken.firstName,
                        middleName: decodedToken.middleName,
                        lastName: decodedToken.lastName,
                        email: decodedToken.sub,
                        divisionIds: [],
                        studyIds: [],
                        roleIds: [],
                    }

                    dispatch(loginJwt({ accessToken }));
                    getUserRolePermission(user);
                    if (state && state.from)
                        history.push(state.from.pathname);
                    else
                        history.push('/');
                }).catch((err: AxiosError) => {
                    if (err.response?.status === 303) {
                        const userEmail = err.response.data.userEmail;
                        if (err.response.data.message === 'PWD_CHG') {
                            dispatch(forcePasswordReset({ userEmail: userEmail, message: err.response.data.message, session: err.response.data.session }));
                            history.push('/login/reset');
                            return;
                        } else if (err.response.data.message === 'SET_MFA') {
                            dispatch(setupMfa({ userEmail: userEmail, message: err.response.data.message, session: err.response.data.session, secretCode: err.response.data.secretCode, accessToken: err.response.data.accessToken }));
                            history.push('/login/setup-mfa');
                            return;
                        }
                    } else if (err.response?.status === 422) {
                        handleEditErrors(err, setErrors);
                        return;
                    } else if (err.response?.status === 400) {
                        const index = err.response.data.message.indexOf('(');
                        const message = err.response.data.message.substring(0, index - 1);
                        if (message === 'Incorrect username or password.') {
                            setErrors({ general: 'Incorrect username or password.' });
                            return;
                        } else if (message === 'User does not exist.') {
                            dispatch(showErrorSnackbar(message));
                            return;
                        } else if (message === 'Invalid code received for user') {
                            setErrors({ mfaToken: 'Incorrect code.' });
                            return;
                        } else if (message === 'User is disabled.') {
                            setErrors({ email: 'The user associated with this email has been deactivated. Please contact your system/institution administrator for more information.' });
                            return;
                        } else if (err.response.data.message === 'Too many requests. Try again later.') {
                            dispatch(showErrorSnackbar('Too many requests. Try again later.'));
                            return;
                        } else if (message === 'Your software token has already been used once.') {
                            setErrors({ mfaToken: 'Your 2FA token has already been used once. Please wait and submit the next token.' });
                            return;
                        } else if (message === 'Password reset required for the user') {
                            dispatch(showErrorSnackbar('You must reset your password. Click the forgot password link below the login button.'));
                            return;
                        } else if (message === 'Temporary password has expired and must be reset by an administrator.') {
                            dispatch(showErrorSnackbar('Your temporary password has expired. Please contact your division or system administrator to request a new temporary password. Temporary passwords expire after 7 days of inactivity.'));
                            return;
                        } else {
                            dispatch(showErrorSnackbar('Unexpected error. ' + message));
                            return;
                        }
                    }
                })
            }).catch((err: yup.ValidationError) => {
                const errors: { [key: string]: string } = {};
                for (const e of err.inner) {
                    errors[e.path!] = e.message
                }
                setErrors(errors)
            });
    }

    const getUserRolePermission = (user: User) => {
        getCurrentUserPermissions().then(res => {
            const permissions : PermissionConst[] =[];
            res.data.forEach((userRolePerm:UserRolePermission) => {
                permissions.push(userRolePerm.permissionId as PermissionConst) ;
            });
            
            dispatch(loginSuccess({ user, permissions }));
            dispatch(permissionsFetched(res.data));
        });
    }
    return (
        <GridWrapper>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
            >
                <BForm>
                    <Typography className="card-heading">Login</Typography>
                    <BTextField
                        name="email"
                        label="Email"
                        noMP
                    />
                    <BPassword
                        name="password"
                        label="Password"
                        noMP
                    />
                    {showToken &&
                        <BTextField
                            name="mfaToken"
                            label="MFA Token"
                            noMP
                            format="999 999"
                        />
                    }
                    <BError name={'general'} id={'login-general-errors'} />
                    <BSubmit
                        name="login-submit"
                        xs={12}
                        noMP
                        gridStyle={{ marginTop: '12px', marginBottom: '0px' }}
                    >
                        Login
                    </BSubmit>
                    <div>
                        <Typography variant="body2">
                            <Link to="/user/password/forgot">Forgot password</Link>
                        </Typography>
                    </div>
                </BForm>
            </Formik>
        </GridWrapper>
    );
}

export default Login;
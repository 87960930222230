import * as React from 'react';

import { IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { Add, Edit, NotInterested } from '@material-ui/icons';

import { Patient, PatientNote, PatientVisit, ShortScheduledVisit, Study } from '../../../../../@types';
import { displayDate } from '../../../../../common/Utils/utils';
import useLoggedInUserPermissions from '../../../../../common/hooks/useLoggedInUserPermissions';

interface SVPatientSectionProps {
    scheduledVisits: ShortScheduledVisit[];
    patients: Patient[];
    patientNotes?: { [key: number]: PatientNote[] };
    setAddPatientOpen: (value: any) => void;
    patientVisits: PatientVisit[][];
    handleEditPatient: (patient: Patient) => void;
    handlePatientVisit: (idx: number, idx2: number, completelyNew: boolean, readonly: boolean) => () => void;
    study: Study;
}

const ScheduledVisitsTablePatients: React.FC<SVPatientSectionProps> = props => {
    const {
        scheduledVisits,
        patients,
        patientNotes,
        setAddPatientOpen,
        patientVisits,
        handleEditPatient,
        handlePatientVisit,
        study,
    } = props;

    const studyStartedNotComplete = !!study.started && !study.completed;

    const canAddPatient = useLoggedInUserPermissions('API_PATIENT_CREATE', study.divisionId, study.id!) && studyStartedNotComplete;
    const canDeletePatient = useLoggedInUserPermissions('API_PATIENT_DELETE_BY_ID', study.divisionId, study.id!);
    const canEditPatient = useLoggedInUserPermissions('API_PATIENT_UPDATE', study.divisionId, study.id!);
    const canSetPatientStatus = useLoggedInUserPermissions('API_PATIENT_SET_PATIENT_STATUS', study.divisionId, study.id!);
    const canViewPatientNotes = useLoggedInUserPermissions('API_PATIENT_NOTE_GET_ALL_BY_PATIENT_ID', study.divisionId, study.id!);
    const canViewPatientVisit = useLoggedInUserPermissions('API_PATIENT_VISIT_GET_SCHEDULED_PATIENT_VISIT_DETAIL', study.divisionId, study.id!);
    const canCreatePatientVisit = useLoggedInUserPermissions('API_PATIENT_VISIT_CREATE', study.divisionId, study.id!) && studyStartedNotComplete
    const canOpenEditPatientModal = (canDeletePatient || canEditPatient || canSetPatientStatus || canViewPatientNotes) && studyStartedNotComplete;

    const handleClickAddPatient = () => {
        setAddPatientOpen(true);
    }

    const findPositionScheduledVisitForPatient = (_scheduledVisit: ShortScheduledVisit, _allScheduledVisitsForPatient: number[]) => {
        const position = _allScheduledVisitsForPatient.indexOf(_scheduledVisit.id!);
        if (position >= 0) {
            return position;
        }
        if (_scheduledVisit.linkIds && _scheduledVisit.linkIds.length > 0) {
            for (let i = 0; i < _allScheduledVisitsForPatient.length; i++) {
                if (_scheduledVisit.linkIds.includes(_allScheduledVisitsForPatient[i])) {
                    return i;
                }
            }
        }
        return -1;
    }

    const patientNotesTooltip = (patient: Patient, idx: number, active: boolean) => 
        (!active || (patientNotes && patientNotes[patient.id!].length > 0)) ? <div className="sv-patient-note-tooltip">
            {!active && <ul className='tooltip-inactive'> Patient is no longer active </ul>}
            {(patientNotes && patientNotes[patient.id!].length > 0) && <ul>
                {[...patientNotes[patient.id!]].sort((a, b) =>
                    -1 * (new Date(a.created!).getTime() - new Date(b.created!).getTime())
                ).map((note, noteIdx) => {
                    return (
                        <li key={`tooltip-${idx}-${noteIdx}`}> {note.comment} </li>
                    );
                })}
            </ul>}
        </div> : '';

    const logVisitInstruction = (study.started) ?
        'Click on a cell to log or edit a visit' :
        'Patients and visits cannot be logged until the study is started';

    return (
        <>
            <TableRow>
                <TableCell key={'visit-cost'} className={'sv-body-table-label-stick left'} style={{ zIndex: 1 }}>
                    <div>
                        Patients
                        <IconButton
                            disabled={!canAddPatient}
                            className={'white-icon ' + (patients.length < 1 && 'cta')}
                            onClick={handleClickAddPatient}
                            style={{ marginLeft: '10px' }}
                        >
                            <Add />
                        </IconButton>
                    </div>
                </TableCell>
                <TableCell key='sv-patients-label' align={'left'} colSpan={scheduledVisits.length} className="sv-body-table-label">
                    {logVisitInstruction}
                </TableCell>
            </TableRow>
            {patients.map((patient: Patient, idx: number) => {
                const scheduledVisitsForPatient: number[] = patientVisits[idx] ? patientVisits[idx].map(pv => pv.scheduledVisitId!) : [];
                const patientActive: boolean = patient.status === 'ACTIVE';
                return (
                    <TableRow key={'patient-' + idx} hover={studyStartedNotComplete}>
                        <TableCell
                            key={'patient-name-' + idx}
                            className={'sv-table-body-section-starter left' + (canOpenEditPatientModal && ' canDelete')}
                            onClick={() => { if (canOpenEditPatientModal) handleEditPatient(patient) }}
                        >
                            <Tooltip arrow placement="right" title={patientNotesTooltip(patient, idx, patientActive)}>
                                <div className='aligned-row right'>
                                    <span className="aligned-row left">
                                        {patient.status === 'INACTIVE' && <NotInterested className="aligned-row left"/>}
                                    </span>
                                    <span className="aligned-row right">
                                        <Edit className="sv-patient-icon" />
                                        {patient.identifier}
                                    </span>
                                </div>
                            </Tooltip>
                        </TableCell>
                        {new Array(scheduledVisits.length).fill(0).map((_: number, idx2: number) => {
                            const position = findPositionScheduledVisitForPatient(scheduledVisits[idx2], scheduledVisitsForPatient);
                            
                            if (position >= 0) {
                                const readonly = !scheduledVisitsForPatient.includes(scheduledVisits[idx2].id!) || !studyStartedNotComplete;
                                return (
                                    <Tooltip
                                        arrow title={patientVisits[idx][position].note!}
                                        key={'patient-visit-' + idx2}
                                    >
                                        <TableCell
                                            className={(canViewPatientVisit) ? 'body-cell patient-visit-cell center' : 'body-cell center'}
                                            onClick={(canViewPatientVisit) ? handlePatientVisit(idx, position, false, readonly) : undefined}
                                        >
                                            {displayDate(patientVisits[idx][position].dateOfService!)}
                                        </TableCell>
                                    </Tooltip>
                                );
                            }
                            else {
                                if (!patientActive) {
                                    return (
                                        <TableCell className={'sv-table-inactive body-cell'}/> 
                                    )
                                } else {
                                    return (
                                        <TableCell
                                            key={'patient-visit-' + idx2}
                                            className={(canCreatePatientVisit) ? 'body-cell patient-visit-cell center' : 'body-cell center'}
                                            onClick={(canCreatePatientVisit) ? handlePatientVisit(patients[idx].id!, scheduledVisits[idx2].id!, true, false) : undefined}
                                        />
                                    );
                                }
                            }
                        })}
                    </TableRow>
                );
            })}
        </>
    );
}
export default ScheduledVisitsTablePatients;
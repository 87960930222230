import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import { EditTwoTone } from '@material-ui/icons';
import { BForm } from 'mui-bueno';

import { ContractScheduleOfEvents, ScheduleOfEventAssociation } from '../../../../@types';
import useLoggedInUserPermissions from '../../../../common/hooks/useLoggedInUserPermissions';
import { handleErrorResponse } from '../../../../service/utils';
import { getContract, getScheduleOfEvents } from '../../../../service/Contract/contracts';
import { updateScheduleOfEventAssociations } from '../../../../service/Contract/scheduledVisits';

import TotalsTable from './TotalsTable';
import TotalsTableHeader from './TotalsTableHeader';
import EditEmployeeCosts from './EditEmployeeCosts';
import ScheduleOfEventsTable from './ScheduleOfEventsTable';
import ScheduleOfEventsHeader from './ScheduleOfEventsHeader';
import ScheduleOfEventsActions from './ScheduleOfEventsActions';
import { Store } from '../../../../modules/rootReducer';
import { CollapsiblePanelState } from '../../../../modules/collapsiblePanelReducer';
import { Prompt } from 'react-router-dom';
import Loading from '../../../../common/Routes/Loading';

interface Props {
    contractId: number;
    studyId: number;
    divisionId: number;
    readonly: boolean;
}

export interface SOEForm {
    hiddenAssociations: string[];
    added: string[];
    removed: string[];
    actualCosts: number[];
    proposedCosts: number[];
    finalCosts: number[];
}

const ScheduleOfEvents: React.FC<Props> = props => { //NOSONAR
    const { contractId, studyId, divisionId, readonly } = props;

    const dispatcher = useDispatch();

    const canViewSOE = useLoggedInUserPermissions('API_CONTRACT_GET_SCHEDULE_OF_EVENTS', divisionId, studyId);
    const canEditContract = useLoggedInUserPermissions('API_CONTRACT_UPDATE', divisionId, studyId);
    const canEditProcedure = useLoggedInUserPermissions('API_PROCEDURE_UPDATE', divisionId, studyId);

    const [scheduleOfEvents, setScheduleOfEvents] = React.useState<ContractScheduleOfEvents>({} as ContractScheduleOfEvents);
    const [unlockCells, setUnlockCells] = React.useState<boolean>(false);
    const [uploaded, setUploaded] = React.useState<boolean>(false);
    const [reload, setReload] = React.useState<boolean>(false);
    const [editEmployeeCostsOpen, setEditEmployeeCostsOpen] = React.useState(false);
    const [employeeCosts, setEmployeeCosts] = React.useState({
        coordinatorCost: 0,
        providerCost: 0
    });


    const { expanded } = useSelector<Store, CollapsiblePanelState>(
        store => store.collapsiblePanelReducer
    );

    React.useEffect(() => {
        if (canViewSOE) {
            getScheduleOfEvents(contractId)
                .then(res => {
                    setScheduleOfEvents(res.data);
                }).catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        prefix: 'Could not retrieve Schedule of Events: '
                    })
                });
        }
        setUploaded(false);
        setReload(false);
    }, [contractId, uploaded, canViewSOE, reload, employeeCosts])

    React.useEffect(() => {
        getContract(contractId)
            .then(res => {
                setEmployeeCosts({
                    coordinatorCost: res.data.coordinatorCost,
                    providerCost: res.data.providerCost,
                })
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    prefix: 'Could not retrieve Contract: '
                });
            });
        setReload(false);
    }, [contractId, reload])


    React.useEffect(() => {
        const soeMatrix = document.getElementById('schedule-of-events-matrix');
        const totalsMatrix = document.getElementById('totals-matrix');

        if (soeMatrix && totalsMatrix) {
            let syncingActualScrollBar = false;
            let syncingProposedScrollBar = false;
            soeMatrix.onscroll = () => {
                if (!syncingActualScrollBar) {
                    syncingProposedScrollBar = true;
                    totalsMatrix.scrollLeft = soeMatrix.scrollLeft;
                }
                syncingActualScrollBar = false;
            };
            totalsMatrix.onscroll = () => {
                if (!syncingProposedScrollBar) {
                    syncingActualScrollBar = true;
                    soeMatrix.scrollLeft = totalsMatrix.scrollLeft;
                }
                syncingProposedScrollBar = false;
            };
        }
    }, [scheduleOfEvents]);


    const handleUnlockCell = () => {
        setUnlockCells(true);
    }

    const initialValues: SOEForm = {
        hiddenAssociations: scheduleOfEvents.associations,
        added: [],
        removed: [],
        actualCosts: scheduleOfEvents.actualTotalCosts,
        proposedCosts: scheduleOfEvents.proposedTotalCosts,
        finalCosts: scheduleOfEvents.finalTotalCosts
    }

    const createObjectIfNotPresent = (mapRecord: Record<number, ScheduleOfEventAssociation>, visitId: number) => {
        if (!mapRecord[visitId]) {
            mapRecord[visitId] = {
                scheduledVisitId: visitId,
                addedProcedureIds: [],
                removedProcedureIds: []
            };
        }
    }

    const handleSubmit = async (data: SOEForm, { setErrors }: FormikHelpers<SOEForm>) => {
        const svMap: Record<number, ScheduleOfEventAssociation> = {}; //key is sv ID
        for (const str of data.added) {
            const procId = Number(str.split(',')[0]);
            const svId = Number(str.split(',')[1]);
            createObjectIfNotPresent(svMap, svId);
            svMap[svId].addedProcedureIds = [...svMap[svId].addedProcedureIds, procId];
        }
        for (const str of data.removed) {
            const procId = Number(str.split(',')[0]);
            const svId = Number(str.split(',')[1]);
            createObjectIfNotPresent(svMap, svId);
            svMap[svId].removedProcedureIds = [...svMap[svId].removedProcedureIds, procId];
        }
        await updateScheduleOfEventAssociations(Object.values(svMap))
            .catch(err => {
                handleErrorResponse(err, dispatcher, {
                    prefix: 'Could not update Schedule of Events: '
                })
            });
        history.go(0);
    }

    const getError = (message: string) => {
        return (
            <Paper className='empty-soe-grid'>
                {!readonly && canViewSOE &&
                    <div className="aligned-row">
                        <ScheduleOfEventsActions
                            contractId={contractId} studyId={studyId} divisionId={divisionId}
                            readonly={readonly} unlockCells={unlockCells}
                            handleUnlockCell={handleUnlockCell} setUploaded={setUploaded}
                        />
                    </div>
                }
                <Typography>
                    {message}
                </Typography>
            </Paper>
        );
    }

    if (!canViewSOE) {
        return getError('You do not have the permissions to view the schedule of events for this contract. Please contact your division or institution administrator for more information.');
    } else if (Object.keys(scheduleOfEvents).length === 0) {
        return (<Loading />);
    } else if (scheduleOfEvents.scheduledVisits.length === 0 && readonly) {
        return getError('There are no visits or procedures to display in the schedule of events.')
    } else if (scheduleOfEvents.scheduledVisits.length === 0) {
        return getError('There are no visits or procedures to display in the schedule of events. Access the "Visits" or "Procedures" tabs and add them to the table to populate this matrix. Alternatively, click the "Bulk Entry" button above and upload a .csv file of the schedule of events to populate this matrix.');
    }

    return (
        <>
            <Paper className={expanded ? 'constrain-expanded' : ''} style={{ padding: 10 }}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    enableReinitialize
                    validateOnBlur={false}
                    validateOnChange={false}
                >
                    {formikProps => (
                        <BForm>
                            {!readonly &&
                                <Grid container alignItems='center' style={{ paddingBottom: 10 }}>
                                    <Grid item xs={12} md={6} className='employee-costs'>
                                        <TableContainer>
                                            <Table className='border-separate'>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell align='right'>Coordinator Cost</TableCell>
                                                        <TableCell align='right'>$ {employeeCosts.coordinatorCost} per hour</TableCell>
                                                        {(!readonly || canEditContract) &&
                                                            <TableCell align='center' rowSpan={2} className='edit-cell'>
                                                                <IconButton onClick={() => setEditEmployeeCostsOpen(true)}>
                                                                    <EditTwoTone />
                                                                </IconButton>
                                                            </TableCell>
                                                        }
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align='right' className='label'>Provider Cost</TableCell>
                                                        <TableCell align='right'>$ {employeeCosts.providerCost} per hour</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <ScheduleOfEventsActions contractId={contractId} studyId={studyId} divisionId={divisionId} readonly={readonly} unlockCells={unlockCells} handleUnlockCell={handleUnlockCell} setUploaded={setUploaded} />
                                    </Grid>
                                </Grid>
                            }
                            {unlockCells &&
                                <Grid container className='soe-unsaved-changes'>
                                    Click &quot;Lock Cells&quot; to submit your unsaved changes.
                                </Grid>
                            }
                            <TableContainer className='constrain-table' id='schedule-of-events-matrix'>
                                <Table className='border-separate'>
                                    <ScheduleOfEventsHeader scheduledVisits={scheduleOfEvents.scheduledVisits} />
                                    <TableBody>
                                        <ScheduleOfEventsTable
                                            scheduleOfEvents={scheduleOfEvents}
                                            unlockCells={unlockCells}
                                            formikProps={formikProps}
                                            readonly={readonly}
                                            setReload={setReload}
                                            canEditProcedure={canEditProcedure}
                                        />
                                        <TotalsTableHeader scheduledVisits={scheduleOfEvents.scheduledVisits} />
                                        <TotalsTable
                                            proposedTotalCosts={scheduleOfEvents.proposedTotalCosts}
                                            overrideCosts={scheduleOfEvents.overrideCosts}
                                            formikProps={formikProps}
                                        />
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </BForm >
                    )}
                </Formik >
            </Paper >
            <EditEmployeeCosts
                open={editEmployeeCostsOpen}
                setOpen={setEditEmployeeCostsOpen}
                contractId={contractId}
                costs={employeeCosts}
                setCosts={setEmployeeCosts}
            />
            <Prompt
                when={unlockCells}
                message={'This page contains unsaved changes. Are you sure you wish to leave?'}
            />
        </>
    )
}

export default ScheduleOfEvents;
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { TreeItem, TreeView } from '@material-ui/lab';
import { ChevronRight, DescriptionOutlined, ExpandMore, Folder } from '@material-ui/icons';

import { DocumentTree } from '../../../../@types';
import { DisplayTextFormat } from '../../../../common/Utils/DisplayTextFormat';

interface Props {
    id: string;
    type: string;
    fileId: string | undefined;
    documentTree: DocumentTree[];
}

const DirectoryList: React.FC<Props> = (props) => {
    const { id, type, fileId, documentTree } = props;

    const history = useHistory();

    const treeLabel = (text: string, isDocument: boolean) => {
        return (
            <div className='document-tree-label'>
                {isDocument ? <DescriptionOutlined /> : <Folder />}
                {DisplayTextFormat(text)}
            </div>
        )
    }

    const renderTree = (nodes: DocumentTree) => (
        <TreeItem
            className='division-tree-item sparse'
            key={(nodes.document ? 'document-' : 'directory-') + nodes.id}
            nodeId={(nodes.document ? 'document-' : 'directory-') + nodes.id}
            label={treeLabel(nodes.name, nodes.document)}
            onLabelClick={(event) => {
                event.preventDefault()
                history.push(`/study/${id}/documents/${(nodes.document ? 'document' : 'directory')}/${nodes.id}`)
            }}
        >
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </TreeItem>
    );

    const expandIcon = (
        <div className='tree-item-icon'>
            <ChevronRight
                preserveAspectRatio="none"
                className='chevron'
            />
        </div>
    )
    const collapseIcon = (
        <div className='tree-item-icon'>
            <ExpandMore
                preserveAspectRatio="none"
                className='chevron'
            />
        </div>
    )
    
    return (
        <TreeView
            defaultExpandIcon={expandIcon}
            defaultCollapseIcon={collapseIcon}
            selected={type + '-' + fileId}
        >
            {documentTree.map(item => { return renderTree(item) })}
        </TreeView>
    )
}

export default DirectoryList;

import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Edit, GetApp, MonetizationOn } from '@material-ui/icons';

import { InvoiceStatus, LineItemDetailWithInvoice } from '../../@types';
import EditablePagingTable, { ActionOption, TableColumn } from '../DataTable/EditablePagingTable';
import { handleErrorResponse } from '../../service/utils';
import { downloadInvoice } from '../../service/Invoice/invoice';

import LogPayment from '../../features/Invoicing/Payment/LogPayment';
import EditLineItemCost from '../../features/Invoicing/Invoice/EditLineItemCost';
import ViewSourceModal from '../../features/Invoicing/Invoice/ViewSourceModal';
import usePermissions from '../hooks/usePermissions';

interface Props {
    type: 'invoices' | 'line-items';
    viewMode?: any; // payable or receivable
    data: any[]; // note, if you don't provide status field in data, will get an undefined error on EditablePagingTable
    showColumns: string[];
    disableEdit?: boolean;
    editLineItemOpen?: boolean;
    setEditLineItemOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    updateOnChange?: boolean;
    setUpdateOnChange?: (state: boolean) => void;
    mode?: 'full' | 'expanded' | 'dynamic';
}

const disableUpdateCost: InvoiceStatus[] = ['PAID', 'CURRENT', 'PARTIAL']
const disablePayment: InvoiceStatus[] = ['PAID', 'NOT_SENT']

const InvoiceablesTable: React.FC<Props> = props => {
    const { type, data, showColumns, disableEdit, editLineItemOpen, setEditLineItemOpen, updateOnChange, setUpdateOnChange, mode, viewMode } = props;

    const dispatcher = useDispatch();

    const [clickedObj, setClickedObj] = React.useState<any>(null);
    const [logPaymentOpen, setLogPaymentOpen] = React.useState<boolean>(false);
    const [viewSourceOpen, setViewSourceOpen] = React.useState<boolean>(false);

    const allInvoiceColumns: TableColumn[] = [{
        type: 'status',
        displayValue: 'Status',
        align: 'center',
        format: 'status',
    }, {
        type: 'invoice',
        displayValue: 'Invoice #',
        align: 'left',
        format: 'jsx-element',
        style: { minWidth: 90 }
    }, {
        type: 'amount',
        displayValue: 'Amount',
        align: 'right',
        format: 'currency-US',
    }, {
        type: 'dateCreated',
        displayValue: 'Date Created',
        align: 'center',
        format: 'date',
        greyIfEmpty: true,
        style: { minWidth: 80 }
    }, {
        type: 'dateSent',
        displayValue: 'Date Sent',
        align: 'center',
        format: 'date',
        greyIfEmpty: true,
        style: { minWidth: 80 }
    }, {
        type: 'datePaidMinusHoldback',
        displayValue: 'Date Paid to CURRENT',
        align: 'center',
        format: 'date',
        greyIfEmpty: true,
        headerToolTip: 'Date paid minus holdback',
        style: { minWidth: 100 }
    }, {
        type: 'datePaid',
        displayValue: 'Date Paid',
        align: 'center',
        format: 'date',
        greyIfEmpty: true,
        headerToolTip: 'Date fully paid',
        style: { minWidth: 80 }
    }, {
        type: 'study',
        displayValue: 'Study',
        align: 'left',
        format: 'jsx-element',
        style: { minWidth: 90 }
    }, {
        type: 'payee',
        displayValue: 'Payee',
        align: 'left',
        format: 'jsx-element',
        style: { minWidth: 90 }
    }, {
        type: 'payor',
        displayValue: 'Payor',
        align: 'left',
        format: 'jsx-element',
        style: { minWidth: 90 }
    }];

    const allLineItemColumns: TableColumn[] = [{
        type: 'status',
        displayValue: 'Status',
        align: 'center',
        format: 'status',
    }, {
        type: 'invoiceType',
        displayValue: 'Invoice Type',
        align: 'left'
    }, {
        type: 'invoice',
        displayValue: 'Invoice #',
        align: 'left',
        format: 'jsx-element',
    }, {
        type: 'amount',
        displayValue: 'Amount',
        align: 'right',
        format: 'currency-US',
    }, {
        type: 'totalCost',
        displayValue: 'Total Cost',
        align: 'right',
        format: 'jsx-element'
    }, {
        type: 'paid',
        displayValue: 'Paid',
        align: 'right',
        format: 'currency-US'
    }, {
        type: 'datePaidMinusHoldback',
        displayValue: 'Date Paid to CURRENT',
        align: 'center',
        format: 'date',
        greyIfEmpty: true,
        headerToolTip: 'Date paid minus holdback'
    }, {
        type: 'datePaid',
        displayValue: 'Date Paid',
        align: 'center',
        format: 'date',
        greyIfEmpty: true,
        headerToolTip: 'Date fully paid'
    }, {
        type: 'description',
        displayValue: 'Description',
        align: 'left',
        format: 'jsx-element'
    }, {
        type: 'payee',
        displayValue: 'Payee',
        align: 'left',
        format: 'jsx-element',
    }, {
        type: 'payor',
        displayValue: 'Payor',
        align: 'left',
        format: 'jsx-element',
    },];

    const [invoiceColumns, setInvoiceColumns] = React.useState<TableColumn[]>(allInvoiceColumns);
    const [lineItemColumns, setLineItemColumns] = React.useState<TableColumn[]>(allLineItemColumns);

    React.useEffect(() => {
        if (type == 'invoices') {
            setInvoiceColumns(allInvoiceColumns.filter(c => showColumns.includes(c.type)))
        }
        if (type == 'line-items') {
            setLineItemColumns(allLineItemColumns.filter(c => showColumns.includes(c.type)))
        }
    }, [type, showColumns]);

    const handleDownloadInvoice = (obj: any) => {
        downloadInvoice(obj.invoiceId)
            .then(res => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(new Blob([res.data]));
                link.setAttribute('download', `${obj.invoiceNo}.pdf`);
                link.click();
                URL.revokeObjectURL(link.href);
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    prefix: 'Could not retrieve invoice: '
                })
            });
    }

    //permissions for actions menu
    const canDownloadInvoice: boolean = usePermissions('API_INVOICE_DOWNLOAD_INVOICE');

    const canLogPayment: boolean = usePermissions('API_ACCOUNT_PAYMENT_LOG_PAYMENT')
        && viewMode == 'receivable'
        && type == 'invoices';

    const canEditCost: boolean = usePermissions('API_LINE_ITEM_UPDATE_COST')
        && !disableEdit
        && type == 'line-items';

    const invoiceActions: ActionOption[] = [{
        label: 'Log Payment',
        icon: <MonetizationOn />,
        handler: () => setLogPaymentOpen(true),
        permission: canLogPayment,
        permissionFunction: (obj: any) => !disablePayment.includes(obj['status'])
    }, {
        label: 'Download',
        icon: <GetApp />,
        handler: (obj: any) => handleDownloadInvoice(obj),
        permission: canDownloadInvoice
    }];

    const lineItemActions: ActionOption[] = [{
        label: 'Edit Cost',
        icon: <Edit />,
        handler: () => setEditLineItemOpen ? setEditLineItemOpen(true) : undefined,
        permission: canEditCost,
        permissionFunction: (obj: any) => !disableUpdateCost.includes(obj['status'])
    }];

    const getPayorPayeeNames = () => {
        if (!disableUpdateCost.includes(clickedObj['status'])) {

            const payor = clickedObj.payor;
            const payee = clickedObj.payee;

            //if jsx element - extract text
            if (React.isValidElement(payor) && React.isValidElement(payee)) {
                return {
                    payor: clickedObj.payor.props.children,
                    payee: clickedObj.payee.props.children
                }
            } else { //use string passed in
                return {
                    payor: clickedObj.payor,
                    payee: clickedObj.payee
                }
            }
        } else {
            return undefined;
        }
    }

    return (
        <>
            <EditablePagingTable
                dataList={data}
                tableInfoColumns={type == 'invoices' ? invoiceColumns : lineItemColumns}
                actionOptions={type == 'invoices' ? invoiceActions : lineItemActions}
                clickedObj={clickedObj}
                setClickedObj={setClickedObj}
                readonly={false}
                canCreate={false}
                editingDataObject={undefined}
                validationSchema={undefined}
                handleSubmit={undefined}
                mode={mode}
            />
            <LogPayment
                open={logPaymentOpen}
                setOpen={setLogPaymentOpen}
                payingAccountId={clickedObj && type == 'invoices' ? clickedObj.payingAccountId! : undefined}
                receivingAccountId={clickedObj && type == 'invoices' ? clickedObj.receivingAccountId! : undefined}
                studyId={clickedObj && type == 'invoices' ? clickedObj.studyId! : undefined}
                invoiceIds={clickedObj && type == 'invoices' ? [clickedObj.invoiceId!] : undefined}
                logHoldback={clickedObj && type == 'invoices' ? clickedObj.status == 'CURRENT' : undefined}
                receivedPayment
                updateOnChange={updateOnChange ?? false}
                setUpdateOnChange={setUpdateOnChange ?? ((): void => { })}
            />
            <EditLineItemCost
                open={editLineItemOpen ?? false}
                setOpen={setEditLineItemOpen ?? ((): void => { })}
                accountInfo={clickedObj && type == 'line-items' ?
                    getPayorPayeeNames()
                    : undefined
                }
                selectedLineItem={clickedObj}
                canUpdateCost={true}
                updateOnChange={updateOnChange ?? false}
                setUpdateOnChange={setUpdateOnChange ?? ((): void => { })}
            />
            <ViewSourceModal
                open={viewSourceOpen}
                setOpen={setViewSourceOpen}
                lineItem={clickedObj as LineItemDetailWithInvoice}
            />
        </>
    );
}

export default InvoiceablesTable;
import * as React from 'react';

import { TableCell } from '@material-ui/core';

import { Calculation } from '../../../../@types';
import { displayStatisticsValue } from '../../../../common/Utils/NumberFormatter';


interface Props {
    monthlyCalculations: Calculation[];
    calculationType: string;
    editYear: number;
    currentYear: number;
    currentMonth: number;
}

const MonthlyCalculationTableCell: React.FC<Props> = props => {
    const { monthlyCalculations, calculationType, editYear, currentYear, currentMonth } = props;

    return (
        <>
            {Array.from(Array(12).keys()).map(month => {
                const filtered: Calculation[] = monthlyCalculations.filter(calculation =>
                    calculation.calculationType === calculationType &&
                    calculation.associatedDate.getUTCMonth() === month &&
                    calculation.associatedDate.getUTCFullYear() === editYear);
                const value: number = filtered[0]?.value ?? 0;
                return (
                    <TableCell
                        key={`entire-study-month-${month}-${calculationType}`}
                        className="text-align-right data-cell"
                        data-disabled={editYear === currentYear && month > currentMonth}
                        data-negative={value < 0}
                    >
                        {displayStatisticsValue(value)}
                    </TableCell>
                )
            })}
        </>
    )
}

export default MonthlyCalculationTableCell;
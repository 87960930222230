import * as React from 'react';
import { useDispatch } from 'react-redux';

import {
    Grid,
    Typography
} from '@material-ui/core';

import { verifySetupToken } from '../../../service/Access/authentication';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../modules/messageSnackbarReducer';
import useIncompleteLoggedInUser from '../../../common/hooks/useIncompleteLoggedInUser';
import NotFound from '../../../common/Routes/NotFound';
import { useHistory } from 'react-router-dom';
import { clearStoreForceReset } from '../../../modules/authenticationReducer';
import QRCode from 'qrcode.react';
import { Formik, FormikHelpers } from 'formik';
import { BForm, BPassword, BSubmit, BTextField } from 'mui-bueno';
import { MfaTokenRequest } from '../../../@types';
import { AxiosError } from 'axios';
import { handleEditErrors } from '../../../service/utils';

interface FormValues {
    code: string;
    token: string;
}

const SetupMfa: React.FC = () => {
    const information = useIncompleteLoggedInUser();
    const userEmail = information.userEmail;
    const session = information.session;
    const secretCode = information.secretCode;
    const accessToken = information.accessToken;

    if (!userEmail || !secretCode || (!session && !accessToken)) {
        return <NotFound />;
    }

    const initialValues: FormValues = {
        code: secretCode,
        token: '',
    }

    const history = useHistory();

    const dispatch = useDispatch();

    const handleSubmit = async (data: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
        setErrors({});
        const mfaTokenRequest: MfaTokenRequest = {
            token: data.token,
            session: session,
            email: userEmail,
            accessToken: accessToken
        }
        verifySetupToken(mfaTokenRequest).then(() => {
            dispatch(showSuccessSnackbar('2 Factor Authentication set up successfully. Please log in.'));
            dispatch(clearStoreForceReset());
            history.push('/login');
        }).catch((err: AxiosError) => {
            if (err.response?.status === 422) {
                handleEditErrors(err, setErrors);
            } else if (err.response?.status === 400) {
                const index = err.response.data.message.indexOf('(');
                const message = err.response.data.message.substring(0, index - 1);
                if (message === 'Invalid session for the user, session is expired.') {
                    dispatch(showErrorSnackbar('Your session has expired. Please click the login button at the top right corner and login again.'));
                } else if (err.response.data.message === 'Code mismatch and fail enable Software Token MFA') {
                    setErrors({ token: 'The entered code is incorrect.' });
                } else {
                    dispatch(showErrorSnackbar('Unexpected error, please try again later.'));
                }
            }
        });
    }

    const validate = (values: FormValues) => {
        const errors: { [key: string]: string } = {};
        if (values.token.length !== 7)
            errors.token = 'Token length incorrect. Token must be 6 digits long with a space in between groups of 3 digits (e.g. 123 456).';
        return errors;
    };

    const createValue = (): string => {
        return 'otpauth://totp/SimonComputing%20CRAFT:' + userEmail + '?secret= ' + secretCode + '&issuer=' + 'SimonComputing%20CRAFT&digits=6&period=30';
    }

    return (
        <div className="page-body">
            <Formik
                initialValues={initialValues}
                validate={validate}
                onSubmit={handleSubmit}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
            >
                <BForm>
                    <Grid container>
                        <Typography className="card-heading">Set Up 2 Factor Authentication</Typography>
                        <Grid item>
                            <Typography variant="body2">
                                In order to set up 2 factor authentication, you will need to install an authenticator app on your mobile device. To do this, navigate to the &quot;App store&quot; on
                                your mobile device and search &quot;authenticator app&quot;. Choose and install any one of the applications available. Once the installation has finished, tap on the
                                application. Each application will be slightly different, but the overall steps should be similar. Follow the instructions on the screen. Depending on the application,
                                you may be required to create an account. The application will ask for permission to access your camera. Select the response based on your own comfort levels - there are
                                methods for completing the setup process for both options. Once you have created an account, there should be a button that allows you to add a new service or account
                                (typically a &quot;+&quot; sign). Tap on that button. If you have given the application permission to access the camera, choose the scan QR code option. If not, skip
                                to the &quot;Manually Enter Code&quot; section. Move your mobile device so that the entire QR code is within the field of view of the camera. The application should register
                                the image and provide you with a default name for this service or account. Feel free to change this name. Once you are satisfied with the name, tap the submit button.
                                You should see a section on your mobile application that shows the account or service followed by a 6 digit token. Skip to the &quot;Complete Setup of 2 Factor Authentication&quot;
                                section now for the steps to proceed.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4">Scan the QR Code</Typography>
                            <QRCode value={createValue()} size={256} />
                        </Grid>
                        <Grid item>
                            <Typography variant="h4">Manually Enter Code</Typography>
                            <Typography variant="body2">
                                If you do not wish to allow permissions to your camera, choose the enter code manually option. Right below this section on this page there should be a textbox with a hidden code.
                                Click the eye with a slash icon to reveal the secret code. Enter this code into your mobile device. If you are viewing this page on a small screen, make sure to scroll the text
                                field all the way to the right to view the entire code. If the application has a section for entering the service or account name, provide an easily identifiable name. If not,
                                then this should appear in the next step. Tap the submit button. Once you are on the main application screen now you should see a section on your mobile application that shows
                                the account or service followed by a 6 digit token. Skip to the &quot;Complete Setup of 2 Factor Authentication&quot; section now for the steps to proceed.
                            </Typography>
                        </Grid>
                        <BPassword
                            name="code"
                            disabled
                        />
                        <Typography variant="h4">Complete Setup of 2 Factor Authentication</Typography>
                        <Typography variant="body2">
                            You should see now a section on your mobile application that shows the account or service followed by a 6 digit token. Enter this token into the text box below without any spaces
                            (the space should automatically fill for you). Click the submit button below to finish the setup process. Congratulations, you have now set up 2 factor authentication!
                        </Typography>
                        <BTextField
                            name="token"
                            label="Token"
                            format="999 999"
                            required
                        />
                        <BSubmit
                            name="submit-mfa-setup"
                            xs={12}
                            className='card-button'
                        >
                            Submit
                        </BSubmit>
                    </Grid>
                </BForm>
            </Formik>
        </div>
    );
}
export default SetupMfa;

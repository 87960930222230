import * as React from 'react';

import { Box, CircularProgress, Typography } from '@material-ui/core';

const Loading: React.FC<{ msg?: string }> = props => {
    return (
        <div className="full-screen centered">
            <Box textAlign="center" className="page-body">
                <CircularProgress />
                <Typography gutterBottom variant="h1">
                    {props.msg ?? 'Loading...'}
                </Typography>
            </Box>
        </div>
    );
}

export default Loading;
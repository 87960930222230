import { combineReducers } from 'redux';

import * as authenticationReducer from './authenticationReducer';
import * as loginReducer from './loginReducer';
import * as loginJwtReducer from './loginJwtReducer';
import * as messageSnackbarReducer from './messageSnackbarReducer'
import * as collapsiblePanelReducer from './collapsiblePanelReducer'
import * as simulatorReducer from './simulatorReducer';

/*
 * Root state of the app
 * Contains every substate of the app
 */
export interface Store {
    loginReducer: loginReducer.LoginState;
    loginJwtReducer: loginJwtReducer.LoginJwtState,
    authenticationReducer: authenticationReducer.ForceResetPasswordState;
    messageSnackbarReducer: messageSnackbarReducer.MessageSnackbarState;
    collapsiblePanelReducer: collapsiblePanelReducer.CollapsiblePanelState;
    simulatorReducer: simulatorReducer.SimulatorState;
}

/*
 * Initial state of the app
 */
export const initialState: Store = {
    loginReducer: loginReducer.initialState,
    loginJwtReducer: loginJwtReducer.initialState,
    authenticationReducer: authenticationReducer.initialState,
    messageSnackbarReducer: messageSnackbarReducer.initialState,
    collapsiblePanelReducer: collapsiblePanelReducer.initialState,
    simulatorReducer: simulatorReducer.initialState,
};

/*
 * Root reducer of the app
 * Returned reducer will be the type of Reducer<Store>
 */
const appReducer = combineReducers<Store>({
    loginReducer: loginReducer.reducer,
    loginJwtReducer: loginJwtReducer.reducer,
    authenticationReducer: authenticationReducer.reducer,
    messageSnackbarReducer: messageSnackbarReducer.reducer,
    collapsiblePanelReducer: collapsiblePanelReducer.reducer,
    simulatorReducer: simulatorReducer.reducer,
});

export const rootReducer = (state: Store | undefined, action: any) => {
    if (action.type === loginReducer.LOGOUT) {
        state = {
            ...initialState
        };
    }
    return appReducer(state, action);
};

export default rootReducer;
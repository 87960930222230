import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardHeader, Grid } from '@material-ui/core';

import { SimulatorResourceResult } from '../../../../../@types';
import { handleErrorResponse } from '../../../../../service/utils';
import { getMonthlyResourceResultByYear } from '../../../../../service/Simulator/simulator';
import { Store } from '../../../../../modules/rootReducer';
import { SimulatorState } from '../../../../../modules/simulatorReducer';

import Loading from '../../../../../common/Routes/Loading';
import AllocatedOverReqChart from './AllocatedOverReqChart';
import ResourceReqChart from './ResourceReqChart';


interface Props {
    simulatorId: number;
    editYear: number;
    setEditYear: any;
}

const emptyResourceResult: SimulatorResourceResult = {
    resourceRequirements: [],
    resources: new Map<string, string>()
}

const ResourceCharts: React.FC<Props> = (props) => {
    const { simulatorId, editYear, setEditYear } = props;
    
    const dispatcher = useDispatch();

    const { updated } = useSelector<Store, SimulatorState>(
        store => store.simulatorReducer
    );

    const [resourceData, setResourceData] = React.useState<SimulatorResourceResult>(emptyResourceResult);
    const [doneLoading, setDoneLoading] = React.useState<boolean>(true);

    const coordinatorAllocatedColor = '#c5e063';
    const providerAllocatedColor = '#ffc2b4';
    const coordinatorRequiredColor = '#075c55';
    const providerRequiredColor = '#982126';

    const getData = (year: number) => {
        getMonthlyResourceResultByYear(simulatorId, year).then(res => {
            setResourceData(res.data);
            setDoneLoading(true);
        }).catch(err => {
            handleErrorResponse(err, dispatcher, {
                prefix: 'Could not get resource requirements: '
            })
        });
    }

    React.useEffect(() => {
        getData(editYear);
    }, [simulatorId, editYear, updated])

    const convertData = (initialData: SimulatorResourceResult) => {
        const chartData: any[] = [];
        if (initialData.resourceRequirements.length > 0) {
            initialData.resourceRequirements.forEach((resourceResult: any) => {
                /*
                    Want to update this to create a way to dynamically create and assign the fields on the object
                    being pushed to chartData. I.e. instead of writing 'coordinatorRequired' by hand, figure out
                    a way to use resourceData.resources map to create the variable names and get the respective
                    values. Will also need to figure out a way to tell the graph what dataKey to use
                */
                chartData.push({
                    associatedDate: resourceResult.associatedDate,
                    coordinatorRequired: resourceResult.requiredResources.C,
                    coordinatorAllocated: resourceResult.allocatedResources.C,
                    coordinatorDifference: resourceResult.differenceResources.C,
                    providerRequired: resourceResult.requiredResources.P,
                    providerAllocated: resourceResult.allocatedResources.P,
                    providerDifference: resourceResult.differenceResources.P,
                })
            })
        }
        return chartData;
    }

    if (doneLoading) {
        return (
            <>
                <Grid item xs={12}>
                    <Card className='simulator-chart card'>
                        <CardHeader title='Estimated Resource Requirements' />
                        <ResourceReqChart
                            data={convertData(resourceData)}
                            editYear={editYear}
                            setEditYear={setEditYear}
                            coordinatorAllocatedColor={coordinatorAllocatedColor}
                            providerAllocatedColor={providerAllocatedColor}
                            coordinatorRequiredColor={coordinatorRequiredColor}
                            providerRequiredColor={providerRequiredColor}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className='simulator-chart card'>
                        <CardHeader title='Estimated Requirements Difference' />
                        <AllocatedOverReqChart
                            data={convertData(resourceData)}
                            editYear={editYear}
                            setEditYear={setEditYear}
                            coordinatorColor={coordinatorRequiredColor}
                            providerColor={providerRequiredColor}
                        />
                    </Card>
                </Grid>
            </>
        )
    } else {
        return (
            <Loading />
        )
    }
}
export default ResourceCharts;
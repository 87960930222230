import { useSelector } from 'react-redux';
import { ForceResetPasswordState } from '../../modules/authenticationReducer';

import { Store } from '../../modules/rootReducer';

const useIncompleteLoggedInUser = () => {
    return useSelector<Store, ForceResetPasswordState>(store =>
        store.authenticationReducer
    );
};

export default useIncompleteLoggedInUser;

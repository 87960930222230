import axiosInstance from '../Access/axiosInstance';
import { Calculation, StudyStatisticsUsers } from '../../@types';
import { handleError } from '../utils';

/**
 * Gets a List of PRODUCTIVITY Calculations for a User and year
 */
export const getUserProductivityCalculationsByYear = (id: number, year: number) =>
    axiosInstance.get<Calculation[]>(
        `/api/calculations/user/productivity/${id}/${year}`
    ).catch(handleError);

/**
 * Gets a List of Calculations for a User and year
 */
export const getUserCalculationsByYear = (id: number, year: number) =>
    axiosInstance.get<Calculation[]>(
        `/api/calculations/user/${id}/${year}`
    ).catch(handleError);

/**
* Gets a List of Calculations for a User , a study, and year
*/
export const getUserCalculationsByStudyAndYear = (userId: number, studyId: number, year: number) =>
    axiosInstance.get<Calculation[]>(
        `/api/calculations/user/${userId}/study/${studyId}/${year}`
    ).catch(handleError);

/**
 * Gets a set of Calculation PRODUCTIVITY totals for all Studies assigned to a User
 */
export const getStudyCalculationTotalByUser = (userId: number) =>
    axiosInstance.get<Calculation[]>(
        `/api/calculations/total/user/${userId}`
    ).catch(handleError);

/**
 * Gets a set of MONTHLY_COST, MONTHLY_ACTIVITY and PRODUCTIVITY Calculation totals for a User and Study
 */
export const getCalculationTotalsByUserAndStudyId = (userId: number, studyId: number) =>
    axiosInstance.get<Calculation[]>(
        `/api/calculations/total/user/${userId}/study/${studyId}`
    ).catch(handleError);

/**
 * Gets a set of monthly MONTHLY_COST, MONTHLY_ACTIVITY and PRODUCTIVITY Calculation totals for an entire Study and year
 */
export const getMonthlyCalculationTotalsByStudyIdAndYear = (studyId: number, year: number) =>
    axiosInstance.get<Calculation[]>(
        `/api/calculations/total/monthly/study/${studyId}/${year}`
    ).catch(handleError);

/**
 * Gets a set of MONTHLY_COST, MONTHLY_ACTIVITY and PRODUCTIVITY Calculation totals for an entire Study
 */
export const getCalculationTotalsByStudyId = (studyId: number) =>
    axiosInstance.get<Calculation[]>(
        `/api/calculations/total/study/${studyId}`
    ).catch(handleError);

/**
 * Get the study's coordinators, providers, and others who have the calculations for the study.
 */
export const getStudyStatisticsUsers = (studyId: number) =>
    axiosInstance.get<StudyStatisticsUsers>(
        `/api/calculations/users/study/${studyId}`
    ).catch(handleError);

export const getMonthlyDivisionCalculations = (dataType: string, dataMode: string, divisionId: number, year: number) =>
    axiosInstance.get<Calculation[]>(
        `/api/calculations/division/${dataType}/${dataMode}/${divisionId}/${year}`
    ).catch(handleError);

export const getYearlySumsByDivision = (dataType: string, dataMode: string, divisionId: number, year: number) =>
    axiosInstance.get<Calculation[]>(
        `/api/calculations/division/sums/${dataType}/${dataMode}/${divisionId}/${year}`
    ).catch(handleError);

export const getLifetimeSumsByDivision = (dataType: string, dataMode: string, divisionId: number) =>
    axiosInstance.get<Calculation[]>(
        `/api/calculations/division/sums/${dataType}/${dataMode}/${divisionId}`
    ).catch(handleError);

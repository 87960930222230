import * as React from 'react';
import { useDispatch } from 'react-redux';
import { BOption } from 'mui-bueno';

import {
    Contract,
    IncurredCostWithUsers,
    LineItemDetailWithInvoice,
    PatientVisit,
    PatientVisitUser,
    ScheduledPatientVisitsTab,
    SelectOption,
    Study,
    UnscheduledPatientVisitsTab,
    UserSummary
} from '../../../@types';
import { handleErrorResponse } from '../../../service/utils';
import {
    getPatientVisit,
    getScheduledPatientVisitDetail
} from '../../../service/Study/patientVisit';
import {
    getStudy,
    getStudyCurrentContract,
    getUnscheduledPatientVisitsTabByPatientVisitId
} from '../../../service/Study/study';
import { getPatient } from '../../../service/Study/patient';
import { getUserIncurredCost } from '../../../service/IncurredCost/incurredCost';

import ScheduledVisitsModal from '../../Study/Details/Activity/Modals/ScheduledVisitModal';
import EditIncurredCost from '../../Study/Details/Activity/IncurredCosts/EditIncurredCost';
import UnscheduledVisitModal from '../../Study/Details/Activity/Modals/UnscheduledVisitModal';


const blankScheduledPatientVisitsData: ScheduledPatientVisitsTab = { scheduledVisits: [], proceduresAssociatedWithScheduledVisits: [], payments: [], holdbacks: [], totalPerVisits: [], patients: [], allProceduresFromContract: [], scheduledPatientVisits: [], allCoordinatorsOnStudy: [], allProvidersOnStudy: [], totalVisits: [], expectedPaid: [], expectedHold: [], totalPaid: [] };

function toUserSummary(user: PatientVisitUser): UserSummary {
    return ({
        id: user.userId ?? -1,
        firstName: user.userName,
        lastName: '',
        active: true
    });
}

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    lineItem: LineItemDetailWithInvoice;
}

const ViewSourceModal: React.FC<Props> = props => {
    const {
        open,
        setOpen,
        lineItem
    } = props;

    const dispatch = useDispatch();

    const [patientVisit, setPatientVisit] = React.useState<PatientVisit | null>(null);
    const [study, setStudy] = React.useState<Study | null>(null);
    const [scheduledPatientVisitsData, setScheduledPatientVisitsData] = React.useState<ScheduledPatientVisitsTab | null>(null);
    const [unscheduledPatientVisitsTab, setUnscheduledPatientVisitsTab] = React.useState<UnscheduledPatientVisitsTab | null>(null);
    const [contract, setContract] = React.useState<Contract | null>(null);
    const [incurredCostWithUsers, setIncurredCostWithUsers] = React.useState<IncurredCostWithUsers | null>(null);
    const [invoiceTypes, setInvoiceTypes] = React.useState<BOption<string>[]>([]);
    const [serviceProviderOptions, setServiceProviderOptions] = React.useState<SelectOption<number>[]>([]);

    const viewSourceError = (err: any) => {
        handleErrorResponse(err, dispatch, {
            prefix: 'Could not retrieve Line Item source data: '
        });
        setOpen(false);
    }

    React.useEffect(() => {
        if (!open) return; // prevents data from being loaded when actions menu is first opened
        if (lineItem?.sourceType === 'PATIENT_VISIT') {
            getPatientVisit(lineItem.sourceId)
                .then(patientVisitResponse => {
                    setPatientVisit(patientVisitResponse.data);
                    if (patientVisitResponse.data.scheduledVisitId) { // Scheduled Visit
                        getScheduledPatientVisitDetail(lineItem.sourceId)
                            .then(scheduledPatientVisitDetailResponse => {
                                getPatient(scheduledPatientVisitDetailResponse.data.patientId)
                                    .then(patientResponse => {
                                        setScheduledPatientVisitsData({
                                            ...blankScheduledPatientVisitsData,
                                            patients: [patientResponse.data],
                                            allProceduresFromContract: scheduledPatientVisitDetailResponse.data.allProceduresFromContract,
                                            allCoordinatorsOnStudy: scheduledPatientVisitDetailResponse.data.visitCoordinators.map(user => toUserSummary(user)),
                                            allProvidersOnStudy: scheduledPatientVisitDetailResponse.data.visitProviders.map(user => toUserSummary(user)),
                                        });
                                        getStudy(patientResponse.data.studyId)
                                            .then(studyResponse => {
                                                setStudy(studyResponse.data);
                                            })
                                            .catch(err => viewSourceError(err));
                                    })
                                    .catch(err => viewSourceError(err));
                            })
                            .catch(err => viewSourceError(err));
                    } else { // Unscheduled Visit
                        if (patientVisitResponse.data.id) {
                            getUnscheduledPatientVisitsTabByPatientVisitId(patientVisitResponse.data.id)
                                .then(unscheduledPatientVisitsTabResponse => {
                                    setUnscheduledPatientVisitsTab(unscheduledPatientVisitsTabResponse.data);
                                })
                                .catch(err => viewSourceError(err));
                        }
                    }
                })
                .catch(err => viewSourceError(err));
        } else if (lineItem?.sourceType === 'INCURRED_COST') {
            setServiceProviderOptions([
                {
                    value: lineItem.receivingAccountId,
                    label: lineItem.receivingAccountName
                }, {
                    value: lineItem.payingAccountId,
                    label: lineItem.payingAccountName
                }
            ]);
            getUserIncurredCost(lineItem.sourceId)
                .then(incurredCostResponse => {
                    setIncurredCostWithUsers(incurredCostResponse.data);
                    const incurredCostType: string = incurredCostResponse.data.incurredCost.invoiceType ?? '';
                    setInvoiceTypes([{
                        value: incurredCostType,
                        label: incurredCostType.split('_').map(word => word[0] + word.substring(1).toLocaleLowerCase()).join(' ')
                    }]);
                    
                    getStudyCurrentContract(incurredCostResponse.data.incurredCost.studyId)
                        .then(contractResponse => {
                            setContract(contractResponse.data);
                        })
                        .catch(err => viewSourceError(err));
                })
                .catch(err => viewSourceError(err));
        }
    }, [lineItem, open]);

    React.useEffect(() => {
        if (open) return;
        // clear all data when closing
        setPatientVisit(null);
        setStudy(null);
        setScheduledPatientVisitsData(null);
        setUnscheduledPatientVisitsTab(null);
        setContract(null);
        setIncurredCostWithUsers(null);
        setInvoiceTypes([]);
        setServiceProviderOptions([]);
    }, [open]);

    const isScheduledVisit: boolean = !!patientVisit?.id && !!patientVisit.scheduledVisitId;
    const isScheduledVisitValid: boolean = !!patientVisit && !!study && !!scheduledPatientVisitsData;
    const isUnscheduledVisitValid: boolean = !!unscheduledPatientVisitsTab;

    return (
        <>
            {lineItem?.sourceType === 'PATIENT_VISIT' &&
                ((isScheduledVisit && isScheduledVisitValid) || (!isScheduledVisit && isUnscheduledVisitValid)) // Hides Modal entirely to prevent screen flashing when unauthorized
                && <>
                    {isScheduledVisit ?
                        isScheduledVisitValid &&
                        <ScheduledVisitsModal
                            open={open}
                            setOpen={setOpen}
                            patientVisit={patientVisit!}
                            study={study!}
                            scheduledPatientVisitsData={scheduledPatientVisitsData!}
                            lineItemName={lineItem.description}
                            readOnly
                        />
                        : isUnscheduledVisitValid &&
                        <UnscheduledVisitModal
                            open={open}
                            setOpen={setOpen}
                            patientVisit={unscheduledPatientVisitsTab}
                            mode="VIEW"
                            lineItem={true}
                        />
                    }
                </>
            }
            {lineItem?.sourceType === 'INCURRED_COST'
                && !!incurredCostWithUsers && !!contract
                && <EditIncurredCost
                    open={open}
                    handleEditCostModalClose={() => setOpen(false)}
                    incurredCostToEdit={incurredCostWithUsers.incurredCost}
                    users={incurredCostWithUsers.incurredCostUsers}
                    employees={[]}
                    invoiceTypes={invoiceTypes}
                    serviceProviders={serviceProviderOptions}
                    setUpdatePage={() => {}}
                    readOnly
                    lineItemDisplay
                />
            }
        </>
    );
}

export default ViewSourceModal;
import * as React from 'react'

import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { BForm, BGrid, BSubmit, BTextField } from 'mui-bueno';
import { BGridProps } from 'mui-bueno/lib/components/BGrid';

import {
    Card, CardActions, CardContent, InputAdornment,
    Modal, Table, TableBody, TableCell, TableContainer, TableRow,
} from '@material-ui/core';
import { Save } from '@material-ui/icons';

import CardTitle from '../../../common/Card/CardTitle'
import { EditableLineItem, TableHeader } from '../../../@types';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../service/utils';
import { NumberFormatter } from '../../../common/Utils/NumberFormatter';
import { updateLineItemCost } from '../../../service/Invoice/lineItem';


interface Props {
    open: boolean,
    setOpen: (state: boolean) => void
    accountInfo?: { payor: string, payee: string }
    selectedLineItem: any
    canUpdateCost: boolean
    updateOnChange: boolean,
    setUpdateOnChange: (state: boolean) => void,
}

/**
 * @param open modal state
 * @param setOpen toggle state change for modal
 * @param accountInfo payor: 'paying account name', payee: 'receiving account name' 
 * @param selectedLineItem LineItemTable, line item to update
 * @param canUpdateCost boolean, determines if user can update line item
 */
const EditLineItemCost: React.FC<Props> = props => {

    const { open, setOpen, accountInfo, selectedLineItem, canUpdateCost, updateOnChange, setUpdateOnChange } = props;

    const dispatcher = useDispatch();

    const [inputErrors, setInputErrors] = React.useState<{ [k: string]: string }>({});
    const [lineItem, setLineItem] = React.useState<EditableLineItem>()

    const tblSections: TableHeader[] = [
        { type: 'payor', displayValue: 'Payor', align: 'left' },
        { type: 'payee', displayValue: 'Payee', align: 'left' },
        { type: 'description', displayValue: 'Description', align: 'left' }
    ]

    React.useEffect(() => {
        if (accountInfo && open) {
            if (selectedLineItem) {
                const lineItemValues: EditableLineItem = {
                    id: selectedLineItem.id,
                    description: selectedLineItem.description,
                    payor: accountInfo.payor,
                    payee: accountInfo.payee,
                    amount: selectedLineItem['amount'],
                }
                setLineItem(lineItemValues);
            }
        }
    }, [open])

    const validate = (values: any) => {
        const errors: { [k: string]: string } = {};
        if (values.amount < 0)
            errors.amount = 'Total Cost must be greater than or equal to $0.00'
        setInputErrors(errors);
        return errors;
    }

    const handleSubmit = async (data: EditableLineItem, { setErrors }: FormikHelpers<EditableLineItem>) => {

        const errors = validate(data);
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }
        if (data.id && data.amount) {

            const formData = new FormData;
            formData.set('cost', data.amount.toString())

            //update cost
            updateLineItemCost(data.id, formData)
                .then(res => {
                    dispatcher(showSuccessSnackbar('Line item cost updated'));
                    setUpdateOnChange(!updateOnChange);
                    handleCloseModal();
                }).catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        prefix: 'Line item cost could not be updated: '
                    })
                });
        } else {
            dispatcher(showErrorSnackbar('Line item could not be updated'));
        }
    }

    const handleCloseModal = () => {
        setOpen(false);
    }

    const getCellContents = (type: string, obj: EditableLineItem) => {
        const property = type.valueOf()
        return (
            <BGrid {...props as BGridProps}>
                {obj ? obj[property as keyof EditableLineItem] : ''}
            </BGrid>
        )
    }

    const toolTip = 'A line item\'s cost can not be updated once its invoice has been sent'

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
        >
            <div className='modal-form'>
                <Formik
                    initialValues={lineItem!}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    <BForm>
                        <Card className='detail-form sm line-item-form'>
                            <CardTitle handleClose={handleCloseModal} toolTip={toolTip} title={'Edit Line Item Cost'} />
                            <CardContent>
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            {tblSections.map((section) => (
                                                <TableRow key={`row-${section.type}`}>
                                                    <TableCell className='label'>
                                                        {section.displayValue}:
                                                    </TableCell>
                                                    <TableCell className='value'>
                                                        {lineItem ? getCellContents(section.type, lineItem) : ''}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell className='label'>
                                                    Total Cost:
                                                </TableCell>
                                                <TableCell>
                                                    <BTextField
                                                        name='amount'
                                                        label=''
                                                        required autoFocus
                                                        placeholder='e.g. 100.00'
                                                        staticLabel
                                                        disabled={!canUpdateCost}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                                                            inputComponent: NumberFormatter as any,
                                                            error: !!inputErrors['amount'],
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                            {canUpdateCost &&
                                <CardActions className='flex-end'>
                                    <BSubmit
                                        startIcon={<Save />}
                                        variant='contained'
                                        color='primary'
                                    >
                                        Save
                                    </BSubmit>
                                </CardActions>
                            }
                        </Card>
                    </BForm>
                </Formik>
            </div>
        </Modal>
    );
}

export default EditLineItemCost;
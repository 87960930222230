import * as React from 'react';
import NumberFormat from 'react-number-format';

const ALT_NEGATIVE: boolean = true;

interface NumberFormatterProps {
    inputRef?: (instance: NumberFormat | null) => void;
    onChange?: (event: { target: { name: string; value: string } }) => void;
    name?: string;
    value?: any;
    integer?: boolean;
    currency?: boolean;
    percent?: boolean;
    prefix?: string;
    suffix?: string;
    className?: string;
    default?: number;
    nullable?: boolean;
}

export const NumberFormatter = (props: NumberFormatterProps) => {
    const {
        inputRef, onChange, name, value, integer, currency,
        percent, prefix, suffix, className, nullable, ...other
    } = props;

    const nonNullValue = value ?? (props.default ?? 0);

    if (inputRef && onChange) {
        return (
            <NumberFormat
                {...other}
                value={nullable ? value : nonNullValue}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: name ?? '',
                            value: values.value,
                        },
                    });
                }}
                decimalScale={integer ? 0 : 2}
                thousandSeparator
                isNumericString
                fixedDecimalScale
                className={className ?? ''}
            />
        );
    } else {
        const negative: boolean = nonNullValue < 0;
        const signedValue: number = ALT_NEGATIVE && negative ? -nonNullValue : nonNullValue;
        const percentedValue: any = percent ? (signedValue * 100).toFixed(2) : signedValue;
        const _value = (nullable && !value) ? null : percentedValue;
        return (
            <NumberFormat
                {...other}
                value={_value}
                className={(className ?? '') + (negative ? ' error-color' : '')}
                decimalScale={(integer || percent) ? 0 : 2}
                prefix={`${ALT_NEGATIVE && negative ? '(' : ''}${prefix ?? (currency ? '$' : '')}`}
                suffix={`${suffix ?? percent ? '%' : ''}${ALT_NEGATIVE && negative ? ')' : ''}`}
                displayType="text"
                thousandSeparator
                isNumericString
                fixedDecimalScale
            />
        );
    }
}

export const NullableNumberFormatter = (props: NumberFormatterProps) => {
    const {
        inputRef, onChange, name, value, integer, currency,
        percent, prefix, suffix, className, ...other
    } = props;

    if (inputRef && onChange) {
        return (
            <NumberFormat
                {...other}
                value={value}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: name ?? '',
                            value: values.value,
                        },
                    });
                }}
                decimalScale={integer ? 0 : 2}
                thousandSeparator
                isNumericString
                fixedDecimalScale
                className={className ?? ''}
            />
        );
    } else {
        const nonNullValue = value !== null ? value : 0;
        const negative: boolean = nonNullValue < 0;
        const _value: number = ALT_NEGATIVE && negative ? -nonNullValue : nonNullValue;
        return (
            <NumberFormat
                {...other}
                value={percent ? (_value * 100).toFixed(2) : (value ? _value : null)}
                className={(className ?? '') + (negative ? ' error-color' : '')}
                decimalScale={(integer || percent) ? 0 : 2}
                prefix={`${ALT_NEGATIVE && negative ? '(' : ''}${prefix ?? (currency ? '$' : '')}`}
                suffix={`${suffix ?? percent ? '%' : ''}${ALT_NEGATIVE && negative ? ')' : ''}`}
                displayType="text"
                thousandSeparator
                isNumericString
                fixedDecimalScale
            />
        );
    }
}

export function displayStatisticsValue(value?: number): React.ReactNode {
    if (!value)
        return (<>{'---'}</>);
    else
        return (<NumberFormatter currency value={value} />);
}

export function displayStatisticsPercent(value?: number): React.ReactNode {
    if (!value)
        return (<>{'---'}</>);
    else
        return (<NumberFormatter percent value={value} />);
}
import * as React from 'react';

import {
    Button, Card, CardActions, CardContent, CardHeader,
    Grid, IconButton, Modal, TextField, Typography
} from '@material-ui/core';
import { Close, Delete } from '@material-ui/icons';

interface Props {
    open: any;
    setOpen: any;
    type: string;
    objectName: string;
    handleDelete: any;
}

const TypeConfirmDelete: React.FC<Props> = props => {
    const { open, setOpen, type, objectName, handleDelete } = props;

    const [string, setString] = React.useState<string>('');
    const handleString = () => (event: React.ChangeEvent<HTMLInputElement>) => {
        setString(event.target.value);
    }

    const handleModalClose = () => {
        setOpen(false);
    }

    return (
        <Modal
            open={open}
            onClose={handleModalClose}
        >
            <div className="modal-form">
                <Card className="detail-form sm">
                    <CardHeader
                        title={`Delete ${type}?`}
                        action={
                            <IconButton color="primary" aria-label="Close" onClick={handleModalClose}>
                                <Close />
                            </IconButton>
                        }
                    />
                    <CardContent>
                        <Grid container spacing={2} justifyContent='center' alignItems='center' direction="column">
                            <Grid item xs={12}>
                                <Typography>Type &quot;{objectName}&quot; below to confirm:</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    placeholder={objectName}
                                    value={string}
                                    variant="outlined"
                                    onChange={handleString()}
                                    margin='dense'
                                    required autoFocus
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions className="flex-end">
                        <Button
                            startIcon={<Delete />}
                            variant="contained"
                            className={objectName == string ? 'delete-color' : ''}
                            onClick={handleDelete}
                            disabled={objectName == string ? false : true}
                        >
                            Delete
                        </Button>
                    </CardActions>
                </Card>
            </div>
        </Modal>
    )
};

export default TypeConfirmDelete;

import * as React from 'react';
import { Avatar, Chip, Link, Tooltip, Typography } from '@material-ui/core';
import { ArrowDownward } from '@material-ui/icons';
import _ from 'lodash';

import { LineItemDetailWithInvoice, SelectOption, Study, StudyUser, User } from '../../@types';
import { MONTHS, shortUserString, studyToLocalDate } from '../Utils/utils';
import { NumberFormatter } from '../Utils/NumberFormatter';


export const renderAccountCell = (accountName: string, accountId: number, history: any, canViewAccountDetails: boolean) => { //
    return (canViewAccountDetails ?
        <Link className='cursor-pointer sort-field' onClick={() => history.push(`/manage/account/${accountId}`)}>
            {accountName}
        </Link> :
        <Typography variant='body2' className='sort-field'>
            {accountName}
        </Typography>
    );
}

export const renderInvoiceCell = (invoiceNo: string, invoiceId: number, history: any, canViewInvoiceDetails: boolean, createdDate?: Date) => {
    const invoiceCell = () => {
        if (canViewInvoiceDetails) {
            const InvoiceLink = (
                <Link className='cursor-pointer sort-field' onClick={() => history.push(`/invoicing/${invoiceId}`)}>
                    {invoiceNo}
                </Link>
            )
            if (createdDate) {
                const temp = new Date(createdDate)
                const tooltip = (
                    <Typography variant='body2'>
                        Created on {MONTHS[temp.getMonth()] + ' ' + temp.getUTCDate() + ', ' + temp.getFullYear()}
                    </Typography>
                )
                return (
                    <Tooltip arrow title={tooltip}>
                        {InvoiceLink}
                    </Tooltip>
                )
            }
            return InvoiceLink
        }
        return (
            <Typography className='sort-field'>
                {invoiceNo}
            </Typography>
        )
    }

    return invoiceCell();
}

export const renderStudyCell = (studyName: string, studyId: number, history: any) => {
    return (
        <Link className='cursor-pointer sort-field' onClick={() => history.push(`/study/${studyId}`)}>
            {studyName}
        </Link>
    )
}

export const renderVisitCost = (currType: string, actual: number, proposed: number, override: number, final: number) => {
    let currValue = -1;
    switch (currType) {
        case 'actual':
            currValue = actual;
            break;
        case 'proposed':
            currValue = proposed;
            break;
        case 'final':
            currValue = final;
            break;
        default:
            break;
    }
    const overrideCostExists = currType == 'final' && override;
    const actualCostIsFinal = currType == 'actual' && actual > proposed && !override;
    const proposedCostIsFinal = currType == 'proposed' && actual <= proposed && !override;
    if (currType == 'final' && overrideCostExists && override < actual && override < proposed) {
        return (
            <div className='row flex-end'>
                <Tooltip arrow title="The manually entered final cost value is lower than the visit's actual and proposed cost. 
                    If this was a mistake, the overrided cost can be re-entered or removed by editing the visit."
                >
                    <ArrowDownward className='low-value-alert' />
                </Tooltip>
                <NumberFormatter currency value={currValue} className='current-final-cost sort-field' />
            </div>
        )
    }
    if (overrideCostExists || actualCostIsFinal || proposedCostIsFinal) {
        return (
            <NumberFormatter currency value={currValue} className='current-final-cost sort-field' />
        )
    }
    return (
        <NumberFormatter currency value={currValue} className='sort-field' />
    )
}

export const renderDescriptionCell = (obj: LineItemDetailWithInvoice, setClickedObject: React.Dispatch<React.SetStateAction<LineItemDetailWithInvoice | null>>, setViewSourceOpen: React.Dispatch<React.SetStateAction<boolean>>, viewSourceOpen: boolean) => {
    return (
        <Link className='cursor-pointer sort-field' onClick={() => {
            setViewSourceOpen(true)
            setClickedObject(obj)
        }}>
            {obj.description}
        </Link>
    )
}

// Helper method for renderEmployeeChips to see if the user is still on the study.
// If they are not on the studythey will not have a letter placed in their avatar for their role
const handleExistsOnStudy = (studyUsers: StudyUser[], userId: number) => {
    return _.find(studyUsers, ['userId', userId])!;
}

// Helper method for renderEmployeeChips to retrieve the first letter of the user's role to place in their avatar
const handleGetRoleInitial = (allReferenceRoles: SelectOption<number>[], studyUsers: StudyUser[], userId: number) => {
    const foundRole = _.find(allReferenceRoles, ['value', handleExistsOnStudy(studyUsers, userId).roleIds![0]])!;
    if (foundRole) {
        return foundRole.label.charAt(0);
    } else {
        return '';
    }
}

// Helper methods to determine the chips and avatar color of the employee's chip
const handleDetermineChipColor = (roleInitial: string) => {
    if (roleInitial === 'C') {
        // Coordinator
        return 'ept-coordinator-chip';
    } else if (roleInitial === 'P') {
        // Provider
        return 'ept-provider-chip';
    } else {
        return '';
    }
}

const handleDetermineAvatarColor = (roleInitial: string) => {
    if (roleInitial === 'C') {
        // Coordinator
        return 'ept-coordinator-avatar';
    } else if (roleInitial === 'P') {
        // Provider
        return 'ept-provider-avatar';
    } else {
        return '';
    }
}

export const renderEmployeeChip = (allReferenceRoles: SelectOption<number>[], user: StudyUser, studyUsers: StudyUser[],
    history: any, canViewUserStats: boolean
) => {
    if (handleExistsOnStudy(studyUsers, user.userId) != null) {
        return (
            <Tooltip
                arrow title={canViewUserStats ? 'View employee\'s statistics' : ''}
                key={`user-chip-${user.userId}`}
            >
                <Chip
                    className={handleDetermineChipColor(handleGetRoleInitial(allReferenceRoles, studyUsers, user.userId))}
                    label={user.userName}
                    avatar={
                        <Avatar className={handleDetermineAvatarColor(handleGetRoleInitial(allReferenceRoles, studyUsers, user.userId))}>
                            {handleGetRoleInitial(allReferenceRoles, studyUsers, user.userId)}
                        </Avatar>
                    }
                    onClick={() => canViewUserStats ? history.push(`/manage/user/${user.userId}/statistics`) : null}
                    clickable={canViewUserStats}
                />
            </Tooltip>
        )
    } else {
        return (
            <Tooltip title={canViewUserStats ? 'View employee&apos;s statistics' : ''} arrow>
                <Chip
                    key={`user-chip-${user.userId}`}
                    className='chip'
                    label={user.userName}
                    onClick={() => canViewUserStats ? history.push(`/manage/user/${user.userId}/statistics`) : null}
                    clickable={canViewUserStats}
                />
            </Tooltip>
        )
    }
}

export interface DataRowHeader {
    id: number;
    name: string;
    object: any;
}

export function studyToDataRowHeader(study: Study): DataRowHeader {
    return ({
        id: study.id ?? -1,
        name: study.name,
        object: studyToLocalDate(study)
    });
}

export function userToDataRowHeader(user: User): DataRowHeader {
    return ({
        id: user.id ?? -1,
        name: shortUserString({
            id: user.id ?? -1,
            firstName: user.firstName,
            middleName: user.middleName,
            lastName: user.lastName,
            active: user.active ?? true
        }, true),
        object: user
    });
}

export interface DataRow<T> {
    [key: number]: T
}
type Grid<T> = DataRow<DataRow<T>>;
export class DataGrid<T> {
    grid: Grid<T>;

    constructor(grid?: Grid<T>) {
        this.grid = grid ?? {};
    }

    getByIndex (row: number, col: number): T | undefined {
        return (this.grid[row] && this.grid[row][col]) ?? undefined;
    }

    setByIndex (row: number, col: number, val: T): void {
        this.grid[row] = {
            ...this.grid[row],
            [col]: val
        }
    }
}
import * as React from 'react';

import { Link, LinkProps } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';
import { AppBar, Button, Toolbar } from '@material-ui/core';

import logo from '../../../media/logo/logo-180.png';

export const AdapterLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
    (props, ref) => <Link innerRef={ref as any} {...props} />
);

interface Props {
    linkToHomepage?: boolean
}

const HomepageHeader: React.FC<Props> = props => {

    const [logoVisible, setlogoVisible] = React.useState('hide-logo')
    const [headerStyle, setHeaderStyle] = React.useState('transparent-bg')

    const fullLogo = (
        <>
            <div className='logo-div'>
                <img src={logo} alt="CRAFT Logo Image" className='logo' />
            </div>
            <div className='text-logo'>CRAFT</div>
        </>
    )

    React.useEffect(() => {
        const handleScroll = () => {
            const showLogo = window.scrollY > 200;
            const solidHeader = window.scrollY > 200;

            if (showLogo)
                setlogoVisible('')
            else
                setlogoVisible('hide-logo')

            if (solidHeader)
                setHeaderStyle('solid-bg')
            else
                setHeaderStyle('transparent-bg')
        }
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <div className='homepage-header'>
            <AppBar className={'app-bar ' + headerStyle} position='fixed'>
                <Toolbar>
                    {props.linkToHomepage ?
                        <Link className='full-logo-div' to='/'>{fullLogo}</Link> :
                        <ScrollTo className={'full-logo-div ' + logoVisible}
                            to='homepage-start'
                            spy={true}
                            smooth={true}
                        >
                            {fullLogo}
                        </ScrollTo>
                    }
                    <div className='top-right'>
                        <Button className='animated-button btn-color'
                            component={AdapterLink}
                            to='/contact'
                            disableRipple
                        >
                            Contact Us
                        </Button>
                        <Button className='animated-button outline btn-color outline-color'
                            component={AdapterLink}
                            to='/login'
                            disableRipple
                        >
                            Login
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    )
}

export default HomepageHeader;
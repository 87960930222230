import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, Paper, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { AccountSummary, InvoiceStatus, InvoiceStatusCounts } from '../../../@types';
import { handleErrorResponse } from '../../../service/utils';
import { getAccountSummaries } from '../../../service/Account/account';
import usePermissions from '../../../common/hooks/usePermissions';

import SearchBar from '../../../common/SearchBar/SearchBar';
import AccountingModal from '../../../common/Invoicing/AccountingModal';
import StatusChip from '../../../common/Invoicing/StatusChip';
import Loading from '../../../common/Routes/Loading';
import EditablePagingTable, { TableColumn } from '../../../common/DataTable/EditablePagingTable';
import FilterSortButton from '../../../common/FilterSortButton/FilterSortButton';

interface State {
    objects: AccountSummary[];
    filters: { internal: boolean; external: boolean; }
}

const initialState: State = {
    objects: [],
    filters: {
        internal: true,
        external: true
    }
}

interface Action {
    type: 'objectPage' | 'filters';
    payload?: any;
}

interface TableData {
    id: number;
    name: JSX.Element;
    receivable: JSX.Element;
    totalUnsent: number;
    totalUnpaid: number;
    payable: JSX.Element;
}

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'objectPage':
            return {
                ...state,
                objects: action.payload.list,
            }
        case 'filters':
            return {
                ...state,
                filters: action.payload,
            };
        default:
            return state;
    }
}

const AccountList: React.FC = props => {
    const history = useHistory();
    const dispatch = useDispatch();

    const defaultAccount: AccountSummary = {
        accountId: 0,
        accountEnteredName: '',
        internal: false,
        studyName: null,
        receivableCounts: null,
        payableCounts: null
    };
    const [accountSummaries, setAccountSummaries] = React.useState<AccountSummary[]>([defaultAccount]);
    const [query, setQuery] = React.useState<string>('');
    const [state, dispatchPage] = React.useReducer(reducer, initialState);
    const canViewAccounts = usePermissions('API_ACCOUNT_GET_ACCOUNT_SUMMARIES');
    const canAddAccounts = usePermissions('API_ACCOUNT_CREATE');
    const {
        objects,
        filters,
    } = state;

    const columns: TableColumn[] = [{
        type: 'name',
        displayValue: 'Account',
        align: 'left',
        format: 'jsx-element'
    }, {
        type: 'receivable',
        displayValue: 'Outstanding Receivable',
        align: 'left',
        format: 'jsx-element'
    }, {
        type: 'totalUnsent',
        displayValue: 'Total Not Sent',
        align: 'right',
        format: 'currency-US',
    }, {
        type: 'totalUnpaid',
        displayValue: 'Total Not Paid',
        align: 'right',
        format: 'currency-US',
    }, {
        type: 'payable',
        displayValue: 'Outstanding Payable',
        align: 'left',
        format: 'jsx-element'
    }];

    React.useEffect(() => {
        getAccountSummaries()
            .then(res => {
                setAccountSummaries(res.data);
                dispatchPage({ type: 'objectPage', payload: { list: res.data } });
            }).catch(err => {
                setAccountSummaries([]);
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve list of Accounts: '
                });
            });
    }, []);

    React.useEffect(() => {
        if (accountSummaries.length > 0) {
            let filteredList: AccountSummary[] = accountSummaries.filter(account =>
                (filters.internal && account.internal)
                || (filters.external && !account.internal)
            );

            if (Number(query) !== 0) {
                filteredList = filteredList.filter(account =>
                    account.accountEnteredName.toLowerCase().indexOf(query.toLowerCase()) !== -1
                );
            }
            dispatchPage({
                type: 'objectPage',
                payload: {
                    list: filteredList,
                    total: filteredList.length
                }
            });
        }
    }, [accountSummaries, filters, query]);

    const handleChangeFilters = (internal: boolean, external: boolean) => {
        dispatchPage({ type: 'filters', payload: { internal: internal, external: external } });
    };

    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const handleAddModalOpen = () => {
        setAddModalOpen(true);
    }

    const displayError = (message: string) => {
        return (
            <div className={'account-list-error'}>
                <Paper className={'paper'}>
                    <div className={'error-text'}>
                        <Typography>
                            {message}
                        </Typography>
                    </div>
                </Paper>
            </div>
        );
    };

    const outstandingStatusArr: InvoiceStatus[] = ['NOT_SENT', 'NOT_PAID', 'PARTIAL'];
    const renderStatusChips = (invoiceCounts: InvoiceStatusCounts) => (
        <div className='invoice-status-button sort-field'>
            {(outstandingStatusArr).map((status: InvoiceStatus, idx: number) => {
                if (invoiceCounts[status] && invoiceCounts[status]?.count !== 0)
                    return (
                        <StatusChip count={invoiceCounts[status]?.count} status={status} bottomMargin width='small' key={status + '-' + idx} />
                    )
            })}
        </div>
    );

    const renderAccountName = (accountId: number, accountName: string, studyName: string) => {
        return (
            <>
                <div className='double-line-text'>
                    <div className='title sort-field'>
                        {accountName}
                    </div>
                    <div className='subtitle'>
                        {studyName}
                    </div>
                </div>
            </>
        )
    };

    const renderInvoiceCounts = (invoiceCounts: InvoiceStatusCounts | null) => {
        let hasOutstanding = false;
        outstandingStatusArr.forEach((status: InvoiceStatus) => {
            if (invoiceCounts && invoiceCounts[status] && invoiceCounts[status]?.count !== 0) {
                hasOutstanding = true;
                return;
            }
        });
        return hasOutstanding ? renderStatusChips(invoiceCounts!!)
            : <Typography variant='caption' color='textSecondary' className='sort-field'>No Outstanding</Typography>;
    };

    const extractAmount = (invoiceCounts: InvoiceStatusCounts | null, status: 'NOT_SENT' | 'NOT_PAID') => {
        return (invoiceCounts && invoiceCounts[status]) ? invoiceCounts[status]!!.outstandingAmountForStatus : 0;
    };

    const getTableData = (data: AccountSummary[]) => {
        const temp: TableData[] = [];
        data.forEach((obj: AccountSummary) => {
            temp.push({
                id: obj.accountId,
                name: renderAccountName(obj.accountId, obj.accountEnteredName, obj.studyName ?? ''),
                receivable: renderInvoiceCounts(obj.receivableCounts),
                totalUnsent: extractAmount(obj.receivableCounts, 'NOT_SENT'),
                totalUnpaid: extractAmount(obj.receivableCounts, 'NOT_PAID'),
                payable: renderInvoiceCounts(obj.payableCounts)
            });
        });
        return temp;
    };

    const handleRowClick = (id: number) => {
        history.push(`/manage/account/${id}/receivable`)
    }

    const accountListDisplay = () => {
        return (
            <EditablePagingTable
                dataList={getTableData(objects)}
                tableInfoColumns={columns}
                readonly={true}
                handleSubmit={undefined}
                canCreate={false}
                rowClick={handleRowClick}
            />
        );
    }

    let returnedContent: JSX.Element;
    if (!canViewAccounts)
        returnedContent = displayError('You do not have permission to view accounts. Please contact your division or a system administrator for more information.');
    else if (accountSummaries.length === 0 && canAddAccounts)
        returnedContent = displayError('There are currently no accounts to display. Create an account by clicking the "Add Account" button above.');
    else if (accountSummaries.length === 0)
        returnedContent = displayError('There are currently no accounts to display.');
    else if (objects.length === 0)
        returnedContent = displayError('There are no accounts to display with the filters applied above. Modify the filters to view more results.');
    else if (accountSummaries.length === 1 && accountSummaries[0].accountId === 0)
        returnedContent = <Loading />
    else
        returnedContent = accountListDisplay();

    return (
        <>
            <div className="page-heading">
                <Typography className="title">Account Management</Typography>
            </div>
            <div className="page-body margin-auto">
                <div className="aligned-row">
                    <div className="left">
                        <div style={{ paddingRight: '10px' }}>
                            <SearchBar query={query} setQuery={setQuery} />
                        </div>
                        <FilterSortButton
                            sections={[{
                                title: 'Filter By Account Type',
                                tooltip: 'Filter by internal and external account types.',
                                body: (
                                    <FormControl>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox checked={filters.internal} onChange={() => handleChangeFilters(!filters.internal, filters.external)} name="internalFilter" />}
                                                label="Internal Accounts"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={filters.external} onChange={() => handleChangeFilters(filters.internal, !filters.external)} name="externalFilter" />}
                                                label="External Accounts"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                )
                            }]}
                        />
                    </div>
                    <div className="right">
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Add />}
                            onClick={handleAddModalOpen}
                            disabled={!canAddAccounts}
                        >
                            Add Account
                        </Button>
                    </div>
                </div>
                <div className="spacer" />
                {returnedContent}
            </div>
            <AccountingModal open={addModalOpen} setOpen={setAddModalOpen} management />
        </>
    );
}

export default AccountList;

import * as React from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { Card, CardActions, CardContent, CardHeader, Grid, IconButton, InputAdornment, Modal } from '@material-ui/core';
import { Close, Save } from '@material-ui/icons';
import { BForm, BSubmit, BTextField } from 'mui-bueno';

import { Contract } from '../../../../@types';
import { INT_MAX } from '../../../../common/Utils/utils';
import { NumberFormatter } from '../../../../common/Utils/NumberFormatter';
import { showSuccessSnackbar } from '../../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../../service/utils';
import { updateContract } from '../../../../service/Contract/contracts';


interface Props {
    open: boolean;
    setOpen: any;
    contract: Contract;
    setContract: any;
}

const schema = yup.object<Contract>().shape({
    overhead: yup.number().typeError('A number is required').required('Overhead is required').min(0, 'Min value: 0').max(100, 'Max value: 100'),
    holdback: yup.number().typeError('A number is required').required('Holdback is required').min(0, 'Min value: 0').max(100, 'Max value: 100'),
    coordinatorCost: yup.number().typeError('A number is required').required('Coordinator Cost is required').min(0, 'Min value: 0'),
    providerCost: yup.number().typeError('A number is required').required('Provider Cost is required').min(0, 'Min value: 0'),
    projectedSubjects: yup.number().typeError('A number is required').required('Projected Subjects is required').integer('Whole number required').min(0, 'Min value: 0').max(INT_MAX, 'Max value: ' + INT_MAX),
    perSubjectDuration: yup.number().typeError('A number is required').required('Per Subject Duration is required').integer('Whole number required').min(0, 'Min value: 0').max(INT_MAX, 'Max value: ' + INT_MAX),
    coordinatorExtraTime: yup.number().typeError('A number is required').required('Coordinator Extra Time is required').integer('Whole number required').min(0, 'Min value: 0').max(INT_MAX, 'Max value: ' + INT_MAX),
    providerExtraTime: yup.number().typeError('A number is required').required('Provider Extra Time is required').integer('Whole number required').min(0, 'Min value: 0').max(INT_MAX, 'Max value: ' + INT_MAX),
    coordinatorAdminTime: yup.number().typeError('A number is required').required('Coordinator Admin Time is required').integer('Whole number required').min(0, 'Min value: 0').max(INT_MAX, 'Max value: ' + INT_MAX),
    providerAdminTime: yup.number().typeError('A number is required').required('Provider Admin Time is required').integer('Whole number required').min(0, 'Min value: 0').max(INT_MAX, 'Max value: ' + INT_MAX)
});

const ContractEditDetails: React.FC<Props> = props => {
    const { open, setOpen, contract, setContract } = props;

    const dispatcher = useDispatch();

    const handleSubmit = async (data: Contract, { setErrors }: FormikHelpers<Contract>) => {
        updateContract({ ...data, id: contract.id })
            .then(res => {
                dispatcher(showSuccessSnackbar('Contract details updated'));
                setContract(res.data);
                handleModalClose();
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    setStatus: setErrors,
                    prefix: 'Could not update Contract details: '
                })
            });
    }

    const handleModalClose = () => {
        setOpen(false);
    }

    const initialValues: Contract = {
        id: contract.id,
        studyId: contract.studyId,
        sponsorProposal: contract.sponsorProposal,
        negotiatingCost: contract.negotiatingCost,
        actualCost: contract.actualCost,
        finalCost: contract.finalCost,
        overhead: contract.overhead ? contract.overhead : 0,
        holdback: contract.holdback ? contract.holdback : 0,
        coordinatorCost: contract.coordinatorCost ? contract.coordinatorCost : 0,
        providerCost: contract.providerCost ? contract.providerCost : 0,
        projectedSubjects: contract.projectedSubjects ? contract.projectedSubjects : 0,
        perSubjectDuration: contract.perSubjectDuration ? contract.perSubjectDuration : 0,
        coordinatorExtraTime: contract.coordinatorExtraTime ? contract.coordinatorExtraTime : 0,
        providerExtraTime: contract.providerExtraTime ? contract.providerExtraTime : 0,
        coordinatorAdminTime: contract.coordinatorAdminTime ? contract.coordinatorAdminTime : 0,
        providerAdminTime: contract.providerAdminTime ? contract.providerAdminTime : 0,
        signed: contract.signed,
        created: contract.created!,
        updated: contract.updated!
    }

    return (
        <Modal
            open={open}
            onClose={handleModalClose}
        >
            <div className="modal-form">
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={schema}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    <BForm>
                        <Card className="detail-form sm">
                            <CardHeader
                                title="Edit Contract Details"
                                action={
                                    <IconButton color="primary" aria-label="Close" onClick={handleModalClose}>
                                        <Close />
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                <Grid container spacing={2} justifyContent='center'>
                                    <BTextField
                                        name="overhead"
                                        label="Overhead"
                                        placeholder="Overhead"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            inputComponent: NumberFormatter as any
                                        }}
                                        autoFocus
                                        xs={6} sm={5} noMP
                                    />
                                    <BTextField
                                        name="holdback"
                                        label="Holdback"
                                        placeholder="Holdback"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            inputComponent: NumberFormatter as any
                                        }}
                                        xs={6} sm={5} noMP
                                    />
                                    <BTextField
                                        name="coordinatorCost"
                                        label="Coordinator Cost"
                                        placeholder="e.g., 25"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            endAdornment: <InputAdornment position="end">per hr</InputAdornment>,
                                            inputComponent: NumberFormatter as any
                                        }}
                                        xs={6} sm={5} noMP
                                    />
                                    <BTextField
                                        name="providerCost"
                                        label="Provider Cost"
                                        placeholder="e.g., 25"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            endAdornment: <InputAdornment position="end">per hr</InputAdornment>,
                                            inputComponent: NumberFormatter as any
                                        }}
                                        xs={6} sm={5} noMP
                                    />
                                    <BTextField
                                        name="projectedSubjects"
                                        label="Projected Subjects"
                                        placeholder="Projected Subjects"
                                        InputProps={{
                                            inputComponent: NumberFormatter as any,
                                            inputProps: { integer: true }
                                        }}
                                        xs={6} sm={5} noMP
                                    />
                                    <BTextField
                                        name="perSubjectDuration"
                                        label="Per Subject Duration"
                                        placeholder="e.g., 5"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">months</InputAdornment>,
                                            inputComponent: NumberFormatter as any,
                                            inputProps: { integer: true }
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        xs={6} sm={5} noMP
                                    />
                                    <BTextField
                                        name="coordinatorExtraTime"
                                        label="Coordinator Extra Time"
                                        placeholder="e.g., 15"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">min/assessment</InputAdornment>,
                                            inputComponent: NumberFormatter as any,
                                            inputProps: { integer: true }
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        xs={6} sm={5} noMP
                                    />
                                    <BTextField
                                        name="providerExtraTime"
                                        label="Provider Extra Time"
                                        placeholder="e.g., 15"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">min/assessment</InputAdornment>,
                                            inputComponent: NumberFormatter as any,
                                            inputProps: { integer: true }
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        xs={6} sm={5} noMP
                                    />
                                    <BTextField
                                        name="coordinatorAdminTime"
                                        label="Coordinator Admin Time"
                                        placeholder="e.g., 2"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">hr/patient</InputAdornment>,
                                            inputComponent: NumberFormatter as any,
                                            inputProps: { integer: true }
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        xs={6} sm={5} noMP
                                    />
                                    <BTextField
                                        name="providerAdminTime"
                                        label="Provider Admin Time"
                                        placeholder="e.g., 2"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">hr/patient</InputAdornment>,
                                            inputComponent: NumberFormatter as any,
                                            inputProps: { integer: true }
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        xs={6} sm={5} noMP
                                    />
                                </Grid>
                            </CardContent>
                            <CardActions className="flex-end">
                                <BSubmit
                                    startIcon={<Save />}
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </BSubmit>
                            </CardActions>
                        </Card>
                    </BForm>
                </Formik>
            </div>
        </Modal >
    )
};

export default ContractEditDetails;

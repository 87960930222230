import * as React from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Close, Save } from '@material-ui/icons';
import { Card, CardActions, CardContent, CardHeader, Grid, IconButton, Modal, Typography } from '@material-ui/core';
import { BDatePicker, BForm, BSubmit } from 'mui-bueno';
import { Formik, FormikHelpers } from 'formik';

import { Contract, SignContractWarning } from '../../../../@types';
import { showSuccessSnackbar } from '../../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../../service/utils';
import { getSignContractWarnings, signContract } from '../../../../service/Contract/contracts';


interface Props {
    open: any;
    setOpen: any;
    contract: Contract;
    setContract: any;
    setUpdatedSigned: any;
}

const schema = yup.object<Contract>().shape({
    signed: yup.string().required('Signed date is required'),
});

const ContractSignModal: React.FC<Props> = props => {
    const { open, setOpen, contract, setContract, setUpdatedSigned } = props;

    const [signContractWarning, setSignContractWarning] = React.useState<SignContractWarning>({
        visitWithNoCost: false,
        visitsWithNoTimeFromBaseline: false
    });

    const history = useHistory();
    const dispatcher = useDispatch();

    React.useEffect(() => {
        getSignContractWarnings(contract.id!)
            .then(res => {
                setSignContractWarning(res.data);
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    prefix: 'Could not get sign contract warnings: '
                });
            });
    }, []);

    const handleValidate = (data: Contract) => {
        const errorList: { [k: string]: string } = {};
        schema.validate(data, { abortEarly: false })
            .catch((err: yup.ValidationError) => {
                for (const e of err.inner) {
                    if (e.path) errorList[e.path] = e.message;
                }
            });
        return errorList;
    }

    const handleSubmit = async (data: Contract, { setErrors }: FormikHelpers<Contract>) => {
        let localSignedDateTime = data.signed?? new Date();
        if (data.signed) { localSignedDateTime = new Date(data.signed.toLocaleString() + ' UTC') }
        const localSignedDate : string = localSignedDateTime.toISOString().split('T')[0];
        
        signContract(contract.id!, localSignedDate)
            .then(() => {
                dispatcher(showSuccessSnackbar('Contract successfully signed'));
                setContract({...data, signed: localSignedDateTime});
                setUpdatedSigned(true);
                handleModalClose();
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    setStatus: setErrors,
                    prefix: 'Could not sign Contract: '
                })
            });
    }

    const handleModalClose = () => {
        setOpen(false);
    }

    return (
        <Modal
            open={open}
            onClose={handleModalClose}
        >
            <div className="modal-form">
                <Formik
                    initialValues={{ ...contract }}
                    onSubmit={handleSubmit}
                    validate={handleValidate}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    <BForm>
                        <Card className="detail-form sm">
                            <CardHeader
                                title="Sign Contract"
                                action={
                                    <IconButton color="primary" aria-label="Close" onClick={handleModalClose}>
                                        <Close />
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                <Grid container spacing={0} justifyContent='center'>
                                    <Grid item xs={10} className='warning-text'>
                                        <Typography className='title'>
                                            ATTENTION
                                        </Typography>
                                        <Typography className='middle'>
                                            Once signed, this contract can no longer be updated or deleted.
                                            Any further changes must be made by going to the &quot;Addendums&quot;
                                            tab within the study details and creating an Addendum Contract.
                                            If there are any issues listed below that require attention, review the items as necessary.
                                        </Typography>
                                        {(signContractWarning.visitWithNoCost || signContractWarning.visitsWithNoTimeFromBaseline) &&
                                            <div>
                                                <Typography className='title'>
                                                    Possible Issues
                                                </Typography>
                                                <ul>
                                                    {signContractWarning.visitWithNoCost &&
                                                        <li>
                                                            There are visits with a sponsor proposal or final cost of $0.00
                                                            <p onClick={() => history.push(`/contract/${contract.id}/schedule/visits`)} className='issue-path'>
                                                                Review Visit Costs
                                                            </p>
                                                        </li>
                                                    }
                                                    {signContractWarning.visitsWithNoTimeFromBaseline &&
                                                        <li>
                                                            There are more than one visit with time from baseline of 0
                                                            <p onClick={() => history.push(`/contract/${contract.id}/schedule/visits`)} className='issue-path'>
                                                                Review Visit Time from baseline
                                                            </p>
                                                        </li>
                                                    }
                                                </ul>
                                            </div>
                                        }
                                    </Grid>
                                    <BDatePicker
                                        name="signed"
                                        label="Signed Date"
                                        placeholder="Date"
                                        required autoFocus
                                        inputVariant='outlined'
                                        xs={10}
                                    />
                                </Grid>
                            </CardContent>
                            <CardActions className="flex-end">
                                <BSubmit
                                    startIcon={<Save />}
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </BSubmit>
                            </CardActions>
                        </Card>
                    </BForm>
                </Formik>
            </div>
        </Modal>
    )
};

export default ContractSignModal;

import * as React from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';

import { Card, CardContent, Typography } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

import { Formik, FormikHelpers } from 'formik';
import { BEmail, BForm, BOption, BSelect, BSubmit, BTextField } from 'mui-bueno';

import Header from '../HomepageHeader/HomepageHeader';
import { ContactUs } from '../../../@types';
import { sendEmailContactUs } from '../../../service/Contact/contact';
import { handleErrorResponse } from '../../../service/utils';
import { showSuccessSnackbar } from '../../../modules/messageSnackbarReducer';

const contactUsSchema = yup.object<ContactUs>().shape({
    messageType: yup.string().required(),
    institutionName: yup.string().required('Institution is required'),
    email: yup.string().email('Must be in email format').required('Email is required'),
});

const Contact: React.FC = () => {
    const dispatch = useDispatch();
    const [bannerVisible, setBannerVisible] = React.useState(false);
    const [cardsVisible, setCardsVisible] = React.useState(false);

    const contactInfo = [
        {
            id: 0,
            type: 'Email:',
            text: 'craftsupport@simoncomputing.com'
        },
        {
            id: 1,
            type: 'Phone:',
            text: '(703) 914-5454'
        },
        {
            id: 2,
            type: 'Address',
            text: '5350 Shawnee Rd, Alexandria, VA 22312'
        },

    ]
    const messageOptions: BOption<string>[] = [
        { value: 'Demo Request', label: 'Request a Demo' },
        { value: 'Technical Support', label: 'Technical Support' },
        { value: 'Comment/Question', label: 'Comment/Question' },
    ]

    const initialValues: ContactUs = {
        messageType: messageOptions[0].value,
        institutionName: '',
        pointOfContact: '',
        email: '',
        phone: '',
        message: '',
    };

    React.useEffect(() => {
        setBannerVisible(true);
        setCardsVisible(true);
    }, [])

    const handleSubmit = (data: ContactUs, { setErrors, resetForm }: FormikHelpers<ContactUs>) => {
        sendEmailContactUs(data)
            .then(res => {
                resetForm();
                dispatch(showSuccessSnackbar('Email Sent'));
            })
            .catch(err => {
                handleErrorResponse(err, dispatch, {
                    setStatus: setErrors,
                    prefix: 'Could not send email: '
                });
            });
    }

    return (
        <div className='contact-page'>
            <Header linkToHomepage />
            <div className='content'>
                <div className='banner-text'>
                    <Typography className={'title animate-right ' + (bannerVisible ? 'is-visible' : '')}>Contact Us</Typography>
                    <Typography className={'tag-line animate-left ' + (bannerVisible ? 'is-visible' : '')}>Have a question?</Typography>
                </div>
                <div className='card-container'>
                    <Card className={'card animate-up ' + (cardsVisible ? 'is-visible' : '')}>
                        <CardContent className='card-content'>
                            <Typography className='card-title '>Contact Information</Typography>
                            <div className='contact-info-div'>
                                <div className='type-column'>
                                    {contactInfo.map((info) => (
                                        <Typography key={`type-${info.id}`} className='card-text'>{info.type}</Typography>
                                    ))}
                                </div>
                                <div className='text-column'>
                                    {contactInfo.map((info) => (
                                        <Typography key={`text-${info.id}`} className='card-text'>{info.text}</Typography>
                                    ))}
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    <Card className={'card animate-up ' + (cardsVisible ? 'is-visible' : '')}>
                        <CardContent className='card-content'>
                            <Typography className='card-title'>Send Us a Message</Typography>
                            <div className='form-div'>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={handleSubmit}
                                    validationSchema={contactUsSchema}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                >
                                    <BForm>
                                        <BSelect
                                            name='messageType'
                                            label='How can we help?'
                                            options={messageOptions}
                                            required
                                        />
                                        <BTextField
                                            name='institutionName'
                                            label='Institution'
                                            placeholder='Enter your institution name'
                                            required
                                            xs={6}
                                        />
                                        <BTextField
                                            name='pointOfContact'
                                            label='Point of Contact'
                                            placeholder='Who should we contact?'
                                            required
                                            xs={6}
                                        />
                                        <BEmail
                                            name='email'
                                            label='Email'
                                            placeholder='Enter your email'
                                            required
                                            xs={6}
                                        />
                                        <BTextField
                                            name='phone'
                                            label='Phone'
                                            placeholder='Enter your phone number'
                                            autoFormat
                                            format='(999) 999-9999'
                                            xs={6}
                                        />
                                        <BTextField
                                            name='message'
                                            label='Message'
                                            placeholder='Enter your message'
                                            multiline
                                            rows={3}
                                        />
                                        <div className='btn-div'>
                                            <BSubmit
                                                startIcon={<SendIcon />}
                                                variant='contained'
                                                color='primary'
                                                className='btn'
                                            >
                                                Send
                                            </BSubmit>
                                        </div>
                                    </BForm>
                                </Formik>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default Contact;
import * as React from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { Card, CardActions, CardContent, Grid, Modal } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { BForm, BSubmit, BTextField } from 'mui-bueno';

import { Simulator, Study } from '../../../@types';
import { showSuccessSnackbar } from '../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../service/utils';
import { updateSimulator } from '../../../service/Simulator/simulator';
import CardTitle from '../../../common/Card/CardTitle';

interface Props {
    open: boolean;
    setOpen: any;
    simulator: any;
    setSimulator: any;
}

const schema = yup.object<Study>().shape({
    name: yup.string().required('Subtitle is required'),
    description: yup.string(),
});

const SimulatorEditDetailsModal: React.FC<Props> = props => {
    const { open, setOpen, simulator, setSimulator } = props;

    const dispatcher = useDispatch();

    const handleValidate = (data: Simulator) => {
        const errorList: { [k: string]: string } = {};
        schema.validate(data, { abortEarly: false })
            .catch((err: yup.ValidationError) => {
                for (const e of err.inner) {
                    if (e.path) errorList[e.path] = e.message;
                }
            });
        return errorList;
    }

    const handleSubmit = async (data: Simulator, { setErrors }: FormikHelpers<Simulator>) => {
        updateSimulator(data, null)
            .then(res => {
                dispatcher(showSuccessSnackbar(`Simulator for "${simulator.studyName}" updated`));
                setSimulator(res.data);
                setOpen(false)
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    setStatus: setErrors,
                    prefix: 'Could not update Simulator info: '
                })
            });
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
        >
            <div className="modal-form">
                <Formik
                    initialValues={{ ...simulator }}
                    onSubmit={handleSubmit}
                    validate={handleValidate}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    <BForm>
                        <Card className="detail-form sm">
                            <CardTitle title='Edit Simulator Details' handleClose={() => setOpen(false)} />
                            <CardContent>
                                <Grid container spacing={2} justifyContent='center'>
                                    <BTextField
                                        name='subtitle'
                                        label='Subtitle'
                                        placeholder='Subtitle...'
                                        required autoFocus
                                        xs={12} sm={10} noMP
                                    />
                                    <BTextField
                                        name='description'
                                        label='Description'
                                        placeholder='Enter a description...'
                                        multiline minRows={3}
                                        xs={12} sm={10} noMP
                                    />
                                </Grid>
                            </CardContent>
                            <CardActions className="space-between">
                                {/* Uncomment Delete button once concept of simulation deletion is finalized */}
                                {/* {(permissionDelete && !signed) ?
                                    <BButton
                                        startIcon={<Delete />}
                                        variant="contained"
                                        className="delete-color"
                                        onClick={() => {
                                            handleModalClose();
                                            setDelete(true);
                                        }}
                                    >
                                        Delete
                                    </BButton> : <div />} */}
                                <div />
                                <BSubmit
                                    startIcon={<Save />}
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </BSubmit>
                            </CardActions>
                        </Card>
                    </BForm>
                </Formik>
            </div>
        </Modal>
    )
};

export default SimulatorEditDetailsModal;

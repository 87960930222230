import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { Card, CardActions, CardContent, CardHeader, Chip, Grid, IconButton, Modal } from '@material-ui/core';
import { Check, Close, Save } from '@material-ui/icons';
import { BForm, BSelect, BSubmit } from 'mui-bueno';

import { DivisionUser, SelectOption } from '../../../@types';
import { showSuccessSnackbar } from '../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../service/utils';
import { assignUserToDivision } from '../../../service/Management/divisions';


interface Props {
    open: any;
    setOpen: any;
    divisionId: number;
    editDivisionUser: DivisionUser;
    saveEditedDivisionUser: any;
    allReferenceRoles: SelectOption<number>[];
}

const DivisionUserEdit: React.FC<Props> = props => {
    const { open, setOpen, divisionId, editDivisionUser, saveEditedDivisionUser, allReferenceRoles } = props;
    const dispatcher = useDispatch();

    const handleSubmit = async (data: DivisionUser, { setErrors }: FormikHelpers<DivisionUser>) => {
        assignUserToDivision(divisionId, data)
            .then(res => {
                dispatcher(showSuccessSnackbar(`User ${data.userName} updated`));
                saveEditedDivisionUser(data);
                handleModalClose();
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    setStatus: setErrors,
                    prefix: 'Could not assign User to Division: '
                });
            });
    };

    const handleModalClose = () => {
        setOpen(false);
    }

    return (
        <Modal
            open={open}
            onClose={handleModalClose}
        >
            <div className="modal-form">
                <Formik
                    initialValues={editDivisionUser}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    <BForm>
                        <Card className="detail-form sm">
                            <CardHeader
                                title={`Edit Division Role for ${editDivisionUser.userName}`}
                                action={
                                    <IconButton color="primary" aria-label="Close" onClick={handleModalClose}>
                                        <Close />
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                <Grid container spacing={2} justifyContent='center'>
                                    <Grid item xs={12} >
                                        <BSelect
                                            name="roleIds"
                                            label="Roles"
                                            options={allReferenceRoles}
                                            multiple
                                            selectedOptionIcon={<Check />}
                                            renderValue={(selected: unknown) => {
                                                return (selected as number[]).map(value => {
                                                    const option = allReferenceRoles[value];
                                                    return (
                                                        option && <Chip key={`role-chip-${option.value}`} label={option.label} className="chip" size={'small'} />
                                                    );
                                                });
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions className="flex-end">
                                <BSubmit
                                    startIcon={<Save />}
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </BSubmit>
                            </CardActions>
                        </Card>
                    </BForm>
                </Formik>
            </div>
        </Modal>
    )
};
export default DivisionUserEdit;



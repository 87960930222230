
/*** CONSTANTS ***/
export const LOGINJWT = 'loginReducer/LOGINJWT';
export type LOGINJWT = typeof LOGINJWT;

export const LOGOUTJWT = 'loginReducer/LOGOUTJWT';
export type LOGOUTJWT = typeof LOGOUTJWT;

/*** ACTIONS ***/
export interface LoginJwt {
    type: LOGINJWT;
    payload: LoginJwtState;
}

export interface LogoutJwt {
    type: LOGOUTJWT;
}

export type LoginAction = LoginJwt | LogoutJwt;

/*** ACTION CREATORS ***/
export const loginJwt = (loginJwtState: LoginJwtState): LoginJwt => {
    return {
        type: LOGINJWT,
        payload: loginJwtState
    };
};

export const clearJWT = (): LogoutJwt => {
    return {
        type: LOGOUTJWT
    };
};

/*** REDUCER ***/
export interface LoginJwtState {
    accessToken: string;
}

export const initialState: LoginJwtState = {
    accessToken: ''
};

export const reducer = (
    state: LoginJwtState = initialState,
    action: LoginAction
): LoginJwtState => {
    if (action.type === LOGINJWT) {
        return {
            ...state,
            accessToken: action.payload.accessToken
        };
    } else if (action.type === LOGOUTJWT) {
        return {
            ...state,
            accessToken: ''
        }
    }
    /* istanbul ignore next */
    else {
        return state;
    }
};
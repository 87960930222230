import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Typography } from '@material-ui/core';

import { DivisionTree, User } from '../../@types';
import { Store } from '../../modules/rootReducer';
import usePermissions from '../../common/hooks/usePermissions';
import { handleErrorResponse } from '../../service/utils';
import { getCurrentUserEffortLogStatus } from '../../service/Management/effort-logs';

import GoToEffortLogs from './Widgets/EffortLog/GoToEffortLogs';
import StudyQuickAccess from './Widgets/QuickAccess/StudyQuickAccess/StudyQuickAccess';
import StudyInvoiceWidget from './Widgets/Invoice/StudyInvoiceWidget';
import HealthWidget from './Widgets/Health/HealthWidget';
import FilterSortButton from '../../common/FilterSortButton/FilterSortButton';
import FilterByDivision from '../Invoicing/Invoice/FilterByDivision';


const Dashboard: React.FC = () => {
    const dispatch = useDispatch();

    const [division, setDivision] = React.useState<DivisionTree>({
        id: 0,
        name: 'All Divisions',
        updatable: false,
    })

    const { firstName, lastName, id } = useSelector<Store, User>(
        store => store.loginReducer.user
    );

    // Permissions required for the various widgets
    const showStudyInvoicesNotInvoiced = usePermissions('API_INVOICE_GET_AMOUNT_NOT_INVOICED_ON_STUDIES');
    const showRestrictedStudyInvoicesNotInvoiced = usePermissions('API_INVOICE_GET_AMOUNT_NOT_INVOICED_ON_RESTRICTED_STUDIES');
    const showStudyInvoicesNotPaid = usePermissions('API_INVOICE_GET_AMOUNT_NOT_PAID_ON_STUDIES');
    const showRestrictedStudyInvoicesNotPaid = usePermissions('API_INVOICE_GET_AMOUNT_NOT_PAID_ON_RESTRICTED_STUDIES');
    const showGetEffortLogStatus = usePermissions('API_EFFORT_LOG_GET_CURRENT_USER_EFFORT_LOG_STATUS');
    const showStudyHealth = usePermissions('API_DIVISION_GET_DIVISION_HEALTH');

    const hasAnyPermission = showStudyInvoicesNotInvoiced || showRestrictedStudyInvoicesNotInvoiced ||
        showStudyInvoicesNotPaid || showRestrictedStudyInvoicesNotPaid || showGetEffortLogStatus || showStudyHealth;

    const date = (new Date()).toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });


    const [hasActive, setHasActive] = React.useState<boolean>(false);
    const [missingLogs, setMissingLogs] = React.useState<number>(0);

    React.useEffect(() => {
        if (showGetEffortLogStatus) {
            getCurrentUserEffortLogStatus()
                .then(res => {
                    setHasActive(res.data.assignedActiveStudy)
                    setMissingLogs(res.data.missingEffortLogCount)
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not get current user\'s effort log status: '
                    });
                });
        }
    }, []);

    const comingSoon = (
        <div className="dashboard-body page-body max-xl" >
            <div className='flex-center full-size'>
                <div className='flex-center column coming-soon'>
                    <div>More Dashboard Modules</div>
                    <div>Coming Soon</div>
                </div>
            </div>
        </div>
    )

    // Selecting the division for each graph
    const handleChangeDivision = (div: DivisionTree): void => {
        setDivision(div);
    };


    const divisionSelect = (
        <FilterSortButton
            sections={[{
                title: 'Filter By Division',
                tooltip: 'Filter dashboard content by division or sub-division. Select "All Divisions" option to show content from every division.',
                body: (
                    <FilterByDivision
                        setChosenDivision={handleChangeDivision}
                        withinCollapsiblePanel={false}
                        withinMenu
                        restricted={!showStudyInvoicesNotInvoiced}
                    />
                )
            }]}
            selectMode={division?.name}
        />
    )
    return (
        <div className="page">
            <div className="page-content">
                <div className="page-heading aligned-row dashboard-header">
                    <div className="left">
                        <Typography className='welcome-text name'>
                            Welcome, {firstName} {lastName}
                        </Typography>
                        <Typography className='welcome-text date'>
                            Here is your CRAFT snapshot for {date}.
                        </Typography>
                    </div>
                    <div className="right">
                        {divisionSelect}
                        {/* <Button
                            variant="outlined"
                            color="primary"
                            className='outlined-button grey'
                            startIcon={<Build />}
                            onClick={() => { }}
                        >
                            Customize
                        </Button> */}
                    </div>
                </div>
                <div className="dashboard">
                    <div className={hasActive ? 'dashboard-side-bar with-effort-link' : 'dashboard-side-bar'}>
                        {hasActive &&
                            <GoToEffortLogs
                                userId={Number(id)}
                                missingLogs={missingLogs}
                            />
                        }
                        <StudyQuickAccess />
                    </div>
                    {hasAnyPermission ?
                        <div className="dashboard-body">
                            <div className='responsive-filter'>
                                {divisionSelect}
                            </div>
                            <Grid container spacing={3} className="page-body max-xl">
                                {showStudyHealth &&
                                    <Grid item xs={12}>
                                        <HealthWidget division={division} />
                                    </Grid>
                                }
                                {(showRestrictedStudyInvoicesNotInvoiced || showStudyInvoicesNotInvoiced) &&
                                    <Grid item xs={12} lg={(showRestrictedStudyInvoicesNotPaid || showStudyInvoicesNotPaid) ? 6 : 12}>
                                        <StudyInvoiceWidget division={division} type='notInvoiced'
                                            restricted={showRestrictedStudyInvoicesNotInvoiced && !showStudyInvoicesNotInvoiced}
                                        />
                                    </Grid>
                                }
                                {(showRestrictedStudyInvoicesNotPaid || showStudyInvoicesNotPaid) &&
                                    <Grid item xs={12} lg={(showRestrictedStudyInvoicesNotInvoiced || showStudyInvoicesNotInvoiced) ? 6 : 12}>
                                        <StudyInvoiceWidget division={division} type='notPaid'
                                            restricted={showRestrictedStudyInvoicesNotPaid && !showStudyInvoicesNotPaid}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </div> :
                        comingSoon
                    }
                </div>
            </div>
        </div>
    )
}

export default Dashboard;
import * as React from 'react';

import { BForm, BSubmit, BTextField } from 'mui-bueno';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';

import {
    Card,
    CardActions,
    CardContent,
    Modal,
} from '@material-ui/core';
import { Save } from '@material-ui/icons';

import CardTitle from '../../../common/Card/CardTitle'
import { Invoice } from '../../../@types';
import { showSuccessSnackbar } from '../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../service/utils';
import { updateInvoiceDescription } from '../../../service/Invoice/invoice';


interface Props {
    open: boolean;
    setOpen: (state: boolean) => void;
    updateOnChange: boolean;
    setUpdateOnChange: any;
    invoice: Invoice;
}

const EditInvoiceDesc: React.FC<Props> = props => {

    const { open, setOpen, updateOnChange, setUpdateOnChange, invoice } = props;

    const dispatcher = useDispatch();

    const initialValues: Invoice = {
        id: invoice.id,
        invoiceNo: invoice.invoiceNo,
        amount: invoice.amount,
        amountMinusHoldback: invoice.amountMinusHoldback,
        description: invoice.description ?? '',
        lineItemIds: invoice.lineItemIds,
        payingAccountId: invoice.payingAccountId,
        receivingAccountId: invoice.receivingAccountId,
        invoiceStatus: invoice.invoiceStatus,
        studyId: invoice.studyId,
        createdDate: invoice.createdDate,
        autopayable: invoice.autopayable
    }

    const handleSubmit = async (data: Invoice, { setErrors }: FormikHelpers<Invoice>) => {

        if (invoice.id) {

            const description = data.description ? data.description : ' '

            updateInvoiceDescription(invoice.id, description)
                .then(res => {
                    dispatcher(showSuccessSnackbar('Invoice description updated'));
                    handleCloseModal();
                    setUpdateOnChange(!updateOnChange);
                }).catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        setStatus: setErrors,
                        prefix: 'Invoice description could not be updated: '
                    })
                });
        }
    }

    const handleCloseModal = () => {
        setOpen(false);
    }

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
        >
            <div className='modal-form'>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    <BForm>
                        <Card className='detail-form sm'>
                            <CardTitle title='Edit Invoice Description' handleClose={handleCloseModal} />
                            <CardContent className='flex-center'>
                                <BTextField
                                    name='description'
                                    label='Description'
                                    multiline
                                    xs={10}
                                    autoFocus
                                    InputProps={{
                                        rows: 5
                                    }}
                                />
                            </CardContent>
                            <CardActions className='flex-end'>
                                <BSubmit
                                    startIcon={<Save />}
                                    variant='contained'
                                    color='primary'
                                >
                                    Save
                                </BSubmit>
                            </CardActions>
                        </Card>
                    </BForm>
                </Formik>
            </div>
        </Modal>
    )
}

export default EditInvoiceDesc;


import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, FormikProps } from 'formik';
import {
    ClickAwayListener,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import { BDatePicker } from 'mui-bueno';

import { SimulatorPatient } from '../../../../../@types';
import { displayDate, getLocalDate } from '../../../../../common/Utils/utils';
import { TableColumn } from '../../../../../common/DataTable/EditablePagingTable';
import { handleErrorResponse } from '../../../../../service/utils';
import { getSimulatorPatients, updatePatientFirstVisit } from '../../../../../service/Simulator/simulator';
import { showSuccessSnackbar } from '../../../../../modules/messageSnackbarReducer';
import { updateSimulatorCharts } from '../../../../../modules/simulatorReducer';
import { MutableRefObject, RefObject } from 'react';

interface Props {
    simulatorId: number;
}

const ExistingPatientDetail: React.FC<Props> = (props) => {
    const { simulatorId } = props;
    const dispatch = useDispatch();

    // To be used to access the firstVisitDate value from BDatePicker
    const ref = React.useRef() as RefObject<FormikProps<SimulatorPatient>> & MutableRefObject<HTMLInputElement>;

    const [updated, setUpdated] = React.useState<boolean>(false);
    const [existingPatients, setExistingPatients] = React.useState<SimulatorPatient[]>([]);
    const [editPatient, setEditPatient] = React.useState<SimulatorPatient>({
        id: null,
        simulatorId: 0,
        patientId: 0,
        identifier: '',
        firstVisitDate: new Date(),
        dropDate: new Date(),
        remainingVisitCount: 0,
        firstVisitOccurred: true,
    });

    React.useEffect(() => {
        getSimulatorPatients(simulatorId)
            .then(res => {
                setExistingPatients(res.data);
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve list of existing patients on simulator source: '
                });
            });

        setUpdated(false)
    }, [updated]);

    const tblHeaders: TableColumn[] = [
        { type: 'patient', displayValue: 'Patient', align: 'left', style: { width: '5%' } },
        { type: 'firstVisitDate', displayValue: 'First Visit Date', align: 'center', format: 'date', style: { width: '5%' } },
        { type: 'dateDropped', displayValue: 'Date Dropped', align: 'center', format: 'date' },
        { type: 'remainingVisits', displayValue: 'Remaining Visits', align: 'center', format: 'integer', style: { width: '5%' } },
    ]

    const handlePatientFirstVisitSubmit = async (data: SimulatorPatient) => {
        if (!editPatient.id || !ref.current.values.firstVisitDate) return;
        // Don't want to make a service call if the date wasn't changed
        if (ref.current.values.firstVisitDate == editPatient.firstVisitDate) return;
        if (data.id) {
            updatePatientFirstVisit(data.id, ref.current.values.firstVisitDate)
                .then(res => {
                    dispatch(showSuccessSnackbar("Simulator patient's first visit date updated"));
                    setEditPatient({
                        id: null,
                        simulatorId: 0,
                        patientId: 0,
                        identifier: '',
                        firstVisitDate: new Date(),
                        dropDate: new Date(),
                        remainingVisitCount: 0,
                        firstVisitOccurred: true,
                    });
                    setUpdated(true);
                    dispatch(updateSimulatorCharts());
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        // setStatus: setErrors,
                        prefix: 'Could not update simulator patient: '
                    });
                });
        }
    }

    return (
        <TableContainer component={Paper}>
            <Table stickyHeader>
                <TableHead className="table-header">
                    <TableRow>
                        {tblHeaders.map((header) => (
                            <TableCell
                                key={`header-${header.type}`}
                                className='primary-cell primary-color'
                            >
                                <Typography className={`${header.align}`}>
                                    {header.displayValue}
                                </Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {existingPatients.length === 0 ?
                        <TableRow>
                            <TableCell colSpan={tblHeaders.length + 1} className='payment-table-body-cell center' style={{ height: 80, fontSize: 16 }}>
                                No data to display.
                            </TableCell>
                        </TableRow> :
                        existingPatients.map((patient, idx) => (
                            <TableRow key={`row-${idx}`}>
                                <TableCell className={`body-cell`}>{patient.identifier}</TableCell>
                                <TableCell
                                    className={`body-cell center input-cell`}
                                    onClick={() => {
                                        !patient.firstVisitOccurred && !patient.dropDate &&
                                        setEditPatient({ ...patient, firstVisitDate: patient.firstVisitDate ? getLocalDate(patient.firstVisitDate) : patient.firstVisitDate })
                                    }}
                                >
                                    {patient.id === editPatient.id ?
                                        <Formik innerRef={ref} initialValues={editPatient} onSubmit={handlePatientFirstVisitSubmit}>
                                            <ClickAwayListener onClickAway={() => handlePatientFirstVisitSubmit(editPatient)}>
                                                <div>
                                                    <BDatePicker
                                                        name="firstVisitDate"
                                                        label=''
                                                        inputVariant="filled"
                                                        autoFocus noMP
                                                        className='cell-input'
                                                    />
                                                </div>
                                            </ClickAwayListener>
                                        </Formik>
                                        : displayDate(patient.firstVisitDate)
                                    }
                                </TableCell>
                                <TableCell className={`body-cell center`}>{displayDate(patient.dropDate)}</TableCell>
                                <TableCell className={`body-cell center`}>{patient.remainingVisitCount}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}
export default ExistingPatientDetail;
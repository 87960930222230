import * as React from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';

import { Box, Button, Typography } from '@material-ui/core';
import { AccessibilityRounded, AssignmentOutlined, DescriptionRounded, HistoryRounded, Launch, MonetizationOnRounded, People, TimelineRounded } from '@material-ui/icons';

import { Contract, Study } from '../../../@types';
import { showErrorSnackbar } from '../../../modules/messageSnackbarReducer';
import { getStudy, getStudyCurrentContract } from '../../../service/Study/study';
import useLoggedInUserPermissions from '../../../common/hooks/useLoggedInUserPermissions';
import CollapsiblePanel, { NavItem } from '../../../common/CollapsiblePanel/CollapsiblePanel';
import Loading from '../../../common/Routes/Loading';

import Visits from './Activity/Activity';
import Addendums from './Addendums/Addendums';
import Documents from './Documents/Documents';
import StudyInvoicing from './Invoicing/StudyInvoicing';
import StudyOverview from './Overview/StudyOverview';
import StudyEmployees from './Employees/StudyEmployees';
import StudyStatistics from './Statistics/StudyStatistics';


interface RouteParams {
    id: string;
    section: string;
    subSection?: string;
}

const StudyDetails: React.FC = () => {
    const { id, section, subSection } = useParams<RouteParams>();

    const history = useHistory();
    const dispatch = useDispatch();

    const [currentContract, setCurrentContract] = React.useState<Contract>({
        id: null,
        studyId: 0,
        sponsorProposal: 0,
        negotiatingCost: 0,
        actualCost: 0,
        finalCost: 0,
        overhead: 0,
        holdback: 0,
        coordinatorCost: 0,
        providerCost: 0,
        projectedSubjects: 0,
        perSubjectDuration: 0,
        coordinatorExtraTime: 0,
        providerExtraTime: 0,
        coordinatorAdminTime: 0,
        providerAdminTime: 0,
        signed: null,
    });
    const [contractSigned, setContractSigned] = React.useState<(Date | null)>(null);
    const [study, setStudy] = React.useState<Study>({
        id: null,
        name: '',
        identifier: '',
        irbNo: '',
        studyId: '',
        sponsorIdentifier: '',
        divisionId: 0,
        accountId: 0,
        sponsorId: 0,
    });
    const [loadingComplete, setLoadingComplete] = React.useState<boolean>(false);

    React.useEffect(() => {
        getStudy(Number(id))
            .then(studyRes => {
                setStudy(studyRes.data);
                getStudyCurrentContract(Number(studyRes.data.id))
                    .then(contract => {
                        setCurrentContract(contract.data);
                        setContractSigned(contract.data.signed);
                        setLoadingComplete(true);
                    }).catch(err => {
                        setLoadingComplete(true);
                    });
            }).catch((err: AxiosError) => {
                if (err.response?.status === 403) {
                    dispatch(showErrorSnackbar('You do not have access to this study'));
                } else {
                    dispatch(showErrorSnackbar('Could not find Study'));
                }
                setLoadingComplete(true);
            });
    }, [id]);

    // TODO: Fix active sidebar item when sub route is different from the one listed here
    const navStudyOptions: NavItem[] = [
        { title: 'Overview', icon: <AssignmentOutlined />, route: `/study/${id}/overview` },
        { title: 'Employees', icon: <People />, route: `/study/${id}/employees` },
        { title: 'Activity', icon: <AccessibilityRounded />, route: `/study/${id}/activity/scheduled` },
        { title: 'Invoicing', icon: <MonetizationOnRounded />, route: `/study/${id}/invoicing/receivable/invoices` },
        { title: 'Statistics', icon: <TimelineRounded />, route: `/study/${id}/statistics` },
    ];


    if (useLoggedInUserPermissions('API_DOCUMENT_GET_DOCUMENTS_BY_STUDY', study.divisionId, Number(id))) {
        navStudyOptions.splice(3,0,
            { title: 'Documents', icon: <DescriptionRounded />, route: `/study/${id}/documents` },
        )
    }
    if (useLoggedInUserPermissions('API_CONTRACT_GET_BY_ID', study.divisionId, Number(study.studyId))) {
        navStudyOptions.push(
            { title: 'Addendums', icon: <HistoryRounded />, route: `/study/${id}/addendums` },
        )
    }
    if (useLoggedInUserPermissions('API_STUDY_GET_CURRENT_CONTRACT', study.divisionId, Number(study.studyId))) {
        navStudyOptions.push(
            { title: 'Go to Contract', icon: <Launch />, route: `/contract/${currentContract.id!}` },
        )
    }

    if (!loadingComplete) {
        return ( <Loading /> );
    }
    else if (study.id && contractSigned) {
        return (
            <div className="page">
                <CollapsiblePanel navOptions={navStudyOptions} />
                <div className="page-content">
                    <div className="inner">
                        {(section === 'overview') && <StudyOverview studyId={Number(id)} />}
                        {(section === 'employees') && <StudyEmployees id={id} />}
                        {(section === 'activity') && <Visits study={study} currentContract={currentContract} />}
                        {(section === 'documents') && <Documents id={id} />}
                        {(section === 'invoicing') && <StudyInvoicing />}
                        {(section === 'addendums') && <Addendums id={id} />}
                        {(section === 'statistics') && <StudyStatistics study={study} year={subSection ? +subSection : undefined} />}
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="page">
                <div className="page-content">
                    <div className="inner">
                        <div className="page-heading" />
                        <div className="full-screen centered">
                            <Box textAlign="center" className="page-body">
                                <Typography gutterBottom variant="h1">
                                    Study Unavailable
                                </Typography>
                                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                                    The page you are looking for does not exist. <br />
                                    Note that study pages for contracts are only accessible once the contract has been signed.
                                </Typography>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => history.push('/study')}
                                    style={{ margin: 40 }}
                                >
                                    View All Studies
                                </Button>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default StudyDetails;
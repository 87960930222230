import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Box, Button, Card, CardContent, Grid, Paper, Table, TableBody, TableContainer, Tooltip, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';

import { Study, StudyOverviewData } from '../../../../@types';
import { handleErrorResponse } from '../../../../service/utils';
import { getStudy } from '../../../../service/Study/study';

import MonthlyTableHeader from '../Statistics/MonthlyTableHeader';
import StudyStatisticsTableRow from '../Statistics/StudyStatisticsTableRow';
import YearSelector from '../../../../common/DataTable/YearSelector';


interface Props {
    studyData: StudyOverviewData,
}

const StudyOverviewStatistics: React.FC<Props> = props => {
    const { studyData } = props;

    const history = useHistory();
    const dispatch = useDispatch();

    const [study, setStudy] = React.useState<Study>();

    React.useEffect(() => {
        getStudy(studyData.id)
            .then(res => {
                setStudy(res.data);
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve Study: '
                })
            });
    }, [])

    const currentYear: number = new Date().getFullYear();
    const currentMonth: number = new Date().getMonth();

    const infoToolip: string = 'If any effort values are inaccurate, please contact the organization’s administrator or division manager to correct them.';
    
    const [editYear, setEditYear] = React.useState<number>(currentYear);

    const renderHeader = () => {
        const startYear = studyData.started ? new Date(studyData.started.toString()).getUTCFullYear() : new Date().getUTCFullYear();
        return (
            <div className="aligned-row space-between">
                <div className="section-heading">
                    <div className='text'>Statistics</div>
                    <Tooltip arrow title={infoToolip}><Info className='info' /></Tooltip>
                    <Button id='view-statistics' onClick={() => history.push(`/study/${studyData.id}/statistics`)}>
                        View Full Details
                    </Button>
                </div>
                <YearSelector year={editYear} setYear={setEditYear} minimum={startYear} />
            </div>
        );
    }

    if (studyData.started && study) {
        return (
            <Grid container item>
                {renderHeader()}
                <div className="aligned-row">
                    <Card className="full-width">
                        <TableContainer component={Paper} style={{ maxHeight: '30em' }}>
                            <Table stickyHeader className="study-statistics-table">
                                <MonthlyTableHeader editYear={editYear} currentYear={currentYear} currentMonth={currentMonth} />
                                <TableBody>
                                    <StudyStatisticsTableRow
                                        study={study} editYear={editYear} currentYear={currentYear} currentMonth={currentMonth}
                                    />
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>
                </div>
            </Grid>
        );
    }
    return (
        <Grid container item>
            {renderHeader()}
            <div className="aligned-row">
                <Card className="full-width margin-auto">
                    <CardContent>
                        <Box textAlign="center" className="page-body">
                            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                                The study has not yet started.
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </div>
        </Grid>
    );
}

export default StudyOverviewStatistics;

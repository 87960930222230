import * as React from 'react';

import { Simulator, SimulatorSummaryResult } from '../../@types';

const longMonthExtractor = (date: string) => {
    // Date is in the format YYYY-MM-DD
    const splitDate: number[] = date.toString().split('-').map(a => +a);
    const newDate = new Date(splitDate[0], (splitDate[1] - 1), splitDate[2]);
    const locale: string = 'en-US';
    return newDate.toLocaleDateString(locale, { month: 'long' });
}

export const handleResourceReqTooltip = (resourceReqProps: any, year: number) => {
    if (resourceReqProps.payload && resourceReqProps.payload[0] != null) {
        return (
            <div className='tooltip-div'>
                <div className='month center'>
                    {longMonthExtractor(resourceReqProps.payload[0].payload.associatedDate) + ' ' + year}
                </div>
                <br />
                <div className='value'>
                    {'Coordinator Required: ' + resourceReqProps.payload[0].payload.coordinatorRequired + ' FTE'}
                </div>
                <div className='value'>
                    {'Coordinator Allocated: ' + resourceReqProps.payload[0].payload.coordinatorAllocated + ' FTE'}
                </div>
                <div className='value'>
                    {'Provider Required: ' + resourceReqProps.payload[0].payload.providerRequired + ' FTE'}
                </div>
                <div className='value'>
                    {'Provider Allocated: ' + resourceReqProps.payload[0].payload.providerAllocated + ' FTE'}
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export const handleAllocatedReqTooltip = (resourceReqProps: any, year: number) => {
    if (resourceReqProps.payload && resourceReqProps.payload[0] != null) {
        return (
            <div className='tooltip-div'>
                <div className='month center'>
                    {longMonthExtractor(resourceReqProps.payload[0].payload.associatedDate) + ' ' + year}
                </div>
                <br />
                <div className='value'>
                    {'Coordinator Difference: ' + resourceReqProps.payload[0].payload.coordinatorDifference + ' FTE'}
                </div>
                <div className='value'>
                    {'Provider Difference: ' + resourceReqProps.payload[0].payload.providerDifference + ' FTE'}
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export const DEFAULT_SUMMARY_DETAILS: string = 'No baseline';
export const handleSummaryDetailString = (data: SimulatorSummaryResult): string => {
    if (data.countChange) {
        return `${Math.abs(data.countChange)} ${data.countChange < 0 ? 'Less' : 'More'} than baseline`;
    } else if (data.currencyChangePercentage) {
        return `${Math.abs(data.currencyChangePercentage)}% ${data.currencyChangePercentage < 0 ? 'Less' : 'More'} than baseline`;
    } else {
        return DEFAULT_SUMMARY_DETAILS;
    }
}


export const statusStyle = (simulator: Simulator) => {
    if (simulator.sourceStatus == 'PENDING_CONTRACT')
        return ['Pending Contract', 'grey-chip'];
    else if (simulator.sourceStatus == 'SIGNED_CONTRACT')
        return ['Signed Contract', 'yellow-chip'];
    else if (simulator.sourceStatus == 'ACTIVE_STUDY')
        return ['Active Study', 'green-chip'];
    else
        return ['Completed Study', 'blue-chip'];
}

import { useSelector } from 'react-redux';

import { User } from '../../@types';
import { Store } from '../../modules/rootReducer';

const useLoggedInUser = () => {
    return useSelector<Store, User>(store =>
        store.loginReducer.user
    );
};

export default useLoggedInUser;
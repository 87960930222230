import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, CardActions, CardContent, CardHeader, Checkbox, CircularProgress, FormControlLabel, Grid, IconButton, Input, Modal, Typography } from '@material-ui/core';
import { Close, Save } from '@material-ui/icons';

import { showSuccessSnackbar } from '../../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../../service/utils';
import { uploadSchedule } from '../../../../service/Contract/schedule';


interface Props {
    open: any;
    setOpen: any;
    contractId: number;
    setUploaded: any;
}

const acceptableFileTypes: string[] = [
    'text/csv',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/octet-stream'
];

const BulkEntryModal: React.FC<Props> = props => {
    const { open, setOpen, contractId, setUploaded } = props;
    const [saving, setSaving] = React.useState<boolean>(false);
    const [selectedFile, setSelectedFile] = React.useState<File>();
    const [errors, setErrors] = React.useState<string>('');
    const [append, setAppend] = React.useState<boolean>(false);
    const dispatcher = useDispatch();

    React.useEffect(() => {
        setSelectedFile(undefined);
        setErrors('');
        setSaving(false);
        setAppend(false);
    }, [open]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files;
        if (!fileList) return;
        setSelectedFile(fileList[0]);
        setErrors('');
    };

    const handleChangeAppend = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAppend(event.target.checked);
    }

    const validate = (): boolean => {
        if (!selectedFile) {
            setErrors('File is required.');
            return false;
        } else if (!acceptableFileTypes.includes(selectedFile.type)) {
            setErrors('File must be in CSV format.');
            // this log is temporary added to troubleshoot on windows
            return false;
        }
        return true;
    }
    const handleUpload = () => {
        if (!validate()) return;

        if (selectedFile && !saving) {
            setSaving(true);
            const formData = new FormData();
            formData.append('file', selectedFile, selectedFile.name);
            uploadSchedule(contractId, formData, append)
                .then(res => {
                    dispatcher(showSuccessSnackbar('Bulk Entry uploaded.'));
                    handleModalClose();
                    setUploaded(true);
                }).catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        setStatus: setErrors,
                        prefix: 'Could not upload file: '
                    })
                    setSaving(false);
                });
        }
    }

    const handleModalClose = () => {
        setOpen(false);
    }

    return (
        <Modal
            open={open}
            onClose={handleModalClose}
        >
            <div className="modal-form">
                <Card className="detail-form sm">
                    <CardHeader
                        title="Bulk Entry"
                        action={
                            <IconButton color="primary" aria-label="Close" onClick={() => setOpen(false)}>
                                <Close />
                            </IconButton>
                        }
                    />
                    <CardContent>
                        <Grid container spacing={4} justifyContent='center' alignItems='center' direction="column">
                            <Grid item xs={12}>
                                <Typography variant="body1" color="textSecondary" component="p">
                                    Select CSV file to create Scheduled Visits and Procedures.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Input type="file" autoFocus={true} fullWidth={true}
                                    onChange={handleFileChange}
                                    error={!!errors}
                                />
                                <Typography color='error'>{errors}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox checked={append} onChange={handleChangeAppend} name="appendCheck" />}
                                    label="Append data to the existing Schedule Of Events"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions className="flex-end">
                        <Button
                            startIcon={saving ? null : <Save />}
                            variant="contained"
                            color={saving ? 'inherit' : 'primary'}
                            onClick={handleUpload}
                            disabled={saving}
                        >
                            {saving &&
                                <CircularProgress size={24} className='button-progress' />
                            }
                            Save
                        </Button>
                    </CardActions>
                </Card>
            </div>
        </Modal>
    )
}

export default BulkEntryModal;
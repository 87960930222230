import * as React from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    IconButton,
    InputAdornment,
    Modal
} from '@material-ui/core';
import { Close, Delete, Save } from '@material-ui/icons';
import {
    BButton,
    BDatePicker,
    BForm,
    BGrid,
    BSubmit,
    BTextField
} from 'mui-bueno';

import { SimulatorSalaryLog } from '../../../../../../@types';
import { NumberFormatter } from '../../../../../../common/Utils/NumberFormatter';
import { displayDate } from '../../../../../../common/Utils/utils';
import {
    createSimulatorSalaryLog,
    deleteSimulatorSalaryLog,
    updateSimulatorSalaryLog
} from '../../../../../../service/Simulator/simulator';
import { handleErrorResponse } from '../../../../../../service/utils';
import { showSuccessSnackbar } from '../../../../../../modules/messageSnackbarReducer';
import { updateSimulatorCharts } from '../../../../../../modules/simulatorReducer';
import usePermissions from '../../../../../../common/hooks/usePermissions';

import SimpleConfirmDelete from '../../../../../../common/ConfirmDelete/SimpleConfirmDelete';


const emptyForm = {
    effectiveDate: new Date(),
    salary: '',
    fringe: ''
};

const schema = yup.object<SimulatorSalaryLog>().shape({
    effectiveDate: yup.date().required('Salary Log Effective Date is required'),
    salary: yup.number().required('Salary is required'),
    fringe: yup.number().required('Fringe is required')
});

interface Props {
    simulatorUserId: number;
    open: boolean;
    setOpen: (arg0: boolean) => void;
    salaryLog: SimulatorSalaryLog | null;
    setSalaryLog?: (arg0: SimulatorSalaryLog | null) => void;
    setUpdated: () => void;
}
const EmployeeDetailedSalaryLogModal: React.FC<Props> = (props) => {
    const { simulatorUserId, open, setOpen, salaryLog, setSalaryLog, setUpdated } = props;

    const canDelete: boolean = usePermissions('API_SIMULATOR_REMOVE_SALARY_LOG');
    const canCreate: boolean = usePermissions('API_SIMULATOR_ADD_SALARY_LOG');
    const canUpdate: boolean = usePermissions('API_SIMULATOR_UPDATE_SALARY_LOG');

    const dispatch = useDispatch();

    const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);

    const handleClose = () => {
        setSalaryLog && setSalaryLog(null);
        setDeleteOpen(false);
        setOpen(false);
    };

    const handleDelete = () => {
        if (!salaryLog?.id) return;
        deleteSimulatorSalaryLog(salaryLog.id)
            .then(res => {
                dispatch(showSuccessSnackbar('Simulator Salary Log deleted'));
                setUpdated();
                handleClose();
                dispatch(updateSimulatorCharts());
            })
            .catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not delete Simulator Salary Log: '
                });
            })
            .finally(() => setDeleteOpen(false));
    };

    const handleSubmit = async (data: any, { setErrors }: FormikHelpers<any>) => {
        const submit: SimulatorSalaryLog = {
            id: salaryLog?.id ?? null,
            simulatorUserId: simulatorUserId,
            salary: +data.salary,
            fringe: +data.fringe,
            effectiveDate: data.effectiveDate
        };
        if (salaryLog) {
            updateSimulatorSalaryLog(submit)
                .then(res => {
                    dispatch(showSuccessSnackbar('Simulator Salary Log updated'));
                    setUpdated();
                    handleClose();
                    dispatch(updateSimulatorCharts());
                })
                .catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not update Simulator Salary Log: ',
                        setStatus: setErrors
                    });
                });
        } else {
            createSimulatorSalaryLog(submit)
                .then(res => {
                    dispatch(showSuccessSnackbar('Simulator Salary Log created'));
                    setUpdated();
                    handleClose();
                    dispatch(updateSimulatorCharts());
                })
                .catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not create Simulator Salary Log: ',
                        setStatus: setErrors
                    });
                });
        }
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <div className="modal-form">
                    <Formik
                        initialValues={salaryLog ?? emptyForm}
                        onSubmit={handleSubmit}
                        validationSchema={schema}
                        enableReinitialize
                        validateOnBlur={false}
                        validateOnChange={false}
                    >
                        <BForm>
                            <Card className="detail-form sm">
                                <CardHeader
                                    title={`${salaryLog ? 'Edit' : 'Add'} Salary Log`}
                                    action={
                                        <IconButton color="primary" aria-label="Close" onClick={handleClose}>
                                            <Close />
                                        </IconButton>
                                    }
                                />
                                <CardContent>
                                    <BGrid container spacing={1}>    
                                        <BDatePicker
                                            name="effectiveDate"
                                            label="Effective Date"
                                            staticLabel
                                            noMP xs={12} sm={6}
                                        />
                                        <BTextField
                                            name="salary"
                                            label="Salary"
                                            placeholder={undefined}
                                            staticLabel
                                            noMP xs={12} sm={3}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                inputComponent: NumberFormatter as any
                                            }}
                                        />
                                        <BTextField
                                            name="fringe"
                                            label="Fringe"
                                            placeholder={undefined}
                                            staticLabel
                                            noMP xs={12} sm={3}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                inputComponent: NumberFormatter as any
                                            }}
                                        />
                                    </BGrid>
                                </CardContent>
                                <CardActions className="space-between">
                                    {(salaryLog && canDelete) ? <BButton
                                        startIcon={<Delete />}
                                        onClick={() => setDeleteOpen(true)}
                                        variant="contained"
                                        className="flex-start delete-color"
                                    >
                                        Delete
                                    </BButton> : <div className="flex-start" />}
                                    {(canCreate || canUpdate) ? <BSubmit
                                        startIcon={<Save />}
                                        variant="contained"
                                        color="primary"
                                        className="flex-end"
                                    >
                                        Save
                                    </BSubmit> : <div className="flex-end" />}
                                </CardActions>
                            </Card>
                        </BForm>
                    </Formik>
                </div>
            </Modal>
            <SimpleConfirmDelete
                open={deleteOpen}
                setOpen={setDeleteOpen}
                type='Salary Log'
                objectName={displayDate(salaryLog?.effectiveDate ?? null)}
                handleDelete={handleDelete}
                message={`Do you want to delete the Salary Log beginning on ${displayDate(salaryLog?.effectiveDate ?? null)} from the simulation?`}
                title={'Delete Salary Log From Simulation?'}
            />
        </>
    );
}

export default EmployeeDetailedSalaryLogModal;
import * as React from 'react';
import { useDispatch } from 'react-redux';

import {
    Button, Card, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Typography, TableFooter
} from '@material-ui/core';
import { Add, ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

import { DivisionTree, StudyInvoicingData, StudyInvoicingResult } from '../../../../@types';
import Loading from '../../../../common/Routes/Loading';
import { displayDate } from '../../../../common/Utils/utils';
import usePermissions from '../../../../common/hooks/usePermissions';
import LogPayment from '../../../Invoicing/Payment/LogPayment';
import { handleErrorResponse } from '../../../../service/utils';
import { getAmountNotInvoicedOnRestrictedStudies, getAmountNotInvoicedOnStudies, getAmountNotPaidOnRestrictedStudies, getAmountNotPaidOnStudies } from '../../../../service/Invoice/invoice';
import InvoiceChart from './InvoiceChart';
import { amountFormatter } from '../Utils';

interface Props {
    division: DivisionTree;
    type: 'notInvoiced' | 'notPaid';
    restricted: boolean;
}

interface State {
    sortedList: StudyInvoicingData[];
    sortColumn: string;
    sortOrder: boolean;
}

interface Action {
    type: 'list' | 'column' | 'order';
    payload?: any;
}

interface GraphData {
    studyId: number;
    studyName: string;
    divisionName: string;
    amountNotInvoiced: number | null;
    lastInvoiceDate: string | null;
    invoiceId: number | null;
    amountNotPaid: number | null;
    lastPaymentDate: string | null
    paymentId: number | null;
    accountId: number | null;
}

const StudyInvoiceWidget: React.FC<Props> = (props) => {
    const { division, type, restricted } = props;
    const dispatcher = useDispatch();

    const [data, setData] = React.useState<StudyInvoicingResult>({ list: [], totalAmountNotPaid: 0, totalAmountNotInvoiced: 0 });
    const [logPaymentModalOpen, setLogPaymentModalOpen] = React.useState<boolean>(false);
    const [updateOnPayment, setUpdateOnPayment] = React.useState<boolean>(false);
    const [doneLoading, setDoneLoading] = React.useState<boolean>(false);

    const showLogPayment: boolean = type == 'notPaid' && usePermissions('API_ACCOUNT_PAYMENT_LOG_PAYMENT')
        && usePermissions('API_ACCOUNT_PAYMENT_LOG_HOLDBACK_PAYMENT');

    // State management for sorting
    const initialState: State = {
        sortedList: data.list,
        sortColumn: type == 'notInvoiced' ? 'amountNotInvoiced' : 'amountNotPaid',
        sortOrder: false,
    };

    const reducer = (invoiceWidgetState: State, action: Action) => {
        switch (action.type) {
            case 'list':
                return { ...invoiceWidgetState, sortedList: action.payload };
            case 'column':
                return { ...invoiceWidgetState, sortColumn: action.payload, sortOrder: true }
            case 'order':
                return { ...invoiceWidgetState, sortOrder: action.payload }
            default:
                return invoiceWidgetState;
        }
    };

    const [state, dispatchPage] = React.useReducer(reducer, initialState);
    const { sortedList, sortColumn, sortOrder } = state;

    const getData = (divisionId?: number) => {
        if (type == 'notInvoiced') {
            if (restricted) {
                getAmountNotInvoicedOnRestrictedStudies(divisionId).then(res => {
                    setData(res.data);
                    setDoneLoading(true);
                }).catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        prefix: 'Could not get not invoiced restricted studies data: '
                    })
                });
            } else {
                getAmountNotInvoicedOnStudies(divisionId).then(res => {
                    setData(res.data);
                    setDoneLoading(true);
                }).catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        prefix: 'Could not get not invoiced studies data: '
                    })
                });
            }
        } else {
            if (restricted) {
                getAmountNotPaidOnRestrictedStudies(divisionId).then(res => {
                    setData(res.data);
                    setDoneLoading(true);
                }).catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        prefix: 'Could not get not paid restricted studies data: '
                    })
                });
            } else {
                getAmountNotPaidOnStudies(divisionId).then(res => {
                    setData(res.data);
                    setDoneLoading(true);
                }).catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        prefix: 'Could not get not paid studies data: '
                    })
                });
            }
        }
    }

    // Retrieve the updated data for the specified division or when a payment is made
    React.useEffect(() => {
        if (division) {
            getData(division.id);
        } else {
            getData();
        }
    }, [division, updateOnPayment]);

    // Sorting
    const handleSortClick = (columnType: string): void => {
        if (columnType === sortColumn) {
            dispatchPage({ type: 'order', payload: !sortOrder });
        } else {
            dispatchPage({ type: 'column', payload: columnType });
        }
    };

    const headerSortIcon = (sortType: string) => {
        if (sortColumn === sortType) return sortOrder ? <ArrowDropUp /> : <ArrowDropDown />;
    };

    React.useEffect(() => {
        const sorted: any[] = [...data.list].sort((aObj: any, bObj: any) => {
            const order: number = !sortOrder ? -1 : 1;
            const a = aObj[sortColumn];
            const b = bObj[sortColumn];

            if (a instanceof Date || b instanceof Date) {
                return order * ((a?.getTime() ?? new Date(0).getTime()) - (b?.getTime() ?? new Date(0).getTime()));
            } else if (typeof a === 'number' || typeof b === 'number') {
                return order * ((a ?? 0) - (b ?? 0));
            } else {
                return order * (a ?? '').localeCompare(b ?? '');
            }
        });
        dispatchPage({ type: 'list', payload: sorted });
    }, [data, sortColumn, sortOrder]);

    const handleLogPaymentOpen = (): void => {
        setLogPaymentModalOpen(true);
    };

    // Need to convert the date to a string before sending the data to the bar graph
    const convertData = (dataToConvert: StudyInvoicingData[]) => {
        const tempData: GraphData[] = [];
        dataToConvert.forEach((obj: StudyInvoicingData) => {
            tempData.push({
                studyId: obj.studyId,
                studyName: obj.studyName,
                divisionName: obj.divisionName,
                amountNotPaid: obj.amountNotPaid ?? null,
                amountNotInvoiced: obj.amountNotInvoiced ?? null,
                lastInvoiceDate: obj.lastInvoiceDate ? displayDate(obj.lastInvoiceDate) : 'No Invoices',
                lastPaymentDate: obj.lastPaymentDate ? displayDate(obj.lastPaymentDate) : 'No Payments',
                invoiceId: obj.lastInvoiceId ?? null,
                paymentId: obj.lastPaymentId ?? null,
                accountId: obj.lastPaymentPayingAccountId ?? null
            })
        })
        return tempData;
    };

    if (doneLoading) {
        return (
            <Card className='bar-chart-widget card'>
                <div className='widget-header'>
                    {type == 'notInvoiced' ?
                        <Typography className='header-text'>Not Invoiced</Typography> :
                        <Typography className='header-text'>Not Paid</Typography>
                    }
                    {showLogPayment &&
                        <Button
                            onClick={handleLogPaymentOpen}
                            variant='contained'
                            color='primary'
                            startIcon={<Add />}
                            className='payment-button'
                        >
                            Log Received Payment
                        </Button>
                    }
                </div>
                <TableContainer className='table-container'>
                    <Table stickyHeader>
                        <TableHead className="table-header">
                            <TableRow>
                                <TableCell className='cursor-pointer'
                                    style={{ width: '25%', paddingLeft: 10 }}
                                    onClick={() => handleSortClick('studyName')}
                                >
                                    <div className='header-cell-label left text-600'>
                                        Study
                                        {headerSortIcon('studyName')}
                                    </div>
                                </TableCell>
                                <TableCell className='cursor-pointer'
                                    style={{ width: '52%' }}
                                    onClick={() => { type == 'notInvoiced' ? handleSortClick('amountNotInvoiced') : handleSortClick('amountNotPaid') }}
                                >
                                    {type == 'notInvoiced' ?
                                        <div className='header-cell-label center text-600'>
                                            Amount Not Invoiced
                                            {headerSortIcon('amountNotInvoiced')}
                                        </div> :
                                        <div className='header-cell-label center text-600'>
                                            Amount Not Paid
                                            {headerSortIcon('amountNotPaid')}
                                        </div>
                                    }
                                </TableCell>
                                <TableCell className='cursor-pointer'
                                    onClick={() => { type == 'notInvoiced' ? handleSortClick('lastInvoiceDate') : handleSortClick('lastPaymentDate') }}
                                >
                                    {type == 'notInvoiced' ?
                                        <div className='header-cell-label left text-600'>
                                            Last Invoice
                                            {headerSortIcon('lastInvoiceDate')}
                                        </div> :
                                        <div className='header-cell-label left text-600'>
                                            Last Payment
                                            {headerSortIcon('lastPaymentDate')}
                                        </div>
                                    }
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {data.list.length > 0 ?
                            <>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ paddingLeft: 10 }}>
                                            <InvoiceChart
                                                graphData={convertData(sortedList)}
                                                barColor='#fff'
                                                displayColumn='study'
                                                notInvoiced={type == 'notInvoiced' ? true : false}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingLeft: 0 }}>
                                            <InvoiceChart
                                                graphData={convertData(sortedList)}
                                                barColor={type == 'notInvoiced' ? '#fca503' : '#e04f4f'}
                                                displayColumn='amount'
                                                notInvoiced={type == 'notInvoiced' ? true : false}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <InvoiceChart
                                                graphData={convertData(sortedList)}
                                                barColor='#fff'
                                                displayColumn='date'
                                                notInvoiced={type == 'notInvoiced' ? true : false}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                                <TableFooter>
                                    <TableRow className='info-row'>
                                        <TableCell className='left' style={{ paddingLeft: 10 }}>
                                            Total
                                        </TableCell>
                                        <TableCell className='center'>
                                            {type == 'notInvoiced' ?
                                                amountFormatter(data.totalAmountNotInvoiced) :
                                                amountFormatter(data.totalAmountNotPaid)
                                            }
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableFooter>
                            </> :
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={3} className='body-cell center' style={{ height: 80, fontSize: 16 }}>
                                        No data to display.
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
                {showLogPayment &&
                    <LogPayment
                        open={logPaymentModalOpen}
                        setOpen={setLogPaymentModalOpen}
                        updateOnChange={updateOnPayment}
                        setUpdateOnChange={setUpdateOnPayment}
                        receivedPayment
                    />
                }
            </Card >
        )
    } else {
        return (
            <Loading />
        )
    }
}

export default StudyInvoiceWidget;
import * as React from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Card, CardActions, CardContent, Grid, Modal } from '@material-ui/core';
import { BForm, BSubmit, BTextField } from 'mui-bueno';
import { Formik, FormikHelpers } from 'formik';

import { Directory, SelectOption } from '../../../../../@types';
import { showSuccessSnackbar } from '../../../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../../../service/utils';
import { createDirectory, getDirectoriesByStudy } from '../../../../../service/Study/directories';
import CheckedAutocomplete from '../../../../../common/CheckedAutocomplete/CheckedAutocomplete';
import CardTitle from '../../../../../common/Card/CardTitle';


interface Props {
    open: any;
    setOpen: any;
    setUpdated: any;
    studyId: number;
    parentDirectorySet?: Directory | null;
}

const schema = yup.object<Directory>().shape({
    name: yup.string().required('Name is required'),
});

const AddDirectoryModal: React.FC<Props> = props => {
    const { open, setOpen, studyId, setUpdated, parentDirectorySet } = props;

    const history = useHistory();
    const dispatch = useDispatch();

    // Current division details in edit/add
    const [directory, setDirectory] = React.useState<any>({
        id: null,
        name: '',
        parentDirectoryId: -1,
        studyId: studyId,
        allowedFileDeleted: true,
    });

    const [directoryOptions, setDirectoryOptions] = React.useState<SelectOption<number>[]>([]);
    const [selectedDirectory, setSelectedDirectory] = React.useState<SelectOption<number>>({
        value: -1,
        label: 'No Parent Directory'
    });
    const [saving, setSaving] = React.useState<boolean>(false);
    const [inputErrors, setInputErrors] = React.useState<string>('');

    React.useEffect(() => {
        getDirectoriesByStudy(studyId).then(res => {
            const tempDirectoryOptions: SelectOption<number>[] = [];
            // No parent directory option
            tempDirectoryOptions.push({
                value: -1,
                label: 'No Parent Directory'
            })
            res.data.forEach((dir: Directory) => {
                tempDirectoryOptions.push({
                    value: dir.id!,
                    label: dir.name
                })
            })
            // This occurs when clicking the 'New Directory' button while inside a directory
            if (parentDirectorySet) {
                setDirectory({ ...directory, parentDirectoryId: parentDirectorySet.id! })
                setSelectedDirectory({
                    value: parentDirectorySet.id!,
                    label: ''
                });
            } else {
                setDirectory({ ...directory, parentDirectoryId: null })
                setSelectedDirectory({
                    value: -1,
                    label: ''
                });
            }

            setDirectoryOptions(tempDirectoryOptions);
        });
        setSaving(false);
        setInputErrors('');
    }, [open]);

    const validate = (): boolean => {
        // Only when creating a directory with no parent directory ID passed in is a directory selected
        if (!parentDirectorySet && !selectedDirectory) {
            setInputErrors('Directory is required.');
            return false;
        } else {
            setInputErrors('');
        }
        return true;
    };

    const handleSubmit = async (data: Directory, { setErrors }: FormikHelpers<Directory>) => {
        if (!validate()) return;

        let chosenParentDirectory: number | null;
        if (selectedDirectory.value == -1) {
            chosenParentDirectory = null;
        } else {
            chosenParentDirectory = selectedDirectory.value;
        }

        if (!saving) {
            createDirectory({
                id: data.id,
                name: data.name,
                parentDirectoryId: chosenParentDirectory,
                studyId: studyId,
                allowedFileDeleted: data.allowedFileDeleted,
            }).then(res => {
                setSaving(false);
                handleModalClose();
                dispatch(showSuccessSnackbar(`${res.data.name} created`));
                history.push(`/study/${studyId}/documents/directory/${res.data.id}`);
                setUpdated(true);
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    setStatus: setErrors,
                    prefix: 'Could not create directory: '
                });
                setSaving(false);

            });
        }
    }

    const handleModalClose = () => {
        setSelectedDirectory({
            value: -1,
            label: 'No Parent Directory'
        });
        setOpen(false);
    }

    return (
        <Modal
            open={open}
            onClose={handleModalClose}
        >
            <div className="modal-form">
                <Formik
                    initialValues={directory}
                    onSubmit={handleSubmit}
                    enableReinitialize
                    validateOnBlur={false}
                    validateOnChange={false}
                    validationSchema={schema}
                >
                    <BForm>
                        <Card className="detail-form sm">
                            <CardTitle
                                handleClose={handleModalClose}
                                title={parentDirectorySet ? 'Add Subdirectory to ' + parentDirectorySet.name : 'Add Directory'}
                            />
                            <CardContent>
                                <Grid container spacing={1} justifyContent='center'>
                                    {!parentDirectorySet &&
                                        <Grid item xs={12} sm={10}>
                                            <CheckedAutocomplete
                                                idText={'parentDirectoryId'}
                                                multiple={false}
                                                options={directoryOptions}
                                                labelText={'Parent Directory'}
                                                closeOnSelect={true}
                                                defaultValue={directoryOptions[0]}
                                                onChange={setSelectedDirectory}
                                                error={!!inputErrors}
                                                autoFocus
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12} sm={10}>
                                        <BTextField
                                            name="name"
                                            label="Name"
                                            placeholder="Directory Name"
                                            variant="standard"
                                            required 
                                            autoFocus={!!parentDirectorySet}
                                            noGrid={true}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions className="flex-end">
                                <BSubmit>Create</BSubmit>
                            </CardActions>
                        </Card>
                    </BForm>
                </Formik>
            </div>
        </Modal>
    )
};

export default AddDirectoryModal;
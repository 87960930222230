import * as React from 'react';
import _ from 'lodash';
import { Grid, Typography } from '@material-ui/core';

import { DivisionUser, SelectOption, StudyUser } from '../../../../@types';
import { convertReferenceDataToSelectionOptions } from '../../../../common/Utils/utils';
import { DisplayTextFormat } from '../../../../common/Utils/DisplayTextFormat';
import { getStudyUsers } from '../../../../service/Study/study';
import { getReferenceStudyRoles } from '../../../../service/Reference/reference';
import { getDivisionUsers } from '../../../../service/Management/divisions';
import StudyRoleTable from './StudyRoleTable';


interface State {
    objects: StudyUser[];
}

const initialState: State = {
    objects: []
}

interface Action {
    type: 'objectPage';
    payload?: any;
}

const reducer = (state: State, action: Action) => {
    if (action.type == 'objectPage') {
        return {
            ...state,
            objects: action.payload
        }
    } else {
        return state;
    }
}

const StudyEmployees: React.FC<{ id: string }> = props => {

    const { id } = props;

    const [state, dispatchPage] = React.useReducer(reducer, initialState);
    const {
        objects
    } = state;

    const [studyName, setStudyName] = React.useState<string>('');
    const [divisionId, setDivisionId] = React.useState<number>(0);

    // State management for the study roles
    const [allReferenceRoles, setAllReferenceRoles] = React.useState<SelectOption<number>[]>([]);

    // State management for users in the study's division
    const [divisionUsers, setDivisionUsers] = React.useState<SelectOption<number>[]>([]);

    // Keep track of if the study has been started and completed
    const [started, setStarted] = React.useState<boolean>(false);
    const [completed, setCompleted] = React.useState<boolean>(false);

    const [updated, setUpdated] = React.useState<boolean>(false);

    // Get current user, all users, and all roles on initial load
    React.useEffect(() => {
        if (id) {
            getStudyUsers(Number(id)).then(res => {
                dispatchPage({ type: 'objectPage', payload: res.data.studyUsers });
                setStudyName(res.data.study.name);
                setDivisionId(res.data.study.divisionId);

                res.data.study.started ? setStarted(true) : setStarted(false);
                res.data.study.completed ? setCompleted(true) : setCompleted(false);

                // Get all users in the study's division
                const temp: SelectOption<number>[] = [];
                // Don't want users on the study to be displayed as an option; keep track of which users are on the study
                const alreadyAssignedUserIds = _.map(res.data.studyUsers, 'userId');
                getDivisionUsers(res.data.study.divisionId).then(res2 => {
                    res2.data.divisionUsers.forEach((type: DivisionUser) => {
                        if (!alreadyAssignedUserIds.includes(type.userId)) {
                            temp.push({
                                value: type.userId,
                                label: type.userName!
                            })
                        }
                    })
                })
                setDivisionUsers(temp);
            })
        }

        getReferenceStudyRoles().then(res => {
            setAllReferenceRoles(convertReferenceDataToSelectionOptions(res.data));
        });
    }, [id, updated]);

    return (
        <>
            <div className="page-heading">
                <Typography className="title">{DisplayTextFormat(studyName, 64)}</Typography>
            </div>
            <div className="page-body max-lg">
                <div className="aligned-row" style={{ paddingBottom: 10 }}>
                    <div className="page-title">Employees</div>
                </div>
                {/*Loop through each study role and create a table for each*/}
                <Grid container spacing={3}>
                    {allReferenceRoles.map((studyRole: any, idx: number) => {
                        return (
                            <Grid key={'studyRole-' + idx} item xs={6}>
                                <StudyRoleTable studyId={Number(id)} divisionId={divisionId}
                                    divisionUsers={divisionUsers} studyRole={studyRole}
                                    allStudyUsers={objects} update={updated} setUpdate={setUpdated}
                                    started={started} completed={completed}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        </>
    )
}

export default StudyEmployees;

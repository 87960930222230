import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse, Hidden, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { CallMade, ExpandLess, ExpandMore } from '@material-ui/icons';

import { Store } from '../../modules/rootReducer';
import { CollapsiblePanelState, toggleCollapsiblePanel } from '../../modules/collapsiblePanelReducer';


interface Props {
    contents: React.ReactNode;
    button?: {
        contents: React.ReactNode;
        permission: boolean;
    }
}

const CollapsibleFilterPanel: React.FC<Props> = props => {
    const {
        contents,
        button
    } = props;

    // Manage the expanded state in the Redux store
    const dispatch = useDispatch();
    const { expanded } = useSelector<Store, CollapsiblePanelState>(
        store => store.collapsiblePanelReducer
    );
    const toggleExpanded = () => {
        dispatch(toggleCollapsiblePanel())
    }

    return (
        <div className="collapsible-panel">
            <Hidden smDown>
                <div className={expanded ? 'side-bar' : 'side-bar collapsed'}>
                    <List>
                        <ListItem
                            button
                            id="toggle-side-panel"
                            className='toggle'
                            onClick={toggleExpanded}
                        >
                            <CallMade />
                            <ListItemText primary="Collapse Panel" />
                        </ListItem>
                        {button && button.permission && button.contents}
                        {contents}
                    </List>
                    <div className={expanded ? 'filler' : 'filler collapsed'} />
                </div>
            </Hidden>
            <Hidden mdUp>
                <div className="top-bar">
                    <List>
                        <ListItem
                            button
                            id="toggle-top-panel"
                            onClick={toggleExpanded}
                        >
                            <ListItemText primary={<Typography>Filters</Typography>} />
                            {expanded ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <div className="spacer" />
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            {button && button.permission && button.contents}
                            {contents}
                        </Collapse>
                    </List>
                </div>
            </Hidden>
        </div>
    )
}

export default CollapsibleFilterPanel;
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import jwt_decode from 'jwt-decode';
import { AxiosError } from 'axios';

import { DecodedToken } from '../../@types';
import axiosInstance from '../../service/Access/axiosInstance';
import { requestToken } from '../../service/Access/authentication';
import { Store } from '../../modules/rootReducer';
import { clearJWT } from '../../modules/loginJwtReducer';
import { clearStoreLogout } from '../../modules/loginReducer';
import { showErrorSnackbar } from '../../modules/messageSnackbarReducer';

const TokenRefresher: React.FC = () => {
    const SERVICE_TOKEN_REFRESH_IN_SECONDS = 15 * 60; // refresh at 15 minutes before expiration
    const accessToken = useSelector<Store, string>(
        store => store.loginJwtReducer.accessToken
    );

    const dispatch = useDispatch();

    const [timer, setTimer] = React.useState<NodeJS.Timeout>();

    const resetTimer = () => {
        if (timer) {
            clearTimeout(timer);
            setTimer(undefined);
        }
    }
    React.useEffect(() => {

        if (accessToken && accessToken !== '') {
            const now = + new Date();
            const decodedToken: DecodedToken = jwt_decode(accessToken);
            const millSecondsToExpire = decodedToken.exp * 1000 - now;
            const millSecondsToRefresh = millSecondsToExpire - SERVICE_TOKEN_REFRESH_IN_SECONDS * 1000;
            resetTimer();

            const requestTimer = setTimeout(() => {
                requestToken(accessToken).then(async res => {
                    // Set the new access and refresh tokens
                    const newAccessToken = res.data.accessToken;
                    axiosInstance.defaults.headers.common.Authorization = 'Bearer ' + newAccessToken;
                    localStorage.setItem('accessToken', newAccessToken);
                }).catch((err: AxiosError) => {
                    axiosInstance.defaults.headers.common.Authorization = '';
                    localStorage.removeItem('accessToken');
                    dispatch(clearStoreLogout());
                    dispatch(clearJWT());
                    if (err.response) {
                        if (err.response.status === 401) {
                            if (err.response.data.message === 'User has been deactivated.') {
                                dispatch(showErrorSnackbar('The user associated with this email has been deactivated. Please contact your division/institution administrator for more details.'));
                            } else {
                                dispatch(showErrorSnackbar('Your session has ended. Please log in again.'));
                            }
                        } else if (err.response.status === 400) {
                            const index = err.response.data.message.indexOf('(');
                            const message = err.response.data.message.substring(0, index-1);
                            if (message === 'Refresh Token has been revoked') {
                                dispatch(showErrorSnackbar('Your session has ended. Please log in again.'));
                            } else {
                                dispatch(showErrorSnackbar('Unexpected error. Please log in again.'));
                            }
                        }
                    }
                });

            }, millSecondsToRefresh);
            setTimer(requestTimer);
        } else { //logged out
            resetTimer();
        }
    }, [accessToken])

    // Intentionally has null return because we don't need to render any TSX
    return null;
}

export default TokenRefresher;

import * as React from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { Card, CardActions, CardContent, Grid } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { BButton, BForm, BOption, BSelect, BSubmit, BTextField } from 'mui-bueno';

import { Division, DivisionWithUsers, SelectOption } from '../../../@types';
import { showSuccessSnackbar } from '../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../service/utils';
import { createDivision, deleteDivision, getDivisionUsers, updateDivision } from '../../../service/Management/divisions';

import usePermissions from '../../../common/hooks/usePermissions';
import TypeConfirmDelete from '../../../common/ConfirmDelete/TypeConfirmDelete';
import DivisionUsers from './DivisionUsers';
import CardTitle from '../../../common/Card/CardTitle';


interface Props {
    id: string;
    allDivisions: Division[];
    updated: any;
    allReferenceUsers: SelectOption<number>[];
    allReferenceRoles: SelectOption<number>[];
}

const schema = yup.object<Division>().shape({
    name: yup.string().required('Name is required'),
});

const emptyDivisionWithUsers: DivisionWithUsers = {
    division: {
        id: undefined,
        name: '',
        parentDivisionId: null
    },
    divisionUsers: []
};

const DivisionDetail: React.FC<Props> = props => {
    const { id, allDivisions, updated } = props;

    const history = useHistory();
    const dispatch = useDispatch();

    // Current division details in edit/add
    const [selectedDivision, setSelectedDivision] = React.useState<DivisionWithUsers>(emptyDivisionWithUsers);

    const updateMode = (id !== 'new');

    // check by permission only since the permission alone covers for all divisions.
    const canDelete = usePermissions('API_DIVISION_DELETE_BY_ID');
    const canUpdate = usePermissions('API_DIVISION_UPDATE');
    const canCreate = usePermissions('API_DIVISION_CREATE');

    // holding the newly added Ids to grant permission without requiring re-login
    const [newlyAddedIds, setNewlyAddedIds] = React.useState<number[]>([]);

    const loadDivisionDetail = () => {
        if (!!id && id !== 'new') {
            getDivisionUsers(Number(id))
                .then(res => {
                    setSelectedDivision(res.data);
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not retrieve list of Division Users: '
                    });
                });
        } else {
            setSelectedDivision(emptyDivisionWithUsers);
        }
    }

    const [parentDivisionOptions, setParentDivisionOptions] = React.useState<BOption<any>[]>([]);

    React.useEffect(() => {
        loadDivisionDetail();
        // First create a BOption for no parent division
        const noParentDivision: BOption<number | null> = {
            value: null,
            label: 'No Parent Division'
        }
        // For each division, store the division as a BOption
        const tempDivisions: BOption<number | null>[] = [noParentDivision];
        allDivisions.forEach(d => {
            if (id === 'new' || '' + d.id != id) {
                tempDivisions.push({
                    value: d.id as number,
                    label: d.name
                })
            }
        });

        // Update the list of options when opening the division details
        setParentDivisionOptions(tempDivisions);
        // Reset the confirm delete status when opening a different division
        setDeleteOpen(false);
    }, [id, allDivisions]);

    const handleValidate = (data: Division) => {
        const errorList: { [k: string]: string } = {};
        schema.validate(data, { abortEarly: false })
            .catch((err: yup.ValidationError) => {
                for (const e of err.inner) {
                    if (e.path) errorList[e.path] = e.message;
                }
            });
        return errorList;
    }

    const handleSubmit = async (data: Division, { setErrors }: FormikHelpers<Division>) => {
        if (updateMode) {
            updateDivision({ ...data })
                .then(res => {
                    updated(true);
                    dispatch(showSuccessSnackbar(`Division ${selectedDivision.division.name} updated`));
                    history.push(`/manage/division/${selectedDivision.division.id}`);
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        setStatus: setErrors,
                        prefix: 'Could not update division: '
                    });
                });
        } else {
            createDivision({ ...data })
                .then(res => {
                    setNewlyAddedIds(newlyAddedIds.concat(Number(res.data.id)));
                    updated(true);
                    dispatch(showSuccessSnackbar(`Division ${res.data.name} created`));
                    history.push(`/manage/division/${res.data.id}`);
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        setStatus: setErrors,
                        prefix: 'Could not create Division: '
                    });
                });
        }
    }

    // Delete Division Handlers
    const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);
    const handleDelete = () => { setDeleteOpen(true) }
    const handleConfirmDelete = () => {
        deleteDivision(selectedDivision.division.id!)
            .then(() => {
                updated(true);
                dispatch(showSuccessSnackbar(`Deleted division "${selectedDivision.division.name}"`));
                history.push('/manage/division');
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not delete Division: '
                });
            });
    }
    const deleteButton = () => {
        return (
            <BButton
                variant='contained'
                startIcon={<Delete />}
                className='delete-color'
                onClick={handleDelete}
            >
                Delete
            </BButton>
        )
    }

    const actionButtons = () => {
        if (updateMode && (canDelete || canUpdate)) {
            return (
                <CardActions className="space-between">
                    {canDelete && deleteButton()}
                    {canUpdate && <BSubmit>Save</BSubmit>}
                </CardActions>
            )
        } else if (!updateMode && canCreate) {
            return (
                <CardActions className="flex-end">
                    <BSubmit>Create</BSubmit>
                </CardActions>
            )
        } else {
            return null;
        }
    }

    return (
        <>
            <Formik
                initialValues={selectedDivision.division}
                values={selectedDivision.division}
                onSubmit={handleSubmit}
                validate={handleValidate}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
            >
                <BForm>
                    <Card className="detail-form">
                        <CardTitle
                            title={updateMode ? 'Edit Division' : 'Add Division'}
                            handleClose={() => history.push('/manage/division')}
                        />
                        <CardContent>
                            <Grid container spacing={1} justifyContent='center'>
                                <Grid item xs={12} sm={6} lg={6}>
                                    <BTextField
                                        name="name"
                                        label="Name"
                                        placeholder="Division Name"
                                        required
                                        disabled={!canCreate && !canUpdate}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} lg={6}>
                                    <BSelect
                                        name="parentDivisionId"
                                        label="Parent Division"
                                        placeholder="No Parent Division"
                                        options={parentDivisionOptions}
                                        variant='outlined'
                                        disabled={!canCreate && !canUpdate}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        {actionButtons()}
                    </Card>
                </BForm>
            </Formik >
            {updateMode && selectedDivision && selectedDivision.division.id &&
                <>
                    <DivisionUsers divisionId={selectedDivision.division.id}
                        userList={selectedDivision.divisionUsers}
                        allReferenceUsers={props.allReferenceUsers}
                        allReferenceRoles={props.allReferenceRoles}
                        newlyAddedIds={newlyAddedIds}
                    />
                    <TypeConfirmDelete open={deleteOpen} setOpen={setDeleteOpen}
                        type='Division' objectName={selectedDivision.division.name}
                        handleDelete={handleConfirmDelete}
                    />
                </>
            }
        </>
    )
};

export default DivisionDetail;
import axiosInstance from '../Access/axiosInstance';
import { ContactUs } from '../../@types';
import { handleError } from '../utils';


/**
 * Send email contact us
 */
export const sendEmailContactUs = (contact: ContactUs) =>
    axiosInstance.post(
        '/api/contact', contact
    ).catch(handleError);


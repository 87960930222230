import * as React from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button, FormControlLabel, FormGroup, Switch, Table,
    TableBody, TableCell, TableContainer, TableHead,
    TableRow, Tooltip, Typography
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Beenhere, NavigateNext } from '@material-ui/icons';

import { Calculation, Division } from '../../@types';
import { calculationToLocalDate, capitalizeFirstLetter, displayDate, shortMonthString } from '../../common/Utils/utils';
import { DisplayTextFormat } from '../../common/Utils/DisplayTextFormat';
import { displayStatisticsValue } from '../../common/Utils/NumberFormatter';
import { DataGrid, DataRow, DataRowHeader, studyToDataRowHeader, userToDataRowHeader } from '../../common/DataTable/Utils';
import { handleErrorResponse } from '../../service/utils';
import { getDivision } from '../../service/Management/divisions';
import { getLifetimeSumsByDivision, getMonthlyDivisionCalculations, getYearlySumsByDivision } from '../../service/Study/calculations';
import { getDivisionStudies } from '../../service/Study/study';
import { getDivisionUsers } from '../../service/Management/users';
import { Store } from '../../modules/rootReducer'
import { CollapsiblePanelState } from '../../modules/collapsiblePanelReducer';

import YearSelector from '../../common/DataTable/YearSelector';


type DataType = 'study' | 'employee';
type DataMode = 'activity' | 'cost' | 'productivity';

interface Props {
    divisionId: number | null;
}

const DivisionStatistics: React.FC<Props> = props => {
    const { divisionId } = props;

    const { expanded } = useSelector<Store, CollapsiblePanelState>(
        store => store.collapsiblePanelReducer
    );

    const history = useHistory();
    const dispatch = useDispatch();

    const currentYear: number = new Date().getFullYear();
    const currentMonth: number = new Date().getMonth();

    const [editYear, setEditYear] = React.useState<number>(currentYear);
    const [division, setDivision] = React.useState<Division>();
    const [dataType, setDataType] = React.useState<DataType>('study');
    const [showCompleted, setShowCompleted] = React.useState<boolean>(false);
    const [dataMode, setDataMode] = React.useState<DataMode>('productivity');
    const [hoveredCell, setHoveredCell] = React.useState<[number, number]>([-1, -1]);

    const [rowObjects, setRowObjects] = React.useState<DataRowHeader[]>([]);
    const [filteredRowObjects, setFilteredRowObjects] = React.useState<DataRowHeader[]>([]);
    const [dataGrid, setDataGrid] = React.useState<DataGrid<Calculation>>(new DataGrid<Calculation>());
    const [monthlySums, setMonthlySums] = React.useState<DataRow<number>>({});
    const [fiscalYearRow, setFiscalYearRow] = React.useState<DataRow<number>>({});
    const [fiscalYearSum, setFiscalYearSum] = React.useState<number>(0);
    const [lifetimeRow, setLifetimeRow] = React.useState<DataRow<number>>({});
    const [lifetimeSum, setLifetimeSum] = React.useState<number>(0);

    const disableCell = (month: number): boolean => editYear === currentYear && month > currentMonth;

    const clearData = (): void => {
        setDivision(undefined);
        setRowObjects([]);
        setDataGrid(new DataGrid<Calculation>());
        setMonthlySums({});
        setFiscalYearRow({});
        setFiscalYearSum(0);
        setLifetimeRow({});
        setLifetimeSum(0);
    };

    const studyInFilteredRowObjects = (studyId: number) => filteredRowObjects.find(study => (dataType === 'study') ? (study.object.id === studyId) : true);

    const getDataTypeId = (calculation: Calculation): number => {
        if (dataType === 'study')
            return calculation.studyId ?? -1
        else if (dataType === 'employee')
            return calculation.userId ?? -1;
        return -1;
    };

    const getStartedDate = (object: DataRowHeader): Date | null => {
        if (dataType === 'study')
            return object.object.started ?? null;
        return null;
    };
    const getCompletedDate = (object: DataRowHeader): Date | null => {
        if (dataType === 'study')
            return object.object.completed ?? null;
        return null;
    };

    const handleObjectLink = (id: number, year?: number): void => {
        if (dataType === 'study') {
            history.push(`/study/${id}/statistics${year ? '/' + year : ''}`);
        } else if (dataType === 'employee') {
            history.push(`/manage/user/${id}/statistics${year ? '/' + year : ''}`);
        }
    };

    React.useEffect(() => {
        clearData();
        if (!divisionId) return;
        getDivision(divisionId)
            .then(res => {
                setDivision(res.data);
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve Division: '
                });
            });
    }, [divisionId]);

    React.useEffect(() => {
        if (!divisionId) return;
        if (dataType === 'study') {
            getDivisionStudies(divisionId)
                .then(res => {
                    setRowObjects(res.data.map(study => studyToDataRowHeader(study)));
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not retrieve list of Studies: '
                    });
                });
        } else if (dataType === 'employee') {
            getDivisionUsers(divisionId)
                .then(res => {
                    setRowObjects(res.data.map(user => userToDataRowHeader(user)));
                })
                .catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not retrieve list of Users: '
                    });
                });
        }
    }, [divisionId, dataType]);

    React.useEffect(() => {
        if (!divisionId) return;
        if (filteredRowObjects.length === 0) return;
        getMonthlyDivisionCalculations(dataType, dataMode, divisionId, editYear)
            .then(res => {
                const convertedCalculations: Calculation[] = res.data.map(calculation => calculationToLocalDate(calculation));

                const tempGrid: DataGrid<Calculation> = new DataGrid<Calculation>();
                const tempSums: DataRow<number> = {};
                convertedCalculations.forEach(calculation => {
                    const index: number = getDataTypeId(calculation);
                    const month: number = calculation.associatedDate.getUTCMonth();

                    tempGrid.setByIndex(index, month, calculation);
                    if (studyInFilteredRowObjects(calculation.studyId ?? -1))
                        tempSums[month] = (tempSums[month] ?? 0) + calculation.value;
                });
                setDataGrid(tempGrid);
                setMonthlySums(tempSums);
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve list of Calculations: '
                });
            });

        getYearlySumsByDivision(dataType, dataMode, divisionId, editYear)
            .then(res => {
                const convertedStudyFiscalYearSums: Calculation[] = res.data.map(calculation => calculationToLocalDate(calculation));

                const tempRow: DataRow<number> = {};
                let tempSum: number = 0;
                convertedStudyFiscalYearSums.forEach(calculation => {
                    const index: number = getDataTypeId(calculation);
                    tempRow[index] = calculation.value;
                    if (studyInFilteredRowObjects(calculation.studyId ?? -1))
                        tempSum += calculation.value;
                });
                setFiscalYearRow(tempRow);
                setFiscalYearSum(tempSum);
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve list of yearly Calculation sums: '
                });
            });
    }, [divisionId, editYear, dataType, dataMode, filteredRowObjects]);

    React.useEffect(() => {
        if (!divisionId) return;
        getLifetimeSumsByDivision(dataType, dataMode, divisionId)
            .then(res => {
                const convertedStudyLifetimeSums: Calculation[] = res.data.map(calculation => calculationToLocalDate(calculation));

                const tempRow: DataRow<number> = {};
                let tempSum: number = 0;
                convertedStudyLifetimeSums.forEach(calculation => {
                    const index: number = getDataTypeId(calculation);
                    tempRow[index] = calculation.value;
                    if (studyInFilteredRowObjects(calculation.studyId ?? -1))
                        tempSum += calculation.value;
                });
                setLifetimeRow(tempRow);
                setLifetimeSum(tempSum);
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve list of lifetime Calculation sums: '
                });
            });
    }, [divisionId, dataType, dataMode]);

    React.useEffect(() => {
        if ((dataType === 'study') && !showCompleted)
            setFilteredRowObjects(rowObjects.filter(study => !study.object.completed));
        else
            setFilteredRowObjects(rowObjects);
    }, [rowObjects, showCompleted]);

    React.useEffect(() => {
        if (dataType === 'employee') return; // Not currently filtering Employees

        // Update Fiscal Year and Lifetime sums
        let tempFiscalYearSum: number = 0;
        let tempLifetimeSum: number = 0;
        for (const key in lifetimeRow) {
            if (filteredRowObjects.find(study => study.object.id === +key))
                tempLifetimeSum += lifetimeRow[key];
        }
        for (const key in fiscalYearRow) {
            if (filteredRowObjects.find(study => study.object.id === +key))
                tempFiscalYearSum += fiscalYearRow[key];
        }
        setFiscalYearSum(tempFiscalYearSum);
        setLifetimeSum(tempLifetimeSum);

        // Update monthly sums
        const tempMonthlySums: DataRow<number> = {};
        for (const studyKey in dataGrid.grid) {
            if (!filteredRowObjects.find(study => study.object.id === +studyKey)) continue;
            for (const monthKey in dataGrid.grid[studyKey]) {
                tempMonthlySums[monthKey] = (tempMonthlySums[monthKey] ?? 0) + dataGrid.grid[studyKey][monthKey].value;
            }
        }
        setMonthlySums(tempMonthlySums);
    }, [filteredRowObjects]);

    const blankRow = (key: number) => {
        return (
            <TableRow className="background-color-paper">
                <TableCell align="left" className="header-cell sticky" data-column="0">---</TableCell>
                <TableCell align="right" className="data-cell sticky" data-column="1">---</TableCell>
                <TableCell align="right" className="data-cell sticky" data-column="2">---</TableCell>
                {Array.from(Array(12).keys()).map(month => {
                    return (
                        <TableCell
                            key={`empty-row-${key}-month-${month}`}
                            align="right"
                            className="data-cell"
                            data-disabled={disableCell(month)}
                        >
                            ---
                        </TableCell>
                    );
                })}
            </TableRow>
        );
    };

    return (
        <>
            <div className="page-heading">
                <Typography
                    className="title inactive cursor-pointer"
                    onClick={() => history.push('/divisions')}
                >
                    Division Statistics
                </Typography>
                <React.Fragment>
                    <NavigateNext className="arrow" />
                    <Typography className="title">
                        {DisplayTextFormat(division?.name ?? 'No Divison Selected')}
                    </Typography>
                </React.Fragment>
            </div>
            <div className="page-body">
                <div className="table-options-info">
                    <div className="row left">
                        <Typography>Type:</Typography>
                        <ToggleButtonGroup
                            value={dataType}
                            onChange={((event: any, value: any) => setDataType(value))}
                            className="mode-toggle-group"
                            style={{ marginLeft: '0.5vw' }}
                            size="small"
                            exclusive
                        >
                            <ToggleButton value="study" disabled={dataType === 'study'}>
                                <Tooltip arrow title="Display Studies in Division">
                                    <Typography>Study</Typography>
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton value="employee" disabled={dataType === 'employee'}>
                                <Tooltip arrow title="Display Employees in Division">
                                    <Typography>Employee</Typography>
                                </Tooltip>
                            </ToggleButton>
                        </ToggleButtonGroup>
                        {dataType === 'study' && <FormGroup style={{ paddingLeft: '2vw' }}>
                            <FormControlLabel
                                control={<Switch checked={showCompleted} onChange={((event: any, value: boolean) => setShowCompleted(value))} />}
                                label="Show Completed"
                            />
                        </FormGroup>}
                        <Typography style={{ paddingLeft: '1.0vw' }}>Mode:</Typography>
                        <ToggleButtonGroup
                            value={dataMode}
                            onChange={((event: any, value: any) => setDataMode(value))}
                            className="mode-toggle-group"
                            style={{ marginLeft: '0.5vw' }}
                            size="small"
                            exclusive
                        >
                            <ToggleButton value="activity" disabled={dataMode === 'activity'} data-mode={dataMode}>
                                <Tooltip arrow title={`Display logged Visit Activity for each ${capitalizeFirstLetter(dataType)}`}>
                                    <Typography>Activity</Typography>
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton value="cost" disabled={dataMode === 'cost'} data-mode={dataMode}>
                                <Tooltip arrow title={`Display resource Cost as the sum of monthly Employee Salaries multiplied by the percent Effort logged to each ${capitalizeFirstLetter(dataType)}`}>
                                    <Typography>Cost</Typography>
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton value="productivity" disabled={dataMode === 'productivity'} data-mode={dataMode}>
                                <Tooltip arrow title={`Display Productivity as the difference between total logged ${capitalizeFirstLetter(dataType)} Activity and total resource Costs logged to the ${capitalizeFirstLetter(dataType)}`}>
                                    <Typography>Productivity</Typography>
                                </Tooltip>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <YearSelector year={editYear} setYear={setEditYear} />
                </div>
                <div className={`division-statistics-table constrainable ${expanded ? 'expanded' : 'collapsed'}`}>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={3} className="header-cell background" style={{ zIndex: 4, border: 'none' }} />
                                    {Array.from(Array(12).keys()).map(month => {
                                        return (
                                            <TableCell
                                                key={month + '-header'}
                                                className="header-cell"
                                                data-disabled={disableCell(month)}
                                                data-highlight={hoveredCell[0] === month}
                                                style={{ borderRadius: !month ? '3px 0 0 0' : '' }}
                                            >
                                                {shortMonthString(month, editYear)}<br />{editYear}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead >
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left" className="sub-header-cell sticky" style={{ zIndex: 4, borderRadius: '3px 0 0 0' }} data-column="0" data-mode={dataMode}>
                                        {`${capitalizeFirstLetter(dataType)} ${capitalizeFirstLetter(dataMode)}`}
                                    </TableCell>
                                    <TableCell align="right" className="sub-header-cell sticky" style={{ zIndex: 4 }} data-column="1" data-mode={dataMode}>Total<br />(FY {editYear})</TableCell>
                                    <TableCell align="right" className="sub-header-cell sticky" style={{ zIndex: 4 }} data-column="2" data-mode={dataMode}>Total<br />(Lifetime)</TableCell>
                                    <TableCell colSpan={12} className="sub-header-cell" style={{ zIndex: 3 }} data-mode={dataMode} />
                                </TableRow>
                                {filteredRowObjects.map((obj, objIndex) => {
                                    const tooltip = <div className="column tip-padding">
                                        <span> {obj.name} </span>
                                        {obj.object.started && <span> Started: {displayDate(getStartedDate(obj))} </span>}
                                        {obj.object.completed && <span> Completed: {displayDate(getCompletedDate(obj))} </span>}
                                    </div>;
                                    return (
                                        <TableRow key={`study-row-${obj.id}`}>
                                            <Tooltip arrow title={tooltip}>
                                                <TableCell
                                                    align="left"
                                                    className="header-cell sticky button"
                                                    data-column="0"
                                                    data-highlight={hoveredCell[1] === obj.id}
                                                >
                                                    <Button
                                                        onClick={() => handleObjectLink(obj.id)}
                                                        endIcon={getCompletedDate(obj) && <Beenhere />}
                                                    >
                                                        {DisplayTextFormat(obj.name, 20, false)}
                                                    </Button>
                                                </TableCell>
                                            </Tooltip>
                                            <TableCell align="right" className="data-cell sticky" data-column="1">
                                                {displayStatisticsValue(fiscalYearRow[obj.id])}
                                            </TableCell>
                                            <TableCell align="right" className="data-cell sticky" data-column="2">
                                                {displayStatisticsValue(lifetimeRow[obj.id])}
                                            </TableCell>
                                            {Array.from(Array(12).keys()).map(month => {
                                                const disabled: boolean = disableCell(month)
                                                    || (obj.object.completed && (new Date(editYear, month, 1) >= obj.object.completed))
                                                    || (dataType === 'study' && !obj.object.started)
                                                    || (obj.object.started && obj.object.started >= new Date(editYear, month + 1, 1));
                                                const value: number | undefined = dataGrid.getByIndex(obj.id, month)?.value;
                                                return (
                                                    <TableCell
                                                        key={`study-${objIndex}-month-${month}`}
                                                        align="right"
                                                        className={`data-cell${!disabled ? ' button' : ''}`}
                                                        data-disabled={disabled}
                                                        onMouseOver={() => !disabled && setHoveredCell([month, obj.id])}
                                                        onMouseLeave={() => setHoveredCell([-1, -1])}
                                                    >
                                                        {disabled
                                                            ? displayStatisticsValue(value)
                                                            : <Button onClick={() => handleObjectLink(obj.id, editYear)}>
                                                                {displayStatisticsValue(value)}
                                                            </Button>}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                                {filteredRowObjects.length === 0 &&
                                    blankRow(0)
                                }
                                <TableRow>
                                    <TableCell align="right" className="header-cell sticky" data-column="0">Total</TableCell>
                                    <TableCell align="right" className="data-cell sticky" data-column="1">
                                        {displayStatisticsValue(fiscalYearSum)}
                                    </TableCell>
                                    <TableCell align="right" className="data-cell sticky" data-column="2">
                                        {displayStatisticsValue(lifetimeSum)}
                                    </TableCell>
                                    {Array.from(Array(12).keys()).map(month => {
                                        const disabled: boolean = disableCell(month);
                                        return (
                                            <TableCell
                                                key={`study-total-month-${month}`}
                                                align="right"
                                                className="data-cell"
                                                data-disabled={disabled}
                                                onMouseOver={() => !disabled && setHoveredCell([month, -1])}
                                                onMouseLeave={() => setHoveredCell([-1, -1])}
                                            >
                                                {displayStatisticsValue(monthlySums[month])}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </>
    );
};

export default DivisionStatistics;
import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import axiosInstance from '../../service/Access/axiosInstance';
import useLoggedInUser from '../hooks/useLoggedInUser';
import usePermissions from '../hooks/usePermissions';

import NotFound from './NotFound';

interface Props extends RouteProps {
    component: React.ComponentType<any>;
    title?: string;
}

export const ProtectedRoute: React.FC<Props> = props => {
    const { component, title, ...rest } = props;

    const user = useLoggedInUser();

    const Component = component;

    React.useEffect(() => {
        if (title) {
            document.title = title + ' | CRAFT';
        } else {
            document.title = 'CRAFT | Clinical Research Activity Financial Tracker';
        }
    }, [title]);

    const accessToken = localStorage.getItem('accessToken');

    // On refresh, if the authorization header is not set, access from local storage
    if (!axiosInstance.defaults.headers.common.Authorization && accessToken) {
        axiosInstance.defaults.headers.common.Authorization = 'Bearer ' + accessToken;
    }

    // List of paths only admins have access to
    const adminAccess = ['/manage'];

    const permissionGetUserSummaries: boolean = usePermissions('API_USER_GET_USER_SUMMARIES');
    const permissionGetRoleSummaries: boolean = usePermissions('API_ROLE_GET_ROLE_SUMMARIES');
    const permissionGetDivisionTree: boolean = usePermissions('API_DIVISION_GET_DIVISION_TREE');
    const canManage = permissionGetUserSummaries || permissionGetRoleSummaries || permissionGetDivisionTree;

    const permissionGetContracts: boolean = usePermissions('API_CONTRACT_GET_CONTRACTS');
    const permissionGetContractById: boolean = usePermissions('API_CONTRACT_GET_BY_ID');
    const canViewContracts = permissionGetContracts || permissionGetContractById;

    const permissionGetStudies: boolean = usePermissions('API_STUDY_GET_USER_STUDIES');
    const permissionGetStudyById: boolean = usePermissions('API_STUDY_GET_BY_ID');
    const canViewStudies = permissionGetStudies || permissionGetStudyById;

    const renderFn = (routerProps: any) => {
        if (user.id) {
            if (!canViewContracts && !canViewStudies) {
                return <NotFound />;
            }
            if (!canViewContracts && props.path?.includes('/contract')) {
                return <Redirect to={{ pathname: '/study', state: { from: props.location } }} />;
            }
            if (!canViewStudies && props.path?.includes('/study')) {
                return <Redirect to={{ pathname: '/contract', state: { from: props.location } }} />;
            }
            if (canManage || !adminAccess.some(path => props.path?.includes(path))) {
                return <Component {...routerProps} />;
            }
            return <NotFound />;
        }
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
    };

    return <Route {...rest} render={renderFn} />;
};

export default ProtectedRoute;
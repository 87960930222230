import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@material-ui/core';
import { Add, ArrowDropDown, Block, Check, Edit, Person, PersonOutline, Timeline } from '@material-ui/icons';

import { TableHeader, UserSummary } from '../../../@types';
import { DisplayTextFormat } from '../../../common/Utils/DisplayTextFormat';
import { showSuccessSnackbar } from '../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../service/utils';
import { activateUser, deactivateUser, getUserSummaries } from '../../../service/Management/users';
import usePermissions from '../../../common/hooks/usePermissions';
import useLoggedInUser from '../../../common/hooks/useLoggedInUser';

import SearchBar from '../../../common/SearchBar/SearchBar';
import UserAdd from './UserAdd';


const headers: TableHeader[] = [{
    type: 'lastName',
    displayValue: 'Last Name',
    align: 'left',
}, {
    type: 'firstName',
    displayValue: 'First Name',
    align: 'left',
}, {
    type: 'middleName',
    displayValue: 'Middle Name',
    align: 'left',
}, {
    type: 'active',
    displayValue: 'Active',
    align: 'center',
}];

interface State {
    objects: UserSummary[];
    rowsPerPage: number;
    requestedPage: number;
    orderBy: string;
    totalElements: number;
    page: number;
}

const initialState: State = {
    objects: [],
    rowsPerPage: 10,
    requestedPage: 0,
    orderBy: 'lastName',
    totalElements: 0,
    page: 0,
}

interface Action {
    type: 'objectPage' | 'requestedPage' | 'rowsPerPage' | 'orderBy';
    payload?: any;
}

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'objectPage':
            return {
                ...state,
                objects: action.payload.list,
                totalElements: action.payload.total,
                page: action.payload.page
            }
        case 'requestedPage':
            return {
                ...state,
                requestedPage: action.payload
            }
        case 'rowsPerPage':
            return {
                ...state,
                rowsPerPage: action.payload,
                page: 0,
                requestedPage: 0
            }
        case 'orderBy':
            return {
                ...state,
                orderBy: action.payload
            }
        default:
            return state;
    }
}

const UserList: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const permissionCreateUser: boolean = usePermissions('API_USER_CREATE');
    const permissionActivateUser: boolean = usePermissions('API_USER_ACTIVATE');
    const permissionDeactivateUser: boolean = usePermissions('API_USER_DEACTIVATE');
    const permissionGetUserStudies: boolean = usePermissions('API_STUDY_GET_USER_STUDIES');
    const permissionGetEffortLogsByYear: boolean = usePermissions('API_EFFORT_LOG_GET_USER_EFFORT_LOGS_BY_YEAR');

    const logonUserId = useLoggedInUser().id;

    const [userSummaries, setUserSummaries] = React.useState<UserSummary[]>([]);
    const [query, setQuery] = React.useState<string>('');
    const [newUserModalOpen, setNewUserModalOpen] = React.useState(false);
    const [updated, setUpdated] = React.useState<boolean>(false);
    const [state, dispatchPage] = React.useReducer(reducer, initialState);
    const {
        objects,
        rowsPerPage,
        requestedPage,
        orderBy,
        totalElements,
        page
    } = state;

    React.useEffect(() => {
        getUserSummaries()
            .then(res => {
                setUserSummaries(res.data);
                dispatchPage({ type: 'objectPage', payload: { list: res.data, page: 0, total: res.data.length } });
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve list of Users: '
                });
            });
    }, [updated]);

    React.useEffect(() => {
        if (userSummaries.length > 0) {
            let filteredList: UserSummary[] = userSummaries;

            if (Number(query) !== 0) {
                filteredList = filteredList.filter(user =>
                    user.firstName.toLowerCase().indexOf(query.toLowerCase()) !== -1
                    || (user.middleName && user.middleName.toLowerCase().indexOf(query.toLowerCase()) !== -1)
                    || user.lastName.toLowerCase().indexOf(query.toLowerCase()) !== -1
                );
            }

            if (['firstName', 'middleName', 'lastName'].includes(orderBy)) {
                filteredList = filteredList.sort((a, b) => (a[orderBy] ?? '').localeCompare(b[orderBy] ?? ''));
            } else if (['active'].includes(orderBy)) {
                filteredList = filteredList.sort((a, b) => (+a[orderBy] - +b[orderBy]) * -1);
            }

            let displayList: UserSummary[] = filteredList;
            if (filteredList.length > rowsPerPage) {
                displayList = filteredList.slice((requestedPage * rowsPerPage), ((1 + requestedPage) * rowsPerPage));
            }

            dispatchPage({
                type: 'objectPage',
                payload: {
                    list: displayList,
                    page: requestedPage,
                    total: filteredList.length
                }
            });
        }
        setUpdated(false);
    }, [userSummaries, requestedPage, rowsPerPage, orderBy, query, updated]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        dispatchPage({ type: 'requestedPage', payload: newPage });
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        dispatchPage({ type: 'rowsPerPage', payload: parseInt(event.target.value, 10) });
    }

    const handleChangeSortBy = (header: string) => (event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => {
        dispatchPage({ type: 'orderBy', payload: header });
    }

    const handleNewUserModalOpen = () => {
        setNewUserModalOpen(true);
    }

    const handleDeactivate = (id: undefined | number) => {
        if (id) {
            deactivateUser(id)
                .then(res => {
                    setUpdated(true);
                    dispatch(showSuccessSnackbar(`${res.data.firstName} ${res.data.lastName} deactivated`));
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not deactivate User: '
                    });
                });
        }
    }

    const handleActivate = (id: undefined | number) => {
        if (id) {
            activateUser(id)
                .then(res => {
                    setUpdated(true);
                    dispatch(showSuccessSnackbar(`${res.data.firstName} ${res.data.lastName} activated`));
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not activate User: '
                    });
                });
        }
    }

    const paginationFeatures = (full: boolean) => {
        return (
            <TablePagination
                component="div"
                count={totalElements ?? initialState.totalElements}
                rowsPerPage={rowsPerPage}
                page={page ?? initialState.page}
                rowsPerPageOptions={[5, 10, 20, 30]}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Items Per Page:"
                className={full ? '' : 'paging-arrows'}
            />
        )
    }

    const columnValue = (type: string, value: any) => {
        if (type === 'active') {
            if (value === true) {
                return <Check className="success-color" />;
            } else {
                return <Block className="error-color" />
            }
        }
        return DisplayTextFormat(value);
    }

    return (
        <>
            <div className="page-heading">
                <Typography className="title">User Management</Typography>
            </div>
            <div className="page-body max-lgxl">
                <div className="aligned-row">
                    <div className="left">
                        <SearchBar query={query} setQuery={setQuery} />
                    </div>
                    <div className="right">
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Add />}
                            onClick={handleNewUserModalOpen}
                            disabled={!permissionCreateUser}
                        >
                            Add User
                        </Button>
                    </div>
                </div>
                {paginationFeatures(true)}
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead className="table-header">
                            <TableRow>
                                {headers.map(header => {
                                    return (
                                        <TableCell
                                            key={'header-' + header.type}
                                            onClick={handleChangeSortBy(header.type)}
                                            className={'cursor-pointer primary-cell ' +
                                                (orderBy === header.type && 'selected')
                                            }
                                        >
                                            <div className={`header-cell-label ${header.align}`}>
                                                {header.displayValue}
                                                {orderBy === header.type && <ArrowDropDown />}
                                            </div>
                                        </TableCell>
                                    )
                                })}
                                <TableCell key='header-actions' className='primary-cell'>
                                    <div className="header-cell-label center">
                                        Actions
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {objects.map((obj: any, idx: number) => {
                                return (
                                    <TableRow key={'row-' + idx}>
                                        {headers.map(header => {
                                            return (
                                                <TableCell
                                                    key={header.type + '-' + idx}
                                                    className={`body-cell ${header.align}`}
                                                >
                                                    {columnValue(header.type, obj[header.type])}
                                                </TableCell>
                                            )
                                        })}
                                        <TableCell
                                            key={'actions-' + idx}
                                            className={'body-cell center'}
                                        >
                                            <Tooltip arrow title='View/Edit User Details'>
                                                <IconButton
                                                    id={`user-${obj.id}-edit`}
                                                    onClick={() => history.push(`/manage/user/${obj.id}/details`)}
                                                    style={{ padding: '6px' }}
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                            {permissionGetUserStudies && permissionGetEffortLogsByYear &&
                                                <Tooltip arrow title='View User Statistics'>
                                                    <IconButton
                                                        id={`user-${obj.id}-statistics`}
                                                        onClick={() => history.push(`/manage/user/${obj.id}/statistics`)}
                                                        style={{ padding: '6px' }}
                                                    >
                                                        <Timeline />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            {(obj.id !== logonUserId) && (obj.active
                                                ? (permissionDeactivateUser && 
                                                    <Tooltip arrow title="Deactivate User">
                                                        <IconButton
                                                            id={`user-${obj.id}-deactivate`}
                                                            onClick={() => handleDeactivate(obj.id)}
                                                            style={{ padding: '6px' }}
                                                        >
                                                            <PersonOutline />
                                                        </IconButton>
                                                    </Tooltip>
                                                )
                                                : (permissionActivateUser && 
                                                    <Tooltip arrow title="Activate User">
                                                        <IconButton
                                                            id={`user-${obj.id}-activate`}
                                                            onClick={() => handleActivate(obj.id)}
                                                            style={{ padding: '6px' }}
                                                        >
                                                            <Person />
                                                        </IconButton>
                                                    </Tooltip>
                                                )
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    {objects.length === 0 && <Typography variant="body1" className="flex-center margin-2">No data to display.</Typography>}
                </TableContainer>
                {paginationFeatures(false)}
                <UserAdd open={newUserModalOpen} setOpen={setNewUserModalOpen} />
            </div>
        </>
    )
}

export default UserList;
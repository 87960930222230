import * as React from 'react';
import { useDispatch } from 'react-redux';

import { Card, Divider, Typography } from '@material-ui/core';

import { InvoiceStatusAmounts, InvoiceStatusSummary } from '../../@types';
import StatusChip from './StatusChip';
import { NumberFormatter } from '../Utils/NumberFormatter';
import { handleErrorResponse } from '../../service/utils';
import { getStatusSummaryByAccount, getStatusSummaryByDivision, getStatusSummaryByStudy } from '../../service/Invoice/invoice';

interface Props {
    accountId?: number;
    divisionId?: number;
    studyId?: number;
    updateOnChange: boolean;
}

const InvoicesSummaryCard: React.FC<Props> = props => {

    const { divisionId, accountId, studyId, updateOnChange } = props;

    const dispatch = useDispatch();

    const [receivableStatusAmounts, setReceivableStatusAmounts] = React.useState<InvoiceStatusAmounts>();
    const [payableStatusAmounts, setPayableStatusAmounts] = React.useState<InvoiceStatusAmounts>();

    const [hasReceivables, setHasReceivables] = React.useState<boolean>(false);
    const [hasPayables, setHasPayables] = React.useState<boolean>(false);

    // manually calculate and totals fields
    const [receivableNotInvoiced, setReceivableNotInvoiced] = React.useState<number>(0);
    const [receivableNotSent, setReceivableNotSent] = React.useState<number>(0);
    const [receivableNotPaid, setReceivableNotPaid] = React.useState<number>(0);
    const [payableNotPaid, setPayableNotPaid] = React.useState<number>(0);
    const [payableNotSent, setPayableNotSent] = React.useState<number>(0);

    const setStatusAmounts = (data: InvoiceStatusSummary) => {
        setReceivableStatusAmounts(data.receivableInvoiceStatusAmounts);
        setPayableStatusAmounts(data.payableInvoiceStatusAmounts);
    };

    React.useEffect(() => {
        if (accountId) {
            // get by accountId
            getStatusSummaryByAccount(accountId).then(res => {
                setStatusAmounts(res.data);
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve Invoice Summary by account: '
                });
            });
        } else if (studyId) {
            // get by studyId
            getStatusSummaryByStudy(studyId).then(res => {
                setStatusAmounts(res.data);
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve Invoice Summary by study: '
                });
            });
        } else {
            // get by divisionId (undefined for all divisions)
            getStatusSummaryByDivision(divisionId).then(res => {
                setStatusAmounts(res.data);
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve Invoice Summary by division: '
                });
            });
        }
    }, [divisionId, accountId, studyId, updateOnChange]);

    React.useEffect(() => {
        let statusCounts;

        if (receivableStatusAmounts) {
            statusCounts = receivableStatusAmounts.statusCounts;

            setReceivableNotInvoiced(receivableStatusAmounts.amountNotInvoiced);
            setReceivableNotSent(statusCounts.NOT_SENT!.outstandingAmountForStatus);
            setReceivableNotPaid(statusCounts.NOT_PAID.outstandingAmountForStatus + statusCounts.PARTIAL.outstandingAmountForStatus);

            setHasReceivables(statusCounts.NOT_SENT!.count > 0 || statusCounts.NOT_PAID.count > 0 ||
                statusCounts.PARTIAL.count > 0 || statusCounts.CURRENT.count > 0 || statusCounts.PAID.count > 0)
        }

        if (payableStatusAmounts) {
            statusCounts = payableStatusAmounts.statusCounts;

            setPayableNotPaid(statusCounts.NOT_PAID.outstandingAmountForStatus + statusCounts.PARTIAL.outstandingAmountForStatus);
            if (statusCounts.NOT_SENT) {
                setPayableNotSent(statusCounts.NOT_SENT?.outstandingAmountForStatus);
                setHasPayables(statusCounts.NOT_SENT.count > 0 || statusCounts.NOT_PAID.count > 0 || statusCounts.PARTIAL.count > 0 ||
                    statusCounts.CURRENT.count > 0 || statusCounts.PAID.count > 0);
            } else {
                setHasPayables(statusCounts.NOT_PAID.count > 0 || statusCounts.PARTIAL.count > 0 ||
                    statusCounts.CURRENT.count > 0 || statusCounts.PAID.count > 0);
            }
        }
    }, [receivableStatusAmounts, payableStatusAmounts]);

    const getStatusChips = (data: InvoiceStatusAmounts) => {
        const statusCounts = data.statusCounts;

        return (
            <div className='statusBtns'>
                {statusCounts.NOT_SENT && statusCounts.NOT_SENT.count > 0 &&
                    <StatusChip count={statusCounts.NOT_SENT.count} status={'NOT_SENT'} />
                }
                {statusCounts.NOT_PAID.count > 0 &&
                    <StatusChip count={statusCounts.NOT_PAID.count} status={'NOT_PAID'} />
                }
                {statusCounts.PARTIAL.count > 0 &&
                    <StatusChip count={statusCounts.PARTIAL.count} status={'PARTIAL'} />
                }
                {statusCounts.CURRENT.count > 0 &&
                    <StatusChip count={statusCounts.CURRENT.count} status={'CURRENT'} />
                }
                {statusCounts.PAID.count > 0 &&
                    <StatusChip count={statusCounts.PAID.count} status={'PAID'} />
                }
            </div>
        )
    };

    const createRow = (label: string, value: number) => (
        <div className='total-row'>
            <Typography className='label'>{label}</Typography>
            <Typography className='value'>
                <NumberFormatter currency value={value} />
            </Typography>
        </div>
    );

    return (
        <Card className='invoices-summary-card'>
            <div className='card-content'>
                <div className='status-div'>
                    <Typography className='title'>Receivable</Typography>
                    {hasReceivables && receivableStatusAmounts && getStatusChips(receivableStatusAmounts)}
                </div>
                {receivableStatusAmounts ?
                    <div className='totals-div'>
                        {createRow('Total Not Invoiced', receivableNotInvoiced)}
                        {hasReceivables && createRow('Total Not Sent', receivableNotSent)}
                        {hasReceivables && createRow('Total Not Paid', receivableNotPaid)}
                        {hasReceivables && createRow('Total Held Back', receivableStatusAmounts.amountHeldback)}
                    </div> :
                    <Typography variant='body2' color='textSecondary'>No Invoices</Typography>
                }
            </div>
            <Divider />
            <div className='card-content'>
                <div className='status-div'>
                    <Typography className='title'>Payable</Typography>
                    {hasPayables && payableStatusAmounts && getStatusChips(payableStatusAmounts)}
                </div>
                {hasPayables && payableStatusAmounts ?
                    <div className='totals-div'>
                        {payableNotSent > 0 && createRow('Total Not Sent', payableNotSent)}
                        {createRow('Total Not Paid', payableNotPaid)}
                        {(payableStatusAmounts.amountHeldback > 0) && createRow('Total Held Back', payableStatusAmounts.amountHeldback)}
                    </div> :
                    <Typography variant='body2' color='textSecondary'>No Invoices</Typography>
                }
            </div>
        </Card >
    );
};

export default InvoicesSummaryCard;
/*** CONSTANTS ***/
export const SHOW_SNACKBAR = 'messageSnackbarReducer/SHOW_SNACKBAR';
export type SHOW_SNACKBAR = typeof SHOW_SNACKBAR;

export const CLEAR_SNACKBAR = 'messageSnackbarReducer/CLEAR_SNACKBAR';
export type CLEAR_SNACKBAR = typeof CLEAR_SNACKBAR;

export type MessageSnackbarType = 'success' | 'error' | 'warning' | 'general';

/*** ACTIONS ***/
export interface ShowMessageSnackbar {
    type: SHOW_SNACKBAR;
    payload: MessageSnackbarState;
}

interface ClearMessageSnackbar {
    type: CLEAR_SNACKBAR;
}

export type MessageSnackbarAction = ShowMessageSnackbar | ClearMessageSnackbar;

/*** ACTION CREATORS ***/
export const showSuccessSnackbar = (message: string): ShowMessageSnackbar => {
    return {
        type: SHOW_SNACKBAR,
        payload: {
            message,
            type: 'success'
        }
    };
};

export const showErrorSnackbar = (message: string): ShowMessageSnackbar => {
    return {
        type: SHOW_SNACKBAR,
        payload: {
            message,
            type: 'error'
        }
    };
};

export const showWarningSnackbar = (message: string): ShowMessageSnackbar => {
    return {
        type: SHOW_SNACKBAR,
        payload: {
            message,
            type: 'warning'
        }
    };
};

export const showGeneralSnackbar = (message: string): ShowMessageSnackbar => {
    return {
        type: SHOW_SNACKBAR,
        payload: {
            message,
            type: 'general'
        }
    };
};

export const clearSnackbar = (): ClearMessageSnackbar => {
    return {
        type: CLEAR_SNACKBAR
    };
};

/*** REDUCER ***/
export interface MessageSnackbarState {
    message: string;
    type: MessageSnackbarType;
}

export const initialState: MessageSnackbarState = {
    message: '',
    type: 'success'
};

export const reducer = (
    state: MessageSnackbarState = initialState,
    action: MessageSnackbarAction
): MessageSnackbarState => {
    switch (action.type) {
        case SHOW_SNACKBAR:
            return {
                ...state,
                message: action.payload.message,
                type: action.payload.type
            };
        case CLEAR_SNACKBAR:
            return {
                ...state,
                message: ''
            };
        /* istanbul ignore next */
        default:
            return state;
    }
};

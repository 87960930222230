import React from 'react';

import { TextField } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

import { SelectOption } from '../../@types';

interface Props {
    idText: string;
    multiple?: boolean;
    disabled?: boolean;
    acValue?: any;
    defaultValue?: any;
    options: SelectOption<number>[];
    labelText?: string;
    error?: boolean;
    closeOnSelect?: boolean;
    onChange: any;
    noCheckmark?: boolean;
    variant?: 'standard' | 'outlined';
    size?: 'small' | 'medium';
    placeholder?: string;
    InputLabelProps?: React.ReactNode;
    errorMessage?: any //use for specific error messages
    autoFocus?: boolean;
    required?: boolean;
}

const CheckedAutocomplete: React.FC<Props> = props => {
    const {
        idText, multiple, disabled, acValue, defaultValue, options, labelText, error, closeOnSelect, autoFocus,
        onChange, noCheckmark, variant = 'standard', size, placeholder, InputLabelProps, errorMessage, required,
    } = props;

    return (
        <Autocomplete
            id={idText}
            multiple={multiple}
            disabled={disabled}
            defaultValue={defaultValue}
            value={acValue}
            options={options}
            disableCloseOnSelect={!closeOnSelect}
            getOptionLabel={(option) => option.label}
            renderOption={(option, { selected }) => (
                <>
                    {!noCheckmark &&
                        <Check
                            color='secondary'
                            fontSize="small"
                            style={{
                                marginRight: 8,
                                padding: 0,
                                visibility: selected ? 'visible' : 'hidden',
                            }}
                        />
                    }
                    {option.label}
                </>
            )}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label={labelText ? labelText : null}
                    variant={variant} size={size}
                    error={error}
                    helperText={error ? errorMessage ?? 'Required' : null}
                    placeholder={placeholder}
                    InputLabelProps={InputLabelProps ?? {}}
                    autoFocus={autoFocus ?? false}
                    required={required}
                />
            }
            getOptionSelected={(option, value) => (option != null && value != null) ? option.value === value.value : false}
            onChange={(_event, newValue) => onChange(newValue)}
        />
    )
};

export default CheckedAutocomplete;
import * as React from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import { InvoiceStatus } from '../../@types';

interface Props {
    status: InvoiceStatus;
    count?: number;
    width?: 'small' | 'large';
    bottomMargin?: boolean;
}

const StatusChip: React.FC<Props> = props => {

    const { count = 0, status, bottomMargin, width } = props;

    // added displayStatus conditional due to type errors with null invoice status returned from LineItemDetailWithInvoice objects
    const displayStatus = status? status.split('_').join(' '): ''; 
    const displayCount = count >= 100? '100+' : count;
    
    const widthClass = width? `fixed-width-${width}` : '';
    const marginClass = bottomMargin? 'bottom-margin' : '';

    const getTooltipText = () => {
        const messages = new Map<InvoiceStatus, string>([
            ['NOT_INVOICED', 'An Invoice has not yet been generated containing this line item.'],
            ['NOT_SENT', 'Invoice has not been sent yet to the payor.'],
            ['NOT_PAID', 'Invoice has been sent to the payor, but no payments have been logged.'],
            ['PARTIAL', 'One or more of the line items in the invoice has been paid.'],
            ['CURRENT', 'Invoice has been paid minus holdback.'],
            ['PAID', 'Invoice has been fully paid.']
        ]);

        return (
            <>
                {count >= 100 &&
                    <Typography variant='body2' align='center'>{count + ' Invoices'}</Typography>
                }
                <Typography variant='body2' align='center'>{messages.get(status)}</Typography>
            </>
        );
    };

    return (
        <Tooltip arrow title={getTooltipText()}>
            <div className={`status-chip ${status} ${widthClass} ${marginClass}`}>
                {count?
                    <Typography>{displayCount} {displayStatus}</Typography> 
                    : <Typography>{displayStatus}</Typography>
                }
            </div>
        </Tooltip>
    );
};

export default StatusChip;
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@material-ui/core';
import { AssignmentOutlined, DateRange, FormatListBulleted, Launch, MonetizationOnRounded } from '@material-ui/icons';

import { handleErrorResponse } from '../../../service/utils';
import { getContract } from '../../../service/Contract/contracts';
import { getStudy } from '../../../service/Study/study';

import CollapsiblePanel, { NavItem } from '../../../common/CollapsiblePanel/CollapsiblePanel';
import PlannedCosts from './PlannedCosts/PlannedCosts';
import ContractOverview from './Overview/ContractOverview';
import ContractSchedules from './Schedules/ContractSchedules';
import Accounting from './Accounts/Accounts';

interface RouteParams {
    id: string;
    section: string;
}

const ContractDetails: React.FC = () => {
    const { id, section } = useParams<RouteParams>();

    const history = useHistory();
    const dispatch = useDispatch();

    const [contractId, setContractId] = React.useState<number | null>(null);
    const [studyId, setStudyId] = React.useState<number | null>(null);
    const [studyName, setStudyName] = React.useState<string>('');
    const [divisionId, setDivisionId] = React.useState<number | null>(null);
    const [signed, setSigned] = React.useState<Date | null>(null);
    const [updatedSigned, setUpdatedSigned] = React.useState<boolean>(false);
    const [updatedInfo, setUpdatedInfo] = React.useState<boolean>(false);
    const [accountId, setAccountId] = React.useState<number | null>(0);
    const [sponsorId, setSponsorId] = React.useState<number | null>(0);

    React.useLayoutEffect(() => {
        getContract(Number(id))
            .then(res => {
                setContractId(res.data.id);
                setSigned(res.data.signed);
                setStudyId(res.data.studyId);
                getStudy(res.data.studyId)
                    .then(studyRes => {
                        setDivisionId(studyRes.data.divisionId);
                        setStudyName(studyRes.data.name);
                        setAccountId(studyRes.data.accountId!);
                        setSponsorId(studyRes.data.sponsorId!);
                    }).catch(err => {
                        handleErrorResponse(err, dispatch, {
                            prefix: 'Could not retrieve Study: '
                        })
                    });
            }).catch(err => {
                setContractId(0);
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve Contract: '
                })
            });
        setUpdatedSigned(false);
        setUpdatedInfo(false);
    }, [id, updatedSigned, updatedInfo]);

    const navContractOptions: NavItem[] = [
        { title: 'Overview', icon: <AssignmentOutlined />, route: `/contract/${id}/overview` },
        { title: 'Schedule of Events', icon: <DateRange />, route: `/contract/${id}/schedule` },
        { title: 'Planned Costs', icon: <FormatListBulleted />, route: `/contract/${id}/planned-costs` },
        { title: 'Invoicing Accounts', icon: <MonetizationOnRounded />, route: `/contract/${id}/accounts` },
    ];

    // Only display the "Go to Study" feature if the contract has been signed and it has a study created
    if (signed) {
        navContractOptions.push({ title: 'Go to Study', icon: <Launch />, route: `/study/${studyId}` });
    }

    if (contractId) {
        return (
            <div className="page">
                <CollapsiblePanel navOptions={navContractOptions} />
                <div className="page-content">
                    <div className="inner">
                        {(section === 'overview') && <ContractOverview
                            contractId={contractId}
                            studyId={Number(studyId)}
                            divisionId={Number(divisionId)}
                            setUpdatedSigned={setUpdatedSigned}
                            setUpdatedInfo={setUpdatedInfo}
                        />}
                        {(section === 'planned-costs') && <PlannedCosts
                            contractId={contractId}
                            studyId={Number(studyId)}
                            divisionId={Number(divisionId)}
                            signed={signed ? true : false}
                        />}
                        {(section === 'schedule') && <ContractSchedules
                            studyId={Number(studyId)}
                            divisionId={Number(divisionId)}
                            studyName={studyName}
                            signed={signed ? true : false}
                        />}
                        {(section === 'accounts') && <Accounting
                            studyName={studyName}
                            accountId={accountId}
                            sponsorId={sponsorId}
                            studyId={studyId}
                            signed={signed ? true : false}
                        />}
                    </div>
                </div>
            </div>
        )
    } else if (contractId == 0) {
        return (
            <div className="page">
                <div className="page-content">
                    <div className="inner">
                        <div className="page-heading" />
                        <div className="full-screen centered">
                            <Box textAlign="center" className="page-body">
                                <Typography gutterBottom variant="h1">
                                    Contract Does Not Exist
                                </Typography>
                                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                                    The page you are looking for does not exist.
                                </Typography>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => history.push('/contract')}
                                    style={{ margin: 40 }}
                                >
                                    View All Contracts
                                </Button>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (<></>)
}

export default ContractDetails;
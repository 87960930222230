import * as React from 'react';

import { Grid, Paper } from '@material-ui/core';

const GridWrapper: React.FC = props => {
    return (
        <div className='full-screen centered'>
            <Grid item sm={10} md={7} lg={5}>
                <Paper className='paper-margin-padding'>
                    {props.children}
                </Paper>
            </Grid>
        </div>
    )
}

export default GridWrapper;
import * as React from 'react';

import { Card, Grid, Table, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import { StudyOverviewData } from '../../../../@types';
import { DisplayTextFormat } from '../../../../common/Utils/DisplayTextFormat';

interface Props {
    studyData: StudyOverviewData,
}

const StudyOverviewInformation: React.FC<Props> = props => {
    const { studyData } = props;

    const tooltipText = 'The following lists all identification attributes for the study.';

    const renderTextField = (text?: string) => {
        return (text ? DisplayTextFormat(text) : 'None');
    }

    return (
        <Grid item xs={12} md={4} container>
            <div className="section-heading">
                <div className='text'>Identification</div>
                <Tooltip arrow title={tooltipText}><InfoIcon className='info' /></Tooltip>
            </div>
            <div className='overview-card-div'>
                <Card className='info overview-card detail-row-text-identification'>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={12}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name:</TableCell>
                                            <TableCell>{renderTextField(studyData.studyName)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Identifier:</TableCell>
                                            <TableCell>{renderTextField(studyData.studyIdentifier)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>IRB #:</TableCell>
                                            <TableCell>{renderTextField(studyData.irbNo)}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} sm={6} md={12}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Study #:</TableCell>
                                            <TableCell>{renderTextField(studyData.studyNumber)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Sponsor&apos;s Study ID:</TableCell>
                                            <TableCell>{renderTextField(studyData.sponsorStudyIdentifier)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Division:</TableCell>
                                            <TableCell>{renderTextField(studyData.division.name)}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Card>
            </div>
        </Grid>
    );
}

export default StudyOverviewInformation;
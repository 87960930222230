import * as React from 'react';

import { TextField } from '@material-ui/core';

interface Props {
    label: string;
    value: any;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    error: any;
    type?: string;
    multiline?: boolean;
    rows?: number;
}

const OutlinedTextField: React.FC<Props> = props => {
    const {
        label,
        value,
        onChange,
        error,
        type,
        multiline,
        rows
    } = props;

    return (
        <TextField
            label={label}
            fullWidth
            value={value}
            margin="dense"
            variant="outlined"
            onChange={onChange}
            error={!!error}
            helperText={error}
            type={type}
            multiline={multiline}
            rows={rows}
        />
    )
}

export default OutlinedTextField;
import * as React from 'react';

import {
    Card, CardActions, CardContent, CardHeader, CircularProgress, Grid, IconButton, Modal
} from '@material-ui/core';
import { Close, Save } from '@material-ui/icons';

import { Formik, FormikHelpers } from 'formik';
import { BForm, BSubmit, BTextField } from 'mui-bueno';

import { Directory, Document } from '../../../../../@types';
import { updateDocumentName } from '../../../../../service/Study/documents';
import { useDispatch } from 'react-redux';
import { showSuccessSnackbar } from '../../../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../../../service/utils';
import { updateDirectory } from '../../../../../service/Study/directories';


interface Props {
    open: any;
    setOpen: any;
    setUpdated: any;
    selectedDocument?: Document;
    setSelectedDocument?: any;
    selectedDirectory?: Directory;
    setSelectedDirectory?: any;
}

const RenameModal: React.FC<Props> = props => {
    const {
        open, setOpen, setUpdated, selectedDocument,
        setSelectedDocument, selectedDirectory, setSelectedDirectory
    } = props;

    const [saving, setSaving] = React.useState<boolean>(false);

    const dispatcher = useDispatch();

    const handleSubmit = (data: any, { setErrors }: FormikHelpers<any>) => {
        if (!saving) {
            if (selectedDocument) {
                setSaving(true);
                updateDocumentName(selectedDocument.id!, data.fileName)
                    .then(res => {
                        dispatcher(showSuccessSnackbar('Document name changed.'));
                        setSelectedDocument(res.data);
                        setUpdated(true);
                        handleModalClose();
                    }).catch(err => {
                        handleErrorResponse(err, dispatcher, {
                            setStatus: setErrors,
                            prefix: 'Could not update document name: '
                        });
                    }).finally(() => setSaving(false));
            } else if (selectedDirectory) {
                setSaving(true);
                updateDirectory({
                    id: selectedDirectory.id!,
                    name: data.name,
                    studyId: selectedDirectory.studyId,
                    parentDirectoryId: selectedDirectory.parentDirectoryId,
                    allowedFileDeleted: selectedDirectory.allowedFileDeleted
                }).then(res => {
                    dispatcher(showSuccessSnackbar('Directory name changed.'));
                    setSelectedDirectory(res.data);
                    setUpdated(true);
                    handleModalClose();
                }).catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        setStatus: setErrors,
                        prefix: 'Could not update directory name: '
                    });
                }).finally(() => setSaving(false));
            }
        }
    }

    const handleModalClose = () => {
        setOpen(false);
    }

    return (
        <Modal
            open={open}
            onClose={handleModalClose}
        >
            <div className="modal-form">
                <Formik
                    initialValues={selectedDocument ? { ...selectedDocument } : { ...selectedDirectory }}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    <BForm>
                        <Card className="detail-form sm">
                            <CardHeader
                                title={selectedDocument ? 'Rename ' + selectedDocument.fileName : 'Rename ' + selectedDirectory!.name}
                                action={
                                    <IconButton color="primary" aria-label="Close" onClick={handleModalClose}>
                                        <Close />
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                <Grid container spacing={2} justifyContent='center'>
                                    <Grid item xs={12}>
                                        {selectedDocument ?
                                            <BTextField
                                                name="fileName"
                                                label="File Name"
                                                placeholder="File Name"
                                                required autoFocus
                                            /> :
                                            <BTextField
                                                name="name"
                                                label="Directory Name"
                                                placeholder="Directory Name"
                                                required autoFocus
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions className="flex-end">
                                <BSubmit
                                    startIcon={saving ? null : <Save />}
                                    variant="contained"
                                    color="primary"
                                    disabled={saving}
                                >
                                    {saving && <CircularProgress size={24} className='button-progress' />}
                                    Save
                                </BSubmit>
                            </CardActions>
                        </Card>
                    </BForm>
                </Formik>
            </div>
        </Modal>
    )
};

export default RenameModal;
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { Division, DivisionTree, SelectOption } from '../../../@types';
import { convertReferenceDataToSelectionOptions } from '../../../common/Utils/utils';
import { handleErrorResponse } from '../../../service/utils';
import { getReferenceDivisionRoles, getReferenceUsers } from '../../../service/Reference/reference';

import usePermissions from '../../../common/hooks/usePermissions';
import DivisionDetail from './DivisionDetail';
import FilterByDivision from '../../Invoicing/Invoice/FilterByDivision';

interface RouteParams {
    id?: string; // Either the id or 'new'
}

const DivisionList: React.FC = () => {
    const { id } = useParams<RouteParams>();
    const history = useHistory();
    const dispatch = useDispatch();

    // List of divisions in the navigation tree
    const [divisions, setDivisions] = React.useState<Division[]>([]);
    const [defaultExpand, setDefaultExpand] = React.useState<string[]>([]);

    // Tracks whether an update has been made so that the list can refresh
    const [updated, setUpdated] = React.useState<boolean>(false);

    const [allReferenceUsers, setAllReferenceUsers] = React.useState<SelectOption<number>[]>([]);
    const [allReferenceRoles, setAllReferenceRoles] = React.useState<SelectOption<number>[]>([]);

    // only check if user has permission to create or not
    // role restriction will be checked at the service after submit
    const canCreate = usePermissions('API_DIVISION_CREATE');

    // Initial Load one time
    React.useEffect(() => {
        getReferenceUsers()
            .then(res => {
                setAllReferenceUsers(convertReferenceDataToSelectionOptions(res.data));
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve list of User references: '
                });
            });

        getReferenceDivisionRoles()
            .then(res => {
                setAllReferenceRoles(convertReferenceDataToSelectionOptions(res.data));
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve list of Division Roles: '
                });
            });
    }, []);

    const handleChangeSelectedDivision = (division: DivisionTree): void => {
        history.push(`/manage/division/${division.id}`);
    };

    return (
        <>
            <div className="page-heading">
                <Typography className="title">Division Management</Typography>
            </div>
            <div className="page-body max-lgxl">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Card className="detail-form">
                            <CardHeader
                                title="Divisions"
                                action={canCreate &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="add-division-button"
                                        onClick={() => history.push('/manage/division/new')}
                                        startIcon={<Add />}
                                    >
                                        Add Division
                                    </Button>
                                }
                            />
                            <CardContent>
                                <FilterByDivision
                                    setChosenDivision={handleChangeSelectedDivision}
                                    withinCollapsiblePanel={false}
                                    withinMenu={true}
                                    restricted={true}
                                    divisionStatisticsPage={true}
                                    reload={true}
                                    updated={updated}
                                    setUpdated={setUpdated}
                                    setDivisions={setDivisions}
                                    defaultExpanded={defaultExpand}
                                    setDefaultExpanded={setDefaultExpand}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        {id &&
                            <DivisionDetail
                                id={id}
                                allDivisions={divisions}
                                updated={setUpdated}
                                allReferenceUsers={allReferenceUsers}
                                allReferenceRoles={allReferenceRoles}
                            />}
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default DivisionList;

import * as React from 'react';
import { TableCell, TableRow } from '@material-ui/core';

import { Calculation, Study } from '../../../../@types';
import { getCalculationTotalsByStudyId, getMonthlyCalculationTotalsByStudyIdAndYear } from '../../../../service/Study/calculations';
import useLoggedInUserPermissions from '../../../../common/hooks/useLoggedInUserPermissions';

import ActivityCostProductivityRows from './ActivityCostProductivityRows';


interface Props {
    editYear: number;
    study: Study;
    currentMonth: number;
    currentYear: number;
}

const StudyStatisticsTableRow: React.FC<Props> = props => {
    const { editYear, study, currentMonth, currentYear } = props;

    const permissionGetCalculationTotalsByStudyId: boolean =
        useLoggedInUserPermissions('API_CALCULATION_GET_CALCULATION_TOTALS_BY_STUDY_ID', study.divisionId, study.id!);
    const permissionGetMonthlyCalculationTotalsByStudyIdAndYear: boolean =
        useLoggedInUserPermissions('API_CALCULATION_GET_MONTHLY_CALCULATION_TOTALS_BY_STUDY_ID_AND_YEAR', study.divisionId, study.id!);
    const permissionDisplayCalculationTotalsByStudyId: boolean =
        useLoggedInUserPermissions('API_CALCULATION_GET_CALCULATION_TOTALS_BY_STUDY_ID', study.divisionId, -1);
    const permissionDisplayMonthlyCalculationTotalsByStudyIdAndYear: boolean =
        useLoggedInUserPermissions('API_CALCULATION_GET_MONTHLY_CALCULATION_TOTALS_BY_STUDY_ID_AND_YEAR', study.divisionId, -1);

    const [studyCalculationTotals, setStudyCalculationTotals] = React.useState<Calculation[]>([]);
    const [monthlyCalculations, setMonthlyCalculations] = React.useState<Calculation[]>([]);

    React.useEffect(() => {
        if (permissionGetCalculationTotalsByStudyId)
            getCalculationTotalsByStudyId(Number(study.id)).then(res => {
                setStudyCalculationTotals(res.data);
            });
    }, [study]);

    React.useEffect(() => {
        if (permissionGetMonthlyCalculationTotalsByStudyIdAndYear)
            getMonthlyCalculationTotalsByStudyIdAndYear(Number(study.id), editYear).then(res => {
                res.data.forEach(calculation => calculation.associatedDate = new Date(calculation.associatedDate));
                setMonthlyCalculations(res.data);
            });
    }, [study, editYear]);

    return (
        <>
            <TableRow>
                <TableCell colSpan={1} className="primary-color top-left sticky">Entire Study</TableCell>
                <TableCell colSpan={13} className="primary-color" />
            </TableRow>
            <ActivityCostProductivityRows
                calculationTotals={studyCalculationTotals}
                monthlyCalculations={monthlyCalculations}
                editYear={editYear}
                currentYear={currentYear}
                currentMonth={currentMonth}
                titlePadding={false}
                onlyShowActivity={!(permissionDisplayCalculationTotalsByStudyId && permissionDisplayMonthlyCalculationTotalsByStudyIdAndYear)}
            />
        </>
    )
}

export default StudyStatisticsTableRow;
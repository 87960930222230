import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Tab, Tabs, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DivisionTree, InvoiceDetailWithChecks, InvoiceStatus } from '../../../@types';
import { handleErrorResponse } from '../../../service/utils';
import { getDivisionInvoices } from '../../../service/Invoice/invoice';

import InvoiceListBrowsingPanel from './InvoiceListBrowsingPanel';
import LogPayment from '../Payment/LogPayment';
import InvoicesSummaryCard from '../../../common/Invoicing/InvoicesSummaryCard';
import InvoiceablesTable from '../../../common/Invoicing/InvoiceablesTable';
import { renderAccountCell, renderInvoiceCell, renderStudyCell } from '../../../common/DataTable/Utils';
import { useHistory } from 'react-router-dom';
import usePermissions from '../../../common/hooks/usePermissions';


const BASE_COLUMNS: string[] = ['status', 'invoice', 'amount', 'study', 'payee', 'payor'];
const CONTROLLED_COLUMNS: string[] = ['dateCreated', 'dateSent', 'datePaidMinusHoldback', 'datePaid'];

interface State {
    objects: any[];
    viewMode: 'receivable' | 'payable';
    dateRange: number;
    statusFilters: {
        NOT_SENT: boolean;
        NOT_PAID: boolean;
        PARTIAL: boolean;
        CURRENT: boolean;
        PAID: boolean;
    };
    columnFilters: {
        dateCreated: boolean;
        dateSent: boolean;
        datePaidMinusHoldback: boolean;
        datePaid: boolean;
    };
    selectedDivision?: DivisionTree;
}

const initialState: State = {
    objects: [],
    viewMode: 'receivable',
    dateRange: 12,
    statusFilters: {
        NOT_SENT: true,
        NOT_PAID: true,
        PARTIAL: true,
        CURRENT: true,
        PAID: true
    },
    columnFilters: {
        dateCreated: true,
        dateSent: true,
        datePaidMinusHoldback: true,
        datePaid: true,
    },
}

interface Action {
    type: 'objects' | 'viewMode' | 'dateRange' | 'statusFilters' | 'columnFilters' | 'selectedDivision';
    payload?: any;
}

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'objects':
            return {
                ...state,
                objects: action.payload
            }
        case 'viewMode':
            return {
                ...state,
                viewMode: action.payload
            }
        case 'dateRange':
            return {
                ...state,
                dateRange: action.payload
            }
        case 'statusFilters':
            return {
                ...state,
                statusFilters: action.payload
            };
        case 'columnFilters':
            return {
                ...state,
                columnFilters: action.payload
            }
        case 'selectedDivision':
            return {
                ...state,
                selectedDivision: action.payload
            }
        default:
            return state;
    }
}

interface TableData {
    invoiceId: number | null;
    status: InvoiceStatus;
    invoice: JSX.Element;
    amount: number;
    dateCreated: Date;
    dateSent?: Date;
    datePaid?: Date;
    datePaidMinusHoldback?: Date,
    study: JSX.Element;
    payee: JSX.Element;
    payor: JSX.Element;
    payingAccountId: number;
    receivingAccountId: number;
    studyId: number;
    invoiceNo: string;
}

const InvoiceList: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [receivableTableData, setReceivableTableData] = React.useState<TableData[]>([]);
    const [payableTableData, setPayableTableData] = React.useState<TableData[]>([]);
    const [columns, setColumns] = React.useState<string[]>(BASE_COLUMNS.concat(CONTROLLED_COLUMNS));
    const [logPaymentModalOpen, setLogPaymentModalOpen] = React.useState<boolean>(false);
    const [state, dispatchPage] = React.useReducer(reducer, initialState);
    const [updateOnChange, setUpdateOnChange] = React.useState<boolean>(false);
    const canViewAccountDetails = usePermissions('API_ACCOUNT_GET_BY_ID');
    const canLogPayment: boolean = usePermissions('API_ACCOUNT_PAYMENT_LOG_PAYMENT');
    const canViewInvoiceDetails = usePermissions('API_INVOICE_GET_BY_ID');

    const {
        objects,
        viewMode,
        dateRange,
        statusFilters,
        columnFilters,
        selectedDivision
    } = state;

    const handleLogPaymentOpen = (): void => {
        setLogPaymentModalOpen(true);
    };

    const handleChangeStatusFilters = (filter1: boolean, filter2: boolean, filter3: boolean, filter4: boolean, filter5: boolean) => {
        dispatchPage({ type: 'statusFilters', payload: { NOT_SENT: filter1, NOT_PAID: filter2, PARTIAL: filter3, CURRENT: filter4, PAID: filter5 } });
    };

    const handleChangeColumnFilters = (filter1: boolean, filter2: boolean, filter3: boolean, filter4: boolean, filter5: boolean) => {
        dispatchPage({ type: 'columnFilters', payload: { dateCreated: filter1, dateSent: filter2, datePaidMinusHoldback: filter3, datePaid: filter4, checkNumbers: filter5 } });
    };

    const handleChangeDateRange = (event: any): void => {
        dispatchPage({ type: 'dateRange', payload: event.target.value });
    };

    const handleChangeSelectedDivision = (division: DivisionTree): void => {
        dispatchPage({ type: 'selectedDivision', payload: division });
    };

    const getTableData = (data: InvoiceDetailWithChecks[]) => {
        const temp: TableData[] = [];
        data.forEach((obj: InvoiceDetailWithChecks) => {
            temp.push({
                invoiceId: obj.invoice.id!,
                status: obj.invoice.invoiceStatus,
                invoice: renderInvoiceCell(obj.invoice.invoiceNo, obj.invoice.id!, history, canViewInvoiceDetails),
                amount: obj.invoice.amount,
                dateCreated: obj.invoice.createdDate,
                dateSent: obj.invoice.sentDate,
                datePaid: obj.invoice.paidDate,
                datePaidMinusHoldback: obj.invoice.paidDateMinusHoldback,
                study: renderStudyCell(obj.studyName, obj.invoice.studyId, history),
                payee: renderAccountCell(obj.receivingAccountName, obj.invoice.receivingAccountId, history, canViewAccountDetails),
                payor: renderAccountCell(obj.payingAccountName, obj.invoice.payingAccountId, history, canViewAccountDetails),
                payingAccountId: obj.invoice.payingAccountId,
                receivingAccountId: obj.invoice.receivingAccountId,
                studyId: obj.invoice.studyId,
                invoiceNo: obj.invoice.invoiceNo
            });
        });
        return temp;
    };

    React.useEffect(() => {
        setColumns(CONTROLLED_COLUMNS.map(type => columnFilters[type] && type).concat(BASE_COLUMNS));
    }, [columnFilters]);

    React.useEffect(() => {
        // Use the selectedDivision and dateRange to request the service and populate payableInvoices and receivableInvoices
        const selectedDivisionId = selectedDivision ? selectedDivision.id : undefined

        getDivisionInvoices(
            'payable',
            dateRange,
            selectedDivisionId
        ).then(res => {
            setPayableTableData(getTableData(res.data));
        }).catch(err => {
            handleErrorResponse(err, dispatch, {
                prefix: 'Could not retrieve list of payable Invoices: '
            })
        });

        getDivisionInvoices(
            'receivable',
            dateRange,
            selectedDivisionId
        ).then(res => {
            setReceivableTableData(getTableData(res.data));
        }).catch(err => {
            handleErrorResponse(err, dispatch, {
                prefix: 'Could not retrieve list of receivable Invoices: '
            })
        });
    }, [dateRange, selectedDivision, updateOnChange]);

    React.useEffect(() => {
        const displayList = viewMode === 'payable' ? payableTableData : receivableTableData;

        const filteredList = displayList.filter(obj => statusFilters[obj.status]);
        dispatchPage({
            type: 'objects',
            payload: filteredList
        });

    }, [receivableTableData, payableTableData, viewMode, statusFilters]);

    const handleCallToRouter = (_event: React.ChangeEvent<{}>, value: any) => {
        dispatchPage({ type: 'viewMode', payload: value });
    };

    return (
        <div className="page">
            <InvoiceListBrowsingPanel
                statusFilters={statusFilters}
                handleChangeStatusFilters={handleChangeStatusFilters}
                columnFilters={columnFilters}
                handleChangeColumnFilters={handleChangeColumnFilters}
                dateRange={dateRange}
                handleChangeDateRange={handleChangeDateRange}
                handleChangeSelectedDivision={handleChangeSelectedDivision}
            />
            <div className="page-content">
                <div className="page-heading">
                    <Typography className="title">Invoices</Typography>
                </div>
                <div className="invoice-list">
                    <div className="summary-card">
                        <Typography className="summary-card-header">All Invoices from {selectedDivision ? selectedDivision.name : 'All Divisions'}</Typography>
                        <InvoicesSummaryCard
                            divisionId={selectedDivision?.id}
                            updateOnChange={updateOnChange}
                        />
                    </div>
                    <div className='page-tabs'>
                        <Tabs
                            value={viewMode}
                            onChange={handleCallToRouter}
                            indicatorColor="primary"
                            textColor="primary"
                            className='sv-tab spacing'
                        >
                            <Tab label="Receivable Invoices" value={'receivable'} />
                            <Tab label="Payable Invoices" value={'payable'} />
                        </Tabs>
                        {state.viewMode == 'receivable' && canLogPayment &&
                            <div className='log-payment'>
                                <Button
                                    onClick={handleLogPaymentOpen}
                                    variant='contained'
                                    color='primary'
                                    startIcon={<Add />}
                                >
                                    Log Received Payment
                                </Button>
                            </div>
                        }
                    </div>
                    <div className="invoice-table">
                        <InvoiceablesTable
                            type="invoices"
                            data={objects}
                            showColumns={columns}
                            updateOnChange={updateOnChange}
                            setUpdateOnChange={setUpdateOnChange}
                            viewMode={state.viewMode}
                        />
                    </div>
                </div>
            </div>
            <LogPayment
                open={logPaymentModalOpen}
                setOpen={setLogPaymentModalOpen}
                updateOnChange={updateOnChange}
                setUpdateOnChange={setUpdateOnChange}
                receivedPayment
            />
        </div>
    );
}

export default InvoiceList;
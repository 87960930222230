import _ from 'lodash';
import { PermissionConst, User, UserRolePermission } from '../@types';

/*** CONSTANTS ***/
export const LOGIN = 'loginReducer/LOGIN';
export type LOGIN = typeof LOGIN;

export const LOGOUT = 'loginReducer/LOGOUT';
export type LOGOUT = typeof LOGOUT;

export const FETCH_PERMISSIONS = 'loginReducer/FETCH_PERMISSIONS';
export type FETCH_PERMISSIONS = typeof FETCH_PERMISSIONS;

/*** ACTIONS ***/
export interface Login {
    type: LOGIN;
    payload: LoginState;
}

export interface Logout {
    type: LOGOUT;
}

export interface PermissionsFetched {
    type: FETCH_PERMISSIONS;
    payload: UserRolePermission[];
}

export type LoginAction = Login | Logout | PermissionsFetched;

/*** ACTION CREATORS ***/
export const loginSuccess = (loginState: LoginState): Login => {
    return {
        type: LOGIN,
        payload: loginState
    };
};

export const clearStoreLogout = (): Logout => {
    return {
        type: LOGOUT
    };
};

export const permissionsFetched = (rolePermissions: UserRolePermission[]): PermissionsFetched => {
    return {
        type: FETCH_PERMISSIONS,
        payload: rolePermissions
    };
}
/*** REDUCER ***/
export interface LoginState {
    user: User;
    permissions: PermissionConst[];
    rolePermissions?: any;
}

export const initialState: LoginState = {
    user: {
        id: undefined,
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        password: undefined,
        divisionIds: [],
        studyIds: [],
        roleIds: [],
    },
    permissions: [],
    rolePermissions: {}
};

export const reducer = (
    state: LoginState = initialState,
    action: LoginAction
): LoginState => {
    if (action.type === LOGIN) {
        return {
            ...state,
            user: action.payload.user,
            permissions: action.payload.permissions
        };
    } else if (action.type === FETCH_PERMISSIONS) {
        return {
            ...state,
            rolePermissions: _.mapKeys(action.payload, 'permissionId')
        }
    }
    /* istanbul ignore next */
    else {
        return state;
    }
};
import * as React from 'react';

import { Button, ListItem, Menu, Tooltip } from '@material-ui/core';
import { ArrowDropDown, FilterList, InfoRounded } from '@material-ui/icons';

export interface FilterSortMenuSection {
    title: string;
    tooltip: any;
    body: any;
}

interface Props {
    sections: FilterSortMenuSection[];
    selectMode?: any;
}

const FilterSortButton: React.FC<Props> = props => {
    const { sections, selectMode } = props;

    const [filterMenuAnchor, setFilterMenuAnchor] = React.useState<null | HTMLElement>(null);
    const handleCloseMenu = () => {
        setFilterMenuAnchor(null);
    }

    const button = () => {
        if (selectMode) {
            return (
                <Button
                    onClick={event => setFilterMenuAnchor(event.currentTarget)}
                    className='filter-button select'
                    variant='outlined'
                    endIcon={<ArrowDropDown />}
                >
                    {selectMode}
                </Button>
            )
        } else {
            return (
                <Button
                    onClick={event => setFilterMenuAnchor(event.currentTarget)}
                    className='filter-button'
                    variant='outlined'
                    startIcon={<FilterList />}
                >
                    Filter
                </Button>
            )
        }
    }

    return (<>
        {button()}
        <Menu
            anchorEl={filterMenuAnchor}
            keepMounted
            open={Boolean(filterMenuAnchor)}
            onClose={handleCloseMenu}
            style={{ zIndex: 1301, marginTop: 5 }}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            className='filter-menu'
        >
            {sections.map((section: FilterSortMenuSection, idx: number) => {
                return (
                    <div key={'menu-' + section.title}>
                        <ListItem className="section-title">
                            {section.title}
                            <Tooltip arrow title={section.tooltip}>
                                <InfoRounded />
                            </Tooltip>
                        </ListItem>
                        <ListItem className='form'>
                            {section.body}
                        </ListItem>
                        {idx != sections.length - 1 &&
                            <div className="spacer" />
                        }
                    </div>
                )
            })}
        </Menu>
    </>)
}
export default FilterSortButton;

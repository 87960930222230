import { Dispatch } from 'react';
import { AxiosError } from 'axios';
import { showErrorSnackbar, showWarningSnackbar } from '../modules/messageSnackbarReducer';
import { FormikErrors } from 'formik';


export const handleError = (error: AxiosError) => {
    throw error;
};

/** 
 * @Deprecated
 * Use handleErrorResponse
 * */
export const handleUnprocessableEntity = (err: AxiosError, setErrors: (errors: any) => void) => {
    if (err.response && err.response.status === 422) {
        const editErrors = err.response.data.editErrors;

        if (editErrors) {
            setErrors(editErrors);
        } else {
            setErrors({ 'general': err.response.data.message });
            showErrorSnackbar(err.response.data.message);
        }
    }
}

/** 
 * @Deprecated
 * Use handleErrorResponse
 * */
export const handleUnprocessableEntityAndDispatch = (err: AxiosError, setErrors: (errors: any) => void, dispatch: Dispatch<any>) => {
    if (err.response && (err.response.status === 422 || err.response.status === 403)) {
        const editErrors = err.response.data.editErrors;

        if (editErrors) {
            setErrors(editErrors);
        } else {
            setErrors({ 'general': err.response.data.message });
            dispatch(showErrorSnackbar(err.response.data.message));
        }
    }
}

export const handleEditErrors = (err: AxiosError, setErrors: (errors: FormikErrors<any>) => void) => {
    if (err.response) {
        const editErrors: { [key: string]: string } = err.response.data.editErrors;
        if (editErrors) {
            for (const key of Object.keys(editErrors)) {
                setErrors({[key]: editErrors[key]})
            }
        } else {
            setErrors({'general': err.response.data.message });
        }
    }
}
/**
 * General function for handling all errors returned by Axios functions
 * @param error AxiosError returned by catch function
 * @param dispatch Dispatch element from calling page
 * @param props.setStatus (optional) setStatus function from the calling formik element. Required to set EditErrors from the service
 * @param props.prefix (optional) string to be displayed before any error message returned by the backend service 
 * @returns EditErrors, WarningSnackbar or ErrorSnackbar to the calling form
 */
export const handleErrorResponse = (error: AxiosError, dispatch: Dispatch<any>, props?: { setStatus?: (status?: any) => void; prefix?: string; }) => {
    if (!error.response) return;

    const message: string = `${props?.prefix ?? ''}${error.response.data?.message ?? error.message}`;

    if (error.response.status === 422) {
        const editErrors = error.response.data.editErrors;

        if (props?.setStatus && editErrors) {
            props.setStatus(editErrors);
        } else if (editErrors && editErrors.general) {
            dispatch(showWarningSnackbar(editErrors.general));
        } else {
            dispatch(showWarningSnackbar(message));
        }
    } else if (error.response.status === 403) {
        dispatch(showWarningSnackbar(message));
    } else {
        dispatch(showErrorSnackbar(message));
    }
}
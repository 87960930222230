import * as React from 'react';

import { Link as ScrollTo } from 'react-scroll';
import { Link, LinkProps } from 'react-router-dom';

import { Button, Card, CardContent, CardMedia, Grid, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import Header from './HomepageHeader/HomepageHeader'

// image imports
import logo from '../../media/logo/logo-180.png';
import effortImage from '../../media/homepage/features/effort.png';
import easyImage from '../../media/homepage/features/easy.png';
import contractImage from '../../media/homepage/features/negotiation.png';
import securityImage from '../../media/homepage/features/security.png';
import danielWoo from '../../media/homepage/daniel-woo/daniel-woo-md.jpeg';

export const AdapterLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
    (props, ref) => <Link innerRef={ref as any} {...props} />
);

const Homepage: React.FC = () => {

    const [showScroll, setShowScroll] = React.useState(true);
    const [isTitleVisible, setTitleVisible] = React.useState(false);

    // Homepage text
    const tagline = 'The most efficient way to keep track of clinical research, activity, productivity, and finances'

    const keyPoints = {
        'p1': 'Leads to identifying top performers and operational best practices',
        'p2': 'Informs changes in how contracts are negotiated',
        'p3': 'Provides data to improve the financial health of research'
    }

    const features = [
        {
            id: 0,
            title: 'Effective Contract Negotiation',
            desc: 'Estimation tools provides the financial justification to properly reimburse the cost of running research studies',
            photo: contractImage,
            alt: 'Business man and woman shaking hands'
        },
        {
            id: 1,
            title: 'Security',
            desc: 'Meets and exceeds HIPPA requirements; Led by application architects experienced in building secure applications for high security organizations',
            photo: securityImage,
            alt: 'Lock with computer'
        },
        {
            id: 2,
            title: 'Easy To Use',
            desc: 'Minimizes the effort to track research activity, keeping your staff focused on their patients',
            photo: easyImage,
            alt: 'Medical professional using a laptop'
        },
        {
            id: 3,
            title: 'Compare Effort & Productivity',
            desc: 'Know your coordinator efforts across studies compared to productivity',
            photo: effortImage,
            alt: 'Two people comparing stats on a business report'
        }
    ]

    const action = {
        'title': 'The Journey to Maximizing Success Starts Here',
        'q1': 'Are you losing a fortune on research on account of being unable to project into the future?',
        'q2': 'Are you aware of invoices? ',
        'q3': 'Are you tracking employee effort vs actual productivity?',
        'q4': 'Is your team working hard but still losing money?',
        'action': 'Request a Demo today to see how CRAFT can be a great fit for your organization'
    }

    const background = {
        'text1': 'Dr. Daniel Woo, Vice Chair of Research for Neurology at the University of Cincinnati, challenged the idea that research generally loses money.',
        'text2': 'He took an analytic approach to the problem and tracked the actions and behaviors of numerous research teams. His work proved beneficial, and thus was created into CRAFT as a means for organizations to access this product.'
    }

    React.useEffect(() => {
        //start enter animation for title section
        setTitleVisible(true);
        const handleScroll = () => {
            const showMore = window.scrollY < 200
            if (showMore) {
                setShowScroll(true)
            } else {
                setShowScroll(false)
            }
        }
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    }, [])

    //when element is in view run its enter animation
    const io = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('is-visible');
            }
        })
    })

    //observe this list of elements to determine when to animate them on screen
    const elementList = document.querySelectorAll('.enter-animation ');
    elementList.forEach((el) => {
        io.observe(el);
    })


    return (
        <div id='homepage-start' className='homepage root'>
            <Header />

            <div className='title-section' >
                <div className={'full-logo animate-right enter-animation ' + (isTitleVisible ? 'is-visible' : '')}>
                    <div className='logo-div'>
                        <img src={logo} alt="CRAFT Logo Image" className='logo' />
                    </div>
                    <Typography variant='h1'className='title'>CRAFT</Typography>
                </div>
                <Typography variant='h2' className={'tag-line ' + (isTitleVisible ? 'is-visible' : '')}>
                    {tagline}
                </Typography>
                <Button className={'demo-btn animate enter-animation ' + (isTitleVisible ? 'is-visible' : '')}
                    variant='contained'
                    color='primary'
                    component={AdapterLink}
                    to="/contact"
                >
                    <Typography className='btn-text'>Request a Demo</Typography>
                </Button>
            </div>

            {showScroll &&
                <ScrollTo className='learn-more-btn bounce'
                    spy={true}
                    smooth={true}
                    to='overview-section'
                >
                    <Typography className='text'>Learn More</Typography>
                    <KeyboardArrowDownIcon className='arrow' fontSize='large' />
                </ScrollTo>
            }

            <div className='overview-section'>
                <div className='craft-div'>
                    <Typography variant='h2' className='craft-title'>
                        Clinical <br className='title-break' />
                        Research <br className='title-break' />
                        Activity <br className='title-break' />
                        Financial <br className='title-break' />
                        Tracking
                    </Typography>
                    <div className='craft-summary-div animate-left enter-animation'>
                        <Typography className='header-text first'>
                            What is <span className='bold'>CRAFT</span>?
                        </Typography>
                        <div className='box top center'>
                            <Typography variant='body1' className='body-text'>
                                <span className='bold color'>CRAFT </span>
                                is the culmination of many years of analysis experience, providing
                                <span className='bold color'> a proven, data-based approach&nbsp;</span>
                                to improving and maintaining high performing research studies
                            </Typography>
                        </div>
                    </div>
                </div>

                <div className='key-points-div'>
                    <Typography variant='body1' className='header-text second animate-right enter-animation'>
                        <span className='bold'>CRAFT</span> is an innovative tool that...
                    </Typography>
                    <div className='box left center animate-right enter-animation'>
                        <Typography variant='body1' className='body-text'>{keyPoints.p1}</Typography>
                    </div>
                    <div className='box middle center animate-center enter-animation'>
                        <Typography variant='body1' className='body-text'>{keyPoints.p2}</Typography>
                    </div>
                    <div className='box right center animate-left enter-animation'>
                        <Typography variant='body1' className='body-text'>{keyPoints.p3}</Typography>
                    </div>
                </div>
            </div>

            <div className='feature-section'>
                <Typography variant='h3' className='title'>Key Features</Typography>
                <Grid className='grid center' container spacing={5}>
                    {features.map((feature) => (
                        <Grid className='grid-item animate-up enter-animation'
                            item xs={12} sm={6}
                            key={'item-' + feature.title}
                        >
                            <Card className='card' >
                                <CardMedia className='feat-img'
                                    component='img'
                                    image={feature.photo}
                                    alt={feature.alt}
                                ></CardMedia>
                                <Typography variant='h6' className='feat-title'>{feature.title}</Typography>
                                <CardContent className='card-content'>
                                    <Typography variant='body1' className='feat-desc'>{feature.desc}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </div>

            <div className='action-section'>
                <Typography variant='h3' className='title animate-right enter-animation'>{action.title}</Typography>
                <div className='text-and-logo-div'>
                    <div className='text-div'>
                        <Typography variant='body1' className='text'>{action.q1}</Typography>
                        <Typography variant='body1' className='text'>{action.q2}</Typography>
                        <Typography variant='body1' className='text'>{action.q3}</Typography>
                        <Typography variant='body1' className='text'>{action.q4}</Typography>
                    </div>
                    <div className='logo-div'>
                        <img src={logo} alt="CRAFT Logo Image" className='logo' />
                    </div>
                </div>
                <Typography variant='h4' className='action-text animate-up enter-animation'>{action.action}</Typography>
                <Button className='demo-btn animate-up enter-animation'
                    variant='contained'
                    color='primary'
                    component={AdapterLink}
                    to="/contact"
                >
                    <Typography className='btn-text'>Request a Demo</Typography>
                </Button>
            </div>

            <div className='background-section center'>
                <div className='img-div center'>
                    <img src={danielWoo} alt="Portrait of Daniel Woo, m.d." className='logo' />
                </div>
                <div className='text-div'>
                    <Typography variant='body1' className='text'>{background.text1}</Typography>
                    <Typography variant='body1' className='text'>{background.text2}</Typography>
                </div>
            </div>

        </div>
    )
}

export default Homepage;
import * as React from 'react';
import { useDispatch } from 'react-redux';

import {
    Card, CardContent, CardHeader, IconButton, Modal, Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow
} from '@material-ui/core';
import { Close, GetApp } from '@material-ui/icons';

import { Document, DocumentVersion } from '../../../../../@types';
import { downloadDocument, downloadLatestDocument, getDocumentHistories } from '../../../../../service/Study/documents';
import { handleErrorResponse } from '../../../../../service/utils';
import { displayDateWithTime } from '../../../../../common/Utils/utils';
import useLoggedInUserPermissions from '../../../../../common/hooks/useLoggedInUserPermissions';

interface Props {
    open: any;
    setOpen: any;
    studyId: number;
    divisionId: number;
    selectedDocument: Document | null;
}

const HistoryModal: React.FC<Props> = props => {
    const { open, setOpen, studyId, divisionId, selectedDocument } = props;

    const [versions, setVersions] = React.useState<DocumentVersion[]>([]);

    const dispatcher = useDispatch();

    const canDownload = useLoggedInUserPermissions('API_DOCUMENT_DOWNLOAD_DOCUMENT', divisionId, studyId);

    React.useEffect(() => {
        if (selectedDocument) {
            getDocumentHistories(selectedDocument.id!).then(res => {
                setVersions(res.data);
            });
        }
    }, [open, selectedDocument]);

    const handleModalClose = () => {
        setOpen(false);
    }

    const handleDownloadDocument = (selectedDoc: Document, versionId: string | null) => {
        if (versionId) {
            downloadDocument(selectedDoc.id!, versionId)
                .then(res => {
                    const blob = new Blob([res.data]);
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.setAttribute('download', selectedDoc.fileName);
                    link.click();
                    URL.revokeObjectURL(link.href);
                }).catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        prefix: 'Could not retrieve Document: '
                    })
                });
        } else {
            downloadLatestDocument(selectedDoc.id!)
                .then(res => {
                    const blob = new Blob([res.data]);
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.setAttribute('download', selectedDoc.fileName);
                    link.click();
                    URL.revokeObjectURL(link.href);
                }).catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        prefix: 'Could not retrieve Document: '
                    })
                });
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleModalClose}
        >
            <div className="modal-form">
                <Card className="detail-form sm">
                    <CardHeader
                        title='Document History'
                        action={
                            <IconButton color="primary" aria-label="Close" onClick={handleModalClose}>
                                <Close />
                            </IconButton>
                        }
                    />
                    <CardContent>
                        <TableContainer component={Paper} className="salary-table-container">
                            <Table stickyHeader>
                                <TableHead className="table-header">
                                    <TableRow>
                                        <TableCell
                                            key='header-date'
                                            className="salary-table-header-cell"
                                        >
                                            <div className='header-cell-label'>Date</div>
                                        </TableCell>
                                        <TableCell
                                            key='header-download'
                                            className="salary-table-header-cell"
                                        >
                                            <div className='header-cell-label center'>Download</div>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {versions.map((obj: DocumentVersion, idx: number) => {
                                        return (
                                            <TableRow key={'row-' + idx}>
                                                <TableCell key={'date-' + idx} className='body-cell'>
                                                    {displayDateWithTime(obj.created)}
                                                </TableCell>
                                                {canDownload &&
                                                    <TableCell key={'download-' + idx} className='body-cell center'>
                                                        <IconButton onClick={() => handleDownloadDocument(selectedDocument!, obj.versionId)}>
                                                            <GetApp />
                                                        </IconButton>
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </div>
        </Modal>
    )
};

export default HistoryModal;
import * as React from 'react';

import { useDispatch } from 'react-redux';

import { BCheckbox, BForm, BGrid, BSubmit, BTextField } from 'mui-bueno';
import { Formik, FormikHelpers } from 'formik';

import {
    Card,
    CardActions,
    CardContent,
    InputAdornment,
    Modal,
} from '@material-ui/core';

import CardTitle from '../../../common/Card/CardTitle'
import { AccountPageRequest, LoggedPayment, SelectOption } from '../../../@types';
import { NumberFormatter } from '../../../common/Utils/NumberFormatter';
import CheckedAutocomplete from '../../../common/CheckedAutocomplete/CheckedAutocomplete';
import { BGridProps } from 'mui-bueno/lib/components/BGrid';
import { handleErrorResponse } from '../../../service/utils';
import { showSuccessSnackbar } from '../../../modules/messageSnackbarReducer';
import { getAccount, getAccountPage } from '../../../service/Account/account';
import { getStudiesByAccount, getStudy } from '../../../service/Study/study';
import { getUnpaidInvoices } from '../../../service/Invoice/invoice';
import { logHoldbackPayment, logPayment } from '../../../service/AccountPayment/accountPayment';
import OutlinedFileInput from '../../../common/OutlinedFileInput/OutlinedFileInput';

interface Props {
    open: boolean,
    setOpen: (state: boolean) => void,
    payingAccountId?: number,
    receivingAccountId?: number,
    studyId?: number,
    invoiceIds?: number[],
    logHoldback?: boolean,
    receivedPayment?: boolean,
    updateOnChange: boolean,
    setUpdateOnChange: (state: boolean) => void,
}

const toolTip = 'Regular payments and holdback payments must be logged separately. \n\n If an invoice # is not specified, the payment will go towards existing receivable invoices for the payee account.'

const LogPayment: React.FC<Props> = props => {

    const { open, setOpen, payingAccountId, receivingAccountId, studyId, invoiceIds, logHoldback: logHoldbackOnly = false, receivedPayment = false, updateOnChange, setUpdateOnChange } = props;

    const [selectedFile, setSelectedFile] = React.useState<File>();
    const [isHoldback, setIsHoldback] = React.useState<boolean>(logHoldbackOnly)
    const [selectedPayor, setSelectedPayor] = React.useState<SelectOption<number> | null>();
    const [selectedPayee, setSelectedPayee] = React.useState<SelectOption<number> | null>();
    const [selectedStudy, setSelectedStudy] = React.useState<SelectOption<number> | null>();
    const [selectedInvoices, setSelectedInvoices] = React.useState<SelectOption<number>[]>([]);

    //State management to track dropdown option lists
    const [payorOptions, setPayorOptions] = React.useState<SelectOption<number>[]>([]);
    const [payeeOptions, setPayeeOptions] = React.useState<SelectOption<number>[]>([]);
    const [studyOptions, setStudyOptions] = React.useState<SelectOption<number>[]>([]);
    const [invoiceOptions, setInvoiceOptions] = React.useState<SelectOption<number>[]>([]);

    //State maneagement to track if the selected id's were valid as options
    const [preSelectedPayorId, setPreSelectedPayorId] = React.useState<boolean>(false);
    const [preSelectedPayeeId, setPreSelectedPayeeId] = React.useState<boolean>(false);
    const [preSelectedStudyId, setPreSelectedStudyId] = React.useState<boolean>(false);

    const dispatcher = useDispatch();

    const initialValues: LoggedPayment = {
        payingAccountId: payingAccountId ? payingAccountId : null,
        receivingAccountId: receivingAccountId ? receivingAccountId : null,
        studyId: studyId ? studyId : null,
        invoiceIds: invoiceIds ? invoiceIds : [],
        amount: 0,
        checkNo: '',
        remittanceFile: null,
        holdback: logHoldbackOnly
    }

    const validFileTypes = ['pdf', 'doc', 'docx', 'png', 'jpg', 'jpeg', 'csv', 'txt', 'xls', 'xlsx', 'ppt', 'pptx']

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })

    React.useEffect(() => {
        //retrieve list for payorOptions
        if (open) {
            //set holdback
            setIsHoldback(logHoldbackOnly)
            if (payingAccountId) {
                //get account by id
                getAccount(payingAccountId).then((payorRes) => {
                    const payor = { value: payingAccountId, label: payorRes.data.name }
                    setPayorOptions([payor])
                    setSelectedPayor(payor)
                    setPreSelectedPayorId(true);

                    if (studyId) {
                        //get study by id
                        getStudy(studyId).then((resStudy) => {
                            if (resStudy.data.sponsorId === payingAccountId) {
                                const tempStudy = { value: studyId, label: resStudy.data.name }
                                setStudyOptions([tempStudy]);
                                setSelectedStudy(tempStudy);
                                setPreSelectedStudyId(true)
                            } else {
                                setPreSelectedStudyId(false)
                            }

                        }).catch(err => {
                            setPreSelectedStudyId(false);
                            handleErrorResponse(err, dispatcher, {
                                prefix: 'Could not retrieve study : '
                            })
                        })
                    }

                }).catch(err => {
                    setPreSelectedPayorId(false);
                    handleErrorResponse(err, dispatcher, {
                        prefix: 'Could not retrieve account : '
                    })
                })
            }

            if (receivingAccountId) {
                getAccount(receivingAccountId).then((payeeRes) => {
                    const payee = { value: receivingAccountId, label: payeeRes.data.name }
                    setPayeeOptions([payee])
                    setSelectedPayee(payee)
                    setPreSelectedPayeeId(true);

                }).catch(err => {
                    setPreSelectedPayeeId(false);
                    handleErrorResponse(err, dispatcher, {
                        prefix: 'Could not retrieve account : '
                    })
                })
            }

            if (!payingAccountId || !receivingAccountId) {
                // populate account options list for paying and receiving accounts
                const pageReq: AccountPageRequest = {
                    page: 0,
                    sort: 'name'
                }
                //get account list options sorted by name
                getAccountPage(pageReq).then((res) => {
                    const temp: SelectOption<number>[] = []
                    res.data.list.forEach((account) => {
                        temp.push({
                            value: account.id!,
                            label: account.name
                        })
                    })
                    if (!payingAccountId) {
                        setPayorOptions(temp);
                    }
                    if (!receivingAccountId) {
                        setPayeeOptions(temp);
                    }

                }).catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        prefix: 'Could not retrieve account list: '
                    })
                })
            }

        } else {
            //reset form
            setPayorOptions([])
            setPayeeOptions([])
            setSelectedPayor(null)
            setSelectedPayee(null)
            setSelectedStudy(null)
            setStudyOptions([])
            setInvoiceOptions([])
            setSelectedInvoices([])
        }
    }, [open]);

    //update study dropdown option list
    React.useEffect(() => {
        if (!preSelectedStudyId && open) {
            if (selectedPayor) {
                //get a list of studies for the account for dropdown
                getStudiesByAccount(selectedPayor.value)
                    .then((res) => {
                        const temp: SelectOption<number>[] = []
                        res.data.forEach((study) => {
                            temp.push({
                                value: study.id!,
                                label: study.name + ` (${study.identifier})`
                            })
                        })
                        setStudyOptions(temp);
                        if (temp.length == 1) {
                            setSelectedStudy(temp[0])
                        } else {
                            setSelectedStudy(null)
                        }

                    }).catch(err => {
                        handleErrorResponse(err, dispatcher, {
                            prefix: 'Could not retrieve study list: '
                        })
                    })
            } else {
                setStudyOptions([]);
                setSelectedStudy(null)
            }
        }
    }, [selectedPayor])

    //update invoice dropdown option list
    React.useEffect(() => {
        if (open) {
            if (selectedPayor && selectedPayee && selectedStudy) {
                getUnpaidInvoices(selectedPayor.value, selectedStudy.value, selectedPayee.value, isHoldback)
                    .then((res) => {
                        const temp: SelectOption<number>[] = []
                        res.data.forEach((invoice) => {
                            temp.push({
                                value: invoice.id!,
                                label: invoice.invoiceNo
                            })
                        })

                        if (invoiceIds) {
                            //pre-select any invoices with and id that was passed in
                            const defaultSelection: SelectOption<number>[] = [];

                            for (const id of invoiceIds) {
                                const option = temp.find((a) => a.value == id);
                                if (option)
                                    defaultSelection.push(option)
                            }

                            if (defaultSelection.length > 0) {
                                setInvoiceOptions(temp);
                                setSelectedInvoices(defaultSelection);
                                return;
                            }
                        }

                        setInvoiceOptions(temp)
                        setSelectedInvoices([])

                    }).catch(err => {
                        handleErrorResponse(err, dispatcher, {
                            prefix: 'Could not retrieve invoice list: '
                        })
                    })
            } else {
                setInvoiceOptions([])
                setSelectedInvoices([])
            }
        }

    }, [selectedStudy, selectedPayee, isHoldback])

    const validateForm = (values: any) => {
        const errors: { [k: string]: string } = {};
        if (!selectedPayor)
            errors.payor = 'Payor is required'
        if (!selectedPayee)
            errors.payee = 'Payee is required'
        if ((selectedPayor && selectedPayee) && (selectedPayor.value == selectedPayee.value)) {
            errors.payor = 'Payor and Payee cannot be the same account'
            errors.payee = 'Payor and Payee cannot be the same account'
        }
        if (!selectedStudy)
            errors.study = 'Study is required'
        if (values.amount <= 0)
            errors.amount = 'Payment Amount must be greater than 0'
        return errors;
    }

    const getFormData = (data: any): FormData => {
        const formData = new FormData();

        formData.set('amount', String(data.amount));

        if (data.checkNo && String(data.checkNo).length > 0) {
            formData.set('checkNo', data.checkNo);
        }

        if (selectedInvoices) {
            const selectedInvoiceIds = selectedInvoices?.map((invoice) => Number(invoice.value)).toString()
            formData.set('invoiceIds', selectedInvoiceIds)
        }

        if (selectedFile) {
            formData.append('file', selectedFile, selectedFile.name);
        }

        return formData;
    }

    const onSubmitPayment = (data: LoggedPayment, { setErrors, resetForm }: FormikHelpers<LoggedPayment>) => {
        const errors = validateForm(data.amount);
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            if (errors.remittanceFile) {
                setSelectedFile(undefined);
            }
            return;
        }
        if (selectedPayor && selectedPayee && selectedStudy) {

            const formData = getFormData(data)

            if (data.holdback) {
                logHoldbackPayment(selectedPayor.value, selectedStudy.value, formData)
                    .then(res => {
                        dispatcher(showSuccessSnackbar('Holdback Payment logged'));
                        setUpdateOnChange(!updateOnChange)
                        handleCloseModal();
                    }).catch(err => {
                        handleErrorResponse(err, dispatcher, {
                            prefix: 'Holdback Payment could not be logged: '
                        })
                    });
            } else {
                logPayment(selectedPayor.value, selectedStudy.value, selectedPayee.value, formData)
                    .then(res => {
                        if (res.data) {
                            const credit = res.data.amount;
                            const fullPayment = data.amount;
                            if (credit < fullPayment) {
                                dispatcher(showSuccessSnackbar(`${getCurrencyText(fullPayment - credit)} has been applied as a payment. ${getCurrencyText(credit)} was turned into a credit on the paying account.`));
                            } else {
                                dispatcher(showSuccessSnackbar('Payment has been saved as a credit on the paying account'))
                            }

                        } else {
                            dispatcher(showSuccessSnackbar('Payment has been logged and applied'));
                        }

                        setUpdateOnChange(!updateOnChange)
                        handleCloseModal();
                    }).catch(err => {
                        handleErrorResponse(err, dispatcher, {
                            prefix: 'Payment could not be logged: '
                        })
                    });
            }
            resetForm();
            handleCloseModal();
        }

    }

    const handleCloseModal = () => {
        setSelectedFile(undefined);
        setOpen(false);
    }

    const getModalTitle = () => {
        if (isHoldback) {
            return receivedPayment ? 'Log Received Holdback Payment' : 'Log Holdback Payment'
        } else {
            return receivedPayment ? 'Log Received Payment' : 'Log Payment'
        }
    }

    const getCurrencyText = (input: number): string => {
        return formatter.format(input);
    }

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
        >
            <div className='modal-form'>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmitPayment}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validate={validateForm}
                >
                    {formikValues => (
                        <BForm>
                            <Card className='detail-form sm'>
                                <CardTitle title={getModalTitle()} handleClose={handleCloseModal} toolTip={logHoldbackOnly ? undefined : toolTip} />
                                <CardContent>
                                    <BGrid {...props as BGridProps}>
                                        <CheckedAutocomplete
                                            idText='payor'
                                            labelText='Payor'
                                            options={payorOptions}
                                            acValue={selectedPayor}
                                            multiple={false}
                                            closeOnSelect
                                            onChange={setSelectedPayor}
                                            error={!!formikValues.errors['payor']}
                                            errorMessage={formikValues.errors.payor}
                                            variant='outlined'
                                            noCheckmark
                                            size='small'
                                            disabled={preSelectedPayorId}
                                            autoFocus
                                        />
                                    </BGrid>
                                    <BGrid {...props as BGridProps}>
                                        <CheckedAutocomplete
                                            idText='payee'
                                            labelText='Payee'
                                            options={payeeOptions}
                                            acValue={selectedPayee}
                                            multiple={false}
                                            closeOnSelect
                                            onChange={setSelectedPayee}
                                            error={!!formikValues.errors['payee']}
                                            errorMessage={formikValues.errors.payee}
                                            variant='outlined'
                                            noCheckmark
                                            size='small'
                                            disabled={preSelectedPayeeId}
                                            autoFocus={preSelectedPayorId}
                                        />
                                    </BGrid>
                                    <BTextField
                                        name='amount'
                                        label='Payment Amount'
                                        required
                                        placeholder='e.g. 2,000.00'
                                        autoFocus={(selectedPayor && selectedPayee) ? true : false}
                                        InputProps={{
                                            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                                            inputComponent: NumberFormatter as any
                                        }}
                                    />
                                    <BTextField
                                        name='checkNo'
                                        label='Check Number'
                                    />
                                    <OutlinedFileInput
                                        name='RemittanceFile'
                                        label='Remittance Record'
                                        onFileSelect={setSelectedFile}
                                        fileTypes={validFileTypes}
                                    />
                                    <BGrid {...props as BGridProps}>
                                        <CheckedAutocomplete
                                            idText='study'
                                            labelText='Study'
                                            placeholder='Select study...'
                                            multiple={false}
                                            options={studyOptions}
                                            acValue={selectedStudy}
                                            closeOnSelect
                                            onChange={setSelectedStudy}
                                            error={!!formikValues.errors['study']}
                                            variant='outlined'
                                            noCheckmark
                                            size='small'
                                            disabled={preSelectedStudyId || !selectedPayor}
                                        />
                                    </BGrid>
                                    {!logHoldbackOnly &&
                                        <BCheckbox
                                            name='holdback'
                                            label={'Payment for Holdback?'}
                                            onClick={() => setIsHoldback(!formikValues.values.holdback)}
                                        />
                                    }
                                    <BGrid {...props as BGridProps}>
                                        <CheckedAutocomplete
                                            idText='invoice'
                                            labelText='Invoice'
                                            placeholder='Select invoice(s) to apply payment to…'
                                            multiple
                                            options={invoiceOptions}
                                            acValue={selectedInvoices}
                                            closeOnSelect={false}
                                            onChange={setSelectedInvoices}
                                            error={!!formikValues.errors['invoice']}
                                            variant='outlined'
                                            size='small'
                                            disabled={!selectedStudy}
                                        />
                                    </BGrid>
                                </CardContent>
                                <CardActions className='flex-end'>
                                    <BSubmit
                                        variant='contained'
                                        color='primary'
                                    >
                                        Submit
                                    </BSubmit>
                                </CardActions>
                            </Card>
                        </BForm>
                    )}
                </Formik>
            </div>
        </Modal>
    );
}

export default LogPayment;




import axiosInstance from '../Access/axiosInstance';
import {
    ChangePasswordRequest,
    ChangeTokenPasswordRequest,
    CreateUser,
    PasswordResetRequest,
    User,
    UserPage,
    UserPageRequest,
    UserRole,
    UserRolePermission,
    UserSummary
} from '../../@types';
import { handleError } from '../utils';

/**
 * Gets a page of users
 */
export const getUserPage = (pageReq: UserPageRequest) =>
    axiosInstance.get<UserPage>(
        '/api/users',
        {
            params: pageReq
        }
    ).catch(handleError);

/** 
 * Gets a list of UserSumaries. To be used on the UserList page
 */
export const getUserSummaries = () =>
    axiosInstance.get<UserSummary[]>(
        '/api/users/summaries'
    ).catch(handleError);

/**
 * Gets a list of UserSummaries of active Users limited by the logged in Users access.
 * To be used when importing an existing User into a Simulator
 */
export const getActiveRestrictedUserSummaries = () =>
    axiosInstance.get<UserSummary[]>(
        'api/users/activeRestricted'
    ).catch(handleError);

/**
 * Retuns whether the user has not changed the temporary password sent by Cognito
 */
export const hasUserResetTemporaryPassword = (id: number) =>
    axiosInstance.get<boolean>(
        `/api/users/${id}/temporaryPassword`
    ).catch(handleError);
    

export const getDivisionUsers = (id: number) =>
    axiosInstance.get<User[]>(
        `/api/users/division/${id}`
    ).catch(handleError);

/**
 * Gets a user by id
 */
export const getUser = (id: number) =>
    axiosInstance.get<User>(
        `/api/users/${id}`
    ).catch(handleError);

/**
 * Get currently logged in user
 */
export const getCurrentUser = () =>
    axiosInstance.get<User>(
        `/api/users/currentUser`
    ).catch(handleError);

/**
 * Updates a user
 */
export const updateUser = (user: User) =>
    axiosInstance.put<User>(
        '/api/users', user
    ).catch(handleError);

/**
 * Creates a user
 */
export const createUser = (createUserObject: CreateUser) =>
    axiosInstance.post<User>(
        '/api/users', createUserObject
    ).catch(handleError);

/**
 * Deactivates a user
 * @param id
 */
export const deactivateUser = (id: number) =>
    axiosInstance.put<User>(
        `/api/users/${id}/deactivate`
    ).catch(handleError);

/**
 * Activates a user
 * @param id
 */
export const activateUser = (id: number) =>
    axiosInstance.put<User>(
        `/api/users/${id}/activate`
    ).catch(handleError);

/**
 * Assigns the default Admin Role to a User
 * @param id 
 */
export const assignUserAdminRole = (id: number) =>
    axiosInstance.put(
        `/api/users/${id}/assignAdminRole`
    ).catch(handleError);

/**
 * Removes the default Admin Role from a User
 * @param id 
 */
export const removeUserAdminRole = (id: number) =>
    axiosInstance.put(
        `/api/users/${id}/removeAdminRole`
    ).catch(handleError);

/**
 * Sign up
 */
export const signUp = (user: User) =>
    axiosInstance.post<User>(
        '/api/users/signUp', user
    ).catch(handleError);

/**
 * Change password
 */
export const changePassword = (changePasswordRequest: ChangePasswordRequest) =>
    axiosInstance.post(
        '/api/users/password-change', changePasswordRequest
    ).catch(handleError);

/**
 * Request reset password
 */
export const requestPasswordReset = (passwordResetRequest: PasswordResetRequest) =>
    axiosInstance
        .put('/api/users/password-reset-request', passwordResetRequest)
        .catch(handleError);

/**
* Request token reset password
*/
export const changePasswordWithToken = (resetRequest: ChangeTokenPasswordRequest) =>
    axiosInstance
        .post('/api/users/token-password-change', resetRequest)
        .catch(handleError);

/**
 * Get all the user's permissions
 */
export const getUserPermissions = (id: number) =>
    axiosInstance.get<string[]>(
        `/api/users/${id}/permissions`
    ).catch(handleError);

/**
 * Retrieves a list of UserRoles for a given User. Includes information for Study and Division-based Roles.
 * @param id a valid User Id
 * @returns list of UserRoles
 */
export const getUserRoles = (id: number) =>
    axiosInstance.get<UserRole[]>(
        `/api/users/${id}/userRoles`
    ).catch(handleError);

/**
 * Get currently logged in user's permissions with restriction
 */
export const getCurrentUserPermissions = () =>
    axiosInstance.get<UserRolePermission[]>(
        '/api/users/currentUserPermissions'
    ).catch(handleError);

/**
 * Resends the user invite email to the specified user
 */
export const resendUserInvite = (id: number) =>
    axiosInstance.put<User>(
        `/api/users/${id}/resendUserInvite`
    ).catch(handleError);
import * as React from 'react';

import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import {
    Button,
    Typography
} from '@material-ui/core';
import * as yup from 'yup';

import { ChangeTokenPasswordRequest } from '../../../@types';
import { changePasswordWithToken } from '../../../service/Management/users';
import { handleUnprocessableEntity } from '../../../service/utils';
import { showSuccessSnackbar } from '../../../modules/messageSnackbarReducer';
import GridWrapper from '../../../common/GridWrapper/GridWrapper';
import OutlinedPassword from '../../../common/OutlinedTextField/OutlinedPassword';
import { Link } from 'react-router-dom';

const schema = yup.object().shape({
    newPassword: yup.string().required('New password is required'),
    confirmNewPassword: yup.string().required('Must confirm new password')
        .test('new-password-match', 'New passwords do not match', function(value) {
            const { newPassword } = this.parent;
            return newPassword === value;
        }),
});

interface PasswordRequest {
  newPassword: string;
  confirmNewPassword: string;
}

const initialState = {
    newPassword: '',
    confirmNewPassword: ''
}

interface RouteParams {
    token?: string; // Either the id or 'new'
}

const ResetTokenPassword: React.FC = () => {
    const [passwordValues, setPasswordValues] = React.useState<PasswordRequest>(initialState);

    const [errors, setErrors] = React.useState<any>({});

    const { token } = useParams<RouteParams>();

    const dispatch = useDispatch();

    const history = useHistory();

    const handleChange = (name: keyof PasswordRequest) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setErrors({});
        setPasswordValues({ ...passwordValues, [name]: event.target.value });
    }

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault(); // prevent component from reloading

        schema.validate(passwordValues, {abortEarly: false})
            .then(() => {
                if (token) {
                    const changePasswordRequest: ChangeTokenPasswordRequest = {
                        token,
                        newPassword: passwordValues.newPassword
                    }

                    changePasswordWithToken(changePasswordRequest).then(() => {
                        dispatch(showSuccessSnackbar('Password reset successfully'));
                        history.push('/login');
                    }).catch(err => {
                        if (err.response) {
                            if (err.response.status === 422) {
                                handleUnprocessableEntity(err, setErrors)
                            }
                            if (err.response.status === 410 || err.response.status === 404) {
                                setErrors({ 'invalidToken' : true })
                            }
                        }
                    });
                }
            }).catch( (err: yup.ValidationError) => {
                const list: any = {};
                for (const e of err.inner) {
                    if (e.path) list[e.path] = e.message;
                }
                setErrors(list);
            });
    }

    return (
        <GridWrapper>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Typography className="card-heading">Reset Password</Typography>
                <OutlinedPassword
                    label="New password"
                    value={passwordValues.newPassword}
                    onChange={handleChange('newPassword')}
                    error={errors['newPassword']}
                />
                <OutlinedPassword
                    label="Confirm new password"
                    value={passwordValues.confirmNewPassword}
                    onChange={handleChange('confirmNewPassword')}
                    error={errors['confirmNewPassword']}
                />
                <Typography variant="body2" color="error">{errors['general']}</Typography>
                {errors['invalidToken'] && <Typography variant="body2" color="error">
                    Oops! You can no longer use this link to reset your password.
                    You can request a new password reset link <Link to='/user/password/forgot'>here.</Link>
                </Typography>}
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className='card-button'
                >
                    Save
                </Button>
            </form>
        </GridWrapper>
    );
}

export default ResetTokenPassword;
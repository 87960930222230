import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Card, CardContent, Grid, Tooltip, Typography } from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';

import { AccountPaymentDetail, InvoiceStatus, LineItemDetailWithInvoice, LineItemSourceType } from '../../../@types';
import { NumberFormatter } from '../../../common/Utils/NumberFormatter';
import { getPaymentDetail } from '../../../service/AccountPayment/accountPayment';
import { handleErrorResponse } from '../../../service/utils';

import DownloadPdf from '../../Invoicing/Invoice/DownloadPdf';
import InvoiceablesTable from '../../../common/Invoicing/InvoiceablesTable';
import { renderDescriptionCell, renderInvoiceCell } from '../../../common/DataTable/Utils';
import ViewSourceModal from '../../Invoicing/Invoice/ViewSourceModal';
import usePermissions from '../../../common/hooks/usePermissions';

interface RouteParams {
    id: string; // account id
    optionId: string; // payment id
}

interface TableRow {
    sourceType: LineItemSourceType;
    sourceId: number;
    dateCreated: Date;
    description: JSX.Element;
    invoice: JSX.Element;
    totalCost: JSX.Element;
    paid: number;
    status: InvoiceStatus;
}

const PaymentDetail: React.FC = () => {

    const { id, optionId } = useParams<RouteParams>();

    const dispatch = useDispatch();
    const history = useHistory();

    const emptyPayment = {
        id: null,
        credit: false,
        used: true,
        amount: 0,
        remittanceFilePath: '',
        payingAccountId: 0,
        checkNo: '',
        autopayable: false,
    }

    const emptyPaymentDetail: AccountPaymentDetail = {
        originalPayment: emptyPayment,
        amountUsed: 0,
        amountUnused: 0,
        studyName: '',
        payingAccountName: '',
        receivingAccountName: '',
        payingAccountId: 0,
        receivingAccountId: 0,
        lineItemsWithInvoiceInfo: [],
        studyId: 0,
        studyHoldback: 0
    }

    const [paymentDetail, setPaymentDetail] = React.useState<AccountPaymentDetail>(emptyPaymentDetail);
    const [tableData, setTableData] = React.useState<any[]>([]);
    const [viewSourceOpen, setViewSourceOpen] = React.useState<boolean>(false);
    const [clickedObj, setClickedObj] = React.useState<LineItemDetailWithInvoice | null>(null);
    const canViewInvoiceDetails = usePermissions('API_INVOICE_GET_BY_ID');

    React.useEffect(() => {
        getPaymentDetail(Number(optionId)).then(res => {
            setPaymentDetail(res.data);

            // create table data
            const lineItems = res.data.lineItemsWithInvoiceInfo;
            const data: TableRow[] = [];
            lineItems.forEach(lineItem => {
                const totalCost = lineItem.unitCount * lineItem.actualUnitCost;
                const subtotal = lineItem.unitCount * lineItem.unitCostMhb;
                const holdback = totalCost - subtotal;
                data.push({
                    sourceType: lineItem.sourceType,
                    sourceId: lineItem.sourceId,
                    description: renderDescriptionCell(lineItem, setClickedObj, setViewSourceOpen, viewSourceOpen),
                    invoice: renderInvoiceCell(lineItem.invoiceNo, lineItem.invoiceId, history, canViewInvoiceDetails, lineItem.invoiceCreated),
                    totalCost: renderTotalCostCell(lineItem.lineItemId, subtotal, holdback, totalCost),
                    paid: lineItem.amountCoveredByPayment,
                    status: lineItem.status,
                    dateCreated: lineItem.lineItemCreated
                })
            });
            setTableData(data);
        }).catch(err => {
            handleErrorResponse(err, dispatch, {
                prefix: 'Could not retrieve Payment Detail: '
            })
        })
    }, []);

    const columns = ['description', 'invoice', 'totalCost', 'paid']

    const PaymentInformation = () => (
        <Grid item xs={12} lg={6}>
            <Typography className='pd-section-title'>Payment Information</Typography>
            <Card>
                <CardContent className='payment-info-card'>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={5}>
                            <div className='content-left'>
                                <div>
                                    <Typography className='label-text' variant='body1'>Check Number</Typography>
                                    <Typography className='value-text check' variant='body2'>{paymentDetail.originalPayment.checkNo}</Typography>
                                </div>
                                {paymentDetail.originalPayment.remittanceFilePath?
                                    <div>
                                        <DownloadPdf type='REMITTANCE' id={Number(optionId)} fileName={paymentDetail.originalPayment.remittanceFilePath}/>
                                    </div>
                                    : null // can later replace with upload file button
                                } 
                            </div>
                        </Grid>
                        <Grid item className='content-right' xs={12} sm={7}>
                            <div className='content-row'>
                                <Typography className='label-text payment-info' variant='body1'>Total Check Amount:</Typography>
                                <Typography className='value-text payment-info' variant='body2'>
                                    <NumberFormatter currency value={paymentDetail.originalPayment.amount} />
                                </Typography>
                            </div>
                            <div className='content-row'>
                                <Typography className='label-text payment-info' variant='body1'>Amount Used:</Typography>
                                <Typography className='value-text payment-info' variant='body2'>
                                    <NumberFormatter currency value={paymentDetail.amountUsed} />
                                </Typography>
                            </div>
                            <div className='content-row'>
                                <Typography className='label-text payment-info' variant='body1'>Amount Unused:</Typography>
                                <Typography className='value-text payment-info' variant='body2'>
                                    <NumberFormatter currency value={paymentDetail.amountUnused} />
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );

    const InvoiceInformation = () => (
        <Grid item xs={12} lg={6}>
            <Typography className='pd-section-title'>Invoice Information</Typography>
            <Card>
                <CardContent className='invoice-info-card'>
                    <div className='content-row'>
                        <Typography className='label-text invoice-info' variant='body1'>Payor Account:</Typography>
                        <Typography className='value-text' variant='body2'>{paymentDetail.payingAccountName}</Typography>
                    </div>
                    <div className='content-row'>
                        <Typography className='label-text invoice-info' variant='body1'>Payee Account:</Typography>
                        <Typography className='value-text' variant='body2'>{paymentDetail.receivingAccountName}</Typography>
                    </div>
                    <div className='content-row'>
                        <Typography className='label-text invoice-info' variant='body1'>Study:</Typography>
                        <Typography className='value-text' variant='body2'>{paymentDetail.studyName}</Typography>
                    </div>
                    <div className='content-row'>
                        <Typography className='label-text invoice-info' variant='body1'>Study Holdback:</Typography>
                        <Typography className='value-text' variant='body2'>
                            <NumberFormatter percent value={paymentDetail.studyHoldback *.01} />
                        </Typography>
                    </div>
                </CardContent>
            </Card>
        </Grid>
    );

    const renderTotalCostCell = (lineItemId: number, subtotal: number, holdback: number, totalCost: number) => {
        const tooltip = (
            <>
                <div className='tooltip-row'>
                    <Typography variant='body2' className='tooltip-row-label'>Subtotal:</Typography>
                    <Typography variant='body2'><NumberFormatter currency value={subtotal} /></Typography>
                </div>
                <div className='tooltip-row'>
                    <Typography variant='body2' className='tooltip-row-label'>Holdback:</Typography>
                    <Typography variant='body2'><NumberFormatter currency value={holdback} /></Typography>
                </div>
                <div className='tooltip-row'>
                    <Typography variant='body2' className='tooltip-row-label'>Total Cost:</Typography>
                    <Typography variant='body2'><NumberFormatter currency value={totalCost} /></Typography>
                </div>
            </>
        )
        return (
            <Tooltip arrow title={tooltip}>
                <span><NumberFormatter currency value={totalCost}/></span>
            </Tooltip>
        )
    }

    const PaymentLineItems = () => (
        <Grid item xs={12}>
            <Typography className='pd-section-title'>Line Items</Typography>
            <InvoiceablesTable
                type='line-items' 
                data={tableData}
                showColumns={columns}
                disableEdit
            />
            {clickedObj && <ViewSourceModal
                open={viewSourceOpen}
                setOpen={setViewSourceOpen}
                lineItem={clickedObj}
            />}
        </Grid>
    );
    
    const PageHeading = () => (
        <div className='page-heading'>
            <Typography
                className='title inactive cursor-pointer'
                onClick={() => history.push('/manage/account')}
            >
                Account Management
            </Typography>
            <NavigateNext className='arrow' />
            <Typography 
                className='title inactive cursor-pointer'
                onClick={() => history.push(`/manage/account/${id}`)}
            >
                {paymentDetail.payingAccountName}
            </Typography>
            <NavigateNext className='arrow' />
            <Typography className='title'>
                Payment Details
            </Typography>
        </div>
    )

    return (
        <div className='payment-detail'>
            <PageHeading />
            <div className="page-body margin-auto reduce-top-sm">
                <Grid container direction='row' spacing={3} alignItems="stretch" >
                    <PaymentInformation />
                    <InvoiceInformation />
                    <PaymentLineItems />
                </Grid>
            </div>
        </div>
    )
}

export default PaymentDetail;
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Tab, Tabs, Tooltip, Typography } from '@material-ui/core';
import { AccessTime, Add, HourglassEmptyTwoTone, Info, ReceiptTwoTone } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import { InvoiceDetailWithChecks, LineItemDetailWithInvoice } from '../../../../@types';
import { renderAccountCell, renderDescriptionCell, renderInvoiceCell } from '../../../../common/DataTable/Utils';
import { MONTHS } from '../../../../common/Utils/utils';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../../service/utils';
import { getStudy } from '../../../../service/Study/study';
import { generateInvoiceByStudy, getLastInvoiceGeneration, getStudyPayableInvoices, getStudyReceivableInvoices } from '../../../../service/Invoice/invoice';
import { getStudyPayableLineItems, getStudyReceivableLineItems } from '../../../../service/Invoice/lineItem';

import LogPayment from '../../../Invoicing/Payment/LogPayment';
import InvoiceablesTable from '../../../../common/Invoicing/InvoiceablesTable';
import InvoicesSummaryCard from '../../../../common/Invoicing/InvoicesSummaryCard';
import AccountSummary from './AccountSummary';
import StudyAccountSettings from '../../../Contract/Details/Accounts/StudyAccountSettings';
import usePermissions from '../../../../common/hooks/usePermissions';
import ViewSourceModal from '../../../Invoicing/Invoice/ViewSourceModal';


interface RouteParams {
    id: string;
    subSection: 'receivable' | 'payable';
    subId: 'invoices' | 'line-items';
}

interface State {
    objects: LineItemDetailWithInvoice[];
    filters: {
        NOT_INVOICED: boolean;
        AUTOPAY: boolean;
        ALL: boolean;
    }
}

const initialState: State = {
    objects: [],
    filters: {
        NOT_INVOICED: false,
        AUTOPAY: false,
        ALL: true,
    }
}

interface Action {
    type: 'objects' | 'filters';
    payload?: any;
}

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'objects':
            return {
                ...state,
                objects: action.payload.list,
            }
        case 'filters':
            return {
                ...state,
                filters: action.payload,
            };
        default:
            return state;
    }
};

const StudyInvoicing: React.FC = () => {
    const { id, subSection, subId: sectionPath } = useParams<RouteParams>();

    const history = useHistory();
    const dispatcher = useDispatch();

    const [studyName, setStudyName] = React.useState<string>('');
    const [internalAccountId, setInternalAccountId] = React.useState<number>();
    const [sponsorAccountId, setSponsorAccountId] = React.useState<number>();
    const [studyStarted, setStudyStarted] = React.useState<boolean>(false);
    const [updateOnChange, setUpdateOnChange] = React.useState<boolean>(false);
    const [viewSourceOpen, setViewSourceOpen] = React.useState<boolean>(false);
    const [clickedObj, setClickedObj] = React.useState<LineItemDetailWithInvoice | null>(null);

    const [tableData, setTableData] = React.useState<any[]>([]);

    const [payOpen, setPayOpen] = React.useState(false);
    const [editLineItemOpen, setEditLineItemOpen] = React.useState<boolean>(false);
    const [lastCreatedDate, setLastCreatedDate] = React.useState<string | null>(null);
    const [confirmGeneration, setConfirmGeneration] = React.useState<boolean>(false);
    const [invoiceInitiated, setInvoiceInitiated] = React.useState<boolean>(false);

    // line item filter states
    const [state, dispatchPage] = React.useReducer(reducer, initialState);
    const { objects, filters } = state;

    const showLogPayment: boolean = usePermissions('API_ACCOUNT_PAYMENT_LOG_PAYMENT')
        && usePermissions('API_ACCOUNT_PAYMENT_LOG_HOLDBACK_PAYMENT');
    const canViewAccountDetails: boolean = usePermissions('API_ACCOUNT_GET_BY_ID');
    const canViewInvoiceDetails: boolean = usePermissions('API_INVOICE_GET_BY_ID');
    const canGenerateInvoices: boolean = usePermissions('API_INVOICE_GENERATE_BY_STUDY');
    const canGetLastInvoiceGeneration: boolean = usePermissions('API_INVOICE_GET_LAST_INVOICE_GENERATION');

    React.useEffect(() => {
        if (subSection != 'receivable' && subSection != 'payable') {
            history.push(`/study/${id}/invoicing/receivable/invoices`)
        }
        getStudy(Number(id)).then(res => {
            setStudyName(res.data.name);
            setSponsorAccountId(res.data.sponsorId);
            setInternalAccountId(res.data.accountId);
            setStudyStarted(!!(res.data.started));
        }).catch(err => {
            dispatcher(showErrorSnackbar('Could not find Study'));
            history.push('/study');
        });

        if (canGetLastInvoiceGeneration) {
            getLastInvoiceGeneration(Number(id)).then(res => {
                setLastCreatedDate(res.data.lastInvoiceCreatedDate);
                setInvoiceInitiated(res.data.invoiceInitiatedToday);
            });
        }
    }, []);

    React.useEffect(() => {
        if (sectionPath != 'invoices' && sectionPath != 'line-items') {
            history.push(`/study/${id}/invoicing/${subSection}/invoices`)
        }
    }, [sectionPath]);

    const convertData = (data: any[], type: 'invoices' | 'line-items') => {
        const tempData: any[] = [];
        if (type == 'invoices') {
            data.forEach((obj: InvoiceDetailWithChecks) => {
                tempData.push({
                    status: obj.invoice.invoiceStatus,
                    invoice: renderInvoiceCell(obj.invoice.invoiceNo, obj.invoice.id!, history, canViewInvoiceDetails),
                    amount: obj.invoice.amount,
                    dateCreated: obj.invoice.createdDate,
                    dateSent: obj.invoice.sentDate,
                    datePaid: obj.invoice.paidDate,
                    datePaidMinusHoldback: obj.invoice.paidDateMinusHoldback,
                    study: obj.studyName,
                    payee: renderAccountCell(obj.receivingAccountName, obj.invoice.receivingAccountId, history, canViewAccountDetails),
                    payor: renderAccountCell(obj.payingAccountName, obj.invoice.payingAccountId, history, canViewAccountDetails),
                    payingAccountId: obj.invoice.payingAccountId,
                    receivingAccountId: obj.invoice.receivingAccountId,
                    studyId: obj.invoice.studyId,
                    invoiceId: obj.invoice.id,
                    invoiceNo: obj.invoice.invoiceNo,
                    autopayable: obj.invoice.autopayable
                });
            });
        } else if (type == 'line-items') {
            data.forEach((obj: LineItemDetailWithInvoice) => {
                tempData.push({
                    id: obj.lineItemId,
                    status: obj.status,
                    invoice: renderInvoiceCell(obj.invoiceNo, obj.invoiceId, history, canViewInvoiceDetails, obj.invoiceCreated),
                    amount: obj.actualUnitCost,
                    datePaid: obj.paidDate,
                    datePaidMinusHoldback: obj.paidDateMhb,
                    description: renderDescriptionCell(obj, setClickedObj, setViewSourceOpen, viewSourceOpen),
                    payee: renderAccountCell(obj.receivingAccountName, obj.receivingAccountId, history, canViewAccountDetails),
                    payor: renderAccountCell(obj.payingAccountName, obj.payingAccountId, history, canViewAccountDetails),
                    invoiceable: obj.invoiceable,
                    invoiceType: obj.invoiceType,
                    sourceType: obj.sourceType,
                    sourceId: obj.sourceId
                });
            });
        }
        return tempData;
    };

    React.useEffect(() => {
        if (sectionPath == 'invoices') {
            if (subSection == 'receivable') {
                getStudyReceivableInvoices(Number(id)).then(res => {
                    setTableData(convertData(res.data.receivableInvoices, sectionPath));
                }).catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        prefix: 'Could not retrieve Study\'s Receivable Invoices: '
                    });
                });
            } else if (subSection == 'payable') {
                getStudyPayableInvoices(Number(id)).then(res => {
                    setTableData(convertData(res.data.payableInvoices, sectionPath));
                }).catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        prefix: 'Could not retrieve Study\'s Payable Invoices: '
                    });
                });
            }
        } else if (sectionPath == 'line-items') {
            if (subSection == 'receivable') {
                getStudyReceivableLineItems(Number(id)).then(res => {
                    setTableData(convertData(res.data.receivableLineItems, sectionPath));
                }).catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        prefix: 'Could not retrieve Study\'s Receivable Line Items: '
                    });
                });
            } else if (subSection == 'payable') {
                getStudyPayableLineItems(Number(id)).then(res => {
                    setTableData(convertData(res.data.payableLineItems, sectionPath));
                }).catch(err => {
                    handleErrorResponse(err, dispatcher, {
                        prefix: 'Could not retrieve Study\'s Payable Line Items: '
                    });
                });
            }
        }
    }, [id, subSection, sectionPath, editLineItemOpen, updateOnChange]);

    React.useEffect(() => {
        if (sectionPath == 'line-items') {
            const selectedStatus = filters.NOT_INVOICED ? ['NOT_INVOICED'] : [];
            const selectedType = filters.AUTOPAY ? ['Autopay'] : [];

            const filteredData: LineItemDetailWithInvoice[] = [];

            tableData.forEach(lineItem => {
                if ((!filters.NOT_INVOICED && !filters.AUTOPAY) || selectedStatus.includes(lineItem.status) || selectedType.includes(lineItem.invoiceType)) {
                    filteredData.push(lineItem);
                }
            });

            dispatchPage({
                type: 'objects',
                payload: {
                    list: filteredData
                }
            });
        }

    }, [tableData, filters, sectionPath, subSection]);

    const handleChangeFilters = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        if (value == 'NOT_INVOICED') {
            dispatchPage({ type: 'filters', payload: { ALL: false, NOT_INVOICED: true, AUTOPAY: false } });
        } else if (value == 'AUTOPAY') {
            dispatchPage({ type: 'filters', payload: { ALL: false, NOT_INVOICED: false, AUTOPAY: true } });
        } else {
            dispatchPage({ type: 'filters', payload: { ALL: true, NOT_INVOICED: false, AUTOPAY: false } });
        }
    };

    const handleCallToRouter = (_event: React.ChangeEvent<{}>, value: any) => {
        history.push(value);
    };

    const displayLastInvoiceCreatedDate = () => {
        if (lastCreatedDate) {
            const dateParts = lastCreatedDate.split('-');
            const year = dateParts[0];
            const month = dateParts[1];
            const day = dateParts[2];
            const displayDate = MONTHS[Number(month) - 1] + ' ' + day + ', ' + year;

            return (<>
                <Typography variant='body1'>
                    Study&apos;s last invoice was generated on:
                </Typography>
                <Typography variant='subtitle1'>
                    {displayDate}
                </Typography>
            </>
            );
        } else {
            return (
                <Typography variant='body1'>
                    Study has no generated invoice
                </Typography>
            )
        }
    }

    const handleGenerateInvoice = () => {
        generateInvoiceByStudy(Number(id))
            .then(() => {
                dispatcher(showSuccessSnackbar('Invoice generation has been initiated. Check later when it is completed.'));
                setConfirmGeneration(false);
                setInvoiceInitiated(true);
            }).catch(err => {
                handleErrorResponse(err, dispatcher, {
                    prefix: 'Could not start generating invoices: '
                });
            });
    }

    const generateInvoiceButton = () => {
        if (invoiceInitiated === true) {
            return (
                <Button
                    variant='outlined'
                    className='small-grey-button-disabled'
                    startIcon={<HourglassEmptyTwoTone />}
                    disabled
                >
                    Initiated Today
                </Button>
            )
        } else if (confirmGeneration === true) {
            return (
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AccessTime />}
                    onClick={handleGenerateInvoice}
                >
                    Confirm Generate
                </Button>
            )
        } else {
            return (
                <Button
                    variant='outlined'
                    className='small-grey-button'
                    startIcon={<ReceiptTwoTone />}
                    onClick={() => setConfirmGeneration(true)}
                >
                    Generate Now
                </Button>
            )
        }
    }

    const columnsDisplayed = () => {
        if (subSection == 'receivable') {
            if (sectionPath == 'invoices') {
                return ['status', 'invoice', 'amount', 'dateCreated', 'dateSent', 'datePaid', 'datePaidMinusHoldback', 'payor'];
            } else if (sectionPath == 'line-items') {
                return ['status', 'invoiceType', 'invoice', 'amount', 'dateSent', 'datePaid', 'datePaidMinusHoldback', 'description', 'payor'];
            }
        }
        if (subSection == 'payable') {
            if (sectionPath == 'invoices') {
                return ['status', 'invoice', 'amount', 'dateCreated', 'dateSent', 'datePaid', 'datePaidMinusHoldback', 'payee'];
            } else if (sectionPath == 'line-items') {
                return ['status', 'invoiceType', 'invoice', 'amount', 'dateSent', 'datePaid', 'datePaidMinusHoldback', 'description', 'payee'];
            }
        }
        return [];
    };

    if (studyStarted && sponsorAccountId && internalAccountId) {
        return (
            <>
                <div className="page-heading">
                    <Typography className="title">{studyName}</Typography>
                </div>
                <div className="page-body margin-auto reduce-top-sm">
                    <Grid container spacing={3} alignItems="flex-start" justifyContent='center'>
                        <Grid container item xs={12} sm={6} md={4}>
                            <div className="section-heading">
                                <div className='text'>Internal Account</div>
                                <Tooltip arrow title='The account associated to this institution that the sponsor will pay to.'><Info className='info' /></Tooltip>
                            </div>
                            <AccountSummary id={internalAccountId.toString()} />
                        </Grid>
                        <Grid container item xs={12} sm={6} md={4}>
                            <div className="section-heading">
                                <div className='text'>Sponsor Account</div>
                                <Tooltip arrow title="The sponsor's account for this study. The internal account will primarily bill to this sponsor account."><Info className='info' /></Tooltip>
                            </div>
                            <AccountSummary id={sponsorAccountId.toString()} />
                        </Grid>
                        <Grid container item xs={12} sm={12} md={4}>
                            <div className="section-heading">
                                <div className='text'>Invoicing Summary</div>
                                <Tooltip arrow title="A overview highlighting the statuses of this study's invoices at a broad level."><Info className='info' /></Tooltip>
                            </div>
                            <InvoicesSummaryCard studyId={Number(id)} updateOnChange={updateOnChange} />
                        </Grid>
                    </Grid>
                    <div className='page-tabs'>
                        {subSection == 'receivable' && showLogPayment &&
                            <div className='log-payment'>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<Add />}
                                    onClick={() => setPayOpen(true)}
                                >
                                    Log Received Payment
                                </Button>
                            </div>
                        }
                        <Tabs
                            value={`/study/${id}/invoicing/${subSection}/${sectionPath}`}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleCallToRouter}
                            className='sv-tab spacing'
                        >
                            <Tab label="Receivable" value={`/study/${id}/invoicing/receivable/${sectionPath}`} />
                            <Tab label="Payable" value={`/study/${id}/invoicing/payable/${sectionPath}`} />
                        </Tabs>
                    </div>
                    <div className='table-options-info top-padding'>
                        <div className='toggles row'>
                            <Typography variant='body1'>View By:</Typography>
                            <ToggleButtonGroup
                                value={sectionPath}
                                onChange={(_event: any, value: any) => history.push(`/study/${id}/invoicing/${subSection}/${value}`)}
                                className="mode-toggle-group"
                                exclusive
                            >
                                <ToggleButton value="invoices" disabled={sectionPath === 'invoices'}>
                                    Invoices
                                </ToggleButton>
                                <ToggleButton value="line-items" disabled={sectionPath === 'line-items'}>
                                    Line Items
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <div className='generate-date row'>
                            {canGetLastInvoiceGeneration && displayLastInvoiceCreatedDate()}
                            {canGenerateInvoices && generateInvoiceButton()}
                        </div>
                    </div>
                    {sectionPath == 'line-items' &&
                        <div className='line-item-filters row'>
                            <Typography className='filter-label' variant='body1'>Filter:</Typography>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    row
                                    aria-label="filter"
                                    defaultValue="ALL"
                                    onChange={handleChangeFilters}
                                >
                                    <FormControlLabel value="ALL" control={<Radio />} label="All" />
                                    <FormControlLabel value="NOT_INVOICED" control={<Radio />} label="Not Invoiced" />
                                    <FormControlLabel value="AUTOPAY" control={<Radio />} label="Autopay" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    }
                    <div role='tabpanel' id='simple-tabpanel-scheduled' aria-labelledby='simple-tab-scheduled'>
                        <InvoiceablesTable
                            type={sectionPath}
                            data={sectionPath == 'invoices' ? tableData : objects}
                            showColumns={columnsDisplayed()}
                            editLineItemOpen={editLineItemOpen}
                            setEditLineItemOpen={setEditLineItemOpen}
                            updateOnChange={updateOnChange}
                            setUpdateOnChange={setUpdateOnChange}
                            viewMode={subSection}
                        />
                    </div>
                </div>
                {showLogPayment && <LogPayment
                    open={payOpen}
                    setOpen={setPayOpen}
                    payingAccountId={sponsorAccountId}
                    receivingAccountId={internalAccountId}
                    studyId={Number(id)}
                    receivedPayment
                    updateOnChange={updateOnChange}
                    setUpdateOnChange={setUpdateOnChange}
                />}
                {clickedObj && <ViewSourceModal
                    open={viewSourceOpen}
                    setOpen={setViewSourceOpen}
                    lineItem={clickedObj}
                />}
            </>
        );
    }
    return (
        <>
            <div className="page-heading">
                <Typography className="title">{studyName}</Typography>
            </div>
            <div className="page-body">
                <StudyAccountSettings
                    studyName={studyName}
                    accountId={Number(internalAccountId)}
                    sponsorId={Number(sponsorAccountId)}
                    studyId={Number(id)}
                    contractSigned
                />
            </div>
        </>
    );
};

export default StudyInvoicing;
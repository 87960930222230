import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import useLoggedInUser from '../hooks/useLoggedInUser';

interface Props extends RouteProps {
    component: React.ComponentType<any>
    title?: string;
}

const PublicRoute: React.FC<Props> = props => {
    const { component, title, ...rest } = props;

    const user = useLoggedInUser();

    const Component = component;

    React.useEffect(() => {
        if (title) {
            document.title = title + ' | CRAFT';
        } else {
            document.title = 'CRAFT | Clinical Research Activity Financial Tracker';
        }
    }, [title]);

    const renderFn = (routerProps: any) => {
        return !user.id ?
            <Component {...routerProps} /> :
            <Redirect
                to={{
                    pathname: '/dashboard',
                    state: { from: props.location }
                }}
            />
    }

    return <Route {...rest} render={renderFn} />;
}

export default PublicRoute;
import * as React from 'react';

import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';

interface Props {
    query: any,
    setQuery: any,
}

const SearchBar: React.FC<Props> = props => {
    const { query, setQuery } = props;

    const handleSearchQuery = () => (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    }

    return (
        <div className="search-bar">
            <TextField
                value={query}
                margin="dense"
                variant="outlined"
                onChange={handleSearchQuery()}
                className="search-field"
                InputProps={{
                    endAdornment:
                        <InputAdornment position="end">
                            <IconButton
                                style={{ backgroundColor: 'transparent' }}
                                onClick={() => setQuery('')}
                            >
                                <Close />
                            </IconButton>
                        </InputAdornment>,
                    startAdornment: <InputAdornment position="start"> <Search /> </InputAdornment>,
                    style: { paddingRight: 0 }
                }}
            />
        </div>
    )
}

export default SearchBar;
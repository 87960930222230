import * as React from 'react';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { Button, Card, CardActions, CardContent, CardHeader, Grid, IconButton, Modal, Typography } from '@material-ui/core';
import { Close, Save } from '@material-ui/icons';
import { BForm, BSelect, BSubmit, BTextField } from 'mui-bueno';

import { Division, SelectOption, Study } from '../../../@types';
import { showSuccessSnackbar } from '../../../modules/messageSnackbarReducer';
import { handleErrorResponse } from '../../../service/utils';
import { createContract } from '../../../service/Contract/contracts';
import { getRestrictedDivisions } from '../../../service/Management/divisions';

interface Props {
    open: any;
    setOpen: any;
}

const schema = yup.object<Study>().shape({
    name: yup.string().required('Name is required'),
    identifier: yup.string().required('Identifier is required'),
    irbNo: yup.string(),
    studyId: yup.string(),
    divisionId: yup.string().required('Division is required'),
    sponsorIdentifier: yup.string(),
});

const ContractAdd: React.FC<Props> = props => {
    const { open, setOpen } = props;

    const history = useHistory();
    const dispatch = useDispatch();

    const [divisions, setDivisions] = React.useState<SelectOption<number>[]>([]);

    React.useEffect(() => {
        if (open) {
            getRestrictedDivisions()
                .then((res: { data: Division[] }) => {
                    // For each division, store the division as a SelectOption
                    const tempDivisions: SelectOption<number>[] = [];
                    res.data.forEach((division: Division) => {
                        if (division.id) {
                            tempDivisions.push({
                                value: division.id,
                                label: division.name
                            })
                        }
                    });
                    setDivisions(tempDivisions);
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not retrieve list of Divisions: '
                    });
                });
        }
    }, [open]);

    const handleSubmit = async (data: Study, { setErrors }: FormikHelpers<Study>) => {
        createContract(data).then(res => {
            dispatch(showSuccessSnackbar(`Contract '${data.name}' created`));
            history.push(`/contract/${res.data.id}/overview`);
        }).catch(err => {
            handleErrorResponse(err, dispatch, {
                setStatus: setErrors,
                prefix: 'Could not create Contract: '
            });
        });
    }

    const handleModalClose = () => {
        setOpen(false);
    }

    const initialValues: Study = {
        id: null,
        name: '',
        identifier: '',
        irbNo: '',
        studyId: '',
        divisionId: 0,
        sponsorIdentifier: ''
    }

    return (
        <Modal
            open={open}
            onClose={handleModalClose}
        >
            <div className='modal-form'>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={schema}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    <BForm>
                        <Card className='detail-form sm'>
                            <CardHeader
                                title='Add Contract'
                                action={
                                    <IconButton color='primary' aria-label='Close' onClick={handleModalClose}>
                                        <Close />
                                    </IconButton>
                                }
                            />
                            {divisions.length > 0 ?
                                <>
                                    <CardContent>
                                        <Grid container spacing={1} justifyContent='center'>
                                            <BTextField
                                                name='name'
                                                label='Name'
                                                placeholder='Name'
                                                required autoFocus
                                                xs={12} sm={10} noMP
                                            />
                                            <BTextField
                                                name='identifier'
                                                label='Identifier'
                                                placeholder='Identifier'
                                                required
                                                xs={12} sm={10} noMP
                                            />
                                            <BTextField
                                                name='irbNo'
                                                label='IRB Number'
                                                placeholder='IRB Number'
                                                xs={12} sm={10} noMP
                                            />
                                            <BTextField
                                                name='studyId'
                                                label='Study Number'
                                                placeholder='Study Number'
                                                xs={12} sm={10} noMP
                                            />
                                            <BTextField
                                                name='sponsorIdentifier'
                                                label='Sponsor&apos;s Study Identifier'
                                                placeholder='Sponsor&apos;s Study Identifier'
                                                xs={12} sm={10} noMP
                                            />
                                            <BSelect
                                                name='divisionId'
                                                label='Division'
                                                placeholder='Division'
                                                options={divisions}
                                                variant='outlined'
                                                required
                                                xs={12} sm={10} noMP
                                            />
                                            <Grid item xs={12} sm={10} className='flex-center' style={{ paddingTop: 0 }}>
                                                <Typography
                                                    className='link'
                                                    style={{ padding: '0px 10px' }}
                                                    onClick={() => history.push(`/manage/division`)}
                                                >
                                                    Manage Divisions
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions className='flex-end'>
                                        <BSubmit
                                            startIcon={<Save />}
                                            variant='contained'
                                            color='primary'
                                        >
                                            Create
                                        </BSubmit>
                                    </CardActions>
                                </> :
                                <CardContent className='flex-center column'>
                                    <Typography variant='body1' align='center' style={{ paddingBottom: 20 }}>
                                        Institution has no divisions yet.<br />Divisions are required to create contracts/studies.
                                    </Typography>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={() => history.push(`/manage/division/new`)}
                                    >
                                        Create a Division
                                    </Button>
                                </CardContent>
                            }
                        </Card>
                    </BForm>
                </Formik>
            </div>
        </Modal>
    )
};

export default ContractAdd;

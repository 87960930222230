import * as React from 'react';
import { DisplayTextFormat } from '../../../common/Utils/DisplayTextFormat';

export const studyNameFormatter = (value: any) => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 961) {
        return DisplayTextFormat(value, 18, false);
    }
    return DisplayTextFormat(value, undefined, false);
};

export const halfStudyNameFormatter = (value: any) => {
    return DisplayTextFormat(value, 18, false);
};

export const negativeAmountFormatter = (value: any) => {
    const formattedValue = new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(value);
    if (value != 0) {
        return '-' + formattedValue;
    } else {
        return formattedValue;
    }
};

export const amountFormatter = (value: any) => {
    return new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(value);
};

export const handleStudyNameTooltip = (studyNameProps: any) => {
    if (studyNameProps.payload && studyNameProps.payload[0] != null) {
        return (
            <div className='tooltip-div'>
                <div className='study'>
                    {studyNameProps.payload[0].payload.studyName}
                </div>
                <div className='division'>
                    {studyNameProps.payload[0].payload.divisionName}
                </div>
            </div>
        )
    } else {
        return null;
    }
};
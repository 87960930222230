import * as React from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { createStyles, ListItem, makeStyles, Theme, Tooltip } from '@material-ui/core';
import { ArrowDropDown, ArrowRight, InfoRounded } from '@material-ui/icons';
import { TreeItem, TreeView } from '@material-ui/lab';

import { Division, DivisionTree } from '../../../@types';
import { DisplayTextFormat } from '../../../common/Utils/DisplayTextFormat';
import { handleErrorResponse } from '../../../service/utils';
import { getDivisionTree } from '../../../service/Management/divisions';

interface Props {
    setChosenDivision: any;
    withinCollapsiblePanel: boolean;
    restricted: boolean;
    withinMenu?: boolean;
    divisionStatisticsPage?: boolean;
    headerText?: string;
    tooltipText?: string;
    reload?: boolean;
    updated?: boolean;
    setUpdated?: any;
    setDivisions?: any;
    defaultExpanded?: string[];
    setDefaultExpanded?: any;
}

// This is needed to be able to set a different style for a child division compared to its parent
const useStyles = makeStyles((theme: Theme) => createStyles({
    readonly: {
        color: theme.palette.grey.A100,
        borderBottom: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: '#dddddd',
        paddingTop: '2px'
    },
    updatable: {
        color: theme.palette.grey.A700,
        borderBottom: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: '#dddddd',
        paddingTop: '2px'
    }
}));

const FilterByDivision: React.FC<Props> = props => {
    const {
        withinCollapsiblePanel, restricted, withinMenu, setChosenDivision,
        divisionStatisticsPage, headerText, tooltipText, reload, updated, setUpdated,
        setDivisions, defaultExpanded, setDefaultExpanded
    } = props;

    const dispatch = useDispatch();
    const classes = useStyles();

    // List of divisions in the navigation tree
    const [divisionTree, setDivisionTree] = React.useState<DivisionTree[]>([]);

    const loadDivisionTree = () => {
        getDivisionTree()
            .then(res => {
                const fullDivisionTree: DivisionTree[] = divisionStatisticsPage
                    ? _.orderBy(res.data, ['name'])
                    : [{ id: 0, name: 'All Divisions', updatable: true, children: _.orderBy(res.data, ['name']) }];
                setDivisionTree(fullDivisionTree);

                // flatten the tree to re-use as the parent division options; used in DivisionList
                const flattenTemp = flatten(res.data);
                const tempDivisions: Division[] = [];
                flattenTemp.forEach(item => {
                    tempDivisions.push({
                        id: item.id,
                        name: item.name,
                        parentDivisionId: 0
                    })
                })
                setDivisions(tempDivisions);

                //find the root to set default expanded
                const rootIdsWithChildren = _.map(
                    _.filter(divisionTree, (o) => { return o.children && o.children.length > 0 }),
                    'id');
                setDefaultExpanded(rootIdsWithChildren);
            }).catch(err => {
                handleErrorResponse(err, dispatch, {
                    prefix: 'Could not retrieve Division tree: '
                });
            });
    }

    // Initial Load one time
    React.useEffect(() => {
        loadDivisionTree();
    }, []);

    // Reload needed for the DivisionList under Management
    if (reload) {
        React.useEffect(() => {
            loadDivisionTree();
            setUpdated(false);
        }, [updated]);
    }

    const flatten = (collection: any[], flattened?: any[]) => {
        if (!flattened) {
            flattened = [];
        }
        _.each(collection, (obj) => {
            flattened!.push(obj);
            flatten(obj.children, flattened);
        });
        return flattened;
    };

    const renderTree = (nodes: DivisionTree) => {
        const canUpdateNode = (!restricted || nodes.updatable);
        return (
            <TreeItem
                classes={{ content: canUpdateNode ? classes.updatable : classes.readonly }}
                key={'nodes-' + nodes.id} nodeId={nodes.id + ''}
                label={DisplayTextFormat(nodes.name)}
                onLabelClick={(event) => {
                    { canUpdateNode && setChosenDivision(nodes) }
                    event.preventDefault()
                }}
            >
                {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
            </TreeItem>
        )
    };

    const renderDivisionTree = () => {
        return divisionTree.map(item => { return renderTree(item) });
    }

    const expandIcon = (
        <div className='tree-item-icon'>
            <ArrowRight
                preserveAspectRatio="none"
                viewBox='10 7 5 10'
                className='expand arrow'
            />
        </div>
    )
    const collapseIcon = (
        <div className='tree-item-icon'>
            <ArrowDropDown
                preserveAspectRatio="none"
                viewBox='7 10 10 5'
                className='collapse arrow'
            />
        </div>
    )

    if (withinMenu) {
        return (
            <TreeView
                className='division-tree'
                defaultExpanded={defaultExpanded ? defaultExpanded : [divisionStatisticsPage ? '1' : '0']}
                defaultExpandIcon={expandIcon}
                defaultCollapseIcon={collapseIcon}
            >
                {renderDivisionTree()}
            </TreeView>
        )
    }
    return (
        <div className="browsing-options">
            <ListItem className={withinCollapsiblePanel ? 'section-title' : 'division-section-title'}>
                {headerText ?? 'Filter By Division'}
                <Tooltip title={tooltipText ?? 'Invoices will be filtered by their assigned Study\'s parent Division'}>
                    <InfoRounded />
                </Tooltip>
            </ListItem>
            <div className="spacer" />
            <ListItem>
                <TreeView
                    className='division-tree'
                    defaultExpanded={defaultExpanded ? defaultExpanded : [divisionStatisticsPage ? '1' : '0']}
                    defaultExpandIcon={expandIcon}
                    defaultCollapseIcon={collapseIcon}
                    defaultSelected={'0'}
                >
                    {renderDivisionTree()}
                </TreeView>
            </ListItem>
            <div className="spacer" />
        </div>
    )
}

export default FilterByDivision;
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';

import IdleTimout from './common/IdleTimeout/IdleTimeout';
import TokenRefresher from './common/SessionTimeout/TokenRefresher';

import Header from './common/Header/Header';
import MessageSnackbar from './common/MessageSnackbar/MessageSnackbar';
import ProtectedRoute from './common/Routes/ProtectedRoute';
import PublicRoute from './common/Routes/PublicRoute';
import NotFound from './common/Routes/NotFound';
import { envMessage } from './common/EnvHeader/EnvHeader';

import Homepage from './features/Homepage/Homepage';
import Contact from './features/Homepage/Contact/Contact';
import Login from './features/User/Login/Login';
import SignUp from './features/User/SignUp/SignUp';
import ChangePassword from './features/User/PasswordReset/ChangePassword';
import ResetTokenPassword from './features/User/PasswordReset/ResetTokenPassword';
import ForgotPassword from './features/User/PasswordReset/ForgotPassword';

import Dashboard from './features/Dashboard/Dashboard';
import Management from './features/Management/Management';
import ContractList from './features/Contract/List/ContractList';
import ContractDetails from './features/Contract/Details/ContractDetails';
import StudyList from './features/Study/List/StudyList';
import StudyDetails from './features/Study/Details/StudyDetails';
import Divisions from './features/Divisions/Divisions';
import Invoicing from './features/Invoicing/Invoicing';

import ForceResetPassword from './features/User/PasswordReset/ForceResetPassword';
import SetupMfa from './features/User/Login/SetupMfa';

import SimulatorList from './features/Simulator/List/SimulatorList';
import SimulatorDetail from './features/Simulator/Details/SimulatorDetail';

const App: React.FC = () => {
    return (
        <div className={envMessage ? 'env' : ''}>
            <CssBaseline />
            <IdleTimout />
            <TokenRefresher />

            <Switch>
                <PublicRoute exact path="/" component={Homepage} />
                <PublicRoute exact path="/contact" component={Contact} />

                <>
                    <Header />
                    <Switch>
                        <PublicRoute exact path="/login" component={Login} title='Login' />
                        <PublicRoute exact path="/signup" component={SignUp} title='Sign Up' />

                        <PublicRoute exact path="/login/reset" component={ForceResetPassword} title='Reset Password' />
                        <PublicRoute exact path="/login/setup-mfa" component={SetupMfa} title='Setup Authentication' />

                        <PublicRoute exact path="/user/password/forgot" component={ForgotPassword} title='Forgot Password' />
                        <PublicRoute exact path="/user/password/reset/:token" component={ResetTokenPassword} title='Forgot Password' />
                        <ProtectedRoute exact path="/user/password/change" component={ChangePassword} title='Change Password' />

                        <ProtectedRoute exact path="/dashboard" component={Dashboard} title='Dashboard' />

                        <ProtectedRoute exact path="/simulator" component={SimulatorList} title='Simulator List' />
                        <ProtectedRoute exact path="/simulator/:id" component={SimulatorDetail} title='Simulator' />

                        {/* TODO: Add name of Contract/Study to title */}
                        <ProtectedRoute exact path="/contract" component={ContractList} title='Contracts' />
                        <ProtectedRoute exact path="/contract/:id/:section/:subSection?" component={ContractDetails} title='Contract' />
                        <Redirect from="/contract/:id" to="/contract/:id/overview" />
                        <Redirect from="/contract/:id/schedule" to="/contract/:id/schedule/events" />

                        <ProtectedRoute exact path="/study" component={StudyList} title='Studies' />
                        <ProtectedRoute exact path="/study/:id/:section/:subSection?/:subId?" component={StudyDetails} title='Study' />
                        <Redirect from="/study/:id" to="/study/:id/overview" />
                        <Redirect from="/study/:id/activity" to="/study/:id/activity/scheduled" />

                        <ProtectedRoute exact path="/divisions/:id?" component={Divisions} title='Division Statistics' />

                        <ProtectedRoute exact path="/invoicing/:id?" component={Invoicing} title='Invoicing' />

                        {/* TODO: Add management type to title */}
                        <ProtectedRoute exact path="/manage/:type/:id?/:option?/:optionId?" component={Management} title='Management' />
                        <Redirect from="/manage" to="/manage/user" />
                        <Redirect from="/manage/user/:id" to="/manage/user/:id/details" />

                        <Route component={NotFound} />
                    </Switch>
                </>
            </Switch>
            <MessageSnackbar />
        </div>
    );
};

export default App;
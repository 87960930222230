import axiosInstance from '../Access/axiosInstance';

import {
    Role,
    RoleSummary
} from '../../@types';
import { handleError } from '../utils';

/**
 * Gets a page of roles
 */
export const getRoleSummaries = () =>
    axiosInstance.get<RoleSummary[]>(
        '/api/roles'
    ).catch(handleError);

/**
 * Gets a role by id
 */
export const getRole = (id: number) =>
    axiosInstance.get<Role>(
        `/api/roles/${id}`
    ).catch(handleError);

/**
 * Updates a role
 */
export const updateRole = (role: Role) =>
    axiosInstance.put<Role>(
        '/api/roles', role
    ).catch(handleError);

/**
 * Creates a role
 */
export const createRole = (role: Role) =>
    axiosInstance.post<Role>(
        '/api/roles', role
    ).catch(handleError);

/**
 * Deletes a role 
 */
export const deleteRole = (id: number) => 
    axiosInstance.delete(
        `/api/roles/${id}`
    ).catch(handleError);

import * as React from 'react';
import { TableCell, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';

import { ShortProcedure, ShortScheduledVisit } from '../../../../../@types';
import { DisplayTextFormat } from '../../../../../common/Utils/DisplayTextFormat';


interface SVTableHeaderProps {
    scheduledVisits: ShortScheduledVisit[];
    procedures: ShortProcedure[][];
}

const ScheduledVisitsTableHeader: React.FC<SVTableHeaderProps> = props => {
    const { scheduledVisits, procedures } = props;

    return (
        <TableHead className='sv-table-header' key='sv-table'>
            <TableRow>
                <TableCell key='sv-header-top-left' align='center' className='sv-table-header-starter' />
                {scheduledVisits.map((sv, idx) => {
                    return (
                        <TableCell key={'header-' + idx + '-sv-' + sv.id} className='sv-primary-cell'>
                            <Tooltip
                                placement="bottom"
                                enterDelay={300}
                                title={
                                    procedures[idx] && procedures[idx].length > 0 ? (
                                        <div>
                                            <Typography variant="subtitle1">Procedures</Typography>
                                            <ol>
                                                {procedures[idx].map(procedure => {
                                                    return (
                                                        <li key={'sv-' + sv.id + '-procedure-' + procedure.id}>
                                                            {DisplayTextFormat(procedure.name, 24, false)}
                                                        </li>
                                                    );
                                                })}
                                            </ol>
                                        </div>
                                    ) : ('There are no procedures associated with this study visit.')
                                }
                            >
                                <div className='sv-header-cell-label center'>
                                    {DisplayTextFormat(sv.visitName, 24, false)}
                                </div>
                            </Tooltip>
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    );
}
export default ScheduledVisitsTableHeader;
import * as React from 'react';

// istanbul ignore next
export const envMessage: string = process.env.REACT_APP_ENV_MESSAGE
    ? process.env.REACT_APP_ENV_MESSAGE.toUpperCase()
    : '';

const EnvHeader: React.FC = () => {
    return (
        <div className='env-header'>
            <div className="header-text">
                {envMessage}
            </div>
        </div>
    );
};

export default EnvHeader;

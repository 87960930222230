import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, Grid, Typography } from '@material-ui/core';
import { Account, AccountContact } from '../../../../@types';
import { handleErrorResponse } from '../../../../service/utils';
import AccountingModal from '../../../../common/Invoicing/AccountingModal';
import { getAccountWithTotalCredit } from '../../../../service/Management/accounts';
import usePermissions from '../../../../common/hooks/usePermissions';

interface Props {
    studyName: string;
    accountId: number | null;
    sponsorId: number | null;
    studyId: number | null;
    contractSigned: boolean;
}

const StudyAccountSettings: React.FC<Props> = props => { //NOSONAR
    const { studyName, accountId, sponsorId, studyId, contractSigned } = props;

    const dispatch = useDispatch();

    const [openInternalModal, setOpenInternalModal] = React.useState<boolean>(false);
    const [openExternalModal, setOpenExternalModal] = React.useState<boolean>(false);

    const [internalAccount, setInternalAccount] = React.useState<Account | null>(null);
    const [internalContacts, setInternalContacts] = React.useState<AccountContact[]>([]);
    const [externalAccount, setExternalAccount] = React.useState<Account | null>(null);
    const [externalContacts, setExternalContacts] = React.useState<AccountContact[]>([]);
    const canAddAccounts = usePermissions('API_ACCOUNT_CREATE');
    const canUpdateAccounts = usePermissions('API_ACCOUNT_UPDATE') && usePermissions('API_ACCOUNT_CONTACT_SET_CONTACTS_ON_ACCOUNT');

    React.useEffect(() => {
        if (accountId) {
            getAccountWithTotalCredit(accountId)
                .then(res => {
                    setInternalAccount(res.data.accountWithContacts.account);
                    const contacts: AccountContact[] = res.data.accountWithContacts.contacts;
                    contacts.sort((ac1, ac2) => (ac1.id! - ac2.id!));
                    setInternalContacts(contacts);
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not retrieve internal account or associated contacts: '
                    });
                });
        }
        if (sponsorId) {
            getAccountWithTotalCredit(sponsorId)
                .then(res => {
                    setExternalAccount(res.data.accountWithContacts.account);
                    const contacts: AccountContact[] = res.data.accountWithContacts.contacts;
                    contacts.sort((ac1, ac2) => (ac1.id! - ac2.id!));
                    setExternalContacts(contacts);
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not retrieve sponsor account or associated contacts: '
                    });
                });
        }
    }, [accountId, sponsorId]);

    let internalCard, externalCard;

    const handleAccountActions = (internal: boolean) => {
        internal ? setOpenInternalModal(true) : setOpenExternalModal(true);
    }

    const displayActionRequiredCard = (internal: boolean) => {
        const handleCreateAccount = () => {
            if (!canAddAccounts)
                return undefined;
            else
                return handleAccountActions(internal)
        }
        return (
            <Grid item xs={12} sm={12} md={6} lg={5} key={internal ? 'internal-action-required' : 'external-action-required'}>
                <Typography variant='subtitle2' className={'card-label'}>{internal ? 'Internal Account' : 'Sponsor Account'}</Typography>
                <Card
                    className='action-required-card'
                >
                    <div>
                        <Grid container spacing={2} justifyContent='center' alignItems='center' direction='column'>
                            <Grid item>
                                <Typography variant='body2' className='action-required-label'>Action Required</Typography>
                            </Grid>
                            <Grid item>
                                <Button color='primary' variant='contained' className={canAddAccounts ?'action-required-button' : undefined} onClick={handleCreateAccount} disabled={!canAddAccounts}>Create Account</Button>
                            </Grid>
                        </Grid>
                    </div>
                </Card>
            </Grid>
        );
    }

    const noneLabel = (
        <Typography variant='body2' className={'account-responses none-label'}>None</Typography>
    );

    const displayAddress = (account: Account) => {
        if (!account.addressLine1) {
            return noneLabel;
        }
        const rest = account.city + ', ' + account.state + ' ' + account.zipCode;
        return (
            <Grid item>
                <Typography variant='body2' className='account-responses'>{account.addressLine1}</Typography>
                {account.addressLine2 && <Typography variant='body2' className='account-responses'>{account.addressLine2}</Typography>}
                <Typography variant='body2' className='account-responses'>{rest}</Typography>
            </Grid>
        );
    }

    const displayPrimary = (contact: AccountContact[]) => {
        const index = contact.map(c => c.primaryContact).findIndex(elem => elem);
        if (index === -1) {
            return noneLabel;
        }
        const primaryContact: AccountContact = contact[index];
        return (
            <Grid item>
                <Typography variant='body2' className='account-responses'>{primaryContact.name}</Typography>
                <Typography variant='body2' className='account-responses'>{primaryContact.email}</Typography>
                <Typography variant='body2' className='account-responses'>{primaryContact.phoneNo}</Typography>
            </Grid>
        );
    }
    const displayOtherContacts = (contacts: AccountContact[], internal: boolean) => {
        const list = contacts.filter(contact => !contact.primaryContact).sort((a, b) => a.id! - b.id!);
        if (list.length === 0) {
            return (
                <Grid item>
                    {noneLabel}
                </Grid>
            );
        }
        return (
            <Grid container item direction='column' spacing={2}>
                {list.map((c: AccountContact, idx: number) => {
                    return (
                        <Grid item key={'other-contact-' + internal + '-' + idx}>
                            <Typography variant='body2' className='account-responses'>{c.name}</Typography>
                            <Typography variant='body2' className='account-responses'>{c.email}</Typography>
                            <Typography variant='body2' className='account-responses'>{c.phoneNo}</Typography>
                        </Grid>
                    );
                })}
            </Grid>
        )
    }

    const displayPaymentPreference = (paymentPreference: string) => {
        if (!paymentPreference)
            return 'None';
        else if (paymentPreference === 'ACH')
            return paymentPreference;
        else if (paymentPreference === 'CHECK')
            return 'Check';
        else if (paymentPreference === 'INTRA_TRANSFER')
            return 'Intracompany Transfer';
        return 'None';
    }

    const displayLabel = (label: string, required: boolean) => {
        if (required) {
            return (
                <Grid className={'account-card-label'}>
                    <Typography>{label}</Typography>
                    <Typography className={'required-asterisk'}>*</Typography>
                </Grid>
            )
        } else {
            return (
                <Typography variant='body1'>{label}</Typography>
            );
        }
    }

    const displayPrimaryLabel = (contacts: AccountContact[]) => {
        const index = contacts.map(c => c.primaryContact).findIndex(elem => elem);
        if (index === -1) {
            return displayLabel('Primary Contact', contractSigned);
        } else {
            return (<Typography variant='body1'>Primary Contact</Typography>);
        }
    }

    const displayAccountCard = (internal: boolean) => { //NOSONAR
        const account: Account = internal ? internalAccount! : externalAccount!;
        const contacts: AccountContact[] = internal ? internalContacts : externalContacts;
        return (
            <Grid item xs={12} sm={12} md={6} lg={5} key={internal ? 'internal-account-card' : 'external-account-card'}>
                <Grid container item justifyContent='space-between'>
                    <Grid item>
                        <Typography variant='subtitle2' className={'card-label'}>{internal ? 'Internal Account' : 'Sponsor Account'}</Typography>
                    </Grid>
                    { canUpdateAccounts && 
                        <Grid item>
                            <Button variant='text' color='secondary' onClick={() => handleAccountActions(internal)} className={'edit-account-button'}>Edit</Button>
                        </Grid>
                    }
                </Grid>
                <Card
                    className='contract-accounting-card'
                >
                    <div className='account-information'>
                        <Grid container spacing={0} direction='column'>
                            <Grid container item className='account-section' spacing={2} direction='column'>
                                <Grid item>
                                    <Typography variant='body1'>Account Name</Typography>
                                    <Typography variant='body2' className={account.enteredName ? 'account-responses' : 'account-responses none-label'}>{account.enteredName ? account.enteredName : 'None'}</Typography>
                                </Grid>
                                { internal &&
                                    <>
                                        <Grid item>
                                            <Typography variant='body1'>Payment Preference</Typography>
                                            <Typography variant='body2' className={account.preferredPayType ? 'account-responses' : 'account-responses none-label'}>{account.preferredPayType ? displayPaymentPreference(account.preferredPayType) : 'None'}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='body1'>ACH Account Number</Typography>
                                            <Typography variant='body2' className={account.achAccountingNo ? 'account-responses' : 'account-responses none-label'}>{account.achAccountingNo ? account.achAccountingNo : 'None'}</Typography>
                                        </Grid>
                                    </>
                                }
                                <Grid item>
                                    <Typography variant='body1'>Address</Typography>
                                    {displayAddress(account)}
                                </Grid>
                                { internal && 
                                    <>
                                        <Grid item>
                                            <Typography variant='body1'>Check Mailing Address</Typography>
                                            <Typography variant='body2' className={account.checkAddress ? 'address-text' : 'address-text none-label'}>{account.checkAddress ? account.checkAddress : 'None'}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='body1'>EFT Address</Typography>
                                            <Typography variant='body2' className={account.eftAddress ? 'address-text' : 'address-text none-label'}>{account.eftAddress ? account.eftAddress : 'None'}</Typography>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                            <div className='divider' />
                            <Grid container item className='account-section' spacing={2} direction='column'>
                                <Grid item>
                                    { displayPrimaryLabel(contacts) }
                                    { displayPrimary(contacts) }
                                </Grid>
                                <Grid container item direction='column'>
                                    <Grid item>
                                        <Typography variant='body1'>Other Contacts</Typography>
                                    </Grid>
                                    { displayOtherContacts(contacts, internal) }
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Card>
            </Grid>
        );
    }

    if (!internalAccount) {
        internalCard = displayActionRequiredCard(true);
    } else {
        internalCard = displayAccountCard(true);
    }
    if (!externalAccount) {
        externalCard = displayActionRequiredCard(false);
    } else {
        externalCard = displayAccountCard(false);
    }

    return (
        <>
            <div className="page-title">
                Study Invoicing Accounts
            </div>
            <div className='aligned-row'>
                <Grid container spacing={3} justifyContent='center' alignItems='flex-start'>
                    {internalCard}
                    {externalCard}
                </Grid>
            </div>
            <AccountingModal
                open={openInternalModal || openExternalModal}
                setOpen={openInternalModal ? setOpenInternalModal : setOpenExternalModal}
                internal={openInternalModal}
                initialAccount={openInternalModal ? internalAccount : externalAccount}
                initialContacts={openInternalModal ? internalContacts : externalContacts}
                studyName={studyName}
                studyId={studyId}
                setNewAccount={openInternalModal ? setInternalAccount : setExternalAccount}
                setNewContacts={openInternalModal ? setInternalContacts : setExternalContacts}
                management={false}
                contractSigned={contractSigned}
            />
        </>
    );
}

export default StudyAccountSettings;
import * as React from 'react';
import { TableCell, TableRow, Tooltip } from '@material-ui/core';

import { ShortScheduledVisit } from '../../../../../@types';
import { NumberFormatter } from '../../../../../common/Utils/NumberFormatter';


interface SVCostSectionProps {
    scheduledVisits: ShortScheduledVisit[];
    payments: number[];
    holdbacks: number[];
    totalPerVisits: number[];
}

const ScheduledVisitsTableCost: React.FC<SVCostSectionProps> = props => {
    const { scheduledVisits, payments, holdbacks, totalPerVisits } = props;
    return (
        <>
            <TableRow>
                <TableCell key={'visit-cost'} className={'sv-body-table-label-stick left'}>
                    Visit Cost
                </TableCell>
                <TableCell key='sv-body-label-cost' align={'left'} colSpan={scheduledVisits.length} className="sv-body-table-label" />
            </TableRow>
            <TableRow key={'visit-payments-row'}>
                <Tooltip title='This value is the final cost minus the holdback amount'>
                    <TableCell key={'payment-section'} className={'sv-table-body-section-starter right'}>
                        Payment
                    </TableCell>
                </Tooltip>
                {payments.map((payment: number, svidx: number) => {
                    return (
                        <TableCell key={'scheduled-visit-payment-' + (svidx + 1)} className={'body-cell right'}>
                            <NumberFormatter currency value={payment} />
                        </TableCell>
                    )
                })}
            </TableRow>
            <TableRow key={'visit-holdback-row'}>
                <Tooltip title='This value is based on the holdback percentage from the contract'>
                    <TableCell key={'holdback-section'} className={'sv-table-body-section-starter right'}>
                        Holdback
                    </TableCell>
                </Tooltip>
                {holdbacks.map((holdback: number, svidx: number) => {
                    return (
                        <TableCell key={'scheduled-visit-holdback-' + (svidx + 1)} className={'body-cell right'}>
                            <NumberFormatter currency value={holdback} />
                        </TableCell>
                    )
                })}
            </TableRow>
            <TableRow key={'visit-total-per-visit-row'}>
                <Tooltip title='This value is the final cost for the visit'>
                    <TableCell key={'total-per-visit-section'} className={'sv-table-body-section-starter right'}>
                        Total Per Visit
                    </TableCell>
                </Tooltip>
                {totalPerVisits.map((totalPerVisit: number, svidx: number) => {
                    return (
                        <TableCell key={'scheduled-visit-total-per-visit-' + (svidx + 1)} className={'body-cell right'}>
                            <NumberFormatter currency value={totalPerVisit} />
                        </TableCell>
                    );
                })}
            </TableRow>
        </>
    )
}
export default ScheduledVisitsTableCost;
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { Button, Card, CardActions, CardContent, CardHeader, IconButton, Modal, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { BForm, BGrid, BTextField } from 'mui-bueno';
import { Formik } from 'formik';
import { AxiosError } from 'axios';

import { deletePatientVisit } from '../../../../../service/Study/patientVisit';
import { showErrorSnackbar } from '../../../../../modules/messageSnackbarReducer';

interface DeleteUPVModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    patientVisitData: any;
}

interface DeleteModalInfo {
    identifier: string,
    dateOfService: Date,
    payment: string,
    holdback: string,
    total: string,
}

const UnscheduledVisitDeleteModal: React.FC<DeleteUPVModalProps> = props => {
    const { open, setOpen, patientVisitData } = props;
    const dispatch = useDispatch();

    const handleDeletion = async () => {
        deletePatientVisit(patientVisitData.patientVisit.id!).then(_ => {
            handleModalClose();
            history.go(0);
        }).catch((err: AxiosError) => {
            handleModalClose();
            dispatch(showErrorSnackbar(err.response?.data.message));
        });
    }

    const initialValues: DeleteModalInfo = {
        identifier: patientVisitData.patientIdentifier,
        dateOfService: patientVisitData.dateOfService!,
        payment: '$' + patientVisitData.payment.toFixed(2),
        holdback: '$' + patientVisitData.holdback.toFixed(2),
        total: '$' + patientVisitData.total.toFixed(2)
    }

    const handleSubmit = () => {
        // Do nothing
    }

    const handleModalClose = () => {
        setOpen(false);
    }

    return (
        <Modal
            open={open}
            onClose={handleModalClose}
        >
            <div className='modal-form'>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    <BForm>
                        <Card className="detail-form sm">
                            <CardHeader
                                title={'Delete Unscheduled Patient Visit: '}
                                action={
                                    <IconButton color="primary" aria-label="Close" onClick={handleModalClose}>
                                        <Close />
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                <Typography>Are you sure you want to delete this unscheduled patient visit?</Typography>
                                <BGrid container>
                                    <BTextField
                                        name="identifier"
                                        disabled
                                        variant={'outlined'}
                                        xs={6}
                                    />
                                    <BTextField
                                        name="dateOfService"
                                        disabled
                                        variant={'outlined'}
                                        xs={6}
                                    />
                                </BGrid>
                                <BGrid container>
                                    <BTextField
                                        name="payment"
                                        label="Cost"
                                        disabled
                                        inputProps={{
                                            className: 'right-input-text'
                                        }}
                                        variant={'outlined'}
                                        xs={4}
                                    />
                                    <BTextField
                                        name="holdback"
                                        label="Holdback"
                                        disabled
                                        inputProps={{
                                            className: 'right-input-text'
                                        }}
                                        variant={'outlined'}
                                        xs={4}
                                    />
                                    <BTextField
                                        name="total"
                                        label="Total"
                                        disabled
                                        inputProps={{
                                            className: 'right-input-text'
                                        }}
                                        variant={'outlined'}
                                        xs={4}
                                    />
                                </BGrid>
                            </CardContent>
                            <CardActions className='flex-end'>
                                <Button
                                    variant="contained"
                                    className="delete-button"
                                    onClick={handleDeletion}
                                >
                                    Delete
                                </Button>
                            </CardActions>
                        </Card>
                    </BForm>
                </Formik>
            </div>
        </Modal>
    )
}
export default UnscheduledVisitDeleteModal;
import * as React from 'react';
import { IconButton } from '@material-ui/core';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';


interface Props {
    editYear: number;
    setEditYear: (arg0: number) => void;
}
const ChartYearControls: React.FC<Props> = (props) => {
    const { editYear, setEditYear } = props;
    return (
        <div className="year-controls">
            <span>
                <IconButton
                    onClick={() => setEditYear(editYear - 1)}
                    size="small"
                >
                    <ArrowLeft />
                </IconButton>
            </span>
            <span>
                <IconButton
                    onClick={() => setEditYear(editYear + 1)}
                    size="small"
                >
                    <ArrowRight />
                </IconButton>
            </span>
        </div>
    )
}
export default ChartYearControls;
import * as React from 'react';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';

import {
    Typography,
} from '@material-ui/core';

import { ChangePasswordRequest, LoginRequest } from '../../../@types';
import { changePassword } from '../../../service/Management/users';
import { handleEditErrors } from '../../../service/utils';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../modules/messageSnackbarReducer';
import GridWrapper from '../../../common/GridWrapper/GridWrapper';

import { Store } from '../../../modules/rootReducer';

import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import { BForm, BPassword, BSubmit } from 'mui-bueno';

const schema = yup.object<LoginRequest>().shape({
    oldPassword: yup.string().required('Old password is required'),
    newPassword: yup.string().required('New password is required'),
    confirmNewPassword: yup.string().required('Must confirm new password')
        .test('new-password-match', 'New passwords do not match', function (value) {
            const { newPassword } = this.parent;
            return newPassword === value;
        }),
});

interface PasswordRequest {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}

const ChangePassword: React.FC = () => {
    const accessToken = useSelector<Store, string>(
        store => store.loginJwtReducer.accessToken
    );
    const dispatch = useDispatch();
    const history = useHistory();

    const initialValues: PasswordRequest = {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: ''
    }

    const handleSubmit = async (data: PasswordRequest, { setErrors }: FormikHelpers<PasswordRequest>) => {
        setErrors({});
        schema.validate(data, { abortEarly: false })
            .then(() => {
                const changePasswordRequest: ChangePasswordRequest = {
                    oldPassword: data.oldPassword,
                    newPassword: data.newPassword,
                    accessToken: accessToken,
                }
                changePassword(changePasswordRequest).then(() => {
                    dispatch(showSuccessSnackbar('Password reset successfully'));
                    history.push('/study');
                }).catch((err: AxiosError) => {
                    if (err.response) {
                        if (err.response.status === 422) {
                            handleEditErrors(err, setErrors);
                        } else if (err.response.status === 400) {
                            const index = err.response.data.message.indexOf('(');
                            const message = err.response.data.message.substring(0, index - 1);
                            if (message === 'Incorrect username or password.') {
                                setErrors({ oldPassword: 'Incorrect password.' });
                            } else if (err.response.data.message === 'Too many requests. Try again later.') {
                                dispatch(showErrorSnackbar('Too many requests. Try again later.'));
                            } else if (err.response.data.message === 'User not found.') {
                                dispatch(showErrorSnackbar('User not found.'));
                            } else {
                                dispatch(showErrorSnackbar('Unexpected error. Try again later.'));
                            }
                        } else if (err.response.status === 401) {
                            dispatch(showErrorSnackbar('Unauthorized. Please log in.'));
                        }
                    }
                });
            }).catch((err: yup.ValidationError) => {
                const errors: { [key: string]: string } = {};
                for (const e of err.inner) {
                    errors[e.path!] = e.message
                }
                setErrors(errors);
            });
    }

    return (
        <GridWrapper>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
            >
                <BForm>
                    <Typography className="card-heading">Reset Password</Typography>
                    <BPassword
                        name="oldPassword"
                        label="Old Password"
                        noMP
                        required
                    />
                    <BPassword
                        name="newPassword"
                        label="New Password"
                        noMP
                        required
                    />
                    <BPassword
                        name="confirmNewPassword"
                        label="Confirm New Password"
                        noMP
                        required
                    />
                    <BSubmit
                        name="change-password-submit"
                        xs={12}
                        noMP
                        gridStyle={{ marginTop: '12px', marginBottom: '0px' }}
                    >
                        Reset Password
                    </BSubmit>
                </BForm>
            </Formik>
        </GridWrapper>
    );
}

export default ChangePassword;
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, Box, Typography } from '@material-ui/core';

import { DivisionTree, Simulator } from '../../../@types';
import { getDivisionTreeIds } from '../../../common/Utils/utils';
import { handleErrorResponse } from '../../../service/utils';
import { getAllByDivision } from '../../../service/Simulator/simulator';

import Loading from '../../../common/Routes/Loading';
import SearchBar from '../../../common/SearchBar/SearchBar';
import usePermissions from '../../../common/hooks/usePermissions';
import FilterSortButton from '../../../common/FilterSortButton/FilterSortButton';
import EditablePagingTable, { TableColumn } from '../../../common/DataTable/EditablePagingTable';
import FilterByDivision from '../../Invoicing/Invoice/FilterByDivision';

import AddSimulatorModal from './AddSimulatorModal';
import { statusStyle } from '../Utils';

interface TableData {
    id: number | null;
    object: JSX.Element;
    subtitle: string;
    sourceStatus: JSX.Element;
    author: string;
    created: Date | undefined;
    lifetimeProductivity: number;
}

const columns: TableColumn[] = [{
    type: 'object',
    displayValue: 'Contract/Study',
    align: 'left',
    format: 'jsx-element',
    style: { minWidth: 250 },
}, {
    type: 'subtitle',
    displayValue: 'Subtitle',
    align: 'left',
    style: { minWidth: 200 },
}, {
    type: 'sourceStatus',
    displayValue: 'Status of Source',
    align: 'center',
    format: 'jsx-element',
    style: { width: 200 },
}, {
    type: 'author',
    displayValue: 'Author',
    align: 'center',
}, {
    type: 'created',
    displayValue: 'Created',
    align: 'center',
    format: 'date',
}, {
    type: 'lifetimeProductivity',
    displayValue: 'Lifetime Productivity',
    align: 'right',
    format: 'currency-US',
}];

interface State {
    objects: any[];
    filters: {
        PENDING_CONTRACT: boolean;
        SIGNED_CONTRACT: boolean;
        ACTIVE_STUDY: boolean;
        COMPLETED_STUDY: boolean;
    }
    division?: number[];
}

const initialState: State = {
    objects: [],
    filters: {
        PENDING_CONTRACT: true,
        SIGNED_CONTRACT: true,
        ACTIVE_STUDY: true,
        COMPLETED_STUDY: true,
    },
}

interface Action {
    type: 'objectPage' | 'filters' | 'division';
    payload?: any;
}

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'objectPage':
            return {
                ...state,
                objects: action.payload.list,
            } as State;
        case 'filters':
            return {
                ...state,
                filters: action.payload,
            } as State;
        case 'division':
            return {
                ...state,
                division: action.payload,
            } as State;
        default:
            return state;
    }
}

const SimulatorList: React.FC = () => {
    const [state, dispatchPage] = React.useReducer(reducer, initialState);
    const { objects, filters, division } = state;

    const history = useHistory();
    const dispatch = useDispatch();

    const [list, setList] = React.useState<Simulator[]>([]);
    const [query, setQuery] = React.useState<string>('');
    const [doneLoading, setDoneLoading] = React.useState<boolean>(false);
    const [addSimulatorOpen, setAddSimulatorOpen] = React.useState(false);

    const canViewSimulatorList: boolean = usePermissions('API_SIMULATOR_GET_ALL_SIMULATIONS_BY_DIVISION');
    const canCreateSimulator: boolean = usePermissions('API_SIMULATOR_CREATE');

    React.useEffect(() => {
        if (canViewSimulatorList) {
            getAllByDivision(null)
                .then(res => {
                    setList(res.data);
                    dispatchPage({ type: 'objectPage', payload: { list: res.data } });
                    setDoneLoading(true);
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        prefix: 'Could not retrieve list of Simulators: '
                    });
                });
        }
    }, []);

    React.useEffect(() => {
        if (list.length > 0) {
            let filteredList: Simulator[] = list;
            if (division && division.length != 0) {
                filteredList = filteredList.filter(o => {
                    return division.includes(o.divisionId);
                });
            }
            filteredList = filteredList.filter(o =>
                (filters.PENDING_CONTRACT && o.sourceStatus == 'PENDING_CONTRACT')
                || (filters.SIGNED_CONTRACT && o.sourceStatus == 'SIGNED_CONTRACT')
                || (filters.ACTIVE_STUDY && o.sourceStatus == 'ACTIVE_STUDY')
                || (filters.COMPLETED_STUDY && o.sourceStatus == 'COMPLETED_STUDY')
            );
            if (Number(query) !== 0) {
                filteredList = filteredList.filter(o =>
                    o.studyName.toLowerCase().indexOf(query.toLowerCase()) !== -1
                    || o.studyIdentifier.toLowerCase().indexOf(query.toLowerCase()) !== -1
                    || o.subtitle.toLowerCase().indexOf(query.toLowerCase()) !== -1
                    || o.author.toLowerCase().indexOf(query.toLowerCase()) !== -1
                );
            }
            dispatchPage({
                type: 'objectPage',
                payload: { list: filteredList }
            });
        }
    }, [list, filters, query, division]);

    const handleChangeFilters = (f1: boolean, f2: boolean, f3: boolean, f4: boolean) => {
        dispatchPage({ type: 'filters', payload: { PENDING_CONTRACT: f1, SIGNED_CONTRACT: f2, ACTIVE_STUDY: f3, COMPLETED_STUDY: f4 } });
    };
    const handleChangeDivision = (div: DivisionTree): void => {
        dispatchPage({ type: 'division', payload: getDivisionTreeIds(div) });
    };

    const handleRowClick = (id: number) => {
        history.push(`/simulator/${id}`)
    }

    

    const convertTableData = (data: Simulator[]) => {
        const newData: TableData[] = [];
        data.forEach(obj => {
            const identifiers = (
                <div className='double-line-text'>
                    <div className='title sort-field'>
                        {obj.studyName} ({obj.studyIdentifier})
                    </div>
                    <div className='subtitle'>
                        {obj.divisionName}
                    </div>
                </div>
            )
            const statusChip = (
                <div className={statusStyle(obj)[1] + ' sort-field'}>
                    {statusStyle(obj)[0]}
                </div>
            )
            newData.push({
                id: obj.id,
                object: identifiers,
                subtitle: obj.subtitle,
                sourceStatus: statusChip,
                author: obj.author,
                created: obj.created,
                lifetimeProductivity: obj.lifetimeProductivity,
            })
        })
        return newData;
    }

    if (!canViewSimulatorList) {
        return (
            <div className="full-screen centered">
                <Box textAlign="center" className="page-body">
                    <Typography gutterBottom variant="h1">
                        Restricted
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                        You do not have permission to access this page.
                    </Typography>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => history.push('/')}
                        style={{ margin: 40 }}
                    >
                        Back to Home
                    </Button>
                </Box>
            </div>
        )
    } else if (doneLoading) {
        return (
            <div className="simulator-landing">
                <div className='half-background' />
                <div className="heading">
                    <Typography variant='h2'>
                        Welcome to CRAFT’s “What If?” Simulator
                    </Typography>
                    <Typography variant='body1'>
                        Use this tool to project the future of your studies.
                        {canCreateSimulator ?
                            ' Get started by creating a new simulator from your institution’s proposal/study or select an existing simulator below.'
                            : ' Select one of the existing simulations below to get started'
                        }
                    </Typography>
                    {canCreateSimulator &&
                        <Button
                            variant="contained"
                            onClick={() => setAddSimulatorOpen(true)}
                        >
                            Launch New Simulation
                        </Button>
                    }
                </div>
                <div className="list">
                    <EditablePagingTable
                        dataList={convertTableData(objects)}
                        tableInfoColumns={columns}
                        initialSortType='contract'
                        readonly={true}
                        canCreate={false}
                        mode='full'
                        rowClick={handleRowClick}
                        height='60vh'
                        borderedCells={false}
                        leftActionsElement={
                            <>
                                <SearchBar query={query} setQuery={setQuery} />
                                <FilterSortButton
                                    sections={[{
                                        title: 'Filter By Status',
                                        tooltip: 'Filter contract list by status. Contracts with a pending status have not been signed.',
                                        body: (
                                            <FormControl>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={filters.PENDING_CONTRACT} onChange={() => handleChangeFilters(!filters.PENDING_CONTRACT, filters.SIGNED_CONTRACT, filters.ACTIVE_STUDY, filters.COMPLETED_STUDY)} name="statusFilter1" />}
                                                        label="Pending Contract"
                                                    />
                                                    <FormControlLabel
                                                        control={<Checkbox checked={filters.SIGNED_CONTRACT} onChange={() => handleChangeFilters(filters.PENDING_CONTRACT, !filters.SIGNED_CONTRACT, filters.ACTIVE_STUDY, filters.COMPLETED_STUDY)} name="statusFilter2" />}
                                                        label="Signed Contract"
                                                    />

                                                    <FormControlLabel
                                                        control={<Checkbox checked={filters.ACTIVE_STUDY} onChange={() => handleChangeFilters(filters.PENDING_CONTRACT, filters.SIGNED_CONTRACT, !filters.ACTIVE_STUDY, filters.COMPLETED_STUDY)} name="statusFilter3" />}
                                                        label="Active Study"
                                                    />
                                                    <FormControlLabel
                                                        control={<Checkbox checked={filters.COMPLETED_STUDY} onChange={() => handleChangeFilters(filters.PENDING_CONTRACT, filters.SIGNED_CONTRACT, filters.ACTIVE_STUDY, !filters.COMPLETED_STUDY)} name="statusFilter4" />}
                                                        label="Completed Study"
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        )
                                    }, {
                                        title: 'Filter By Division',
                                        tooltip: 'Filter contracts by division or sub-division. Select upper "All Divisions" option to show all contracts without division filter.',
                                        body: (
                                            <FilterByDivision
                                                setChosenDivision={handleChangeDivision}
                                                withinCollapsiblePanel={false}
                                                restricted={true}
                                                withinMenu
                                            />
                                        )
                                    }]}
                                />
                            </>
                        }
                    />
                    {canCreateSimulator &&
                        <AddSimulatorModal open={addSimulatorOpen} setOpen={setAddSimulatorOpen} />
                    }
                </div>
            </div>
        )
    } else {
        return (
            <Loading />
        )
    }
}

export default SimulatorList;
import * as React from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import {
    Card, CardActions, CardContent, CardHeader, IconButton, InputAdornment, Modal,
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@material-ui/core';
import { Add, Close, Delete, HelpOutline, Save } from '@material-ui/icons';
import { BButton, BDatePicker, BForm, BSubmit, BTextField } from 'mui-bueno';

import { SalaryLog, SalaryLogPage, SalaryLogPageRequest, TableHeader, User } from '../../../../@types';
import { createSalaryLog, deleteSalaryLog, getSalaryLogPage, updateSalaryLog } from '../../../../service/Management/salary-logs';
import { handleErrorResponse } from '../../../../service/utils';
import { showSuccessSnackbar } from '../../../../modules/messageSnackbarReducer';
import { NumberFormatter } from '../../../../common/Utils/NumberFormatter';
import { displayDate, getLocalDate } from '../../../../common/Utils/utils';
import useLoggedInUserAccess from '../../../../common/hooks/useLoggedInUserAccess';


const headers: TableHeader[] = [{
    type: 'effectiveDate',
    displayValue: 'Effective Date',
    align: 'center'
}, {
    type: 'salary',
    displayValue: 'Salary',
    align: 'center'
}, {
    type: 'fringe',
    displayValue: 'Fringe Benefits',
    align: 'center'
}];

const salaryLogSchema = yup.object<SalaryLog>().shape({
    salary: yup.number().required('Salary is required').min(0, 'Salary cannot be negative'),
    fringe: yup.number().required('Fringe Benefits is required').min(0, 'Fringe Benefits cannot be negative'),
    effectiveDate: yup.date().required('Pay Start Date is required')
});

const SalaryInformation: React.FC<{ user: User }> = props => {
    const { user } = props;

    const dispatch = useDispatch();

    const permissionGetSalaryLogsPage: boolean = useLoggedInUserAccess('API_SALARY_LOG_GET_SALARY_LOGS', user);
    const permissionCreateSalaryLog: boolean = useLoggedInUserAccess('API_SALARY_LOG_CREATE', user);
    const permissionUpdateSalaryLog: boolean = useLoggedInUserAccess('API_SALARY_LOG_UPDATE', user);
    const permissionsDeleteSalaryLog: boolean = useLoggedInUserAccess('API_SALARY_LOG_DELETE_BY_ID', user);

    const [salaryLog, setSalaryLog] = React.useState<any>({
        id: undefined,
        salary: '0',
        fringe: '0',
        hourlyRate: undefined,
        effectiveDate: new Date(),
        userId: undefined
    });
    const [salaryLogsPage, setSalaryLogsPage] = React.useState<SalaryLogPage>({
        page: 0,
        total: 0,
        list: []
    });
    const [salaryLogModalOpen, setSalaryLogModalOpen] = React.useState(false);
    const [confirmDeleteSalaryLog, setConfirmDeleteSalaryLog] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (user.id) {
            const pageReq: SalaryLogPageRequest = {
                sort: 'effectiveDate',
                userId: user.id,
            }

            if (permissionGetSalaryLogsPage)
                getSalaryLogPage(pageReq)
                    .then(res => {
                        setSalaryLogsPage(res.data);
                    }).catch(err => {
                        handleErrorResponse(err, dispatch, {
                            prefix: 'Could not retrieve list of SalaryLogs: '
                        });
                    });
        }
    }, [user, salaryLogModalOpen]);

    const handleSubmitSalaryLog = async (data: SalaryLog, { setErrors }: FormikHelpers<SalaryLog>) => {
        if (salaryLog.id) {
            if (permissionUpdateSalaryLog)
                updateSalaryLog({
                    ...salaryLog,
                    salary: data.salary,
                    fringe: data.fringe,
                    hourlyRate: +(data.salary! / 2080).toFixed(4),
                    effectiveDate: data.effectiveDate,
                    userId: user.id
                }).then(() => {
                    handleSalaryLogModalClose();
                    dispatch(showSuccessSnackbar('Salary Log updated'));
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        setStatus: setErrors,
                        prefix: 'Could not update Salary Log: '
                    });
                });
        } else {
            if (permissionCreateSalaryLog)
                createSalaryLog({
                    ...salaryLog,
                    salary: data.salary,
                    fringe: data.fringe,
                    hourlyRate: +(data.salary! / 2080).toFixed(4),
                    effectiveDate: data.effectiveDate,
                    userId: user.id
                }).then(res => {
                    handleSalaryLogModalClose();
                    dispatch(showSuccessSnackbar('Salary Log created'));
                }).catch(err => {
                    handleErrorResponse(err, dispatch, {
                        setStatus: setErrors,
                        prefix: 'Could not create Salary Log: '
                    });
                });
        }
    }
    const handleDeleteSalaryLog = () => {
        setConfirmDeleteSalaryLog(true);
    }

    const handleConfirmDeleteSalaryLog = () => {
        if (salaryLog.id) {
            if (permissionsDeleteSalaryLog)
                deleteSalaryLog(salaryLog.id)
                    .then(res => {
                        handleSalaryLogModalClose();
                        dispatch(showSuccessSnackbar('Salary Log deleted'));
                    }).catch(err => {
                        handleErrorResponse(err, dispatch, {
                            prefix: 'Could not delete Salary Log: '
                        });
                    });
        }
    }

    const handleSalaryLogModalOpen = () => {
        setSalaryLogModalOpen(true);
    };

    const handleSalaryLogModalClose = () => {
        setSalaryLogModalOpen(false);
        setSalaryLog({
            id: undefined,
            salary: '0',
            fringe: '0',
            hourlyRate: undefined,
            effectiveDate: new Date(),
            userId: undefined
        });
        setConfirmDeleteSalaryLog(false);
    };

    const onSelectSalaryLog = (selected: SalaryLog) => {
        setSalaryLog({
            id: selected.id,
            salary: selected.salary,
            fringe: selected.fringe,
            hourlyRate: selected.hourlyRate,
            effectiveDate: getLocalDate(selected.effectiveDate!),
            userId: selected.userId
        });
        setSalaryLogModalOpen(true);
    }

    // Handles different types of display values for each Salary Log attribute
    const fillTableCell = (type: string, value: any) => {
        if (type === 'salary') {
            return (
                <NumberFormatter currency value={+value} />
            );
        } else if (type === 'fringe') {
            return (
                <NumberFormatter percent value={+value / 100} />
            );
        } else {
            return displayDate(value);
        }
    };

    return (
        <>
            {permissionGetSalaryLogsPage &&
                <>
                    <Card className="detail-form">
                        <CardHeader
                            title="Salary Information"
                            action={
                                <BButton
                                    startIcon={<Add />}
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    className="flex-end"
                                    onClick={handleSalaryLogModalOpen}
                                    disabled={!user.active || !permissionCreateSalaryLog}
                                >
                                    Add Salary Log
                                </BButton>
                            }
                        />
                        <CardContent>
                            <TableContainer component={Paper} className="salary-table-container">
                                <Table stickyHeader>
                                    <TableHead className="table-header">
                                        <TableRow>
                                            {headers.map(header => {
                                                return (
                                                    <TableCell
                                                        key={'header-' + header.type}
                                                        className="salary-table-header-cell"
                                                        data-header-type={header.type}
                                                    >
                                                        <div className={`header-cell-label ${header.align}`}>
                                                            {header.displayValue}
                                                        </div>
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {salaryLogsPage.list.map((obj: any, idx: number) => {
                                            return (
                                                <TableRow
                                                    key={'row-' + idx}
                                                    onClick={() => onSelectSalaryLog(obj)}
                                                    className='cursor-pointer'
                                                >
                                                    {headers.map(header => {
                                                        return (
                                                            <TableCell
                                                                key={header.type + '-' + idx}
                                                                className={`body-cell ${header.align}`}
                                                            >
                                                                {fillTableCell(header.type, obj[header.type])}
                                                            </TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                                {salaryLogsPage.list.length === 0 && <Typography variant="body1" className="flex-center margin-2">No data to display.</Typography>}
                            </TableContainer>
                        </CardContent>
                    </Card>
                    <Modal
                        open={salaryLogModalOpen}
                        onClose={handleSalaryLogModalClose}
                    >
                        <div className="modal-form">
                            <Formik
                                initialValues={salaryLog}
                                onSubmit={handleSubmitSalaryLog}
                                enableReinitialize
                                validationSchema={salaryLogSchema}
                                validateOnChange={false}
                                validateOnBlur={false}
                            >
                                <BForm>
                                    <Card className="detail-form sm">
                                        <CardHeader
                                            title={salaryLog.id ? 'Edit Salary Log' : 'Create Salary Log'}
                                            action={
                                                <IconButton color="primary" aria-label="Close" onClick={handleSalaryLogModalClose}>
                                                    <Close />
                                                </IconButton>
                                            }
                                        />
                                        <CardContent>
                                            <BTextField
                                                name="salary"
                                                label="Salary"
                                                placeholder="e.g. 55,000.00"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    inputComponent: NumberFormatter as any
                                                }}
                                                disabled={!user.active || (salaryLog.id ? !permissionUpdateSalaryLog : !permissionCreateSalaryLog)}
                                                required
                                            />
                                            <BTextField
                                                name="fringe"
                                                label="Fringe Benefits"
                                                placeholder="e.g. 5.00"
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                    inputComponent: NumberFormatter as any
                                                }}
                                                disabled={!user.active || (salaryLog.id ? !permissionUpdateSalaryLog : !permissionCreateSalaryLog)}
                                                required
                                            />
                                            <BDatePicker
                                                name='effectiveDate'
                                                label='Effective Date'
                                                placeholder='Effective Date'
                                                disabled={!user.active || (salaryLog.id ? !permissionUpdateSalaryLog : !permissionCreateSalaryLog)}
                                                required
                                                inputVariant='outlined'
                                            />
                                        </CardContent>
                                        <CardActions className='space-between'>
                                            {salaryLog.id ?
                                                <BButton
                                                    variant="contained"
                                                    startIcon={confirmDeleteSalaryLog ? <HelpOutline /> : <Delete />}
                                                    className={(!user.active || !salaryLog.id || !permissionsDeleteSalaryLog) ? 'disabled' : 'delete-color'}
                                                    onClick={confirmDeleteSalaryLog ? handleConfirmDeleteSalaryLog : handleDeleteSalaryLog}
                                                    disabled={!user.active || !salaryLog.id || !permissionsDeleteSalaryLog}
                                                >
                                                    {confirmDeleteSalaryLog ? 'Confirm Delete' : 'Delete'}
                                                </BButton> : <div />
                                            }
                                            <BSubmit
                                                startIcon={<Save />}
                                                variant="contained"
                                                color="primary"
                                                disabled={!user.active || ((salaryLog.id && !permissionUpdateSalaryLog) || (!salaryLog.id && !permissionCreateSalaryLog))}
                                            >
                                                Save
                                            </BSubmit>
                                        </CardActions>
                                    </Card>
                                </BForm>
                            </Formik>
                        </div>
                    </Modal>
                </>
            }
        </>
    );
}

export default SalaryInformation;

import * as React from 'react';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';

import {
    Button, IconButton, InputAdornment, Menu, MenuItem, Paper, Table, TableBody,
    TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tooltip
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, MoreHoriz } from '@material-ui/icons';
import { BDatePicker, BError, BForm, BOption, BSelect, BSubmit, BTextField } from 'mui-bueno';

import { SelectOption, StudyUser } from '../../@types';
import { NumberFormatter } from '../Utils/NumberFormatter';
import { displayDate } from '../../common/Utils/utils';
import { DisplayTextFormat } from '../../common/Utils/DisplayTextFormat';
import { Store } from '../../modules/rootReducer';
import { CollapsiblePanelState } from '../../modules/collapsiblePanelReducer';

import CheckedAutocomplete from '../CheckedAutocomplete/CheckedAutocomplete';
import StatusChip from '../Invoicing/StatusChip';
import { renderEmployeeChip } from './Utils';
import { useHistory } from 'react-router-dom';
import useLoggedInUser from '../hooks/useLoggedInUser';

export interface TableColumn {
    type: string;
    displayValue: string;
    align: 'left' | 'center' | 'right';
    style?: any;
    placeholder?: string;
    required?: boolean;
    bOptions?: BOption<string>[];
    selectOptions?: SelectOption<number>[];
    derivedField?: boolean;
    format?: 'integer' | 'currency-US' | 'date' | 'status' | 'button' | 'enum' | 'long-desc' | 'jsx-element' | 'symbol' | 'employees';
    onChange?: any;
    disabled?: boolean;
    hidden?: boolean;
    greyIfEmpty?: boolean;
    noSort?: boolean;
    buttonProps?: {
        disable?: (object: any) => boolean;
        label?: string;
        iconLabel?: React.ReactNode;
        handler: (object: any) => void;
        handlerPermission: boolean;
    };
    symbolProps?: {
        label?: string;
        icon?: React.ReactNode;
    }
    // checkedautocomplete props
    acValue?: any;
    multiple?: boolean;
    defaultValue?: any;
    noCheckmark?: boolean;
    closeOnSelect?: boolean;
    error?: boolean;
    errorMessage?: any;
    tooltipTitle?: string;
    headerToolTip?: string;
    // Props for employee chips
    referenceRoles?: SelectOption<number>[];
    studyUsers?: StudyUser[];
    canGoToUserStats?: boolean;
}

export interface ActionOption {
    label: string;
    handler: any;
    permission: boolean;
    permissionFunction?: (arg0: any) => boolean;
    disabled?: boolean;
    tooltipTitle?: string;
    icon?: React.ReactNode;
}

interface State {
    sortedList: any[];
    sortColumn: string;
    sortOrder: boolean;
}

interface Action {
    type: 'list' | 'column' | 'order';
    payload?: any;
}

interface Props {
    dataList: any[];
    tableInfoColumns: TableColumn[];
    initialSortType?: string;
    mode?: 'full' | 'expanded' | 'dynamic';
    height?: string;
    borderedCells?: boolean;

    readonly: boolean;
    rowClick?: any;

    canCreate: boolean;
    validationSchema?: any;
    handleValidate?: any;
    handleSubmit?: any;
    editingDataObject?: any;
    clickedObj?: null | any;
    setClickedObj?: any;
    actionOptions?: ActionOption[];
    leftActionsElement?: any;
}


export const renderFieldInTableCell = (column: TableColumn, idx: number) => {
    const addingStyle = column.style ? column.style : '';
    const inputProps = () => {
        if (column.format === 'integer') {
            return {
                inputComponent: NumberFormatter as any,
                inputProps: { integer: true }
            }
        }
        if (column.format === 'currency-US' && !column.disabled) {
            return {
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                inputComponent: NumberFormatter as any
            }
        }
        return undefined;
    }

    const inputComponent = () => {
        if (column.selectOptions) {
            return (
                <Tooltip title={column.tooltipTitle ? column.tooltipTitle : ''} arrow>
                    <div>
                        <CheckedAutocomplete
                            idText={column.type}
                            placeholder={column.placeholder}
                            multiple={column.multiple ?? undefined}
                            acValue={column.acValue ?? null}
                            options={column.selectOptions}
                            onChange={column.onChange ?? undefined}
                            defaultValue={column.defaultValue ?? undefined}
                            noCheckmark={column.noCheckmark ?? undefined}
                            closeOnSelect={column.closeOnSelect ?? undefined}
                            disabled={column.disabled ?? undefined}
                            error={column.error ?? undefined}
                            errorMessage={column.errorMessage ?? undefined}
                            autoFocus={idx == 0}
                        />
                        {column.type === 'serviceProvider' && <BError name={'serviceProviderId'} id="serviceProviderId-error" />}
                    </div>
                </Tooltip>
            )
        } else if (column.bOptions) {
            return (
                <Tooltip title={column.tooltipTitle ? column.tooltipTitle : ''} arrow>
                    <div>
                        <BSelect
                            name={column.type}
                            label=''
                            placeholder={column.placeholder}
                            variant='standard'
                            required={column.required}
                            options={column.bOptions}
                            onChange={column.onChange ?? undefined}
                            disabled={column.disabled ?? undefined}
                            className='bselect-no-margin'
                            noMP
                            autoFocus={idx == 0}
                        />
                    </div>
                </Tooltip>
            )
        } else if (column.format == 'date') {
            return (
                <Tooltip title={column.tooltipTitle ? column.tooltipTitle : ''} arrow>
                    <div>
                        <BDatePicker
                            name={column.type}
                            label=''
                            placeholder="MM/DD/YYYY"
                            inputVariant="standard"
                            required
                            disabled={column.disabled ?? undefined}
                            noMP
                            autoFocus={idx == 0}
                        />
                    </div>
                </Tooltip>
            )
        } else {
            return (
                <Tooltip title={column.tooltipTitle ? column.tooltipTitle : ''} arrow>
                    <div>
                        <BTextField
                            name={column.type}
                            label=''
                            placeholder={column.placeholder}
                            variant='standard'
                            margin='none'
                            required={column.required}
                            InputProps={inputProps()}
                            disabled={column.disabled ?? undefined}
                            noMP
                            autoFocus={idx == 0}
                        />
                    </div>
                </Tooltip>
            )
        }
    }

    return (
        <TableCell key={`data-cell-for-${column.type}`} className='body-cell left' style={{ ...addingStyle }}>
            {column.derivedField !== true && inputComponent()}
        </TableCell>
    );
};

const EditablePagingTable: React.FC<Props> = (props) => {
    const {
        dataList,
        tableInfoColumns,
        editingDataObject,
        handleSubmit,
        validationSchema,
        handleValidate,
        readonly,
        canCreate,
        clickedObj,
        setClickedObj,
        actionOptions,
        initialSortType,
        mode,
        rowClick,
        height,
        borderedCells = true,
        leftActionsElement,
    } = props;

    const history = useHistory();
    const loggedInUser = useLoggedInUser();

    // State management for table pagination
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(-1);

    const hasMenuItems: boolean = !!actionOptions;
    const hasActionPermission: boolean = !!actionOptions?.find(action => action.permission);

    const { expanded } = useSelector<Store, CollapsiblePanelState>(
        store => store.collapsiblePanelReducer
    );

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const initialState: State = {
        sortedList: dataList,
        sortColumn: initialSortType ? initialSortType : tableInfoColumns[0].type,
        sortOrder: true
    };
    const reducer = (state: State, action: Action) => {
        switch (action.type) {
            case 'list':
                return { ...state, sortedList: action.payload };
            case 'column':
                return { ...state, sortColumn: action.payload, sortOrder: true }
            case 'order':
                return { ...state, sortOrder: action.payload }
            default:
                return state;
        }
    };
    const [sortState, dispatchSort] = React.useReducer(reducer, initialState);
    const { sortedList, sortColumn, sortOrder } = sortState;

    const handleSortClick = (columnType: string): void => {
        if (columnType === sortColumn) {
            dispatchSort({ type: 'order', payload: !sortOrder });
        } else {
            dispatchSort({ type: 'column', payload: columnType });
        }
    };


    const findSortableField: any = (obj: any) => {
        if (obj && obj.props) {
            if (obj.props.className && obj.props.className.includes('sort-field')) {
                if (obj.props.value) return obj.props.value as number;
                if (obj.props.children) return obj.props.children.toString();
            }
            if (obj.props.children) {
                if (obj.props.children instanceof Array) {
                    for (const child of obj.props.children) {
                        return findSortableField(child);
                    }
                }
                return findSortableField(obj.props.children);
            }
            return findSortableField(obj.props);
        }
        return obj;
    }

    React.useEffect(() => {
        const sorted: any[] = [...dataList].sort((aObj, bObj) => {
            const order: number = !sortOrder ? -1 : 1;
            const a = aObj[sortColumn];
            const b = bObj[sortColumn];

            if (a instanceof Date || b instanceof Date) {
                return order * ((a?.getTime() ?? new Date(0).getTime()) - (b?.getTime() ?? new Date(0).getTime()));
            } else if (typeof a === 'number' || typeof b === 'number') {
                return order * ((a ?? 0) - (b ?? 0));
            } else {
                const aVal = findSortableField(a);
                const bVal = findSortableField(b);
                if (aVal instanceof Date || bVal instanceof Date) {
                    return order * ((aVal?.getTime() ?? new Date(0).getTime()) - (bVal?.getTime() ?? new Date(0).getTime()));
                }
                if (typeof aVal === 'number' || typeof bVal === 'number') {
                    return order * ((aVal ?? 0) - (bVal ?? 0));
                }
                return order * (aVal ?? '').localeCompare(bVal ?? '');
            }
        });
        dispatchSort({ type: 'list', payload: sorted });
    }, [dataList, sortColumn, sortOrder]);

    // State management for the more actions menu
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleMoreMenu = (event: React.MouseEvent<HTMLElement>, selected: any) => {
        setClickedObj(selected);
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const tableEndRef = React.useRef<HTMLInputElement>(null);
    const [updated, setUpdated] = React.useState<number>(0);
    React.useEffect(() => {
        // Prevent scrolling on initial render
        if (updated > 0) {
            setUpdated(updated + 1);
        }
    }, [dataList])

    React.useEffect(() => {
        if (tableEndRef.current) tableEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [updated]);

    const conditionalClassName = () => {
        if (mode == 'expanded') {
            return 'constrainable expanded';
        }
        if ((!mode || mode == 'dynamic') && expanded) {
            return 'constrainable expanded';
        }
        if ((!mode || mode == 'dynamic') && !expanded) {
            return 'constrainable collapsed';
        }
        return 'constrainable';
    }

    const renderActionIcons = (obj: any, objIndex: number): React.ReactNode => {
        if ((actionOptions?.length ?? 0) > 3) {
            return (
                <IconButton
                    aria-controls="more-actions-menu"
                    aria-haspopup="true"
                    onClick={(event) => handleMoreMenu(event, obj)}
                    style={{ padding: 0 }}
                >
                    <MoreHoriz />
                </IconButton>
            );
        } else {
            return (
                <div className="aligned-row" style={{ justifyContent: 'center' }}>
                    {actionOptions?.map((option, index) => {
                        if (!option.permission) return;
                        if (!!option.permissionFunction && !option.permissionFunction(obj)) return;
                        return (
                            <Tooltip
                                key={`row-${objIndex}-action-${index}`}
                                title={option.label}
                                arrow
                            >
                                <IconButton
                                    onClick={() => {
                                        setClickedObj(obj);
                                        option.handler(obj);
                                    }}
                                    style={{ padding: '6px' }}
                                >
                                    {option.icon ?? <span className="ept-custom-icon">&#10033;</span>}
                                </IconButton>
                            </Tooltip>
                        );
                    })}
                </div>
            );
        }
    };

    const headerSortIcon = (type: string) => {
        if (sortColumn === type) {
            return sortOrder ? <ArrowDropUp /> : <ArrowDropDown />;
        } else {
            return;
        }
    }

    const actionsRow = () => {
        if (leftActionsElement) {
            return (
                <div className='aligned-row'>
                    <div className='left'>
                        {leftActionsElement}
                    </div>
                    <div className='right'>
                        <TablePagination
                            component="div"
                            count={dataList.length}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[{ label: 'All', value: -1 }, 10, 20, 30]}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage="Items Per Page:"
                            className='table-pagination'
                            labelDisplayedRows={({ from, to, count }) => {
                                if (to == -1) {
                                    return `${count} of ${count}`;
                                }
                                return `${from}-${to} of ${count}`;
                            }}
                        />
                    </div>
                </div>
            )
        } else {
            return (
                <TablePagination
                    component="div"
                    count={dataList.length}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[{ label: 'All', value: -1 }, 10, 20, 30]}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Items Per Page:"
                    className='table-pagination'
                    labelDisplayedRows={({ from, to, count }) => {
                        if (to == -1) {
                            return `${count} of ${count}`;
                        }
                        return `${from}-${to} of ${count}`;
                    }}
                />
            )
        }
    }

    return (
        <>
            <div className={conditionalClassName()}>
                {actionsRow()}
                <Formik
                    initialValues={editingDataObject}
                    onSubmit={handleSubmit}
                    validate={handleValidate ?? undefined}
                    validationSchema={validationSchema ?? undefined}
                    enableReinitialize
                    validateOnBlur={false}
                    validateOnChange={false}
                >
                    <BForm>
                        <TableContainer component={Paper} className={borderedCells ? 'input-table' : 'input-table horizontal-lines'} style={{ maxHeight: height ? height : '70vh' }}>
                            <Table stickyHeader>
                                <TableHead className="table-header">
                                    <TableRow>
                                        {tableInfoColumns.map(header => {
                                            if (!header.hidden) {
                                                return (
                                                    <TableCell
                                                        key={'header-' + header.type}
                                                        className={`primary-cell primary-color${!header.noSort ? ' cursor-pointer' : ''}`}
                                                        style={{ ...(header.style ? header.style : '') }}
                                                        onClick={() => header.noSort ? undefined : handleSortClick(header.type)}
                                                    >
                                                        <Tooltip arrow title={header.headerToolTip ?? ''} placement='top'>
                                                            <div className={`header-cell-label ${header.align}`}>
                                                                {header.displayValue}
                                                                {headerSortIcon(header.type)}
                                                            </div>
                                                        </Tooltip>

                                                    </TableCell>
                                                );
                                            }
                                        })}
                                        {!readonly && hasActionPermission && hasMenuItems &&
                                            <TableCell key="header-actions" className="primary-cell primary-color">
                                                <div className="header-cell-label center">
                                                    Actions
                                                </div>
                                            </TableCell>
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? sortedList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : sortedList
                                    ).map((obj: any, idx: number) => {
                                        return (
                                            <TableRow
                                                key={'row-' + idx}
                                                onClick={rowClick && (() => rowClick(obj.id))}
                                                className={rowClick ? 'clickable' : ''}
                                            >
                                                {tableInfoColumns.map(column => {
                                                    const backgroundColor = column.greyIfEmpty && !obj[column.type] ? ' disabled-cell ' : '';
                                                    if (!column.hidden) {
                                                        if (column.format === 'symbol') {
                                                            const icon: React.ReactNode | undefined = column.symbolProps?.icon;
                                                            const label: string | undefined = column.symbolProps?.label;
                                                            return (
                                                                <TableCell key={column.type + '-' + idx} className={`body-cell ${column.align}`}>
                                                                    {obj[column.type] ? icon ?? label : ''}
                                                                </TableCell>
                                                            )
                                                        } else if (column.format === 'currency-US' && (obj[column.type] != null)) {
                                                            return (
                                                                <TableCell key={column.type + '-' + idx} className={`${backgroundColor} body-cell ${column.align}`}>
                                                                    <NumberFormatter currency value={obj[column.type]} />
                                                                </TableCell>
                                                            )
                                                        } else if (column.format === 'date') {
                                                            return (
                                                                <TableCell key={column.type + '-' + idx} className={`${backgroundColor} body-cell ${column.align}`}>
                                                                    {displayDate(obj[column.type])}
                                                                </TableCell>
                                                            )
                                                        } else if (column.format === 'status') {
                                                            return (
                                                                obj[column.type] ?
                                                                    <TableCell key={column.type + '-' + idx} className={`body-cell ${column.align}`}>
                                                                        <StatusChip width='small' status={obj[column.type]} />
                                                                    </TableCell> : <TableCell key={column.type + '-' + idx} className={'body-cell'} />
                                                            )
                                                        } else if (column.format === 'button') {
                                                            const icon: React.ReactNode | undefined = column.buttonProps?.iconLabel;
                                                            const label: string | undefined = column.buttonProps?.label;
                                                            const value = obj[column.type];
                                                            let buttonDisabled: boolean = !column.buttonProps?.handlerPermission ?? false;
                                                            if (!buttonDisabled && column.buttonProps?.disable)
                                                                buttonDisabled = column.buttonProps.disable(obj[column.type]);
                                                            const buttonEmpty: boolean = !icon && !label && !value;
                                                            const backgroundColorButton: string = column.greyIfEmpty && (buttonDisabled || buttonEmpty) ? ' disabled-cell ' : '';
                                                            return (
                                                                <TableCell key={column.type + '-' + idx} className={`'${backgroundColorButton}' body-cell ${column.align} `}>
                                                                    {(icon || label || value) && <Button
                                                                        variant="text"
                                                                        color="primary"
                                                                        onClick={() => column.buttonProps?.handler(obj)}
                                                                        disabled={buttonDisabled}
                                                                    >
                                                                        {!icon && !label ? value : <>{icon}{label}</>}
                                                                    </Button>}
                                                                </TableCell>
                                                            )
                                                        } else if (column.format === 'enum' && column.bOptions) { // renders enum type as proper label
                                                            return (
                                                                <TableCell key={column.type + '-' + idx} className={`${backgroundColor} body-cell ${column.align}`}>
                                                                    {DisplayTextFormat(column.bOptions.find(o => o.value == obj[column.type])?.label)}
                                                                </TableCell>
                                                            )
                                                        } else if (column.format === 'employees') {
                                                            if (obj.users) {
                                                                return (
                                                                    <TableCell key={column.type + '-' + idx} className={`${backgroundColor} body-cell ${column.align}`}>
                                                                        {obj.users.map((user: StudyUser) => {
                                                                            return renderEmployeeChip(column.referenceRoles!, user, column.studyUsers!, history,
                                                                                (column.canGoToUserStats || loggedInUser.id === user.userId)
                                                                            );
                                                                        })}
                                                                    </TableCell>
                                                                )
                                                            }
                                                        } else if (column.selectOptions) { // if checked autocomplete component, render label
                                                            return (
                                                                <TableCell key={column.type + '-' + idx} className={`${backgroundColor} body-cell ${column.align}`}>
                                                                    {DisplayTextFormat(column.selectOptions.find(o => o.value == obj.serviceProviderId)?.label)}
                                                                </TableCell>
                                                            )
                                                        } else if (column.format === 'long-desc') {
                                                            return (
                                                                <TableCell
                                                                    key={column.type + '-' + idx}
                                                                    className={`body-cell ${column.align}`}
                                                                    style={{ minWidth: '550px', width: '550px' }}
                                                                >
                                                                    {DisplayTextFormat(obj[column.type], 75)}
                                                                </TableCell>
                                                            )

                                                        } else if (column.format === 'jsx-element') {
                                                            return (
                                                                <TableCell key={column.type + '-' + idx} className={`${backgroundColor} body-cell ${column.align}`}>
                                                                    {obj[column.type]}
                                                                </TableCell>
                                                            )
                                                        } else {
                                                            return (
                                                                <TableCell key={column.type + '-' + idx} className={`${backgroundColor} body-cell ${column.align}`}>
                                                                    {DisplayTextFormat(obj[column.type])}
                                                                </TableCell>
                                                            )
                                                        }
                                                    }
                                                })}
                                                {!readonly && hasActionPermission && hasMenuItems &&
                                                    <TableCell key='header-actions' className="body-cell center">
                                                        {renderActionIcons(obj, idx)}
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        )
                                    })}
                                    {dataList.length === 0 &&
                                        <TableRow>
                                            <TableCell colSpan={tableInfoColumns.length + 1} className='body-cell center' style={{ height: 80, fontSize: 16 }}>
                                                No data to display.
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                                {!readonly && canCreate &&
                                    <TableFooter className="background-color-paper" style={{ position: 'sticky', bottom: 0, zIndex: 1001 }}>
                                        <TableRow>
                                            {tableInfoColumns.map((column, idx) => { return (!column.hidden && renderFieldInTableCell(column, idx)) })}
                                            <TableCell className='body-cell center' style={{ width: 80 }}>
                                                <BSubmit id='add-submit' variant='text' onClick={() => setUpdated(updated + 1)}>
                                                    Add
                                                </BSubmit>
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                }
                            </Table>
                            <div ref={tableEndRef} />
                        </TableContainer>
                    </BForm>
                </Formik>
            </div>
            {clickedObj &&
                <Menu
                    id="more-actions-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    {actionOptions?.map(option => {
                        if (option.permission) return (
                            <Tooltip key={`actions-tooltip-${option.label}`} title={option.tooltipTitle ? option.tooltipTitle : ''} arrow>
                                <div>
                                    <MenuItem
                                        key={`more-actions-${option.label}`}
                                        onClick={() => {
                                            option.handler();
                                            handleCloseMenu();
                                        }}
                                        disabled={option.disabled ? option.disabled : false}
                                    >
                                        {option.label}
                                    </MenuItem>
                                </div>
                            </Tooltip>
                        );
                    })}
                </Menu>
            }
        </>
    )
}

export default EditablePagingTable;
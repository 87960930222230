import * as React from 'react';
import { useDispatch } from 'react-redux';

import { ForceResetPasswordRequest } from '../../../@types';
import { forceResetPassword } from '../../../service/Access/authentication';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../modules/messageSnackbarReducer';
import GridWrapper from '../../../common/GridWrapper/GridWrapper';
import useIncompleteLoggedInUserEmail from '../../../common/hooks/useIncompleteLoggedInUser';
import NotFound from '../../../common/Routes/NotFound';
import { useHistory } from 'react-router-dom';
import { clearStoreForceReset } from '../../../modules/authenticationReducer';
import { Formik, FormikHelpers } from 'formik';
import { BForm, BPassword, BSubmit } from 'mui-bueno';
import { Typography } from '@material-ui/core';
import { AxiosError } from 'axios';
import { handleEditErrors } from '../../../service/utils';

export interface PasswordRequest extends Record<string, any>{
    newPassword: string;
    confirmNewPassword: string;
}

const ForceResetPassword: React.FC = () => {
    const information = useIncompleteLoggedInUserEmail();
    const userEmail = information.userEmail;
    const session = information.session;

    if (!userEmail) {
        return <NotFound/>
    }

    const initialValues: PasswordRequest = {
        newPassword: '',
        confirmNewPassword: ''
    }

    const history = useHistory();

    const dispatch = useDispatch();

    const validate = (values: PasswordRequest) => {
        const errors: { [key: string]: string } = {};
        if (values.newPassword !== values.confirmNewPassword)
            errors.confirmNewPassword = 'New passwords do not match';
        return errors;
    };

    const handleSubmit = async (data: PasswordRequest, { setErrors }: FormikHelpers<PasswordRequest>) => {
        setErrors({});
        const forceResetPasswordRequest: ForceResetPasswordRequest = {
            email: userEmail,
            newPassword: data.newPassword,
            session: session,
        }
        forceResetPassword(forceResetPasswordRequest).then(() => {
            dispatch(showSuccessSnackbar('Password reset successfully'));
            dispatch(clearStoreForceReset());
            history.push('/login');
        }).catch((err: AxiosError) => {
            if (err.response?.status === 422) {
                handleEditErrors(err, setErrors);
            } else if (err.response?.status === 400) {
                const index = err.response.data.message.indexOf('(');
                const message = err.response.data.message.substring(0, index-1);
                if (err.response.data.message === 'Your session has expired. Please log in again' || message === 'Invalid session for the user, session is expired.') {
                    dispatch(showErrorSnackbar('Your session has expired. Please click the login button at the top right corner and login again.'));
                } else {
                    dispatch(showErrorSnackbar('Unexpected error. Try again later.'));
                }
            }
        });
    }

    return (
        <GridWrapper>
            <Formik
                initialValues={initialValues}
                validate={validate}
                onSubmit={handleSubmit}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
            >
                <BForm>
                    <Typography className="card-heading">You Must Reset Your Password</Typography>
                    <BPassword name="newPassword" label="New Password" noMP required></BPassword>
                    <BPassword name="confirmNewPassword" label="Confirm New Password" noMP required></BPassword>
                    <BSubmit name="save-password" xs={12} noMP gridStyle={{marginTop: '12px'}}>Save</BSubmit>
                </BForm>
            </Formik>
        </GridWrapper>
    );
}

export default ForceResetPassword;
